import { combineEpics } from 'redux-observable';
import fetchSearchSettings from './fetchSearchSettings';
import saveSearchSettings from './saveSearchSettings';
import removeSearchSettings from './removeSearchSettings';

export default combineEpics(
  fetchSearchSettings,
  saveSearchSettings,
  removeSearchSettings
);
