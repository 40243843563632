import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { toast } from 'react-toastify';
import interpolateString from 'common/helpers/interpolateString';
import request from '../../../utils/request';
import { API } from '../../../utils/config';

export const deleteComparatorEpic = $action =>
  $action.pipe(
    ofType('checklist-comparators/remove'),
    RxOperators.pluck('comparatorId'),
    RxOperators.mergeMap(comparatorId =>
      iif(
        () => comparatorId < 0,
        of({
          type: 'checklist-comparators/delete-success',
          originalId: comparatorId
        }),
        of(comparatorId).pipe(
          RxOperators.mergeMap(() =>
            request({
              url: interpolateString(API.comparators.detail, { comparatorId }),
              method: 'DELETE'
            }).pipe(
              RxOperators.map(() => ({
                type: 'checklist-comparators/delete-success',
                originalId: comparatorId
              })),
              RxOperators.catchError(({ response, message }) => {
                toast.error(message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 2500
                });

                return of({
                  type: 'checklist-comparators/delete-fail',
                  error: response ? response.data : message
                });
              })
            )
          )
        )
      )
    )
  );
