import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { AjaxError } from 'rxjs/ajax';
import type {
  SaveAccessSettings,
  SaveAccessSettingsFail,
  SaveAccessSettingsSuccess
} from 'flow-types/actions/projects/detail/accessRules/SaveAccessSettings';
import request from 'utils/request';
import { API } from 'utils/config';
import isNewElement from 'common/helpers/isNewElement';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import type { Epic } from 'flow-types/Epic';
import type { AppState } from 'flow-types/AppState';
import type { CloseModal } from '../../../Modals/modals';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

const saveAccessSettings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/save-access-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.exhaustMap(
      ([action, state]: [SaveAccessSettings, AppState]) => {
        const { accessSettings, modalId } = action;

        const projectId = projectIdFromPathSelector(state);

        const isNew = isNewElement(accessSettings);

        const params = isNew
          ? {
              url: interpolateString(API.projects.accessSettings.list, {
                projectId
              }),
              method: 'POST',
              body: decamelizeKeys(accessSettings)
            }
          : {
              url: interpolateString(API.projects.accessSettings.detail, {
                projectId,
                settingsId: accessSettings.id
              }),
              method: 'PUT',
              body: decamelizeKeys(accessSettings)
            };

        return request(params).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.mergeMap((rawData): [
            SaveAccessSettingsSuccess,
            CloseModal
          ] =>
            [
              {
                type: 'project/save-access-settings-success',
                accessSettings: camelizeKeys(rawData),
                settingId: action.accessSettings.id
              },
              modalId && {
                type: 'modals/close',
                modalId,
                dataReset: null
              }
            ].filter(Boolean)
          ),
          RxOperators.catchError((error: AjaxError): [
            SaveAccessSettingsFail
          ] => [
            {
              type: 'project/save-access-settings-fail',
              error: error.response
            }
          ])
        );
      }
    )
  );

export default saveAccessSettings;
