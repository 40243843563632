/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
// @flow

import * as React from 'react';
import cx from 'classnames';

// If DimmerProps will be exact, then all places where extra props are passed will be reported
// so DimmerProps type is inexact.
// However, inexact type results in rest operation being in strage mannger
export type Props = {
  +icon?: string,
  +className?: ?string,
  +onClick?: ?Function,
  +style?: Object | null,
  +tagName?: string,
  +noPointerEvents?: boolean,
  +fitted?: boolean
};

type RestProps = $Rest<Props, $Exact<Props>>;

const Icon = ({
  tagName,
  icon,
  className,
  style,
  onClick,
  noPointerEvents,
  fitted,
  ...rest
}: Props): React.Node => {
  const Component = tagName ?? 'i';

  const classes = cx(
    icon,
    {
      fitted
    },
    className,
    { 'no-pointer-events': noPointerEvents },
    'icon'
  );

  return (
    <Component
      className={classes}
      style={style}
      onClick={onClick}
      {...(rest: RestProps)}
    />
  );
};

Icon.defaultProps = {
  icon: '',
  className: '',
  onClick: null,
  style: null,
  tagName: 'i',
  noPointerEvents: false,
  fitted: false
};

export const Icons = ({
  className,
  size,
  fitted,
  ...props
}: {
  size?: null | string,
  className?: null | string,
  fitted?: boolean
}): React.Node => (
  <i {...props} className={cx(className, size, { fitted }, 'icons')} />
);

Icons.defaultProps = {
  className: null,
  size: null,
  fitted: false
};

export default Icon;
