// @flow

import map from 'lodash/map';
import size from 'lodash/size';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import fromPairs from 'lodash/fromPairs';
import sortBy from 'lodash/sortBy';

import type { FiltersSettingsAction } from 'flow-types/actions/projects/detail/filtersSettings';
import type { FiltersSettingsState } from 'flow-types/states/ProjectsState/detail';
import type { ResetProject } from 'flow-types/actions/projects/detail/project/ResetProject';
import type { FetchUserFiltersSettingsCountsSuccess } from 'flow-types/actions/projects/detail/filtersSettings/FetchUserFiltersSettingsCounts';
import type { FilterSettingsListGroup } from 'flow-types/entities/FilterSettings';
import type { ReorderFilters } from 'flow-types/actions/projects/detail/filtersSettings/ReorderFIlters';
import moveListAndReorder, {
  moveFromListToList
} from 'common/helpers/moveArrayAndReorder';
import type { ReorderFiltersGroups } from 'flow-types/actions/projects/detail/filtersSettings/ReorderFiltersGroups';
import type { MoveFilters } from 'flow-types/actions/projects/detail/filtersSettings/MoveFilters';
import { recalculateOrder } from 'common/transducers/sortingItemsNormalizer';
import type { Reducer } from 'flow-types/Reducer';

const initialState: FiltersSettingsState = {
  data: {},
  filterSettingsIdToGroupMap: {},
  isLoading: false,
  isMutating: false,
  isLoadingCounts: false
};

const constructFilterSettingsToGroupMap = groups =>
  reduce(
    groups,
    (result, { filterSettingsList, id: groupId }: FilterSettingsListGroup) => {
      const obj = fromPairs(
        map(filterSettingsList, (settings, index) => [
          settings.id,
          [groupId, index]
        ])
      );

      return {
        ...result,
        ...obj
      };
    },
    {}
  );

// const handleSaveFiltersGroupSuccess = (
//   state,
//   { groupId, data }: SaveFilterSettingsListGroupSuccess
// ): FiltersSettingsState => {
//   const nextData = { ...state.data };
//
//   if (nextData[groupId]) {
//     nextData[groupId] = { ...nextData[groupId], ...data };
//   } else {
//     nextData[groupId] = { ...data };
//   }
//
//   return {
//     ...state,
//     data: nextData,
//     filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData)
//   };
// };

// const handleRemoveFiltersGroupSuccess = (
//   state,
//   { groupId }: RemoveFilterSettingsListGroupSuccess
// ): FiltersSettingsState => {
//   const nextData = { ...state.data };
//
//   if (nextData[groupId]) {
//     const temp = [...nextData[groupId].filterSettingsList];
//
//     nextData['-1'].filterSettingsList = concat(
//       nextData['-1'].filterSettingsList,
//       temp
//     );
//
//     delete nextData[groupId];
//   }
//
//   return {
//     ...state,
//     data: nextData,
//     filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData)
//   };
// };

const handleSaveFilterSuccess = (
  state: FiltersSettingsState
): FiltersSettingsState =>
  // const { data, filterSettingsIdToGroupMap, ...rest } = state;
  //
  // const { originalId, filterSettings: nextFilterSettings } = action;
  //
  // const nextData = { ...data };
  //
  // let targetGroupId = '-1';
  //
  // if (!isNil(nextFilterSettings.userFiltersSettingsGroupId)) {
  //   targetGroupId = nextFilterSettings.userFiltersSettingsGroupId;
  // }
  //
  // if (originalId) {
  //   const [originalGroupId, targetIndex] = filterSettingsIdToGroupMap[
  //     originalId
  //   ];
  //
  //   if (targetGroupId !== originalGroupId) {
  //     nextData[originalGroupId].filterSettingsList[targetIndex] = {
  //       ...action.filterSettings
  //     };
  //   } else {
  //     nextData[targetGroupId].filterSettingsList[targetIndex] = {
  //       ...action.filterSettings
  //     };
  //   }
  // } else {
  //   nextData[targetGroupId].filterSettingsList = addAt(
  //     nextData[targetGroupId].filterSettingsList,
  //     { ...action.filterSettings },
  //     action.filterSettings
  //   );
  // }

  ({
    ...state,
    // data: nextData,
    // filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData),
    isMutating: false
  });
const handleRemoveFilterSuccess = (
  state: FiltersSettingsState
  // action: RemoveFilterSettingsSuccess
): FiltersSettingsState =>
  // const { data, filterSettingsIdToGroupMap } = state;
  //
  // const { settingsId } = action;
  //
  // const nextData = { ...data };
  //
  // const [groupId, index] = filterSettingsIdToGroupMap[settingsId];
  //
  // delete nextData[groupId].filterSettingsList[index];

  ({
    ...state,
    // data: nextData,
    // filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData),
    isMutating: false
  });
const handleFetchFilterSettingsCountsSuccess = (
  state: FiltersSettingsState,
  action: FetchUserFiltersSettingsCountsSuccess
) => {
  const { filterSettingsIdToGroupMap } = state;

  const { data, isFinal } = action;

  if (size(data) === 0) {
    if (!isFinal) return state;

    return {
      ...state,
      isLoadingCounts: false
    };
  }

  const nextData = { ...state.data };

  forEach(data, (value, settingsId) => {
    const { count } = value;

    const [groupId, index] = filterSettingsIdToGroupMap[settingsId];

    // number is a valid index key
    // $FlowIgnore
    if (nextData[groupId].filterSettingsList[index]) {
      nextData[groupId].filterSettingsList[index].count = count;
    }
  });

  return {
    ...state,
    ...(isFinal && {
      isLoadingCounts: false
    }),
    data: nextData
  };
};

function handleFetchFilterSettingsListGroupSuccess(
  state,
  { data }
): FiltersSettingsState {
  const keyedData = keyBy(data, 'id');

  return {
    ...state,
    data: keyedData,
    filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(keyedData),
    isLoading: false
  };
}

function reorderFilterSettings(
  state: FiltersSettingsState,
  action: ReorderFilters
): FiltersSettingsState {
  const { data } = state;

  const { from, to, groupId } = action;

  const nextData = { ...data };

  nextData[groupId].filterSettingsList = moveListAndReorder(
    nextData[groupId].filterSettingsList || [],
    from,
    to
  );

  return {
    ...state,
    data: nextData,
    filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData)
  };
}

function reorderFilterSettingsGroups(
  state: FiltersSettingsState,
  action: ReorderFiltersGroups
): FiltersSettingsState {
  let nextData = sortBy({ ...state.data }, 'order');

  nextData = moveListAndReorder(nextData, action.from, action.to);

  nextData = keyBy(nextData, 'id');

  return {
    ...state,
    data: nextData,
    filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData)
  };
}

function moveFilterSettings(
  state: FiltersSettingsState,
  action: MoveFilters
): FiltersSettingsState {
  const nextData = { ...state.data };

  moveFromListToList(
    {
      source: nextData[action.fromParentId].filterSettingsList || [],
      destination: nextData[action.toParentId].filterSettingsList || [],
      from: action.from,
      to: action.to
    },
    true
  );

  nextData[action.fromParentId].filterSettingsList = recalculateOrder(
    nextData[action.fromParentId].filterSettingsList
  );

  nextData[action.toParentId].filterSettingsList = recalculateOrder(
    nextData[action.toParentId].filterSettingsList
  );

  return {
    ...state,
    data: nextData,
    filterSettingsIdToGroupMap: constructFilterSettingsToGroupMap(nextData)
  };
}

const filtersSettings: Reducer<
  FiltersSettingsState,
  FiltersSettingsAction | ResetProject
> = (state = initialState, action) => {
  switch (action.type) {
    case 'project-filter-settings/move':
      return moveFilterSettings(state, action);

    case 'project-filter-settings/reorder':
      return reorderFilterSettings(state, action);

    case 'project-filter-settings/reorder-groups':
      return reorderFilterSettingsGroups(state, action);

    // case 'project/save-filter-settings-list-group-success':
    //   return handleSaveFiltersGroupSuccess(state, action);
    //
    // case 'project/remove-filter-settings-list-group-success':
    //   return handleRemoveFiltersGroupSuccess(state, action);

    case 'project/reset':
      return initialState;

    case 'project/fetch-filter-settings-list-groups':
      return {
        ...state,
        isLoading: true
      };

    case 'project/fetch-filter-settings-list-groups-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project/fetch-filter-settings-list-groups-success':
      return handleFetchFilterSettingsListGroupSuccess(state, action);

    case 'project/save-filter-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/save-filter-settings-fail':
      return {
        ...state,
        isMutating: false
      };

    case 'project/save-filter-settings-success':
      return handleSaveFilterSuccess(state);

    case 'project/remove-filter-settings-success':
      return handleRemoveFilterSuccess(state);

    case 'project/remove-filter-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/remove-filter-settings-fail':
      return {
        ...state,
        isMutating: true
      };

    case 'project/fetch-user-filters-settings-counts':
      return {
        ...state,
        isLoadingCounts: true
      };

    case 'project/fetch-user-filters-settings-counts-fail':
      return {
        ...state,
        isLoadingCounts: false
      };

    case 'project/fetch-user-filters-settings-counts-success':
      return handleFetchFilterSettingsCountsSuccess(state, action);

    default:
      return state;
  }
};

export default filtersSettings;
