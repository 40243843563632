import { combineEpics } from 'redux-observable';
import createExportTaskEpic from './createExportTask';
import fetchExportTasksEpic from './fetchExportTasks';
import fetchSettingsListEpic from './fetchSettingsList';
import removeSettingsEpic from './removeSettings';
import saveSettingsEpic from './saveSettings';
import watchExportTaskEpic from './watchExportTask';

export default combineEpics(
  fetchExportTasksEpic,
  createExportTaskEpic,
  fetchSettingsListEpic,
  saveSettingsEpic,
  removeSettingsEpic,
  watchExportTaskEpic
);
