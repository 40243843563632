// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { API } from 'utils/config';
import request from 'utils/request';

import decamelizeKeys from 'common/helpers/decamelizeKeys';
import interpolateString from 'common/helpers/interpolateString';
import { INTERVIEW_APPEARANCE } from 'common/helpers/interview/constants';

import type { Epic } from 'flow-types/Epic';
import type { AppState } from 'flow-types/AppState';
import type { AddRegistryRecord } from 'flow-types/actions/interview/AddRegistryRecord';
import type { IView } from 'flow-types/entities/View';

import {
  createInterviewAppearanceSelector,
  interviewStatusesSelector
} from '../../selectors/interview/statuses';
import { interviewRecordSelector } from '../../selectors/interview/root';
import { authStateSelector } from '../../selectors';

/**
 * Each time user moves somewhere we're storing its last location
 * in a view that has not timeEnd.
 * Thus, on page reload we can simply take him to the last visited block before reloading.
 */
const syncRegistryEpic: Epic = (action$, state$) =>
  action$.pipe<AddRegistryRecord>(
    ofType('interview/registry'),
    RxO.withLatestFrom(state$),
    RxO.filter(([action, state]: [AddRegistryRecord, AppState]) => {
      const { skipRequest } = interviewStatusesSelector(state);

      if (skipRequest) return false;

      return (
        [INTERVIEW_APPEARANCE.PUBLIC, INTERVIEW_APPEARANCE.POST].includes(
          createInterviewAppearanceSelector()(state)
        ) && !!action.payload.enter
      );
    }),
    RxO.concatMap(([action, state]: [AddRegistryRecord, AppState]) => {
      const {
        data: { id: responseId, localId }
      } = interviewRecordSelector(state);

      const { token } = authStateSelector(state);

      const { isPostPolling, isPublicPolling } = interviewStatusesSelector(
        state
      );

      const body: { views: IView[], localId: string } = {
        localId,
        views: []
      };

      if (action.payload.questionIds) {
        body.views = action.payload.questionIds.map(questionId => ({
          questionId,
          responseId,
          timeEnd: null,
          // TODO: resolve later
          // $FlowIgnore
          timeStart: action.payload.enter
        }));
      } else {
        body.views = [
          {
            // TODO: resolve later
            // $FlowIgnore
            questionId: action.payload.questionId,
            responseId,
            timeEnd: null,
            // TODO: resolve later
            // $FlowIgnore
            timeStart: action.payload.enter
          }
        ];
      }

      return request({
        url: interpolateString(API.responses.views, { responseId }),
        method: 'POST',
        body: decamelizeKeys(body),
        token: isPostPolling || isPublicPolling ? token : undefined
      }).pipe(RxO.mergeMap(() => EMPTY));
    })
  );

export default syncRegistryEpic;
