import fp from 'lodash/fp';
import reduce from 'lodash/reduce';
import { camelizeKeys } from 'humps';
import type { IStat } from '../../../flow-types/entities/Stat';
import type { IResponseStats } from '../../../flow-types/entities/ResponseStats';

export const normalizeStats = fp.compose(
  fp.reduce(
    (
      responseStats: IResponseStats,
      checklistStats: Array<IStat>
    ): IResponseStats => {
      const { pointsPercent, checklistId, groups } = checklistStats;
      return {
        ...responseStats,
        [checklistId]: {
          checklistId,
          percent: pointsPercent,
          groups: reduce(
            groups,
            (checklistGroupsStats, groupStats) => {
              const {
                checklistGroupId,
                pointsPercent: groupPointsPercent
              } = groupStats;

              return {
                ...checklistGroupsStats,
                [checklistGroupId]: {
                  checklistGroupId,
                  percent: groupPointsPercent
                }
              };
            },
            {}
          )
        }
      };
    },
    {}
  ),
  camelizeKeys
);
