// @flow
import { merge } from 'merge-anything';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';

export type SidebarState = {
  visible: boolean
};

export type SidebarsState = {
  [key: string]: SidebarState
};

type OpenSidebar = {
  type: 'open',
  sidebarId: string
};

type CloseSidebar = {
  type: 'close',
  sidebarId: string
};

type OpenAllSidebars = {
  type: 'open-all'
};

type CloseAllSidebars = {
  type: 'close-all'
};

type SidebarsAction =
  | OpenSidebar
  | CloseSidebar
  | OpenAllSidebars
  | CloseAllSidebars;

const openSidebar = (state: SidebarsState, action: OpenSidebar) => {
  if (!action.sidebarId) return state;

  return merge(cloneDeep(state), { [action.sidebarId]: { visible: true } });
};

const closeSidebar = (state: SidebarsState, action: CloseSidebar) => {
  if (!action.sidebarId) return state;

  return merge(cloneDeep(state), { [action.sidebarId]: { visible: false } });
};

export default function reducer(
  state: SidebarsState,
  action: SidebarsAction
): SidebarsState {
  switch (action.type) {
    case 'close':
      return closeSidebar(state, action);
    case 'open':
      return openSidebar(state, action);
    case 'open-all':
      return reduce(
        state,
        (nextState, sidebarState, sidebarId) => ({
          ...nextState,
          [sidebarId]: {
            ...sidebarState,
            visible: true
          }
        }),
        {}
      );
    case 'close-all':
      return reduce(
        state,
        (nextState, sidebarState, sidebarId) => ({
          ...nextState,
          [sidebarId]: {
            ...sidebarState,
            visible: false
          }
        }),
        {}
      );
    default:
      // it's an error case in development
      return state;
  }
}
