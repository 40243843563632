// @flow
import React, { useCallback, useRef } from 'react';
import Clipboard from 'clipboard';
import { toast } from 'react-toastify';

import Header from 'common/components/Header';
import Link from 'common/components/Link';
import useWillUnmount from 'common/hooks/useWillUnmount';
import useUniqueId from 'common/hooks/useUniqId';

import type { TemplateCode as ITemplateCode } from './flow';

type Props = {
  data: ITemplateCode
};

export default function TemplateCode({ data }: Props) {
  const id = useUniqueId('clip-');

  const clipboardRef = useRef(null);

  const setupClipboard = useCallback(
    node => {
      if (!node) return;

      clipboardRef.current = new Clipboard(`#${id}`);

      clipboardRef.current.on('success', e => {
        toast.success(`Code is copied to clipboard`, {
          autoClose: 2500
        });
        e.clearSelection();
      });
    },
    [id]
  );

  useWillUnmount(() => {
    if (clipboardRef.current) {
      clipboardRef.current.destroy();
      clipboardRef.current = null;
    }
  });

  return (
    <>
      <div>
        <Header
          as={Link}
          size="small"
          ref={setupClipboard}
          id={id}
          data-clipboard-text={`{{${data.code}}}`}
        >
          {`{{${data.code}}}`}
        </Header>
      </div>
      {data.title}
    </>
  );
}
