import type { InterviewAction } from '../../flow-types/actions/interview';
import type { InterviewActiveStackState } from '../../flow-types/states/InterviewState/InterviewActiveStackState';

const initialState: InterviewActiveStackState = null;

export default function activeStack(
  state = initialState,
  action: InterviewAction
): InterviewActiveStackState {
  switch (action.type) {
    case 'interview/reset':
      return initialState;

    case 'interview/change-active-stack':
      return action.stackId === false ? state : action.stackId;

    default:
      return state;
  }
}
