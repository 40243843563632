const {
  REACT_APP_BUILDINGS_FEATURE,
  REACT_APP_AGITATION_FEATURE,
  REACT_APP_CLIENT_ID
} = process.env;

const AGITATION_FEATURE = REACT_APP_AGITATION_FEATURE === 'true';

const AppEnvironment = {
  /**
   * Checks consumer environment.
   * Can be used to use some code only for a specific consumer.
   */
  tiRealty: REACT_APP_CLIENT_ID === 'ti-realty',
  /**
   * Buildings feature is required
   * for agitation
   */
  buildings: REACT_APP_BUILDINGS_FEATURE === 'true',
  agitations: AGITATION_FEATURE
};

export default AppEnvironment;
