import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { SignInSuccess } from 'flow-types/actions/auth/SignIn';
import saveToken from 'utils/saveToken';
import type { LocationState } from 'redux-first-router/dist';
import type { PageState } from 'flow-types/states/PageState';
import { EMPTY, of } from 'rxjs';
import { locationStateSelector, pageStateSelector } from '../../selectors';

export const signInSuccessEpic = ($action, $state) =>
  $action.pipe(
    ofType('auth/sign-in-success'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([action, state]) => {
      const {
        initialLoggedInState,
        accessToken,
        rememberMe
      }: SignInSuccess = action;

      // if accessToken has been passed,
      // then set it according to remember\Me
      if (accessToken) {
        saveToken({
          token: accessToken,
          remember: rememberMe
        });
      }

      const { prev }: LocationState = locationStateSelector(state);
      const { name: page }: PageState = pageStateSelector(state);

      // if login succeeded, but we're not on Login page,
      // then stay where you are
      if (page !== 'Login') {
        return EMPTY;
      }

      // it means, that we actually just entered the page from outside or have relogged in
      if (!initialLoggedInState) {
        // if we were moving somewhere before guard stopped us,
        // then we will try to go to that place again
        if (prev && !!prev.type) {
          if (
            prev.type !== 'router/interviewsPage' ||
            (prev.type === 'router/interviewsPage' &&
              prev.meta &&
              prev.meta.query &&
              prev.meta.query.rid)
          ) {
            return of(prev);
          }
        }
      }

      return of({ type: 'router/homePage' });
    })
  );
