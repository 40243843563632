import revertDictionary from 'utils/revertDictionary';

const dictionary = revertDictionary({
  'tasksTable.columns.id': {
    ru: 'ИД',
    en: 'ID'
  },
  'tasksTable.columns.title': {
    ru: 'Заголовок',
    en: 'Title'
  },
  'tasksTable.columns.status': {
    ru: 'Статус',
    en: 'Status'
  },
  'tasksTable.columns.createdAt': {
    ru: 'Дата создания',
    en: 'Creation date'
  }
});

export default dictionary;
