/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import last from 'lodash/last';
import map from 'lodash/map';

import ListItem from 'common/components/ListItem';
import Content from 'common/components/Content';
import Header from 'common/components/Header';
import List from 'common/components/List';

import type { IComment } from 'flow-types/entities/Comment';
import type { NilValue } from 'flow-types/NilValue';

const AnswerComment = ({
  data,
  showDate
}: {
  data: IComment,
  showDate: boolean
}) => (
  <ListItem>
    <Content>
      <div dangerouslySetInnerHTML={{ __html: data.body }} />
      {showDate && <div className="description">{data.dateCreated}</div>}
    </Content>
  </ListItem>
);

type Props = {
  data?: NilValue | IComment[],
  showCommentDate: boolean,
  onlyLast: boolean
};

export default function AnswerComments({
  data,
  showCommentDate,
  onlyLast
}: Props) {
  const list = useMemo(() => {
    if (!data) return [];

    if (onlyLast) {
      return [last(data)];
    }

    return data;
  }, [data, onlyLast]);

  return (
    <>
      <Header size="tiny">
        <FormattedMessage
          id={
            !onlyLast
              ? 'projects.tabs.responses.comments'
              : 'projects.tabs.responses.lastComment'
          }
        />
      </Header>
      <List>
        {map(list, (comment: IComment) => {
          if (!comment) return null;

          return (
            <AnswerComment
              showDate={showCommentDate}
              key={`comment-${comment.id}`}
              data={comment}
            />
          );
        })}
      </List>
    </>
  );
}

AnswerComments.defaultProps = {
  data: null
};
