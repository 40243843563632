// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { AccessSettingsForm as IAccessSettingsForm } from 'flow-types/entities/AccessSettings';
import Input, { InputWrapper } from '../../../../../common/components/Input';
import ConfirmButton from '../../../../../common/components/ConfirmButton';
import Checkbox from '../../../../../common/components/Checkbox';
import Button from '../../../../../common/components/Button';

import isNewElement from '../../../../../common/helpers/isNewElement';

import ProjectAccessSettingsTable from './ProjectAccessSettingsTable';

type Props = {|
  initialData: null | IAccessSettingsForm,
  disabled: boolean,
  onCancel: Function,
  onSubmit: Function,
  onRemove: Function
|};

const AccessSettingsForm = ({
  initialData,
  disabled,
  onCancel,
  onSubmit,
  onRemove
}: Props) => {
  const intl = useIntl();

  const [data, setData] = useState<IAccessSettingsForm>({
    id: -1,
    title: '',
    isDefault: false,
    questionGroupsAccess: {},
    questionsAccess: {}
  });

  const accessTypes = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'accessSettingsForm.fields.settingsTable.columns.editable'
        }),
        value: 'editable'
      },
      {
        label: intl.formatMessage({
          id: 'accessSettingsForm.fields.settingsTable.columns.disabled'
        }),
        value: 'disabled'
      },
      {
        label: intl.formatMessage({
          id: 'accessSettingsForm.fields.settingsTable.columns.hidden'
        }),
        value: 'hidden'
      }
    ],
    [intl]
  );

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    } else {
      setData({
        id: -1,
        title: '',
        isDefault: false,
        questionGroupsAccess: {},
        questionsAccess: {}
      });
    }
  }, [initialData]);

  const isNewlyCreated = useMemo(() => isNewElement(data), [data]);

  const handleTitleChange = useCallback(nextTitle => {
    setData((prev: IAccessSettingsForm): IAccessSettingsForm => {
      if (prev.title !== nextTitle) {
        return {
          ...prev,
          title: nextTitle
        };
      }

      return prev;
    });
  }, []);

  const handleIsDefaultChange = useCallback(nextIsDefault => {
    setData((prev: IAccessSettingsForm): IAccessSettingsForm => {
      if (prev.isDefault !== nextIsDefault) {
        return {
          ...prev,
          isDefault: nextIsDefault
        };
      }

      return prev;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!onSubmit) return;

    onSubmit(data);
  }, [data, onSubmit]);

  return (
    <div className="ui grid">
      <div className="row">
        <div className="column">
          <InputWrapper fluid>
            <Input value={data.title} onlyValue onChange={handleTitleChange} />
          </InputWrapper>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Checkbox value={data.isDefault} onChange={handleIsDefaultChange}>
            <FormattedMessage id="accessSettingsForm.fields.isDefault.label" />
          </Checkbox>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <ProjectAccessSettingsTable
            data={data}
            onChange={setData}
            accessTypes={accessTypes}
          />
        </div>
      </div>
      <div className="row">
        {!isNewlyCreated && (
          <div className="six wide column left aligned left floated">
            <ConfirmButton
              disabled={disabled}
              buttonType="negative"
              confirmMessage="Вы действительно хотите удалить настройки доступа?"
              id="remove-access-settings-btn"
              onSubmit={() => {
                if (!onRemove) return;

                onRemove(data.id);
              }}
            >
              <FormattedMessage id="common.labels.delete" />
            </ConfirmButton>
          </div>
        )}
        <div className="six wide column right aligned right floated">
          <Button onClick={onCancel} disabled={disabled}>
            <FormattedMessage id="common.labels.cancel" />
          </Button>
          <Button
            onClick={handleSubmit}
            buttonType="primary"
            disabled={disabled}
          >
            <FormattedMessage id="common.labels.submit" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessSettingsForm;
