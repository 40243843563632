// @flow
import * as Y from 'yup';
import isNil from 'lodash/isNil';
import type { $QuestionTypes } from 'flow-types/entities/Question';
import { TYPES } from 'common/helpers/question';
import nullToString from 'common/helpers/nullToString';
import toNumber from 'lodash/toNumber';
import isDevelopment from 'utils/isDevelopment';
import parseISO from 'date-fns/parseISO';

const isNotNil = val => !isNil(val);

type Config = {
  /**
   * Messages for all error cases
   * TODO: resolve later,
   *  complete list of all error cases
   */
  messages?: {
    required?: any,
    otherRequired?: any,
    typeError?: any,
    bothValuesRequired?: any,
    incorrectFormat?: any,
    min?: any,
    max?: any,
    scale?: any
  },
  /**
   * Type of {@link IQuestion};
   */
  type: $QuestionTypes,
  /**
   * Depending on type it could be
   * minAnswers or minValue from {@link IQuestion};
   */
  min?: number,
  /**
   * Depending on type it could be
   * maxAnswers or maxValue from {@link IQuestion};
   */
  max?: number,
  /**
   * The same named field from {@link IQuestion};
   */
  scale?: number,
  /**
   * Depending on {@link IQuestion} it be true in the following cases:
   * - {@link TYPES.MultipleAnswer}
   * - {@link TYPES.Checklist}
   * - {@link TYPES.Ranging}
   * - {@link TYPES.Numeric} with {@link IQuestion.isInterval} === `true`
   * - {@link TYPES.DateTime} with {@link IQuestion.isInterval} === `true`
   */
  multi?: boolean,
  /**
   * Depends on {@link IQuestion.required}
   */
  required?: boolean
  /**
   * Depends on {@link IQuestion.filesEnabled}
   */
  // checkFiles?: boolean,
  /**
   * Depends on {@link IQuestion.minFiles}
   */
  // minFiles?: number,
  /**
   * Depends on {@link IQuestion.maxFiles}
   */
  // maxFiles?: number,
};

type AnswerSchemaSkeleton = {
  data: any,
  meta?: any
};

/**
 *
 */
const configureValidator = (
  config: Config,
  onlyFor: 'data' | 'meta' | null = null
): { validate: Function, validateSync: Function } =>
  Y.lazy((answerObj): AnswerSchemaSkeleton => {
    const messages = config?.messages;

    let schema;

    switch (config?.type) {
      case TYPES.DateTime:
        schema = {
          data: Y.lazy(answerData => {
            let dateTimeSchema;

            dateTimeSchema = Y.date().transform(value => {
              if (typeof value === 'string') return parseISO(value);

              return value;
            });

            if (config.required) {
              dateTimeSchema = dateTimeSchema
                .required(messages?.required)
                .typeError(messages?.typeError);
            } else if (
              config.multi &&
              Array.isArray(answerData) &&
              answerData.some(isNotNil)
            ) {
              /*
                In DateTime block when user specifies
                one of the values, then the second one
                should be also set and vice versa.
             */
              dateTimeSchema = dateTimeSchema.typeError(
                messages?.bothValuesRequired
              );
            } else {
              dateTimeSchema = dateTimeSchema.nullable();
            }

            if (config.multi) {
              return Y.array(dateTimeSchema);
            }

            return dateTimeSchema;
          })
        };
        break;
      case TYPES.Email:
        schema = {
          data: Y.lazy(answerData => {
            let emailSchema = Y.string();

            if (config.required) {
              emailSchema = emailSchema
                .transform(nullToString)
                .required(messages?.required);
            } else {
              emailSchema = emailSchema.nullable();
            }

            /*
              In the e-mail block, if there is at least one letter,
              but the block itself is not required,
              the value must still correspond to the format
              of the e-mail address.
            */
            if (answerData) {
              emailSchema = emailSchema.email(messages?.incorrectFormat);
            }

            return emailSchema.default('');
          })
        };
        break;
      case TYPES.MobilePhone:
        schema = {
          data: Y.lazy(answerData => {
            let mobileSchema = Y.string();

            if (config.required) {
              mobileSchema = mobileSchema
                .transform(nullToString)
                .required(messages?.required);
            } else {
              mobileSchema = mobileSchema.nullable();
            }

            if (answerData) {
              mobileSchema = mobileSchema.matches(/^((\+7|7|8)+([0-9]){10})$/, {
                message: messages?.incorrectFormat
              });
            }

            return mobileSchema;
          })
        };
        break;
      case TYPES.Checklist:
      case TYPES.MultipleAnswer:
        schema = {
          data: Y.lazy(() => {
            let multiChoiceSchema = Y.array();

            if (config.required) {
              multiChoiceSchema = multiChoiceSchema.required(
                messages?.required
              );
            } else {
              multiChoiceSchema = multiChoiceSchema.nullable();
            }

            if (config.min && +config.min !== 0) {
              multiChoiceSchema = multiChoiceSchema.min(
                config.min,
                messages?.min
              );
            }

            if (config.max && +config.max !== 0) {
              multiChoiceSchema = multiChoiceSchema.max(
                config.max,
                messages?.max
              );
            }

            return multiChoiceSchema;
          }),
          meta: Y.lazy(() => {
            if (answerObj?.data?.includes('other')) {
              return Y.object({
                other: Y.string()
                  .transform(nullToString)
                  .required(messages?.otherRequired)
              });
            }

            return Y.mixed().nullable();
          })
        };
        break;
      case TYPES.SingleAnswer:
      case TYPES.Status:
        schema = {
          data: Y.lazy(() => {
            let singleChoiceSchema = Y.string()
              .transform(nullToString)
              .transform(val => (typeof val === 'number' ? `${val}` : val));

            if (config.required) {
              singleChoiceSchema = singleChoiceSchema.required(
                messages?.required
              );
            }

            return singleChoiceSchema;
          }),
          meta: Y.lazy(() => {
            if (answerObj?.data === 'other') {
              return Y.object({
                other: Y.string()
                  .transform(nullToString)
                  .required(messages?.otherRequired)
              });
            }

            return Y.mixed().nullable();
          })
        };
        break;
      case TYPES.TextBlock:
        schema = {
          data: Y.lazy(() => {
            let textSchema = Y.string().transform(nullToString);

            if (config.required) {
              textSchema = textSchema.required(messages?.required);
            } else {
              textSchema = textSchema.nullable();
            }

            return textSchema;
          })
        };
        break;
      case TYPES.Numeric:
      case TYPES.Rating:
        schema = {
          data: Y.lazy(answerData => {
            let numericSchema = Y.string()
              .transform(nullToString)
              .transform(val => (typeof val === 'number' ? `${val}` : val));

            if (config.required) {
              numericSchema = numericSchema.required(messages?.required);
            }

            if (!isNil(config.min)) {
              // TODO: resolve later
              if (isDevelopment) {
                if (typeof config.min !== 'number') {
                  throw new Error('min is not a number');
                }
              }

              numericSchema = numericSchema.test(
                'min',
                messages?.min,
                val => toNumber(val) >= config.min
              );
            }

            if (!isNil(config.max)) {
              // TODO: resolve later
              if (isDevelopment) {
                if (typeof config.max !== 'number') {
                  throw new Error('max is not a number');
                }
              }

              numericSchema = numericSchema.test(
                'max',
                messages?.max,
                val => toNumber(val) <= config.max
              );
            }

            if (
              !isNil(answerData) &&
              !isNil(config.scale) &&
              // $FlowIgnore
              config.scale > -1
            ) {
              numericSchema = numericSchema.test(
                'scale',
                messages?.scale,
                value => {
                  // $FlowIgnore
                  const [, decimalDigit] = `${value}`.split('.');

                  return typeof decimalDigit === 'string'
                    ? // $FlowIgnore
                      decimalDigit.length <= config.scale
                    : true;
                }
              );
            }

            if (config.multi) {
              return Y.array(numericSchema);
            }

            return numericSchema;
          })
        };
        break;
      default:
        schema = {
          data: Y.mixed().nullable(),
          meta: Y.mixed().nullable()
        };
    }

    if (onlyFor === 'data') return schema.data;

    // $FlowIgnore
    if (onlyFor === 'meta') return schema.meta ?? Y.mixed().nullable();

    return Y.object(schema);
  });

export default configureValidator;
