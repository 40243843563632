/* eslint-disable react/jsx-props-no-spreading */
// @flow

import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { FomanticSize } from 'flow-types/FomanticSize';
import type { IUpload } from 'flow-types/entities/Upload';
import useProgressiveImage from 'common/components/Image/useProgressiveImage';

export type Props = {
  +src?: null | string | IUpload,
  +container?: boolean,
  +tagName?: ReactComponent,
  +size?: FomanticSize,
  +svg?: Object,
  +svgImage?: Object,
  +className?: null | string,
  +children?: null | ReactChildren,
  +hidden?: boolean,
  +disabled?: boolean,
  +avatar?: boolean,
  +bordered?: boolean,
  +rounded?: boolean,
  +circular?: boolean,
  +align?: null | 'top' | 'center' | 'bottom',
  +fluid?: boolean,
  +centered?: boolean,
  +spaced?: boolean,
  +space?: null | 'left' | 'right',
  +float?: null | 'left' | 'right',
  +group?: boolean,
  +alt?: string
};

const Image = ({
  container,
  tagName,
  size,
  svg,
  svgImage,
  className,
  src,
  children,
  hidden,
  disabled,
  avatar,
  bordered,
  rounded,
  circular,
  align,
  fluid,
  centered,
  spaced,
  space,
  float,
  group,
  alt,
  ...rest
}: Props): Node => {
  const imageClassName = cx(
    { hidden },
    { disabled },
    'ui',
    {
      avatar,
      bordered,
      fluid,
      rounded,
      circular
    },
    align ? [`${align} aligned`] : null,
    {
      centered,
      spaced
    },
    space ? [`${space} spaced`] : null,
    float ? [`${float} floated`] : null,
    size,
    className,
    {
      image: !group,
      images: group
    }
  );

  const imageSrc = useProgressiveImage({
    previewSrc: src !== null && typeof src === 'object' ? src.thumb256 : null,
    // $FlowIgnore
    src: src !== null && typeof src === 'object' ? src.url : src
  });

  let Container;

  if (!container && !group) {
    Container = null;
  } else if (group) {
    Container = 'div';
  } else {
    Container = tagName || null;
  }

  if (!Container) {
    return (
      <img {...rest} alt={alt} className={imageClassName} src={imageSrc} />
    );
  }

  if (group) {
    return (
      <Container {...rest} className={imageClassName}>
        {children}
      </Container>
    );
  }

  return (
    <Container {...rest} className={imageClassName}>
      {svg && (
        <svg {...svg}>
          <image xlinkHref={src} x="0" y="0" {...svgImage} />
        </svg>
      )}
      {!svg && src && <img alt={alt} src={imageSrc} />}
      {children}
    </Container>
  );
};

Image.defaultProps = {
  alt: '',
  children: null,
  svg: null,
  svgImage: null,
  className: null,
  hidden: false,
  disabled: false,
  avatar: false,
  bordered: false,
  rounded: false,
  circular: false,
  align: null,
  fluid: false,
  centered: false,
  spaced: false,
  space: null,
  float: null,
  group: false,
  src: null,
  container: false,
  tagName: 'div',
  size: null
};

export default Image;
