import { animated } from 'react-spring';
import styled from 'styled-components';

export const Container = styled(animated.div)`
  &&& {
    display: flex;
  }

  &&&.fixed {
    position: fixed !important;
  }
`;
