// @flow

/**
 * TODO: it may be rethought how things are working here
 */

import React, { useCallback, useMemo, useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { merge } from 'merge-anything';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import max from 'lodash/max';
import filter from 'lodash/filter';

import Table from 'common/components/Table';
import Checkbox from 'common/components/Checkbox';

import type { Node } from 'react';
import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import type { VisibilitySettings } from 'flow-types/entities/VisibilitySettings';

import Text from 'common/components/Text';
import Input, { InputWrapper } from 'common/components/Input';
import Icon from 'common/components/Icon';
import useFuse from 'common/hooks/useFuse';
import { includeEveryMatch } from 'common/components/Search/utils';
import takeSecondArg from 'common/helpers/takeSecondArg';
import { SYSTEM_COLUMNS } from '../../Panels/ResponsesPanel/constants';
import { projectGroupsStateSelector } from '../../../../../selectors/projects';

type FormProps = {
  data: null | VisibilitySettings,
  onChange: (data: VisibilitySettings) => void
};

type IField = {
  id: string | number,
  type: 'property' | 'block',
  title: string,
  code: string,
  groupId: number
};

const SYSTEM_GROUP_ID = -1;

const blocksSelectors: Function = createSelector(
  projectGroupsStateSelector,
  takeSecondArg,
  (state: ProjectGroupsState, intl) => {
    const initial = map(SYSTEM_COLUMNS, (fieldName): IField => ({
      id: fieldName,
      type: 'property',
      title: intl.formatMessage({
        id: `project.responsesPanel.table.${fieldName}`
      }),
      code: fieldName,
      groupId: SYSTEM_GROUP_ID
    }));

    return reduce(
      state.data,
      (result, group) => {
        const questions = map(group.questions, (question): IField => ({
          id: question.id,
          title: question.title,
          type: 'block',
          code: question.code,
          groupId: group.id
        }));

        return [...result, ...questions];
      },
      initial
    );
  }
);

const groupsSelectors: Function = createSelector(
  projectGroupsStateSelector,
  (state: ProjectGroupsState) => {
    const initial = [
      {
        id: SYSTEM_GROUP_ID,
        title: 'visibilitySettingsFormDictionary.systemFields.label'
      }
    ];

    return [
      ...initial,
      ...map(state.data, group => ({
        id: group.id,
        title: group.title
      }))
    ];
  }
);

const searchOptions = {
  useExtendedSearch: true,
  isCaseSensitive: false,
  findAllMatches: true,
  ignoreLocation: true,
  shouldSort: true,
  keys: ['title', 'code']
};

const VisibilitySettingsForm = ({ data, onChange }: FormProps): Node => {
  const intl = useIntl();

  const [query, set] = useState('');

  const groups: Array<{ id: number, title: string }> = useSelector(
    groupsSelectors,
    shallowEqual
  );

  const blocks: IField[] = useSelector(
    state => blocksSelectors(state, intl),
    shallowEqual
  );

  const search = useFuse(blocks, searchOptions);

  const items = useMemo(() => {
    if (query === null || query === '') {
      return blocks;
    }

    return search.search(includeEveryMatch(query)).map(i => i.item);
  }, [blocks, query, search]);

  const maxOrder = useMemo(() => max([0, ...map(data?.fields, i => i.order)]), [
    data
  ]);

  const changeField = useCallback(
    dataUpdate => {
      onChange(merge(data, { fields: dataUpdate }));
    },
    [data, onChange]
  );

  return (
    <>
      <InputWrapper icon="right" fluid>
        <Input onlyValue value={query} onChange={set} />
        <Icon icon="search" />
      </InputWrapper>
      <Table veryCompact celled definition size="small" basic>
        <Table.Head fullWidth>
          <Table.Row>
            <Table.Cell as="th" wide="one" />
            <Table.Cell as="th">
              <FormattedMessage id="visibilitySettingsForm.columns.title" />
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {map(groups, (group: { id: number, title: string }) => {
            const groupBlocks = filter(items, { groupId: group.id });

            if (groupBlocks.length === 0) return null;

            return (
              <Fragment key={`group-${group.id}`}>
                <Table.Row>
                  <Table.Cell colSpan={2}>
                    {group.id === SYSTEM_GROUP_ID ? (
                      <FormattedMessage id="visibilitySettingsFormDictionary.systemFields.label" />
                    ) : (
                      group.title
                    )}
                  </Table.Cell>
                </Table.Row>
                {map(groupBlocks, (block: IField) => {
                  const visible = data?.fields?.[block.id]?.isVisible || false;

                  return (
                    <Table.Row key={block.id}>
                      <Table.Cell>
                        <Checkbox
                          mame={block.id}
                          toggle
                          fitted
                          onChange={isVisible => {
                            changeField({
                              // $FlowIgnore
                              [block.id]: {
                                // $FlowIgnore
                                id: block.id,
                                type: block.type,
                                order: visible ? -999 : maxOrder + 1,
                                isVisible
                              }
                            });
                          }}
                          // $FlowIgnore
                          value={visible || false}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Text nonUI={false}>
                          {block.title}
                          {block.type === 'block' && (
                            <>
                              &nbsp;
                              <small>{`[${block.code}]`}</small>
                            </>
                          )}
                        </Text>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Fragment>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default VisibilitySettingsForm;
