/* eslint-disable react/jsx-props-no-spreading */
// @flow

import * as React from 'react';
import cx from 'classnames';

import type { ReactComponent } from 'flow-types/ReactComponent';
import type { NilValue } from 'flow-types/NilValue';

type Props = {
  nonUI?: boolean,
  styled?: boolean,
  fluid?: boolean,
  inverted?: boolean,
  className?: null | string,
  tagName?: NilValue | ReactComponent
};

export default function Accordion({
  tagName: Component,
  className,
  fluid,
  inverted,
  styled,
  nonUI,
  ...props
}: Props): React.Node {
  if (!Component) return null;

  return (
    <Component
      {...props}
      className={cx(
        { ui: !nonUI },
        { styled, fluid, inverted },
        className,
        'accordion'
      )}
    />
  );
}

type TitleProps = {
  active?: boolean,
  className?: null | string
};

export const Title = ({
  active,
  className,
  ...props
}: TitleProps): React.Node => (
  <div {...props} className={cx({ active }, className, 'title')} />
);

Title.defaultProps = {
  active: false,
  className: null
};

Accordion.defaultProps = {
  nonUI: false,
  fluid: false,
  styled: false,
  inverted: false,
  className: null,
  tagName: 'div'
};
