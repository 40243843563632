// @flow

import fp from 'lodash/fp';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import getTime from 'date-fns/getTime';
import type { ITest } from 'common/containers/TestsForm/flow';
import type { ILogicTest } from 'flow-types/entities/RawLogic';
import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import decamelizeKeys from '../../helpers/decamelizeKeys';
import { toBackendFormat } from '../logic/groupTestsIntoTestGroup';
import { toBackendFormat as denormalizeActionTargets } from './parseActionTargets';

const denormalizeLogicAction = action => {
  const { localId, targets, ...safeData } = action;

  const { groupsIds, questionsIds, optionsIds } = targets;

  return {
    targets: {
      ...(groupsIds && {
        groupsIds: {
          instanceTable: 'question_groups',
          ids: groupsIds
        }
      }),
      ...(questionsIds && {
        questionsIds: {
          instanceTable: 'questions',
          ids: denormalizeActionTargets(questionsIds, safeData.type)
        }
      }),
      ...(optionsIds && {
        optionsIds: {
          instanceTable: 'question_options',
          ids: optionsIds
        }
      })
    },
    ...safeData
  };
};

const denormalizeLogicTest = (test: ITest): ILogicTest => {
  const { id, source, options, value, ...safeData } = test;

  let normalizedValue = value;

  if (isDate(normalizedValue)) {
    normalizedValue = getTime(normalizedValue);
  }

  const next = {
    ...safeData,
    id,
    questionId: +source,
    ...(Array.isArray(options) && {
      options: options.map(opt => +opt)
    }),
    value: normalizedValue
  };

  if ([LOGIC_OPERATOR.IN, LOGIC_OPERATOR.NOT_IN].includes(next.operator)) {
    if (isNil(next.unionType)) {
      next.unionType = LOGIC_OPERAND.OR;
    }
  }

  return next;
};

const denormalizeLogicTests = fp.map(denormalizeLogicTest);

export const denormalizeLogicTestGroups = fp.map(testGroup => {
  const { localId, tests, testGroups, ...safeData } = testGroup;

  return {
    ...safeData,
    tests: denormalizeLogicTests(tests),
    testGroups: denormalizeLogicTestGroups(testGroups)
  };
});

const denormalizeLogicActions = fp.map(denormalizeLogicAction);

export default fp.compose(decamelizeKeys, logic => {
  const { actions, tests, ...safeData } = logic;

  const rootTestGroup = toBackendFormat(tests);

  return {
    ...safeData,
    testGroups: denormalizeLogicTestGroups([rootTestGroup]),
    actions: denormalizeLogicActions(actions)
  };
});
