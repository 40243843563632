import updateFilter from 'common/helpers/updateFilter';
import type { ResponsesMassActionsTasksState } from 'flow-types/states/ProjectsState/detail/ResponsesMassActions/ResponsesMassActionsTasksState';
import type { Reducer } from 'flow-types/Reducer';
import type { ResponsesMassActionsAction } from 'flow-types/actions/projects/detail/responsesMassActions';
import { TASK_STATUS } from 'common/helpers/response';

const initialState: ResponsesMassActionsTasksState = {
  data: [],
  status: 'idle',
  filter: {
    ps: 5,
    pn: 0,
    sort: '-created_at'
  },
  pagination: {
    activePage: 1,
    totalPages: 1,
    totalElements: 0
  },
  watched: []
};

// TODO: resolve later
const massActionsTasks: Reducer<
  ResponsesMassActionsTasksState,
  ResponsesMassActionsAction
> = (state: ResponsesMassActionsTasksState = initialState, action) => {
  switch (action.type) {
    case 'project-responses-mass-actions-tasks/fetch':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        }),
        status: 'loading'
      };

    case 'project-responses-mass-actions-tasks/fetch-fail':
      return {
        ...state,
        status: 'idle'
      };

    case 'project-responses-mass-actions-tasks/fetch-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        status: 'idle'
      };

    case 'project-responses-mass-actions-tasks/watch':
      return {
        ...state,
        watched: [...state.watched, action.taskId]
      };

    case 'project-responses-mass-actions-tasks/unwatch':
      return {
        ...state,
        watched: state.watched.filter(taskId => taskId !== action.taskId)
      };

    case 'project-responses-mass-actions-tasks/update-task-status':
      return {
        ...state,
        data: state.data.map(item =>
          item.id !== action.taskId
            ? item
            : {
                ...item,
                ...action.data,
                status: action.status
              }
        ),
        watched: [TASK_STATUS.READY, TASK_STATUS.ERROR].includes(action.status)
          ? state.watched.filter(taskId => taskId !== action.taskId)
          : state.watched
      };

    default:
      return state;
  }
};

export default massActionsTasks;
