// @flow

import type { StateSelector } from 'flow-types/Selector';
import { createSelector } from 'reselect';
import type { InterviewLogicState } from 'flow-types/states/InterviewState/InterviewLogicState';
import reduce from 'lodash/reduce';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import filter from 'lodash/filter';
import type { IQuestion } from 'flow-types/entities/Question';
import includes from 'lodash/includes';
import {
  interviewLogicStateSelector,
  interviewProjectDataSelector
} from './root';

export const interviewLogicSourceQuestionsSelector: StateSelector<> = createSelector(
  interviewLogicStateSelector,
  interviewProjectDataSelector,
  (logic: InterviewLogicState, project) => {
    const { sources } = logic;

    const questions = reduce(
      project.groups,
      (_questions, group: IQuestionGroup) => [
        ..._questions,
        ...(group.questions || [])
      ],
      []
    );

    return filter(questions, (question: IQuestion) =>
      includes(sources, question.id)
    );
  }
);

export const interviewLogicRulesSelector: StateSelector<> = createSelector(
  interviewLogicStateSelector,
  (logicState: InterviewLogicState) => logicState.data
);

export const interviewLogicResultsSelector: StateSelector<> = createSelector(
  interviewLogicStateSelector,
  logicState => {
    const { lastComputedResults } = logicState;
    return lastComputedResults;
  }
);
