// @flow
import { of, merge } from 'rxjs';
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import { ofType } from 'redux-observable';
import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import type {
  SaveQuestion,
  SaveQuestionFail,
  SaveQuestionSuccess
} from 'flow-types/actions/projects/detail/structure/questions/SaveQuestion';
import type {
  SaveGroup,
  SaveGroupFail,
  SaveGroupSuccess
} from 'flow-types/actions/projects/detail/structure/questionGroups/SaveQuestionGroup';
import {
  projectGroupsStateSelector,
  selectedGroupDataSelector,
  selectedQuestionDataSelector
} from '../../../selectors/projects';

const startOnSaveSuccess: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/start-on-save-success'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([{ payload }, state]) => {
      // получаем текущий видимый элемент

      const {
        visibleSelected
      }: ProjectGroupsState = projectGroupsStateSelector(state);

      let action: SaveQuestion | SaveGroup = null;
      let success:
        | $Shape<SaveQuestionSuccess>
        | $Shape<SaveGroupSuccess> = null;
      let fail: $Shape<SaveQuestionFail> | $Shape<SaveGroupFail> = null;

      if (visibleSelected === 'group') {
        const group = selectedGroupDataSelector(state);
        action = {
          type: 'project-groups/save-group',
          group
        };

        success = {
          type: 'project-groups/save-group-success',
          originalId: group.id
        };

        fail = {
          type: 'project-groups/save-group-fail',
          originalId: group.id
        };
      } else {
        const question = selectedQuestionDataSelector(state);

        action = {
          type: 'project-groups/save-question',
          question
        };

        success = {
          type: 'project-groups/save-question-success',
          originalId: question.id
        };

        fail = {
          type: 'project-groups/save-question-fail',
          originalId: question.id
        };
      }

      // создаём прослушки на успешное сохранение (использую уже существующие эпики)
      // и на неуспешное сохранение
      return merge(
        action$.pipe(
          ofType(success.type),
          RxO.filter(
            successAction =>
              successAction.originalId === success.originalId ||
              successAction.data.id === success.originalId
          ),
          RxO.map(() => ({ type: 'router/interviewPage', payload })),
          RxO.take(1),
          RxO.takeUntil(
            action$.pipe(
              ofType(fail.type),
              RxO.filter(
                failAction => failAction.originalId === fail.originalId
              )
            )
          )
        ),
        of(action)
      );
    })
  );

export default startOnSaveSuccess;
