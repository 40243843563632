export const removeRememberedToken = () => {
  localStorage.removeItem('authToken');
};

export const removeLocalToken = () => {
  sessionStorage.removeItem('authToken');
};

/**
 * Removes first priority token
 * @description For example we have remembered token and session token. The result of removeFirstPriorityToken() is session token being removed
 */
export const removeFirstPriorityToken = () => {
  if (sessionStorage.getItem('authToken')) {
    sessionStorage.removeItem('authToken');
  } else {
    localStorage.removeItem('authToken');
  }
};

const removeToken = () => {
  removeRememberedToken();
  removeLocalToken();
};

export default removeToken;
