// @flow

import moment from 'moment';
import { camelizeKeys } from 'humps';
import type { MeetingsAction } from './Meetings.action';
import type { MeetingsState } from './Meetings.state';

const initialState: MeetingsState = {
  meetings: [],
  filters: {},
  pagination: null,
  filter: {
    ids: null,
    search: null,
    companyId: null,
    departmentId: null,
    managerId: null,
    projectId: null,
    sourceId: null,
    status: null,
    format: 'web',
    sort: '-time_begin',
    startFrom: moment
      .utc()
      .day(1) // this monday by utc
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString(),
    startTo: moment
      .utc()
      .day(8) // this sunday by utc
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString(),
    respondentPhone: null
  },
  error: null,
  loading: false,
  calendarLoading: false,
  latestDataReceivedTime: null,
  showInCalendar: false,
  meetingCount: 0,
  newRecordGuid: null,
  events: [],
  currentEvent: null
};

export default (
  state: MeetingsState = initialState,
  action: MeetingsAction
): MeetingsState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'meetings/fetch-meetings':
      return {
        ...state,
        loading: true
      };
    case 'meetings/fetch-meetings-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'meetings/fetch-meetings-success':
      return {
        ...state,
        loading: false,
        meetings: action.data,
        error: null,
        latestDataReceivedTime: moment().toISOString()
      };
    case 'meetings/fetch-filters':
      return {
        ...state,
        loading: true
      };
    case 'meetings/fetch-filters-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'meetings/fetch-filters-success':
      return {
        ...state,
        loading: false,
        filters: {
          ...state.filters,
          ...camelizeKeys(action.data)
        },
        error: null
      };
    case 'meetings/select-company':
      return {
        ...state,
        filter: {
          ...state.filter,
          companyId: action.id,
          departmentId: null,
          managerId: null
        },
        showInCalendar: false
      };
    case 'meetings/select-department':
      return {
        ...state,
        filter: {
          ...state.filter,
          departmentId: action.id,
          managerId: null
        },
        showInCalendar: false
      };
    case 'meetings/select-manager':
      return {
        ...state,
        filter: {
          ...state.filter,
          managerId: action.id
        },
        showInCalendar: false
      };
    case 'meetings/select-project':
      return {
        ...state,
        filter: {
          ...state.filter,
          projectId: action.id
        },
        showInCalendar: false
      };
    case 'meetings/select-source':
      return {
        ...state,
        filter: {
          ...state.filter,
          sourceId: action.id
        },
        showInCalendar: false
      };
    case 'meetings/select-status':
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.id
        },
        showInCalendar: false
      };
    case 'meetings/set-filter': {
      const { filter } = action;
      // newFilter[action.name] = action.value;
      return {
        ...state,
        filter: {
          ...state.filter,
          ...filter
        },
        showInCalendar: false
      };
    }
    case 'meetings/set-show-in-calendar':
      return {
        ...state,
        showInCalendar: action.value
      };
    case 'meetings/set-calendar-loading':
      return {
        ...state,
        calendarLoading: action.value
      };
    case 'meetings/count-meetings':
      return {
        ...state,
        loading: true,
        filter: {
          ...state.filter,
          ...action.filter
        }
      };
    case 'meetings/count-meetings-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'meetings/count-meetings-success':
      return {
        ...state,
        // pagination: action.pagination,
        loading: false,
        meetingCount: action.data,
        error: null,
        latestDataReceivedTime: moment().toISOString()
      };
    case 'meetings/fetch-respondents':
      return {
        ...state,
        loading: true
      };
    case 'meetings/fetch-respondents-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'meetings/fetch-respondents-success':
      return {
        ...state,
        loading: false,
        error: null,
        filters: {
          ...state.filters,
          respondents: action.data
        }
      };
    case 'meetings/select-respondent-phone':
      return {
        ...state,
        filter: {
          ...state.filter,
          respondentPhone: action.value
        },
        showInCalendar: false
      };
    case 'meetings/hide-create-meeting':
      return {
        ...state,
        newRecordGuid: null
      };
    case 'meetings/post-meeting-success':
    case 'meetings/put-meeting-success':
      return {
        ...state,
        newRecordGuid: null,
        currentEvent: null
      };
    case 'meetings/add-meeting':
      return {
        ...state,
        newRecordGuid: action.guid
      };
    case 'meetings/post-meeting-fail':
    case 'meetings/put-meeting-fail':
      return {
        ...state,
        error: action.error
      };
    case 'meetings/set-current-event':
      return {
        ...state,
        currentEvent: action.event
      };
  }

  return state;
};
