import fp from 'lodash/fp';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import findLast from 'lodash/findLast';
import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import includes from 'lodash/includes';
import every from 'lodash/every';
import map from 'lodash/map';
import { v4 as uuid } from 'uuid';

import type { LogicJump } from 'flow-types/entities/Logic';
import type { IQuestion } from 'flow-types/entities/Question';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { ILogicResults } from 'flow-types/entities/LogicResults';
import type {
  IInterviewStructureElement,
  LocalId
} from 'flow-types/entities/InterviewStructureElement';

import { INTERVIEW_BLOCK_ROLES_IDS } from 'common/helpers/interview/constants';
import convertDurationObjectToSeconds from 'common/helpers/convertDurationObjectToSeconds';
import { TYPES } from '../question';
import { getItemsBefore, getItemsFrom } from '../list';

const flattenItemsIds = fp.compose(
  fp.uniq,
  fp.flatMapDeep((item: IInterviewStructureElement) => {
    if (Array.isArray(item.items) && item.items.length > 0) {
      return flattenItemsIds(item.items);
    }

    return item.parentQuestionId
      ? [item.parentQuestionId, item.questionId]
      : item.questionId;
  })
);

export function transformQuestionValidation(question: IQuestion) {
  const {
    required,
    maxAnswers,
    maxFiles,
    minFiles,
    maxValue,
    minValue,
    isInterval,
    recommendedTime,
    dateOnly,
    filesEnabled
  } = question;

  return {
    required,
    filesEnabled,
    maxFiles,
    minFiles,
    maxAnswers,
    maxValue,
    minValue,
    isInterval,
    recommendedTime,
    dateOnly
  };
}

export function transformQuestionToStructureElement(
  question: IQuestion & {|
    +insideSinglePage?: boolean,
    +path: Array<LocalId>,
    +postPollingAfter: boolean,
    +postPollingBefore: boolean,
    +groupTitle: string
  |}
): IInterviewStructureElement {
  const {
    id,
    title,
    groupTitle,
    questionGroupId,
    type,
    otherEnabled,
    postPollingFinish,
    postPollingDefault,
    postPollingBefore,
    postPollingAfter,
    subQuestions,
    showTitle,
    settings,
    path,
    insideSinglePage,
    disabled,
    assets
  } = question;

  // this data will be added to each stack generated here
  const commonMetaInfo = {
    groupTitle,
    postPollingFinish,
    postPollingDefault,
    postPollingBefore,
    postPollingAfter,
    insideSinglePage,
    disabled,
    assets
  };

  if (type === TYPES.Table && settings.viewType === 'single') {
    return map(subQuestions, item => {
      const itemEl = transformQuestionToStructureElement({
        ...item,
        path,
        questionGroupId
      });

      return {
        ...itemEl,
        ...commonMetaInfo,
        parentQuestionId: id,
        parentQuestionTitle: title
      };
    });
  }

  const localId = uuid();

  let el = {
    localId,
    questionId: id,
    questionTitle: title,
    groupTitle,
    groupId: questionGroupId,
    questionType: type,
    path: [...path, localId],
    otherEnabled,
    rules: transformQuestionValidation(question),
    ...commonMetaInfo,
    showTitle: showTitle && !postPollingAfter && !postPollingBefore,
    stackRoleId: INTERVIEW_BLOCK_ROLES_IDS.BLOCK
  };

  // Далее начинается код,
  // который нужно будет поправить с учётом того,
  // что в начальной и конечной группах пост-опроса
  // может быть более 1-2 блоков.
  // В общем, цель сделать код более генеративным.
  // начало кода:

  if (postPollingBefore) {
    el.stackRoleId = INTERVIEW_BLOCK_ROLES_IDS.STARTING;
  }

  if (postPollingFinish) {
    el.stackRoleId = INTERVIEW_BLOCK_ROLES_IDS.FINISHING;
  }

  if (postPollingAfter && !postPollingFinish) {
    el.stackRoleId = INTERVIEW_BLOCK_ROLES_IDS.ENDING;
  }

  // конец.

  const items =
    type === TYPES.Table
      ? map(subQuestions, item => {
          const itemEl = transformQuestionToStructureElement({
            ...item,
            postPollingFinish,
            postPollingDefault,
            postPollingBefore,
            postPollingAfter,
            path: el.path,
            showTitle: showTitle && !postPollingAfter && !postPollingBefore,
            questionGroupId: el.groupId
          });

          return {
            ...itemEl,
            ...commonMetaInfo,
            parentQuestionId: id,
            parentQuestionTitle: title
          };
        })
      : null;

  const restrictions = insideSinglePage
    ? null
    : {
        movement: settings?.movementRestriction
          ? {
              enabled: settings.movementRestriction.enabled,
              duration: convertDurationObjectToSeconds(
                settings.movementRestriction.duration
              )
            }
          : null
      };

  el = {
    ...el,
    questionsIds: flattenItemsIds(items),
    restrictions,
    items
  };

  return el;
}

// transforms IQuestion to IInterviewStructureElement
export function transformQuestionsToStructureElements(
  questions: IQuestion[],
  extraData: Object
) {
  return flattenDeep(
    map(questions, item =>
      transformQuestionToStructureElement({
        ...item,
        ...extraData
      })
    )
  );
}

// transforms IQuestionGroup to IInterviewStructureElement
export function transformGroupToStructureElement(
  group: IQuestionGroup
): IInterviewStructureElement {
  const {
    id,
    isSinglePage,
    questions,
    postPollingBefore,
    postPollingAfter,
    title: groupTitle,
    disabled
  } = group;

  if (isSinglePage) {
    const localId = uuid();

    const items = transformQuestionsToStructureElements(questions, {
      insideSinglePage: true,
      path: [localId],
      groupTitle,
      postPollingBefore,
      postPollingAfter,
      ...(disabled && {
        disabled
      })
    });

    return {
      localId,
      groupId: id,
      groupTitle,
      isSinglePage: true,
      postPollingBefore,
      postPollingAfter,
      path: [localId],
      assets: [],
      questionsIds: flattenItemsIds(items),
      items
    };
  }

  return transformQuestionsToStructureElements(questions, {
    path: [],
    groupTitle,
    postPollingBefore,
    postPollingAfter,
    insideSinglePage: false,
    ...(disabled && {
      disabled
    })
  });
}

export const nextStructureConstructor = (
  groups: IQuestionGroup[]
): IInterviewStructureElement[] =>
  // force upcasting to IInterviewStructureElement[]
  ((reduce(
    groups,
    (_structure, group) => {
      const el = transformGroupToStructureElement(group);
      if (Array.isArray(el)) {
        return [..._structure, ...el];
      }
      return [..._structure, el];
    },
    []
  ): any): IInterviewStructureElement[]);

function logicResultsForQuestionApplier(
  structureElement: IInterviewStructureElement,
  results: ILogicResults
) {
  const {
    hiddenGroups,
    hiddenQuestions,
    disabledGroups,
    disabledQuestions,
    requiredQuestions
  } = results;

  const { rules, questionId, parentQuestionId, groupId } = structureElement;

  let nextItems = null;

  let isRequired =
    !!rules.required ||
    includes(requiredQuestions, questionId) ||
    includes(parentQuestionId);

  let isDisabled =
    includes(disabledGroups, groupId) ||
    includes(disabledQuestions, questionId) ||
    includes(disabledQuestions, parentQuestionId);

  let isHidden =
    includes(hiddenGroups, groupId) ||
    includes(hiddenQuestions, questionId) ||
    includes(hiddenQuestions, parentQuestionId);

  if (structureElement.items) {
    nextItems = map(
      structureElement.items,
      (item: IInterviewStructureElement) =>
        logicResultsForQuestionApplier(item, results)
    );

    const isEveryItemHidden = every(
      structureElement.items,
      item => !!item.hidden
    );

    const isEveryItemDisabled = every(
      structureElement.items,
      item => !!item.disabled
    );

    isHidden = isHidden || isEveryItemHidden || !!structureElement.hidden;

    isDisabled =
      isDisabled || isEveryItemDisabled || !!structureElement.disabled;
  }

  // TODO: maybe it is not required to set required to false
  //  when stack is hidden or disabled
  isRequired = !isHidden && !isDisabled && isRequired;

  return {
    ...structureElement,
    ...(structureElement.items && {
      items: nextItems
    }),
    hidden: isHidden,
    disabled: isDisabled,
    rules: { ...rules, required: isRequired }
  };
}

// group is hidden when is is hidden
// group is hidden when every question inside is hidden
//
// group is disabled when it is disabled
// group is disabled when every question inside is disabled
function logicResultsForGroupsApplier(
  structureElement: IInterviewStructureElement,
  results: ILogicResults
) {
  const { hiddenGroups, disabledGroups } = results;

  const isHidden = includes(hiddenGroups, structureElement.groupId);

  const isDisabled = includes(disabledGroups, structureElement.groupId);

  const items = map(structureElement.items, item =>
    logicResultsForQuestionApplier(item, results)
  );

  const isEveryItemHidden = every(items, item => !!item.hidden);

  const isEveryItemDisabled = every(items, item => !!item.disabled);

  return {
    ...structureElement,
    items,
    hidden: isHidden || isEveryItemHidden || !!structureElement.hidden,
    disabled: isDisabled || isEveryItemDisabled || !!structureElement.disabled
  };
}

export function logicResultsToStructureApplier(structure, logicResults = {}) {
  return reduce(
    structure,
    (_structure, el: IInterviewStructureElement) => {
      const { groupId, questionId, items } = el;

      // non single group case
      if (questionId) {
        return [
          ..._structure,
          logicResultsForQuestionApplier(el, logicResults)
        ];
      }

      // single group case
      if (groupId && items) {
        return [..._structure, logicResultsForGroupsApplier(el, logicResults)];
      }

      return [..._structure, el];
    },
    []
  );
}

// defines flow between the elements
export function structureElementsFlowConstructor(
  structure: IInterviewStructureElement[]
) {
  const elCount = size(structure);
  return reduce(
    structure,
    (_structure, element, index) => {
      const isFirst = index === 0;
      const isLast = index === elCount - 1;
      let extData = {};

      if (isFirst) {
        extData = {
          ...extData,
          prev: null
        };
      } else {
        let prev = structure[index - 1];

        if (prev.hidden) {
          prev = findLast(
            getItemsBefore(structure, index),
            item => !item.hidden
          );
        }

        if (!prev) {
          prev = null;
        }

        extData = {
          ...extData,
          prev
        };
      }

      if (isLast) {
        extData = {
          ...extData,
          next: -1
        };
      } else {
        let next = structure[index + 1];

        if (next.hidden) {
          next = find(getItemsFrom(structure, index), item => !item.hidden);
        }

        if (!next) {
          next = -1;
        }

        extData = {
          ...extData,
          next
        };
      }

      return [
        ..._structure,
        {
          ...element,
          ...extData
        }
      ];
    },
    []
  );
}

// TODO: is not used currently, may be it would be removed
export function structureElementsTransitionApplier(
  structure: Array<IInterviewStructureElement>,
  transitions: Array<LogicJump> = []
) {
  return reduce(
    transitions,
    (_structure, transition: LogicJump) => {
      const [from, to] = transition;

      // eslint-disable-next-line eqeqeq
      // const sourceElementIndex = _structure.findIndex(elem => elem.id == from);
      const sourceElementIndex = _structure.findIndex(
        (elem: IInterviewStructureElement) => {
          const { questionId, items, isSinglePage } = elem;

          if (!isSinglePage) {
            // eslint-disable-next-line eqeqeq
            return questionId == from;
          }

          return some(
            items,
            // eslint-disable-next-line eqeqeq
            (item: IInterviewStructureElement) => item.questionId == from
          );
        }
      );

      // eslint-disable-next-line eqeqeq
      const targetElementIndex = _structure.findIndex(
        (elem: IInterviewStructureElement) => {
          const { questionId, items, isSinglePage } = elem;

          if (!isSinglePage) {
            // eslint-disable-next-line eqeqeq
            return questionId == to;
          }

          return some(
            items,
            // eslint-disable-next-line eqeqeq
            (item: IInterviewStructureElement) => item.questionId == to
          );
        }
      );

      // we cannot move to hidden places
      if (sourceElementIndex !== -1 && targetElementIndex !== -1) {
        const nextStructure = [..._structure];

        if (
          !nextStructure[targetElementIndex].hidden &&
          !nextStructure[sourceElementIndex].hidden
        ) {
          nextStructure[sourceElementIndex] = {
            ...nextStructure[sourceElementIndex],
            next: nextStructure[targetElementIndex]
          };
          nextStructure[targetElementIndex] = {
            ...nextStructure[targetElementIndex],
            prev: nextStructure[sourceElementIndex]
          };
        }

        return nextStructure;
      }

      return _structure;
    },
    [...structure]
  );
}
