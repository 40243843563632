// @flow

/* eslint-disable no-unused-vars,react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import cx from 'classnames';

import isDevelopment from 'utils/isDevelopment';

import type { ReactChildren } from 'flow-types/ReactChildren';
import type { FomanticSize } from 'flow-types/FomanticSize';
import type { ReactComponent } from 'flow-types/ReactComponent';

export type Props = {
  +id?: null | string,
  +counter?: string,
  +attached?: boolean,
  +tertiary?: boolean,
  +type?: null | string,
  +size?: FomanticSize,
  +inverted?: boolean,
  +color?: ?string,
  +buttonType?:
    | null
    | 'positive'
    | 'negative'
    | 'primary'
    | 'secondary'
    | 'basic'
    | 'tertiary',
  +toggle?: boolean,
  +vertical?: boolean,
  +animated?: boolean,
  +effect?: 'fade' | string,
  +side?: 'right' | 'left' | 'top' | 'bottom',
  +labeled?: boolean,
  +active?: boolean,
  +disabled?: boolean,
  +loading?: boolean,
  +icon?: boolean,
  +floated?: 'right' | 'left' | string,
  +compact?: boolean,
  +fluid?: boolean,
  +group?: boolean,
  +pointing?: boolean,
  +children?: ReactChildren,
  +onClick?: Function,
  +tagName?: ReactComponent,
  +style?: Object,
  +dropdown?: boolean,
  +floating?: boolean,
  +noClickEvent?: boolean,
  +circular?: boolean,
  +className?: string
};

const Button: React.AbstractComponent<Props, any> = React.forwardRef(
  (
    {
      className,
      disabled,
      inverted,
      color,
      loading,
      onClick,
      children,
      toggle,
      active,
      animated,
      attached,
      buttonType,
      compact,
      tagName,
      counter,
      dropdown,
      effect,
      floated,
      floating,
      fluid,
      group,
      icon,
      labeled,
      noClickEvent,
      pointing,
      side,
      size,
      style,
      tertiary,
      vertical,
      circular,
      ...props
    }: Props,
    ref
  ) => {
    if (isDevelopment) {
      if (!props.type) {
        // eslint-disable-next-line no-console
        console.warn(
          "It's recommended to explicitly set type prop for Button component"
        );
      }
    }

    let floatedClassName: string | null = null;

    if (typeof floated === 'string') {
      floatedClassName = `${floated} floated`;
    } else if (floated) {
      floatedClassName = 'floated';
    }

    const classNames = cx(
      'ui',
      size,
      color,
      buttonType,
      effect,
      side,
      {
        labeled,
        circular,
        tertiary,
        compact,
        attached,
        inverted,
        toggle,
        active,
        disabled,
        loading,
        animated
      },
      floatedClassName,
      {
        icon,
        fluid,
        dropdown,
        pointing,
        floating,
        button: !group,
        buttons: group
      },
      className
    );

    const handleClick = React.useMemo(() => {
      if (noClickEvent) {
        return null;
      }

      return (e: Event) => {
        if (onClick) {
          onClick(e);
        }
      };
    }, [noClickEvent, onClick]);

    let Component = group ? 'div' : 'button';

    if (tagName) {
      Component = tagName;
    }

    return (
      <Component
        {...props}
        disabled={disabled}
        onClick={handleClick}
        className={classNames}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

// $FlowIgnore
Button.defaultProps = {
  tagName: 'button',
  style: null,
  counter: null,
  attached: false,
  tertiary: false,
  size: null,
  inverted: null,
  color: null,
  buttonType: null,
  toggle: null,
  vertical: false,
  animated: false,
  effect: null,
  side: null,
  labeled: false,
  active: false,
  disabled: false,
  loading: false,
  icon: false,
  floated: false,
  compact: false,
  fluid: false,
  group: false,
  pointing: false,
  children: null,
  onClick: null,
  dropdown: false,
  floating: false,
  circular: false,
  noClickEvent: false,
  className: '',
  type: null,
  id: null
};

type ButtonsProps = {
  className?: null | string,
  wide?: null | string
};

export const Buttons = ({
  className,
  wide,
  ...props
}: ButtonsProps): React.Node => {
  const classNames = cx(className, wide);

  return <Button {...props} className={classNames} group tagName="div" />;
};

Buttons.defaultProps = {
  className: null,
  wide: null
};

if (isDevelopment) {
  // $FlowIgnore
  Button.whyDidYouRender = false;
}

export default Button;
