import format from 'date-fns/format';
import { v4 as uuid } from 'uuid';

const createInterviewLocalData = () => {
  const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  return {
    localId: uuid(),
    dateCreated: timestamp
  };
};

export default createInterviewLocalData;
