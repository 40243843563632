// @flow
import * as React from 'react';
import FormikField from 'common/components/Formik/Field';
import Field from 'common/components/Form/Field';
import Label from 'common/components/Label';
import { InputWrapper } from '../Input';
import { getErrorConfig } from './getErrorConfig';

type FormikInputProps = {
  id?: string,
  onChange?: Function | null,
  onBlur?: Function | null,
  onFocus?: Function | null,
  name: string,
  label?: string | null | React.Node,
  inputLabel?: boolean,
  errorPrompt?: boolean,
  fast?: boolean,
  disabled?: boolean
};

const Textarea: React.AbstractComponent<
  FormikInputProps,
  mixed
> = React.forwardRef(
  (
    {
      id,
      onChange: controlledOnChange,
      onBlur: controlledOnBlur,
      onFocus: controlledOnFocus,
      name,
      validate,
      label,
      inputLabel,
      errorPrompt,
      fast,
      disabled,
      ...props
    }: Object,
    ref
  ): React.Node => {
    const fieldLabelId = (id && label && `${id}-field-label`) || undefined;
    const inputLabelId = (id && inputLabel && `${id}-input-label`) || undefined;

    const fieldLabel = label && (
      <Label nonUI id={fieldLabelId} htmlFor={id}>
        {label}
      </Label>
    );

    const errorLabel = meta => {
      const errorConfig = getErrorConfig(meta, errorPrompt);

      return (
        <Label
          id={id ? `${id}-error-message` : undefined}
          {...errorConfig}
          role="alert"
          aria-atomic
        />
      );
    };

    const errorLabelBefore = (meta: Object) =>
      (getErrorConfig(meta, errorPrompt)?.pointing === 'below' ||
        getErrorConfig(meta, errorPrompt)?.pointing === 'right') &&
      errorLabel(meta);

    const errorLabelAfter = (meta: Object) =>
      (getErrorConfig(meta, errorPrompt)?.pointing === 'above' ||
        getErrorConfig(meta, errorPrompt)?.pointing === 'left') &&
      errorLabel(meta);

    return (
      <FormikField name={name} validate={validate} fast={fast}>
        {({ field, meta, form }) => {
          const isDisabled =
            form.isSubmitting ||
            ['deleting', 'saving'].includes(form.status) ||
            disabled;

          return (
            <Field
              validationState={meta.touched && !!meta.error ? 'error' : null}
            >
              {fieldLabel}
              {errorLabelBefore(meta)}
              <InputWrapper>
                <textarea
                  {...props}
                  disabled={isDisabled}
                  id={id}
                  ref={ref}
                  name={name}
                  value={field.value}
                  onChange={(event, data) => {
                    field.onChange(event);
                    if (!controlledOnChange) return;
                    controlledOnChange(event, data);
                  }}
                  onFocus={(event: FocusEvent) => {
                    if (controlledOnFocus) {
                      controlledOnFocus(event);
                    }
                    // TODO: check whether it exists in Formik
                    field.onFocus?.(event);
                  }}
                  onBlur={(event: FocusEvent) => {
                    if (event.relatedTarget instanceof Element) {
                      /*
                        Skip validation onBlur when reset / submit button is clicked or
                        It will block reset / submit button onClick event
                      */
                      // if (
                      //   event.relatedTarget.id === RESET_BUTTON_ID ||
                      //   event.relatedTarget.id === SUBMIT_BUTTON_ID
                      // ) {
                      //   return;
                      // }
                    }
                    if (controlledOnBlur) {
                      controlledOnBlur(event);
                    }
                    field.onBlur(event);
                  }}
                  aria-describedby={id && !!meta.error ? `` : null}
                  aria-invalid={meta.error ? true : undefined}
                  aria-labelledby={`${fieldLabelId ?? ''} ${inputLabelId ??
                    ''}`}
                />
              </InputWrapper>
              {errorLabelAfter(meta)}
            </Field>
          );
        }}
      </FormikField>
    );
  }
);

// $FlowIgnore
Textarea.defaultProps = {
  id: null,
  onChange: null,
  onBlur: null,
  onFocus: null,
  label: null,
  inputLabel: false,
  errorPrompt: false,
  fast: false,
  disabled: false
};

export default Textarea;
