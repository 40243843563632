// @flow

import type { Reducer } from 'flow-types/Reducer';
import type { PivotTablesAction } from 'flow-types/actions/projects/detail/pivotTables';
import type { ResetProject } from 'flow-types/actions/projects/detail/project/ResetProject';

export type PivotTableState = {|
  data: Object[],
  loadingTables: boolean,
  loadingStatistic: boolean,
  loadingOptions: boolean,
  pagination: Object,
  statistic: Object,
  options: Object[]
|};

const initialState: PivotTableState = {
  // TODO: better naming for data or another reducer for statistic
  data: [],
  loadingTables: false,
  loadingStatistic: false,
  loadingOptions: false,
  pagination: {},
  statistic: {},
  options: []
};

const pivotTables: Reducer<
  PivotTableState,
  PivotTablesAction | ResetProject
> = (
  state = initialState,
  // TODO: describe actions right after I back from vacation
  action
) => {
  switch (action.type) {
    // TODO: we should better organize projects state by their ids
    // This action is called when project page is gone.
    // It has not relation to a page that displays projects.
    case 'project/reset':
      return initialState;

    case 'pivot-table/fetch-request':
      return {
        ...state,
        loadingTables: true
      };

    case 'pivot-table/fetch-success':
      return {
        ...state,
        loadingTables: false,
        data: action.data,
        pagination: action.pagination
      };

    case 'pivot-table/fetch-failure':
      return {
        ...state,
        loadingTables: false
      };

    case 'pivot-table/fetch-statistic-request':
      return {
        ...state,
        // TODO: should be separated by table ID and each table should have own loading state
        loadingStatistic: true
      };

    case 'pivot-table/fetch-statistic-success':
      return {
        ...state,
        loadingStatistic: false,
        statistic: {
          ...state.statistic,
          [action.tableId]: action.data
        }
      };

    case 'pivot-table/fetch-statistic-failure':
      return {
        ...state,
        loadingStatistic: false
      };

    case 'pivot-table/fetch-available-fields-request':
      return {
        ...state,
        loadingOptions: true
      };

    case 'pivot-table/fetch-available-fields-success':
      return {
        ...state,
        options: action.data,
        loadingOptions: false
      };

    case 'pivot-table/fetch-available-fields-failure':
      return {
        ...state,
        loadingOptions: false
      };

    default:
      return state;
  }
};

export default pivotTables;
