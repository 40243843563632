// @flow

import arrayMove from 'array-move';
import { recalculateOrder } from 'common/transducers/sortingItemsNormalizer';

type MoveArrayAndReorder = <T>(
  list: T[],
  sourceIndex: number,
  targetIndex: number
) => T[];

const moveListAndReorder: MoveArrayAndReorder = <T>(
  list: Array<T>,
  sourceIndex: number,
  targetIndex: number
): Array<T> => {
  const reordered = arrayMove(list, sourceIndex, targetIndex);

  return recalculateOrder(reordered);
};

export const moveListAndReorderMutable: MoveArrayAndReorder = <T>(
  list: Array<T>,
  sourceIndex: number,
  targetIndex: number
): Array<T> => {
  arrayMove.mutate(list, sourceIndex, targetIndex);

  return recalculateOrder(list);
};

type MoveFromListToListAndReorderFn$Params = {
  source: any[],
  destination: any[],
  from: number,
  to: number
};

type MoveFromListToListAndReorderFn = (
  params: MoveFromListToListAndReorderFn$Params,
  mutable?: boolean
) => [mixed[], mixed[], mixed];

const initialParams: MoveFromListToListAndReorderFn$Params = {};

export const moveFromListToList: MoveFromListToListAndReorderFn = (
  params = initialParams,
  mutable = false
) => {
  const { source, destination, from, to } = params;

  const nextSource = !mutable ? [...source] : source;

  const [draggedItem] = nextSource.splice(from, 1);

  const nextDestination = !mutable ? [...destination] : destination;

  nextDestination.splice(to, 0, draggedItem);

  return [nextSource, nextDestination, draggedItem];
};

export default moveListAndReorder;
