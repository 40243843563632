import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { Field } from 'common/containers/Form';
import Dropdown from 'common/components/Dropdown';
import Input from 'common/components/Input';

type Props = {|
  value: string,
  type: number,
  disabled: boolean,
  onTypeChange: Function,
  onValueChange: Function,
  typeName?: null | string,
  valueName?: null | string
|};

const PostPollingDelay = ({
  disabled,
  value,
  type,
  onTypeChange,
  onValueChange,
  typeName,
  valueName
}: Props) => {
  const intl = useIntl();

  const options = useMemo(
    () => [
      {
        value: -1,
        label: intl.formatMessage({
          id: 'project.form.post_polling_delay.type.noDelay'
        })
      },
      {
        value: 1,
        label: intl.formatMessage({
          id: 'project.form.post_polling_delay.type.delay'
        })
      },
      {
        value: 2,
        label: intl.formatMessage({
          id: 'project.form.post_polling_delay.type.nextDay'
        })
      },
      {
        value: 3,
        label: intl.formatMessage({
          id: 'project.form.post_polling_delay.type.nextWorkingDay'
        })
      }
    ],
    [intl]
  );

  return (
    <Field group wide="two">
      <Field name={typeName}>
        <Dropdown
          disabled={disabled}
          options={options}
          value={isNil(type) ? -1 : type}
          onlyValue
          onChange={nextType => {
            let nextValue = nextType;

            if (nextValue === -1 || nextValue === '-1') {
              nextValue = null;
            }

            onTypeChange(nextValue === null ? nextValue : +nextValue);
          }}
        />
      </Field>
      <Field name={valueName}>
        <Input
          type="time"
          value={value}
          step="1"
          onlyValue
          disabled={disabled}
          onChange={onValueChange}
        />
      </Field>
    </Field>
  );
};

PostPollingDelay.defaultProps = {
  valueName: null,
  typeName: null
};

export default PostPollingDelay;
