/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Content from 'common/components/Content';
import { Field } from 'common/components/Form';
import Checkbox from 'common/components/Checkbox';

import { OtherOption as OtherOptionWrapper } from '../../styled';
import Input from '../../../Input';
import ListItem from '../../../ListItem';

type Props = {|
  onSelect: Function,
  onChange: Function,
  selected: boolean,
  disabled: boolean,
  title: string,
  data: string,
  type: 'radio' | 'checkbox',
  index: number
|};

const OtherOption = ({
  onSelect,
  onChange,
  disabled,
  selected,
  data,
  title,
  type,
  index
}: Props) => {
  const intl = useIntl();

  const handleSelect = useCallback(() => {
    if (!onSelect) return;

    onSelect('other');
  }, [onSelect]);

  const handleChange = useCallback(
    otherTitle => {
      if (!onChange) return;

      onChange(otherTitle);

      if (selected && (otherTitle === null || otherTitle === '')) {
        handleSelect();
      }
    },
    [handleSelect, onChange, selected]
  );

  return (
    <ListItem as={OtherOptionWrapper}>
      <Content>
        <Field>
          <Checkbox
            rawOutput
            radio={type === 'radio'}
            tabIndex={index}
            disabled={disabled}
            name={
              type === 'radio'
                ? `question-${data.questionId}`
                : `question-${data.questionId}-option-${data.id}`
            }
            data-option-id="other"
            onChange={handleSelect}
            value={selected}
          >
            {title}
          </Checkbox>
          <Input
            fluid
            disabled={disabled || !selected}
            placeholder={intl.formatMessage({
              id: 'questionForm.fields.otherOption.placeholder'
            })}
            value={data}
            onlyValue
            onChange={handleChange}
          />
        </Field>
      </Content>
    </ListItem>
  );
};

export default OtherOption;
