/* eslint-disable react/jsx-props-no-spreading */
// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import range from 'lodash/range';

import Segment from 'common/components/Segment';
import Header from 'common/components/Header';
import Icon from 'common/components/Icon';

import type { FomanticWide } from 'flow-types/FomanticWide';

const StyledPlaceholderCell = styled.td`
  .ui.ui.ui.placeholder.segment {
    min-height: 9rem;
  }
`;

type TableHeaderAndFooterCommonProps = {
  fullWidth?: boolean,
  className?: null | string
};

export const TableHead = ({
  fullWidth,
  className,
  ...props
}: TableHeaderAndFooterCommonProps): React.Node => (
  <thead {...props} className={cx(className, { 'full-width': fullWidth })} />
);

TableHead.defaultProps = {
  fullWidth: false,
  className: null
};

export const EmptyTableListPlaceholder = ({
  iconName = 'frown outline',
  columnsCount
}: {
  iconName?: string,
  columnsCount: number
}): React.Node => (
  <TableRow>
    <TableCell as={StyledPlaceholderCell} colSpan={columnsCount}>
      <Segment placeholder fitted basic>
        <Header icon>
          <Icon className={iconName} />
          <FormattedMessage id="common.labels.no_items" />
        </Header>
      </Segment>
    </TableCell>
  </TableRow>
);

EmptyTableListPlaceholder.defaultProps = {
  iconName: 'frown outline'
};

export const TableBody = (props: Object): React.Node => <tbody {...props} />;

export const TableFooter = ({
  fullWidth,
  className,
  ...props
}: TableHeaderAndFooterCommonProps): React.Node => (
  <tfoot {...props} className={cx(className, { 'full width': fullWidth })} />
);

TableFooter.defaultProps = {
  fullWidth: false,
  className: null
};

type TableRowProps = {
  positive?: boolean,
  negative?: boolean,
  error?: boolean,
  warning?: boolean,
  active?: boolean,
  disabled?: boolean,
  color?: string,
  aligned?: 'left' | 'right' | 'top' | 'bottom' | 'center',
  className?: string
};

export const TableRow = ({
  positive,
  negative,
  error,
  warning,
  active,
  disabled,
  color,
  aligned,
  className,
  ...props
}: TableRowProps): React.Node => (
  <tr
    {...props}
    className={cx(
      color,
      className,
      {
        positive,
        negative,
        error,
        warning,
        active,
        disabled
      },
      aligned ? `${aligned} aligned` : null
    )}
  />
);

TableRow.defaultProps = {
  positive: false,
  negative: false,
  error: false,
  warning: false,
  active: false,
  disabled: false,
  color: null,
  aligned: false,
  className: null
};

type TableCellProps = {
  selectable?: boolean,
  as?: 'td' | 'th',
  collapsing?: boolean,
  singleLine?: boolean,
  wide?: FomanticWide,
  positive?: boolean,
  negative?: boolean,
  error?: boolean,
  warning?: boolean,
  active?: boolean,
  disabled?: boolean,
  color?: string,
  aligned?: 'left' | 'right' | 'top' | 'bottom' | 'center',
  className?: string
};

export const TableCell: React.AbstractComponent<
  TableCellProps,
  any
> = React.forwardRef(
  (
    {
      positive,
      negative,
      error,
      warning,
      active,
      disabled,
      color,
      selectable,
      collapsing,
      wide,
      className,
      singleLine,
      as,
      aligned,
      ...props
    }: TableCellProps,
    ref
  ): React.Node => {
    const Component = as || 'td';

    return (
      <Component
        {...props}
        ref={ref}
        className={cx(
          color,
          className,
          typeof wide === 'string' ? [`${wide} wide`] : null,
          {
            positive,
            negative,
            error,
            warning,
            active,
            disabled,
            selectable,
            collapsing,
            'single line': singleLine
          },
          {
            left: aligned === 'left',
            right: aligned === 'right',
            center: aligned === 'center',
            aligned
          }
        )}
      />
    );
  }
);

TableCell.displayName = 'TableCell.ForwardRef';

// $FlowIgnore
TableCell.defaultProps = {
  positive: false,
  negative: false,
  error: false,
  warning: false,
  active: false,
  disabled: false,
  color: null,
  aligned: false,
  selectable: false,
  as: 'td',
  collapsing: false,
  singleLine: false,
  wide: null,
  className: null
};

type TableProps = {
  className?: null | string,
  celled?: boolean,
  compact?: boolean,
  veryCompact?: boolean,
  definition?: boolean,
  structured?: boolean,
  stackable?: 'tablet' | boolean,
  unstackable?: boolean,
  singleLine?: boolean,
  selectable?: boolean,
  inverted?: boolean,
  fixed?: boolean,
  striped?: boolean,
  collapsing?: boolean,
  basic?: boolean,
  veryBasic?: boolean,
  sortable?: boolean,
  padded?: boolean,
  veryPadded?: boolean,
  wide?: null | string,
  color?: null | string,
  size?: null | string
};

const Table = ({
  className,
  celled,
  definition,
  compact,
  structured,
  stackable,
  unstackable,
  collapsing,
  singleLine,
  inverted,
  fixed,
  selectable,
  striped,
  basic,
  veryBasic,
  wide,
  color,
  sortable,
  padded,
  veryPadded,
  veryCompact,
  size,
  ...props
}: TableProps): React.Node => (
  <table
    {...props}
    className={cx(
      'ui',
      className,
      color,
      size,
      {
        padded,
        'very padded': veryPadded,
        inverted,
        ...(typeof wide === 'string' && {
          [`${wide} column`]: !!wide
        }),
        fixed,
        'single line': singleLine,
        celled,
        definition,
        compact,
        'very compact': veryCompact,
        structured,
        selectable,
        sortable,
        ...(typeof stackable === 'string' && {
          [`${stackable}`]: !!stackable
        }),
        collapsing,
        stackable,
        unstackable,
        striped,
        basic,
        'very basic': veryBasic
      },
      'table'
    )}
  />
);

Table.defaultProps = {
  className: null,
  wide: null,
  color: null,
  size: null,
  celled: false,
  compact: false,
  veryCompact: false,
  definition: false,
  structured: false,
  stackable: false,
  unstackable: false,
  singleLine: false,
  selectable: false,
  inverted: false,
  fixed: false,
  striped: false,
  collapsing: false,
  basic: false,
  veryBasic: false,
  sortable: false,
  padded: false,
  veryPadded: false
};

export const TableLoadingPlaceholder = ({
  cellsPerRowCount
}: {
  cellsPerRowCount: number
}): React.Node => (
  <>
    {[1, 2, 3].map(rowId => (
      <TableRow key={rowId}>
        {range(cellsPerRowCount).map(cellId => (
          <TableCell key={cellId}>
            <div className="ui placeholder">
              <div className="paragraph">
                <div className="line short" />
              </div>
            </div>
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

Table.Head = TableHead;
Table.Body = TableBody;
Table.Footer = TableFooter;
Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
