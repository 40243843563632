import { isLocationAction } from 'redux-first-router';
import { routes } from '../router/routes';
import type { PageState } from '../flow-types/states/PageState';

const initialState: PageState = {
  name: null,
  public: false,
  forced: false
};

export default (state: PageState = initialState, action = {}): PageState => {
  if (!isLocationAction(action) && !action.type.startsWith('router/')) {
    return state;
  }

  const route = routes[action.type];

  const key = action.type.split('/')[1];

  return {
    ...state,
    key,
    name: route ? route.component : state.name,
    public: route ? !!route.public : false,
    forced: action.forced || false
  };
};
