import type { ProfileAction } from '../flow-types/actions/profile';
import type { ProfileState } from '../flow-types/states/ProfileState';

const initialState: ProfileState = {
  data: null,
  isLoading: false,
  isMutatingProjectsAccess: false
};

export default function profileReducer(
  state: ProfileState = initialState,
  action: ProfileAction
): ProfileState {
  switch (action.type) {
    case 'router/profilePage':
      return initialState;

    case 'profile/save-access-settings':
      return {
        ...state,
        isMutatingProjectsAccess: true
      };

    case 'profile/save-access-settings-fail':
      return {
        ...state,
        isMutatingProjectsAccess: false
      };

    case 'profile/save-access-settings-success':
      return {
        ...state,
        data: {
          ...state.data,
          projectsAccessSettings: action.data
        },
        isMutatingProjectsAccess: false
      };

    case 'profile/fetch-user':
      return {
        ...state,
        isLoading: true
      };

    case 'profile/fetch-user-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'profile/fetch-user-success':
      return {
        ...state,
        data: action.user,
        isLoading: false
      };

    default:
      return state;
  }
}
