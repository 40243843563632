import replace from 'lodash/replace';

// pass in "+7 (999) 999-99-99" and get "79999999999"
export default function filterPhoneNumberFromChars(
  phoneNumber: string
): string {
  if (!phoneNumber) return null;

  return replace(phoneNumber, /[^0-9]+/g, '');
}
