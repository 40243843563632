import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import { API } from 'utils/config';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import { projectIdAndGroupsSelector } from '../../../selectors/projects';

// eslint-disable-next-line
let _toastId;

const updateOptionsOrderEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/update-options-order'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([action, state]) => {
      _toastId = toast('Changing options order...', {
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_CENTER
      });

      const { projectId, groups } = projectIdAndGroupsSelector(state);

      const { questionGroupId, questionId } = action;

      try {
        const orderedIds = groups
          .find(g => g.id === questionGroupId)
          .questions.find(q => q.id === questionId)
          .options.filter(o => !o.isNew)
          .map(o => o.id);

        return {
          questionGroupId,
          questionId,
          orderedIds,
          projectId
        };
      } catch (e) {
        throw new Error(e);
      }
    }),
    RxOperators.filter(
      ({ projectId }) =>
        projectId !== 'new' &&
        projectId !== null &&
        typeof projectId !== 'undefined'
    ),
    RxOperators.mergeMap(
      (requestConfig: {|
        +questionGroupId: number,
        +questionId: number,
        +orderedIds: Array<number>
      |}) => {
        const { questionId, orderedIds, questionGroupId } = requestConfig;
        return request({
          url: API.options.sort.replace(':question_id', questionId),
          body: orderedIds,
          method: 'PUT'
        }).pipe(
          RxOperators.delay(250),
          responseParser,
          RxOperators.map((response: {| data: Array |}) => {
            const { data } = response;
            toast.update(_toastId, {
              type: toast.TYPE.SUCCESS,
              render: 'Options order has been changed',
              autoClose: 750,
              position: toast.POSITION.BOTTOM_CENTER
            });
            return {
              type: 'project-groups/update-options-order-success',
              data,
              questionGroupId,
              questionId
            };
          }),
          RxOperators.catchError(({ status, response, message }: AjaxError) => {
            toast.update(_toastId, {
              type: toast.TYPE.ERROR,
              render:
                +status === 422
                  ? 'Provided data is not acceptable (422)'
                  : message,
              autoClose: 750,
              position: toast.POSITION.BOTTOM_CENTER
            });
            return of({
              type: 'project-groups/update-options-order-fail',
              error: response ? response.error : message
            });
          })
        );
      }
    )
  );

export default updateOptionsOrderEpic;
