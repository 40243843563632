// @flow

import * as Y from 'yup';
import mapValues from 'lodash/mapValues';
import { TYPES } from 'common/helpers/question';
import nullToString, { emptyStringToNull } from 'common/helpers/nullToString';
import type {
  $QuestionTypes,
  IQuestionSettings
} from 'flow-types/entities/Question';

const createNoZeroTest = ($schema, fieldName) =>
  $schema.test(
    fieldName,
    {
      messageId:
        'questionForm.validation.fields.settings.pollingInterval.noEveryZeros'
    },
    value => value > 0
  );

const labelSettings: Function = Y.lazy(settings => {
  // TODO: maybe unnecessary
  if (settings === null) {
    return Y.mixed();
  }

  return Y.object({
    value: Y.number().required({
      messageId: 'questionForm.validation.fields.settingLabels.value.isRequired'
    }),
    title: Y.string().nullable(),
    color: Y.string()
      .transform(emptyStringToNull)
      .nullable()
  });
});

// validates question object
export default function createQuestionValidator(): Function {
  return Y.lazy(question =>
    Y.object().shape(
      {
        // it can be a string in case of a new block
        // it can be a number in case of a saved block
        id: Y.mixed().nullable(),
        questionGroupId: Y.number().required({
          messageId: 'questionForm.validation.fields.questionGroupId.isRequired'
        }),
        type: Y.number()
          .typeError({
            messageId: 'questionForm.validation.fields.type.isRequired'
          })
          .required({
            messageId: 'questionForm.validation.fields.type.isRequired'
          }),
        title: Y.string().required({
          messageId: 'questionForm.validation.fields.title.isRequired'
        }),
        shortTitle: Y.string()
          .transform(val => val || null)
          .nullable(),
        withPoints: Y.boolean().nullable(),
        filesEnabled: Y.boolean().nullable(),
        commentsEnabled: Y.boolean().nullable(),
        commentsEditable: Y.boolean().nullable(),
        viewType: Y.string().nullable(),
        hint: Y.string().nullable(),
        required: Y.boolean()
          .transform(val => (typeof val === 'undefined' ? null : val))
          .nullable(),
        recommendedTime: Y.number().nullable(),
        defaultAnswer: Y.mixed().nullable(),
        otherEnabled: Y.boolean().nullable(),
        otherTitle: Y.string().nullable(),

        // description is used to store HTML markup for HTMLQuestion,
        // thus it is required
        description: Y.string().when('type', (type, descriptionSchema) => {
          if (type !== TYPES.HTML) {
            return descriptionSchema.nullable();
          }

          // for HTMLQuestion description is a content
          return descriptionSchema.required({
            messageId: 'questionForm.validation.fields.contents.isRequired'
          });
        }),

        // minValue should be <= maxValue
        minValue: Y.number()
          .transform(emptyStringToNull)
          .when(
            ['maxValue', 'type', 'viewType'],
            (maxValue, type: $QuestionTypes, viewType, minValueSchema) => {
              if (type === TYPES.Rating) {
                return minValueSchema.required({
                  messageId:
                    'questionForm.validation.fields.minValue.isRequired'
                });
              }

              const isSliderNumeric =
                type === TYPES.Numeric && viewType === 'slider';

              if (maxValue === null && !isSliderNumeric) {
                return minValueSchema.nullable();
              }

              if (isSliderNumeric) {
                // eslint-disable-next-line no-param-reassign
                minValueSchema = minValueSchema.required({
                  messageId:
                    'questionForm.validation.fields.minValue.isRequiredWhenSlider'
                });
              }

              return minValueSchema.max(maxValue, {
                messageId:
                  'questionForm.validation.fields.minValue.shouldBeLessThanMax',
                values: {
                  max: maxValue
                }
              });
            }
          ),

        // maxValue should be >= maxValue
        maxValue: Y.number()
          .transform(emptyStringToNull)
          .when(
            ['minValue', 'type', 'viewType'],
            (minValue, type: $QuestionTypes, viewType, maxValueSchema) => {
              // rating block forces min to be 1
              // and max cannot be less than 1
              if (type === TYPES.Rating) {
                return maxValueSchema
                  .required({
                    messageId:
                      'questionForm.validation.fields.maxValue.isRequired'
                  })
                  .min(minValue, {
                    messageId:
                      'questionForm.validation.fields.maxValue.shouldBeMoreThanMin',
                    values: {
                      min: minValue
                    }
                  });
              }

              const isSliderNumeric =
                type === TYPES.Numeric && viewType === 'slider';

              if (minValue === null && !isSliderNumeric) {
                return maxValueSchema.nullable();
              }

              if (isSliderNumeric) {
                // eslint-disable-next-line no-param-reassign
                maxValueSchema = maxValueSchema.required({
                  messageId:
                    'questionForm.validation.fields.maxValue.isRequiredWhenSlider'
                });
              }

              return maxValueSchema.min(minValue, {
                messageId:
                  'questionForm.validation.fields.maxValue.shouldBeMoreThanMin',
                values: {
                  min: minValue
                }
              });
            }
          ),

        // minValue should be <= maxValue
        minAnswers: Y.lazy(minAnswers => {
          if (!minAnswers) return Y.mixed().nullable();

          return Y.number().when(
            ['maxAnswers', 'type'],
            (maxAnswers, type, schema) => {
              if (
                ![TYPES.MultipleAnswer, TYPES.Checklist].includes(type) ||
                !maxAnswers
              ) {
                return schema;
              }

              return schema.max(maxAnswers, {
                messageId:
                  'questionForm.validation.fields.minValue.shouldBeLessThanMax',
                values: {
                  max: maxAnswers
                }
              });
            }
          );
        }),

        // maxValue should be >= maxValue
        maxAnswers: Y.lazy(maxAnswers => {
          if (!maxAnswers) return Y.mixed().nullable();

          return Y.number().when(
            ['minAnswers', 'type'],
            (minAnswers, type, schema) => {
              if (
                ![TYPES.MultipleAnswer, TYPES.Checklist].includes(type) ||
                !minAnswers
              ) {
                return schema;
              }

              return schema.min(minAnswers, {
                messageId:
                  'questionForm.validation.fields.maxValue.shouldBeMoreThanMin',
                values: {
                  min: minAnswers
                }
              });
            }
          );
        }),

        // maxFiles should be >= minFiles
        maxFiles: Y.number()
          .transform(emptyStringToNull)
          .nullable()
          .when('minFiles', (minFiles, maxFilesSchema) => {
            if (minFiles === null) {
              return maxFilesSchema;
            }

            return maxFilesSchema.min(minFiles, {
              messageId:
                'questionForm.validation.fields.maxFiles.shouldBeMoreThanMin',
              values: {
                min: minFiles
              }
            });
          }),

        // minFiles should be <= maxFiles
        minFiles: Y.number()
          .transform(emptyStringToNull)
          .nullable()
          .when('maxFiles', (maxFiles, minFilesSchema) => {
            if (maxFiles === null) {
              return minFilesSchema;
            }

            return minFilesSchema.max(maxFiles, {
              messageId:
                'questionForm.validation.fields.minFiles.shouldBeLessThanMax',
              values: { max: maxFiles }
            });
          }),

        fieldWidth: Y.string().nullable(),
        fieldHeight: Y.string().nullable(),
        code: Y.string().nullable(),
        order: Y.number().required({
          messageId: 'questionForm.validation.fields.order.isRequired'
        }),
        editable: Y.boolean().required({
          messageId: 'questionForm.validation.fields.editable.isRequired'
        }),
        unloaded: Y.boolean().required({
          messageId: 'questionForm.validation.fields.unloaded.isRequired'
        }),
        showTitle: Y.boolean().required({
          messageId: 'questionForm.validation.fields.showTitle.isRequired'
        }),
        showLabels: Y.boolean().required({
          messageId: 'questionForm.validation.fields.showLabels.isRequired'
        }),
        showImages: Y.boolean().required({
          messageId: 'questionForm.validation.fields.showImages.isRequired'
        }),
        inProfile: Y.boolean().required({
          messageId: 'questionForm.validation.fields.inProfile.isRequired'
        }),
        dateOnly: Y.boolean().nullable(),
        isInterval: Y.boolean().nullable(),
        meetingField: Y.string().nullable(),
        parentId: Y.number().nullable(),

        /**
         * videoToken field validation.
         * It is required when question is of type video block and when priority is either unset,
         * or 'local'
         */
        videoToken: Y.string()
          .transform(nullToString)
          .when(
            ['type', 'priorityVideoSource'],
            (type, priorityVideoSource, schema) => {
              if (type === TYPES.Video) {
                if (!priorityVideoSource || priorityVideoSource === 'local') {
                  return schema.required({
                    messageId:
                      'questionForm.validation.fields.videoToken.isRequired'
                  });
                }
              }

              // if it is not required, then just return schema
              return schema.nullable();
            }
          ),

        /**
         * videoSrc field validation.
         */
        videoSrc: Y.string()
          .transform(nullToString)
          .when(
            ['type', 'priorityVideoSource'],
            (type, priorityVideoSource, schema) => {
              if (type === TYPES.Video) {
                if (priorityVideoSource === 'remote') {
                  return schema
                    .url({
                      messageId:
                        'questionForm.validation.fields.videoSrc.isNotValidURL'
                    })
                    .required({
                      messageId:
                        'questionForm.validation.fields.videoSrc.isRequired'
                    });
                }
              }

              // if it is not required, then just return schema
              return schema.nullable();
            }
          ),

        options: ![
          TYPES.Table,
          TYPES.MultipleAnswer,
          TYPES.SingleAnswer,
          TYPES.Status,
          TYPES.Checklist
        ].includes(question.type)
          ? Y.mixed().nullable()
          : Y.array()
              .when(
                ['withPoints', 'showImages'],
                (withPoints, showImages, optionsSchema) => {
                  const points = withPoints
                    ? Y.number().required({
                        messageId:
                          'questionForm.validation.fields.optionPoints.isRequired'
                      })
                    : Y.number().nullable();

                  const imageToken = showImages
                    ? Y.string()
                        .transform(nullToString)
                        .required({
                          messageId:
                            'questionForm.validation.fields.optionImage.isRequired'
                        })
                    : Y.string().nullable();

                  return optionsSchema.of(
                    Y.object({
                      // new values have string
                      // saved - numbers
                      id: Y.mixed().nullable(),
                      title: Y.string().required({
                        messageId:
                          'questionForm.validation.fields.optionTitle.isRequired'
                      }),
                      imageToken,
                      order: Y.number().required({
                        messageId:
                          'questionForm.validation.fields.optionOrder.isRequired'
                      }),
                      points,
                      code: Y.string().nullable(),
                      isDefault: Y.boolean().nullable(),
                      isExcluding: Y.boolean().nullable()
                    })
                  );
                }
              )
              .when(
                ['type', 'optionsFrom'],
                (type, optionsFrom, optionsSchema) => {
                  if (
                    [
                      TYPES.SingleAnswer,
                      TYPES.Status,
                      TYPES.Checklist,
                      TYPES.Table,
                      TYPES.MultipleAnswer,
                      TYPES.Ranging
                    ].includes(type) &&
                    !optionsFrom
                  ) {
                    return optionsSchema.required({
                      messageId:
                        'questionForm.validation.fields.options.isRequired'
                    });
                  }

                  return optionsSchema.nullable();
                }
              ),

        // field below is required if TableQuestion
        subQuestions: ![TYPES.Table].includes(question.type)
          ? Y.mixed().nullable()
          : Y.array().when(
              ['type', 'optionsFrom'],
              (type, optionsFrom, subQuestionsSchema) => {
                if (type === TYPES.Table && !optionsFrom) {
                  return subQuestionsSchema
                    .of(
                      Y.object({
                        // new values can have string
                        // saved values - number
                        id: Y.mixed().nullable(),
                        title: Y.string()
                          .transform(nullToString)
                          .required({
                            messageId:
                              'questionForm.validation.fields.subQuestionTitle.isRequired'
                          }),
                        shortTitle: Y.string().nullable(),
                        description: Y.string().nullable(),
                        order: Y.number().required({
                          messageId:
                            'questionForm.validation.fields.subQuestionOrder.isRequired'
                        })
                      })
                    )
                    .min(1, {
                      messageId:
                        'questionForm.validation.fields.subQuestions.isRequired'
                    })
                    .required({
                      messageId:
                        'questionForm.validation.fields.subQuestions.isRequired'
                    });
                }

                return subQuestionsSchema.nullable();
              }
            ),
        useForSms: Y.boolean().nullable(),
        useForEmail: Y.boolean().nullable(),
        isVisibleForMain: Y.boolean().nullable(),
        isVisibleForPost: Y.boolean().nullable(),
        isVisibleForEdit: Y.boolean().nullable(),
        isVisibleForPublic: Y.boolean().nullable(),
        isVisibleForMobile: Y.boolean().nullable(),
        postPollingDefault: Y.boolean().nullable(),
        postPollingFinish: Y.boolean().nullable(),
        settings: Y.lazy((settingsObj: $Shape<IQuestionSettings>) =>
          Y.object().when('type', (type, settingsSchema) => {
            if ([TYPES.Table, TYPES.TabView, TYPES.Rating].includes(type)) {
              const typeValidator =
                type === TYPES.Table
                  ? Y.number()
                      .typeError({
                        messageId:
                          'questionForm.validation.fields.settingType.isRequired'
                      })
                      .required({
                        messageId:
                          'questionForm.validation.fields.settingType.isRequired'
                      })
                  : Y.number().nullable();

              const viewType =
                type === TYPES.Table
                  ? Y.mixed()
                      .oneOf(['single', 'table', 'accordion', 'list'], {
                        messageId:
                          'questionForm.validation.fields.settingViewType.isRequired'
                      })
                      .required({
                        messageId:
                          'questionForm.validation.fields.settingViewType.isRequired'
                      })
                  : Y.mixed()
                      .oneOf(['single', 'table', 'accordion', 'list'])
                      .nullable();

              return settingsSchema
                .shape({
                  type: typeValidator,
                  viewType,
                  imageSrc: Y.string().nullable(),
                  enablePolygonOptions: Y.boolean().nullable(),
                  instantNagivation: Y.boolean().nullable(),
                  showTextOptions: Y.boolean().nullable(),
                  rulesEnabled: Y.boolean().nullable(),
                  // options is controlled server-side,
                  // no need to make it required
                  options: Y.mixed().nullable(),
                  operator: Y.string().nullable(),
                  unionType: Y.string().nullable(),
                  dynamicOptionsSorting: Y.string().nullable(),
                  viewData: Y.mixed().nullable(),
                  tabs: Y.mixed().nullable(),
                  labels: TYPES.Rating
                    ? Y.lazy(val =>
                        Y.object(mapValues(val, () => labelSettings)).nullable()
                      )
                    : Y.mixed().nullable()
                })
                .required({
                  messageId:
                    'questionForm.validation.fields.settings.isRequired'
                });
            }

            if (type === TYPES.Video) {
              return settingsSchema.shape({
                pollingEnabled: Y.bool().nullable(),
                pollingMode: Y.string()
                  .transform(nullToString)
                  .nullable(),
                pollingInterval:
                  settingsObj.pollingEnabled &&
                  ['mixed', 'interval'].includes(settingsObj.pollingMode)
                    ? Y.object().shape(
                        {
                          hours: Y.number()
                            // TODO: resolve later
                            .transform(val => (val !== null ? +val : null))
                            .typeError({
                              messageId:
                                'questionForm.validation.fields.settings.pollingInterval.hours.notANumber'
                            })
                            .when(
                              ['minutes', 'seconds'],
                              ($minutes, $seconds, $schema) => {
                                if ($minutes === 0 && $seconds === 0) {
                                  // eslint-disable-next-line no-param-reassign
                                  $schema = createNoZeroTest($schema, 'hours');
                                }

                                return $schema;
                              }
                            )
                            .required()
                            .default(0),
                          minutes: Y.number()
                            // TODO: resolve later
                            .transform(val => (val !== null ? +val : null))
                            .typeError({
                              messageId:
                                'questionForm.validation.fields.settings.pollingInterval.minutes.notANumber'
                            })
                            .when(
                              ['hours', 'seconds'],
                              ($hours, $seconds, $schema) => {
                                if ($hours === 0 && $seconds === 0) {
                                  // eslint-disable-next-line no-param-reassign
                                  $schema = createNoZeroTest(
                                    $schema,
                                    'minutes'
                                  );
                                }

                                return $schema;
                              }
                            )
                            .default(0),
                          seconds: Y.number()
                            // TODO: resolve later
                            .transform(val => (val !== null ? +val : null))
                            .typeError({
                              messageId:
                                'questionForm.validation.fields.settings.pollingInterval.seconds.notANumber'
                            })
                            .when(
                              ['hours', 'minutes'],
                              ($hours, $minutes, $schema) => {
                                if ($hours === 0 && $minutes === 0) {
                                  // eslint-disable-next-line no-param-reassign
                                  $schema = createNoZeroTest(
                                    $schema,
                                    'seconds'
                                  );
                                }

                                return $schema;
                              }
                            )
                            .default(0)
                        },
                        [
                          ['hours', 'minutes'],
                          ['hours', 'seconds'],
                          ['minutes', 'hours'],
                          ['minutes', 'seconds'],
                          ['seconds', 'hours'],
                          ['seconds', 'minutes']
                        ]
                      )
                    : Y.mixed().nullable()
              });
            }

            return settingsSchema.nullable();
          })
        ),
        optionsFrom: Y.number().nullable(),
        scale: Y.number().nullable()
      },
      [
        ['maxValue', 'type'],
        ['minValue', 'viewType'],
        ['maxValue', 'viewType'],
        ['minValue', 'maxValue'],
        ['minAnswers', 'maxAnswers'],
        ['minFiles', 'maxFiles'],
        ['videoToken', 'videoSrc']
      ]
    )
  );
}
