import { saveAs } from 'file-saver';
import type { IUpload } from 'flow-types/entities/Upload';

export default function downloadFile(
  { isImage, url, originalFilename }: IUpload,
  forceSaveAs = false,
  native = false
) {
  if (native) {
    window.open(url, '_blank');
    return;
  }

  if (isImage && !forceSaveAs) {
    window.open(url, '_blank');
  } else {
    saveAs(url, originalFilename);
  }
}
