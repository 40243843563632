import revertDictionary from '../../utils/revertDictionary';

const regionFormDictionary = {
  'regionForm.checklists.label': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'regionForm.messages.removeConfirm': {
    ru: 'Удалить выбранный регион?',
    en: 'Is selected region should be removed?'
  },
  'regionForm.noComparatorsForSelection': {
    en: 'Has no test elements for selection',
    ru: 'Нет тест элементов для выбора'
  },
  'regionForm.rate.label': {
    en: 'Mark',
    ru: 'Оценка'
  },
  'regionForm.rate.placeholder': {
    en: 'Set mark',
    ru: 'Поставьте оценку'
  },
  'regionForm.markComment.label': {
    en: 'Mark comment',
    ru: 'Комментарий к оценке'
  },
  'regionForm.start.label': {
    en: 'Start',
    ru: 'Старт'
  },
  'regionForm.end.label': {
    en: 'End',
    ru: 'Конец'
  },
  'regionForm.rate.emptyChecklist': {
    en: "There're no available marks for current checklist",
    ru: 'В рамках проекта не установлены возможные оценки'
  },
  'regionForm.validation.rating': {
    en: 'You have to set rating to the region',
    ru: 'Вам нужно поставить оценку региону'
  },
  'regionForm.validation.checklistItems': {
    en: 'You have to select checklist',
    ru: 'Вам нужно выбрать чеклист'
  },
  'regionForm.validation.checklistItemId.required': {
    en: 'You have to select checklist',
    ru: 'Вам нужно выбрать чеклист'
  },
  'regionForm.validation.start.required': {
    en: 'You have to specify region start',
    ru: 'Вам нужно выбрать начало аудио фрагмента'
  },
  'regionForm.validation.end.required': {
    en: 'You have to specify region start',
    ru: 'Вам нужно выбрать конец аудио фрагмента'
  },
  'regionForm.validation.checklistItemOptionId.required': {
    en: 'You have to set mark',
    ru: 'Вам нужно выбрать оценку'
  }
};

export default revertDictionary(regionFormDictionary);
