// @flow

import {
  ADMIN,
  COMPANY_ADMIN,
  DIVISION_HEAD,
  EXPERT,
  MANAGER,
  OPERATOR
} from 'common/helpers/user';

import type { $ObjOfType } from 'flow-types/ObjOfType';
import { PANEL_ID as ACCESS_RULES_PANEL_ID } from '../../../pages/Project/components/Panels/AccessSettingsPanel/constants';

export const POLLING_NOTIFICATION_STATUS = {
  STOPPED: 1,
  ACTIVE: 2
};

export const POST_POLLING_DELAY_TYPES = {
  TIMER: 1,
  NEXT_DAY: 2,
  NEXT_WORKING_DAY: 3
};

export const ACCESS_STATUSES = {
  EDITABLE: 'editable',
  DISABLED: 'disabled',
  HIDDEN: 'hidden'
};

export const PROJECT_STATUSES = {
  PREPARING: 1,
  ACTIVE: 2,
  OUTDATED: 3
};

export const ROLES_WHO_CAN_EXPORT = [ADMIN, COMPANY_ADMIN, OPERATOR];

export const PROJECT_TABS_ACCESS_SETTINGS_BY_ROLES: $ObjOfType<
  // star means all roles
  '*' | number[] | ((params: { webAccess: boolean, roleId: number }) => boolean)
> = {
  overview: [ADMIN, COMPANY_ADMIN],
  editor: [ADMIN, COMPANY_ADMIN],
  interview: [ADMIN, COMPANY_ADMIN],
  responses: [ADMIN, COMPANY_ADMIN, DIVISION_HEAD, MANAGER, OPERATOR, EXPERT],
  response: '*',
  logic: [ADMIN, COMPANY_ADMIN],
  reports: [ADMIN, COMPANY_ADMIN],
  [ACCESS_RULES_PANEL_ID]: [ADMIN, COMPANY_ADMIN],
  export: ROLES_WHO_CAN_EXPORT,
  queries: [ADMIN, COMPANY_ADMIN],
  polling_notifications: [ADMIN, COMPANY_ADMIN],
  variables: [ADMIN, COMPANY_ADMIN],
  testInterview: [ADMIN, COMPANY_ADMIN],
  startInterview: ({ roleId, webAccess }) =>
    [
      ADMIN,
      COMPANY_ADMIN,
      OPERATOR,
      EXPERT,
      webAccess ? MANAGER : false
    ].includes(roleId),
  'tir-export': [ADMIN, OPERATOR, MANAGER, COMPANY_ADMIN, DIVISION_HEAD]
};
