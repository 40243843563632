import * as React from 'react';
import { createPortal } from 'react-dom';
import Dimmer from 'common/components/animated/Dimmer';
import Loader from 'common/components/Loader';

type Props = {|
  +semiTransparent?: boolean
|};

const PageLoader = ({ semiTransparent, ...rest }: Props) =>
  createPortal(
    <Dimmer visible inverted className="hard" fixed page immediate {...rest}>
      <Loader state="active" size="large" />
    </Dimmer>,
    // TODO: SSR alert
    document.body
  );

PageLoader.defaultProps = {
  semiTransparent: false
};

export default PageLoader;
