import reduce from 'lodash/reduce';
import { mergeAndConcat } from 'merge-anything';
import type { YupValidationError } from 'flow-types/Validation';

const normalizeValidation = (validation: YupValidationError) => {
  const { inner, message, path } = validation;

  const errors = [...inner, path && { message, path }].filter(Boolean);

  return reduce(
    errors,
    (result, error: YupValidationError) => {
      const {
        message: { messageId },
        path: field
      } = error;
      return mergeAndConcat({ ...result }, { [field]: [messageId] });
    },
    {}
  );
};

export default normalizeValidation;
