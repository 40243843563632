// @flow
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import { ofType } from 'redux-observable';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeKeys } from 'humps';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

const fetchMassActionsList: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-responses-mass-actions-list/fetch'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([, state]) => {
      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(API.responses.massActions.tasksDraftsList, {
          projectId
        }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck(`data`),
        RxO.map(camelizeKeys),
        RxO.map(data => ({
          type: 'project-responses-mass-actions-list/fetch-success',
          data
        }))
      );
    })
  );

export default fetchMassActionsList;
