// @flow
import type { Bag, GetState } from 'redux-first-router';
import { redirect } from 'redux-first-router';
import { authStateSelector } from '../../selectors';
import hasAccessToRoute from '../helpers/hasAccessToRoute';
/* eslint-disable import/order */
import type { AuthState } from '../../flow-types/states/AuthState';
import type { OnBeforeChangeFn } from '../../flow-types/router/OnBeforeChangeFn';
import type { AppDispatch } from 'flow-types/AppDispatch';
/* eslint-enable import/order */

const accessGuard: OnBeforeChangeFn = (
  dispatch: AppDispatch,
  getState: GetState,
  bag: Bag
) => {
  const {
    action: { type }
  } = bag;
  const state = getState();

  const { scope }: AuthState = authStateSelector(state);

  if (!scope) return null;

  const routeKey = type.split('/')[1];

  if (!hasAccessToRoute(scope, routeKey)) {
    dispatch(redirect({ type: 'router/restricted' }));
    return true;
  }

  return false;
};

export default accessGuard;
