// @flow

import updateFilter from '../../common/helpers/updateFilter';

import type { ComparatorsState } from '../../flow-types/states/ChecksState';
import type { ChecklistItemsAction } from '../../flow-types/actions/checks/checklistItems';

const initialState: ComparatorsState = {
  data: [],
  filter: {
    sort: '-id'
  },
  pagination: {
    activePage: 0,
    totalElements: 0,
    totalPages: 1
  },
  selected: null,
  loading: false,
  form: {
    visible: false,
    data: null,
    error: null,
    loading: false
  }
};

export default (
  state: ComparatorsState = initialState,
  action: ChecklistItemsAction
) => {
  switch (action.type) {
    case 'checklist-comparators/create':
      return {
        ...state,
        form: {
          ...state.form,
          visible: true,
          data: {
            ...action.initialData
          }
        }
      };

    case 'checklist-comparators/edit':
      return {
        ...state,
        form: {
          ...state.form,
          visible: true,
          data: action.comparator,
          cache: action.comparator
        }
      };

    case 'checklist-comparators/update':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          }
        }
      };

    case 'checklist-comparators/reset-form':
      return {
        ...state,
        form: initialState.form
      };

    case 'checklist-comparators/save':
      return {
        ...state,
        form: {
          ...state.form,
          loading: true
        }
      };

    case 'checklist-comparators/save-fail':
      return {
        ...state,
        form: {
          ...state.form,
          error: action.error,
          loading: false
        }
      };

    case 'checklist-comparators/save-success':
      return {
        ...state,
        // $FlowIgnore
        ...(state.selected === action.originalId && {
          selected: action.comparatorId
        }),
        form: initialState
      };

    case 'checklist-comparators/update-state':
      return {
        ...state,
        ...action.stateUpdate
      };

    case 'checklist-comparators/fetch':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'checklist-comparators/fetch-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'checklist-comparators/fetch-success':
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        pagination: action.pagination
      };

    case 'checklists/select':
      return {
        ...state,
        filter: {
          ...state.filter,
          checklistId: action.checklistId,
          checklistGroupId: null
        }
      };

    case 'checklist-test-groups/select':
      return {
        ...state,
        filter: {
          ...state.filter,
          checklistGroupId: action.testGroupId || null
        },
        data: []
      };

    case 'checklist-comparators/select':
      return {
        ...state,
        selected: action.comparatorId
      };

    default:
      return state;
  }
};
