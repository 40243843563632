import { combineEpics } from 'redux-observable';
import saveProjectEpic from './saveProject';
import cloneProjectEpic from './cloneProject';
import fetchProjectEpic from './fetchProject';
import fetchProjectResponsesEpic from './fetchResponses';
import updateGroupsOrderEpic from './updateGroupsOrder';
import updateQuestionsOrderEpic from './updateQuestionsOrder';
import saveGroupEpic from './saveGroup';
import saveQuestionEpic from './saveQuestion';
import updateOptionsOrderEpic from './updateOptionsOrder';
import removeQuestionEpic from './removeQuestion';
import removeGroupEpic from './removeGroup';
import projectLastUpdateDateFetcher from './projectLastUpdateDateFetcher';
import saveProjectLogicEpic from './saveProjectLogicEpic';
import fetchProjectLogicEpic from './fetchProjectLogicEpic';
import saveLogicRule from './saveLogicRule';
import enableChecklistForResponse from './enableChecklistForResponse';
import disableChecklistForResponse from './disableChecklistForResponse';
import fetchResponseStats from './fetchResponseStats';
import fetchProjectReports from './fetchProjectReports';
import editElement from './editElement';
import addQuestion from './addQuestion';
import addGroup from './addGroup';
import moveQuestionToGroup from './moveQuestionToGroup';
import deleteLogicRule from './deleteLogicRule';
import fetchResponse from './fetchResponse';
import saveResponseComment from './saveResponseComment';
import completeResponseReview from './completeResponseReview';
import fetchResponseReviewStage from './fetchResponseReviewStage';
import saveVisibilitySettings from './saveVisibilitySettings';
import fetchVisibilitySettings from './fetchVisibilitySettings';
import updateResponseAnswer from './updateResponseAnswer';
import filterSettingsEpics from './filterSettings';
import accessSettingsEpics from './accessSettings';
import searchSettingsEpics from './searchSettings';
import pollingNotificationsEpics from './pollingNotifications';
import exportEpics from './export';
import pivotTablesEpics from './pivotTables';
import computedPropertiesEpic from './computedProperties';
import fetchMassActionsTasks from './massActionsTasks/fetchMassActionsTasks';
import fetchMassActionsList from './massActionsTasks/fetchMassActionsList';
import watchMassActionsTask from './massActionsTasks/watchMassActionsTask';
import startOnSaveSuccess from './startOnSaveSuccess';

export default combineEpics(
  saveProjectEpic,
  cloneProjectEpic,
  fetchProjectEpic,
  fetchProjectResponsesEpic,
  updateGroupsOrderEpic,
  updateQuestionsOrderEpic,
  updateOptionsOrderEpic,
  saveGroupEpic,
  saveQuestionEpic,
  removeQuestionEpic,
  removeGroupEpic,
  projectLastUpdateDateFetcher,
  saveProjectLogicEpic,
  fetchProjectLogicEpic,
  saveLogicRule,
  enableChecklistForResponse,
  disableChecklistForResponse,
  fetchResponseStats,
  fetchProjectReports,
  editElement,
  addQuestion,
  addGroup,
  moveQuestionToGroup,
  deleteLogicRule,
  fetchResponse,
  saveResponseComment,
  completeResponseReview,
  fetchResponseReviewStage,
  saveVisibilitySettings,
  fetchVisibilitySettings,
  pollingNotificationsEpics,
  updateResponseAnswer,
  filterSettingsEpics,
  accessSettingsEpics,
  searchSettingsEpics,
  exportEpics,
  pivotTablesEpics,
  computedPropertiesEpic,
  fetchMassActionsTasks,
  fetchMassActionsList,
  watchMassActionsTask,
  startOnSaveSuccess
);
