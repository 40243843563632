// @flow
import * as React from 'react';
import composeFp from 'lodash/fp/compose';
import filterFp from 'lodash/fp/filter';
import uniqByFp from 'lodash/fp/uniqBy';
import pickFp from 'lodash/fp/pick';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';

import { getStackByQuestionId } from 'common/helpers/interview/getStack';
import Modal from 'common/components/animated/Modal';
import Content from 'common/components/Content';
import Button from 'common/components/Button';
import Accordion from 'common/components/Accordion';
import ListItem from 'common/components/ListItem';
import List from 'common/components/List';
import Link from 'common/components/Link';
import Text from 'common/components/Text';

import useModal from 'common/hooks/useModal';

import useMove from '../../hooks/useMove';
import { interviewLogicAwaredStructureSelector } from '../../../../selectors/interview/answers';
import { interviewProjectDataSelector } from '../../../../selectors/interview/root';

export const MODAL_ID = 'blocksProblemsDialog';

const dimmerProps = { scrolling: true, page: true, fixed: true };

const filterQuestionStacks = composeFp(
  uniqByFp('questionId'),
  filterFp(Boolean)
);

const pickFromStack = pickFp([
  'localId',
  'groupId',
  'parentQuestionId',
  'parentQuestionTitle',
  'questionId',
  'questionTitle',
  'path',
  'insideSinglePage'
]);

export default function BlocksProblemsDialog(): React.Node {
  const dispatch = useDispatch();

  const [{ visible, data: modalData }, , close] = useModal(MODAL_ID);

  const registerScrollTarget = targetId => {
    dispatch({
      type: 'interview-ui/set-scroll-target',
      targetId
    });
  };

  const moveConfig = React.useRef({ force: true });

  const { run: forcedMove } = useMove(moveConfig.current);

  const structure = useSelector(interviewLogicAwaredStructureSelector);
  const { groups } = useSelector(interviewProjectDataSelector);

  const questionsIds = React.useMemo(
    () => (!modalData?.checkResult ? [] : keys(modalData.checkResult)),
    [modalData?.checkResult]
  );

  const data = React.useMemo(() => {
    if (!visible) {
      return [];
    }

    const questionsStacks = filterQuestionStacks(
      map(questionsIds, questionId => {
        const stack: IInterviewStructureElement = getStackByQuestionId(
          structure,
          +questionId,
          {
            rootOnly: false,
            recursive: true
          }
        );

        if (stack.parentQuestionId) {
          const parentStack: IInterviewStructureElement = getStackByQuestionId(
            structure,
            stack.parentQuestionId,
            {
              rootOnly: false,
              recursive: true
            }
          );

          if (parentStack) {
            return pickFromStack(parentStack);
          }
        }

        return pickFromStack(stack);
      })
    );

    return reduce(
      questionsStacks,
      (result, stack: IInterviewStructureElement) => {
        const { groupId } = stack;

        if (result[groupId]) {
          return {
            ...result,
            [groupId]: {
              ...result[groupId],
              items: [...result[groupId].items, stack]
            }
          };
        }

        const group = find(groups, { id: +groupId });

        return {
          ...result,
          [groupId]: {
            id: groupId,
            title: group.title,
            items: [stack]
          }
        };
      },
      {}
    );
  }, [groups, questionsIds, structure, visible]);

  return (
    <Modal portal visible={visible} onClose={close} dimmerProps={dimmerProps}>
      <Modal.Header nonUI>
        <FormattedMessage
          id="interview.dialogs.blocksProblems.title"
          values={{ newLine: <br /> }}
        />
      </Modal.Header>
      <Modal.Content>
        <div className="ui compact horizontally padded grid">
          <div className="row column">
            <div>
              <Text inverted color="gray">
                <FormattedMessage id="interview.dialogs.blocksProblems.hint" />
              </Text>
            </div>
          </div>
          <div className="row column">
            <Accordion styled>
              {map(data, group => (
                <>
                  <div key={`title-${group.id}`} className="active title">
                    {group.title}
                  </div>
                  <Content active key={`content-${group.id}`}>
                    <List link>
                      {map(group.items, (item: IInterviewStructureElement) => {
                        let title = item.questionTitle;

                        if (item.parentQuestionId) {
                          // $FlowIgnore
                          title = `${item.parentQuestionTitle} (${title})`;
                        }

                        return (
                          <ListItem
                            as={Link}
                            key={item.localId}
                            onClick={() => {
                              close();

                              const stackId = item.insideSinglePage
                                ? item.path[0]
                                : item.localId;

                              forcedMove({
                                stackId,
                                skipAnswersSubmit: true
                                // submitOnly: ['views']
                              });

                              if (item.insideSinglePage) {
                                registerScrollTarget(+item.questionId);
                              }
                            }}
                          >
                            {title}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Content>
                </>
              ))}
            </Accordion>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            forcedMove({ complete: true });
            close();
          }}
        >
          <FormattedMessage id="interview.dialogs.blocksProblems.actions.completeAnyway" />
        </Button>
        <Button
          buttonType="primary"
          onClick={() => {
            close();

            if (modalData?.firstIssueQuestionId) {
              registerScrollTarget(modalData?.firstIssueQuestionId);
            }
          }}
        >
          <FormattedMessage id="common.labels.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
