import React from 'react';
import { InputWrapper } from '../../../components/Input';
import Dropdown, { AsyncDropdown } from '../../../components/Dropdown';
import type { ReactComponent } from '../../../../flow-types/ReactComponent';

type Props = {
  error?: boolean,
  fluid?: boolean,
  size?: null | string,
  async?: boolean,
  CustomInputComponent?: ReactComponent
};

export default function SelectableInput({
  error,
  fluid,
  size,
  async,
  CustomInputComponent,
  ...props
}: Props) {
  if (!CustomInputComponent) {
    return (
      <InputWrapper fluid={fluid} size={size} error={error}>
        {!async ? (
          <Dropdown
            {...props}
            error={error}
            valueKey="id"
            labelKey="title"
            fluid
            onlyValue
          />
        ) : (
          <AsyncDropdown
            {...props}
            error={error}
            fluid
            onlyValue
            valueKey="id"
            labelKey="title"
          />
        )}
      </InputWrapper>
    );
  }

  return (
    <InputWrapper fluid={fluid} size={size} error={error}>
      <CustomInputComponent
        {...props}
        error={error}
        valueKey="id"
        labelKey="title"
        fluid
        onlyValue
      />
    </InputWrapper>
  );
}

SelectableInput.defaultProps = {
  error: false,
  fluid: false,
  async: false,
  size: null,
  CustomInputComponent: null
};
