// @flow

import type { SignOut } from 'flow-types/actions/auth/SignOut';

/**
 * Creates 'auth/sing-out' action object
 * @param local
 * @param manual
 * @returns {{type: string, local: boolean, moveToLogin: boolean}}
 */
const createSingOut = ({
  manual = false,
  error = null,
  local = false
} = {}): SignOut => ({
  type: 'auth/sign-out',
  manual,
  local,
  error
});

// const createSingOut = (
//   local: boolean = false,
//   moveToLogin: boolean = true,
//   manual: boolean = true,
//   error: any = null
// ): SignOut => ({
//   type: 'auth/sign-out',
//   local,
//   moveToLogin,
//   manual,
//   error
// });

export default createSingOut;

// export const creator = ({ manual = false, error = null } = {}): SignOut => {
//   return {
//     type: 'auth/sign-out',
//     manual,
//     error
//   };
// };
