import fp from 'lodash/fp';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import { decamelizeKeys } from 'humps';
import type {
  IDecodingTask,
  IDecodingTask$Settings
} from 'flow-types/entities/DecodingTask';

const processSettings = (settings: IDecodingTask$Settings) => {
  const next = cloneDeep(settings);

  if (next.maxSpeakerCount === '' || isNil(next.maxSpeakerCount)) {
    delete next.maxSpeakerCount;
  }

  if (next.minSpeakerCount === '' || isNil(next.minSpeakerCount)) {
    delete next.minSpeakerCount;
  }

  return next;
};

const processDecodingSettings = (decodingTask: IDecodingTask) => ({
  ...decodingTask,
  settings: processSettings(decodingTask.settings)
});

export const decamelizeAndDenormalizeDecodingTask = fp.compose(
  decamelizeKeys,
  processDecodingSettings
);

export const decamelizeAndDenormalizeDecodingTasks = fp.map(
  decamelizeAndDenormalizeDecodingTask
);
