// @flow

import { useMemo } from 'react';
import reduce from 'lodash/reduce';
import { useIntl } from 'react-intl';

type MessageKeyConfig = {|
  id: string,
  defaultMessage?: string,
  description?: string,
  values?: Object
|};

type Config = {|
  [string]: MessageKeyConfig
|};

type UseI18NResult = {|
  [string]: string
|};

export default function useI18N(config?: Config): UseI18NResult {
  const intl = useIntl();

  return useMemo<UseI18NResult>(() => {
    if (!config) return {};

    return reduce(
      config,
      (result: Object, messageKeyConfig, messageKey) => {
        const { values, ...descriptor } = messageKeyConfig;

        return {
          ...result,
          [messageKey]: intl.formatMessage(descriptor, values)
        };
      },
      {}
    );
  }, [config, intl]);
}
