import React from 'react';
import { interval } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { TASK_STATUS } from 'common/helpers/response';
import { camelizeKeys } from 'humps';
import type { IExportTask } from 'flow-types/entities/ExportTask';
import { toast } from 'react-toastify';
import Button from 'common/components/Button';
import IntlMessageFormat from 'intl-messageformat';
import type { WatchTask } from 'flow-types/actions/projects/detail/responsesMassActions/WatchTask';
import type { AppState } from 'flow-types/AppState';

import { projectIdFromPathSelector } from '../../../../selectors/projects';
import { languageStateSelector } from '../../../../selectors';
import projectResponsesDictionary from '../../../../intl/projectResponsesDictionary';

// TODO: может можно сделать отслеживание разных тасков, а не только экспортов
const watchMassActionTaskEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-responses-mass-actions-tasks/watch'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([action, state]: [WatchTask, AppState]) => {
      const { taskId } = action;

      const projectId = projectIdFromPathSelector(state);

      return interval(3000).pipe(
        RxOperators.switchMap(() =>
          request({
            url: interpolateString(API.responses.massActions.tasksList, {
              projectId
            }),
            query: {
              id: [taskId]
            }
          }).pipe(
            responseParser,
            RxOperators.pluck('data'),
            RxOperators.mergeMap(data => data)
          )
        ),
        RxOperators.takeWhile(data => {
          const task: IExportTask = camelizeKeys(data);

          return ![TASK_STATUS.READY, TASK_STATUS.ERROR].includes(task.status);
        }, true),
        RxOperators.withLatestFrom(state$),
        RxOperators.mergeMap(([data, freshState]) => {
          const language = languageStateSelector(freshState);
          const normalizedData = camelizeKeys(data);

          if (TASK_STATUS.READY === normalizedData.status) {
            toast.success(
              <SuccessMessage
                taskId={normalizedData.id}
                projectId={normalizedData.projectId}
                locale={language}
              />,
              {
                autoClose: false,
                showCloseButton: true,
                position: 'top-right'
              }
            );
          } else if (TASK_STATUS.ERROR === normalizedData.status) {
            toast.error(
              <ErrorMessage
                taskId={normalizedData.id}
                projectId={normalizedData.projectId}
                locale={language}
              />,
              {
                autoClose: false,
                showCloseButton: true,
                position: 'top-right'
              }
            );
          }

          return [
            {
              type: 'project-responses-mass-actions-tasks/update-task-status',
              taskId,
              status: normalizedData.status,
              data: normalizedData
            }
          ];
        }),
        RxOperators.takeUntil(
          action$.pipe(
            ofType('project-responses-mass-actions-tasks/unwatch'),
            RxOperators.filter(
              unwatchAction => unwatchAction.taskId === action.taskId
            )
          )
        )
      );
    }),
    RxOperators.takeUntil(action$.pipe(ofType('auth/sign-out')))
  );

function SuccessMessage({
  projectId,
  taskId,
  locale,
  closeToast
}: {|
  projectId: number,
  taskId: number,
  locale: string,
  // eslint-disable-next-line react/require-default-props
  closeToast?: Function
|}) {
  const title = new IntlMessageFormat(
    projectResponsesDictionary[locale][
      'responsesMassActionsTasks.messages.taskIsReady'
    ]
  ).format({
    taskId,
    projectId
  });

  return (
    <div className="ui padded compact grid">
      <div className="row column">{title}</div>
      <div className="row column">
        <Button buttonType="basic" inverted fluid onClick={closeToast} compact>
          OK
        </Button>
      </div>
    </div>
  );
}

function ErrorMessage({
  taskId,
  projectId,
  locale,
  closeToast
}: {|
  taskId: number,
  projectId: number,
  locale: string,
  // eslint-disable-next-line react/require-default-props
  closeToast?: Function
|}) {
  const title = new IntlMessageFormat(
    projectResponsesDictionary[locale][
      'responsesMassActionsTasks.messages.taskIsFailed'
    ]
  ).format({
    taskId,
    projectId
  });

  return (
    <div className="ui padded compact grid">
      <div className="row column">{title}</div>
      <div className="row column">
        <Button buttonType="basic" inverted fluid onClick={closeToast} compact>
          OK
        </Button>
      </div>
    </div>
  );
}

export default watchMassActionTaskEpic;
