import revertDictionary from 'utils/revertDictionary';

const projectLogicDictionary = {
  'project.logicPanel.no_defined_conditions': {
    en: 'Project does not have conditions',
    ru: 'У проекта не запрограммировано ни одного условия!'
  },
  'project.logicPanel.title': {
    en: 'Project logic',
    ru: 'Логика проекта'
  },
  'project.logicPanel.table.columns.id': {
    en: '#',
    ru: '#'
  },
  'project.logicPanel.table.columns.title': {
    en: 'Title',
    ru: 'Название условия'
  },
  'project.logicPanel.table.columns.priority': {
    en: 'Priority',
    ru: 'Приоритет'
  },
  'project.logicPanel.table.columns.test_counter': {
    en: 'Tests count',
    ru: 'Количество тестов'
  },
  'project.logicPanel.table.columns.actions_and_targets': {
    en: 'Actions and target elements',
    ru: 'Действия и целевые элементы'
  },

  /**
   * This local strings are related to {@link saveLogicRule}
   */
  'project.logicPanel.saveLogicRule.success': {
    en: 'Logic rule has been saved',
    ru: 'Условие успешно сохранено'
  },
  'project.logicPanel.saveLogicRule.differentCountOfOpeningAndClosingBrackets': {
    en: 'There is a difference between amount of opening and closing brackets.',
    ru: 'Не соответствует количество открывающих и закрывающих скобок.'
  },
  'project.logicPanel.saveLogicRule.error': {
    en: 'Error occurred while saving',
    ru: 'Возникла ошибка при сохранении'
  }
};

export default revertDictionary(projectLogicDictionary);
