const dictionary = {
  'agitations.wavesList.headers.title': {
    ru: 'Название',
    en: 'Title'
  },
  'agitations.wavesList.headers.period': {
    ru: 'Период',
    en: 'Schedule'
  },
  'agitations.wavesList.headers.project': {
    ru: 'Проект',
    en: 'Project'
  },
  'agitations.wavesList.headers.status': {
    ru: 'Генерация встреч (статус задачи)',
    en: 'Meetings generation task status'
  }
};

export default dictionary;
