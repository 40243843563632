// @flow

import { combineReducers } from 'redux';
import type { Reducer } from 'flow-types/Reducer';
import type { ProjectExportState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type { ExportAction } from 'flow-types/actions/projects/detail/export';
import settingsList from './settingsList';
import tasks from './tasks';
import settingsForm from './settingsForm';

export default (combineReducers({
  settingsList,
  settingsForm,
  tasks
}): Reducer<ProjectExportState, ExportAction>);
