// @flow

import { createSelector } from 'reselect';
import find from 'lodash/find';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';

import { TYPES, getByType, isSelectable } from 'common/helpers/question';

import type { IQuestion } from 'flow-types/entities/Question';
import type {
  AccessSettingsState,
  FiltersSettingsState,
  ProjectDetailState,
  ProjectGroupsState,
  ProjectResponsesState,
  ProjectState,
  SearchSettingsState,
  VisibilitySettingsState
} from 'flow-types/states/ProjectsState/detail';

import {
  isActive,
  isOutdated,
  isPreparing
} from 'common/helpers/project/getStatus';
import type { StateSelector } from 'flow-types/Selector';
import type { LocationState } from 'redux-first-router/dist/flow-types';
import takeSecondArg from 'common/helpers/takeSecondArg';
import { locationStateSelector, projectDetailStateSelector } from '../index';

import isNewElement from '../../common/helpers/isNewElement';

// use with small lists to preserve performance
export const projectLogicSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState: ProjectDetailState) => projectsDetailState.logic
);

// returns project state from projectDetail
export const projectStateSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState): ProjectState => projectsDetailState.project
);

export const projectResponsesStateSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState: ProjectDetailState): ProjectResponsesState =>
    projectsDetailState.responses
);

export const projectLogicStateSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState: ProjectDetailState) => projectsDetailState.logic
);

export const projectPollingNotificationsStateSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState: ProjectDetailState) =>
    projectsDetailState.pollingNotifications
);

export const projectLogicFormStateSelector: StateSelector<> = createSelector(
  projectLogicStateSelector,
  logic => logic.form
);

export const projectGroupsStateSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectsDetailState: ProjectDetailState) => ({
    ...projectsDetailState.groups,
    data: map(projectsDetailState.groups.data, group => ({
      ...group,
      questions: map(group.questions, question => ({
        ...question,
        isQuestionGroupForNewMeetingsOnly: group.forNewMeetingsOnly,
        ...(isSelectable(question.type) && {
          options: map(question.options, option => ({
            questionGroupId: group.id,
            ...option
          }))
        })
      }))
    }))
  })
);

export const projectIdFromPathSelector: StateSelector<> = createSelector(
  locationStateSelector,
  (location: LocationState) => location.payload.projectId
);

export const projectIdAndGroupsSelector: StateSelector<> = createSelector(
  projectIdFromPathSelector,
  projectGroupsStateSelector,
  (projectId, groupsState) => ({
    projectId,
    groups: groupsState.data
  })
);

export const createQuestionsListSelector = (): StateSelector<IQuestion[]> =>
  createSelector(
    projectGroupsStateSelector,
    (groupsState: ProjectGroupsState) => {
      const { data } = groupsState;

      return data.map(group => group.questions ?? []).flat();
    }
  );

/**
 * For components, please use {@link createQuestionsListSelector}
 * @type {StateSelector<IQuestion[]>}
 */
export const questionsListSelector: StateSelector<> = createQuestionsListSelector();

export const selectableQuestionsListSelector: StateSelector<> = createSelector(
  questionsListSelector,
  questionsList =>
    getByType(questionsList, [
      TYPES.Checklist,
      TYPES.MultipleAnswer,
      TYPES.SingleAnswer,
      TYPES.Status,
      TYPES.Table
    ])
);

export const plainOptionsListSelector: StateSelector<> = createSelector(
  questionsListSelector,
  questions =>
    flattenDeep(
      questions.map((q, index) =>
        q.options.map(o => ({ ...o, questionIndex: index }))
      )
    )
);

export const projectFinalDataSelector: StateSelector<> = createSelector(
  projectStateSelector,
  projectState => {
    const { cache: projectCache, data: projectData } = projectState;

    return {
      ...(projectCache && projectCache),
      ...(projectData && projectData)
    };
  }
);

export const activeTabSelector: StateSelector<> = createSelector(
  locationStateSelector,
  location => (location.query ? location.query.t : null)
);

export const createProjectStatusSelector = (): StateSelector<> =>
  createSelector(projectStateSelector, ({ cache }: ProjectState) => {
    if (!cache) return null;

    return cache.status;
  });

/**
 * @deprecated use createProjectStatusSelector or useProjectStatus hook
 */
export const projectStatusSelector: StateSelector<> = createProjectStatusSelector();

export const projectIsSavedSelector: StateSelector<> = createSelector(
  [projectFinalDataSelector],
  projectData =>
    projectData && Object.values(projectData).length > 0
      ? !isNewElement(projectData)
      : null
);

export const projectHeaderActiveTabSelector: StateSelector<> = createSelector(
  activeTabSelector,
  projectStatusSelector,
  projectIdFromPathSelector,
  (tab, projectStatus: string, projectId) => {
    if (projectId === 'new') {
      return 'overview';
    }

    let initialTab = null;

    if (projectStatus) {
      if (isActive(projectStatus)) {
        initialTab = 'responses';
      } else if (isOutdated(projectStatus)) {
        initialTab = 'responses';
      } else if (isPreparing(projectStatus)) {
        initialTab = 'interview';
      }
    } else {
      initialTab = 'overview';
    }

    if (!tab) return initialTab;

    return tab;
  }
);

export const defaultTab$: StateSelector<> = createSelector(
  activeTabSelector,
  projectStatusSelector,
  (tab, projectStatus: string) => {
    let initialTab = null;

    if (projectStatus) {
      if (isActive(projectStatus)) {
        initialTab = 'responses';
      } else if (isOutdated(projectStatus)) {
        initialTab = 'responses';
      } else if (isPreparing(projectStatus)) {
        initialTab = 'interview';
      }
    } else {
      initialTab = 'overview';
    }

    return initialTab;
  }
);

export const questionsByGroupsStructureForDropdownSelector = (
  { valueKey, labelKey }: { valueKey: string, labelKey: string } = {
    valueKey: 'value',
    labelKey: 'label'
  }
): StateSelector<> =>
  createSelector(projectGroupsStateSelector, (groups: ProjectGroupsState) => {
    const { data } = groups;

    return data.reduce((result, current) => {
      if (isNewElement(current)) return result;

      return [
        ...result,
        {
          group: true,
          title: current.title,
          value: -1,
          options: current.questions
            ? current.questions.reduce((questionsList, currentQuestion) => {
                if (isNewElement(currentQuestion)) return questionsList;

                const { title, id, ...rest } = currentQuestion;

                return [
                  ...questionsList,
                  {
                    ...rest,
                    [valueKey]: id,
                    [labelKey]: title
                  }
                ];
              }, [])
            : []
        }
      ];
    }, []);
  });

export const projectEditabilitySelector: StateSelector<> = createSelector(
  projectFinalDataSelector,
  createProjectStatusSelector(),
  (projectState, projectStatus) =>
    isPreparing(projectStatus) && !projectState.loading
);

export const selectedQuestionDataSelector: StateSelector<> = createSelector(
  questionsListSelector,
  projectGroupsStateSelector,
  (
    questionsList: Array<IQuestion>,
    { selectedQuestionId }: ProjectGroupsState
  ) =>
    find(questionsList, (q: IQuestion) => q.id === selectedQuestionId) || null
);

export const selectedGroupDataSelector: StateSelector<> = createSelector(
  projectGroupsStateSelector,
  ({ data, selectedGroupId }: ProjectGroupsState) =>
    find(data, g => g.id === selectedGroupId)
);

export const projectVisibilitySettings: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectDetail: ProjectDetailState): VisibilitySettingsState =>
    projectDetail.visibilitySettings
);

export const projectFiltersSettingsSelectorCreator = (): StateSelector<> =>
  createSelector(
    projectDetailStateSelector,
    (projectDetail: ProjectDetailState): FiltersSettingsState =>
      projectDetail.filtersSettings
  );

export const projectAccessSettings: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectDetail: ProjectDetailState): AccessSettingsState =>
    projectDetail.accessSettings
);

export const projectSearchSettings: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectDetail: ProjectDetailState): SearchSettingsState =>
    projectDetail.searchSettings
);

export const projectExportTasks: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (detail: ProjectDetailState) => detail.export.tasks
);

export const projectExportSettingsList: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (detail: ProjectDetailState) => detail.export.settingsList
);

export const projectExportSettingsForm: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (detail: ProjectDetailState) => detail.export.settingsForm
);

export const projectPivotTablesSelector: StateSelector<> = createSelector(
  projectDetailStateSelector,
  (projectDetail: ProjectDetailState) => projectDetail.pivotTables
);

export const pivotTableOptionsSelector: StateSelector<> = createSelector(
  projectPivotTablesSelector,
  pivotTables => pivotTables.options
);

export const pivotTableStatisticSelector: StateSelector<> = createSelector(
  [projectPivotTablesSelector, takeSecondArg],
  (pivotTables, tableId) => pivotTables.statistic[tableId]
);
