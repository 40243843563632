// @flow

import * as React from 'react';
import cx from 'classnames';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { FomanticSize } from 'flow-types/FomanticSize';

type Props = {|
  +title?: null | string,
  +children?: React.Node,
  +icon?: null | string,
  +className?: null | string,
  +extraClassName?: string,
  +tagName?: ReactComponent,
  +attached?: boolean | 'top' | 'bottom',
  +floating?: boolean,
  +compact?: boolean,
  +visible?: boolean,
  +status?:
    | 'warning'
    | 'error'
    | 'success'
    | 'info'
    | 'positive'
    | 'negative'
    | string,
  +size?: FomanticSize
|};

const Message = ({
  title,
  children,
  icon,
  visible,
  className,
  attached,
  floating,
  compact,
  status,
  size,
  extraClassName,
  tagName: Component
}: Props): React.Node => {
  const classNames = React.useMemo(
    () =>
      cx(
        'ui',
        {
          icon: !!icon,
          floating,
          compact,
          // attached classes
          top: attached === 'top',
          bottom: attached === 'bottom',
          attached,
          ...(visible !== null && {
            hidden: !visible,
            visible
          })
        },
        size,
        status,
        className,
        'message',
        extraClassName
      ),
    [
      attached,
      className,
      compact,
      extraClassName,
      floating,
      icon,
      size,
      status,
      visible
    ]
  );

  if (!Component) return null;

  return (
    <Component className={classNames}>
      {icon && <i className={cx([icon], 'icon')} />}
      {icon && (
        <div className="content">
          {title && <div className="header">{title}</div>}
          {children}
        </div>
      )}
      {!icon && (
        <>
          {title && <div className="header">{title}</div>}
          {children}
        </>
      )}
    </Component>
  );
};

Message.defaultProps = {
  className: null,
  tagName: 'div',
  extraClassName: '',
  visible: false,
  status: null,
  size: null,
  title: null,
  children: null,
  icon: null,
  attached: false,
  floating: false,
  compact: false
};

export default Message;
