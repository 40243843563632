export async function exitFullScreen() {
  if (
    document.fullscreenElement !== null ||
    document.webkitFullscreenElement !== null
  ) {
    if (document.exitFullscreen) {
      await document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      await document.mozCancelFullScreen();
    } else if (document.mozExitFullScreen) {
      await document.mozExitFullScreen();
    } else if (document.webkitCancelFullScreen) {
      await document.webkitCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      await document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      await document.msExitFullscreen();
    }
  }
}

export async function requestFullScreen(element, options) {
  if (document.fullscreenElement) return;

  if (element.requestFullscreen) {
    element.requestFullscreen(options);
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen(options);
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen(options);
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen(options);
  }
}
