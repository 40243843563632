import revertDictionary from 'utils/revertDictionary';

const pivotTablesDictionary = {
  'pivotTables.title': {
    en: 'Pivot tables',
    ru: 'Сводные таблицы'
  },
  'pivotTables.buttons.create': {
    en: 'Create table',
    ru: 'Добавить таблицу'
  },
  'pivotTables.form.createTitle': {
    en: 'Create pivot table',
    ru: 'Создать сводную таблицу'
  },
  'pivotTables.form.title': {
    en: 'Title',
    ru: 'Название'
  },
  'pivotTables.form.rows': {
    en: 'Rows',
    ru: 'Строки'
  },
  'pivotTables.form.columns': {
    en: 'Columns',
    ru: 'Столбцы'
  },
  'pivotTables.form.addRows': {
    en: 'Add row',
    ru: 'Добавить строку'
  },
  'pivotTables.form.display': {
    en: 'Display',
    ru: 'Отобразить'
  },
  'pivotTables.form.abs': {
    en: 'Absolute values',
    ru: 'Абсолютные значения'
  },
  'pivotTables.form.percent': {
    en: 'Percent',
    ru: 'Процент'
  },
  'pivotTables.form.average': {
    en: 'Average',
    ru: 'Среднее'
  },
  'pivotTables.form.checklist': {
    en: 'Average by checklist',
    ru: 'Среднее по чеклистам'
  },
  'pivotTables.form.statisticsBy': {
    en: 'Statistics by',
    ru: 'Статистика по'
  },
  'pivotTables.form.statisticsByRows': {
    en: 'Rows',
    ru: 'Строкам'
  },
  'pivotTables.form.statisticsByColumns': {
    en: 'Columns',
    ru: 'Столбцам'
  },
  'pivotTables.form.statisticsByTable': {
    en: 'Table',
    ru: 'Таблице'
  },
  'pivotTables.form.addColumns': {
    en: 'Add column',
    ru: 'Добавить столбец'
  },
  'pivotTables.form.buttons.save': {
    en: 'Save',
    ru: 'Сохранить'
  },
  'pivotTables.form.buttons.cancel': {
    en: 'Cancel',
    ru: 'Отменить'
  },
  'pivotTables.form.buttons.delete': {
    en: 'Delete',
    ru: 'Удалить'
  },
  'pivotTables.form.confirmDelete': {
    en: 'Do you want to delete this table?',
    ru: 'Удалить таблицу?'
  },
  'pivotTables.failedToDisplay': {
    en: 'Failed to display a statistics table',
    ru: 'Не удалось отобразить таблицу статистики'
  },
  'pivotTables.noDepartment': {
    en: 'No department',
    ru: 'Нет отдела'
  },
  'pivotTables.noAnswer': {
    en: 'No answer',
    ru: 'Нет ответа'
  },
  'statisticTablesSettings.sources.fields': {
    en: 'Fields',
    ru: 'Поля'
  },
  'statisticTablesSettings.sources.questions': {
    en: 'Questions',
    ru: 'Блоки'
  },
  'statisticTablesSettings.sources.checklists': {
    en: 'Checklists',
    ru: 'Чеклисты'
  },
  'statisticTablesSettings.sources.checklistGroups': {
    en: 'Checklist Groups',
    ru: 'Чек.Группы'
  },
  'statisticTablesSettings.sources.checklistItems': {
    en: 'Checklist Items',
    ru: 'Чеки'
  }
};

export default revertDictionary(pivotTablesDictionary);
