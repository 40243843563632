import type { UsersListState } from 'flow-types/states/CompaniesState';
import type { CompaniesAction } from 'flow-types/actions/companies/companies';
import type { RemoveUserSuccess } from 'flow-types/actions/companies/users/RemoveUser';

const createInitialState: UsersListState = () => ({
  data: [],
  loading: false,
  filter: {
    ps: 5,
    pn: 0,
    sort: '-id'
  },
  pagination: {},
  selected: null,
  form: {
    visible: false,
    loading: false,
    data: {
      name: '',
      email: '',
      roleId: null
    },
    error: null
  }
});

const handleUserRemoved = (
  state: UsersListState,
  action: RemoveUserSuccess
): UsersListState => {
  const next = { ...state };

  const { userId } = action;

  if (next.form.visible) {
    if (next.form.data.id === userId) {
      next.form = createInitialState().form;
    }
  }

  return next;
};

export default (
  state: UsersListState = createInitialState(),
  action: CompaniesAction
) => {
  switch (action.type) {
    case 'companies/delete-user-success':
      return handleUserRemoved(state, action);
    case 'companies/select-company':
    case 'companies/select-department':
      return {
        ...state,
        data: []
      };
    case 'companies/save-user':
      return {
        ...state,
        form: {
          ...state.form,
          loading: true
        }
      };
    case 'companies/save-user-success':
      return {
        ...state,
        form: createInitialState().form
      };
    case 'companies/save-user-fail':
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          error: action.error
        }
      };
    case 'companies/add-new-user':
      return {
        ...state,
        form: {
          ...state.form,
          data: action.initialData,
          visible: true
        }
      };
    case 'companies/update-user-form':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.formUpdate,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          }
        }
      };
    case 'companies/edit-user-cancel':
      return {
        ...state,
        form: createInitialState().form
      };
    case 'companies/fetch-users':
      return {
        ...state,
        loading: true,
        ...(action.filterUpdate && {
          filter: {
            ...state.filter,
            ...action.filterUpdate
          }
        }),
        ...(action.filter && {
          filter: action.filter
        })
      };

    case 'companies/fetch-users-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'companies/fetch-users-success':
      return {
        ...state,
        loading: false,
        data: action.data,
        pagination: action.pagination
      };

    default:
      return state;
  }
};
