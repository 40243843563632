export const passwordChangeFormDictionary = {
  en: {
    'passwordChangeForm.password_old': 'Old password',
    'passwordChangeForm.password_new': 'New password',
    'passwordChangeForm.password_new_confirmation': 'Password confirmation'
  },
  ru: {
    'passwordChangeForm.password_old': 'Текущий пароль',
    'passwordChangeForm.password_new': 'Новый пароль',
    'passwordChangeForm.password_new_confirmation': 'Подтверждение пароля'
  }
};
