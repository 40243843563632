// @flow

import type { $ObjOfType } from '../../../flow-types/ObjOfType';

type ComputedPropertyDescription = {
  /**
   * How variable is called in database
   * @example
   * varchar
   * integer
   * decimal
   */
  code: string,
  /**
   * Whether variable-length has to be set?
   * @example
   * varchar(24)
   * integer
   */
  measurable: boolean
};

export const VARIABLE_TYPES: $ObjOfType<ComputedPropertyDescription> = {
  // TODO: uncomment later
  // varchar: {
  //   code: 'varchar',
  //   measurable: true
  // },
  text: {
    code: 'text',
    measurable: false
  },
  decimal: {
    code: 'decimal',
    measurable: true
  }
  // TODO: uncomment later
  // integer: {
  //   code: 'integer',
  //   measurable: false
  // }
  // TODO: implement these types,
  //  also they're currently disabled on server
  // timestamp: {
  //   code: 'timestamp',
  //   measurable: false
  // },
  // date: {
  //   code: 'date',
  //   measurable: false
  // }
};
