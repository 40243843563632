// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import type { Epic } from 'flow-types/Epic';
import type { SelectRegion } from 'flow-types/actions/records/SelectRecordRegion';
import type { AppState } from '../../flow-types/AppState';

import isNewRegion from '../../pages/Project/helpers/isNewRegion';
import { MODAL_ID } from '../../pages/Project/components/Modals/RegionHasUnsavedChangesModal';

import { recordsRegionsStateSelector } from '../../selectors/records';

const selectRegionEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('records-regions/select'),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([action, state]: [SelectRegion, AppState]) => {
      const { regionId } = action;

      const { data, selected } = recordsRegionsStateSelector(state);

      if (regionId === null) {
        return of({
          type: 'records-regions/set-selected',
          regionId: null
        });
      }

      const targetRegion = find(data, { id: regionId });

      if (!targetRegion) return EMPTY;

      if (!selected) {
        return of({
          type: 'records-regions/set-selected',
          regionId,
          recordId: targetRegion.recordId
        });
      }

      const sourceRegion = find(data, { id: selected });

      if (!sourceRegion) {
        return of({
          type: 'records-regions/set-selected',
          regionId,
          recordId: targetRegion.recordId
        });
      }

      const isNew = isNewRegion(sourceRegion);

      if (isNew) {
        return of({
          type: 'modals/open',
          modalId: MODAL_ID,
          data: {
            sourceRegionId: selected,
            targetRegionId: regionId
          }
        });
      }

      const { cache, ...rest } = sourceRegion;

      // no cache is an evidence for 'non changed' state
      const isChanged = cache ? !isEqual(cache, rest) : false;

      if (isChanged) {
        return of({
          type: 'modals/open',
          modalId: MODAL_ID,
          data: {
            sourceRegionId: selected,
            targetRegionId: regionId
          }
        });
      }

      return of({
        type: 'records-regions/set-selected',
        regionId,
        recordId: targetRegion.recordId
      });
    })
  );

export default selectRegionEpic;
