import uniqueId from 'lodash/uniqueId';
import { v4 as uuid } from 'uuid';
import isNewElement from './isNewElement';

// I introduced useUUID to slightly come from lodash's uniqueId to uuid
export const generateNewData = <T = Object>(data: T, useUUID: boolean) => ({
  ...(data && data),
  isNew: true,
  id: !useUUID ? +uniqueId('-') : uuid()
});

export const filterNewGeneratedProperties = (item: {
  id?: number | string,
  isNew?: boolean
}) => {
  const result = { ...item };

  if (isNewElement(result)) {
    delete result.id;
    delete result.isNew;
  }

  return result;
};
