import { useLayoutEffect } from 'react';

const useDidMount = callback => {
  useLayoutEffect(() => {
    if (!callback) {
      return;
    }
    callback();
    // eslint-disable-next-line
  }, []);
};

export default useDidMount;
