// @flow

import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import type { IComputedProperty } from 'flow-types/entities/ComputedProperty';

type IRawComputedProperty = {
  id: number,
  title: string,
  code: string,
  var_type: string,
  var_args: string[],
  formula: string
};

const processVarArgs = (raw: Object): Object => ({
  ...raw,
  varTypeArgs: Array.isArray(raw.varTypeArgs) ? raw.varTypeArgs : []
});

/**
 * This time, I've decided to call transducer 'toClient' on basis of the fact,
 * that it normalizes data from backend format to client format.
 */
const toClient: IRawComputedProperty => IComputedProperty = fp.compose(
  processVarArgs,
  camelizeKeys
);

const toClientMap: (
  Array<IRawComputedProperty>
) => Array<IComputedProperty> = fp.map(toClient);

export default toClientMap;
