import fp from 'lodash/fp';

import type {
  AccessSettings,
  AccessSettingsForm,
  QuestionAccessSettings,
  QuestionGroupAccessSettings
} from 'flow-types/entities/AccessSettings';
import { ACCESS_STATUSES } from 'common/helpers/project/constants';

// creates access field
export const processAccessField = (
  accessSettings: QuestionAccessSettings | QuestionGroupAccessSettings
) => {
  const { isVisible, isEditable, ...rest } = accessSettings;

  let access = null;

  if (!isVisible) {
    access = ACCESS_STATUSES.HIDDEN;
  } else if (isVisible && !isEditable) {
    access = ACCESS_STATUSES.DISABLED;
  } else if (isVisible && isEditable) {
    access = ACCESS_STATUSES.EDITABLE;
  }

  return {
    ...rest,
    access
  };
};

const normalizeQuestionsGroupsAccessSettings = fp.compose(
  fp.keyBy('questionGroupId'),
  fp.map(processAccessField)
);

const normalizeQuestionsAccessSettings = fp.compose(
  fp.keyBy('questionId'),
  fp.map(processAccessField)
);

export const normalizeAccessSettings = (
  data: AccessSettings
): AccessSettingsForm => {
  const {
    questionGroupsAccess,
    questionsAccess,
    title,
    id,
    isDefault,
    projectId
  } = data;

  return {
    id,
    isDefault,
    title,
    projectId,
    questionGroupsAccess: normalizeQuestionsGroupsAccessSettings(
      questionGroupsAccess
    ),
    questionsAccess: normalizeQuestionsAccessSettings(questionsAccess)
  };
};
