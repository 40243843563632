import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';

const axisToClient = (axis = 'rows') => settingsObj => {
  let { settings } = settingsObj;

  settings = {
    ...settings,
    [axis]:
      settings?.[axis]?.map(settingsRow => {
        if (settingsRow.checklistId) {
          return `ch:${settingsRow.checklistId}`;
        }

        if (settingsRow.checklistGroupId) {
          return `chg:${settingsRow.checklistGroupId}`;
        }

        if (settingsRow.checklistItemId) {
          return `chi:${settingsRow.checklistItemId}`;
        }

        if (settingsRow.field) {
          return `f:${settingsRow.field}`;
        }

        return `q:${settingsRow.questionId}`;
      }) ?? []
  };

  return { ...settingsObj, settings };
};

const toClient = fp.compose(
  axisToClient('rows'),
  axisToClient('columns'),
  camelizeKeys
);

export const toClientMap = fp.map(toClient);

export default toClient;
