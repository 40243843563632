import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import * as Operators from 'rxjs/operators';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import { ProjectsListAction } from 'flow-types/actions/projects/list';
import type { IProject } from 'flow-types/entities/Project';
import { pageStateSelector } from '../../../selectors';

const cloneProjectEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project/clone'),
    Operators.withLatestFrom($state),
    Operators.mergeMap(([action, state]) => {
      const { name: page } = pageStateSelector(state);
      const { project, updateData }: ProjectsListAction = action;
      return request({
        url: API.projects.clone.replace(':project_id', project.id),
        method: 'POST',
        body: updateData
      }).pipe(
        Operators.mergeMap((successResponse: AjaxResponse) => {
          const { response } = successResponse;
          const { id }: IProject = response;
          if (page === 'Project') {
            return of(
              { type: 'project/clone-success' },
              {
                type: 'router/projectPage',
                payload: { projectId: id }
              }
            );
          }
          return of(
            { type: 'project/clone-success' },
            { type: 'projectsList/fetch-projects' }
          );
        }),
        Operators.catchError(({ response, message }: AjaxError) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'project/clone-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

export default cloneProjectEpic;
