// @flow

import { useMemo } from 'react';

import {
  TYPES,
  TYPES_WITH_INSTANT_NAVIGATION_SUPPORT
} from '../helpers/question';

export type UseQuestionType = {|
  isHTML: boolean,
  isURL: boolean,
  isOneAnswer: boolean,
  isMultipleAnswer: boolean,
  isRangingAnswer: boolean,
  isRating: boolean,
  isTextBlock: boolean,
  isEmail: boolean,
  isMobilePhone: boolean,
  isSecondaryPhones: boolean,
  isChecklist: boolean,
  isVideo: boolean,
  isDateTime: boolean,
  isSignature: boolean,
  isTable: boolean,
  // the same as isOneAnswer and isMultiAnswer, but for TYPES.Table
  isOneAnswerTable: boolean,
  // the same as isOneAnswer and isMultiAnswer, but for TYPES.Table
  isMultiAnswerTable: boolean,
  isRedirect: boolean,
  isNumeric: boolean,
  isSearchView: boolean,
  isTabView: boolean,
  isInstantNavigationPossible: boolean,
  isStatus: boolean
|};

// it accepts type as a number and returns an object containing boolean fields
// it also accepts subType, i.e. for subQuestion
const useQuestionType = (
  type: number,
  subType?: ?number = null
): UseQuestionType =>
  useMemo<UseQuestionType>(
    () => ({
      isHTML: type === TYPES.HTML,
      isURL: type === TYPES.URL,
      isOneAnswer: type === TYPES.SingleAnswer,
      isMultipleAnswer: type === TYPES.MultipleAnswer,
      isRangingAnswer: type === TYPES.Ranging,
      isRating: type === TYPES.Rating,
      isTextBlock: type === TYPES.TextBlock,
      isEmail: type === TYPES.Email,
      isMobilePhone: type === TYPES.MobilePhone,
      isSecondaryPhones: type === TYPES.SecondaryPhones,
      isChecklist: type === TYPES.Checklist,
      isVideo: type === TYPES.Video,
      isDateTime: type === TYPES.DateTime,
      isSignature: type === TYPES.Signature,
      isTable: type === TYPES.Table,
      isOneAnswerTable: type === TYPES.Table && subType === TYPES.SingleAnswer,
      isMultiAnswerTable:
        type === TYPES.Table && subType === TYPES.MultipleAnswer,
      isRedirect: type === TYPES.Redirect,
      isNumeric: type === TYPES.Numeric,
      isSearchView: type === TYPES.SearchView,
      isTabView: type === TYPES.TabView,
      isInstantNavigationPossible:
        TYPES_WITH_INSTANT_NAVIGATION_SUPPORT.includes(type) ||
        (type === TYPES.Table &&
          TYPES_WITH_INSTANT_NAVIGATION_SUPPORT.includes(subType)),
      isStatus: type === TYPES.Status
    }),
    [subType, type]
  );

export default useQuestionType;
