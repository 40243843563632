import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import { normalizeVisibilitySettings } from 'common/transducers/projects/visibilitySettings/normalizer';

import { API } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import type { FetchVisibilitySettings } from 'flow-types/actions/projects/detail/visibilitySettings/FetchVisibilitySettings';
import type { VisibilitySettings } from 'flow-types/entities/VisibilitySettings';

const fetchVisibilitySettings: Epic = action$ =>
  action$.pipe(
    ofType('project/fetch-visibility-settings'),
    RxOperators.concatMap((action: FetchVisibilitySettings) => {
      const { settingsId } = action;

      const fetcher$ = request({
        url: interpolateString(API.userFieldsSettings.detail, { settingsId }),
        method: 'GET'
      });

      return fetcher$.pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map(rawVisibilitySettings => {
          const visibilitySettings: VisibilitySettings = normalizeVisibilitySettings(
            rawVisibilitySettings
          );

          return {
            type: 'project/fetch-visibility-settings-success',
            settingsId,
            visibilitySettings
          };
        }),
        RxOperators.catchError(() => [
          { type: 'project/fetch-visibility-settings-fail', settingsId }
        ])
      );
    })
  );

export default fetchVisibilitySettings;
