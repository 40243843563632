// @flow

import * as React from 'react';
import cx from 'classnames';
import type { NilValue } from 'flow-types/NilValue';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { ReactChildren } from 'flow-types/ReactChildren';

type Props = {
  threaded?: boolean,
  minimal?: boolean,
  size?: NilValue | string,
  tagName: ReactComponent,
  className?: null | string,
  children: ReactChildren
};

const Comments: React.AbstractComponent<Props, HTMLElement> = React.forwardRef(
  (
    { threaded, minimal, className, size, tagName = 'div', ...props }: Props,
    ref
  ) => {
    const Component = tagName ?? 'div';

    return (
      <Component
        {...props}
        className={cx('ui', { threaded, minimal }, size, className, 'comments')}
        ref={ref}
      />
    );
  }
);

export default Comments;

// $FlowIgnore
Comments.defaultProps = {
  threaded: false,
  minimal: false,
  size: null,
  className: null,
  tagName: 'div'
};
