// @flow
import cx from 'classnames';
import type { FomanticSize } from 'flow-types/FomanticSize';

type InputClasses = {
  nonUI?: boolean,
  fluid?: boolean,
  action?: 'left' | 'right' | false | null,
  corner?: 'left' | 'right' | false | null,
  labeled?: 'left' | 'right' | false | null,
  inverted?: boolean,
  transparent?: boolean,
  disabled?: boolean | null,
  focus?: boolean | null,
  color?: string | false | null,
  icon?: 'left' | 'right' | false | null,
  size?: FomanticSize,
  loading?: boolean | null,
  error?: boolean | null,
  className?: string | null
};

const getInputClassName = ({
  nonUI,
  fluid,
  action,
  corner,
  icon,
  labeled,
  inverted,
  transparent,
  disabled,
  focus,
  color,
  className,
  size,
  loading,
  error
}: InputClasses = {}): string =>
  cx(
    { ui: !nonUI },
    {
      fluid
    },
    {
      left: action === 'left',
      right: action === 'right',
      action
    },
    {
      left: corner === 'left',
      corner
    },
    {
      left: icon === 'left',
      icon
    },
    {
      right: labeled === 'right',
      labeled
    },
    { inverted, transparent, disabled, focus },
    color,
    className,
    size,
    'input',
    { loading },
    { error }
  );

export default getInputClassName;
