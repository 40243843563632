// @flow

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Node } from 'react';
import { merge } from 'merge-anything';
import { isMobile } from 'is-mobile';
import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';
import isSelected from 'common/helpers/isSelected';
import Icon from 'common/components/Icon';
import Dropdown from 'common/components/Dropdown';
import Field from 'common/components/Form/Field';
import Label from 'common/components/Label';
import Input from 'common/components/Input';

import useQuestionType from 'common/hooks/useQuestionType';
import LazyImage from 'common/components/Image/lazy';
import {
  PolygonImage,
  StyledImage,
  StyledPolygon,
  StyledPolygonSvg
} from 'common/components/Question/styled';
import { useResizeDetector } from 'react-resize-detector';
import useProgressiveImage from 'common/components/Image/useProgressiveImage';
import { scalePolygon } from 'common/components/Question/helpers/scalePolygon';
import SelectionModal from 'common/components/SelectionModal';
import { useSelection } from 'common/hooks/useSelection';

import type { IInterviewQuestion } from 'flow-types/entities/Question';

type Props = {
  question: IInterviewQuestion,
  onAnswerInput: Function
};

const menuListItemStyleGetter = (item: Object) => ({
  color: item.settings?.appearance?.textColor ?? null,
  backgroundColor: item.settings?.appearance?.backgroundColor ?? null
});

export default function DropdownSelectionQuestionBody({
  question,
  onAnswerInput
}: Props): Node {
  const {
    options,
    disabled,
    otherTitle,
    otherEnabled,
    type,
    showImages,
    settings
  } = question;

  const { isMultipleAnswer, isChecklist } = useQuestionType(type);

  const multi = isMultipleAnswer || isChecklist;

  const answer = useAnswer(question.id, {
    // TODO: allow to pass used value type ot useAnswer,
    //  so that it will be clear what values types
    //  are considered to be used here
    // $FlowIgnore
    data: !multi ? null : [],
    meta: {
      other: ''
    }
  });

  const { data, meta } = answer;

  const [otherCache, setCache] = useState<mixed>(meta ? meta.other : '');

  // if onUpdate is not defined, all update actions are redundant
  const onUpdate = useAnswerUpdate(question.id);

  // if question has been changed,
  // then we should clear cache
  useEffect(() => {
    setCache(meta?.other ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id]);

  const changeOther = useCallback(
    nextOtherValue => {
      if (!onUpdate) return;
      setCache(nextOtherValue);
      onUpdate(
        merge(
          { ...answer },
          {
            errors: null,
            meta: {
              other: nextOtherValue
            }
          }
        )
      );
    },
    [answer, onUpdate]
  );

  const {
    // когда за раз меняется статус выборки нескольких элементов
    handleSelectionByMulti,
    // показывает, выбрана ли исключающая опция
    isExcludingItemSelected,
    handleUserInput
    // текущие выбранные, приходят также в nextAnswer в onChange
    // selected
    // текущие вводы от пользователя в опции, которые принимают значения
    // userInputs
  } = useSelection({
    items: options,
    // will be used as a source of truth for selected
    selected: data,
    // will be used as a source of truth for userInputs
    userInputs: meta,
    // вызывается, когда меняется текст опции с "польз.вводом"
    // понадобится если нужно сохранять "другое" в мете ответа, когда оно меняется
    onUserInputChange: (nextAnswer, nextUserInput) => {
      changeOther(nextUserInput?.other);
    },
    // вызывается, когда меняется выборка опций
    onSelectionChange: (
      nextSelected,
      _,
      { isExcludingItemSelected: isExcludingOptionSelected }
    ) => {
      // если выбрана опция "другое", то берётся её текст из второго аргумента и вставляется в мету
      // если нет, то ничего
      const hasOther = isSelected(nextSelected, 'other', multi);

      let updates = [{ data: nextSelected, errors: null }];

      if (!hasOther) {
        updates = [...updates, { meta: { other: null } }];
      } else {
        updates = [...updates, { meta: { other: otherCache ?? '' } }];
      }

      const nextAnswer = merge({ ...answer }, ...updates);

      onUpdate(nextAnswer);

      if (onAnswerInput) {
        onAnswerInput(nextAnswer, {
          isExcludingOptionSelected
        });
      }
    },
    multi,
    max:
      (question.maxAnswers ?? 0) && +question.maxAnswers > 0
        ? +question.maxAnswers
        : null,
    // будет показывать сообщение о превышении лимита выборки
    // при попытке выбрать более, чем {max} элементов,
    // не применяется если {max} === null || {max} === 0,
    // так как это противоречит самой идее что-то выбрать.
    showMaxWarningOnExceedingAttempt: true
  });

  const [imageParams, setImageParams] = useState({ width: null, height: null });
  const { width, height, ref: polygonImgRef } = useResizeDetector();

  const imageSrc = settings?.imageSrc ?? null;

  useProgressiveImage({
    src: imageSrc,
    previewSrc: imageSrc,
    onReceive: image => {
      setImageParams({ width: image.width, height: image.height });
    }
  });

  const polygonImageStyle = React.useMemo(() => ({ height }), [height]);

  const otherValue = meta?.other ?? '';

  const itemLazyProps = useMemo(
    () => ({ scrollContainer: `scroll-box-${question.id}` }),
    [question.id]
  );

  const renderItem = useCallback(
    item => {
      if (!item.userInput) {
        if (showImages && item.image) {
          return (
            <>
              <LazyImage
                lazyProps={itemLazyProps}
                rounded
                avatar
                src={item.image.thumb128}
              />
              {item.title}
            </>
          );
        }

        return item.title;
      }

      return (
        <>
          <Icon icon="pencil" />
          {item.title}
        </>
      );
    },
    [itemLazyProps, showImages]
  );

  const containerRef = React.useRef<?HTMLDivElement>(null);

  const [selectionModalVisible, setSelectionModalVisibility] = React.useState(
    false
  );

  const isMobileParams = React.useRef({ tablet: true, featureDetect: true });

  // TODO: can br used as a top-level context
  const isMobileDevice = isMobile(isMobileParams.current);

  React.useEffect(() => {
    if (!isMobileDevice) return;

    const handler = event => {
      // do not open modal if either 'remove' icon (clears selection) or 'close' icon (removes element from selection)
      // has been clicked
      if (
        event.target.classList.contains('remove') ||
        event.target.classList.contains('close')
      ) {
        return;
      }

      event.target.blur();
      setSelectionModalVisibility(true);
      event.stopPropagation();
    };

    const container = containerRef.current;

    // $FlowIgnore
    container.addEventListener('click', handler);

    // eslint-disable-next-line consistent-return
    return () => {
      // $FlowIgnore
      container.removeEventListener('click', handler);
    };
  }, [isMobileDevice]);

  const items = options
    .filter(o => !o.hidden)
    .map(o => {
      if (
        isExcludingItemSelected &&
        (!o.isExcluding || !isSelected(data, o.id, multi, multi))
      ) {
        return {
          ...o,
          disabled: true
        };
      }

      return o;
    });

  return (
    <>
      <div className="question__options">
        {imageSrc && (
          <PolygonImage
            data-swipe-ignore="true"
            className="polygon-image"
            style={polygonImageStyle}
          >
            <div className="polygon-image__inner">
              <StyledImage src={imageSrc} alt="" ref={polygonImgRef} />
              <div>
                {imageParams.width && width && (
                  <>
                    <StyledPolygonSvg xmlns="http://www.w3.org/2000/svg">
                      {items.map(
                        ({ polygon, disabled: isOptionDisabled, id }) => {
                          if (!polygon) return null;

                          const isSelectedPolygon = isSelected(data, id, multi);

                          const points = scalePolygon(
                            polygon,
                            imageParams.width / width
                          );

                          return (
                            <StyledPolygon
                              key={`polygon-${id}`}
                              onClick={() => {
                                if (disabled || isOptionDisabled) return;

                                handleSelectionByMulti(id);
                              }}
                              points={points}
                              selected={isSelectedPolygon}
                              hovered={false}
                            />
                          );
                        }
                      )}
                    </StyledPolygonSvg>
                  </>
                )}
              </div>
            </div>
          </PolygonImage>
        )}
        <Dropdown
          ref={containerRef}
          fluid
          onlyValue
          valueKey="id"
          labelKey="title"
          getMenuListItemStyle={menuListItemStyleGetter}
          value={data}
          multiple={multi}
          disabled={disabled}
          onChange={handleSelectionByMulti}
          options={items}
          renderItem={renderItem}
          data-swipe-ignore="true"
          resetValue={!multi ? null : []}
          className={`scroll-box-${question.id}`}
        />
        {otherEnabled && isSelected(data, 'other', multi) && (
          <Field>
            <Label nonUI htmlFor={`q-${question.id}-other-title`}>
              {otherTitle}
            </Label>
            <Input
              id={`q-${question.id}-other-title`}
              onChange={otherVal => handleUserInput('other', otherVal)}
              value={otherValue}
              onlyValue
              disabled={disabled}
            />
          </Field>
        )}
      </div>
      <SelectionModal
        visible={selectionModalVisible}
        data={options ?? []}
        onClose={() => setSelectionModalVisibility(false)}
        max={question.maxAnswers}
        onSubmit={(nextData, userInputs) => {
          // Здесь мы уже получаем точные данные,
          // так как сам по себе модал селекции знает такие вещи как
          // исключающие опции, минимальная и максимальная выборка
          // и опции с пользовательским вводом значения для опции.

          const nextAnswer = merge(
            { ...answer },
            {
              data: nextData,
              meta: (multi
              ? nextData?.includes('other')
              : nextData === 'other')
                ? { other: userInputs.other }
                : { other: '' }
            }
          );

          onUpdate(nextAnswer);

          setSelectionModalVisibility(false);

          onAnswerInput?.(nextAnswer);
        }}
        initialSelected={data}
        initialUserInputs={meta}
        multi={multi}
      />
    </>
  );
}
