// @flow
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { SignOut } from 'flow-types/actions/auth/SignOut';
import type { PageState } from 'flow-types/states/PageState';
import removeToken, { removeLocalToken } from 'utils/removeToken';
import { EMPTY, of } from 'rxjs';
import { LOGIN_FORM_DIALOG } from 'common/containers/LoginForm/Modal';
import type { AppState } from '../../flow-types/AppState';
import { pageStateSelector } from '../../selectors';
import type { OpenModal } from '../../pages/Modals/modals';

export const signOutEpic = ($action, $state) =>
  $action.pipe(
    ofType('auth/sign-out'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([action, state]: [SignOut, AppState]) => {
      const { local, manual } = action;

      const { key: routeId }: PageState = pageStateSelector(state);

      if (!manual) {
        // on all pages, except those,
        // that involve PostPolling and PublicPolling
        if (routeId !== 'interviewsPage') {
          return ([
            { type: 'modals/open', modalId: LOGIN_FORM_DIALOG }
          ]: OpenModal[]);
        }
      }

      if (local) {
        removeLocalToken();
      } else {
        removeToken();
      }

      // In case of `Login` page there is no need to repeatedly return to the same page.
      //
      // In case of `Interviews` page only PostPollings and PublicPollings
      // are displayed there.
      // Also, those polling types have after-completion page,
      // Another reason is that Posts and Publics are used by individuals,
      // who may not have an system account,
      // thus there is no need to take a user to Login page.
      if (routeId === 'loginPage' || routeId === 'interviewsPage') {
        return EMPTY;
      }

      return of({ type: 'router/loginPage' });
    })
  );
