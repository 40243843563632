import { EMPTY } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

import type { Epic } from 'flow-types/Epic';
import type { ILogicResults } from 'flow-types/entities/LogicResults';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { InterviewLogicState } from 'flow-types/states/InterviewState/InterviewLogicState';

import { interviewQuestionsSelector } from '../../selectors/interview/questions';
import {
  interviewAnswersSelector,
  interviewLogicStateSelector
} from '../../selectors/interview/root';

const lastCachedResults: { data: ILogicResults } = {
  data: {}
};

const updateCacheOnCompleteAttempt: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/move'),
    RxOperators.filter(action => action.complete),
    RxOperators.withLatestFrom(state$),
    // eslint-disable-next-line no-unused-vars
    RxOperators.mergeMap(([_, state]) => {
      const {
        lastComputedResults
      }: InterviewLogicState = interviewLogicStateSelector(state);

      lastCachedResults.data = { ...lastComputedResults };

      return EMPTY;
    })
  );

const refreshAnswerValidationOnActiveStackChange: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/change-active-stack'),
    RxOperators.withLatestFrom(state$),
    // eslint-disable-next-line no-unused-vars
    RxOperators.concatMap(([_, state]) => {
      const {
        lastComputedResults
      }: InterviewLogicState = interviewLogicStateSelector(state);

      const answers = interviewAnswersSelector(state);

      const questions = interviewQuestionsSelector(state);

      const differenceBetween = difference(
        lastCachedResults.data.requiredQuestions,
        lastComputedResults.requiredQuestions
      );

      // if we have questions that lost their required status (inside logic state of course)
      if (differenceBetween.length > 0) {
        const updatedAnswers = {};

        differenceBetween.forEach(questionId => {
          const question = find(questions, { id: questionId });

          // required questions by default are always required
          // and are not controlled via logic
          if (question && !!question.required) {
            return;
          }

          if (answers[questionId]) {
            answers[questionId].errors = filter(
              answers[questionId].errors,
              error =>
                // remove all errors which tell us those questions to be required
                error.messageId !== 'question.validation.required'
            );

            updatedAnswers[questionId] = answers[questionId];
          }
        });

        // update cached results
        lastCachedResults.data = cloneDeep(lastComputedResults);

        return [
          {
            type: 'interview/set-answers-validation',
            validation: reduce(
              updatedAnswers,
              (result, answer: IInterviewAnswer, questionId) => ({
                ...result,
                [questionId]: answer.errors.length > 0 ? answer.errors : null
              }),
              {}
            )
          }
        ];
      }

      // update cached results
      lastCachedResults.data = cloneDeep(lastComputedResults);

      // case for no changes
      return EMPTY;
    })
  );

export default combineEpics(
  updateCacheOnCompleteAttempt,
  refreshAnswerValidationOnActiveStackChange
);
