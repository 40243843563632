import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  getMeetingStageMessageId,
  MEETING_STAGE
} from 'common/helpers/response';
import Dropdown from '../../../../../common/components/Dropdown';

type Props = {|
  value: number | Array<number>,
  valueKey?: string,
  labelKey?: string,
  onChange: Function,
  onlyValue: boolean
|};

const ResponseMeetingStagesSelector = ({
  value,
  valueKey,
  labelKey,
  onChange,
  onlyValue,
  ...rest
}: Props): React.Node => {
  const intl = useIntl();

  const options = React.useMemo(
    () => [
      {
        [valueKey]: MEETING_STAGE.IN_PROGRESS,
        [labelKey]: intl.formatMessage({
          id: getMeetingStageMessageId(MEETING_STAGE.IN_PROGRESS)
        })
      },
      {
        [valueKey]: MEETING_STAGE.COMPLETED_NO_DATA,
        [labelKey]: intl.formatMessage({
          id: getMeetingStageMessageId(MEETING_STAGE.COMPLETED_NO_DATA)
        })
      },
      {
        [valueKey]: MEETING_STAGE.COMPLETED_WITH_DATA,
        [labelKey]: intl.formatMessage({
          id: getMeetingStageMessageId(MEETING_STAGE.COMPLETED_WITH_DATA)
        })
      }
    ],
    [intl, labelKey, valueKey]
  );

  const handleChange = React.useCallback(
    next => {
      if (onlyValue && next) {
        onChange(Array.isArray(next) ? next.map(i => +i) : +next);
      } else if (next) {
        onChange(
          Array.isArray(next) ? next.map(i => ({ ...i, value: +i })) : next
        );
      } else {
        onChange(next);
      }
    },
    [onChange, onlyValue]
  );

  return (
    <Dropdown
      {...rest}
      options={options}
      valueKey={valueKey}
      labelKey={labelKey}
      onChange={handleChange}
      onlyValue={onlyValue}
      value={value}
    />
  );
};

ResponseMeetingStagesSelector.defaultProps = {
  labelKey: 'label',
  valueKey: 'value'
};

export default ResponseMeetingStagesSelector;
