// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import IFM from 'intl-messageformat';
import { createSelector } from 'reselect';
import size from 'lodash/size';
import filter from 'lodash/filter';
import { of } from 'rxjs';
import { toast } from 'react-toastify';

import {
  locateLogicBrackets,
  logicStructureFormatter,
  replaceBracketsWithArray
} from 'common/transducers/logic/logicStructureFormatter';

import logicDenormalizer from 'common/transducers/projects/logicDenormalizer';
import responseParser from 'common/epicHelpers/responseParser';
import logicNormalizer from 'common/transducers/projects/logicNormalizer';
import interpolateString from 'common/helpers/interpolateString';
import validateLogicRule from 'common/validators/validateLogicRule';
import parseYupValidation from 'common/transducers/parseYupValidation';

import request from 'utils/request';
import { API } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import setLogicFormValidation from '../../../actions/project/logic/setLogicFormValidation';
import {
  projectIdFromPathSelector,
  projectLogicFormStateSelector
} from '../../../selectors/projects';
import projectLogicDictionary from '../../../intl/projectLogicDictionary';
import { languageStateSelector } from '../../../selectors';

const selector = createSelector(
  projectIdFromPathSelector,
  projectLogicFormStateSelector,
  (projectId, logicForm) => ({ projectId, logicForm })
);

const saveLogicRule: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project/submit-logic-form'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([, state]): any => {
      const locale = languageStateSelector(state);

      const { projectId, logicForm } = selector(state);

      const { data } = logicForm;

      const condition = {
        projectId,
        ...data
      };

      try {
        validateLogicRule(condition, true);
      } catch (e) {
        return of(
          setLogicFormValidation(parseYupValidation(e, { structured: true })),
          {
            type: 'project/submit-logic-form-fail'
          }
        );
      }

      let tests = locateLogicBrackets(condition.tests);

      const openingBracketsNum = size(filter(tests, t => t === '('));
      const closingBracketsNum = size(filter(tests, t => t === ')'));

      if (openingBracketsNum !== closingBracketsNum) {
        toast.error(
          new IFM(
            projectLogicDictionary[locale][
              'project.logicPanel.saveLogicRule.differentCountOfOpeningAndClosingBrackets'
            ]
          ).format(),
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          }
        );

        return of({
          type: 'project/submit-logic-form-fail'
        });
      }

      tests = logicStructureFormatter(replaceBracketsWithArray(tests));

      const body = logicDenormalizer({ ...condition, tests });

      const method = condition.id ? 'PUT' : 'POST';

      return request({
        url: !condition.id
          ? API.projects.projectRule.replace(':rule_id', '')
          : interpolateString(
              API.projects.projectRule,
              {
                rule_id: condition.id
              },
              ':'
            ),
        method,
        body
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        // TODO: complete Flow typing
        RxO.mergeMap((savedCondition): any => {
          toast.success(
            new IFM(
              projectLogicDictionary[locale][
                'project.logicPanel.saveLogicRule.success'
              ]
            ).format(),
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );

          return of(
            {
              type: 'project/submit-logic-form-success',
              conditionId: condition.id || null,
              condition: logicNormalizer(savedCondition)
            },
            {
              type: 'project/fetch-logic'
            }
          );
        }),
        // TODO: complete Flow typing
        RxO.catchError((): any => {
          toast.error(
            new IFM(
              projectLogicDictionary[locale][
                'project.logicPanel.saveLogicRule.error'
              ]
            ).format(),
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'project/submit-logic-form-fail'
          });
        })
      );
    })
  );

export default saveLogicRule;
