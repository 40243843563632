// @flow

import updateFilter from '../../common/helpers/updateFilter';

import type { ChecklistGroupsAction } from '../../flow-types/actions/checks/checklistGroups';
import type { TestGroupsState } from '../../flow-types/states/ChecksState';
import type { SelectChecklist } from '../../flow-types/actions/checks/checklists/SelectChecklist';

const initialState: TestGroupsState = {
  data: [],
  filter: {
    sort: '-id'
  },
  pagination: {
    totalPages: 1,
    totalElements: 0,
    activePage: 0
  },
  loading: false,
  selected: null,
  form: {
    visible: false,
    data: null,
    error: null,
    loading: false,
    cache: null
  }
};

export default (
  state: TestGroupsState = initialState,
  action: ChecklistGroupsAction | SelectChecklist
) => {
  switch (action.type) {
    case 'checklist-test-groups/save-fail':
      return {
        ...state,
        form: {
          ...state.form,
          error: action.error,
          loading: false
        }
      };

    case 'checklists/select':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          updater: { checklistId: action.checklistId || null }
        }),
        data: []
      };

    case 'checklist-test-groups/edit':
      return {
        ...state,
        form: {
          data: action.testGroup,
          visible: true,
          cache: action.testGroup
        }
      };

    case 'checklist-test-groups/update':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          }
        }
      };

    case 'checklist-test-groups/create':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...action.initialData
          },
          visible: true
        }
      };

    case 'checklist-test-groups/delete-success':
      return {
        ...state,
        // $FlowIgnore
        data: state.data.filter(group => group.id !== action.originalId),
        form: initialState.form
      };
    case 'checklist-test-groups/save-success':
    case 'checklist-test-groups/reset-form':
      return {
        ...state,
        form: initialState.form
      };

    case 'checklist-test-groups/fetch':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'checklist-test-groups/fetch-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'checklist-test-groups/fetch-success':
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        pagination: action.pagination
      };
    case 'checklist-test-groups/select':
      return {
        ...state,
        selected: action.testGroupId
      };
    default:
      return state;
  }
};
