// @flow
import { createSelector } from 'reselect';
import type {
  ProjectDetailState,
  ProjectReportsState
} from 'flow-types/states/ProjectsState/detail';
import { projectDetailStateSelector } from '../index';

const projectReports: Function = createSelector(
  projectDetailStateSelector,
  (detailState: ProjectDetailState) => detailState.reports
);

export const reportsLoadingSelector: Function = createSelector(
  projectReports,
  (reports: ProjectReportsState) => reports.loading
);

export const reportsDataSelector: Function = createSelector(
  projectReports,
  (reports: ProjectReportsState) => reports.data
);

export const reportsFilterSelector: Function = createSelector(
  projectReports,
  (reports: ProjectReportsState) => reports.filter
);
