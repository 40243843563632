import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import type {
  FieldVisibilitySettings,
  IFieldVisibilitySettings$Unified,
  VisibilitySettings
} from 'flow-types/entities/VisibilitySettings';
import sortingItemsNormalizer from 'common/transducers/sortingItemsNormalizer';

const normalizeFieldsVisibilitySettings = fp.compose(
  fp.reduce(
    (
      fields,
      fieldSettings: FieldVisibilitySettings
    ): IFieldVisibilitySettings$Unified => {
      const { fieldName, questionId, ...rest } = fieldSettings;

      if (fieldName) {
        return {
          ...fields,
          [fieldName]: {
            id: fieldName,
            type: 'property',
            ...rest
          }
        };
      }
      if (questionId) {
        return {
          ...fields,
          [questionId]: {
            id: questionId,
            type: 'block',
            // blocks does not have isVisible property
            isVisible: true,
            ...rest
          }
        };
      }

      return fields;
    },
    {}
  ),
  items => sortingItemsNormalizer(items, false, true)
);

const normalizeVisibilitySettingsFields = (
  visibilitySettings: VisibilitySettings
) => ({
  ...visibilitySettings,
  fields: normalizeFieldsVisibilitySettings(visibilitySettings.fields)
});

export const normalizeVisibilitySettings = fp.compose(
  normalizeVisibilitySettingsFields,
  camelizeKeys
);

const normalizeVisibilitySettingsList = fp.map(normalizeVisibilitySettings);

export default normalizeVisibilitySettingsList;
