// @flow

export const INTERVIEW_STATUS = {
  // indicates that interview is in progress,
  // meaning that timer is ticking
  RUNNING: 'running',
  // interview is stopped
  STOPPED: 'stopped',
  // interview is paused
  PAUSED: 'paused',
  // POST_POLLING ONLY
  // indicates that access to post polling is expired
  // no blocks are visible
  ACCESS_EXPIRED: 'expired',
  // POST_POLLING ONLY
  // indicates that post polling has been already completed
  // no blocks are visible
  ACCESS_CLOSED: 'closed',
  NOT_STARTED: null
};

export const INTERVIEW_APPEARANCE = Object.freeze({
  WEB: 'web',
  POST: 'post',
  PUBLIC: 'public',
  EDIT: 'edit',
  MOBILE: 'mobile',
  CRM: 'crm'
});

export const INTERVIEW_STAGE = {
  // main past of an interview,
  // that can be either executed by registered users or via public link
  // indicates that only main phase blocks should be visible
  MAIN: 'MAIN',
  // post polling is a second and optional phase of an interview,
  // when a respondent (client) move through polling manually
  // indicates that only post polling phase blocks should be visible
  POST_POLLING: 'POST_POLLING',
  // indicates that main and post polling phase blocks should be visible
  MIXED: 'MIXED'
};

export const INTERVIEW_MODE = {
  /*
   Is used for test purposes.
   If page has been opened via a valid link, but project has 'editing' status,
   then LOCAL would not be set, instead REMOTE is used.
  */
  LOCAL: 'local',
  // is used for view purposes,
  // no question is editable,
  // no views are registered,
  // no submits are requested
  VIEW: 'view',
  // is used for edit purposes,
  // only editable questions can be edited
  // non editable questions are visitable, but disabled
  EDIT: 'edit',
  // is used by default to go through an interview,
  // all questions are editable (except those, locked by logic),
  REMOTE: 'remote'
};

export const INTERVIEW_BLOCK_ROLES_IDS = Object.freeze({
  /**
   * Starting Message
   */
  STARTING: 1,
  /**
   * Regular Block
   */
  BLOCK: 2,
  /**
   * Block where session submit happens
   */
  FINISHING: 3,
  /**
   * Block that is displayed, after session is submitted successfully
   */
  ENDING: 4
});

export const INTERVIEW_MOVE_EPIC_REQUIRED_DELAY = 300;

export const INTERVIEW_HISTORY_ACTION_TYPES = Object.freeze({
  CREATED: 1,
  UPDATED: 2,
  REMOVED: 3,
  EXITED_PAGE: 4,
  ENTERED_PAGE: 5
});
