// @flow
import * as React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Modal from 'common/components/animated/Modal';

import Icon from 'common/components/Icon';
import List from 'common/components/List';
import Button from 'common/components/Button';
import Flexbox from 'common/components/Flexbox';
import Input, { InputWrapper } from 'common/components/Input';
import ModernOption from 'common/components/Question/Options/Option/ModernOption';
import ModernOther from 'common/components/Question/Options/Other/ModernOther';

import useFuse from 'common/hooks/useFuse';
import { useSelection } from 'common/hooks/useSelection';

import isSelected from 'common/helpers/isSelected';

import type { $ObjOfType } from 'flow-types/ObjOfType';

const StyledList = styled.div`
  & + .apply-btn-container {
    position: absolute;
    bottom: 3rem;
    left: 0;
    right: 0;
    text-align: center;
  }

  padding-bottom: 4rem !important;
`;

type SelectionModalProps = {
  /**
   * is multiple options can be selected
   */
  multi: boolean,
  initialSelected: any,
  initialUserInputs: any,
  /**
   * list of all available options
   */
  data: Object[],
  onClose: Function,
  /**
   * returns new selection
   */
  onSubmit: (data: string[], userInput: $ObjOfType<?string>) => void,
  visible: boolean,
  max: number
};

export default function SelectionModal({
  data,
  initialSelected,
  initialUserInputs,
  max,
  multi,
  onClose,
  onSubmit,
  visible
}: SelectionModalProps): React.Node {
  const intl = useIntl();

  const options = React.useRef({
    shouldSort: false,
    tokenize: false,
    keys: ['label', 'title', 'userInput'],
    useExtendedSearch: true
  });

  const [items, setVisibleItems] = React.useState(data);

  const [selected, setSelected] = React.useState(multi ? [] : null);

  const [userInputs, setUserInputs] = React.useState<$ObjOfType<?string>>({});

  React.useEffect(() => {
    if (visible) {
      setVisibleItems(data);
      // set selected
      setSelected(multi ? initialSelected ?? [] : initialSelected ?? null);
      // set user inputs
      setUserInputs(initialUserInputs ?? {});
    }

    return () => {
      setSelected([]);
      setUserInputs({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const fuseItems = React.useMemo(
    () =>
      data.map(item => ({ ...item, userInput: userInputs?.[item.id] ?? null })),
    [data, userInputs]
  );

  const fuse = useFuse(fuseItems, options.current);

  const [query, setQuery] = React.useState('');

  const search = React.useCallback(
    nextQuery => {
      setQuery(nextQuery);

      setVisibleItems(
        nextQuery
          ? fuse
              .search({
                $or: [
                  {
                    label: `=${query}`
                  },
                  {
                    title: `=${query}`
                  },
                  {
                    label: `'${query}`
                  },
                  {
                    title: `'${query}`
                  },
                  {
                    userInput: `'${query}`
                  },
                  {
                    userInput: `=${query}`
                  }
                ]
              })
              .map(i => i.item)
          : data
      );
    },
    [data, fuse, query]
  );

  const dimmerProps = React.useRef({ fixed: true, page: true });

  const {
    handleSelectionByMulti,
    handleUserInput,
    isExcludingItemSelected
  } = useSelection({
    items: data,
    // $FlowIgnore
    selected,
    userInputs,
    max,
    showMaxWarningOnExceedingAttempts: true,
    multi,
    onSelectionChange: nextSelected => {
      setSelected(nextSelected);
    },
    onUserInputChange: (_, nextUserInput) => {
      setUserInputs(nextUserInput);
    }
  });

  return (
    <Modal
      visible={visible}
      size="fullscreen"
      className="no-actions"
      onClose={onClose}
      overlay
      dimmerProps={dimmerProps.current}
      portal
      effect="slideUp"
      duration={visible ? 300 : 150}
    >
      <Modal.Header>
        <Flexbox $direction="row">
          <Button buttonType="tertiary" icon size="huge" onClick={onClose}>
            <Icon icon="arrow left" />
          </Button>
          <Flexbox alignSelf="center" ignoreDisplay grow={1}>
            <InputWrapper fluid transparent>
              <Input
                placeholder={intl.formatMessage({
                  id: 'common.labels.search.placeholder'
                })}
                onlyValue
                onChange={search}
                value={query}
              />
            </InputWrapper>
          </Flexbox>
        </Flexbox>
      </Modal.Header>
      <Modal.Content scrolling>
        <List
          as={StyledList}
          className="selection max-w-full relaxed-alt bordered-items rounded-items-sm border-blue compact-items"
        >
          {items.map(option => {
            let { disabled } = option;

            const isOptionSelected = multi
              ? selected?.includes(option.id)
              : selected === option.id;

            if (
              isExcludingItemSelected &&
              (!option.isExcluding ||
                !isSelected(selected, option.id, multi, true))
            ) {
              disabled = true;
            }

            const onSelect = () => handleSelectionByMulti(option.id);

            // Instead of checking for id === 'other',
            // I've decided to check for userInput
            if (option.userInput) {
              return (
                <ModernOther
                  data={userInputs?.[option.id] ?? ''}
                  disabled={disabled}
                  onSelect={onSelect}
                  onChange={otherValue =>
                    handleUserInput(option.id, otherValue)
                  }
                  selected={isOptionSelected}
                  title={option.title}
                />
              );
            }

            return (
              <ModernOption
                key={option.id}
                selected={isOptionSelected}
                data={option}
                onSelect={onSelect}
                disabled={disabled}
              />
            );
          })}
        </List>
        {(multi ? selected?.length > 0 : !!selected) && (
          <div className="apply-btn-container">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                // $FlowIgnore
                onSubmit(selected, userInputs);
              }}
            >
              OK
            </Button>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
