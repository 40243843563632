/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useTheme } from 'styled-components';
import map from 'lodash/map';
import size from 'lodash/size';

import type { IInterviewOption } from '../../../../flow-types/entities/QuestionOption';

import {
  PolygonImage,
  StyledImage,
  StyledPolygon,
  StyledPolygonSvg
} from '../styled';
import { scalePolygon } from '../helpers/scalePolygon';
import useProgressiveImage from '../../Image/useProgressiveImage';
import isSelected from '../../../helpers/isSelected';

import ImageOption from './ImageOption';
import OtherOption from './Other';
import Option from './Option';
import Card from '../../Card';
import List from '../../List';

type Props = {|
  showLabels: boolean,
  showImages: boolean,
  multi: boolean,
  disabled: boolean,
  items: Array<IInterviewOption>,
  selected: Array<number | 'other'>,
  otherOption: string | null,
  onOtherOptionChange: Function,
  onSelect: Function,
  imageSrc: ?string,
  showTextOptions: boolean
|};

const Options = ({
  showLabels,
  showImages,
  multi,
  items,
  selected,
  onSelect,
  otherOption,
  onOtherOptionChange,
  disabled,
  imageSrc,
  showTextOptions
}: Props) => {
  const theme = useTheme();

  const [imageParams, setImageParams] = useState({ width: null, height: null });
  const [hovered, setHovered] = useState(null);
  const { width, height, ref: polygonImgRef } = useResizeDetector();

  useProgressiveImage({
    src: imageSrc,
    previewSrc: imageSrc,
    onReceive: image => {
      setImageParams({ width: image.width, height: image.height });
    }
  });

  const polygonImageStyle = React.useMemo(() => ({ height }), [height]);

  if (showImages) {
    return (
      <Card className="question__options" colCount="four" doubling group>
        {map(items, (opt: IInterviewOption) => (
          <ImageOption
            key={opt.id}
            onSelect={!disabled && onSelect}
            showLabel={showLabels}
            radio={!multi}
            data={opt}
            disabled={
              disabled || (!isSelected(selected, opt.id, multi) && opt.disabled)
            }
            selected={isSelected(selected, opt.id, multi)}
          />
        ))}
      </Card>
    );
  }

  const componentsTheme = theme?.componentsTheme ?? 'classic';

  const isUsingModernTheme = componentsTheme === 'modern';

  return (
    <div className="question__options">
      {imageSrc && (
        <PolygonImage
          data-swipe-ignore="true"
          className="polygon-image"
          style={polygonImageStyle}
        >
          <div className="polygon-image__inner">
            <StyledImage src={imageSrc} alt="" ref={polygonImgRef} />
            <div>
              {imageParams.width && width && (
                <>
                  <StyledPolygonSvg xmlns="http://www.w3.org/2000/svg">
                    {items
                      // other option is successfully filtered out here
                      .filter(({ polygon }) => polygon)
                      .map(({ polygon, disabled: isOptionDisabled, id }) => (
                        <StyledPolygon
                          key={`polygon-${id}`}
                          onClick={() => {
                            if (isOptionDisabled) return;

                            onSelect(id);
                          }}
                          points={scalePolygon(
                            polygon,
                            imageParams.width / width
                          )}
                          selected={isSelected(selected, id, multi)}
                          hovered={hovered === id}
                        />
                      ))}
                  </StyledPolygonSvg>
                </>
              )}
            </div>
          </div>
        </PolygonImage>
      )}
      <List
        horizontal={showImages}
        selection={isUsingModernTheme}
        className={
          isUsingModernTheme
            ? 'max-w-full sm:max-w-3/4 lg:max-w-1/2 relaxed-alt bordered-items rounded-items-sm border-blue compact-items'
            : null
        }
      >
        {!showImages && (imageSrc ? showTextOptions : true) && (
          <>
            {map(items, (opt: IInterviewOption, index) => {
              const type = multi ? 'checkbox' : 'radio';
              if (opt.id === 'other') {
                return (
                  <OtherOption
                    key={opt.id}
                    theme={componentsTheme}
                    index={size(items) + 1}
                    type={type}
                    data={otherOption}
                    title={opt.title}
                    onSelect={!disabled && onSelect}
                    onChange={onOtherOptionChange}
                    selected={isSelected(selected, opt.id, multi)}
                    disabled={opt.disabled || disabled}
                  />
                );
              }

              return (
                <Option
                  key={opt.id}
                  theme={componentsTheme}
                  index={index + 1}
                  disabled={
                    disabled ||
                    (!isSelected(selected, opt.id, multi) && opt.disabled)
                  }
                  selected={isSelected(selected, opt.id, multi)}
                  data={opt}
                  onSelect={!disabled && onSelect}
                  onHover={!imageSrc ? null : () => setHovered(opt.id)}
                  onLeave={!imageSrc ? null : () => setHovered(null)}
                  type={type}
                />
              );
            })}
          </>
        )}
      </List>
    </div>
  );
};

export default Options;
