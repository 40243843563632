import revertDictionary from 'utils/revertDictionary';

const appDictionary = revertDictionary({
  'app.siteTitle': {
    en: 'Formalist',
    ru: 'Формалист'
  }
});

export default appDictionary;
