// @flow

import React, { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import isNewElement from 'common/helpers/isNewElement';

import Modal from 'common/components/animated/Modal';
import useModal from 'common/hooks/useModal';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';

import type { DimmerProps } from 'common/components/animated/Dimmer';
import type {
  PollingNotificationsDetailState$Status,
  PollingNotificationsState
} from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import type { IPollingNotification } from 'flow-types/entities/PollingNotification';

import type { TogglePollingNotification } from 'flow-types/actions/projects/detail/pollingNotifications/detail/TogglePollingNotification';
import { POLLING_NOTIFICATION_STATUS } from 'common/helpers/project/constants';
import { projectPollingNotificationsStateSelector } from '../../../../selectors/projects';

import PollingNotificationForm from '../Forms/PollingNotificationForm';

import {
  MODAL_FORM_TITLE_CREATE,
  MODAL_FORM_TITLE_EDIT
} from '../Panels/PollingNotificationsPanel/PollingNotificationPanel.dictionary';
import PollingNotificationSwitchStatusButton from '../Panels/PollingNotificationsPanel/PollingNotificationSwitchStatusButton';

export const MODAL_ID = 'pollingNotificationModal';

const dimmerProps: DimmerProps = {
  fixed: true,
  page: true,
  scrolling: true
};

const formStatusSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (state: PollingNotificationsState) => {
    if (
      ['saving', 'deleting', 'activating', 'deactivating'].includes(
        state.detail.status
      )
    ) {
      return 'loading';
    }

    return 'idle';
  }
);

const statusSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (state: PollingNotificationsState) => state.detail.status
);

const formDataSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (state: PollingNotificationsState) => {
    if (state.detail.data === null) {
      return undefined;
    }

    return state.detail.data;
  }
);

const notificationStatusSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (
    pollingNotificationsState: PollingNotificationsState
  ): $PropertyType<IPollingNotification, 'status'> | null => {
    const { detail } = pollingNotificationsState;

    return detail.data?.status || null;
  }
);

const formValidationSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (state: PollingNotificationsState) => state.detail.validation
);

export default function PollingNotificationModal() {
  const [state, , close] = useModal(MODAL_ID);

  const dispatch = useDispatch();

  const notificationStatus = useSelector(notificationStatusSelector);

  const formStatus = useSelector(formStatusSelector);

  const status: PollingNotificationsDetailState$Status = useSelector(
    statusSelector
  );

  const data = useSelector(formDataSelector);

  const validation = useSelector(formValidationSelector);

  const update = useCallback(
    (updatedData: $Shape<IPollingNotification>) => {
      dispatch({
        type: 'project/update-polling-notification',
        data: updatedData
      });
    },
    [dispatch]
  );

  const save = useCallback(() => {
    dispatch({
      type: 'project/save-polling-notification'
    });
  }, [dispatch]);

  const remove = useCallback(() => {
    if (!data) return;

    dispatch({
      type: 'project/delete-polling-notification',
      notificationId: data.id
    });
  }, [data, dispatch]);

  const toggle = useCallback(
    nextStatus => {
      dispatch(
        ({
          type: 'project/toggle-polling-notification',
          status: nextStatus
        }: TogglePollingNotification)
      );
    },
    [dispatch]
  );

  return (
    <Modal
      onClose={
        ['saving', 'deleting', 'deactivating', 'activating'].includes(status)
          ? null
          : close
      }
      dimmerProps={dimmerProps}
      visible={state.visible}
      portal
    >
      <Modal.Header>
        <FormattedMessage
          id={
            isNewElement(data) ? MODAL_FORM_TITLE_CREATE : MODAL_FORM_TITLE_EDIT
          }
        />
      </Modal.Header>
      <Modal.Content>
        <PollingNotificationForm
          data={data}
          status={formStatus}
          validation={validation}
          onChange={update}
          onSubmit={save}
        />
      </Modal.Content>
      <Modal.Actions>
        {!!data && !isNewElement(data) && (
          <>
            <PollingNotificationSwitchStatusButton
              status={notificationStatus}
              disabled={status === 'saving' || status === 'deleting'}
              loading={status === 'deactivating' || status === 'activating'}
              floated="left"
              buttonType={
                notificationStatus === POLLING_NOTIFICATION_STATUS.STOPPED
                  ? 'basic primary'
                  : 'basic negative'
              }
              onChange={toggle}
            />
            <Button
              color="negative"
              onClick={remove}
              icon
              labeled
              side="right"
              loading={status === 'deleting'}
              disabled={
                status === 'saving' ||
                status === 'deactivating' ||
                status === 'activating'
              }
            >
              <FormattedMessage id="common.labels.delete" />
              <Icon icon="remove" />
            </Button>
          </>
        )}
        <Button
          color="black"
          onClick={close}
          disabled={[
            'saving',
            'deleting',
            'deactivating',
            'activating'
          ].includes(status)}
        >
          <FormattedMessage id="common.labels.cancel" />
        </Button>
        <Button
          color="black"
          onClick={save}
          buttonType="positive"
          loading={status === 'saving'}
          disabled={
            status === 'deleting' ||
            status === 'deactivating' ||
            status === 'activating'
          }
          icon
          labeled
          side="right"
        >
          <FormattedMessage id="common.labels.submit" />
          <Icon icon="checkmark" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
