// @flow
import { createSelector } from 'reselect';

import registerSelectors from 'utils/registerSelectors';

import type {
  InterviewDirectionState,
  InterviewState
} from 'flow-types/states/InterviewState';
import type { InterviewRegistryState } from 'flow-types/states/InterviewState/InterviewRegistryState';
import type { InterviewRecordState } from 'flow-types/states/InterviewState/InterviewRecordState';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { InterviewLogState } from 'flow-types/states/InterviewState/InterviewLogState';
import type { StateSelector } from 'flow-types/Selector';
import type { $ObjOfType } from 'flow-types/ObjOfType';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { InterviewStructureState } from 'reducers/interview/structure';

import { interviewStateSelector } from '../index';

export const interviewDirectionSelector: StateSelector<> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): InterviewDirectionState =>
    interviewState.direction
);

export const interviewRegistrySelector: StateSelector<InterviewRegistryState> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): InterviewRegistryState =>
    interviewState.registry
);

export const interviewLogStateSelector: Function = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): InterviewLogState => interviewState.log
);

export const interviewRecordSelector: StateSelector<> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): InterviewRecordState =>
    interviewState.record
);

export const interviewLogicStateSelector: StateSelector<> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState) => interviewState.logic
);

export const interviewActiveStackSelector: StateSelector<> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): IInterviewStructureElement =>
    interviewState.activeStack
);

export const interviewProjectDataSelector: StateSelector<> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState) => interviewState.project.data
);

export const interviewAnswersSelector: StateSelector<
  $ObjOfType<IInterviewAnswer>
> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState) => interviewState.answers
);

export const interviewStructureSelector: StateSelector<InterviewStructureState> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState) => interviewState.structure
);

registerSelectors({
  interviewDirectionSelector,
  interviewRegistrySelector,
  interviewLogStateSelector,
  interviewRecordSelector,
  interviewLogicStateSelector,
  interviewActiveStackSelector,
  interviewProjectDataSelector,
  interviewAnswersSelector,
  interviewStructureSelector
});
