import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import useModal from '../../../../common/hooks/useModal';
import Modal from '../../../../common/components/animated/Modal';
import Icon from '../../../../common/components/Icon';
import Button from '../../../../common/components/Button';
import { interviewStatusesSelector } from '../../../../selectors/interview/statuses';

export const MODAL_ID = 'blockIsRequiredDialog';

const dimmerProps = {
  page: true,
  fixed: true
};

const canForceForwardMove = createSelector(
  interviewStatusesSelector,
  statuses => !statuses.isPublicPolling && !statuses.isPostPolling
);

export default function BlockIsRequiredDialog() {
  const dispatch = useDispatch();

  const [{ visible, data: modalData }, , close] = useModal(MODAL_ID);

  const registerScrollTarget = targetId => {
    // TODO: does not guarantee that block will be scrolled into view...
    //  maybe its better to pass here issued block id that is located on that page or null.
    dispatch({
      type: 'interview-ui/set-body-scroll-target',
      targetId
    });
  };

  const canForceForward = useSelector(canForceForwardMove);

  const handleMoveClick = useCallback(() => {
    if (!modalData || !modalData.originalAction) {
      return;
    }
    close();
    dispatch({ ...modalData.originalAction, force: true });
    // take state.data.originalAction
    // dispatch it again with force flag
  }, [close, dispatch, modalData]);

  return (
    <Modal basic visible={visible} onClose={close} dimmerProps={dimmerProps}>
      <Modal.Header icon>
        <Icon icon="exclamation triangle" />
        <FormattedMessage
          id="interview.dialogs.blockIsRequired.title"
          values={{ newLine: <br /> }}
        />
      </Modal.Header>
      <Modal.Actions>
        {canForceForward && (
          <Button onClick={handleMoveClick}>
            <FormattedMessage id="common.labels.goTo" />
          </Button>
        )}
        <Button
          buttonType="primary"
          onClick={() => {
            close();

            if (modalData?.firstIssueQuestionId) {
              registerScrollTarget(modalData?.firstIssueQuestionId);
            }
          }}
        >
          <FormattedMessage id="interview.dialogs.blockIsRequired.actions.stayOnPage" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
