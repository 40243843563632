// @flow
import { merge } from 'merge-anything';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import isDevelopment from 'utils/isDevelopment';

export type LoginFormState = {
  data: {
    email: string,
    password: string,
    rememberMe: boolean
  },
  validation: null | {
    email: Array<mixed>,
    password: Array<mixed>
  },
  isLoading: boolean
};

export type LoginFormAction =
  | { type: 'LOGIN_REQUEST' }
  | { type: 'LOGIN_REQUEST_SUCCESS' }
  | { type: 'LOGIN_REQUEST_FAIL' }
  | { type: 'UPDATE_FORM_DATA', dataUpdate: $Shape<Object> }
  | { type: 'SET_FORM_VALIDATION', validation: Object };

export default function loginFormReducer(
  state: LoginFormState,
  action: LoginFormAction
) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return merge({ ...state }, { isLoading: true });
    case 'LOGIN_REQUEST_FAIL':
      return merge({ ...state }, { isLoading: false });
    case 'LOGIN_REQUEST_SUCCESS':
      return merge({ ...state }, { isLoading: false });
    case 'UPDATE_FORM_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.dataUpdate
        },
        validation: reduce(
          state.validation,
          (result, fieldValidation, fieldName) => {
            const updatedKeys = Object.keys(action.dataUpdate);

            if (includes(updatedKeys, fieldName)) {
              return result;
            }

            return {
              ...(result && result),
              [fieldName]: fieldValidation
            };
          },
          null
        )
      };
    case 'SET_FORM_VALIDATION':
      return {
        ...state,
        validation: action.validation
      };
    default:
      if (isDevelopment) {
        throw new Error('DEFAULT CASE IN REDUCER HOOK');
      }
      return state;
  }
}
