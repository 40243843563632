// @flow
import type { Node } from 'react';
import React from 'react';
import cx from 'classnames';

type Props = {|
  children: Node,
  +type?: 'default' | '' | string,
  +className?: string | null,
  +inverted?: boolean,
  +size?: null | string,
  +color?: string,
  +nonUI?: boolean,
  +as?: string,
  +style: null | Object
|};

function Text(props: Props): Node {
  const {
    children,
    type,
    className,
    nonUI,
    as: Container = 'span',
    inverted,
    color,
    size,
    style
  } = props;

  const classes = cx(
    { ui: !nonUI, inverted },
    size,
    type,
    className,
    color,
    'text'
  );

  return (
    <Container className={classes} style={style}>
      {children}
    </Container>
  );
}

Text.defaultProps = {
  as: 'span',
  className: null,
  inverted: false,
  color: '',
  type: '',
  nonUI: true,
  size: null,
  style: null
};

export default Text;
