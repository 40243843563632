import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getQuestionTypeLocaleMessageId } from 'common/helpers/question';
import { TableCell, TableRow } from 'common/components/Table';
import Checkbox from 'common/components/Checkbox';

import type { IQuestion } from 'flow-types/entities/Question';
import type { AccessSettings } from 'flow-types/entities/AccessSettings';
import { useAccessTypes } from './AccessTypesProvider';

type Props = {|
  color: string,
  data: IQuestion,
  settings: AccessSettings,
  onChange: (questionId: number, access: string) => void,
  disabled?: boolean
|};

export default function QuestionAccessSettings({
  data,
  settings,
  onChange,
  color,
  disabled
}: Props) {
  const accessTypes = useAccessTypes();

  const questionId = useMemo(() => data.id, [data.id]);

  const questionAccess = useMemo(() => {
    if (!settings) return null;

    const { questionsAccess } = settings;

    if (!questionsAccess || !questionsAccess[questionId]) {
      return null;
    }

    return questionsAccess[questionId].access;
  }, [questionId, settings]);

  const name = useMemo(() => `question-${questionId}-access`, [questionId]);

  return (
    <TableRow className={`left marked ${color}`}>
      <TableCell />
      <TableCell>{data.title}</TableCell>
      <TableCell>
        <FormattedMessage id={getQuestionTypeLocaleMessageId(data.type)} />
      </TableCell>
      {accessTypes.map(type => (
        <TableCell key={type.value}>
          <Checkbox
            radio
            fitted
            disabled={disabled}
            name={name}
            value={questionAccess === type.value}
            onChange={() => onChange(questionId, type.value)}
          />
        </TableCell>
      ))}
    </TableRow>
  );
}

QuestionAccessSettings.defaultProps = {
  disabled: false
};
