// @flow

import { of } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { toast } from 'react-toastify';
import request from 'utils/request';
import { API } from 'utils/config';
import { projectGroupsStateSelector } from 'selectors/projects';

import type { Epic } from 'flow-types/Epic';
import type {
  DeleteQuestionGroup,
  DeleteQuestionGroupSuccess
} from 'flow-types/actions/projects/detail/structure/questionGroups/DeleteQuestionGroup';

const removeGroupEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/delete-group'),
    RxOperators.withLatestFrom($state),
    RxOperators.mergeMap(([action, state]: [DeleteQuestionGroup, State]) => {
      const { data: groups } = projectGroupsStateSelector(state);

      const { groupId } = action;

      const group = groups.find(g => g.id === groupId);

      if (group.isNew) {
        return of({
          type: 'project-groups/delete-group-success',
          groupId: group.id
        });
      }

      return request({
        url: API.questionGroups.detail.replace(':question_group_id', group.id),
        method: 'DELETE'
      }).pipe(
        RxOperators.map((): DeleteQuestionGroupSuccess => ({
          type: 'project-groups/delete-group-success',
          groupId
        })),
        RxOperators.catchError(({ response, message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'project-groups/delete-group-fail',
            errorResponse: response ? response.data : message
          });
        })
      );
    })
  );

export default removeGroupEpic;
