// @flow

import unset from 'lodash/unset';
import { merge } from 'merge-anything';

export type ModalId = string;

export type ModalState<T> = {
  // major visibility state
  visible: boolean,
  // this is a some kind of data, that should be passed to modal,
  // this field allows to avoid props passage inside component
  data: T
};

export type ModalsState = {
  [modalId: ModalId]: ModalState<mixed>
};

export type OpenModal = {|
  type: 'modals/open',
  modalId: ModalId,
  data: mixed
|};

export type CloseModal = {|
  type: 'modals/close',
  modalId: ModalId,
  // should data be resetted,
  // if 'true', then data will be simply removed
  dataReset?: boolean
|};

export type UpdateModalData = {
  type: 'modals/update-data',
  modalId: ModalId,
  dataUpdate: mixed
};

export type ModalsAction = OpenModal | UpdateModalData | CloseModal;

const openModal = (state: ModalsState, action: OpenModal): ModalsState => {
  const { modalId, data } = action;

  if (!state[modalId]) {
    return {
      ...state,
      [modalId]: {
        visible: true,
        data
      }
    };
  }

  return {
    ...state,
    [modalId]: {
      ...state[modalId],
      visible: true,
      data
    }
  };
};

const closeModal = (state: ModalsState, action: CloseModal): ModalsState => {
  const { modalId, dataReset } = action;

  if (!state[modalId]) return state;

  const nextModalState = {
    ...state[modalId],
    visible: false
  };

  if (dataReset) {
    unset(nextModalState, 'data');
  }

  return {
    ...state,
    [modalId]: nextModalState
  };
};

const updateModalData = (
  state: ModalsState,
  action: UpdateModalData
): ModalsState => {
  const { modalId, dataUpdate } = action;

  // if modal is not present inside modalsReducer,
  // then data update for that modal will be ignored
  if (!state[modalId]) return state;

  const { [modalId]: modalState } = state;

  const updatedModalState = merge({ ...modalState }, { data: dataUpdate });

  return {
    ...state,
    [modalId]: updatedModalState
  };
};

export default function modalsReducer(
  state: ModalsState = {},
  action: ModalsAction
): ModalsState {
  switch (action.type) {
    case 'modals/open':
      return openModal(state, action);
    case 'modals/close':
      return closeModal(state, action);
    case 'modals/update-data':
      return updateModalData(state, action);
    default:
      return state;
  }
}
