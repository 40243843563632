import type { Epic } from 'flow-types/Epic';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import getBrowserLanguage from 'common/helpers/getBrowserLanguage';
import { of } from 'rxjs';

const initialStartEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('initial-start'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(() => {
      const browserLocale = getBrowserLanguage('en');

      return of({
        type: `language/${browserLocale}`
      });
    })
  );

export default initialStartEpic;
