const viewDictionary = {
  en: {
    'viewComposer.form.tabTitle': 'Tab Title',
    'viewComposer.header.title': 'View Composer',
    'viewComposer.header.searchView': 'Search View Composer',
    'viewComposer.form.viewType': 'View type',
    'viewComposer.form.viewName': 'Name',
    'viewComposer.form.iconSelector': 'Select an icon',
    'viewComposer.form.target': 'Target',
    'viewComposer.form.entity': 'Entity',
    'viewComposer.form.sourceEntityField': 'Current entity field',
    'viewComposer.form.targetEntityField': 'Target entity field',
    'viewComposer.form.conditions': 'Conditions',
    'viewComposer.form.visibleFields': 'Visible fields',
    'viewComposer.form.field': 'Field',
    'viewComposer.form.linkAction': 'Action on link clicking',
    'viewComposer.form.headerName': 'Header name',
    'viewComposer.form.width': 'Width',
    'viewComposer.form.createEntityFlag': 'Create target entity?',
    'viewComposer.form.deleteButton': 'Delete',
    'viewComposer.form.not': 'Not',
    'viewComposer.form.searchField': 'Search in field',
    'searchView.nothingFound': 'Nothing Found',
    'viewComposer.error.entityUndefined': 'Undefined entiry!'
  },
  ru: {
    'viewComposer.form.tabTitle': 'Название вкладки',
    'viewComposer.header.title': 'Создание блоков',
    'viewComposer.header.searchView': 'Создание блока поиска',
    'viewComposer.form.viewType': 'Тип блока',
    'viewComposer.form.viewName': 'Название',
    'viewComposer.form.iconSelector': 'Выбор иконки',
    'viewComposer.form.target': 'Куда запрос',
    'viewComposer.form.entity': 'Сущность',
    'viewComposer.form.sourceEntityField': 'Из текущего',
    'viewComposer.form.targetEntityField': 'В запрашиваемом проекте',
    'viewComposer.form.conditions': 'При условии',
    'viewComposer.form.visibleFields': 'Отображаемые поля в списке',
    'viewComposer.form.field': 'Поле',
    'viewComposer.form.linkAction': 'Действие над ссылкой',
    'viewComposer.form.headerName': 'Название вверху',
    'viewComposer.form.width': 'Ширина',
    'viewComposer.form.createEntityFlag':
      'Создание сущности в запрашиваемом списке',
    'viewComposer.form.deleteButton': 'Удалить',
    'viewComposer.form.not': 'Не',
    'viewComposer.form.searchField': 'Искать в поле',
    'searchView.nothingFound': 'Ничего не найдено',
    'viewComposer.error.entityUndefined': 'Неопределена сущность!'
  }
};

export default viewDictionary;
