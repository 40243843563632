import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { toast } from 'react-toastify';
import { of } from 'rxjs';
import { camelizeKeys } from 'humps';
import type { Epic } from '../../../flow-types/Epic';
import { companiesPageUsersSelector } from '../../../selectors/companies';
import { userDenormalizer } from '../../../common/transducers/users/denormalizer';
import request from '../../../utils/request';
import { API } from '../../../utils/config';
import responseParser from '../../../common/epicHelpers/responseParser';
import { normalizeUser } from '../../../common/transducers/users/normalizer';

export const saveUserSuccessWatcher = $action =>
  $action.pipe(
    ofType('companies/save-user-success'),
    RxOperators.map(() => ({ type: 'companies/fetch-users' }))
  );

const saveUserEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/save-user'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([, state]) => {
      const {
        form: { data }
      } = companiesPageUsersSelector(state);
      return {
        user: data,
        isUpdate: !!data.id
      };
    }),
    RxOperators.mergeMap(({ user, isUpdate }) => {
      const denormalizedUser = userDenormalizer(user);

      return request({
        url: isUpdate
          ? API.users.detail.replace(':userId', user.id)
          : API.users.list,
        body: denormalizedUser,
        method: isUpdate ? 'PUT' : 'POST'
      }).pipe(
        responseParser,
        RxOperators.map(({ data }) => ({
          type: 'companies/save-user-success',
          user: normalizeUser(data)
        })),
        RxOperators.catchError(({ response, message, status }) => {
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'companies/save-user-fail',
            error: response ? camelizeKeys(response.data) : message
          });
        })
      );
    })
  );

export default saveUserEpic;
