import find from 'lodash/find';
import some from 'lodash/some';
import every from 'lodash/every';
import includes from 'lodash/includes';

const isSelected = (items, targetId, multi = true, partial = false) => {
  if (typeof targetId === 'undefined') {
    return false;
  }

  if (!multi) {
    // eslint-disable-next-line eqeqeq
    return items == targetId;
  }

  let result;

  if (multi && Array.isArray(targetId)) {
    if (partial) {
      result = some(targetId, id => includes(items, id));
    } else {
      result = every(targetId, id => includes(items, id));
    }
  } else {
    result = !!find(
      items,
      id =>
        // eslint-disable-next-line eqeqeq
        id == targetId
    );
  }

  return !!result && result !== -1;
};

export default isSelected;
