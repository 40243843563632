// @flow

import includes from 'lodash/includes';
import map from 'lodash/map';

export function disableAll(collection: Object[]) {
  return map(collection, item => ({
    ...item,
    disable: true
  }));
}

export function disableItemsBy(
  collection: Object[],
  targetProperty: string | ((Object, Array<number | string>) => boolean),
  disabledItemsPropertyValues: Array<number | string>
) {
  return map(collection, item => {
    if (typeof targetProperty === 'function') {
      if (targetProperty(item, disabledItemsPropertyValues)) {
        return {
          ...item,
          disabled: true
        };
      }
    } else if (includes(disabledItemsPropertyValues, item[targetProperty])) {
      return {
        ...item,
        disabled: true
      };
    }

    return item;
  });
}

/**
 * Function goes through given items list and if ID of an item exists in identifiers list,
 * then 'disabled: true' is added
 */
export default function disableItemsById(
  items: { id: number },
  disabledIds: number[]
) {
  return map(items, item => {
    if (includes(disabledIds, item.id)) {
      return {
        ...item,
        disabled: true
      };
    }

    return item;
  });
}
