import type { ProjectReportsState } from 'flow-types/states/ProjectsState/detail';
import type { Action } from '../../flow-types/Action';
import updateFilter from '../../common/helpers/updateFilter';

const initialState: ProjectReportsState = {
  data: {
    check: {
      rows: null,
      columns: []
    },
    duration: {
      rows: null,
      columns: []
    }
  },
  loading: false,
  pagination: {},
  filter: {
    minPoints: 0,
    maxPoints: 100,
    showDepartments: true,
    showManagers: true,
    showGroups: true,
    durationFrom: 0,
    durationTo: 43200 // half a day
  }
};

export default function reports(
  state: ProjectReportsState = initialState,
  action: Action
) {
  switch (action.type) {
    case 'project/reset':
      return initialState;

    case 'project-reports/update-filter':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'project-reports/fetch':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'project-reports/fetch-fail':
      return {
        ...state,
        loading: false
      };

    case 'project-reports/fetch-success':
      return {
        ...state,
        data: {
          duration: action.durationReport,
          check: action.checkReport
        },
        pagination: action.pagination,
        loading: false
      };

    default:
      return state;
  }
}
