// @flow

import * as React from 'react';
import MaskedInput from 'react-input-mask';

import type { NilValue } from 'flow-types/NilValue';
import type { ReactComponent } from 'flow-types/ReactComponent';
import getInputClassName from 'common/components/Input/getInputClassName';
import type { FomanticSize } from 'flow-types/FomanticSize';
import getValueFromDOMEvent from '../../helpers/getValueFromDOMEvent';

export type InputProps = {
  tagName?: ReactComponent,
  className?: null | string,
  focus?: boolean,
  size?: FomanticSize,
  disabled?: boolean,
  error?: boolean,
  fluid?: boolean,
  inverted?: boolean,
  loading?: boolean,
  icon?: NilValue | 'left' | 'right',
  labeled?: NilValue | 'left' | 'right',
  corner?: NilValue | 'left' | 'right',
  action?: NilValue | 'left' | 'right',
  color?: null | string,
  transparent?: boolean,
  value?: string | number,
  type?: string,
  name?: string | null,
  nonUI?: boolean,
  mask?: null | string,
  onChange?: null | Function,
  onlyValue?: boolean,
  wrappedInsideContainer?: boolean,
  // will use name to make an output like
  // { [name]: event | value }
  useNameAsValueKey?: NilValue | boolean,
  raw?: boolean
};

const Input: React.AbstractComponent<InputProps, any> = React.forwardRef(
  (
    {
      raw,
      className,
      nonUI,
      fluid,
      disabled,
      error,
      loading,
      tagName = 'input',
      inverted,
      action,
      labeled,
      icon,
      color,
      corner,
      size,
      transparent,
      mask,
      name,
      onChange,
      onlyValue,
      useNameAsValueKey,
      wrappedInsideContainer,
      type,
      value,
      focus,
      ...props
    }: InputProps,
    ref
  ) => {
    const Component = tagName ?? 'input';

    const handleChange = React.useCallback(
      event => {
        if (!onChange) return;

        if (
          !useNameAsValueKey ||
          (useNameAsValueKey && (!onlyValue || !name))
        ) {
          if (onlyValue) {
            onChange(getValueFromDOMEvent(event));
          } else {
            onChange(event);
          }
          return;
        }

        onChange({
          // $FlowFixMe
          [name]: getValueFromDOMEvent(event)
        });
      },
      [name, onChange, onlyValue, useNameAsValueKey]
    );

    const safeValue = value ?? '';

    const classNames = !wrappedInsideContainer
      ? getInputClassName({
          action,
          className,
          color,
          corner,
          disabled,
          focus,
          error,
          fluid,
          icon,
          inverted,
          labeled,
          loading,
          nonUI,
          size,
          transparent
        })
      : null;

    return type === 'phone' ? (
      <MaskedInput
        {...props}
        onChange={raw ? onChange : handleChange}
        name={name}
        value={safeValue}
        mask={mask}
        type="text"
        ref={ref}
        className={classNames}
        disabled={disabled}
      />
    ) : (
      <Component
        {...props}
        type={type}
        name={name}
        value={safeValue}
        onChange={raw ? onChange : handleChange}
        ref={ref}
        className={classNames}
        disabled={disabled}
      />
    );
  }
);

// $FlowIgnore
Input.defaultProps = {
  className: null,
  type: 'text',
  focus: false,
  action: false,
  corner: false,
  size: null,
  disabled: false,
  error: false,
  fluid: false,
  icon: false,
  inverted: false,
  labeled: false,
  loading: false,
  color: false,
  transparent: false,
  value: null,
  tagName: 'input',
  name: null,
  nonUI: false,
  mask: '+7 (999) 999-99-99',
  onChange: null,
  wrappedInsideContainer: false,
  onlyValue: false,
  useNameAsValueKey: false,
  raw: false
};

type WrapperProps = {
  focus?: boolean,
  loading?: boolean,
  disabled?: NilValue | boolean,
  error?: boolean,
  className?: NilValue | string,
  icon?: NilValue | 'left' | 'right',
  labeled?: NilValue | 'left' | 'right',
  corner?: NilValue | 'left' | 'right',
  action?: NilValue | 'left' | 'right',
  transparent?: boolean,
  color?: NilValue | string,
  inverted?: boolean,
  fluid?: boolean,
  size?: NilValue | 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive',
  tagName?: ReactComponent,
  nonUI?: boolean
};

export const InputWrapper: React.AbstractComponent<
  WrapperProps,
  mixed
> = React.forwardRef(
  (
    {
      inverted,
      error,
      action,
      className,
      loading,
      disabled,
      color,
      corner,
      fluid,
      icon,
      labeled,
      nonUI,
      size,
      focus,
      transparent,
      tagName = 'div',
      ...props
    }: WrapperProps,
    ref
  ) => {
    const Component = tagName ?? 'div';

    const classNames = getInputClassName({
      action,
      className,
      color,
      corner,
      disabled,
      focus,
      error,
      fluid,
      icon,
      inverted,
      labeled,
      loading,
      nonUI,
      size,
      transparent
    });

    return <Component {...props} className={classNames} ref={ref} />;
  }
);

// $FlowFixMe
InputWrapper.defaultProps = {
  className: null,
  focus: false,
  action: false,
  corner: false,
  size: null,
  disabled: false,
  error: false,
  fluid: false,
  icon: false,
  inverted: false,
  labeled: false,
  loading: false,
  color: false,
  transparent: false,
  nonUI: false,
  tagName: 'div'
};

InputWrapper.displayName = 'InputWrapper';

Input.displayName = 'Input';

export default Input;
