import revertDictionary from '../../utils/revertDictionary';

const regionWithUnsavedChanges = {
  'regionWithUnsavedChangesModal.title': {
    en: 'Unsaved changes!',
    ru: 'Не сохранённые изменения!'
  },
  'regionWithUnsavedChangesModal.content': {
    en: 'Current mark has unsaved changes! Please, either save or reset them!',
    ru:
      'Текущая оценка имеет не сохранённые изменения! Сохраните или отмените их!'
  },
  'regionWithUnsavedChangesModal.actions.cancelChanges': {
    en: 'Reset changes',
    ru: 'Отменить изменения'
  }
};

export default revertDictionary(regionWithUnsavedChanges);
