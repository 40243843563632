import revertDictionary from 'utils/revertDictionary';

const recordsDictionary = {
  'player.fillContainer': {
    en: 'fill container',
    ru: 'по ширине'
  },
  'player.scale': {
    en: 'scale',
    ru: 'масштаб'
  },
  'records.recordsTitle': {
    en: 'Tracks',
    ru: 'Список треков'
  },
  'records.records.showTimeInfo': {
    en: 'Show time info',
    ru: 'Показать данные по времени'
  },
  'records.records.search.placeholder': {
    en: 'Search...',
    ru: 'Поиск...'
  },
  'records.records.list.duration': {
    en: 'Duration',
    ru: 'Продолжительность'
  },
  'records.records.list.start': {
    en: 'Start',
    ru: 'Начало'
  },
  'records.records.list.end': {
    en: 'End',
    ru: 'Конец'
  },
  'records.records.notFoundPlaceholder': {
    en: 'No tracks found',
    ru: 'Данных по трекам не обнаружено'
  },
  'records.pageTitle': {
    en: 'Встреча #{responseId}',
    ru: 'Встреча #{responseId}'
  },
  'records.peaks.loading': {
    en: 'Loading peaks...',
    ru: 'Идёт загрузка пиков...'
  },
  'records.regionsList.showAllRegions': {
    en: 'Show all regions of active record',
    ru: 'Показать все регионы активной записи'
  },
  'records.regionsList.filterByActiveRecord': {
    en: 'Filter regions list by active record',
    ru: 'Отфильтровать список по активной записи'
  },
  'records.regionsList.actions.remove': {
    en: 'Are you definitely want to remove region?',
    ru: 'Вы действительно хотите удалить регион?'
  },
  'records.regionsList.editOnlySelectedRegion': {
    en: 'Edit only selected region on player',
    ru: 'На плеере можно редактировать только выбранный регион'
  }
};

export default revertDictionary(recordsDictionary);
