import filter from 'lodash/filter';
import head from 'lodash/head';
import uniq from 'lodash/uniq';
import { LOGIC_OPERAND } from 'common/helpers/logic/constants';
import { isTestsGroupingOperator } from './isTestsGroupingOperator';

export const toBackendFormat = list => {
  const testGroups = filter(list, item => Array.isArray(item)).map(
    toBackendFormat
  );

  const tests = filter(
    list,
    item => !Array.isArray(item) && typeof item !== 'string'
  );

  const operator =
    head(uniq(filter(list, isTestsGroupingOperator))) || LOGIC_OPERAND.AND;

  return {
    ...(testGroups.length > 0 && {
      testGroups
    }),
    ...(tests.length > 0 && {
      tests
    }),
    operator
  };
};
