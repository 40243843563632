// @flow

import * as React from 'react';
import type { Node, AbstractComponent } from 'react';
import entries from 'lodash/entries';

import FormComponent from '../components/Form';
import FieldComponent from '../components/Form/Field';
import getValidationState from '../helpers/getFieldValidationState';

import type { FormProps } from '../components/Form';
import type { Props as FieldProps } from '../components/Form/Field';

type Props = FormProps & { validation: Object };

// Contains validation state of a form
const FormValidationContext = React.createContext({});

// returns form validation
export function useFormValidation(): Object {
  return React.useContext(FormValidationContext);
}

type UseFieldValidationParams = {
  // Strictly check for errors for a given fieldName
  // If true, then only array will be considered as error state
  // for a given fieldName
  // If false, it will consider a value that is stored, according to a given fieldName,
  // inside form validation object to be an error info
  strict: boolean
};

const initialUseFieldValidationParams: UseFieldValidationParams = {
  strict: false
};

// returns field validation
export function useFieldValidation(
  fieldName?: string,
  { strict }: UseFieldValidationParams = initialUseFieldValidationParams
): { state: null, error: null } | string {
  const formValidation = useFormValidation();

  return React.useMemo(() => {
    if (fieldName) {
      const state = getValidationState(formValidation, fieldName, true);

      if (!strict || !!Array.isArray(state.error)) {
        return state;
      }
    }

    return { state: null, error: null };
  }, [fieldName, formValidation, strict]);
}

export function useField(fieldName: string): { validation: Object } {
  const formValidation = useFormValidation();

  const validation = React.useMemo(() => {
    if (!formValidation) return null;

    return formValidation[fieldName];
  }, [fieldName, formValidation]);

  return {
    validation
  };
}

export function Field(
  props: FieldProps & {
    name?: string
  }
): Node {
  const { name, children, ...rest } = props;

  const fieldValidationState: Object = useFieldValidation(name);

  return (
    <FieldComponent validationState={fieldValidationState.state} {...rest}>
      {children}
    </FieldComponent>
  );
}

const getErrorState = validation =>
  !!validation && entries(validation).length > 0;

const Form: AbstractComponent<Props, any> = React.forwardRef(
  ({ validation, ...props }: Props, ref) => (
    <FormValidationContext.Provider value={validation}>
      <FormComponent {...props} ref={ref} error={getErrorState(validation)} />
    </FormValidationContext.Provider>
  )
);

export function FormValidationProvider({ validation, children }: Props): Node {
  return (
    <FormValidationContext.Provider value={validation}>
      {children}
    </FormValidationContext.Provider>
  );
}

export default Form;
