// @flow
/* eslint-disable react/no-array-index-key */

import * as React from 'react';
import cx from 'classnames';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import filterList from 'lodash/filter';
import sizeOf from 'lodash/size';
import map from 'lodash/map';
import useEventListener from '@use-it/event-listener';
import { useIntl } from 'react-intl';
import type { NilValue } from 'flow-types/NilValue';
import useFuse from 'common/hooks/useFuse';
import type { FetcherFn } from 'common/components/Dropdown/flow';
import Icon from '../Icon';
import Text from '../Text';
import Menu from '../Menu';
import ListItem from '../ListItem';
import Label from '../Label';
import { OptionGroupLabel } from './styled';
import Container from './Container';
import Search from './Search';
import isSelected from './utils/isOptionSelected';
import getOptionPropByKey from './utils/getOptionPropByKey';
import valueDenormalizer from './utils/valueDenormalizer';
import valueNormalizer from './utils/valueNormalizer';
import optionsNormalizer from './utils/optionsNormalizer';

export type RenderItemFn = (option: Object, asLabel?: boolean) => any;

type Props = {
  name?: string,
  placeholder: string,
  // should return valid style object
  getMenuListItemStyle?: null | ((item: Object) => Object),
  renderItem?: NilValue | RenderItemFn,
  multiple?: boolean,
  searchable?: boolean,
  clearable?: boolean,
  options?: Array<Object>,
  labelKey?: string,
  valueKey?: string,
  onChange?: Function,
  onSearch?: Function,
  onScroll?: Function,
  onlyValue?: boolean,
  value?: number | string | Object,
  fluid?: boolean,
  onFocus?: Function,
  onBlur?: Function,
  loading?: boolean,
  disabled?: boolean,
  error?: boolean,
  size?: null | string,
  emptyOptionsFallback?: ?Array<Object>,
  onCreateOption?: null | ((label: string) => Promise<void>),
  groups?: boolean,
  localSearching?: boolean,
  resetValue?: null | number | string | Array<Object> | Object,
  resetSearchString?: boolean,
  keepSelected?: boolean
};

/**
 * TODO: remake on react-select basis
 */
const Dropdown: React.AbstractComponent<
  Props,
  ?HTMLDivElement
> = React.forwardRef(
  (
    {
      name,
      placeholder,
      renderItem,
      multiple,
      searchable,
      clearable,
      options,
      labelKey,
      valueKey,
      onChange,
      onSearch,
      onlyValue,
      value,
      fluid,
      onFocus,
      onBlur,
      loading,
      disabled,
      emptyOptionsFallback,
      localSearching,
      groups,
      size,
      resetValue,
      resetSearchString,
      error,
      keepSelected,
      onScroll,
      onCreateOption,
      getMenuListItemStyle,
      ...props
    }: Props,
    ref
  ): React.Node => {
    const intl = useIntl();

    // const searchEngineRef = useRef(null);

    const searchRef: any = React.useRef(null);

    const [localValue, setLocalValue] = React.useState(null);

    const normalizedOptions = React.useMemo(() => {
      let list = [];

      if (
        (!Array.isArray(options) || options.length === 0) &&
        emptyOptionsFallback
      ) {
        list = optionsNormalizer(emptyOptionsFallback, valueKey);
      } else {
        list = optionsNormalizer(options, valueKey);
      }

      list = [...list];

      if (list.length === 0) {
        list = [
          {
            // $FlowFixMe
            [valueKey]: '-1',
            // $FlowFixMe
            [labelKey]: intl.formatMessage({ id: 'common.labels.no_items' }),
            disabled: true,
            placeholder: true
          }
        ];
      }

      return list;
    }, [emptyOptionsFallback, intl, labelKey, options, valueKey]);

    React.useEffect(() => {
      if (!isEqual(value, localValue)) {
        setLocalValue(
          valueNormalizer({
            value,
            onlyValue: true,
            multiple,
            valueKey
          })
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, multiple, valueKey]);

    const plainNormalizedOptionsList = React.useMemo(
      () =>
        reduce(
          normalizedOptions,
          (_options, option, index) => {
            if (!option.group) {
              return [..._options, option];
            }

            if (Array.isArray(option.options)) {
              return [
                ..._options,
                ...map(option.options, opt => ({
                  ...opt,
                  // TODO: maybe groupIndex does not have high accuracy
                  //  and could result in some sort of bugs\
                  groupIndex: index
                }))
              ];
            }

            return _options;
          },
          []
        ),
      [normalizedOptions]
    );

    const handleChange = React.useCallback(
      nextLocalValue => {
        if (!onChange) {
          setLocalValue(
            valueNormalizer({
              value: nextLocalValue,
              valueKey,
              multiple,
              onlyValue: true
            })
          );
          return;
        }

        const valueNormalized = valueDenormalizer(
          nextLocalValue,
          plainNormalizedOptionsList,
          onlyValue,
          valueKey,
          labelKey,
          multiple
        );

        onChange(valueNormalized);
      },
      [
        labelKey,
        multiple,
        onChange,
        onlyValue,
        plainNormalizedOptionsList,
        valueKey
      ]
    );

    const searchEngineOptions = React.useMemo(
      () => ({
        shouldSort: true,
        minMatchCharLength: 2,
        keys: [valueKey, labelKey]
      }),
      [labelKey, valueKey]
    );

    const [search, setSearch] = React.useState('');

    const [visible, setVisibility] = React.useState(false);

    const handleSearch = React.useCallback(
      searchStr => {
        setSearch(searchStr);
        if (onSearch) {
          onSearch(searchStr);
        }
      },
      [onSearch]
    );

    const closeMenu = React.useCallback(() => {
      setVisibility(false);
      setSearch('');
      if (onBlur) {
        onBlur();
      }
    }, [onBlur]);

    const openMenu = React.useCallback(() => {
      setVisibility(true);
      if (onFocus) {
        onFocus();
      }
      if (searchable && searchRef.current) {
        searchRef.current.focus();
      }
    }, [onFocus, searchable]);

    const containerClickHandler = React.useCallback(
      event => {
        const { target } = event;

        if (target.classList.contains('remove')) {
          event.preventDefault();
          closeMenu();
          return;
        }

        // if user has clicked on either a clear value icon-button or remove option from selector icon-button,
        // then clear value, but prevent default and prevent further execution
        if (target.classList.contains('close')) {
          event.preventDefault();
          return;
        }

        if (visible) {
          // if searchable and multiple,
          // then we close container only if icon or item is clicked
          if (searchable) {
            if (
              target.tagName === 'I' ||
              (!multiple && target.getAttribute('data-type') === 'option')
            ) {
              closeMenu();
            } else if (
              searchRef.current &&
              document.activeElement !== searchRef.current
            ) {
              searchRef.current.focus();
            }
            return;
          }
          closeMenu();
        } else {
          openMenu();
        }
      },
      [closeMenu, multiple, openMenu, searchable, visible]
    );

    const searcher = useFuse(plainNormalizedOptionsList, searchEngineOptions);

    const normalizedValue = React.useMemo<Object | Array<Object> | null>(() => {
      if (!localValue) {
        return null;
      }

      return localValue;
    }, [localValue]);

    // it always returns an array, thus it is easily to check,
    // whether element is selected or not just using selectedValues.includes()
    const hasValue = multiple
      ? Array.isArray(normalizedValue) && normalizedValue.length > 0
      : !!normalizedValue;

    // const hasValue = React.useMemo(() => {
    //   if (multiple) {
    //     return normalizedValue && sizeOf(normalizedValue) > 0;
    //   }
    //
    //   return !!normalizedValue;
    // }, [multiple, normalizedValue]);

    const selectedValues = React.useMemo(() => {
      if (!hasValue) return [];

      if (multiple) {
        return map(normalizedValue, val => `${val}`);
      }

      // $FlowIgnore
      return [`${normalizedValue}`];
    }, [hasValue, multiple, normalizedValue]);

    const handleSelection = React.useCallback(
      (option, isOptionSelected) => {
        // if multiple, then just toggle element
        if (multiple) {
          let returnedValue;
          if (isOptionSelected) {
            returnedValue = filterList(normalizedValue, val => {
              // here we cast values to the same 'string' type

              if (typeof val === 'object') {
                // $FlowFixMe
                return `${val[valueKey]}` !== `${option[valueKey]}`;
              }

              // $FlowFixMe
              return `${val}` !== `${option[valueKey]}`;
            });
          } else {
            returnedValue = normalizedValue
              ? [...normalizedValue, option]
              : [option];
          }

          handleChange(returnedValue);

          return;
        }

        if (!option) return;

        handleChange(option);
      },
      [handleChange, multiple, normalizedValue, valueKey]
    );

    const handleItemClick = React.useCallback(
      (event, option) => {
        // TODO: try to filter such clicks in root click handler
        // with these, menu will not be closed
        // after we select something in multiple dropdown
        // if (multiple) {
        //   event.stopPropagation();
        //   event.preventDefault();
        // }

        handleSelection(option, isSelected(selectedValues, option, valueKey));
      },
      [handleSelection, selectedValues, valueKey]
    );

    const reset = React.useCallback(() => {
      if (resetValue === null && onChange) {
        onChange(resetValue);
      } else {
        handleChange(resetValue);
      }
    }, [handleChange, onChange, resetValue]);

    // TODO: add check for arrow down and arrow up for both searchable and non-searchable
    const keyDownHandler = React.useCallback(
      event => {
        if (
          multiple &&
          (event.key === 'Backspace' ||
            event.code === 'Backspace' ||
            event.keyCode === 8)
        ) {
          // don't check for selectionDirection, because it can be not updated after selected text is being removed
          if (
            event.target &&
            event.target.selectionStart === 0 &&
            event.target.selectionEnd === 0
          ) {
            // remove last selected
            const nextValue = normalizedValue ? [...normalizedValue] : [];
            nextValue.pop();
            handleChange(nextValue);
          }
        }
      },
      [handleChange, multiple, normalizedValue]
    );

    const searchedNormalizedOptions = React.useMemo(() => {
      if (!searchable || !localSearching || search.length === 0 || !searcher) {
        return normalizedOptions;
      }

      const searchResult = searcher.search(search);

      if (!groups) {
        return map(searchResult, result => result.item);
      }

      return map(normalizedOptions, (group, groupIndex) => {
        const items = filterList(
          searchResult,
          result => result.item.groupIndex === groupIndex
        );

        if (sizeOf(items) === 0) return null;

        return {
          ...group,
          options: map(items, item => item.item)
        };
      }).filter(Boolean);
    }, [
      groups,
      localSearching,
      normalizedOptions,
      search,
      searchable,
      searcher
    ]);

    // reset search if value has changed, and it is not multiple
    React.useEffect(() => {
      if (selectedValues && !multiple && resetSearchString) {
        handleSearch('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValues]);

    useEventListener('keydown', keyDownHandler, searchRef.current);

    const containerClasses = React.useMemo(
      () =>
        cx(size, {
          active: visible,
          visible,
          multiple,
          search: searchable,
          error,
          fluid,
          disabled,
          loading,
          clearable
        }),
      [
        clearable,
        disabled,
        error,
        fluid,
        loading,
        multiple,
        searchable,
        size,
        visible
      ]
    );

    return (
      <Container
        {...props}
        onClickOutside={closeMenu}
        className={containerClasses}
        onClick={containerClickHandler}
        ref={ref}
      >
        <input type="hidden" name={name} value={localValue || ''} />
        <Icon icon="dropdown" />
        {clearable && !disabled && selectedValues.length > 0 && (
          <Icon onClick={reset} icon="remove" />
        )}
        {multiple && (
          <>
            {selectedValues
              .map(selectedValue => {
                const optionForValue = plainNormalizedOptionsList.find(
                  opt => opt[valueKey] === selectedValue
                );

                if (!optionForValue) {
                  return {
                    // $FlowFixMe
                    [valueKey]: selectedValue,
                    // $FlowFixMe
                    [labelKey]: selectedValue
                  };
                }

                return optionForValue;
              })
              .map(option => {
                // $FlowFixMe
                const optionValue = option[valueKey];
                // $FlowFixMe
                const optionLabel = option[labelKey];

                const content =
                  typeof renderItem === 'function'
                    ? renderItem(option, true)
                    : optionLabel;

                const key =
                  optionValue === null ? 'label-none' : `label-${optionValue}`;

                return (
                  <Label
                    key={key}
                    as="a"
                    inlined
                    data-value={optionValue}
                    data-label={optionLabel}
                    data-selected="true"
                    className="transition visible"
                    href="#"
                  >
                    {content}
                    {!disabled && (
                      <Icon
                        icon="close"
                        data-value={optionValue}
                        data-label={optionLabel}
                        data-selected="true"
                        onClick={e => handleItemClick(e, option)}
                      />
                    )}
                  </Label>
                );
              })}
          </>
        )}
        {searchable && !disabled && (
          <Search
            ref={searchRef}
            onChange={handleSearch}
            autoGrow={!!multiple}
            value={search}
            opened={visible}
          />
        )}
        {!hasValue && (
          <Text
            as="div"
            className={search.length > 0 ? 'default filtered' : 'default'}
            nonUI
          >
            {placeholder}
          </Text>
        )}
        {!multiple && normalizedValue && (
          <Text
            as="div"
            className={search.length > 0 ? 'filtered' : null}
            nonUI
          >
            {renderItem &&
              renderItem(
                selectedValues.reduce((item, currentValue) => {
                  if (item) return item;

                  const optionForValue = plainNormalizedOptionsList.find(
                    opt => opt[valueKey] === currentValue
                  );

                  if (!optionForValue) {
                    return {
                      // $FlowFixMe
                      [valueKey]: currentValue,
                      // $FlowFixMe
                      [labelKey]: ''
                    };
                  }

                  return optionForValue;
                }, null),
                true
              )}
            {!renderItem &&
              getOptionPropByKey(
                plainNormalizedOptionsList.find(option => {
                  if (typeof normalizedValue !== 'object') {
                    return includes(selectedValues, option[valueKey]);
                  }

                  return includes(selectedValues, option[valueKey]);
                }),
                labelKey
              )}
          </Text>
        )}
        {visible && (
          <Menu
            nonUI
            onScroll={onScroll}
            className={cx('transition', { visible, hidden: !visible })}
          >
            {searchedNormalizedOptions
              .reduce((_options, option) => {
                if (!option.group) return [..._options, option];

                if (Array.isArray(option.options)) {
                  return [
                    ..._options,
                    { group: true, title: option.title },
                    ...option.options
                  ];
                }

                return _options;
              }, [])
              .map((option, index) => {
                if (option.group) {
                  return (
                    <OptionGroupLabel key={`group-label-${index}`}>
                      {option.title}
                    </OptionGroupLabel>
                  );
                }

                const selected = isSelected(selectedValues, option, valueKey);

                if (selected && !keepSelected) {
                  return null;
                }

                // $FlowFixMe
                const optionValue = option[valueKey];

                // $FlowFixMe
                const optionLabel = option[labelKey];

                const content =
                  typeof renderItem === 'function'
                    ? renderItem(option, false)
                    : optionLabel;

                return (
                  <ListItem
                    className={cx({
                      active: selected,
                      selected,
                      disabled: option.disabled
                    })}
                    style={
                      getMenuListItemStyle ? getMenuListItemStyle(option) : null
                    }
                    disabled={option.disabled}
                    key={optionValue}
                    data-value={optionValue}
                    data-label={optionLabel}
                    data-selected={selected ? 'true' : 'false'}
                    data-type="option"
                    onClick={
                      !disabled && !option.disabled
                        ? e => handleItemClick(e, option)
                        : null
                    }
                  >
                    {content}
                  </ListItem>
                );
              })}
          </Menu>
        )}
      </Container>
    );
  }
);

// $FlowIgnore
Dropdown.defaultProps = {
  getMenuListItemStyle: null,
  onCreateOption: null,
  keepSelected: false,
  value: null,
  error: false,
  // $FlowFixMe
  options: [],
  valueKey: 'value',
  labelKey: 'label',
  localSearching: true,
  searchable: true,
  clearable: true,
  disabled: false,
  size: null,
  emptyOptionsFallback: null,
  loading: false,
  fluid: false,
  groups: false,
  multiple: false,
  onBlur: null,
  onChange: null,
  name: '',
  placeholder: '',
  onlyValue: false,
  onFocus: null,
  resetValue: null,
  onSearch: null,
  onScroll: null,
  renderItem: null,
  resetSearchString: true
};

function asyncDropdownReducer(state, action) {
  // useReducer's reducer should not have default case
  // eslint-disable-next-line
  switch (action.type) {
    case 'fetch':
      return {
        ...state,
        loading: true
      };
    case 'fetch-success':
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case 'fetch-fail':
      return {
        ...state,
        loading: false
      };
    default:
      throw new Error('it should not happen');
  }
}

const asyncDropdownInitialState = {
  loading: false,
  data: []
};

export type AsyncProps = {
  ...Props,
  fetchOnFocus?: boolean,
  fetchOnMount?: boolean,
  fetchOnSearch?: boolean,
  fetchOnFilterUpdate?: boolean,
  filter?: Object,
  fetcher: FetcherFn,
  dataExtractor: Function,
  dataFormatter: Function
};

export const AsyncDropdown = ({
  fetcher,
  fetchOnFocus,
  fetchOnMount,
  fetchOnSearch,
  fetchOnFilterUpdate,
  filter,
  dataExtractor,
  dataFormatter,
  ...props
}: AsyncProps): React.Node => {
  const searchCache = React.useRef(null);

  const latestFilter = React.useRef(filter);
  // const [filterCache, setFilterCache] = useCache(filter);

  const [asyncState, dispatch] = React.useReducer(
    asyncDropdownReducer,
    asyncDropdownInitialState
  );

  const fetcherCaller = React.useCallback(async () => {
    if (!fetcher) return;

    dispatch({ type: 'fetch' });

    let response = await fetcher(filter, searchCache.current);

    response = dataFormatter(dataExtractor(response));

    dispatch({ type: 'fetch-success', data: response });
  }, [dataExtractor, dataFormatter, fetcher, filter]);

  const handleSearch = React.useCallback(
    value => {
      searchCache.current = value;
      if (fetchOnSearch) {
        fetcherCaller();
      }
    },
    [fetchOnSearch, fetcherCaller]
  );

  React.useEffect(() => {
    if (fetchOnMount) {
      fetcherCaller();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!isEqual(filter, latestFilter.current)) {
      latestFilter.current = filter;
      if (fetchOnFilterUpdate) {
        fetcherCaller();
      }
    }
    // eslint-disable-next-line
  }, [filter]);

  const handleFocus = React.useCallback(() => {
    if (fetchOnFocus) {
      fetcherCaller();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown
      {...props}
      options={asyncState.data}
      loading={asyncState.loading}
      onSearch={handleSearch}
      onFocus={handleFocus}
    />
  );
};

AsyncDropdown.defaultProps = {
  ...Dropdown.defaultProps,
  dataExtractor: options => options,
  dataFormatter: options => options,
  fetchOnMount: false,
  fetchOnFilterUpdate: false,
  fetchOnFocus: false,
  fetchOnSearch: false,
  filter: null
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
