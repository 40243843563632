import fp from 'lodash/fp';
import times from 'lodash/times';
import { decamelizeKeys } from 'humps';

import { toBackendFormat } from 'common/transducers/logic/groupTestsIntoTestGroup';
import { denormalizeLogicTestGroups } from 'common/transducers/projects/logicDenormalizer';
import { POST_POLLING_DELAY_TYPES } from 'common/helpers/project/constants';

import type { IPollingNotification } from 'flow-types/entities/PollingNotification';

// processes postPollingDelayValue field
export const processPostPollingDelayField = (
  pollingNotification: IPollingNotification
): IPollingNotification => {
  const EXPECTED_LENGTH = 3;

  if (+pollingNotification.delayType !== POST_POLLING_DELAY_TYPES.TIMER) {
    return pollingNotification;
  }

  const next = { ...pollingNotification };

  if (typeof next.delayValue === 'string') {
    const splittedValue = next.delayValue.split(':');

    // calculate shortage from splitted value
    const valueShortage = EXPECTED_LENGTH - splittedValue.length;

    if (valueShortage > 0) {
      // generate required amount of zeroes
      const generatedShortage = times(valueShortage, () => '00').join(':');

      // add them to a given value
      next.delayValue = `${next.delayValue}:${generatedShortage}`;
    }
  }

  return next;
};

function accessSettingsProcessor(key) {
  return fp.compose(
    fp.map(item => +item[key]),
    fp.filter(access => access && access.access === 'visible')
  );
}

const processPollingNotificationAccessSettings = (
  pollingNotification: IPollingNotification
) => {
  const next = { ...pollingNotification };

  next.questionIds = accessSettingsProcessor('questionId')(
    next.questionsAccess
  );

  next.questionGroupIds = accessSettingsProcessor('questionGroupId')(
    next.questionGroupsAccess
  );

  delete next.questionsAccess;

  delete next.questionGroupsAccess;

  return next;
};

const processPollingNotificationTestGroups = (
  pollingNotification: IPollingNotification
): IPollingNotification => {
  const next = { ...pollingNotification };

  const rootTestGroup = toBackendFormat(next.tests);

  next.testGroups = denormalizeLogicTestGroups([rootTestGroup]);

  delete next.tests;

  return next;
};

export const denormalizePollingNotification = fp.compose(
  processPostPollingDelayField,
  processPollingNotificationAccessSettings,
  processPollingNotificationTestGroups
);

export const decamelizeAndDenormalizePollingNotification = fp.compose(
  decamelizeKeys,
  denormalizePollingNotification
);
