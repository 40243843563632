// @flow
import isNil from 'lodash/isNil';

import replace from 'lodash/replace';
import isNaN from 'lodash/isNaN';
import map from 'lodash/map';

export const safeParseInt = (value: any, defaultValue?: number = 0): number => {
  if (typeof value === 'number') return value;

  if (isNaN(value)) return defaultValue;

  return value;
};

/**
 * Checks whether slider view type
 * can be applied to numeric block
 */
export const supportsSliderViewType = (question: {
  minValue?: any,
  maxValue?: any
}): boolean => !isNil(question.minValue) && !isNil(question.maxValue);

/**
 * Parses value that comes from number input.
 * Used in single number block case.
 */
export const parseValue = (
  value: number[] | number | string,
  multi?: boolean = false,
  defaultValue?: string | null = null
): number | number[] | string | null => {
  if (Array.isArray(value)) {
    if (multi) {
      return map(value, v => parseValue(v));
    }

    const val = value?.[0];

    return parseValue(val);
  }

  if (multi) {
    return map([value, value], v => parseValue(v));
  }

  if (typeof value === 'undefined') return defaultValue;

  if (typeof value === 'string') {
    let parsedVal = replace(value, /[a-zA-Z]+|[а-яА-я]+/g, '');

    if (parsedVal.length === 2) {
      if (parsedVal.split('').every(c => c === '0')) {
        return '0';
      }
    }

    if (parsedVal.length > 1) {
      parsedVal = replace(parsedVal, /^0+(?![.,])/g, '');
    }

    return safeParseInt(parsedVal, defaultValue);
  }

  return value;
};

/**
 * Checks that values suits multi numeric block expectations about value.
 */
export const multiValueGuard = (
  value: number[] | number,
  min: number,
  max: number
): number[] => {
  if (!value) {
    return [min, max];
  }

  if (!Array.isArray(value)) {
    return [
      ((safeParseInt(value, min): any): number),
      ((safeParseInt(value, max): any): number)
    ];
  }

  return value;
};
