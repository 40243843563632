import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import defaults from 'lodash/defaults';
import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import type { IQuestion, IQuestionId } from 'flow-types/entities/Question';
import type { NilValue } from 'flow-types/NilValue';
import takeSecondArg from 'common/helpers/takeSecondArg';
import { questionsListSelector } from '../../../selectors/projects';

type UseQuestionsFnArgs = {|
  flatten?: boolean,
  keyedBy?: string | null,
  // Is a set of questions ids that is allowed to return,
  // to return all questions, just do not pass value into this field
  allowed?: IQuestionId[],
  // Return values only if enabled is true,
  // by default is 'true'
  enabled?: boolean,
  refactorFn?:
    | NilValue
    | ((items: IQuestion[] | { [questionId: string]: IQuestion }) => mixed[])
|};

const selectorFactory = () =>
  createSelector(
    questionsListSelector,
    takeSecondArg,
    (questions, options: UseQuestionsFnArgs) => {
      if (!options) {
        return questions;
      }

      const { flatten, keyedBy, allowed, enabled, refactorFn } = options;

      if (!enabled) {
        if (keyedBy) {
          return {};
        }
        return [];
      }

      let result = [...questions];

      if (flatten) {
        result = flatQuestionsSubQuestions(result);
      }

      if (keyedBy) {
        result = keyBy(result, keyedBy);
      }

      if (allowed) {
        result = filter(result, (question: IQuestion) =>
          includes(allowed, question.id)
        );
      }

      return !refactorFn ? result : refactorFn(result);
    }
  );

const initialOptions: UseQuestionsFnArgs = {
  flatten: false,
  keyedBy: null,
  enabled: true,
  allowed: null,
  refactorFn: null
};

export function useProjectQuestions(
  params: UseQuestionsFnArgs = initialOptions
) {
  const options = useMemo(() => defaults(params, initialOptions), [params]);

  const selector = useMemo(selectorFactory, []);

  return useSelector(state => selector(state, options));
}
