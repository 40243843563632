// @flow

import filter from 'lodash/filter';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import omit from 'lodash/omit';

import updateFilter from 'common/helpers/updateFilter';
import { responseQuestionsAttachmentsMixin } from 'common/transducers/response/responsesNormalizer';

import type { IView } from 'flow-types/entities/View';
import type { IUpload } from 'flow-types/entities/Upload';
import type { IComment } from 'flow-types/entities/Comment';
import type { IResponse } from 'flow-types/entities/Response';
import type { ProjectResponsesState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectResponsesAction } from 'flow-types/actions/projects/detail/responses';
import type { ResetProject } from 'flow-types/actions/projects/detail/project/ResetProject';
import type { UpdateResponseAnswerSuccess } from 'flow-types/actions/projects/detail/responses/UpdateResponseAnswer';
import type { AddResponseUpload } from 'flow-types/actions/projects/detail/responses/AddResponseUpload';
import type { RemoveResponseUpload } from 'flow-types/actions/projects/detail/responses/RemoveResponseUpload';
import type { ReplaceExpert } from 'flow-types/actions/projects/detail/responses/ReplaceExpert';

const initialState: ProjectResponsesState = {
  data: null,
  loading: false,
  error: null,
  filter: { ps: 10, pn: 0, sort: '-id' },
  pagination: {
    activePage: 0,
    totalElements: 0,
    totalPages: 1
  }
};

const handleResponseAnswerUpdateSuccess = (
  state: ProjectResponsesState,
  {
    answer,
    comments,
    questionId,
    responseId,
    question,
    views
  }: UpdateResponseAnswerSuccess
): ProjectResponsesState => {
  const { data } = state;

  const nextQuestion = {
    ...question,
    comments,
    views,
    answer
  };

  const plainQuestion = omit(nextQuestion, ['comments', 'answer', 'views']);

  const nextData = map(data, (response: IResponse) => {
    if (response.id !== responseId) return response;

    const {
      answers: oldAnswers = [],
      questions: oldQuestions = [],
      comments: oldComments = [],
      views: oldViews = [],
      ...rest
    } = response;

    // answer may not exist until the moment of update
    const questionAnswerIndex = findIndex(oldAnswers, { questionId });

    let nextAnswers = Array.isArray(oldAnswers) ? [...oldAnswers] : [];

    if (questionAnswerIndex === -1) {
      nextAnswers = [...nextAnswers, { ...answer, question: plainQuestion }];
    } else {
      nextAnswers[questionAnswerIndex] = {
        ...nextAnswers[questionAnswerIndex],
        ...answer
      };
    }

    // remove all previous question comments
    let nextComments = filter(
      oldComments,
      (cmt: IComment) => cmt.questionId !== questionId
    );

    // add next question comments
    nextComments = [
      ...nextComments,
      ...comments.map(cmt => ({ ...cmt, question: plainQuestion }))
    ];

    // remove all previous question views
    let nextViews = filter(
      oldViews,
      (view: IView) => view.questionId !== questionId
    );

    // add next question views
    nextViews = [...nextViews, ...views];

    let nextQuestions = [...oldQuestions];

    const changedQuestionIndex = findIndex(oldQuestions, { id: questionId });

    if (changedQuestionIndex === -1) {
      nextQuestions = [...nextQuestions, nextQuestion];
    } else {
      nextQuestions[changedQuestionIndex] = nextQuestion;
    }

    return {
      ...rest,
      answers: nextAnswers,
      comments: nextComments,
      views: nextViews,
      questions: nextQuestions
    };
  });

  return {
    ...state,
    data: nextData
  };
};

const addResponseUpload = (state, action: AddResponseUpload) => ({
  ...state,
  data: map(state.data, (res: IResponse) => {
    if (action.responseId !== res.id) return res;

    const { uploads, attachments, questions } = res;

    let nextUploads = [];

    if (Array.isArray(uploads)) {
      nextUploads = [...uploads];
    }

    nextUploads = [...nextUploads, action.upload];

    let nextAttachments = [];

    if (Array.isArray(attachments)) {
      nextAttachments = [...attachments];
    }

    if (action.upload.isImage) {
      nextAttachments = [...nextAttachments, action.upload];
    }

    const nextQuestions = responseQuestionsAttachmentsMixin(
      questions,
      nextUploads
    );

    return {
      ...res,
      uploads: nextUploads,
      attachments: nextAttachments,
      questions: nextQuestions
    };
  })
});

const removeResponseUpload = (state, action: RemoveResponseUpload) => ({
  ...state,
  data: map(state.data, (res: IResponse) => {
    if (action.responseId !== res.id) return res;

    const { uploads, attachments, questions } = res;

    const nextUploads = filter(
      uploads,
      (upload: IUpload) => upload.id !== action.uploadId
    );

    const nextAttachments = filter(
      attachments,
      (attachment: IUpload) => attachment.id !== action.uploadId
    );

    const nextQuestions = responseQuestionsAttachmentsMixin(
      questions,
      nextUploads
    );

    return {
      ...res,
      uploads: nextUploads,
      attachments: nextAttachments,
      questions: nextQuestions
    };
  })
});

const replaceExpert = (
  state: ProjectResponsesState,
  { expert = null, responseId }: ReplaceExpert
) => {
  const { data, ...rest } = state;

  return {
    ...rest,
    data: map(data, response => {
      if (response.id !== responseId) return response;

      return {
        ...response,
        expertId: expert ? expert.id : null,
        expert
      };
    })
  };
};

export default function responsesReducer(
  state: ProjectResponsesState = initialState,
  action: ProjectResponsesAction | ResetProject
): ProjectResponsesState {
  switch (action.type) {
    case 'project/reset':
      return initialState;

    case 'project-responses/replace-expert':
      return replaceExpert(state, action);

    case 'project/update-response-answer-success':
      return handleResponseAnswerUpdateSuccess(state, action);

    // TODO: remove candidate
    // case 'project-responses/update':
    //   return {
    //     ...state,
    //     data: map(state.data, r => ({
    //       ...r,
    //       ...(r.id === action.responseId && action.dataUpdate)
    //     }))
    //   };

    case 'project-responses/select':
      return {
        ...state,

        data: map(state.data, r => ({
          ...r,
          active: r.id === action.responseId
        }))
      };

    case 'project-responses/update-filter':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'project-responses/reset-filter':
      return {
        ...state,
        filter: initialState.filter
      };

    case 'project-responses/fetch':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };
    case 'project-responses/fetch-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'project-responses/fetch-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        loading: false,
        error: null
      };

    case 'project/add-response-upload':
      return addResponseUpload(state, action);

    case 'project/remove-response-upload':
      return removeResponseUpload(state, action);

    default:
      return state;
  }
}
