import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import IntlMessageFormat from 'intl-messageformat';
import { EMPTY } from 'rxjs';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import request from '../../utils/request';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import { regionDenormalizer } from '../../common/transducers/regions/denormalizer';
import { regionNormalizer } from '../../common/transducers/regions/normalizer';
import type { Epic } from '../../flow-types/Epic';
import isNewRegion from '../../pages/Project/helpers/isNewRegion';
import flatErrors from '../../common/transducers/errors/flatErrors';
import type { SaveRegion } from '../../flow-types/actions/records/SaveRecordRegion';
import { recordsRegionsStateSelector } from '../../selectors/records';
import validateRate from '../../common/validators/validateRate';
import normalizeValidation from '../../common/helpers/normalizeValidation';
import { languageStateSelector, modalsStateSelector } from '../../selectors';
import regionFormDictionary from '../../intl/forms/regionFormDictionary';
import { MODAL_ID } from '../../pages/Project/components/Modals/RegionHasUnsavedChangesModal';

const saveRegionEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('records-regions/save'),
    RxOperators.withLatestFrom($state),
    RxOperators.mergeMap(([action, state]) => {
      const {
        recordId,
        regionId,
        responseId,
        nextRegionId
      }: SaveRegion = action;

      const { data: regions } = recordsRegionsStateSelector(state);

      const language = languageStateSelector(state);

      const modalsState = modalsStateSelector(state);

      let modalState = { visible: false };

      if (modalsState[MODAL_ID]) {
        modalState = modalsState[MODAL_ID];
      }

      const region = find(regions, { id: regionId });

      if (!region) return EMPTY;

      try {
        validateRate(region, true);
      } catch (validationResult) {
        const normalizedValidationForRate = normalizeValidation(
          validationResult
        );

        const flattedValidation = flatErrors(normalizedValidationForRate);

        flattedValidation.forEach(error => {
          const localizedError = new IntlMessageFormat(
            regionFormDictionary[language][error],
            language
          ).format();

          toast.error(localizedError, {
            position: 'bottom-center',
            autoClose: 2500
          });
        });

        return [
          {
            type: 'records-regions/save-fail',
            error: normalizedValidationForRate,
            regionId
          },
          // TODO: hide it only if current region is in fact active selected
          modalState.visible
            ? { type: 'modals/close', modalId: MODAL_ID }
            : null
        ].filter(Boolean);
      }

      const formattedRegion = regionDenormalizer({
        ...region,
        responseId,
        recordId: recordId || region.recordId
      });

      const isUpdate = !isNewRegion(region);

      const url = isUpdate
        ? API.uploads.region.replace(':region_id', regionId)
        : API.uploads.regions;

      return request({
        url,
        method: isUpdate ? 'PUT' : 'POST',
        body: {
          ...formattedRegion,
          ...(isUpdate && {
            sync_detaching: true
          })
        }
      }).pipe(
        responseParser,
        RxOperators.mergeMap(({ data }) => {
          const saveRegionSuccess = {
            type: 'records-regions/save-success',
            recordId,
            regionId: data.id,
            ...(!isUpdate && {
              originalId: regionId,
              regionId: data.id
            }),
            region: regionNormalizer(data)
          };

          const selectRegion = nextRegionId
            ? { type: 'records-regions/set-selected', regionId: nextRegionId }
            : null;

          return [
            saveRegionSuccess,
            selectRegion,
            // TODO: hide it only if current region is in fact active selected
            modalState.visible
              ? {
                  type: 'modals/close',
                  modalId: MODAL_ID
                }
              : null
          ].filter(Boolean);
        }),
        RxOperators.catchError(({ message, response, status }) => {
          const { data } = response;

          if (+status === 422) {
            const errorsList = flatErrors(data);
            errorsList.forEach(error => {
              toast.error(error, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2500
              });
            });
          } else {
            toast.error(message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            });
          }

          return [
            {
              type: 'records-regions/save-fail',
              error: response ? response.data : response,
              regionId
            },
            // TODO: hide it only if current region is in fact active selected
            modalState.visible
              ? {
                  type: 'modals/close',
                  modalId: MODAL_ID
                }
              : null
          ].filter(Boolean);
        })
      );
    })
  );

export default saveRegionEpic;
