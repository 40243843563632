import revertDictionary from 'utils/revertDictionary';

const profileDictionary = {
  'profile.tabs.info': {
    en: 'Info',
    ru: 'Основная информация'
  },
  'profile.tabs.security': {
    en: 'Security',
    ru: 'Безопасность'
  },
  'profile.tabs.projectsAccess': {
    en: 'Projects access settings',
    ru: 'Настройки доступа к проектам'
  },
  'profile.tables.projectsAccess.columns.projectId': {
    en: 'ID',
    ru: 'ID'
  },
  'profile.tables.projectsAccess.columns.projectTitle': {
    en: 'Project title',
    ru: 'Проект'
  },
  'profile.tables.projectsAccess.columns.accessLevel': {
    en: 'Access level',
    ru: 'Уровень доступа'
  },
  'profile.joinedIn': {
    ru: 'Зарегистрирован с {createdAt, date, ::yyyy}',
    en: 'Since {createdAt, date, ::yyyy}'
  }
};

export default revertDictionary(profileDictionary);
