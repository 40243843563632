import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { camelizeKeys } from 'humps';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';

import type {
  FetchAccessSettingsList,
  FetchAccessSettingsListFail,
  FetchAccessSettingsListSuccess
} from 'flow-types/actions/projects/detail/accessRules/FetchAccessSettingsList';
import type { AppState } from 'flow-types/AppState';

import { projectIdFromPathSelector } from '../../../../selectors/projects';

const fetchAccessSettingsList = (action$, state$) =>
  action$.pipe(
    ofType(
      'project/fetch-access-settings-list',
      'project/save-access-settings-success',
      'project/remove-access-settings-success'
    ),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([, state]: [FetchAccessSettingsList, AppState]) => {
      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(API.projects.accessSettings.list, { projectId }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map((rawData): FetchAccessSettingsListSuccess => ({
          type: 'project/fetch-access-settings-list-success',
          data: camelizeKeys(rawData)
        })),
        RxOperators.catchError((error: AjaxError): [
          FetchAccessSettingsListFail
        ] => [
          {
            type: 'project/fetch-access-settings-list-fail',
            error: error.response
          }
        ])
      );
    })
    // here goes the code
  );

export default fetchAccessSettingsList;
