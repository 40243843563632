import composeDictionaries from 'utils/composeDictionaries';
import revertDictionary from 'utils/revertDictionary';

import buildingFormDictionary from './BuildingForm/dictionary';

const dictionary = {
  'buildings.header.title': {
    ru: 'Здания',
    en: 'Buildings'
  }
};

export default revertDictionary(
  composeDictionaries(buildingFormDictionary, dictionary)
);
