import type { Action } from '../flow-types/actions/language';
import type { LanguageState } from '../flow-types/states/LanguageState';

const initialState: LanguageState = 'en';

export default (state: LanguageState = initialState, action: Action) => {
  switch (action.type) {
    case 'language/ru':
      return 'ru';
    case 'language/en':
      return 'en';
    default:
      return state;
  }
};
