import * as Y from 'yup';
import isNil from 'lodash/isNil';

import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import nullToString from 'common/helpers/nullToString';
import type {
  ITest as IBaseTest,
  ITestSource
} from '../containers/TestsForm/flow';

// validateTest requires sourceData to be passed within ITest data so
// that its validation could be handled properly
type ITest = IBaseTest & {
  sourceData: ITestSource
};

export type ValidatorConfig = {
  messages?: {
    groupingOperatorIsRequired?: mixed,
    testOperatorIsRequired?: mixed,
    testOperatorIsNotSupported?: mixed,
    testOptionsAreRequired?: mixed,
    testSourceIsRequired?: mixed,
    testUnionIsRequired?: mixed,
    testUnionIsNotSupported?: mixed,
    testValueIsRequired?: mixed,
    testOptionsMinimum?: mixed
  }
};

export const initialConfig: ValidatorConfig = {
  messages: {
    groupingOperatorIsRequired: {
      messageId: 'testForm.validation.groupingOperator.isRequired'
    },
    testSourceIsRequired: {
      messageId: 'testForm.validation.testSource.isRequired'
    },
    testOperatorIsRequired: {
      messageId: 'testForm.validation.testOperator.isRequired'
    },
    testUnionIsRequired: {
      messageId: 'testForm.validation.testUnionType.isRequired'
    },
    testUnionIsNotSupported: {
      messageId: 'testForm.validation.testUnionType.isNotSupported'
    },
    testOperatorIsNotSupported: {
      messageId: 'testForm.validation.testUnionType.isNotSupported'
    },
    testOptionsAreRequired: {
      messageId: 'testForm.validation.testOptions.isRequired'
    },
    testOptionsMinimum: {
      messageId: 'testForm.validation.testOptions.min',
      values: {
        count: 1
      }
    },
    testValueIsRequired: {
      messageId: 'testForm.validation.testValue.isRequired'
    }
  }
};

type TestSchemaSkeleton = {
  source?: any,
  operator?: any,
  unionType?: any,
  options?: any,
  value?: any
};

const configureValidator = (config = initialConfig) =>
  Y.lazy((test: ITest): any => {
    if (typeof test === 'string') {
      return Y.string()
        .required()
        .oneOf(
          Object.values(LOGIC_OPERAND),
          config.messages?.groupingOperatorIsRequired
        );
    }

    const shouldAvoidValueInputCheck = [
      LOGIC_OPERATOR.NOT_EMPTY,
      LOGIC_OPERATOR.EMPTY
    ].includes(test.operator);

    const shouldCheckUnionType =
      Array.isArray(test.options) && !shouldAvoidValueInputCheck;

    const schema: TestSchemaSkeleton = {
      source: Y.mixed().required(config.messages?.testSourceIsRequired),
      operator: Y.string()
        .transform(nullToString)
        .required(config.messages?.testOperatorIsRequired)
        .oneOf(
          Object.values(LOGIC_OPERATOR),
          config.messages?.testOperatorIsNotSupported
        )
    };

    if (shouldCheckUnionType) {
      schema.unionType = Y.string()
        .transform(nullToString)
        .required(config.messages?.testUnionIsRequired)
        .oneOf(
          Object.values(LOGIC_OPERAND),
          config.messages?.testUnionIsNotSupported
        );
    }

    if (!shouldAvoidValueInputCheck) {
      const { sourceData } = test;

      if (sourceData?.isDate && !!test.settings?.specialTime) {
        schema.value = Y.mixed().test(
          'value',
          config.messages?.testValueIsRequired,
          value => Array.isArray(value) && !isNil(value[0]) && !isNil(value[1])
        );
      } else if (sourceData?.isSelectable) {
        schema.options = Y.array()
          .required(config.messages?.testOptionsAreRequired)
          .min(1, config.messages?.testOptionsMinimum);
      } else {
        schema.value = Y.string()
          .transform(val => (typeof val === 'number' ? `${val}` : val))
          .transform(nullToString)
          .required(config.messages?.testValueIsRequired);
      }
    }

    return Y.object(schema);
  });

export default configureValidator;
