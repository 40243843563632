// @flow
import format from 'date-fns/format';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import type { ProjectResponsesStateFilter } from 'flow-types/states/ProjectsState/detail';

const denormalizeFilter = (filter: ProjectResponsesStateFilter) => {
  const {
    byBlocksValues,
    interviewerIds,
    dateCreatedFrom,
    dateCreatedTo,
    dateEndFrom,
    dateEndTo,
    ...safeFilter
  } = filter;

  let result = {
    ...safeFilter
  };

  if (interviewerIds) {
    result = {
      ...result,
      interviewerIds
    };
  }

  if (dateCreatedTo) {
    result = {
      ...result,
      dateCreatedTo: format(dateCreatedTo, 'yyyy-MM-dd HH:mm')
    };
  }

  if (dateCreatedFrom) {
    result = {
      ...result,
      dateCreatedFrom: format(dateCreatedFrom, 'yyyy-MM-dd HH:mm')
    };
  }

  if (dateEndTo) {
    result = {
      ...result,
      dateEndTo: format(dateEndTo, 'yyyy-MM-dd HH:mm')
    };
  }

  if (dateEndFrom) {
    result = {
      ...result,
      dateEndFrom: format(dateEndFrom, 'yyyy-MM-dd HH:mm')
    };
  }

  if (byBlocksValues) {
    result = reduce(
      byBlocksValues,
      (lastResult, value) => {
        if (!value[0] || isNil(value[1]) || value[1] === '') {
          return lastResult;
        }

        return {
          ...lastResult,
          [value[0]]: value[1]
        };
      },
      result
    );
  }

  return result;
};

export default denormalizeFilter;
