import fp from 'lodash/fp';
import size from 'lodash/size';
import find from 'lodash/find';
import { camelizeKeys } from 'humps';

import type { IRate, IRegion } from 'flow-types/entities/RecordRegion';
import type { IChecklistItem } from 'flow-types/entities/ChecklistItem';

export const checklistItemNormalizer = (
  checklistItem: IChecklistItem
): IRate => {
  let result: IRate = {
    ...checklistItem
  };

  if (checklistItem.pivot) {
    result = {
      ...result,
      checklistItemOptionId: checklistItem.pivot.checklistItemOptionId,
      checklistItemId: checklistItem.pivot.checklistItemId,
      checklistTagId: checklistItem.pivot.checklistTagId,
      ratingComment: checklistItem.pivot.ratingComment,
      comment: checklistItem.pivot.ratingComment
    };
  } else {
    result = {
      checklistItemOptionId: null,
      checklistItemId: null,
      checklistTagId: null,
      ratingComment: '',
      comment: '',
      ...result
    };
  }

  if (checklistItem.options) {
    const selectedOption = find(
      checklistItem.options,
      option => option.id === result.checklistItemOptionId
    );

    result = {
      ...result,
      rating: (selectedOption && selectedOption.value) || 0
    };
  }

  return result;
};

export const normalizeChecklistItems = fp.map(checklistItemNormalizer);

export const regionChecklistItemsNormalizer = (region: IRegion): IRegion => {
  const next = { ...region };

  if (size(next.checklistItems) > 0) {
    next.checklistItems = normalizeChecklistItems(next.checklistItems);
  } else {
    next.checklistItems = [{}];
  }

  return next;
};

const regionTimeFrameNormalizer = (region: IRegion): IRegion => {
  const { start, end } = region;

  return {
    ...region,
    start: parseFloat(start),
    end: parseFloat(end)
  };
};

export const regionNormalizer = fp.compose(
  regionTimeFrameNormalizer,
  regionChecklistItemsNormalizer,
  (region: IRegion) => {
    const { record, ...safeData } = region;
    return {
      ...safeData,
      record,
      recordId: (record && record.id) || null
    };
  },
  camelizeKeys
);

export default fp.map(regionNormalizer);
