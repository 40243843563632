import React, { useCallback } from 'react';
import ListItem from 'common/components/ListItem';
import Checkbox from 'common/components/Checkbox';

import type { IQuestionOption } from 'flow-types/entities/QuestionOption';

import { Option as OptionWrapper } from '../../styled';

type Props = {|
  disabled: boolean,
  selected: boolean,
  data: IQuestionOption,
  onSelect: Function,
  onHover: () => void,
  onLeave: () => void,
  type: 'radio' | 'checkbox',
  index: number
|};

function Option({
  disabled,
  selected,
  data,
  onSelect,
  onHover,
  onLeave,
  type,
  index
}: Props) {
  const handleSelect = useCallback(() => {
    if (!onSelect) return;

    onSelect(data.id);
  }, [data.id, onSelect]);

  return (
    <ListItem
      as={OptionWrapper}
      onFocus={onHover}
      onBlur={onLeave}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
    >
      <Checkbox
        radio={type === 'radio'}
        tabIndex={index}
        name={
          type === 'radio'
            ? `question-${data.questionId}`
            : `question-${data.questionId}-option-${data.id}`
        }
        data-option-id={data.id}
        disabled={disabled}
        onClick={handleSelect}
        value={selected}
        labelClassName="question__options-item-label"
      >
        {data.title}
      </Checkbox>
    </ListItem>
  );
}

export default Option;
