import { concat } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import chunk from 'lodash/chunk';
import map from 'lodash/map';

import type { FetchUserFiltersSettingsCounts } from 'flow-types/actions/projects/detail/filtersSettings/FetchUserFiltersSettingsCounts';

import { API } from 'utils/config';
import request from 'utils/request';

import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import userFiltersSettingsCountsNormalizer from 'common/transducers/projects/userFiltersSettingsCountsNormalizer';

import { flatGroupFilters } from 'common/transducers/projects/filterSettingsNormalizer';
import type { AppState } from 'flow-types/AppState';
import {
  projectFiltersSettingsSelectorCreator,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const fetchFilterSettingsCounts = (action$, state$) =>
  action$.pipe(
    ofType('project/fetch-user-filters-settings-counts'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(
      ([, state]: [FetchUserFiltersSettingsCounts, AppState]) => {
        const { data } = projectFiltersSettingsSelectorCreator()(state);

        const projectId = projectIdFromPathSelector(state);

        const chunks = chunk(flatGroupFilters(data), 5);

        const chunksSize = chunks.length;

        const fetchOrder = map(chunks, (items, index) =>
          request({
            url: interpolateString(API.projects.userFilterSettings.counts, {
              projectId
            }),
            query: {
              id: map(items, item => item.id),
              force: 'true'
            }
          }).pipe(
            responseParser,
            RxOperators.pluck('data'),
            RxOperators.map(countsData => ({
              result: countsData,
              isFinal: index + 1 === chunksSize
            }))
          )
        );

        if (fetchOrder.length === 0) {
          return [
            {
              type: 'project/fetch-user-filters-settings-counts-success',
              data: [],
              isFinal: true
            }
          ];
        }

        return concat(...fetchOrder).pipe(
          RxOperators.map(({ result, isFinal }) => ({
            type: 'project/fetch-user-filters-settings-counts-success',
            data: userFiltersSettingsCountsNormalizer(result),
            isFinal
          })),
          RxOperators.take(chunksSize),
          RxOperators.takeUntil(
            action$.pipe(
              ofType(
                'project/reset',
                'project/fetch-filter-settings-list-groups-success'
              )
            )
          )
        );
      }
    )
  );

export default fetchFilterSettingsCounts;
