import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import type { ITestSource } from './flow';

export const excludedChecksBy = {
  selectable: [
    LOGIC_OPERATOR.LESS_THAN,
    LOGIC_OPERATOR.LESS_THAN_OR_EQUAL,
    LOGIC_OPERATOR.GREATER_THAN,
    LOGIC_OPERATOR.GREATER_THAN_OR_EQUAL
  ],
  unselectable: [LOGIC_OPERATOR.IN, LOGIC_OPERATOR.NOT_IN],
  // text is expanded with unselectable
  text: [
    LOGIC_OPERATOR.LESS_THAN,
    LOGIC_OPERATOR.LESS_THAN_OR_EQUAL,
    LOGIC_OPERATOR.GREATER_THAN,
    LOGIC_OPERATOR.GREATER_THAN_OR_EQUAL
  ]
};

export const processNextSourceSelection = (nextSource: ITestSource) => {
  const update = {};

  update.source = nextSource.id;

  // reset settings if source has changed
  update.settings = {};
  // reset value and options if source has changed
  update.value = null;
  update.options = null;
  update.operator = null;
  update.unionType = null;

  // defaults
  if (nextSource.isSelectable) {
    if (nextSource.targetField && nextSource.targetField !== 'value') {
      update.options = [];
    }
    update.operator = LOGIC_OPERATOR.IN;
    update.unionType = LOGIC_OPERAND.AND;
  }

  // overrides
  if (nextSource.defaultCheckOperator === null) {
    update.operator = null;
  } else if (typeof nextSource.defaultCheckOperator === 'string') {
    update.operator = nextSource.defaultCheckOperator;
  }

  return update;
};
