// @flow

import type { ChangeActiveStack } from '../../flow-types/actions/interview/ChangeActiveStack';

export default function changeActiveStack(stackId = false): ChangeActiveStack {
  return {
    type: 'interview/change-active-stack',
    stackId
  };
}
