import React from 'react';
import type { ReactComponent } from 'flow-types/ReactComponent';
import Input, { InputWrapper } from '../../../components/Input';

type Props = {
  error?: boolean,
  fluid?: boolean,
  size?: null | string,
  CustomInputComponent?: ReactComponent
};

export default function TextInput({
  error,
  fluid,
  size,
  CustomInputComponent,
  ...props
}: Props) {
  if (CustomInputComponent) {
    return (
      <CustomInputComponent
        fluid={fluid}
        size={size}
        error={error}
        {...props}
      />
    );
  }

  return (
    <InputWrapper fluid={fluid} size={size} error={error}>
      <Input {...props} onlyValue />
    </InputWrapper>
  );
}

TextInput.defaultProps = {
  error: false,
  fluid: false,
  size: null,
  CustomInputComponent: null
};
