import size from 'lodash/size';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import type {
  SaveSearchSettings,
  SaveSearchSettingsFail,
  SaveSearchSettingsSuccess
} from 'flow-types/actions/projects/detail/searchSettings/SaveSearchSettings';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import isNewElement from 'common/helpers/isNewElement';
import { normalizeSearchSettings } from 'common/transducers/projects/searchSettingsNormalizer';
import { denormalizeSearchSettings } from 'common/transducers/projects/searchSettingsDenormalizer';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

const saveSearchSettings = (action$, state$) =>
  action$.pipe(
    ofType('project/save-search-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([action, state]: [SaveSearchSettings, AppState]) => {
      const { searchSettings } = action;

      const { questionIds } = searchSettings;

      const projectId = projectIdFromPathSelector(state);

      const isNewSettings = isNewElement(searchSettings);

      if (size(questionIds) === 0) {
        if (!isNewSettings) {
          return [
            {
              type: 'project/remove-search-settings',
              settingsId: searchSettings.id
            }
          ];
        }

        return [
          {
            type: 'project/save-search-settings-success',
            searchSettings: null
          }
        ];
      }

      const params = isNewSettings
        ? {
            url: interpolateString(API.projects.searchSettings.list, {
              projectId
            }),
            method: 'POST',
            body: denormalizeSearchSettings(searchSettings)
          }
        : {
            url: interpolateString(API.projects.searchSettings.detail, {
              projectId: searchSettings.projectId,
              settingsId: searchSettings.id
            }),
            method: 'PUT',
            body: denormalizeSearchSettings(searchSettings)
          };

      return request(params).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map((rawData): SaveSearchSettingsSuccess => ({
          type: 'project/save-search-settings-success',
          searchSettings: normalizeSearchSettings(rawData)
        })),
        RxOperators.catchError((error: AjaxError): [SaveSearchSettingsFail] => [
          {
            type: 'project/save-search-settings-fail',
            error: error.response
          }
        ])
      );
    })
  );

export default saveSearchSettings;
