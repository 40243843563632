import type { Pagination } from 'flow-types/Pagination';

const derivePaginationPropsFromPaginationState$: (
  pagination: Pagination,
  filter: { pn: number }
) => { totalPages: number, activePage: number } = (
  pagination,
  filter
): { activePage: number, totalPages: number, ... } => {
  const { totalPages } = pagination;
  const { pn } = filter;

  return {
    totalPages: totalPages === 0 ? 1 : totalPages,
    activePage: (pn ?? 0) + 1
  };
};

export default derivePaginationPropsFromPaginationState$;
