import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import request from 'utils/request';
import type { Epic } from 'flow-types/Epic';
import type { DeleteLogicRule } from 'flow-types/actions/projects/detail/logic/DeleteLogicRule';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import decamelizeKeys from 'common/helpers/decamelizeKeys';

const deleteLogicRule: Epic = action$ =>
  action$.pipe(
    ofType('project/delete-logic-rule'),
    RxO.mergeMap((action: DeleteLogicRule) => {
      const { ruleId } = action;

      return request({
        url: interpolateString(
          API.projects.projectRule,
          decamelizeKeys({ ruleId })
        ),
        method: 'DELETE'
      }).pipe(
        RxO.mergeMap(() => [
          { type: 'project/delete-logic-rule-success' },
          { type: 'project/fetch-logic' }
        ]),
        RxO.catchError((error: AjaxError) => {
          const { message } = error;

          return of({
            type: 'project/delete-logic-rule-fail',
            ruleId,
            error: message
          });
        })
      );
    })
  );

export default deleteLogicRule;
