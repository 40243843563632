import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import find from 'lodash/find';
import * as RxOperators from 'rxjs/operators';
import { toast } from 'react-toastify';
import request from '../../utils/request';
import { API } from '../../utils/config';
import interpolateString from '../../common/helpers/interpolateString';
import responseParser from '../../common/epicHelpers/responseParser';
import type { Epic } from '../../flow-types/Epic';
import {
  recordsListStateSelector,
  recordsPeaksStateSelector
} from '../../selectors/records';
import type {
  RecordsListState,
  RecordsPeaksState
} from '../../flow-types/states/RecordsState';
import type { FetchRecordPeaks } from '../../flow-types/actions/records/FetchRecordPeaks';

const fetchRecordPeaks: Epic = $action =>
  $action.pipe(
    ofType('records-peaks/fetch'),
    // we only want to start peaks once for each record
    RxOperators.distinctUntilChanged(
      (prev, curr) => prev.recordId === curr.recordId
    ),
    RxOperators.mergeMap((action: FetchRecordPeaks) =>
      request({
        url: interpolateString(
          API.uploads.peaks,
          {
            upload_id: action.recordId
          },
          ':'
        ),
        query: {
          ...action.options
        }
      }).pipe(
        responseParser,
        RxOperators.map(({ data }) => {
          const { lines1, lines2 } = data;

          return {
            type: 'records-peaks/fetch-success',
            recordId: action.recordId,
            peaks: [lines1, lines2.length > 0 ? lines2 : [0, 0, 0]]
          };
        }),
        RxOperators.catchError(() => {
          toast.error(
            `Something wrong happened while loading peaks for record #${action.recordId}`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'records-peaks/fetch-fail',
            recordId: action.recordId
          });
        })
      )
    )
  );

export const fetchPeaksOnRecordSelection = ($action, $state) =>
  $action.pipe(
    ofType('records/select'),
    RxOperators.withLatestFrom($state),
    RxOperators.filter(([action, state]) => {
      const { recordId } = action;

      const data: RecordsPeaksState = recordsPeaksStateSelector(state);

      const peaksForRecord = data?.[recordId] || null;

      if (peaksForRecord === null) return true;

      return !peaksForRecord.data && peaksForRecord.status !== 'loading';
    }),
    RxOperators.mergeMap(([action, state]): FetchRecordPeaks => {
      const { recordId } = action;

      const { data: records }: RecordsListState = recordsListStateSelector(
        state
      );

      const record = find(records, { id: recordId });

      let peaksFetchOptions = {};

      if (record && record.duration) {
        peaksFetchOptions = {
          ...peaksFetchOptions,
          width: record.duration * 3
        };
      }

      return [
        {
          type: 'records-peaks/fetch',
          recordId,
          options: peaksFetchOptions
        }
      ];
    })
  );

export default fetchRecordPeaks;
