import React from 'react';
import cx from 'classnames';

export type ListItemProps = {
  as?: 'div' | 'li' | 'a',
  className?: string,
  selected?: boolean,
  marked?: boolean,
  active?: boolean
};

const ListItem = React.forwardRef(
  (
    {
      as = 'div',
      className,
      selected,
      active,
      marked = false,
      ...rest
    }: ListItemProps,
    ref
  ) => {
    const Component = as ?? 'div';

    const classNames = cx(className, { selected, active, marked }, 'item');

    return <Component ref={ref} className={classNames} {...rest} />;
  }
);

ListItem.displayName = 'ListItem.ForwardRef';

// $FlowIgnore
ListItem.defaultProps = {
  as: 'div',
  className: null,
  selected: false,
  marked: false,
  active: false
};

export const Content = ({ className, ...rest }: { className: string }) => (
  <div className={cx([className], 'content')} {...rest} />
);

export const Header = ({
  as: Component = 'div',
  className,
  ...rest
}: {
  as: 'div' | 'a',
  className: string
}) => <Component className={cx([className], 'header')} {...rest} />;

export const Description = ({ className, ...rest }: { className: string }) => (
  <div className={cx([className], 'description')} {...rest} />
);

export default ListItem;
