// @flow

import React, { useMemo } from 'react';
import type { Node } from 'react';
import cx from 'classnames';
import type { ReactComponent } from 'flow-types/ReactComponent';

type Props = {
  +className?: null | string,
  +scrolling?: boolean,
  +image?: boolean,
  +active?: boolean,
  +floated?: null | boolean | 'left' | 'right',
  +tagName?: ReactComponent
};

// TODO: implement that component everywhere, where content is used
export default function Content({
  className,
  scrolling,
  image,
  floated,
  active,
  tagName: Component,
  ...props
}: Props): Node {
  const classNames = useMemo(
    () =>
      cx(
        {
          active,
          'left floated': floated === 'left',
          'right floated': floated === 'right',
          scrolling,
          image
        },
        className,
        'content'
      ),
    [active, className, floated, image, scrolling]
  );

  if (!Component) return null;

  return <Component {...props} className={classNames} />;
}

Content.defaultProps = {
  className: null,
  floated: false,
  active: false,
  scrolling: false,
  image: false,
  tagName: 'div'
};
