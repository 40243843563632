// @flow
import type { Pagination, Pagination$Raw } from 'flow-types/Pagination';

export default function normalizePagination(
  pagination: Pagination$Raw
): Pagination {
  return {
    totalElements: pagination.total,
    totalPages: pagination.pages,
    activePage: pagination.current
  };
}
