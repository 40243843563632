// @flow
import type { ComponentType } from 'react';
import styled from 'styled-components';

export const OptionGroupLabel: ComponentType<Object> = styled.h4`
  white-space: normal !important;
  margin: 0;
  padding: 5.5px 5px;
  background-color: rgba(0, 0, 0, 0.45);
  color: white;
`;
