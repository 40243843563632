import fp from 'lodash/fp';
import moment from 'moment';
import { camelizeKeys } from 'humps';
import type { IUpload } from '../../../flow-types/entities/Upload';
import { API_PREFIX } from '../../../utils/config';
import toHHMMSS from '../../helpers/toHHMMSS';

export const recordNormalizer = (upload: IUpload) => {
  let duration = null;

  if (upload.timeStart && upload.timeEnd) {
    duration =
      moment
        .duration(moment(upload.timeEnd).diff(upload.timeStart))
        .asMilliseconds() / 1000;
  }

  return {
    ...upload,
    duration,
    parsedDuration: duration ? toHHMMSS(duration) : 'N/A'
  };
};

const urlNormalizer = (upload: IUpload) => {
  const { path, filename, url } = upload;

  if (url) {
    return upload;
  }

  // eslint-disable-next-line consistent-return
  return {
    ...upload,
    url: path ? `${API_PREFIX}/uploads${path}${filename}` : null
  };
};

const uploadIdentifiersNormalizer = (upload: IUpload): IUpload => {
  const { questionId, responseId } = upload;
  return {
    ...upload,
    questionId:
      typeof questionId === 'string' ? +questionId : questionId || null,
    responseId:
      typeof responseId === 'string' ? +responseId : responseId || null
  };
};

export const uploadNormalizer = (upload: IUpload) => {
  const { isAudio } = upload;

  const nextUpload = {
    ...upload
  };

  if (isAudio) return recordNormalizer(nextUpload);

  return nextUpload;
};

const uploadsNormalizer = fp.compose(
  fp.map(uploadNormalizer),
  fp.map(urlNormalizer),
  fp.map(uploadIdentifiersNormalizer),
  fp.map(camelizeKeys),
  fp.filter(Boolean)
);

export default uploadsNormalizer;
