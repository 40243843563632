// @flow

import type {
  CompleteReview,
  CompleteReviewFail,
  CompleteReviewSuccess
} from '../../../flow-types/actions/projects/detail/response/CompleteReview';

export function createCompleteReview(): CompleteReview {
  return {
    type: 'project-response/complete-review'
  };
}

export function createCompleteReviewFail(): CompleteReviewFail {
  return {
    type: 'project-response/complete-review-fail'
  };
}

export function createCompleteReviewSuccess(
  reviewStageId
): CompleteReviewSuccess {
  return {
    type: 'project-response/complete-review-success',
    reviewStageId
  };
}
