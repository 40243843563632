// @flow

import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import Modal from 'common/components/animated/Modal';
import useModal from 'common/hooks/useModal';
import Button from 'common/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { DecodingsState } from 'flow-types/states/RecordsState';
import SidesForm from './SidesForm';
import { decodingsStateSelector } from '../../../../../../../../selectors/records/decodings';

export const MODAL_ID = 'modals/decoding-sides';

const dimmerProps = { page: true };

const selector = createSelector(
  [decodingsStateSelector],
  (decodingsState: DecodingsState) => {
    const { sidesForm } = decodingsState;

    return sidesForm.status;
  }
);

export default function DecodingSidesModal() {
  const dispatch = useDispatch();

  const status = useSelector(selector);

  const [formInstance, setForm] = useState(null);

  const [state, , close] = useModal(MODAL_ID);

  const submit = useCallback(() => {
    if (formInstance) {
      formInstance.current.submit();
    }
  }, [formInstance]);

  const onSubmit = useCallback(
    sides => {
      if (!state.data) return;
      dispatch({
        type: 'records/save-decoding-sides',
        decodingId: state.data.decodingId,
        sides: keyBy(sides, 'id')
      });
    },
    [dispatch, state.data]
  );

  return (
    <Modal
      onClose={status === 'loading' ? null : close}
      portal
      dimmerProps={dimmerProps}
      visible={state.visible}
    >
      <Modal.Header>
        <FormattedMessage id="transcriptions.sidesForm.title" />
      </Modal.Header>
      <Modal.Content scrolling>
        {state.visible && (
          <SidesForm
            disabled={status === 'loading'}
            onFormInstanceReceived={setForm}
            initialData={values(state.data.sides)}
            onSubmit={onSubmit}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={status === 'loading'}
          buttonType="deny"
          type="button"
          color="black"
          onClick={close}
        >
          <FormattedMessage id="common.labels.cancel" />
        </Button>
        <Button
          disabled={status === 'loading'}
          buttonType="positive"
          type="button"
          onClick={submit}
        >
          <FormattedMessage id="common.labels.submit" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
