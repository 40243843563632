// @flow

import React from 'react';
import LazyLoad from 'react-lazyload';
import type { Node } from 'react';

import Image from './index';

/**
 * It is a lazy-loading version of Image component
 */

type Props = {
  lazyProps?: null | Object
};

export default function LazyImage({ lazyProps, ...props }: Props): Node {
  return (
    <LazyLoad {...(lazyProps && lazyProps)}>
      <Image {...props} />
    </LazyLoad>
  );
}

LazyImage.defaultProps = {
  lazyProps: null
};
