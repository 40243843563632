import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import { API } from 'utils/config';
import request from 'utils/request';

import type { Epic } from 'flow-types/Epic';

import responseParser from '../../../common/epicHelpers/responseParser';

const deleteUserEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/delete-user'),
    RxOperators.mergeMap(action => {
      const { userId } = action;

      return request({
        url: API.users.detail.replace(':userId', userId),
        method: 'DELETE'
      }).pipe(
        responseParser,
        RxOperators.mergeMap(() =>
          of(
            { type: 'companies/delete-user-success', userId },
            { type: 'companies/fetch-users' }
          )
        ),
        RxOperators.catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({ type: 'companies/delete-user-fail' });
        })
      );
    })
  );

export default deleteUserEpic;
