/*
 * TODO: Actually, question should contain some info about group,
 *  thus in future creation of those flags should be replaced,
 *  by injecting a bit of group data inside a question
 */
export default function mixQuestionWithGroupRelatedFlags(question, group) {
  return {
    ...question,
    inSinglePageGroup: group.isSinglePage,
    inStatusGroup: group.isStatusGroup
  };
}
