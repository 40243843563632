import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import sortingItemsNormalizer from '../sortingItemsNormalizer';
import filterDeleted from '../../helpers/filterDeleted';

// creates option.order field
const processOptionOrder = option => ({
  ...option,
  order: option.sortOrder
});

export const optionNormalizer = fp.compose(processOptionOrder, camelizeKeys);

const optionsNormalizer = fp.compose(
  filterDeleted,
  sortingItemsNormalizer,
  fp.map(optionNormalizer)
);

export default optionsNormalizer;
