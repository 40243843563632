// @flow

import map from 'lodash/map';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import uniqueId from 'lodash/uniqueId';
import pullAt from 'lodash/pullAt';
import merge from 'common/helpers/deleteAwareMerge';
import { generateNewData } from 'common/helpers/generateNewData';
import isNewElement from 'common/helpers/isNewElement';
import getNextOrder from 'common/helpers/getNextOrder';
import constructObjectMap from 'common/helpers/constructObjectMap';
import {
  TYPES,
  generateNewQuestion,
  VIEW_TYPES
} from 'common/helpers/question';

import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectDetailAction } from 'flow-types/actions/projects/detail';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { IQuestion } from 'flow-types/entities/Question';
import type { Reducer } from 'flow-types/Reducer';
import type { DeleteQuestionGroupSuccess } from 'flow-types/actions/projects/detail/structure/questionGroups/DeleteQuestionGroup';
import type { DeleteQuestionSuccess } from 'flow-types/actions/projects/detail/structure/questions/DeleteQuestion';
import type { AddQuestion } from 'flow-types/actions/projects/detail/structure/questions/AddQuestionProcess';
import mixQuestionWithGroupRelatedFlags from '../../pages/Project/helpers/mixQuestionWithGroupRelatedFlags';
import mixGroupWithQuestionsRelatedFlags from '../../pages/Project/helpers/mixGroupWithQuestionsRelatedFlags';

const initialState: ProjectGroupsState = {
  data: [],
  loading: false,
  error: null,
  selectedQuestionId: null,
  selectedGroupId: null,
  visibleSelected: null,
  previewEnabled: false,
  quickImportModal: {
    targetQuestionGroup: null,
    targetQuestion: null,
    targetField: null,
    visible: false
  }
};

function handleGroupClone(
  state: ProjectGroupsState,
  action: Object
): ProjectGroupsState {
  if (!state.data || !action.groupId) return state;

  const group: IQuestionGroup | typeof undefined = state.data.find(
    g => g.id === action.groupId
  );

  if (!group) return state;

  const clonedGroupId = +uniqueId('-');

  const clonedGroup = mixGroupWithQuestionsRelatedFlags({
    ...group,
    order: state.data.length,
    isNew: true,
    id: clonedGroupId,
    // $FlowIgnore
    questions: group.questions
      ? group.questions.map(q =>
          mixQuestionWithGroupRelatedFlags(
            {
              ...q,
              id: +uniqueId('-'),
              isNew: true,
              questionGroupId: clonedGroupId,
              options: q.options
                ? q.options.map(o => ({
                    ...o,
                    id: +uniqueId('-'),
                    isNew: true
                  }))
                : []
            },
            group
          )
        )
      : []
  });

  return {
    ...state,
    data: [...state.data, clonedGroup]
  };
}

function handleQuestionDeleteSuccess(
  state: ProjectGroupsState,
  action: DeleteQuestionSuccess
): ProjectGroupsState {
  const { data } = state;

  const next = {
    ...state
  };

  const groupIndex = data.findIndex(
    group => group.id === action.questionGroupId
  );

  if (groupIndex === -1) return state;

  const group = { ...data[groupIndex] };

  const questionIndex = group.questions.findIndex(
    question => question.id === action.questionId
  );

  if (questionIndex === -1) return state;

  const isQuestionLast = questionIndex === group.questions.length - 1;
  const isQuestionFirst = questionIndex === 0;

  if (isQuestionLast && !isQuestionFirst) {
    // пробуем открыть предыдущий вопрос
    const prevItem = group.questions[questionIndex - 1];

    next.visibleSelected = 'question';
    next.selectedQuestionId = prevItem.id;
    // if you need group to be highlighted,
    // then you can set group here
    next.selectedGroupId = null;
  } else if (isQuestionFirst) {
    // пробуем открыть группу
    next.visibleSelected = 'group';
    next.selectedQuestionId = null;
    next.selectedGroupId = group.id;
  }

  next.data[groupIndex] = mixGroupWithQuestionsRelatedFlags({
    ...group,
    questions: group.questions.filter(
      question => question.id !== action.questionId
    )
  });

  return next;
}

function handleGroupDeleteSuccess(
  state: ProjectGroupsState,
  action: DeleteQuestionGroupSuccess
): ProjectGroupsState {
  const { data } = state;

  const next = {
    ...state
  };

  /*
   If we've deleted group when either question of that group is currently visible,
   or group itself, then clear information about selected items.
  */
  if (next.selectedGroupId === action.groupId) {
    const groupIndex = data.findIndex(group => group.id === action.groupId);

    const isLast = groupIndex === data.length - 1;

    const isFirst = groupIndex === 0;

    // if group is last,
    // then it is definitely first too
    if (isFirst) {
      next.selectedGroupId = null;
      next.visibleSelected = null;
      next.selectedQuestionId = null;
      // if group is last, but not first,
      // then try to open previous element
    } else if (isLast && !isFirst) {
      const prevGroup = data[groupIndex - 1];
      const prevGroupQuestionsLength = prevGroup.questions.length;

      next.selectedGroupId = prevGroup.id;

      if (prevGroupQuestionsLength > 0) {
        next.selectedQuestionId =
          prevGroup.questions[prevGroupQuestionsLength - 1].id;
        next.visibleSelected = 'question';
      } else {
        next.selectedQuestionId = null;
        next.visibleSelected = 'group';
      }
    }
  }

  /*
    Filter out deleted group from the list
  */
  next.data = next.data.filter(group => group.id !== action.groupId);

  return next;
}

function handleAddQuestions(
  state: ProjectGroupsState,
  action: AddQuestion
): ProjectGroupsState {
  const sourceGroupIndex = state.data.findIndex(
    group => group.id === action.questionGroupId
  );

  let group = state.data[sourceGroupIndex];

  const type = group.isStatusGroup ? TYPES.Status : TYPES.HTML;

  // TODO: resolve later
  // $FlowIgnore
  const question = generateNewQuestion({
    title: '',
    questionGroupId: action.questionGroupId,
    order: getNextOrder(group.questions),
    type,
    // $FlowIgnore
    viewType: type in VIEW_TYPES ? VIEW_TYPES[type][0] : null
  });

  group = mixGroupWithQuestionsRelatedFlags({
    ...group,
    questions: [
      ...group.questions,
      mixQuestionWithGroupRelatedFlags(question, group)
    ]
  });

  const nextData = [...state.data];

  nextData[sourceGroupIndex] = group;

  return {
    ...state,
    visibleSelected: 'question',
    selectedQuestionId: question.id,
    data: nextData
  };
}

const projectGroupReducer: Reducer<ProjectGroupsState, ProjectDetailAction> = (
  state: ProjectGroupsState = initialState,
  action: ProjectDetailAction
) => {
  let shouldCreateCache = true;
  switch (action.type) {
    // TODO: create action FTD
    case 'project-groups/clone-group':
      return handleGroupClone(state, action);

    case 'project-groups/preview-on':
      return {
        ...state,
        previewEnabled: true
      };

    case 'project-groups/preview-off':
      return {
        ...state,
        previewEnabled: false
      };

    case 'project/reset':
      return initialState;

    case 'project-groups/reset-question':
      return {
        ...state,
        data: state.data.map(group => {
          const { questions } = group;

          const questionIndex = questions.findIndex(
            q => q.id === action.questionId
          );

          if (questionIndex === -1) return group;

          const nextQuestions = [...questions];

          const question = nextQuestions[questionIndex];

          if (isNewElement(question)) {
            pullAt(nextQuestions, questionIndex);

            return mixGroupWithQuestionsRelatedFlags({
              ...group,
              questions: nextQuestions
            });
          }

          const { cache, ...original } = question;

          // TODO: resolve later
          nextQuestions[questionIndex] = mixQuestionWithGroupRelatedFlags(
            // $FlowIgnore
            {
              ...original,
              ...cache
            },
            group
          );

          return mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions: nextQuestions
          });
        })
      };

    case 'project-groups/reset-group':
      return {
        ...state,
        data: reduce(
          state.data,
          (_nextData, group) => {
            if (group.id !== action.questionGroupId) {
              return [..._nextData, group];
            }

            if (isNewElement(group)) {
              return _nextData;
            }

            const nextGroup = {
              ...group,
              ...group.cache
            };

            delete nextGroup.cache;

            nextGroup.questions = nextGroup.questions.map(question =>
              mixQuestionWithGroupRelatedFlags(question, nextGroup)
            );

            return [..._nextData, nextGroup];
          },
          []
        )
      };

    case 'project-groups/add-group':
      return {
        ...state,
        data: [...state.data, mixGroupWithQuestionsRelatedFlags(action.group)]
      };

    case 'project-groups/add-question':
      return handleAddQuestions(state, action);

    case 'project-groups/quick-import-open':
      return {
        ...state,
        quickImportModal: {
          visible: true,
          targetQuestionGroup: action.questionGroupId,
          targetQuestion: action.questionId,
          targetField: action.targetField
        }
      };

    case 'project-groups/quick-import-close':
      return {
        ...state,
        quickImportModal: initialState.quickImportModal
      };

    case 'project-groups/quick-import-submit':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          if (group.id !== state.quickImportModal.targetQuestionGroup) {
            return group;
          }

          if (state.quickImportModal.targetQuestion) {
            return mixGroupWithQuestionsRelatedFlags({
              ...group,
              questions: map(group.questions, q => {
                if (state.quickImportModal.targetQuestion !== q.id) return q;

                const updatedField: 'subQuestions' | 'options' =
                  state.quickImportModal.targetField || 'options';

                let baseOrder = 0;

                if (action.importMode === 'replace') {
                  baseOrder = 0;
                } else {
                  baseOrder = q[updatedField] ? q[updatedField].length : 0;
                }

                const generatedItems = map(action.importList, (o, index) => {
                  if (updatedField === 'subQuestions') {
                    return generateNewQuestion({
                      title: o,
                      order: baseOrder + index
                    });
                  }

                  // option
                  return generateNewData(
                    {
                      title: o,
                      order: baseOrder + index,
                      settings: {
                        appearance: {}
                      }
                    },
                    true
                  );
                });

                let nextItems = q[updatedField] || [];

                if (action.importMode === 'replace') {
                  nextItems = generatedItems;
                } else if (action.importMode === 'append') {
                  nextItems = [...nextItems, ...generatedItems];
                }

                return mixQuestionWithGroupRelatedFlags(
                  {
                    ...q,
                    // either options or subQuestions
                    // $FlowIgnore
                    [updatedField]: nextItems
                  },
                  group
                );
              })
            });
          }

          const newQuestionList = group.questions.concat(
            map(action.importList, (q, index) =>
              generateNewQuestion({
                title: q,
                questionGroupId: state.quickImportModal.targetQuestionGroup,
                order: group.questions.length + index,
                type: TYPES.HTML,
                options: []
              })
            )
          );

          return mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions: newQuestionList
          });
        }),
        quickImportModal: initialState.quickImportModal
      };

    case 'project-groups/delete-group-success':
      return handleGroupDeleteSuccess(state, action);

    case 'project-groups/remove-question-success':
      return handleQuestionDeleteSuccess(state, action);

    case 'project-groups/save-question-success':
      return {
        ...state,
        ...(action.originalId && {
          selectedQuestionId: action.data.id
        }),
        data: map<IQuestionGroup>(state.data, (group: IQuestionGroup) => {
          const { questions } = group;

          if (action.data.questionGroupId !== group.id) return group;

          const nextQuestions = map(questions, q => {
            if (action.data.id !== q.id && action.originalId !== q.id) {
              return q;
            }

            return mixQuestionWithGroupRelatedFlags(action.data, group);
          });

          return mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions: nextQuestions
          });
        })
      };

    case 'project-groups/save-question-fail':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          const { questions } = group;
          if (action.originalGroupId !== group.id) return group;

          return {
            ...group,
            questions: map(questions, q => {
              if (action.originalId !== q.id) return q;

              return {
                ...q,
                loading: false,
                error: action.error
              };
            })
          };
        })
      };

    case 'project-groups/save-question':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          const { questions } = group;
          if (action.question.questionGroupId !== group.id) return group;

          return {
            ...group,
            questions: map(questions, q => {
              if (action.question.id !== q.id) return q;

              return {
                ...q,
                loading: true
              };
            })
          };
        })
      };

    case 'project/fetch-success':
      return {
        ...state,
        error: null,
        data: action.project.groups.map(group =>
          mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions: group.questions.map(question =>
              mixQuestionWithGroupRelatedFlags(question, group)
            )
          })
        )
      };

    case 'project-groups/select-group':
      return {
        ...state,
        selectedGroupId: action.questionGroupId,
        selectedQuestionId: null,
        visibleSelected: 'group',
        previewEnabled: false
      };

    case 'project-groups/save-group':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, g => {
          if (action.originalId !== g.id && action.group.id !== g.id) return g;

          return {
            ...g,
            loading: true
          };
        })
      };

    case 'project-groups/save-group-success':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, g => {
          if (action.originalId !== g.id && action.data.id !== g.id) return g;

          return {
            ...mixGroupWithQuestionsRelatedFlags(action.data),
            // we replace only data of group, not its questions,
            // thus, we will not erase changes,
            // that user could have made in question
            // before saving group
            // questions: map(g.questions, q => ({
            //   ...q,
            //   questionGroupId: action.data.id
            // })),
            loading: false
          };
        })
      };

    case 'project-groups/save-group-fail':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, g => {
          if (action.originalId !== g.id && action.data.id !== g.id) return g;

          return {
            ...g,
            error: action.error,
            loading: false
          };
        })
      };

    case 'project-groups/update-group':
      if (typeof action.cache !== 'undefined' && !action.cache) {
        shouldCreateCache = false;
      }

      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          if (action.questionGroupId !== group.id) {
            return group;
          }

          const nextGroup = {
            ...group,
            ...action.dataUpdate,
            ...(shouldCreateCache &&
              !group.isNew &&
              !group.cache && {
                cache: omit(group, 'questions')
              })
          };

          return {
            ...nextGroup,
            questions: group.questions.map(question =>
              mixQuestionWithGroupRelatedFlags(question, nextGroup)
            )
          };
        })
      };

    case 'project-groups/update-question__UNSAFE':
      return {
        ...state,
        data: state.data.map(group => {
          if (action.questionGroupId !== group.id) {
            return group;
          }

          return mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions:
              group.questions?.map(question => {
                if (question.id !== action.questionId) {
                  return question;
                }

                let next: IQuestion = { ...question };

                // create cache if it is not present (i.e. first update)
                if (!next.cache) {
                  next.cache = { ...question };
                }

                next = merge(next, action.data);

                // I would like to hydrate reducer by proper data rather than falling back
                // to default one every single use case.
                // TODO: CRM views might have unique settings structure,
                // we should prepare templates and validators for each of them and
                // think how to organize it to keep this reducer clean.
                // const newQuery = {
                //   inputFields: [],
                //   outputFields: [],
                //   query: [],
                //   entity: null,
                //   action: null,
                //   filters: [],
                //   minChars: undefined,
                //   title: '',
                //   searchBy: null
                // };

                // remove changed keys from error object
                if (next.error && typeof next.error === 'object') {
                  next.error = { ...next.error };

                  const updatedPaths = constructObjectMap(action.data);

                  updatedPaths.forEach(path => {
                    // undefined is safe-checked above
                    // $FlowIgnore
                    delete next.error[path];
                    if (path === 'optionsFrom' && !!next.optionsFrom) {
                      // undefined is safe-checked above
                      // $FlowIgnore
                      delete next.error.options;
                    }
                  });
                }

                // if type has changed to SearchView,
                // then add defaults for SearchView
                // Note for @Nazar:
                // > It can be however added for question on normalization step (I'm about newQuery)
                // > inside questionNormalizer if they should/could be there.
                // > Look for 'projectSettingsNormalizer' as an example.
                // > If they should be present only if type is SearchView,
                // > then it is another question. May be server can filter out unused fields,
                // > instead of doing that part on client-side.
                // > Another way, you can create special helper functions ('transitioners'),
                // > that would carry out the process of changing question type from one to another
                // > with side-effects happening to its data.
                // > Also you can look at merge from merge-anything,
                // > it could help you to update objects deeply.
                // if (action.dataUpdate.type === SearchView) {
                // next.settings = {
                //   dataSettings: {
                //     query: newQuery
                //   }
                // };
                // } else {
                // if (!next.settings) {
                //   next.settings = {};
                // }

                // if (action.dataUpdate.settings) {
                //   next.settings = {
                //     ...next.settings,
                //     ...action.dataUpdate.settings
                //   };
                // }
                // }

                // SIDE_EFFECT / change from N-type to TYPES.Table
                if (
                  TYPES.Table === next.type &&
                  TYPES.Table !== question.type
                ) {
                  next = merge(next, {
                    settings: { type: TYPES.SingleAnswer }
                  });
                }

                if (
                  (!question.optionsFrom && !!next.optionsFrom) ||
                  question.optionsFrom !== next.optionsFrom
                ) {
                  if (next.type === TYPES.Table) {
                    next.subQuestions = [];
                  } else {
                    next.options = [];
                  }
                }

                if (
                  !next.optionsFrom &&
                  [
                    TYPES.SingleAnswer,
                    TYPES.MultipleAnswer,
                    TYPES.Checklist
                  ].includes(next.type)
                ) {
                  if (!Array.isArray(next.options)) {
                    next.options = [];
                  }

                  if (next.options.length === 0) {
                    next.options = [
                      generateNewData({
                        title: '',
                        sortOrder: 1,
                        order: 1
                      })
                    ];
                  }
                }

                return next;
              }) || []
          });
        })
      };

    case 'project-groups/update-question':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          if (action.questionGroupId !== group.id) {
            return group;
          }

          return mixGroupWithQuestionsRelatedFlags({
            ...group,
            questions: map(group.questions, q => {
              if (q.id !== action.questionId) return q;

              // create new reference
              let next: IQuestion = { ...q };

              // create cache if it is not present (i.e. first update)
              if (!next.cache) {
                next.cache = { ...q };
              }

              // update next with dataUpdate
              next = {
                ...next,
                ...action.dataUpdate
              };

              // I would like to hydrate reducer by proper data rather than falling back
              // to default one every single use case.
              // TODO: CRM views might have unique settings structure,
              // we should prepare templates and validators for each of them and
              // think how to organize it to keep this reducer clean.
              const newQuery = {
                inputFields: [],
                outputFields: [],
                query: [],
                entity: null,
                action: null,
                filters: [],
                minChars: undefined,
                title: '',
                searchBy: null
              };

              // remove changed keys from error object
              if (next.error && typeof next.error === 'object') {
                next.error = { ...next.error };

                Object.keys(action.dataUpdate).forEach(key => {
                  // undefined is safe-checked
                  // $FlowIgnore
                  delete next.error[key];
                  if (key === 'optionsFrom' && !!next.optionsFrom) {
                    // undefined is safe-checked above
                    // $FlowIgnore
                    delete next.error.options;
                  }
                });
              }

              // if type has changed to SearchView,
              // then add defaults for SearchView
              // Note for @Nazar:
              // > It can be however added for question on normalization step (I'm about newQuery)
              // > inside questionNormalizer if they should/could be there.
              // > Look for 'projectSettingsNormalizer' as an example.
              // > If they should be present only if type is SearchView,
              // > then it is another question. May be server can filter out unused fields,
              // > instead of doing that part on client-side.
              // > Another way, you can create special helper functions ('transitioners'),
              // > that would carry out the process of changing question type from one to another
              // > with side-effects happening to its data.
              // > Also you can look at merge from merge-anything,
              // > it could help you to update objects deeply.
              if (action.dataUpdate.type === TYPES.SearchView) {
                next.settings = {
                  labels: null,
                  dataSettings: {
                    query: newQuery
                  }
                };
              } else {
                if (!next.settings) {
                  next.settings = {
                    labels: null
                  };
                }

                if (action.dataUpdate.settings) {
                  next.settings = {
                    ...next.settings,
                    ...action.dataUpdate.settings
                  };
                }
              }

              if (
                (!q.optionsFrom && !!next.optionsFrom) ||
                q.optionsFrom !== next.optionsFrom
              ) {
                if (next.type === TYPES.Table) {
                  next.subQuestions = [];
                } else {
                  next.options = [];
                }
              }

              if (
                !next.optionsFrom &&
                [
                  TYPES.SingleAnswer,
                  TYPES.MultipleAnswer,
                  TYPES.Checklist
                ].includes(next.type)
              ) {
                if (!Array.isArray(next.options)) {
                  next.options = [];
                }

                if (next.options.length === 0) {
                  next.options = [
                    generateNewData({
                      title: '',
                      sortOrder: 1,
                      order: 1
                    })
                  ];
                }
              }

              return next;
            })
          });
        })
      };

    case 'project-groups/update-option':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          if (action.questionGroupId !== group.id) {
            return group;
          }
          return {
            ...group,
            questions: map(group.questions, q => {
              if (q.id !== action.questionId) return q;

              return {
                ...q,
                options: map(q.options, option => {
                  if (option.id !== action.optionId) return option;

                  return {
                    ...option,
                    ...action.dataUpdate,
                    ...(!option.cache && {
                      cache: option
                    })
                  };
                })
              };
            })
          };
        })
      };

    case 'project-groups/update-groups-order':
      return {
        ...state,
        data: action.groups
      };

    case 'project-groups/update-questions-order':
      return {
        ...state,
        data: map<IQuestionGroup>(state.data, group => {
          if (action.questionGroupId !== group.id) {
            return group;
          }
          return {
            ...group,
            questions: action.questions
          };
        })
      };

    case 'project-groups/select-question':
      return {
        ...state,
        selectedGroupId: null,
        selectedQuestionId: action.questionId,
        visibleSelected: 'question',
        previewEnabled: false
      };

    default:
      return state;
  }
};

export default projectGroupReducer;
