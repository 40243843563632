// @flow

import type { ProcessLogicResults } from '../../flow-types/actions/interview/ProcessLogicResults';
import type { IInterviewAnswer } from '../../flow-types/entities/InterviewAnswer';
import type { IFormattedLogic } from '../../flow-types/entities/Logic';

export default function processLogicResults(
  answers: IInterviewAnswer,
  rules: Array<IFormattedLogic>
): ProcessLogicResults {
  return {
    type: 'interview/process-logic-results',
    answers,
    rules
  };
}
