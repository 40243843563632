// @flow
// IS NOT AUTHENTICATED ROUTE GUARD
import type { GetState } from 'redux-first-router';
import { redirect } from 'redux-first-router';
import type { Bag } from 'redux-first-router/dist/flow-types';
import type { OnBeforeChangeFn } from 'flow-types/router/OnBeforeChangeFn';
import type { AppDispatch } from 'flow-types/AppDispatch';
import type { AuthState } from 'flow-types/states/AuthState';
import { authStateSelector } from '../../selectors';
import getToken from '../../utils/getToken';

const isUnknownGuard: OnBeforeChangeFn = (
  dispatch: AppDispatch,
  getState: GetState,
  bag: Bag
) => {
  const {
    action: {
      meta: {
        location: { prev }
      }
    }
  } = bag;
  const state = getState();
  const { isLoggedIn }: AuthState = authStateSelector(state);
  const token = getToken();
  if (!isLoggedIn && !token) return false;

  // we don't want to go back to post-polling page if it somehow redirected as to login page,
  // but we still have token
  if (
    prev &&
    prev.type &&
    !(prev.type === 'router/interviewsPage' && !!prev.query.hash)
  ) {
    dispatch(redirect({ ...prev }));
  } else {
    dispatch(redirect({ type: 'router/homePage' }));
  }
  return true;
};

export default isUnknownGuard;
