import { useIntl } from 'react-intl';
import { useMemo, useRef } from 'react';
import type {
  ITestSource,
  ITestSource$Option
} from 'common/containers/TestsForm/flow';
import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import reduce from 'lodash/reduce';
import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import filter from 'lodash/filter';
import type { IQuestion } from 'flow-types/entities/Question';
import {
  getQuestionTypeLocaleMessageId,
  SELECTABLE_TYPES,
  TYPES
} from 'common/helpers/question';
import map from 'lodash/map';
import find from 'lodash/find';
import type { IQuestionOption } from 'flow-types/entities/QuestionOption';
import values from 'lodash/values';
import ResponseReviewStagesSelector from './ReviewStagesSelector';
import ProjectExpertsSelector from './ProjectExpertsSelector';
import ProjectDepartmentsSelector from '../../ProjectDepartmentsSelector';
import ProjectManagersSelector from './ProjectManagersSelector';
import { useProjectQuestions } from '../../../hooks/useProjectQuestions';
import { useProjectGroups } from '../../../hooks/useProjectGroups';
import ResponseMeetingStagesSelector from './MeetingStagesSelector';

const allowOnlyEqualOperator = values(LOGIC_OPERATOR).filter(
  operator => operator !== LOGIC_OPERATOR.EQUAL
);

// TODO: source validation check

export const DEFAULT_EXCLUDED_BLOCKS = [
  // we cannot use table question as a source as soon as it is a group of questions
  TYPES.Table,
  TYPES.HTML,
  TYPES.URL,
  TYPES.Video,
  TYPES.Redirect
];

export function useSources({
  isPlain = false,
  sourceId = null,
  /**
   * Whether to include response properties into sources data or not.
   */
  includeProperties = true,
  excludedBlockTypes = DEFAULT_EXCLUDED_BLOCKS
} = {}) {
  const intl = useIntl();

  const groups = useProjectGroups();

  const params = useRef({ flatten: true });

  const questions = useProjectQuestions(params.current);

  const sources = useMemo(() => {
    // TODO: define externally to make it more common thing across the app
    const responseSources: ITestSource[] = [
      {
        id: 'date_created',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.dateCreated'
        }),
        isDate: true,
        excludedCheckOperators: [LOGIC_OPERATOR.NOT_EMPTY, LOGIC_OPERATOR.EMPTY]
      },
      {
        id: 'post_polling_start',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.postPollingStart'
        }),
        isDate: true,
        excludedCheckOperators: [LOGIC_OPERATOR.NOT_EMPTY, LOGIC_OPERATOR.EMPTY]
      },
      {
        id: 'post_polling_end',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.postPollingEnd'
        }),
        isDate: true,
        excludedCheckOperators: [LOGIC_OPERATOR.NOT_EMPTY, LOGIC_OPERATOR.EMPTY]
      },
      {
        id: 'interviewer_id',
        title: intl.formatMessage({ id: 'projects.tabs.responses.filter.who' }),
        isSelectable: true,
        CustomInputComponent: ProjectManagersSelector,
        props: {
          fetchOnMount: true
        },
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        hideUnionOperator: true,
        targetField: 'value'
      },
      {
        id: 'department_id',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.department'
        }),
        isSelectable: true,
        CustomInputComponent: ProjectDepartmentsSelector,
        props: {
          fetchOnMount: true,
          fetchOnSearch: true,
          fetchOnFilterUpdate: true
        },
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        hideUnionOperator: true,
        targetField: 'value'
      },
      {
        id: 'expert_id',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.expert'
        }),
        isSelectable: true,
        CustomInputComponent: ProjectExpertsSelector,
        props: {
          fetchOnMount: true
        },
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        hideUnionOperator: true,
        targetField: 'value'
      },
      {
        id: 'review_stage_id',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.status'
        }),
        isSelectable: true,
        props: {
          fetchOnMount: true
        },
        CustomInputComponent: ResponseReviewStagesSelector,
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        defaultUnionOperator: LOGIC_OPERAND.AND,
        hideUnionOperator: true,
        targetField: 'value'
      },
      {
        id: 'meeting_stage_id',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.status'
        }),
        CustomInputComponent: ResponseMeetingStagesSelector,
        isSelectable: true,
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        defaultUnionOperator: LOGIC_OPERAND.AND,
        hideUnionOperator: true,
        targetField: 'value'
      },
      {
        id: 'main_finished',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.mainFinished'
        }),
        isSelectable: true,
        options: [
          {
            id: 'true',
            title: intl.formatMessage({ id: 'common.labels.yes' })
          },
          {
            id: 'false',
            title: intl.formatMessage({ id: 'common.labels.no' })
          }
        ],
        targetField: 'value',
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        excludedUnionOperators: [LOGIC_OPERAND.OR],
        defaultUnionOperator: LOGIC_OPERAND.AND,
        disableCheckOperator: true,
        disableUnionOperator: true,
        hideUnionOperator: true
      },
      {
        id: 'post_polling_finished',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.postPollingFinished'
        }),
        isSelectable: true,
        options: [
          {
            id: 'true',
            title: intl.formatMessage({ id: 'common.labels.yes' })
          },
          {
            id: 'false',
            title: intl.formatMessage({ id: 'common.labels.no' })
          }
        ],
        targetField: 'value',
        excludedCheckOperators: allowOnlyEqualOperator,
        defaultCheckOperator: LOGIC_OPERATOR.EQUAL,
        defaultUnionOperator: LOGIC_OPERAND.AND,
        disableCheckOperator: true,
        disableUnionOperator: true,
        hideUnionOperator: true
      },
      {
        id: 'updated_at',
        title: intl.formatMessage({
          id: 'projects.tabs.responses.filter.updatedAt'
        }),
        isDate: true,
        isTime: true,
        targetField: 'value',
        excludedCheckOperators: [LOGIC_OPERATOR.NOT_EMPTY, LOGIC_OPERATOR.EMPTY]
      }
    ];

    // TODO: define externally to make it more common thing across the app
    const RESPONSES_SOURCES = {
      id: 'response-sources',
      title: intl.formatMessage({ id: 'common.labels.record' }, { count: 1 }),
      group: true,
      options: responseSources
    };

    let initials = [];

    if (includeProperties) {
      initials = !isPlain
        ? [RESPONSES_SOURCES]
        : [...RESPONSES_SOURCES.options];
    }

    return reduce(
      groups,
      (result, group) => {
        const { title, questions: groupQuestions, id: groupId } = group;

        let questionsSources = flatQuestionsSubQuestions(groupQuestions);

        questionsSources = filter(
          questionsSources,
          (q: IQuestion) => !excludedBlockTypes.includes(q.type)
        );

        // if group has not question sources,
        // then return result,
        // there is no need to display such group in dropdown
        if (questionsSources.length === 0) return result;

        questionsSources = map(questionsSources, (q: IQuestion) => {
          const questionTypeLocaleId = getQuestionTypeLocaleMessageId(q.type);

          const prefix = intl.formatMessage(
            {
              id: 'common.labels.subQuestions'
            },
            { multi: false }
          );

          const typeStr = intl.formatMessage({
            id: questionTypeLocaleId
          });

          const isDate = [TYPES.DateTime].includes(q.type);

          const isTime = isDate && !q.dateOnly;

          const isNumber = [TYPES.Numeric, TYPES.Rating].includes(q.type);

          const isSelectable = [...SELECTABLE_TYPES, TYPES.Ranging].includes(
            q.type
          );

          let isMulti = [
            TYPES.MultipleAnswer,
            TYPES.Checklist,
            TYPES.Ranging
          ].includes(q.type);

          if ((isDate || isNumber) && q.isInterval) {
            isMulti = true;
          }

          const isString = [
            TYPES.TextBlock,
            TYPES.Email,
            TYPES.MobilePhone
          ].includes(q.type);

          const questionSource: $Shape<ITestSource> = {
            id: `${q.id}`,
            title: q.title,
            isSelectable,
            isMulti,
            isDate,
            isTime,
            isNumber,
            isString,
            extra: [typeStr],
            actionValidationRules: {
              minValue: q.minValue,
              maxValue: q.maxValue,
              minAnswers: q.minAnswers,
              maxAnswers: q.maxAnswers,
              scale: q.scale,
              required: q.required,
              otherTitle: q.otherTitle,
              type: q.type,
              isInterval: (isNumber || isDate) && isMulti,
              dateOnly: q.dateOnly
            }
          };

          if (q.parentId) {
            const parentQuestion: IQuestion | typeof undefined = find(
              questions,
              {
                id: q.parentId
              }
            );
            if (parentQuestion) {
              questionSource.subTitle = `${prefix}: ${questionSource.title}`;
              questionSource.title = parentQuestion.title;
            }
          }

          if (questionSource.isSelectable) {
            questionSource.excludedCheckOperators = [LOGIC_OPERATOR.EQUAL];
            questionSource.options = map(
              q.options,
              (option: IQuestionOption): ITestSource$Option => ({
                id: `${option.id}`,
                title: option.title
              })
            );
          }

          return questionSource;
        });

        if (isPlain) {
          return [...result, ...questionsSources];
        }

        return [
          ...result,
          {
            id: groupId,
            title,
            group: true,
            options: questionsSources
          }
        ];
      },
      initials
    );
    // TODO: groups and questions here will make this memo to often re-run...
    //  may be it would be better to somehow use state with equal check of some required properties
    //  to re-run sources construction
  }, [excludedBlockTypes, groups, includeProperties, intl, isPlain, questions]);

  if (isPlain && sourceId) {
    return sources.find(source => source.id === sourceId);
  }

  return sources;
}
