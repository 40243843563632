// @flow

/* eslint-disable jsx-a11y/label-has-associated-control,react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import cx from 'classnames';

import isDevelopment from 'utils/isDevelopment';

import type { AbstractComponent, Node } from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';
import type { ReactComponent } from 'flow-types/ReactComponent';

import stopEventLifting from '../helpers/stopEventLifting';

export type Props = {
  // TODO: implement that via value property
  // eslint-disable-next-line react/require-default-props
  indeteraminate?: boolean,

  name?: null | string,

  // A checkbox can be checked/unchecked
  value: boolean,
  // is called on each 'checked' value change
  onChange: Function,
  // WARN: it is more preferred to use label instead of children
  children?: ReactChildren,
  // will return event instead of 'checked' property value
  +rawOutput?: boolean,
  // allows to pass extra class names to checkbox 'div' component
  +className?: string,
  // A checkbox can be read-only and unable to change states
  +disabled?: ?boolean,
  // A checkbox can be read-only and unable to change states
  +readOnly?: boolean,
  // A checkbox can be formatted to show an on or off choice
  +toggle?: boolean,
  // A checkbox can be formatted as a radio element. This means it is an exclusive option.
  +radio?: boolean,
  // A checkbox can be formatted to emphasize the current selection state
  +slider?: boolean,
  // A fitted checkbox does not leave padding for a label
  +fitted?: boolean,
  // return object with new value inside,
  // that is assigned to property equal to name prop
  +useNameAsValueKey?: boolean,
  +labelClassName?: ?string,
  // WARN: it is more preferred to use label instead of children
  +label?: null | string,
  +ContainerComponent?: ReactComponent,
  +LabelComponent?: ReactComponent,
  +fragment?: boolean
};

const Checkbox: AbstractComponent<Props> = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      children,
      rawOutput,
      className,
      toggle,
      radio,
      slider,
      fitted,
      disabled,
      readOnly,
      labelClassName,
      useNameAsValueKey,
      name,
      ContainerComponent: Component = 'div',
      LabelComponent = 'label',
      fragment,
      ...rest
    }: Props,
    ref
  ) => {
    const inputRef = useRef(null);

    const classNames = cx(
      'ui',
      { toggle, slider, radio, fitted, 'read-only': readOnly },
      className,
      'checkbox'
    );

    const handleLabelClick = useCallback(e => {
      if (e) stopEventLifting(e);
      if (!inputRef.current) return;
      inputRef.current.click();
    }, []);

    const handleChange = useCallback(
      e => {
        if (!onChange || readOnly) return;

        if (rawOutput) {
          onChange(e);
          return;
        }

        const {
          target: { checked }
        } = e;

        if (useNameAsValueKey && name) {
          onChange({
            [name]: checked
          });
        } else {
          onChange(checked);
        }
      },
      [name, onChange, rawOutput, readOnly, useNameAsValueKey]
    );

    if (fragment) {
      return (
        <>
          <input
            {...rest}
            name={name}
            disabled={!!disabled}
            type={radio ? 'radio' : 'checkbox'}
            checked={value}
            onChange={handleChange}
            className={radio ? 'radio' : 'checkbox'}
            ref={mergeRefs([inputRef, ref])}
          />
          <LabelComponent className={labelClassName} onClick={handleLabelClick}>
            {/* $FlowIgnore */}
            {label || children}
          </LabelComponent>
        </>
      );
    }

    return (
      <Component className={classNames}>
        <input
          {...rest}
          name={name}
          disabled={!!disabled}
          type={radio ? 'radio' : 'checkbox'}
          checked={value}
          onChange={handleChange}
          className={radio ? 'radio' : 'checkbox'}
          ref={inputRef}
        />
        <LabelComponent className={labelClassName} onClick={handleLabelClick}>
          {/* $FlowIgnore */}
          {label || children}
        </LabelComponent>
      </Component>
    );
  }
);

Checkbox.displayName = 'React.memo(Checkbox)';

// $FlowIgnore
Checkbox.defaultProps = {
  label: null,
  toggle: false,
  disabled: false,
  rawOutput: false,
  readOnly: false,
  radio: false,
  slider: false,
  fitted: false,
  useNameAsValueKey: false,
  labelClassName: '',
  className: '',
  children: null,
  name: null,
  ContainerComponent: 'div',
  LabelComponent: 'label',
  fragment: false
};

export function Radio(props: $Shape<Props>): Node {
  return <Checkbox {...props} radio />;
}

if (isDevelopment) {
  // $FlowIgnore
  Checkbox.whyDidYouRender = false;
}

export default Checkbox;
