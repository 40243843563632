import revertDictionary from 'utils/revertDictionary';

const usersDictionary = {
  'users.actions.remove.message': {
    en: 'Are you definitely want to delete user?',
    ru: 'Вы действительно хотите удалить этого пользователя?'
  },
  'users.list.noCompanySelectedPlaceholder': {
    en: 'No company is selected',
    ru: 'Выберите компанию'
  },
  'users.list.noUsersForDepartmentPlaceholder': {
    en: 'No one user is assigned to selected department',
    ru: 'К выбранному отделу не прикреплён ни один пользователь'
  },
  'users.list.noUsersForCompanyPlaceholder': {
    en: 'No one user is assigned to selected company',
    ru: 'К выбранной компании не прикреплён ни один пользователь'
  }
};

export default revertDictionary(usersDictionary);
