import React from 'react';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import reduce from 'lodash/reduce';
import { toast } from 'react-toastify';
import { of } from 'rxjs';
import type { ResetPassword } from '../../flow-types/actions/auth/ResetPassword';
import request from '../../utils/request';
import { API } from '../../utils/config';
import decamelizeKeys from '../../common/helpers/decamelizeKeys';

const passwordResetEpic = $action =>
  $action.pipe(
    ofType('auth/reset-password'),
    RxO.mergeMap(({ credentials }: ResetPassword) =>
      request({
        url: API.password.change,
        method: 'POST',
        body: decamelizeKeys(credentials)
      }).pipe(
        RxO.map(() => ({ type: 'auth/change-view', view: 'login' })),
        RxO.catchError(({ response }) => {
          if (response.data) {
            const messages = reduce(
              response.data,
              (_messages, fieldMessages) => [
                ..._messages,
                ...(Array.isArray(fieldMessages)
                  ? fieldMessages
                  : [fieldMessages])
              ],
              []
            );
            toast.error(
              <>
                {messages.map((msg, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`msg-${index}`}>{msg}</div>
                ))}
              </>,
              { autoClose: 2500 }
            );
          }
          return of({
            type: 'auth/reset-password-fail'
          });
        })
      )
    )
  );

export default passwordResetEpic;
