// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { of } from 'rxjs';
import { decamelizeKeys } from 'humps';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import type {
  DisableTestElement,
  DisableTestElementSuccess
} from 'flow-types/actions/projects/detail/responses/DisableChecklistItem';

const disableChecklistForResponse = $action =>
  $action.pipe(
    ofType('project-responses/disable-test-element'),
    RxO.delay(1500),
    RxO.mergeMap((action: DisableTestElement): DisableTestElementSuccess => {
      const { testElementId, responseId } = action;

      return request({
        url: interpolateString(
          API.responses.disableTestElement,
          decamelizeKeys({ testElementId, responseId }),
          ':'
        ),
        method: 'POST'
      }).pipe(
        RxO.map(() => ({
          type: 'project-responses/disable-test-element-success',
          testElementId,
          responseId
        })),
        RxO.catchError(() =>
          of({
            type: 'project-responses/disable-test-element-fail',
            testElementId,
            responseId
          })
        )
      );
    })
  );

export default disableChecklistForResponse;
