import revertDictionary from '../../utils/revertDictionary';

// TODO: add possibility to customize tests form messages
const userFilterSettingsForm = {
  'userFilterSettings.actions.createFilter': {
    en: 'Create filter',
    ru: 'Создать фильтр'
  },
  'userFilterSettings.actions.createFiltersGroup': {
    en: 'Create filters group',
    ru: 'Создать группу фильтров'
  },
  'userFilterSettings.actions.manageFiltersGroup': {
    en: 'Manage filters group',
    ru: 'Управлять группами фильтров'
  },
  'userFilterSettingsForm.actions.confirmRemove': {
    en: 'Do you want to remove that filter?',
    ru: 'Вы хотите удалить этот фильтр?'
  },
  'userFilterSettings.form.tabs.settings': { en: 'Settings', ru: 'Настройки' },
  'userFilterSettings.form.tabs.templates': { en: 'Templates', ru: 'Шаблоны' },
  'userFilterSettings.form.fields.responsePattern.label': {
    en: 'Response Pattern',
    ru: 'Шаблон записи'
  },
  'userFilterSettings.form.fields.notificationPattern.label': {
    en: 'Notification Pattern',
    ru: 'Шаблон уведомления'
  },
  'userFilterSettings.form.fields.notificationPattern.templateCodes.title': {
    en: 'Filter settings title',
    ru: 'Название фильтра настроек'
  },
  'userFilterSettings.form.fields.notificationPattern.templateCodes.responses': {
    en: 'Messages according to a response pattern',
    ru: 'Сообщения по записям согласно заданному шаблону записи'
  },
  'userFilterSettings.form.fields.title.placeholder': {
    ru: 'Название',
    en: 'Title'
  },
  'userFilterSettings.form.fields.userFiltersSettingsGroupId.placeholder': {
    ru: 'Группа фильтров',
    en: 'Filters group'
  },
  'userFilterSettings.form.fields.receiveNotifications.label': {
    ru: 'Уведомить при изменении числа соответствующих записей',
    en: 'Notify when matched responses amount changes'
  },
  'userFilterSettings.list.isLoading': {
    ru: 'Загружаем пользовательские фильтры',
    en: 'Loading user filters'
  },
  'userFilterSettings.list.isEmpty': {
    ru: 'Список пользовательских фильтров пуст',
    en: 'User filter list is empty'
  },
  'userFilterSettings.form.validation.title.isRequired': {
    en: 'Title is required',
    ru: 'Нужно задать заголовок'
  },
  'userFilterSettings.form.validation.tests.isRequired': {
    en: 'Tests are required',
    ru: 'Нужно добавить проверки'
  },
  'userFilterSettings.form.validation.title.min': {
    en: 'Title have more than {count} char(-s)',
    ru: 'Заголовок должен иметь более, чем {count} символов'
  }
};

export default revertDictionary(userFilterSettingsForm);
