import some from 'lodash/some';

// TODO: replace with selectors selection
export default function mixGroupWithQuestionsRelatedFlags(group) {
  const isVisibleForMain = some(
    group.questions,
    question => question.isVisibleForMain
  );

  const isVisibleForPost = some(
    group.questions,
    question => question.isVisibleForPost
  );

  const isVisibleForEdit = some(
    group.questions,
    question => question.isVisibleForEdit
  );

  const isVisibleForPublic = some(
    group.questions,
    question => question.isVisibleForPublic
  );

  const isVisibleForMobile = some(
    group.questions,
    question => question.isVisibleForMobile
  );

  return {
    ...group,
    isVisibleForMobile,
    isVisibleForPublic,
    isVisibleForMain,
    isVisibleForEdit,
    isVisibleForPost
  };
}
