// @flow

import { combineReducers } from 'redux';
import type { ProjectDetailState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectDetailAction } from 'flow-types/actions/projects/detail';
import type { Reducer } from 'flow-types/Reducer';
import project from './project';
import responses from './responses';
import reports from './reports';
import groups from './groups';
import logic from './logic';
import stats from './responseStats';
import enablingTestElements from './enablingTestElements';
import disablingTestElements from './disablingTestElements';
import response from './response';
import visibilitySettings from './visibilitySettings';
import filtersSettings from './filtersSettings';
import accessSettings from './accessSettings';
import searchSettings from './searchSettings';
import pivotTables from './pivotTables';
import pollingNotifications from './pollingNotifications';
import exportReducer from './export';
import computedProperties from './computedProperties';
import massActionsTasks from './massActionsTasks';
import massActionsList from './massActionsList';

const projectDetailReducer: Reducer<
  ProjectDetailState,
  ProjectDetailAction
> = combineReducers({
  project,
  responses,
  response,
  groups,
  reports,
  logic,
  stats,
  enablingTestElements,
  disablingTestElements,
  visibilitySettings,
  filtersSettings,
  accessSettings,
  searchSettings,
  export: exportReducer,
  pivotTables,
  pollingNotifications,
  computedProperties,
  massActionsTasks,
  massActionsList
});

export default projectDetailReducer;
