import { array, string, object } from 'yup';
import find from 'lodash/find';
import map from 'lodash/map';

import defaultsDeep from 'common/helpers/defaults';
import nullToArray from 'common/helpers/nullToArray';

import type { ITest, ITestSource } from 'common/containers/TestsForm/flow';
import configureTestValidator, {
  initialConfig
} from 'common/validators/validateTest';

import type { ValidatorConfig as TestValidatorConfig } from 'common/validators/validateTest';

const initials = {
  ...initialConfig,
  testsIsRequired: {
    messageId: 'userFilterSettings.form.validation.tests.isRequired'
  }
};

type ValidatorConfig = TestValidatorConfig & {
  testsIsRequired: mixed,
  sources: ITestSource[]
};

const mixTestsWithSources = sources => value => {
  if (!sources || !Array.isArray(sources) || sources.length === 0) {
    return value;
  }

  return map(value, (test: ITest) => {
    if (typeof test === 'string') return test;

    const { source } = test;

    const sourceData =
      find(
        sources,
        src =>
          // eslint-disable-next-line eqeqeq
          src.id == source
      ) ?? null;

    return {
      ...test,
      sourceData
    };
  });
};

export function createTestsValidator(params: ValidatorConfig) {
  const config: ValidatorConfig = defaultsDeep(params, initials);

  const isRequired = config?.isRequired ?? true;

  const schema = array(configureTestValidator(config))
    .transform(mixTestsWithSources(config.sources))
    .transform(nullToArray);

  if (!isRequired) {
    return schema;
  }

  return schema.required(config.testsIsRequired);
}

export function configureValidator(params) {
  return object({
    title: string().required({
      messageId: 'userFilterSettings.form.validation.title.isRequired'
    }),
    tests: createTestsValidator(params)
  });
}
