import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import type { SelectRecord } from 'flow-types/actions/records/SelectRecord';
import { camelizeAndNormalizeDecodingTasks } from 'common/transducers/uploads/decodingTasksNormalizer';
import type { FetchDecodingTasks } from 'flow-types/actions/records/decodings/tasks/FetchDecodingTasks';
import { SELECT_RECORD } from '../../../reducers/records/recordsList';
import {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS
} from '../../../reducers/records/decodings/tasks';

const fetchTasks$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_TASKS),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([action]: [FetchDecodingTasks, State]) => {
      const { uploadId } = action;

      return request({
        url: interpolateString(API.uploads.decodings.tasks.list, { uploadId }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        // add catchError case
        RxO.map(data => ({
          type: FETCH_TASKS_SUCCESS,
          uploadId,
          data: camelizeAndNormalizeDecodingTasks(data)
        }))
      );
    })
  );

export const fetchTasksOnRecordSelection$: Epic = action$ =>
  action$.pipe(
    ofType(SELECT_RECORD),
    RxO.map((action: SelectRecord): FetchDecodingTasks => ({
      type: FETCH_TASKS,
      uploadId: action.recordId
    }))
  );

export default fetchTasks$;
