// @flow
import React from 'react';

import type { IInterviewQuestion } from 'flow-types/entities/Question';
import MultiNumeric from 'common/components/Question/NumericQuestionBody/Multi';
import SingleNumeric from 'common/components/Question/NumericQuestionBody/Single';
import type { OnAnswerInput } from 'common/components/Question/flow';

type Props = {
  question: IInterviewQuestion,
  onAnswerInput: OnAnswerInput
};

export default function NumericQuestionBody({
  question,
  onAnswerInput,
  ...rest
}: Props) {
  if (question.isInterval) {
    return (
      <MultiNumeric
        {...rest}
        question={question}
        onAnswerInput={onAnswerInput}
      />
    );
  }

  return <SingleNumeric question={question} {...rest} />;
}
