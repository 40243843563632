import keyBy from 'lodash/keyBy';

import type { VisibilitySettingsState } from 'flow-types/states/ProjectsState/detail';
import type { VisibilitySettingsAction } from 'flow-types/actions/projects/detail/visibilitySettings';
import type { SaveVisibilitySettingsSuccess } from 'flow-types/actions/projects/detail/visibilitySettings/SaveVisibilitySettings';
import type { FetchVisibilitySettingsListSuccess } from 'flow-types/actions/projects/detail/visibilitySettings/FetchVisibilitySettingsList';

import type { OpenModal } from '../../pages/Modals/modals';

const createInitialState = (): VisibilitySettingsState => ({
  data: {},
  isLoading: false,
  isMutating: false
});

const handleFetchVisibilitySettingsListSuccess = (
  state: VisibilitySettingsState,
  action: FetchVisibilitySettingsListSuccess
) => {
  const next = { ...state };

  next.data = keyBy(action.visibilitySettings, 'id');

  next.isLoading = false;

  return next;
};

const handleSaveVisibilitySettingsSuccess = (
  state: VisibilitySettingsState,
  action: SaveVisibilitySettingsSuccess
) => {
  const next = { ...state };

  next.data[action.settingsId] = { ...action.visibilitySettings };
  next.isMutating = false;

  return next;
};

export default function visibilitySettings(
  state: VisibilitySettingsState = createInitialState(),
  action: VisibilitySettingsAction | OpenModal
): VisibilitySettingsState {
  switch (action.type) {
    case 'project/reset':
      return createInitialState();

    case 'project/fetch-visibility-settings-list':
      return {
        ...state,
        isLoading: true
      };

    case 'project/fetch-visibility-settings-list-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project/fetch-visibility-settings-list-success':
      return handleFetchVisibilitySettingsListSuccess(state, action);

    case 'project/save-visibility-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/save-visibility-settings-fail':
      return {
        ...state,
        isMutating: false
      };

    case 'project/save-visibility-settings-success':
      return handleSaveVisibilitySettingsSuccess(state, action);

    default:
      return state;
  }
}
