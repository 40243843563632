const headersDictionary = {
  en: {
    'common.headers.companies': 'Companies',
    'common.headers.logic': 'Logic',
    'common.headers.departments': 'Departments',
    'common.headers.users': 'Users',
    'common.headers.projects': 'Projects',
    'common.headers.project': 'Project',
    'common.headers.reports': 'Reports',
    'common.headers.checklists': 'Checklists',
    'common.headers.testGroups': 'Test Groups',
    'common.headers.comparators': 'Test Elements',
    'common.headers.main': 'Main',
    'common.headers.settings': 'Settings',
    'common.headers.responses': 'Responses',
    'common.headers.response': 'Response #{what}',
    'common.headers.appearance': 'Appearance',
    'common.headers.interview.construction': 'Constructor'
  },
  ru: {
    'common.headers.companies': 'Компании',
    'common.headers.logic': 'Логика',
    'common.headers.departments': 'Отделы',
    'common.headers.users': 'Пользователи',
    'common.headers.projects': 'Проекты',
    'common.headers.project': 'Проект',
    'common.headers.reports': 'Отчёты',
    'common.headers.checklists': 'Чеклисты',
    'common.headers.testGroups': 'Тест группы',
    'common.headers.comparators': 'Тест элементы',
    'common.headers.main': 'Главная',
    'common.headers.settings': 'Настройки',
    'common.headers.responses': 'Записи',
    'common.headers.response': 'Запись #{what}',
    'common.headers.appearance': 'Кастомизация',
    'common.headers.interview.construction': 'Конструктор'
  }
};

export default headersDictionary;
