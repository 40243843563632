import omit from 'lodash/omit';

import type { ChecklistsAction } from 'flow-types/actions/checks/checklists';
import type { ChecklistsState } from 'flow-types/states/ChecksState';

import updateFilter from '../../common/helpers/updateFilter';

const initialState: ChecklistsState = {
  data: null,
  pagination: {},
  filter: {
    sort: '-id'
  },
  loading: false,
  error: null,
  form: {
    visible: false,
    data: null,
    error: null,
    loading: false
  }
};

export default (
  state: ChecklistsState = initialState,
  action: ChecklistsAction
) => {
  switch (action.type) {
    case 'checklists/edit':
      return {
        ...state,
        form: {
          ...state.form,
          data: action.checklist,
          cache: action.checklist,
          visible: true
        }
      };
    case 'checklists/select':
      return {
        ...state,
        selected: action.checklistId
      };
    case 'checklists/create':
      return {
        ...state,
        form: {
          ...state.form,
          visible: true,
          data: {
            ...action.initialData
          }
        }
      };

    case 'checklists/update':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          },
          ...(state.form.error &&
            typeof state.form.error === 'object' && {
              error: omit(state.form.error, Object.keys(action.dataUpdate))
            })
        }
      };

    case 'checklists/save':
      return {
        ...state,
        form: {
          ...state.form,
          loading: true
        }
      };

    case 'checklists/save-fail':
      return {
        ...state,
        form: {
          ...state.form,
          error: action.error,
          loading: false
        }
      };

    case 'checklists/save-success':
    case 'checklists/reset-form':
      return {
        ...state,
        form: initialState.form
      };

    case 'checklists/update-state':
      return {
        ...state,
        ...action.stateUpdate
      };

    case 'checklists/fetch':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        }),
        loading: true
      };

    case 'checklists/fetch-fail':
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case 'checklists/fetch-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        error: null,
        loading: false
      };

    case 'checklists/remove':
      return {
        ...state,
        loading: true
      };

    case 'checklists/remove-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'checklists/remove-success':
      return {
        ...state,
        error: null,
        loading: false
      };

    default:
      return state;
  }
};
