// @flow

import React from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';
import fpMap from 'lodash/fp/map';
import fpGet from 'lodash/fp/get';
import arrayMove from 'array-move';
import cx from 'classnames';

import DraggableList from 'common/containers/DraggableList';
import { Field } from 'common/components/Form';
import ListComponent from 'common/components/List';
import ListItem from 'common/components/ListItem';
import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';
import type { IInterviewQuestion } from 'flow-types/entities/Question';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { DragEndResponse } from 'flow-types/DragEndResponse';
import Header from 'common/components/Header';
import Flexbox from 'common/components/Flexbox';
import { useMobileScreen } from 'common/components/Responsive/Mobile';
import type { Node } from 'react';
import type { IInterviewOption } from 'flow-types/entities/QuestionOption';
import type { Provided } from 'react-beautiful-dnd/src/view/droppable/droppable-types';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { StateSnapshot } from 'react-beautiful-dnd/src/view/draggable/draggable-types';
import orderBySequence from 'common/helpers/orderBySequence';
import BackgroundImage from 'common/components/Image/Background';
import Icon from 'common/components/Icon';

type RangingQuestionBodyProps = {
  question: IInterviewQuestion
};

const Item = ({
  data,
  index,
  showImage,
  showLabel
}: {
  data: IInterviewOption,
  index: number,
  showImage: boolean,
  showLabel: boolean
}): Node => (
  <Draggable draggableId={`option-${data.id}`} index={index}>
    {(provided, snapshot: StateSnapshot) => (
      // TODO: it would be better to display draggable item using portal,
      //  thus it will be safe to use inside modals and so on. However,
      //  fomantic-ui concept on list item styling is clear.
      //  Item outside of list tag is not styled as list item.
      //  It may be that we have to expand item styling inside list,
      //  that for example adding .list-item to
      //  item would be enough to preserver list styling.
      <ListItem
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={cx(snapshot.isDragging ? 'selected border-blue' : null, {
          vertical: showImage && showLabel
        })}
      >
        {showImage && data.image && (
          <BackgroundImage
            className={cx(
              'ui avatar rounded',
              {
                squared: (showImage && showLabel) || !showImage,
                centered: showImage && !showLabel,
                fluid: showImage && !showLabel
              },
              'image'
            )}
            height={showImage ? 150 : ''}
            image={data.image}
          />
        )}
        {((showImage && showLabel) || !showImage) && (
          <Flexbox className={showImage && data.image ? 'content' : ''}>
            <Flexbox ignoreDisplay grow={1}>
              <div className="ui grid">
                <div className="column">
                  <Icon icon="grip vertical" className="small blue" />
                </div>
                <div className="fourteen wide column">
                  <Header className="light" color="blue">
                    {data.title}
                  </Header>
                </div>
              </div>
            </Flexbox>
          </Flexbox>
        )}
      </ListItem>
    )}
  </Draggable>
);

type ListProps = {
  provided: Provided,
  data: IInterviewOption[],
  ItemComponent: ReactComponent,
  showImage: boolean,
  showLabel: boolean
};

const List = ({
  provided,
  data,
  ItemComponent,
  showImage,
  showLabel
}: ListProps): Node => {
  const config = React.useMemo(() => ({ only: true }), []);

  const isMobile = useMobileScreen(config);

  return (
    <ListComponent
      selection
      aligned="middle"
      ref={provided.innerRef}
      {...provided.droppableProps}
      className={cx(
        {
          'padded-items-sm': isMobile,
          'compact-items-sm': !isMobile
        },
        'md:max-w-2/3 xl:max-w-1/3 rounded-items-sm bordered-items border-inverted-primary padded-items relaxed-alt'
      )}
    >
      {Array.isArray(data) &&
        data.map((item, index) => (
          <ItemComponent
            data={item}
            key={item.id}
            index={index}
            showImage={showImage}
            showLabel={showLabel}
          />
        ))}
      {provided.placeholder}
    </ListComponent>
  );
};

const extractOrder = fpMap(fpGet('id'));

/**
 * Данный тип блока подразумевает рендер списка, который можно сортировать по принципу DnD.
 * Ответом является последовательность элементов.
 * @param question
 * @constructor
 */
export default function RangingQuestionBody({
  question
}: RangingQuestionBodyProps): Node {
  const beenChanged = React.useRef(false);

  const fieldRef = React.useRef(null);

  const { options, id, disabled } = question;

  const { data }: IInterviewAnswer = useAnswer(id);

  const update = useAnswerUpdate(id);

  const [items, set] = React.useState(() => orderBySequence(options, data));

  React.useEffect(() => {
    if (!beenChanged.current) return;

    update({ data: extractOrder(items) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  React.useEffect(() => {
    if (!isEqual(options, items)) {
      set(() => orderBySequence(options, data));

      beenChanged.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const reorder = React.useCallback((dragResponse: DragEndResponse) => {
    if (fieldRef.current) {
      fieldRef.current.dataset.swipeIgnore = null;
    }

    if (!dragResponse.source || !dragResponse.destination) return;

    if (dragResponse.source.index === dragResponse.destination.index) return;

    beenChanged.current = true;

    set(prev =>
      arrayMove(prev, dragResponse.source.index, dragResponse.destination.index)
    );
  }, []);

  const addDataSwipeIgnoreToFieldNode = React.useCallback(() => {
    if (fieldRef.current) {
      fieldRef.current.dataset.swipeIgnore = 'true';
    }
  }, []);

  return (
    <Field ref={fieldRef}>
      <DragDropContext
        onDragStart={addDataSwipeIgnoreToFieldNode}
        onDragEnd={reorder}
      >
        <DraggableList
          ItemComponent={Item}
          ListComponent={List}
          disabled={disabled}
          data={items}
          droppableId="options"
          showImage={question.showImages}
          showLabel={question.showLabels}
        />
      </DragDropContext>
    </Field>
  );
}
