// @flow

import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { AjaxError, EMPTY } from 'rxjs';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { API } from 'utils/config';
import request from 'utils/request';

import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';

import type { Epic } from 'flow-types/Epic';
import type {
  SaveFilterSettings,
  SaveFilterSettingsSuccess
} from 'flow-types/actions/projects/detail/filtersSettings/SaveFilterSettings';
import type { AppState } from 'flow-types/AppState';

import closeModal from '../../../../actions/modals/close';
import {
  projectIdFromPathSelector,
  projectResponsesStateSelector
} from '../../../../selectors/projects';

const saveFilterSettings: Epic = (action$, state$) =>
  action$.pipe<SaveFilterSettings>(
    ofType('project/save-filter-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([action, state]: [SaveFilterSettings, AppState]) => {
      const { filterSettings, modalId } = action;

      const projectId = projectIdFromPathSelector(state);

      const interpolationData = {
        projectId,
        ...(filterSettings.id && {
          settingsId: filterSettings.id
        })
      };

      const url = interpolateString(
        filterSettings.id
          ? API.projects.userFilterSettings.detail
          : API.projects.userFilterSettings.list,
        interpolationData
      );

      const method = filterSettings.id ? 'PUT' : 'POST';

      return request({
        url,
        method,
        body: decamelizeKeys(filterSettings)
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.mergeMap(rawFilterSettings =>
          [
            modalId && closeModal(modalId, true),
            {
              type: 'project/save-filter-settings-success',
              filterSettings: camelizeKeys(rawFilterSettings),
              originalId: filterSettings.id || null
            }
          ].filter(Boolean)
        ),
        RxOperators.catchError((ajaxError: AjaxError) => {
          const { response, message } = ajaxError;
          return [
            { type: 'project/save-filter-settings-fail', response, message }
          ];
        })
      );
    })
  );

export const reapplyUserFilterOnSaveSuccess: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/save-filter-settings-success'),
    RxOperators.filter(action => !!action.originalId),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(
      ([action, state]: [SaveFilterSettingsSuccess, AppState]) => {
        const { originalId } = action;
        const { filter } = projectResponsesStateSelector(state);

        if (filter.userFilterSettingId !== originalId) return EMPTY;

        return [{ type: 'project-responses/fetch' }];
      }
    )
  );

export default saveFilterSettings;
