// @flow

import * as React from 'react';
import cx from 'classnames';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { FomanticWide } from 'flow-types/FomanticWide';

export type MenuProps = {
  tagName?: ReactComponent,
  className?: string,
  nonUI?: boolean,
  wide?: FomanticWide | null,
  text?: boolean,
  right?: boolean,
  secondary?: boolean,
  pointing?: boolean,
  tabular?: boolean,
  compact?: boolean,
  inverted?: boolean,
  color?: string,
  pagination?: boolean,
  attached?: 'top' | 'bottom',
  vertical?: boolean,
  fluid?: boolean,
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge' | 'massive',
  borderless?: boolean
};

const Menu: React.AbstractComponent<MenuProps, any> = React.forwardRef(
  (
    {
      tagName: Component = 'div',
      className,
      wide,
      text,
      nonUI,
      right,
      secondary,
      pointing,
      tabular,
      attached,
      vertical,
      pagination,
      fluid,
      compact,
      borderless,
      size,
      color,
      inverted,
      ...props
    }: MenuProps,
    ref
  ) => (
    <Component
      {...props}
      className={cx(
        {
          ui: !nonUI,
          vertical
        },
        wide ? `${wide} item` : null,
        attached ? `${attached} attached` : null,
        {
          compact,
          fluid,
          text,
          right,
          secondary,
          pointing,
          tabular,
          pagination,
          borderless,
          inverted
        },
        color,
        className,
        size,
        'menu'
      )}
      ref={ref}
    />
  )
);

Menu.displayName = 'Menu.ForwardRef';

// $FlowIgnore
Menu.defaultProps = {
  tagName: 'div',
  wide: null,
  attached: null,
  vertical: false,
  fluid: false,
  className: null,
  nonUI: false,
  text: false,
  right: false,
  secondary: false,
  pointing: false,
  tabular: false,
  pagination: false,
  compact: false,
  borderless: false,
  size: '',
  color: '',
  inverted: false
};

export default Menu;
