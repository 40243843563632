const responseRegionsListDictionary = {
  en: {
    'response.regionsList.mode.all': 'all',
    'response.regionsList.mode.onlyChecked': 'checked only',
    'response.regionsList.mode.onlyDisabled': 'disabled only',
    'response.regionsList.mode.regions': 'regions',
    'response.regionsList.group.empty': 'No corresponding test elements'
  },
  ru: {
    'response.regionsList.mode.all': 'все',
    'response.regionsList.mode.onlyChecked': 'только проверенные',
    'response.regionsList.mode.onlyDisabled': 'только отключённые',
    'response.regionsList.mode.regions': 'регионы',
    'response.regionsList.group.empty':
      'В данной группе нет соответствующих тест-элементов'
  }
};

export default responseRegionsListDictionary;
