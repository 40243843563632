/* eslint-disable no-case-declarations */
// @flow
import type { CrmStateType } from './CrmState.type';
// TODO: should be CRM action type
import type { QueryActionType } from '../query/Query.action';

const initialState: CrmStateType = {
  loading: false,
  entities: [],
  views: {},
  variables: {},
  variableValues: [],
  searchBlocks: {}
};

export default (
  state: CrmStateType = initialState,
  // TODO: for some reason type checking doesn't work well here,
  // I can use actions which are not included to QueryActionType
  action: QueryActionType
) => {
  switch (action.type) {
    case 'crm/fetch-entities':
      return {
        ...state,
        loading: true
      };

    case 'crm/fetch-entities-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'crm/fetch-entities-success':
      return {
        ...state,
        loading: false,
        entities: [...action.data],
        error: null
      };

    case 'crm/view/add':
      return {
        ...state,
        views: {
          ...state.views,
          ...{
            [action.code]: {
              query: { ...action.query },
              queryResult: null,
              selectedOption: null,
              searchStr: ''
            }
          }
        }
      };
    case 'crm/view/set-query-filters':
      return {
        ...state,
        views: {
          ...state.views,
          ...{
            [action.code]: {
              ...state.views[action.code],
              filters: { ...action.filters },
              searchStr: action.filters[0].value
            }
          }
        }
      };

    case 'crm/view/set-query-result':
      return {
        ...state,
        views: {
          ...state.views,
          ...{
            [action.code]: {
              ...state.views[action.code],
              queryResult: { ...action.queryResult }
            }
          }
        }
      };

    case 'crm/view/save-selected-option':
      const filters = [...state.views[action.code].query.filters];
      if (filters.length > 0) filters[0].value = action.selectedOption.id;
      else
        filters.push({
          value: action.selectedOption.id,
          field: state.views[action.code].query.query[0],
          operator: 'EQ'
        });
      return {
        ...state,
        views: {
          ...state.views,
          ...{
            [action.code]: {
              ...state.views[action.code],
              selectedOption: { ...action.selectedOption },
              query: {
                ...state.views[action.code].query,
                filters: [...filters]
              },
              searchStr: action.selectedOption.id
            }
          }
        }
      };

    case 'interview/prepare-complete':
      return action.isNext
        ? { ...state, variableValues: action.variables }
        : state;

    case 'crm/fetch-variable-values-success':
      return {
        ...state,
        variableValues: action.payload
      };

    case 'crm/variables-value-changed':
      const { code, selectedOption } = action;
      const view = state.views[code];
      const { query } = view;
      const variables = {};
      query.query.forEach(queryId => {
        const [entity, field] = queryId.split('.');
        const variableFormula = `${entity}.${field}`;
        if (selectedOption[field])
          variables[variableFormula] = selectedOption[field];
      });

      return {
        ...state,
        variables: { ...state.variables, ...variables },
        searchBlocks: {
          ...state.searchBlocks,
          [code]: selectedOption
        }
      };

    case 'crm/tab-data-loaded-success':
      // TODO: normalize and store data entties separately
      return {
        ...state,
        views: {
          ...state.views,
          [action.meta.code]: {
            ...state.views[action.meta.code],
            [action.meta.tabIndex]: {
              // TODO: it looks to verbose, it's not clean why I might not have a view
              // also it makes sense to assign UUID for each tab
              ...((state.views[action.meta.code] &&
                state.views[action.meta.code][action.meta.tabIndex]) ||
                {}),
              ...action.payload
            }
          }
        }
      };

    case 'crm/tabs-preview-loaded-success':
      return {
        ...state,
        views: {
          ...state.views,
          [action.payload.code]: {
            ...state.views[action.payload.code],
            // TODO: the same issue as with crm/tab-data-loaded-success reducer
            [action.payload.tabIndex]: {
              ...(state.views[action.payload.code] &&
              state.views[action.payload.code][action.payload.tabIndex]
                ? state.views[action.payload.code][action.payload.tabIndex]
                : {}),
              total: action.payload.total
            }
          }
        }
      };

    default:
      return state;
  }
};
