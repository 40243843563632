import type { IRegionRate } from 'flow-types/entities/RecordRegion';

export const NO_AUDIO_TAG = -1;
export const NO_MARK_TAG = -2;
export const NO_MARK_AND_AUDIO_TAG = -3;
export const NO_MARK_WITH_AUDIO_TAG = -4;
export const NO_AUDIO_WITH_MARK_TAG = -5;

export const checkNoAudio = (region: IRegionRate) =>
  // eslint-disable-next-line eqeqeq
  (region.start == '-1' && region.end == '-1') ||
  (!region.start && !region.end);

export const checkNoMark = (region: IRegionRate) =>
  !region.checklistItemOptionId;

export const checkNoMarkAndAudio = (region: IRegionRate) =>
  checkNoMark(region) && checkNoAudio(region);

export const checkNoMarkWithAudio = (region: IRegionRate) =>
  checkNoMark(region) && !checkNoAudio(region);

export const checkNoAudioWithMark = (region: IRegionRate) =>
  !checkNoMark(region) && checkNoAudio(region);

export const checkForTag = (region: IRegionRate, tagId) =>
  region.checklistTagId === tagId;
