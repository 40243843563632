// @flow

import * as RxOpetators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { toast } from 'react-toastify';

import request from 'utils/request';
import { API } from 'utils/config';

import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import isNewElement from 'common/helpers/isNewElement';

import type { Epic } from 'flow-types/Epic';
import type { SaveVisibilitySettings } from 'flow-types/actions/projects/detail/visibilitySettings/SaveVisibilitySettings';
import type { LanguageState } from 'flow-types/states/LanguageState';

import localizeMessage from 'common/helpers/localizeMessage';
import withLatestSelectors from 'common/epicHelpers/withLatestSelectors';
import { normalizeVisibilitySettings } from 'common/transducers/projects/visibilitySettings/normalizer';
import { denormalizeVisibilitySettings } from 'common/transducers/projects/visibilitySettings/denormalizer';
import type { AppState } from 'flow-types/AppState';
import { MODAL_ID as VISIBILITY_SETTINGS_MODAL_ID } from '../components/Modals/VisibilitySettingsModal';
import { projectIdFromPathSelector } from '../../../selectors/projects';
import { languageStateSelector } from '../../../selectors';
import closeModal from '../../../actions/modals/close';

const saveVisibilitySettings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/save-visibility-settings'),
    RxOpetators.withLatestFrom(state$),
    withLatestSelectors([languageStateSelector]),
    RxOpetators.concatMap(
      ([action, state, language]: [
        SaveVisibilitySettings,
        AppState,
        LanguageState
      ]) => {
        const projectId = projectIdFromPathSelector(state);

        const { visibilitySettings } = action;

        const isNewSettings = isNewElement(visibilitySettings);

        const METHOD = isNewSettings ? 'POST' : 'PUT';

        const URL = isNewSettings
          ? API.userFieldsSettings.list
          : interpolateString(API.userFieldsSettings.detail, {
              settingsId: visibilitySettings.id
            });

        const saveVisibilitySettings$ = request({
          url: URL,
          method: METHOD,
          body: denormalizeVisibilitySettings({
            projectId: +projectId,
            ...visibilitySettings
          })
        });

        return saveVisibilitySettings$.pipe(
          responseParser,
          RxOpetators.pluck('data'),
          RxOpetators.mergeMap(rawVisibilitySettings => {
            const nextVisibilitySettings = normalizeVisibilitySettings(
              rawVisibilitySettings
            );

            return [
              closeModal(VISIBILITY_SETTINGS_MODAL_ID),
              {
                type: 'project/save-visibility-settings-success',
                settingsId: visibilitySettings.id,
                visibilitySettings: nextVisibilitySettings
              }
            ];
          }),
          RxOpetators.catchError(ajaxError => {
            let msg = null;

            try {
              msg = localizeMessage(
                `ajax.error.${
                  ajaxError.status >= 500 ? '500+' : ajaxError.status
                }`,
                language
              )();
            } catch (e) {
              msg = localizeMessage('ajax.error.NA', language)();
            }

            toast.error(msg, {
              autoClose: 4500,
              showCloseButton: true
            });

            return [
              {
                type: 'project/save-visibility-settings-fail',
                settingsId: visibilitySettings.id,
                error: ajaxError.response.message
              }
            ];
          })
        );
      }
    )
  );

export default saveVisibilitySettings;
