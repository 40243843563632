// @flow

export default function getBrowserLanguage(defaultLang: string = 'en'): string {
  if (!navigator) return defaultLang;

  // userLanguage is used in IE and is set via control panel, not browser
  // $FlowFixMe
  const userLang = navigator.language || navigator.userLanguage;

  if (!userLang) {
    return defaultLang;
  }

  return userLang.split('-')[0];
}
