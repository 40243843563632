// @flow
import map from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import sortByFp from 'lodash/fp/sortBy';
import * as h from 'humps';
import type { IChecklist } from 'flow-types/entities/Checklist';

export const checklistNormalizer = compose((checklist: IChecklist) => {
  const { company, department, tags, ...safeData } = checklist;

  return {
    ...safeData,
    company,
    department,
    companyId: company ? company.id : null,
    departmentId: department ? department.id : null,
    tags: tags || []
  };
}, h.camelizeKeys);

const checklistsNormalizer = compose(map(checklistNormalizer), sortByFp('id'));

export default checklistsNormalizer;
