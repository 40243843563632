import isNewElement from '../../../common/helpers/isNewElement';

const isNewRegion = region => {
  if (!region) return false;

  const { id } = region;
  return (
    isNewElement(region) ||
    (typeof id === 'string' && id.startsWith('wavesurfer_'))
  );
};

export default isNewRegion;
