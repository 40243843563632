// @flow

import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

import type { IQuestionId } from '../../../../../flow-types/entities/Question';
import type { IUpload } from '../../../../../flow-types/entities/Upload';
import type { IAnswer } from '../../../../../flow-types/entities/Answer';

type UploadLike = $Shape<IUpload>;
type AnswerLike = $Shape<IAnswer>;

export const RESPONSES_LIST_VISIBILITY_SETTINGS_ACTION = 'responsesList';

export const filterNonImages = (uploads: UploadLike[]): UploadLike[] =>
  filter(uploads, { isImage: true });

export const questionHasAttachments = (
  questionId: IQuestionId,
  uploads: UploadLike[]
): boolean %checks => findIndex(uploads, { questionId }) !== -1;

export const questionHasAnswer = (
  questionId: IQuestionId,
  answers: AnswerLike[]
): boolean %checks => findIndex(answers, { questionId }) !== -1;
