import { EMPTY, merge, of } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import filter from 'lodash/filter';

import { DATE_FNS_FORMAT } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import type { ChangeActiveStack } from 'flow-types/actions/interview/ChangeActiveStack';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { AppState } from 'flow-types/AppState';
import type { InterviewRegistryState } from 'flow-types/states/InterviewState/InterviewRegistryState';

import type { IRegistryRecord } from 'flow-types/entities/RegistryRecord';
import parseISO from 'date-fns/parseISO';
import getDuration from 'common/helpers/getDuration';
import { sortBy } from 'lodash';
import { interviewActiveStackDataSelector } from '../../selectors/interview/answers';
import { createInterviewAppearanceSelector } from '../../selectors/interview/statuses';
import { interviewRegistrySelector } from '../../selectors/interview/root';

export const MOVEMENT_RESTRICTED = 'interviewPage/movement-restricted';

const getMovementRestrictionRetryFlag = stackId =>
  `stack/${stackId}/movement-restriction-retry`;

const calculateViewFromStackViews = (registryRecords: IRegistryRecord[]) => {
  let lastStart = null;
  let total = 0;

  registryRecords.forEach(record => {
    if (record.enter) {
      lastStart = parseISO(record.enter);
    }

    if (record.exit && lastStart !== null) {
      const duration = getDuration(record.exit, lastStart);

      total += duration;

      lastStart = null;
    }
  });

  return total;
};

const applyMovementRestrictionsOnStackChangeEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/change-active-stack'),
    RxO.withLatestFrom(state$),
    RxO.filter(
      ([action, state]: [ChangeActiveStack, AppState]) =>
        !!action.stackId &&
        // in 'edit' and 'web' modes movement restrictions is not applied
        !['web', 'edit'].includes(createInterviewAppearanceSelector()(state))
    ),
    RxO.switchMap(([, state]) => {
      const stack: IInterviewStructureElement = interviewActiveStackDataSelector(
        state
      );

      const registry: InterviewRegistryState = interviewRegistrySelector(state);

      if (
        sessionStorage.getItem(
          getMovementRestrictionRetryFlag(stack.localId)
        ) === 'true'
      ) {
        return EMPTY;
      }

      const movementRestrictions = stack.restrictions?.movement;

      if (stack.isSinglePage || !movementRestrictions?.enabled) return EMPTY;

      const stackViews = sortBy(
        filter(registry.list, {
          questionId: stack.questionId
        }),
        rec => parseISO(rec.enter ?? rec.exit)
      );

      const viewDuration = calculateViewFromStackViews(stackViews);

      if (viewDuration >= movementRestrictions.duration) return EMPTY;

      return merge(
        of({
          type: 'process/start',
          processId: MOVEMENT_RESTRICTED,
          config: { duration: movementRestrictions.duration },
          timeEnd: format(
            addSeconds(new Date(), movementRestrictions.duration),
            DATE_FNS_FORMAT
          )
        }),
        // Пока переход лочится для страницы и это происходит через ЭПИК, один ЭПИК - одна страницы (стек).
        // В планах конечно есть идея перенести контроль над запретом перемещения внутрь какого-нибудь контекста и не делать для этого эпики.
        // Но суть та же, пока есть хотя бы один активный таймер, то страница залочена, пользователь видит оставшееся время,
        // которое ему нужно пробыть на странице. Определяется работает данный процесс или нет всё тем же состоянием `sideProcesses`.
        // Пока запрет срабатывает для страницы и только для страницы с одним выводимым блоком, то всё должно быть ОК,
        // но явно же будет мысль сделать ограничение и для страницы с множеством блоков...
        action$.pipe(
          ofType('process/complete'),
          RxO.filter(action => action.processId === MOVEMENT_RESTRICTED),
          RxO.take(1),
          RxO.mergeMap(() => {
            // указываем, что второй раз такого делать не нужно
            sessionStorage.setItem(
              getMovementRestrictionRetryFlag(stack.localId),
              'true'
            );

            return EMPTY;
          })
        )
      );
    })
  );

export default applyMovementRestrictionsOnStackChangeEpic;
