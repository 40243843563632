const departmentsDictionary = {
  en: {
    'departments.form.company': 'Company',
    'departments.form.company.placeholder': 'My favourite company',
    'departments.form.title': 'Title',
    'departments.form.title.placeholder': 'My new department',
    'departments.form.description': 'Description',
    'departments.form.description.placeholder': 'This is my new department',
    'departments.formTitle.create': 'Register department',
    'departments.formTitle.edit': 'Edit department',
    'departments.actions.remove.message':
      'Are you definitely want to delete department?',
    'departments.list.noDepartmentsForCompanyPlaceholder':
      'No departments are listed for this company.',
    'departments.list.noCompanySelectedPlaceholder': 'No company is selected',
    'departments.actions.addDepartment': 'Add department'
  },
  ru: {
    'departments.form.company': 'Компания',
    'departments.form.company.placeholder': 'Моя избранная компания',
    'departments.form.title': 'Название',
    'departments.form.title.placeholder': 'Мой новый отдел',
    'departments.form.description': 'Описание',
    'departments.form.description.placeholder': 'Это мой новый отдел',
    'departments.formTitle.create': 'Регистрация отдела',
    'departments.formTitle.edit': 'Обновление отдела',
    'departments.actions.remove.message':
      'Вы действительно хотите удалить этот отдел?',
    'departments.list.noDepartmentsForCompanyPlaceholder':
      'Отделов по выбранной компании не найдено',
    'departments.list.noCompanySelectedPlaceholder': 'Выберите компанию',
    'departments.actions.addDepartment': 'Добавить отдел'
  }
};

export default departmentsDictionary;
