import type { InterviewAction } from 'flow-types/actions/interview';
import type { InterviewLogicState } from 'flow-types/states/InterviewState/InterviewLogicState';
import { getLogicRulesSources } from 'common/transducers/logic/getLogicRulesSources';
import { logicRunner } from 'common/transducers/logic/logicRunner';
import { logicRulesResultsAnalyzer } from 'common/transducers/logic/logicResultsAnalyzer';

export const initialState: InterviewLogicState = {
  loading: false,
  data: [],
  sources: [],
  lastComputedResults: {}
};

// stores logic computation results,
// also stores sources and logic conditions data
export default (
  state: InterviewLogicState = initialState,
  action: InterviewAction
) => {
  switch (action.type) {
    case 'interview/reset':
      return {
        ...state,
        lastComputedResults: {}
      };
    case 'interview/set-logic-results':
      return {
        ...state,
        lastComputedResults: { ...action.results }
      };

    case 'interview/process-logic-results':
      return {
        ...state,
        lastComputedResults: logicRulesResultsAnalyzer(
          logicRunner(state.data, {
            answers: action.answers
          })
        )
      };

    case 'interview/prepare-complete':
      return action.isNext
        ? {
            ...state,
            data: action.logic,
            sources: getLogicRulesSources(action.logic),
            lastComputedResults: action.initialLogicResults,
            loading: false
          }
        : state;

    default:
      return state;
  }
};
