// @flow
import reduce from 'lodash/reduce';
import split from 'lodash/split';
import findLastIndex from 'lodash/findLastIndex';
import findIndex from 'lodash/findIndex';
import noop from 'lodash/noop';
import drop from 'lodash/drop';
import dropRight from 'lodash/dropRight';
import filter from 'lodash/filter';
import reduceRight from 'lodash/reduceRight';
import pullAt from 'lodash/pullAt';
import map from 'lodash/map';
import min from 'lodash/min';
import every from 'lodash/every';
import { isTestsGroupingOperator } from './isTestsGroupingOperator';
import { getTestsGroupOperatorPriority } from './getTestsGroupingOperatorPriority';
import type { IFormattedLogicItem } from '../../../flow-types/entities/Logic';

// looks for opening and closing brackets in each test
// and places them in flat list to identify groups
// and to replace them by arrays after all
export const locateLogicBrackets = tests =>
  reduce(
    tests,
    (result, test: IFormattedLogicItem) => {
      if (isTestsGroupingOperator(test)) return [...result, test];

      const openingBrackets = split(test.openingBrackets, '');

      const closingBrackets = split(test.closingBrackets, '');

      return [...result, ...openingBrackets, test, ...closingBrackets];
    },
    []
  );

// it will find all '(' and ')' pairs and replace them by arrays,
// representing groups
export const replaceBracketsWithArray = tests => {
  let updatedTests = [...tests];
  // if we can find any closing and opening bracket,
  // then we should process them at first

  // find first closing operator
  const firstClosingOperator = findIndex(updatedTests, t => t === ')');

  // find last opening operator, that is staying before closing operator
  const lastOpeningOperator = findLastIndex(
    updatedTests,
    t => t === '(',
    firstClosingOperator !== -1 ? firstClosingOperator : noop()
  );

  // if first closing operator does not have a pair,
  // then remove it and retry replacement
  if (firstClosingOperator !== -1 && lastOpeningOperator === -1) {
    pullAt(updatedTests, [firstClosingOperator]);
    return replaceBracketsWithArray(updatedTests);
  }

  if (lastOpeningOperator === -1 || firstClosingOperator === -1) {
    return filter(updatedTests, t => t !== ')' && t !== '(');
  }

  // if last opening operator stays before first test
  // and first closing operator stays after last test
  // then drop them
  if (
    lastOpeningOperator === 0 &&
    firstClosingOperator + 1 === updatedTests.length
  ) {
    return replaceBracketsWithArray(drop(dropRight(updatedTests, 1), 1));
  }

  const stack = {
    indexes: [lastOpeningOperator, firstClosingOperator],
    items: updatedTests.slice(lastOpeningOperator + 1, firstClosingOperator)
  };

  const itemsBeforeStack =
    stack.indexes[0] > 0 ? updatedTests.slice(0, stack.indexes[0]) : [];
  const itemsAfterStack = updatedTests.slice(stack.indexes[1] + 1);

  updatedTests = [...itemsBeforeStack, stack.items, ...itemsAfterStack];

  return replaceBracketsWithArray(updatedTests);
};

// it formats tests so that each operator will group tests around according to operators priority
// it will not change already existing groups,
// if they're formatted non-ambiguously (i.e. no different operators between tests in array)
export const logicStructureFormatter = tests => {
  if (!Array.isArray(tests) || tests.length === 0) return [];

  let updatedArray = tests.map(item =>
    Array.isArray(item) ? logicStructureFormatter(item) : item
  );

  const operators = filter(updatedArray, isTestsGroupingOperator);

  const operatorsPrioritiesMap = map(operators, getTestsGroupOperatorPriority);

  const lowestPriorityRate = min(operatorsPrioritiesMap);

  const isAllOperatorsEqual = every(
    operators,
    operator => operator === operators[0]
  );

  const isAllOperatorsPriorityEqual = every(
    operators,
    operator =>
      getTestsGroupOperatorPriority(operator) ===
      getTestsGroupOperatorPriority(operators[0])
  );

  const latestLowestPriorRateOperatorIndex = findLastIndex(
    updatedArray,
    item =>
      isTestsGroupingOperator(item) &&
      getTestsGroupOperatorPriority(item) === lowestPriorityRate
  );

  const firstLowestPriorRateOperatorIndex = findIndex(
    updatedArray,
    item =>
      isTestsGroupingOperator(item) &&
      getTestsGroupOperatorPriority(item) === lowestPriorityRate
  );

  const executeInChain = !isAllOperatorsEqual && isAllOperatorsPriorityEqual;

  if (executeInChain) {
    const sliceFromOrigin = updatedArray.slice(
      firstLowestPriorRateOperatorIndex - 1,
      firstLowestPriorRateOperatorIndex + 2
    );
    updatedArray.splice(
      firstLowestPriorRateOperatorIndex - 1,
      firstLowestPriorRateOperatorIndex + 2,
      sliceFromOrigin
    );
    return logicStructureFormatter(updatedArray);
  }

  if (isAllOperatorsEqual && isAllOperatorsPriorityEqual) return updatedArray;

  let stackStartIndex = null;
  let stackEndIndex = null;

  const stacks = reduce(
    updatedArray,
    (result, test, index) => {
      if (isTestsGroupingOperator(test)) {
        if (getTestsGroupOperatorPriority(test) === lowestPriorityRate) {
          if (stackStartIndex === null) {
            stackStartIndex = index - 1;
          }
          if (latestLowestPriorRateOperatorIndex === index) {
            stackEndIndex = index + 2;
          }
        } else if (stackStartIndex !== null) {
          stackEndIndex = index;
        }

        if (stackStartIndex !== null && stackEndIndex !== null) {
          const updated = [
            ...result,
            {
              items: logicStructureFormatter(
                tests.slice(stackStartIndex, stackEndIndex)
              ),
              indexes: [stackStartIndex, stackEndIndex - 1]
            }
          ];
          stackStartIndex = null;
          stackEndIndex = null;
          return updated;
        }
      }

      return result;
    },
    []
  );

  updatedArray = reduceRight(
    stacks,
    (result, stack) => {
      const updated = [...result];
      const itemsBeforeStack =
        stack.indexes[0] > 0 ? updated.slice(0, stack.indexes[0]) : [];
      const itemsAfterStack = updated.slice(stack.indexes[1] + 1);

      return [...itemsBeforeStack, stack.items, ...itemsAfterStack];
    },
    [...updatedArray]
  );

  return logicStructureFormatter(updatedArray);
};

// it works in reverse for processBrackets
export const replaceArrayWithBrackets = tests =>
  reduce(
    tests,
    (result, item) => {
      if (!Array.isArray(item)) {
        return [...result, item];
      }

      const processedList = replaceArrayWithBrackets(item);

      return [...result, '(', ...processedList, ')'];
    },
    []
  );

export const encapsulateBracketsIntoTests = tests => {
  let updated = [...tests];

  let bracketsStackCounter = 0;

  updated = reduce(
    updated,
    (result, current) => {
      if (current === '(') {
        bracketsStackCounter += 1;
        return result;
      }

      if (isTestsGroupingOperator(current) || current === ')') {
        // console.log('found out operator');
        return [...result, current];
      }

      const item = {
        ...current,
        openingBrackets: '('.repeat(bracketsStackCounter)
      };

      bracketsStackCounter = 0;

      return [...result, item];
    },
    []
  );

  // reset counter for any reason
  bracketsStackCounter = 0;

  updated = reduceRight(
    updated,
    (result, current) => {
      if (current === ')') {
        bracketsStackCounter += 1;
        return result;
      }

      if (isTestsGroupingOperator(current) || current === '(') {
        return [current, ...result];
      }

      const item = {
        ...current,
        closingBrackets: ')'.repeat(bracketsStackCounter)
      };

      bracketsStackCounter = 0;

      return [item, ...result];
    },
    []
  );

  return updated;
};
