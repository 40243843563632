import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { AuthState } from 'flow-types/states/AuthState';
import { of } from 'rxjs';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import type { SignInSuccess } from 'flow-types/actions/auth/SignIn';
import { authStateSelector } from '../../selectors';

export const signInWithHashEpic = ($action, $state) =>
  $action.pipe(
    ofType('auth/sign-in-with-hash'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([{ hash, scope }, state]) => {
      const { isLoggedIn }: AuthState = authStateSelector(state);

      if (!hash) return of({ type: 'auth/sign-in-fail' });

      return request({
        url: API.hashLogin,
        method: 'POST',
        body: {
          hash
        }
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map(({ accessToken, ...rest }): SignInSuccess => ({
          type: 'auth/sign-in-success',
          scope,
          accessToken,
          rememberMe: false,
          meta: rest,
          initialLoggedInState: isLoggedIn
        })),
        RxO.catchError(() => of({ type: 'router/404' }))
      );
    })
  );
