// @flow

import * as React from 'react';
import ModernOption from 'common/components/Question/Options/Option/ModernOption';
import ClassicOption from 'common/components/Question/Options/Option/ClassicOption';

const THEMED = {
  classic: ClassicOption,
  modern: ModernOption
};

type Props = {
  theme: 'classic' | 'modern'
};

const Option = ({ theme, ...props }: Props): React.Node => {
  const Component = THEMED[theme];

  return <Component {...props} />;
};

export default Option;
