import revertDictionary from 'utils/revertDictionary';

const levelDictionary = {
  'common.levels.veryLow': {
    en: 'Very low',
    ru: 'Очень низкий'
  },
  'common.levels.low': {
    en: 'Low',
    ru: 'Низкий'
  },
  'common.levels.medium': {
    en: 'Medium',
    ru: 'Средний'
  },
  'common.levels.high': {
    en: 'High',
    ru: 'Высокий'
  },
  'common.levels.veryHigh': {
    en: 'Very high',
    ru: 'Очень высокий'
  },
  'common.levels.system': {
    en: 'System',
    ru: 'Системный'
  }
};

export default revertDictionary(levelDictionary);
