import * as RxO from 'rxjs/operators';
import { camelizeKeys } from 'humps';
import { EMPTY } from 'rxjs';
import type { Action } from 'flow-types/Action';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import { projectFinalDataSelector } from '../../../selectors/projects';

const projectLastUpdateDateFetcher = ($action, $state) =>
  $action.pipe(
    RxO.filter((action: Action) => {
      const { type } = action;

      return [
        'project-groups/save-group-success',
        'project-groups/save-question-success',
        'project-groups/remove-group-success',
        'project-groups/remove-question-success',
        'project-groups/update-order-success',
        'project-groups/update-questions-order-success',
        'project-groups/update-options-order-success'
      ].includes(type);
    }),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([, state]) => {
      const project = projectFinalDataSelector(state);

      return request({
        url: interpolateString(
          API.projects.lastUpdateDate,
          {
            project_id: project.id
          },
          ':'
        ),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map(camelizeKeys),
        RxO.map(lastUpdateDate => ({
          type: 'project/update-cache',
          dataUpdate: {
            updatedAt: lastUpdateDate.updatedAt
          }
        })),
        RxO.catchError(() => EMPTY)
      );
    })
  );

export default projectLastUpdateDateFetcher;
