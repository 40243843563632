import { createSelector } from 'reselect';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { combineEpics, ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';

import { API } from 'utils/config';
import request from 'utils/request';

import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import normalizePagination from 'common/transducers/paginationNormalizer';
import debounceEpic from 'common/epicHelpers/debounceEpic';

import type { Epic } from 'flow-types/Epic';
import type { FetchExportTasksSuccess } from 'flow-types/actions/projects/detail/export/FetchExportTasks';
import type { ProjectExportTasksState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';

import { toast } from 'react-toastify';
import { IntlMessageFormat } from 'intl-messageformat';
import {
  projectExportTasks,
  projectIdFromPathSelector
} from '../../../../selectors/projects';
import ajaxErrorsDictionary from '../../../../intl/ajaxErrorsDictionary';
import { languageStateSelector } from '../../../../selectors';

const selector: Function = createSelector(
  projectExportTasks,
  (tasksState: ProjectExportTasksState) => {
    const { filter } = tasksState;

    return filter;
  }
);

const fetchExportTasksEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/fetch-tasks'),
    RxOperators.withLatestFrom(state$),
    debounceEpic(),
    RxOperators.switchMap(([, state]) => {
      const locale = languageStateSelector(state);

      const projectId = projectIdFromPathSelector(state);

      const filter = selector(state);

      return request({
        url: interpolateString(API.projects.exportTasks.list, { projectId }),
        query: decamelizeKeys(filter),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.map(({ data, pagination }): FetchExportTasksSuccess => ({
          type: 'project-export/fetch-tasks-success',
          data: camelizeKeys(data),
          pagination: normalizePagination(pagination)
        })),
        RxOperators.catchError(err => {
          const errorStatus = err.status > 500 ? '500+' : err.status;

          toast.error(
            new IntlMessageFormat(
              ajaxErrorsDictionary[locale][`ajax.error.${errorStatus}`],
              locale
            ).format(),
            {
              autoClose: 2500
            }
          );

          return [{ type: 'project-export/fetch-tasks-fail' }];
        })
      );
    })
  );

const refetchOnCreateTaskSuccess: Epic = action$ =>
  action$.pipe(
    ofType('project-export/create-task-success'),
    RxOperators.map((): FetchExportTasksSuccess => ({
      type: 'project-export/fetch-tasks'
    }))
  );

export default combineEpics(refetchOnCreateTaskSuccess, fetchExportTasksEpic);
