// @flow

import isDevelopment from 'utils/isDevelopment';

if (
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
) {
  // eslint-disable-next-line global-require
  require('intersection-observer');
}

/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { toast } from 'react-toastify';
import { registerPlugin } from 'filepond';

import './semantic/dist/semantic.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './assets/styles.css';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginFileTypeValidation from 'filepond-plugin-file-validate-type';
import reportWebVitals from './reportWebVitals';
import FilePondPluginGetFile from './assets/filepond-plugin-get-file-patched/filepond-plugin-get-file';

import App from './App';
import store from './store';
import { isLogRocketEnabled, initLogRocket } from './services/logrocket';
/* eslint-enable import/first */

const root = document.getElementById('root');

if (!root) {
  throw new Error("couldn't find element with id root");
}

registerLocale('ru', ru);
registerLocale('en', en);

toast.configure();

registerPlugin(
  FilePondPluginImageExifOrientation,
  // TODO: maybe it is possible to use one of below two plugins
  FilePondPluginFilePoster,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginFileTypeValidation,
  FilePondPluginGetFile
);

if (isDevelopment) {
  // eslint-disable-next-line
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  root
);

if (isLogRocketEnabled) {
  initLogRocket();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
