import { createSelector } from 'reselect';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';

import request from 'utils/request';
import { API } from 'utils/config';

import { camelizeAndNormalizeExportSettingsList } from 'common/transducers/projects/exportSettingsNormalizer';

import type { ProjectExportSettingsListState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type {
  FetchExportSettingsListFail,
  FetchExportSettingsListSuccess
} from 'flow-types/actions/projects/detail/export/FetchExportSettingsList';
import type { Epic } from 'flow-types/Epic';

import { AjaxError } from 'rxjs/ajax';
import {
  projectExportSettingsList,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const selector = createSelector(
  projectExportSettingsList,
  (settingsList: ProjectExportSettingsListState) => settingsList.filter
);

const fetchSettingsListEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/fetch-settings-list'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([, state]) => {
      const projectId = projectIdFromPathSelector(state);

      const filter = selector(state);

      return request({
        url: interpolateString(API.projects.exportSettings.list, { projectId }),
        query: filter,
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map((data): FetchExportSettingsListSuccess => ({
          type: 'project-export/fetch-settings-list-success',
          data: camelizeAndNormalizeExportSettingsList(data)
        })),
        RxOperators.catchError(({ response, message }: AjaxError): [
          FetchExportSettingsListFail
        ] => [
          {
            type: 'project-export/fetch-settings-list-fail',
            response,
            message
          }
        ])
      );
    })
  );

export default fetchSettingsListEpic;
