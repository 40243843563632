import revertDictionary from '../../utils/revertDictionary';

const questionGroupFormDictionary = {
  'questionGroupForm.title.label': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'questionGroupForm.fields.showTitleOnTop.label': {
    en: "Display group title on page's top",
    ru: 'Показывать заголовок группы вверху страницы'
  },
  'questionGroupForm.fields.showDescriptionOnTop.label': {
    en: "Show group description on page's top",
    ru: 'Показывать описание группы вверху страницы'
  },
  'questionGroupForm.shortTitle.label': {
    en: 'Short title',
    ru: 'Краткий заголовок'
  },
  'questionGroupForm.description.label': {
    en: 'Description',
    ru: 'Описание'
  },
  'questionGroupForm.singlePage.label': {
    en: 'Display all questions on the same page',
    ru: 'Отображать все вопросы на одной странице'
  },
  'questionGroupForm.isStatusGroup.label': {
    en: 'Status group',
    ru: 'Статус-группа'
  },
  'questionGroupForm.forPostPollingOnly.label': {
    en: 'Display only in post-polling',
    ru: 'Отображать только в пост-опросе'
  },
  'questionGroupForm.forNewMeetingsOnly.label': {
    en: 'For new meetings only',
    ru: 'Только для новых встреч'
  },
  'questionGroupForm.logo.label': {
    en: 'Logo',
    ru: 'Логотип'
  },
  'questionGroupForm.logo.placeholder': {
    en: 'Drag or Drop image file here',
    ru: 'Выберите или перенесите файл в эту область'
  },
  'questionGroupForm.css.label': {
    en: 'CSS',
    ru: 'CSS'
  }
};

export default revertDictionary(questionGroupFormDictionary);
