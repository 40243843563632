import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { decamelizeKeys } from 'humps';
import { EMPTY } from 'rxjs';
import { toast } from 'react-toastify';
import request from 'utils/request';
import type { Epic } from 'flow-types/Epic';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import camelizeMap from 'common/epicHelpers/camelizeMap';
import { responseIdFromPathSelector } from '../../../selectors/projects/response';

const fetchResponseReviewStage: Epic = (action$, state$) =>
  action$.pipe(
    // TODO: if regions will be used on several pages,
    //  check that this epic will not be executed if page is not project and
    //  active panel is not ResponsePanel
    ofType('records-regions/save-success'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([, state]) => {
      const responseId = responseIdFromPathSelector(state);

      return request({
        url: interpolateString(
          API.responses.reviewStage,
          decamelizeKeys({ responseId })
        ),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        camelizeMap,
        RxO.map((data: {| id: number, reviewStageId: number |}) => ({
          type: 'project-response/update-review-stage',
          reviewStageId: data && data.reviewStageId
        })),
        RxO.catchError(() => {
          toast.error('Error happened!', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return EMPTY;
        })
      );
    })
  );

export default fetchResponseReviewStage;
