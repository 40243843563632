// @flow

import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { decamelizeKeys } from 'humps';
import { createSelector } from 'reselect';

import { API } from 'utils/config';
import request from 'utils/request';

import toClient from 'common/transducers/projects/computedProperties/toClient';

import type { Epic } from 'flow-types/Epic';
import type { IProject } from 'flow-types/entities/Project';
import type {
  FetchComputedProperties,
  FetchComputedPropertiesSuccess
} from 'flow-types/actions/projects/detail/computedProperties/FetchComputedProperties';
import type { FetchProjectSuccess } from 'flow-types/actions/projects/detail/project/FetchProject';
import type { IComputedProperty } from 'flow-types/entities/ComputedProperty';

import responseParser from 'common/epicHelpers/responseParser';
import debounceEpic from 'common/epicHelpers/debounceEpic';

import { projectDetailStateSelector } from '../../../../selectors';
import {
  projectFinalDataSelector,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const filterSelector = createSelector(
  projectDetailStateSelector,
  detail => detail.computedProperties.filter
);

const fetchComputedProperties: Epic = (action$, state$) =>
  action$.pipe<FetchComputedProperties | FetchProjectSuccess>(
    ofType('project/fetch-computed-properties', 'project/fetch-success'),
    debounceEpic(300),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([action, state]) => {
      const projectId = projectIdFromPathSelector(state);

      if (projectId === 'new') {
        return [
          {
            type: 'project/fetch-computed-properties-success',
            data: []
          }
        ];
      }

      if (action.type === 'project/fetch-computed-properties') {
        const filter = filterSelector(state);

        return request({
          url: API.projects.variables.list,
          query: decamelizeKeys({ ...filter, projectId }),
          method: 'GET'
        }).pipe(
          responseParser,
          RxO.pluck('data'),
          RxO.map(toClient),
          RxO.map(
            (data: IComputedProperty[]): FetchComputedPropertiesSuccess => ({
              type: 'project/fetch-computed-properties-success',
              data
            })
          )
        );
      }

      const project: IProject = projectFinalDataSelector(state);

      return [
        {
          type: 'project/fetch-computed-properties-success',
          data: project.variables
        }
      ];
    })
  );

export default fetchComputedProperties;
