/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import debounce from 'lodash/debounce';
import Select from 'react-select';
import { languageStateSelector } from '../../selectors';
import { crmStateSelector } from '../../selectors/crm';
import type { SearchBlocksMap } from '../../flow-types/crm';

const StyledMinChars = styled.p`
  margin-top: 10px;
  color: red;
`;

type SearchViewBodyPropType = {|
  // the settings property of the question object from the Univey system is used to store the view settings
  question: Object,
  searchBlocks: SearchBlocksMap,
  views: Object, // a hashmap of active views in the CRM storage
  // TODO: check if react-int has needed types
  intl: {
    formatMessage: ({ id: string }) => string
  }
|};

function SearchViewBody(props: SearchViewBodyPropType) {
  // the view of the CRM system is based on the question implementation of the Univey system
  // the question object contains a settings field that in turn is a subobject
  // the configuration of a view is stored in the settings subobject
  const [charsLeft, setCharsLeft] = useState(null);

  const { question: viewSettings } = props;
  const { settings: viewState } = viewSettings;
  const { variableId } = viewState.dataSettings;
  const { views, intl, searchBlocks } = props;
  const { code } = viewSettings;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'crm/fetch-entities'
    });

    dispatch({
      type: 'crm/search-blocks-displayed',
      payload: { variableId, code, query: viewState.dataSettings.query }
    });

    if (!views[code]) {
      dispatch({
        type: 'crm/view/add',
        code: viewSettings.code,
        query: { ...viewState.dataSettings.query }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentView: Object;

  if (code in views && !!views[code]) {
    currentView = views[code];
  }

  if (!currentView) {
    return null;
  }

  const handleSearchString = searchStr => {
    if (!currentView) return;
    const { query } = currentView;

    const left = query.minChars - searchStr.length;

    if (left > 0) {
      setCharsLeft(left);
      return;
    }

    setCharsLeft(null);

    dispatch({
      type: 'crm/view/update-query-filters',
      code,
      filters: [
        {
          field: `${query.entity.tableName}.${query.searchBy}`,
          // TODO: remove the hard-coded operator later
          operator: 'EQ',
          value: searchStr
        }
      ]
    });
  };

  const debouncedSearchString = debounce(handleSearchString, 500);

  const setSelectedOption = selectedOption => {
    dispatch({
      type: 'crm/view/set-selected-option',
      code,
      selectedOption
    });
  };

  return (
    <div className="ui container fluid" style={{ height: '200px' }}>
      <form className="ui form">
        <Select
          value={searchBlocks[code]}
          onChange={setSelectedOption}
          // TODO: react select has its own way to work with async options
          onInputChange={debouncedSearchString}
          placeholder=""
          options={currentView.queryResult ? currentView.queryResult.items : []}
          getOptionValue={option => option.id}
          noOptionsMessage={() =>
            intl.formatMessage({
              id: 'searchView.nothingFound'
            })
          }
          getOptionLabel={option =>
            currentView.query.outputFields
              .map(field => field.split('.')[1])
              .map(property => option[property])
              .join(' ')
          }
        />
      </form>
      {charsLeft && (
        <StyledMinChars>
          <FormattedMessage
            placeholder="Search..."
            values={{ charsLeft }}
            id="queryBrowser.charsLeft"
          />
        </StyledMinChars>
      )}
    </div>
  );
}

const selector: Function = createSelector(
  crmStateSelector,
  languageStateSelector,
  (crmState, language) => ({ ...crmState, language })
);

export default injectIntl(connect(selector)(SearchViewBody));
