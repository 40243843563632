import { ofType, combineEpics } from 'redux-observable';
import { of, concat, EMPTY } from 'rxjs';
import * as Operators from 'rxjs/operators';
import qs from 'qs';
import { AjaxError } from 'rxjs/ajax';
import { decamelizeKeys } from 'humps';

import type { Epic } from 'flow-types/Epic';

import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import listResponseParser from 'common/epicHelpers/listResponseParser';

import debounceEpic from 'common/epicHelpers/debounceEpic';

const addMeeting: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/post-meeting'),
    Operators.withLatestFrom($state),
    Operators.mergeMap(([{ meeting }, { meetings: { filter } }]) =>
      request({
        url: `${API.meetings.add}`,
        method: 'POST',
        body: decamelizeKeys(meeting)
      }).pipe(
        Operators.mergeMap(() => [
          { type: 'meetings/post-meeting-success' },
          { type: 'meetings/count-meetings', filter },
          { type: 'meetings/fetch-meetings', filter }
        ]),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/post-meeting-fail',
            error: response ? response.error : message
          })
        )
      )
    )
  );

const editMeeting: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/put-meeting'),
    Operators.withLatestFrom($state),
    Operators.mergeMap(([{ meeting, id }, { meetings: { filter } }]) =>
      request({
        url: `${API.meetings.add}/${id}`,
        method: 'PUT',
        body: decamelizeKeys(meeting)
      }).pipe(
        Operators.mergeMap(() => [
          { type: 'meetings/count-meetings', filter },
          { type: 'meetings/fetch-meetings', filter },
          { type: 'meetings/put-meeting-success' }
        ]),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/put-meeting-fail',
            error: response ? response.error : message
          })
        )
      )
    )
  );

export const fetchMeetings: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/fetch-meetings'),
    Operators.withLatestFrom($state),
    debounceEpic(),
    Operators.switchMap(epicData => {
      const [action, state] = epicData;

      const { filter } = action;

      const resultFilter = {
        ...filter,
        ...state.meetings.filter,
        ...state.meetings.formFilter
      };

      const urlParams = qs.stringify(decamelizeKeys(resultFilter), {
        skipNulls: true,
        addQueryPrefix: true
      });

      return request({
        url: `${API.meetings.list}${urlParams}`,
        method: 'GET'
      }).pipe(
        Operators.map(({ response: { data } }) => ({
          type: 'meetings/fetch-meetings-success',
          data
        })),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/fetch-meetings-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

const fetchFilters: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/fetch-filters'),
    Operators.withLatestFrom($state),
    Operators.switchMap(epicData => {
      const [action, state] = epicData;
      const { filter } = action;
      const urlParams = qs.stringify(
        {
          ...filter,
          ...state.meetings.formFilter
        },
        {
          skipNulls: true,
          addQueryPrefix: true
        }
      );
      return request({
        url: `${API.meetings.filters}${urlParams}`,
        method: 'GET'
      }).pipe(
        responseParser, // extracts response.response
        listResponseParser, // extracts data and pagination from above
        Operators.map(response => {
          const { data } = response;
          return {
            type: 'meetings/fetch-filters-success',
            data
          };
        }),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/fetch-filters-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

const updateFilter: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/update-filter'),
    Operators.withLatestFrom($state),
    Operators.switchMap(epicData => {
      const [action] = epicData;
      const { name, value } = action;
      return concat(
        name === 'company'
          ? of({ type: 'meetings/select-company', id: value })
          : EMPTY,
        name === 'department'
          ? of({ type: 'meetings/select-department', id: value })
          : EMPTY,
        name === 'manager'
          ? of({ type: 'meetings/select-manager', id: value })
          : EMPTY,
        name === 'project'
          ? of({ type: 'meetings/select-project', id: value })
          : EMPTY,
        name === 'source'
          ? of({ type: 'meetings/select-source', id: value })
          : EMPTY,
        name === 'status'
          ? of({ type: 'meetings/select-status', id: value })
          : EMPTY,
        name === 'timeInterval'
          ? of({ type: 'meetings/set-filter', filter: value })
          : EMPTY,
        name === 'startFrom'
          ? of({ type: 'meetings/set-filter', filter: { start_from: value } })
          : EMPTY,
        name === 'startTo'
          ? of({ type: 'meetings/set-filter', filter: { start_to: value } })
          : EMPTY,
        name === 'respondentPhone'
          ? of({ type: 'meetings/select-respondent-phone', value })
          : EMPTY,
        of({
          type: 'meetings/count-meetings'
          // filter: { ...state.meetings.filter }
        })
      );
    })
  );

export const countMeetings: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/count-meetings'),
    Operators.withLatestFrom($state),
    Operators.switchMap(epicData => {
      const [action, state] = epicData;

      const { filter } = action;

      const resultFilter = {
        ...filter,
        ...state.meetings.filter,
        ...state.meetings.formFilter
      };

      const urlParams = qs.stringify(decamelizeKeys(resultFilter), {
        skipNulls: true,
        addQueryPrefix: true
      });

      return request({
        url: `${API.meetings.count}${urlParams}`,
        method: 'GET'
      }).pipe(
        responseParser, // extracts response.response
        listResponseParser, // extracts data and pagination from above
        Operators.map(response => {
          const { data } = response;
          return {
            type: 'meetings/count-meetings-success',
            data
            // pagination
          };
        }),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/count-meetings-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

const fetchRespondents: Epic = ($action, $state) =>
  $action.pipe(
    ofType('meetings/fetch-respondents'),
    Operators.withLatestFrom($state),
    Operators.switchMap(epicData => {
      const [action] = epicData;
      const phoneFilter = {
        phone: action.phone
      };

      // respondents are stored in a single table along with managers and administrators
      // respondents are identified by role ids 6 and 7
      const defaultParams = '?role_ids[]=6&role_ids[]=7';

      const urlParams = qs.stringify(phoneFilter, {
        skipNulls: true
      });

      return request({
        url: `${API.users.list}${defaultParams}${urlParams}`,
        method: 'GET'
      }).pipe(
        responseParser, // extracts response.response
        listResponseParser, // extracts data and pagination from above
        Operators.map(response => {
          const { data } = response;
          return {
            type: 'meetings/fetch-respondents-success',
            data
          };
        }),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'meetings/fetch-respondents-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

export default combineEpics(
  addMeeting,
  fetchMeetings,
  fetchFilters,
  updateFilter,
  countMeetings,
  fetchRespondents,
  editMeeting
);
