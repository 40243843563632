// This utility function can help you temporarily log something,
// even pushing it on remote repository to test on 'close to production' environment.
//
// Webpack will automatically remove code inside if-else block if it is a dead-code.
//
// As soon as production is built with NODE_ENV equal to 'production',
// all statements inside if-else checking only for isDevelopments
// will be removed from code
const isDevelopment = process.env.NODE_ENV === 'development';

export default isDevelopment;
