import { of } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import qs from 'qs';
import { AjaxRequest } from 'rxjs/ajax';
import { camelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import request from '../../utils/request';
import decamelizeKeys from '../../common/helpers/decamelizeKeys';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import listResponseParser from '../../common/epicHelpers/listResponseParser';
import isNewElement from '../../common/helpers/isNewElement';
import checklistsNormalizer, {
  checklistNormalizer
} from '../../common/transducers/checks/checklists/normalizers';
import { checklistDenormalizer } from '../../common/transducers/checks/checklists/denormalizers';
import { checksStateSelector } from '../../selectors';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

const fetchChecklistsEpic = ($action, $state) =>
  $action.pipe(
    ofType('checklists/fetch'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([, state]) => {
      const {
        lists: { filter }
      } = checksStateSelector(state);

      return {
        filter
      };
    }),
    debounceEpic(),
    RxOperators.mergeMap(({ filter }) => {
      const query = qs.stringify(decamelizeKeys(filter), {
        addQueryPrefix: true,
        skipNulls: true
      });

      const url = API.checklists.list;

      const params: AjaxRequest = {
        url: `${url}${query}`,
        method: 'GET'
      };

      return request(params).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'checklists/fetch-success',
          data: checklistsNormalizer(data),
          pagination
        })),
        RxOperators.catchError(({ message, response }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'checklists/fetch-fail',
            error: response ? response.data : message
          });
        })
      );
    })
  );

const saveChecklistEpic = $action =>
  $action.pipe(
    ofType('checklists/save'),
    RxOperators.pluck('checklist'),
    RxOperators.mergeMap(checklist => {
      const isNew = isNewElement(checklist);

      return request({
        url: isNew
          ? API.checklists.list
          : API.checklists.detail.replace(':checklist_id', checklist.id),
        method: isNew ? 'POST' : 'PUT',
        body: checklistDenormalizer(checklist)
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map(data => ({
          type: 'checklists/save-success',
          checklist: checklistNormalizer(data),
          ...(isNew && {
            originalId: checklist.id
          }),
          checklistId: data.id
        })),
        RxOperators.mergeMap(successAction =>
          of(successAction, { type: 'checklists/fetch' })
        ),
        RxOperators.catchError(({ response, message, status }) => {
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );

          return of({
            type: 'checklists/save-fail',
            error: response ? camelizeKeys(response.data) : message,
            checklistId: checklist.id
          });
        })
      );
    })
  );

const onChecklistSelectionEpic = $action =>
  $action.pipe(
    ofType('checklists/select'),
    RxOperators.mergeMap(() => [
      { type: 'checklist-test-groups/fetch' },
      { type: 'checklist-comparators/fetch' }
    ])
  );

const deleteChecklistEpic = $action =>
  $action.pipe(
    ofType('checklists/remove'),
    RxOperators.pluck('checklistId'),
    RxOperators.switchMap(checklistId =>
      request({
        url: API.checklists.detail.replace(':checklist_id', checklistId),
        method: 'DELETE'
      }).pipe(
        RxOperators.map(() => ({
          type: 'checklists/remove-success',
          checklistId
        })),
        RxOperators.mergeMap(successAction =>
          of(successAction, {
            type: 'checklists/fetch'
          })
        ),
        RxOperators.catchError(({ message, response }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'checklists/remove-fail',
            error: response ? response.data : message,
            checklistId
          });
        })
      )
    )
  );

export default combineEpics(
  fetchChecklistsEpic,
  saveChecklistEpic,
  deleteChecklistEpic,
  onChecklistSelectionEpic
);
