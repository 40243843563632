// @flow
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';

import request from 'utils/request';
import { API } from 'utils/config';

import camelizeMap from 'common/epicHelpers/camelizeMap';
import responseParser from 'common/epicHelpers/responseParser';
import listResponseParser from 'common/epicHelpers/listResponseParser';

import type { Epic } from 'flow-types/Epic';
import { decamelizeKeys } from 'humps';
import { buildingsFilter$ } from '../selector';

const fetchBuildings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('buildings/fetch'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([, state]) => {
      const filter = buildingsFilter$(state);

      return request({
        url: API.buildings.list,
        method: 'GET',
        query: decamelizeKeys(filter),
        queryParserConfig: {
          skipNulls: true,
          strictStringHandling: true
        }
      }).pipe(
        responseParser,
        listResponseParser,
        camelizeMap,
        RxO.map(({ data, pagination }) => ({
          type: 'buildings/fetch-success',
          data,
          pagination
        }))
      );
    })
  );

export default fetchBuildings;
