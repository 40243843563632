// @flow
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';

import Button from 'common/components/Button';
import Image from 'common/components/Image';

import type { IUpload } from 'flow-types/entities/Upload';
import SignatureModal from 'common/components/Question/SignatureQuestionBody/SignatureModal';

type SignatureQuestionBodyProps = {
  questionId: number,
  uploadAttachment: (file: typeof File) => Promise<IUpload>
};

export default function SignatureQuestionBody({
  questionId,
  uploadAttachment
}: SignatureQuestionBodyProps): React.Node {
  const intl = useIntl();

  const [signatureModalVisible, setSignatureModalVisible] = React.useState(
    false
  );

  const attachmentMutation = useMutation(data =>
    // eslint-disable-next-line no-use-before-define
    uploadAttachment(data)
  );

  const { data } = useAnswer(questionId, { data: null });

  const updateAnswer = useAnswerUpdate(questionId);

  return (
    <div>
      {!data ? (
        <Button
          buttonType="primary"
          onClick={() => setSignatureModalVisible(true)}
        >
          <FormattedMessage id="question.signature.addSignature" />
        </Button>
      ) : (
        <Button onClick={() => setSignatureModalVisible(true)}>
          <Image src={data} />
        </Button>
      )}
      <SignatureModal
        visible={signatureModalVisible}
        loading={attachmentMutation.isLoading}
        onCancel={() => setSignatureModalVisible(false)}
        onSubmit={(blob: File) => {
          const imageFile = new File([blob], 'image/png', {
            type: blob.type
          });

          attachmentMutation
            .mutateAsync(imageFile)
            .then((upload: IUpload) => {
              const nextAnswer = { data: upload };

              updateAnswer(nextAnswer);

              setSignatureModalVisible(false);

              // onAnswerInput(nextAnswer, { forceSubmission: true });
            })
            .catch(error => {
              toast.error(
                intl.formatMessage(
                  { id: 'question.signature.uploadError' },
                  { errorMessage: error.message }
                )
              );
            });
        }}
      />
    </div>
  );
}
