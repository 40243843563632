const projectInterviewTabDictionary = {
  en: {
    'project.interviewerSelectionModal.title':
      'Select employee to start an interview',
    'project.interviewerSelection.selfStart': 'Start from myself',
    'projectEditor.addQuestion': 'Add question',
    'projectEditor.addGroup': 'Add group',
    'projectEditor.controls.prev': 'Prev',
    'projectEditor.controls.next': 'Next',
    'projectEditor.structure.questionGroupSettings': 'Settings',
    'projectEditor.structure.questionGroupClone': 'Clone',
    'projectEditor.structure.noQuestions': 'No questions',
    'projectEditor.structure.question.emptyTitle': '*No title*',
    'projectEditor.confirmQuestionRemove': 'Confirm question remove',
    'projectEditor.confirmQuestionGroupRemove': `Are you definitely want to delete this group?`
  },
  ru: {
    'project.interviewerSelectionModal.title':
      'Выберите сотрудника для начала интервью',
    'project.interviewerSelection.selfStart': 'Начать от своего имени',
    'projectEditor.addQuestion': 'Добавить вопрос',
    'projectEditor.addGroup': 'Добавить группу',
    'projectEditor.controls.prev': 'Назад',
    'projectEditor.controls.next': 'Далее',
    'projectEditor.structure.questionGroupSettings': 'Настройки',
    'projectEditor.structure.questionGroupClone': 'Клонировать',
    'projectEditor.structure.noQuestions': 'Список вопросов пуст',
    'projectEditor.structure.question.emptyTitle': '*Без заголовка*',
    'projectEditor.confirmQuestionRemove': 'Подтвердите удаление вопроса',
    'projectEditor.confirmQuestionGroupRemove': `Подтвердите удаление группы!`
  }
};

export default projectInterviewTabDictionary;
