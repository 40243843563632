import { combineEpics } from 'redux-observable';
import savePollingNotification from './savePollingNotification';
import fetchPollingNotification from './fetchPollingNotification';
import deletePollingNotification from './deletePollingNotification';
import togglePollingNotification from './togglePollingNotification';

export default combineEpics(
  fetchPollingNotification,
  savePollingNotification,
  deletePollingNotification,
  togglePollingNotification
);
