import tc2 from 'tinycolor2';
import isNil from 'lodash/isNil';
import reduceRight from 'lodash/reduceRight';

export const isSelected = (rateValue: number, currentValue: number): boolean =>
  rateValue <= currentValue;

/**
 * This methods will get active color for all rates from minimum to currentValue.
 * If rateValue is passed, then it will check whether rateValue suits currentValue,
 * if not, then null will be returned as all colors.
 * If rateValue is not passed, then function will calculate activeColor on basis of currentValue.
 * If currentValue is not passed, the same result would happen when rateValue is not suiting currentValue.
 */
export const getActiveColor = ({ rateValue, currentValue, marks } = {}) => {
  if (
    isNil(currentValue) ||
    (!isNil(rateValue) && !isSelected(+rateValue, +currentValue))
  ) {
    return {
      original: null,
      lighten: null,
      active: false
    };
  }

  const safeCurrentValue = +currentValue;

  const color = reduceRight(
    marks,
    (result, markSettings) => {
      // we're not safe here, thus just return if value is not set
      if (
        !!result ||
        isNil(markSettings.value) ||
        safeCurrentValue < +markSettings.value
      )
        return result;

      return markSettings.color || null;
    },
    null
  );

  let original;
  let lighten;
  let darken;

  if (color) {
    original = tc2(color).toRgbString();

    lighten = tc2(color)
      .lighten(30)
      .toRgbString();

    darken = tc2(color)
      .darken(15)
      .toRgbString();
  }

  return {
    original,
    lighten,
    darken,
    // in case we do not have color,
    // we're using standard active field
    active: true
  };
};
