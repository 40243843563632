// @flow

import type { Reducer } from 'flow-types/Reducer';
import type { ComputedPropertiesState } from 'flow-types/states/ProjectsState/detail/ProjectComputedVariablesState';
import type { ComputedPropertiesAction } from 'flow-types/actions/projects/detail/computedProperties';
import updateFilter from '../../common/helpers/updateFilter';

const initialState: ComputedPropertiesState = {
  // TODO: remove mockup data
  data: [],
  filter: {
    title: null
  },
  status: null,
  errors: null
};

const computedProperties: Reducer<
  ComputedPropertiesState,
  ComputedPropertiesAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'project/fetch-computed-properties':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        }),
        status: 'loading',
        errors: null
      };

    case 'project/fetch-computed-properties-fail':
      return {
        ...state,
        status: 'fail',
        errors: Array.isArray(action.error) ? [...action.error] : [action.error]
      };

    case 'project/fetch-computed-properties-success':
      return {
        ...state,
        status: 'idle',
        data: action.data
      };

    default:
      return state;
  }
};

export default computedProperties;
