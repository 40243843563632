// @flow

import * as React from 'react';
import type { SidebarsState } from './reducer';
import reducer from './reducer';

type SidebarCtx = [SidebarsState, Function, Function, Function, Function];

const SidebarContext = React.createContext<SidebarCtx>({
  state: {},
  open: null,
  close: null,
  openAll: null,
  closeAll: null
});

export function useSidebars() {
  return React.useContext(SidebarContext);
}

export function useSidebar(sidebarId: string) {
  const { state, open, close, openAll, closeAll } = React.useContext(
    SidebarContext
  );

  return React.useMemo(() => {
    const stateSlice = state?.[sidebarId] || {
      visible: false
    };

    return {
      state: stateSlice,
      open: () => open(sidebarId),
      close: () => close(sidebarId),
      openAll,
      closeAll
    };
  }, [close, open, closeAll, openAll, sidebarId, state]);
}

type Props = {
  children: React.Node
};

const initialState = {};

export default function SidebarProvider({ children }: Props) {
  const [sidebarsState, dispatch] = React.useReducer(reducer, initialState);

  const open = React.useCallback((sidebarId: string) => {
    dispatch({
      type: 'open',
      sidebarId
    });
  }, []);

  const close = React.useCallback((sidebarId: string) => {
    dispatch({
      type: 'close',
      sidebarId
    });
  }, []);

  const openAll = React.useCallback(() => {
    dispatch({
      type: 'open-all'
    });
  }, []);

  const closeAll = React.useCallback(() => {
    dispatch({
      type: 'close-all'
    });
  }, []);

  const ctx = React.useMemo(
    () => ({ state: sidebarsState, open, close, openAll, closeAll }),
    [close, closeAll, open, openAll, sidebarsState]
  );

  return (
    <SidebarContext.Provider value={ctx}>{children}</SidebarContext.Provider>
  );
}
