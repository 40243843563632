import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';

import type {
  IInterviewOption,
  IQuestionOption
} from 'flow-types/entities/QuestionOption';
import type { IInterviewQuestion } from 'flow-types/entities/Question';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import Mobile, { useMobileScreen } from 'common/components/Responsive/Mobile';

import Header from 'common/components/Header';
import Content from 'common/components/Content';
import LazyImage from 'common/components/Image/lazy';
import { useSelection } from 'common/hooks/useSelection';
import { merge } from 'merge-anything';
import useWillUnmount from 'common/hooks/useWillUnmount';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../Table';

import { TableQuestionBodyContainer } from './styled';
import Checkbox from '../Checkbox';
import { TYPES } from '../../helpers/question';
import Input, { InputWrapper } from '../Input';
import Button from '../Button';
import Label from '../Label';

import { useAnswer } from '../../contexts/AnswersContext';
import { useAnswerUpdate } from '../../contexts/AnswerUpdateContext';
import isSelected from '../../helpers/isSelected';

type QuestionRowCellProps = {|
  data: IQuestionOption,
  checked: boolean,
  onChange: Function,
  radio: boolean,
  fitted: boolean,
  showTitle: boolean,
  disabled?: boolean,
  name: string
|};

const QuestionRowCell = React.memo(
  ({
    data,
    checked,
    onChange,
    radio,
    fitted,
    name,
    disabled,
    showTitle
  }: QuestionRowCellProps) => {
    const handleSelection = () => {
      if (!onChange || disabled) return;

      onChange(data?.id);
    };

    const handleCellClick = e => {
      if (e.target.nodeName !== 'TD') return;

      handleSelection();
    };

    return (
      <TableCell onClick={handleCellClick}>
        <Checkbox
          disabled={disabled}
          value={checked}
          onChange={handleSelection}
          radio={radio}
          fitted={fitted}
          name={name}
        >
          {showTitle && data.title}
        </Checkbox>
      </TableCell>
    );
  }
);

QuestionRowCell.defaultProps = {
  disabled: false
};

type QuestionRowProps = {
  data: IInterviewQuestion,
  otherTitle: string
};

const lazyProps = {
  offset: 100,
  scrollContainer: '.lazy-scroll-box'
};

const OTHER_INPUT_STYLE = { minWidth: 110 };

function QuestionRow({ data, otherTitle }: QuestionRowProps) {
  const multi = data.type !== TYPES.SingleAnswer;
  const single = data.type === TYPES.SingleAnswer;

  const answer: IInterviewAnswer = useAnswer(data.id, { data: [], meta: {} });

  const otherValue = answer.meta?.other ?? null;

  const [otherCache, setCache] = React.useState(otherValue ?? '');

  const onUpdate = useAnswerUpdate(data.id);

  // clear valueCache
  useWillUnmount(() => {
    onUpdate(
      {
        meta: {
          valueCache: null
        }
      },
      true
    );
  });

  // if question has been changed,
  // then we should clear cache
  React.useEffect(() => {
    setCache(otherValue ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  const changeOther = React.useCallback(
    nextOtherValue => {
      setCache(nextOtherValue);
      onUpdate(
        merge(
          { ...answer },
          {
            errors: null,
            meta: {
              other: nextOtherValue
            }
          }
        )
      );
    },
    [answer, onUpdate]
  );

  const { handleSelectionByMulti, isExcludingItemSelected } = useSelection({
    items: data.options,
    multi,
    onUserInputChange: (nextAnswer, nextUserInput) => {
      changeOther(nextUserInput?.other);
    },
    onSelectionChange: nextSelected => {
      // если выбрана опция "другое", то берётся её текст из второго аргумента и вставляется в мету
      // если нет, то ничего
      const hasOther = isSelected(nextSelected, 'other', multi);

      let updates = [{ data: nextSelected, errors: null }];

      if (!hasOther) {
        updates = [...updates, { meta: { other: null } }];
      } else {
        updates = [...updates, { meta: { other: otherCache ?? '' } }];
      }

      const nextAnswer = merge({ ...answer }, ...updates);

      onUpdate(nextAnswer);

      // if (onAnswerInput) {
      //   onAnswerInput(nextAnswer);
      // }
    },
    max:
      (data.maxAnswers ?? 0) && +data.maxAnswers > 0 ? +data.maxAnswers : null,
    showMaxWarningOnExceedingAttempts: true,
    userInputs: answer.meta,
    selected: answer.data
  });

  // const handleMultiSelection = React.useCallback(
  //   ({ id }: IQuestionOption) => {
  //     let nextValue = toggleArrayValue(answer.data, id);
  //
  //     if (isSelected(data.excludingOptions, nextValue, true, true)) {
  //       setVCache([...answer.data]);
  //       nextValue = [id];
  //     } else if (nextValue.length === 0) {
  //       nextValue = [...valueCache.current];
  //       setVCache([]);
  //     }
  //
  //     onUpdate({
  //       ...answer,
  //       data: nextValue,
  //       errors: null
  //     });
  //   },
  //   [answer, data.excludingOptions, setVCache, onUpdate, valueCache]
  // );

  // const handleSingleSelection = React.useCallback(
  //   (optionId: number) => {
  //     onUpdate({ ...answer, data: optionId, errors: null });
  //   },
  //   [answer, onUpdate]
  // );

  // const handleSelection = React.useCallback(
  //   (option: IQuestionOption) => {
  //     if (data.type === TYPES.MultipleAnswer || data.type === TYPES.Checklist) {
  //       handleMultiSelection(option);
  //     } else if (data.type === TYPES.SingleAnswer) {
  //       handleSingleSelection(option.id);
  //     }
  //   },
  //   [data.type, handleMultiSelection, handleSingleSelection]
  // );

  const isLessThanTabletScreen = useMobileScreen({ only: true });

  const isInErrorState = answer && !!answer.errors;

  return (
    <TableRow error={isInErrorState}>
      <TableCell>
        {data.imageToken ? (
          <Header as="h4" className="image">
            <LazyImage
              lazyProps={lazyProps}
              src={data.image.thumb256}
              size="huge"
              rounded
            />
            <Content>{data.title}</Content>
          </Header>
        ) : (
          data.title
        )}
      </TableCell>
      {map(data.options, (option: IInterviewOption) => {
        let isDisabled =
          data.disabled || option.disabled || data.hidden || option.hidden;

        const selected = isSelected(answer.data, option.id, !single);

        if (option.id === 'other') {
          return (
            <TableCell key={option.id}>
              {isLessThanTabletScreen && <Label nonUI>{otherTitle}</Label>}
              <InputWrapper
                fluid
                action="left"
                disabled={!selected && isDisabled}
              >
                <Button
                  icon
                  compact
                  size="mini"
                  disabled={isDisabled}
                  onClick={() => handleSelectionByMulti('other')}
                >
                  <Checkbox
                    value={selected}
                    disabled={isDisabled}
                    fitted
                    radio={!multi}
                    // onChange={() => handleSelectionByMulti('other')}
                    name={
                      multi ? `option-${data.id}-other` : `option-${data.id}`
                    }
                  />
                </Button>
                <Input
                  disabled={!selected || isDisabled}
                  onChange={changeOther}
                  style={OTHER_INPUT_STYLE}
                  onlyValue
                  value={answer.meta?.other ?? otherCache ?? ''}
                />
              </InputWrapper>
            </TableCell>
          );
        }

        let name = `option-${data.id}`;

        // if excluding option is selected,
        // then disabled all other options,
        // excluding option that is selected
        if (isExcludingItemSelected) {
          if (!isSelected(data.excludingOptions, option.id, true)) {
            isDisabled = true;
          }
        }

        if (multi) {
          name = `${name}-${option.id}`;
        }

        return (
          <QuestionRowCell
            data={option}
            disabled={isDisabled}
            checked={selected}
            onChange={handleSelectionByMulti}
            radio={!multi}
            fitted={!isLessThanTabletScreen}
            name={name}
            showTitle={isLessThanTabletScreen}
            key={option.id}
          />
        );
      })}
    </TableRow>
  );
}

type Props = {|
  +question: IInterviewQuestion
|};

export default function TableQuestionBody({ question }: Props) {
  const {
    options,
    subQuestions: questions,
    otherTitle,
    otherEnabled,
    settings
  } = question;

  const titleColumnValues = { multi: false };
  return (
    <TableQuestionBodyContainer>
      <Table compact veryCompact size="small">
        <Mobile from>
          <TableHead>
            <TableRow>
              <TableCell as="th" singleLine>
                <FormattedMessage
                  id="common.labels.question"
                  values={titleColumnValues}
                />
              </TableCell>
              {map(options, (opt: IInterviewOption) => (
                <TableCell as="th" key={`option-${opt.id}-header`}>
                  {opt.title}
                </TableCell>
              ))}
              {otherEnabled && <TableCell as="th">{otherTitle}</TableCell>}
            </TableRow>
          </TableHead>
        </Mobile>
        <TableBody>
          {map(questions, sub => {
            if (sub.hidden) return null;

            return (
              <QuestionRow
                key={sub.id}
                data={sub}
                otherEnabled={otherEnabled}
                otherTitle={otherTitle}
                settings={settings}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableQuestionBodyContainer>
  );
}

TableQuestionBody.defaultProps = {};
