import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { merge } from 'merge-anything';
import { useAnswer } from '../../contexts/AnswersContext';
import { useAnswerUpdate } from '../../contexts/AnswerUpdateContext';
import useRecognition from './hooks/useRecognition';
import Input, { InputWrapper } from '../Input';
import Icon from '../Icon';

import getValueFromDOMEvent from '../../helpers/getValueFromDOMEvent';
import filterPhoneNumberFromChars from '../../helpers/filterPhoneNumberFromChars';

import type { IInterviewQuestion } from '../../../flow-types/entities/Question';
import Label from '../Label';

type Props = {
  question: IInterviewQuestion
};

export default function MobilePhoneQuestionBody({ question, ...rest }: Props) {
  const intl = useIntl();

  const answer = useAnswer(question.id, { data: '' });

  const onChange = useAnswerUpdate(question.id);

  const handleRecognition = useCallback(
    recognitionResult => {
      // eslint-disable-next-line prefer-const
      let result = recognitionResult
        .trim()
        .replace(/[a-zA-Zа-яА-Я]+|\s+/gim, '');

      onChange(
        merge(
          { ...answer },
          {
            data: result,
            errors: null
          }
        )
      );
    },
    [answer, onChange]
  );

  const { listen, listening, stop, supported } = useRecognition({
    questionId: question.id,
    onResult: handleRecognition
  });

  const { data } = answer;

  const { disabled } = question;

  const handleChange = useCallback(
    event => {
      const nextValue = getValueFromDOMEvent(event);

      onChange(
        merge(
          { ...answer },
          {
            data: filterPhoneNumberFromChars(nextValue),
            errors: null
          }
        )
      );
    },
    [answer, onChange]
  );

  const placeholder = intl.formatMessage({
    id: 'questionForm.fields.answer.placeholder'
  });

  return (
    <>
      <InputWrapper
        labeled={supported && !question.disabled}
        corner={supported && !question.disabled ? 'left' : null}
        fluid
      >
        {supported && !question.disabled && (
          <Label
            corner="left"
            onMouseDown={e => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            color={listening ? 'red' : 'blue'}
            onClick={listening ? stop : () => listen({ continuous: true })}
          >
            <Icon icon={listening ? 'stop' : 'microphone'} />
          </Label>
        )}
        <Input
          {...rest}
          type="phone"
          name="phone"
          disabled={disabled || listening}
          placeholder={placeholder}
          value={data || ''}
          onChange={!listening ? handleChange : null}
        />
      </InputWrapper>
    </>
  );
}
