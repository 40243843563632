import revertDictionary from '../../utils/revertDictionary';

const userFilterSettingsModal = {
  'userFilterSettingsModal.title': {
    en: 'User Filter Editor',
    ru: 'Редактор пользовательских фильтров'
  },
  'userFilterSettingsModal.actions.confirmRemove': {
    ru: 'Вы хотите удалить этот фильтр?',
    en: 'Do you want to remove that filter?'
  }
};

export default revertDictionary(userFilterSettingsModal);
