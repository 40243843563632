// @flow

// interpolateString(str, {
//    ':project_id': 1
// });

type InterpolateStringFn = (
  str: string,
  config?: { [key: string]: any },
  prefix?: string
) => string;

const interpolateString: InterpolateStringFn = (
  str,
  config,
  prefix = ':'
): string => {
  if (!config || !str) return str;

  if (typeof config !== 'object' || Array.isArray(config)) return str;

  return Object.keys(config).reduce(
    (result, key) => result.replace(`${prefix}${key}`, config[key]),
    str
  );
};

export default interpolateString;
