// @flow
import map from 'lodash/map';
import composeFp from 'lodash/fp/compose';

import { camelizeKeys } from 'humps';
import type { ProjectSearchSettings } from '../../../flow-types/entities/ProjectSearchSettings';

export const normalizeSearchSettings = composeFp(
  (searchSettings: ProjectSearchSettings) => {
    const { questionIds } = searchSettings;

    return {
      ...searchSettings,
      questionIds: map(questionIds, id => +id)
    };
  },
  camelizeKeys
);
