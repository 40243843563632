// @flow

import * as React from 'react';
import Player from 'react-player';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import type { IInterviewQuestion } from 'flow-types/entities/Question';

import Icon from 'common/components/Icon';
import Segment from 'common/components/Segment';
import Header from 'common/components/Header';
import Rating from 'common/components/Rating';
import toHHMMSS from 'common/helpers/toHHMMSS';
import Modal from 'common/components/animated/Modal';
import useIsMobile from 'common/hooks/useIsMobile';
import { exitFullScreen, requestFullScreen } from 'common/helpers/fullscreen';
import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';

type VideoQuestionBodyProps = {
  question: IInterviewQuestion,
  onProgress: Function
};

export const Container: React.AbstractComponent<Object> = styled.div`
  & > .player__wrapper {
    position: relative;
    // player will save 16:9 aspect ratio
    //padding-top: 56.25%;
    padding-top: 56.25%;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & > .player__rating {
    margin-top: 0.75rem;
    // As soon as Rating is a inline-flex block,
    // text-align does the job
    text-align: center;
  }
`;

const getQuestionVideoSource = (
  sourcePriority: null | string,
  remoteSrc: string | null,
  localSrc: string | null
): string | null => {
  if (sourcePriority === 'remote') {
    return remoteSrc;
  }

  return localSrc;
};

const getIsUsingLocalSource = (sourcePriority): boolean =>
  sourcePriority !== 'remote';

const config = { tablet: true, featureDetect: true };

// Player.js line: 111 throws type error as soon as
// it does not take into account nullish onProgress
// callback prop value.
// TODO: check for PR for react-player on the matter of its existence,
//  or make it.
const voidFn = () => {};

const VideoQuestionBody = ({
  question,
  onProgress,
  ...props
}: VideoQuestionBodyProps): React.Node => {
  const isMobile = useIsMobile(config);

  const { data } = useAnswer(question.id, { data: {} });

  const latestData = React.useRef(data);

  React.useLayoutEffect(() => {
    latestData.current = data;
  }, [data]);

  const update = useAnswerUpdate(question.id);

  const [isRatingModalVisible, setIsRatingModalVisible] = React.useState(false);
  const [latestRate, setLatestRate] = React.useState(null);
  // We need it because video elements still plays two frames.
  // First one we catch and on second we're pausing it BECAUSE:
  // video and audio plays simultaneously with app running.
  const isRatingModalVisibleProxy = React.useRef(isRatingModalVisible);

  // we need it before browser would have done it in time to rerender this component,
  // thus our handleProgress handler is ready even before rerender is completed
  React.useLayoutEffect(() => {
    isRatingModalVisibleProxy.current = isRatingModalVisible;
  }, [isRatingModalVisible]);

  const container = React.useRef(null);
  const player = React.useRef(null);
  const restartOnRateIsMade = React.useRef(false);
  // is used to store time marker from rating time markers
  const triggeredTimeMarker = React.useRef(null);
  const shouldRequestFullScreenForVideoPlayer = React.useRef(null);
  // Метки переводятся в секунды, так как плеер возвращает секунды
  const ratingTimeMarkers = React.useRef<Set<number>>(new Set());
  const ratingTimeMarkersChecklist = React.useRef<Set<number>>(new Set());

  const videoSource = getQuestionVideoSource(
    question.priorityVideoSource,
    question.videoSrc,
    question.video?.url ?? null
  );

  const isUsingLocalSource = getIsUsingLocalSource(
    question.priorityVideoSource
  );

  const reactsEnabled =
    question?.settings?.pollingEnabled && isUsingLocalSource;

  const reactsMode: null | 'free' | 'interval' | 'mixed' = !reactsEnabled
    ? null
    : question?.settings?.pollingMode ?? 'free';

  const handleRate = React.useCallback(
    (value, timestamp: number) => {
      setLatestRate(value);

      update({
        data: {
          ...latestData.current,
          [toHHMMSS(timestamp)]: value
        }
      });
    },
    [update]
  );

  const handleProgress = React.useCallback(
    async progress => {
      // eslint-disable-next-line no-unused-expressions
      onProgress && onProgress(progress);

      if (isRatingModalVisibleProxy.current) return;

      const { playedSeconds } = progress;

      const truncatedSeconds = Math.trunc(playedSeconds);

      const relatedTimestamp = toHHMMSS(truncatedSeconds);

      const internalPlayer = player.current.getInternalPlayer();

      // Если юзер проматывает видео,
      // то не запрашиваем у него оценку
      // NOTE: это точно работает для FilePlayer'а
      if (internalPlayer.seeking) return;

      if (ratingTimeMarkersChecklist.current.has(truncatedSeconds)) return;

      if (!ratingTimeMarkers.current.has(truncatedSeconds)) return;

      // Если текущая секунда есть в списке на оценивание,
      // то плеер останавливает проигрывание и показывает модал.
      triggeredTimeMarker.current = truncatedSeconds;
      restartOnRateIsMade.current = true;
      internalPlayer.pause();

      if (document.fullscreenElement !== null) {
        shouldRequestFullScreenForVideoPlayer.current =
          document.fullscreenElement;
      }

      await exitFullScreen();

      const valueForCurrentTimestamp = latestData.current?.[relatedTimestamp];

      if (
        typeof valueForCurrentTimestamp !== 'undefined' &&
        valueForCurrentTimestamp !== null
      ) {
        // TODO: currently we can set only rating points as marks
        //  for video fragment, thus this `parseInt` thing is valid,
        //  however, if there would be emoji as marks or any other stuff that is not a number,
        //  then you have to change the row below.
        setLatestRate(+valueForCurrentTimestamp);
      }
      setIsRatingModalVisible(true);
    },
    [onProgress]
  );

  function handleSeek(seekedPoint) {
    ratingTimeMarkersChecklist.current.forEach(point => {
      if (point <= seekedPoint) return;

      ratingTimeMarkersChecklist.current.delete(point);
    });
  }

  function handleDuration(duration: number) {
    if (reactsMode === 'free') return;

    const total = question.settings?.pollingInterval?.total ?? 0;

    if (total === 0) return;

    let currentIndex = total;

    const nextRatingTimeMarkers = [];

    while (currentIndex <= duration) {
      nextRatingTimeMarkers.push(currentIndex);
      currentIndex += total;
    }

    ratingTimeMarkers.current = new Set(nextRatingTimeMarkers);
  }

  if (!videoSource) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon icon="video slash" />
          <FormattedMessage id="questionForm.messages.missedVideo" />
        </Header>
      </Segment>
    );
  }

  return (
    <Container as={Segment} basic fitted ref={container}>
      <div className="player__wrapper">
        <Player
          {...props}
          ref={player}
          playsinline
          className="react-player"
          onProgress={reactsMode === 'free' ? voidFn : handleProgress}
          url={videoSource}
          onDuration={handleDuration}
          onSeek={handleSeek}
          width="100%"
          height="100%"
          controls
        />
        {(reactsMode === 'interval' || reactsMode === 'mixed') && (
          <Modal
            visible={isRatingModalVisible}
            size="mini"
            portal={isMobile.current}
            dimmerProps={isMobile.current ? { page: true, fixed: true } : null}
          >
            <Modal.Content>
              <Header>Как вы оцените данный фрагмент?</Header>
              <Rating
                min={1}
                max={5}
                value={latestRate}
                shouldResetOnSameCheck={false}
                icon="star"
                size="massive"
                onChange={async (value): Promise<void> => {
                  const currentTime = triggeredTimeMarker.current ?? 0;

                  ratingTimeMarkersChecklist.current.add(currentTime);

                  handleRate(value, currentTime);

                  // triggeredTimeMarker.current = null;

                  setIsRatingModalVisible(false);
                  setLatestRate(null);

                  if (shouldRequestFullScreenForVideoPlayer.current) {
                    await requestFullScreen(
                      shouldRequestFullScreenForVideoPlayer.current
                    );

                    shouldRequestFullScreenForVideoPlayer.current = null;
                  }

                  if (restartOnRateIsMade.current) {
                    player.current.getInternalPlayer().play();
                    restartOnRateIsMade.current = false;
                  }
                }}
              />
            </Modal.Content>
          </Modal>
        )}
      </div>
      {reactsEnabled && (reactsMode === 'free' || reactsMode === 'mixed') && (
        <div className="player__rating">
          <Rating
            min={1}
            max={5}
            value={null}
            icon="star"
            size="massive"
            onChange={value =>
              handleRate(value, player.current?.getCurrentTime() ?? 0)
            }
          />
        </div>
      )}
    </Container>
  );
};

export default VideoQuestionBody;
