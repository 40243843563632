import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import qs from 'qs';
import { AjaxRequest } from 'rxjs/ajax';
import { toast } from 'react-toastify';
import { of } from 'rxjs';
import type { Epic } from '../../../flow-types/Epic';
import decamelizeKeys from '../../../common/helpers/decamelizeKeys';
import { API } from '../../../utils/config';
import request from '../../../utils/request';
import responseParser from '../../../common/epicHelpers/responseParser';
import listResponseParser from '../../../common/epicHelpers/listResponseParser';
import comparatorsNormalizer from '../../../common/transducers/checks/testElements/normalizer';
import { checksStateSelector } from '../../../selectors';
import debounceEpic from '../../../common/epicHelpers/debounceEpic';

export const fetchComparatorsEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('checklist-comparators/fetch'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([, state]) => {
      const {
        comparators: { filter },
        groups: { selected }
      } = checksStateSelector(state);

      return {
        filter: {
          ...filter,
          checklistGroupId: selected
        }
      };
    }),
    debounceEpic(),
    RxOperators.mergeMap(({ filter }) => {
      const query = qs.stringify(decamelizeKeys(filter), {
        addQueryPrefix: true,
        skipNulls: true
      });

      const url = API.comparators.list;

      const params: AjaxRequest = {
        url: `${url}${query}`,
        method: 'GET'
      };

      return request(params).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'checklist-comparators/fetch-success',
          data: comparatorsNormalizer(data),
          pagination
        })),
        RxOperators.catchError(({ response, message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'checklist-comparators/fetch-fail',
            error: response ? response.data : message
          });
        })
      );
    })
  );
