import revertDictionary from 'utils/revertDictionary';

const authDictionary = {
  'auth.passwordResetForm.actions.sendCode': {
    en: 'Send code',
    ru: 'Отправить код'
  },
  'auth.passwordResetForm.title': {
    en: 'Password Restoration',
    ru: 'Восстановление пароля'
  },
  'auth.passwordResetForm.login.label': {
    en: 'Login',
    ru: 'Логин'
  },
  'auth.passwordResetForm.login.placeholder': {
    en: 'Enter email',
    ru: 'Введите email'
  },
  'auth.passwordResetForm.password.label': {
    en: 'Password',
    ru: 'Пароль'
  },
  'auth.passwordResetForm.code.label': {
    en: 'SMS-code',
    ru: 'Код из смс'
  },
  'auth.passwordResetForm.passwordConfirmation.label': {
    en: 'Password confirmation',
    ru: 'Подтверждение пароля'
  },
  'auth.passwordResetForm.readSpamNotification': {
    en: 'Check "spam" folder of your mailbox',
    ru: 'Обязательно проверьте папку "спам" вашего почтового ящика'
  },
  'auth.passwordResetForm.passwordHasBeenChanged': {
    en: 'Password has been successfully changed',
    ru: 'Пароль был успешно изменён!'
  },
  'auth.passwordResetForm.validation.email.isInvalid': {
    en: 'Provided value is not a valid email',
    ru: 'Введённое значение не соответствует электронному адресу'
  },
  'auth.passwordResetForm.validation.password.minLength': {
    en:
      'Password should be more than {count, plural, one {# character} many {# characters}} =0 {# characters}',
    ru:
      'Пароль должен иметь длину в более чем {count, plural, one {# символ} few {# символа} many {# символов} =0 {# символов}}'
  },
  'auth.passwordResetForm.validation.passwordConfirmation.shouldMatch': {
    en: 'Passwords should match',
    ru: 'Пароли должны совпадать'
  },
  'auth.passwordResetForm.validation.email.isRequired': {
    en: 'Email is required',
    ru: 'Необходимо ввести email'
  },
  'auth.passwordResetForm.validation.code.isRequired': {
    en: 'Code is required',
    ru: 'Необходимо ввести код из СМС-сообщения'
  },
  'auth.passwordResetForm.loginNotExists': {
    en: "You've present wrong email",
    ru: 'Вы ввели недействительный email'
  },
  'auth.loginForm.title': {
    en: 'Authorization',
    ru: 'Авторизация'
  },
  'auth.loginForm.fields.email.placeholder': {
    en: 'Email',
    ru: 'Email'
  },
  'auth.loginForm.fields.password.placeholder': {
    en: 'Password',
    ru: 'Пароль'
  },
  'auth.loginForm.validation.email.incorrectFormat': {
    en: 'Entered email has incorrect format',
    ru: 'Введённый email имеет неверный формат'
  },
  'auth.loginForm.validation.email.isRequired': {
    en: 'Email is required',
    ru: 'Необходимо ввести email'
  },
  'auth.loginForm.validation.password.isRequired': {
    en: 'Password is required',
    ru: 'Необходимо ввести пароль'
  },
  'auth.form.messages.wrong.credentials': {
    en: "You've entered wrong credentials!",
    ru: 'Вы ввели недействительные данные для входа!'
  },
  'auth.loginForm.actions.singIn': {
    en: 'Sign In',
    ru: 'Войти'
  },
  'auth.loginForm.actions.singOut': {
    en: 'Sign Out',
    ru: 'Выйти'
  },
  'auth.loginForm.actions.forgotPassword': {
    en: 'Forgot password',
    ru: 'Забыл пароль'
  },
  'auth.loginForm.fields.rememberMe.label': {
    en: 'Remember Me',
    ru: 'Запомнить меня'
  },
  'auth.loginForm.validation.invalidCredentials': {
    en: "You've entered incorrect email or password",
    ru: 'Вы ввели неверные логин или пароль'
  }
};

export default revertDictionary(authDictionary);
