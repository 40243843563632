import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Menu from 'common/components/Menu';
import Input, { InputWrapper } from 'common/components/Input';
import Button from 'common/components/Button';

export type Props = {|
  // length of available pages
  +totalPages?: number,
  // index of current page
  +activePage: number,
  +onChange: Function,
  +orderOffset?: number,
  +showDirectPageNavigator?: boolean,
  +size?: null | string,
  +disabled?: boolean
|};

const MenuItem = styled.a`
  outline: none;
`;

const StyledMenu = styled(Menu)`
  flex-wrap: wrap;

  .item::before {
    display: none;
  }
`;

/**
 * It can be confusing, but activePage is an index of current page
 * and totalPages is a length of an abstract array of pages on server.
 *
 * Thus, orderOffset is an amount of numerical shift
 * that should be made to activePage to be correctly displayed.
 */
function Pagination(props: Props) {
  const {
    totalPages,
    activePage,
    onChange,
    orderOffset = 0,
    showDirectPageNavigator,
    size,
    disabled
  } = props;

  const [active, setActive] = useState(activePage);
  const [directActive, setDirectActive] = useState('');

  useEffect(() => {
    if (activePage !== active) {
      setDirectActive('');
      setActive(activePage);
    }
    // eslint-disable-next-line
  }, [activePage]);

  const changeActive = useCallback(
    nextActive => {
      setActive(nextActive);
      if (!onChange) return;
      onChange(nextActive);
    },
    [onChange]
  );

  const atBeginning = active <= 1 - orderOffset;

  const atEnd = active + 1 > totalPages - orderOffset;

  const moveNext = useCallback(() => {
    if (!atEnd) {
      changeActive(active + 1);
    }
  }, [active, atEnd, changeActive]);

  const moveBack = useCallback(() => {
    if (!atBeginning) {
      changeActive(active - 1);
    }
  }, [active, atBeginning, changeActive]);

  const moveDirect = useCallback(
    e => {
      e.preventDefault();

      if (!directActive && directActive !== 0) return;

      changeActive(+directActive - orderOffset);
    },
    [changeActive, directActive, orderOffset]
  );

  const title = `${active + orderOffset} / ${totalPages}`;

  return (
    <StyledMenu pagination size={size} className={disabled ? 'disabled' : null}>
      <MenuItem
        role="button"
        tabIndex={-1}
        className={`center aligned ${
          atBeginning || disabled ? 'disabled ' : ''
        }item`}
        onClick={moveBack}
      >
        <i className="chevron left icon" style={{ margin: 0 }} />
      </MenuItem>
      <MenuItem role="button" tabIndex="-1" className="item">
        {title}
      </MenuItem>
      <MenuItem
        role="button"
        tabIndex={-1}
        className={`center aligned ${atEnd || disabled ? 'disabled ' : ''}item`}
        onClick={moveNext}
      >
        <i className="chevron right icon" style={{ margin: 0 }} />
      </MenuItem>
      {showDirectPageNavigator && (
        <MenuItem tabIndex={-1} as="div" className="aligned center item">
          <form onSubmit={moveDirect}>
            <InputWrapper action="right" disabled={disabled}>
              <Input
                type="number"
                value={directActive}
                onChange={setDirectActive}
                onlyValue
                max={totalPages}
                min={1}
              />
              <Button disabled={disabled} type="submit">
                →
              </Button>
            </InputWrapper>
          </form>
        </MenuItem>
      )}
    </StyledMenu>
  );
}

Pagination.defaultProps = {
  disabled: false,
  totalPages: 0,
  orderOffset: 0,
  showDirectPageNavigator: false,
  size: null
};

export default Pagination;
