import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import type { Epic } from '../../../flow-types/Epic';
import request from '../../../utils/request';
import { API } from '../../../utils/config';
import responseParser from '../../../common/epicHelpers/responseParser';
import { normalizeUser } from '../../../common/transducers/users/normalizer';

const editUserEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/edit-user'),
    RxOperators.map(action => {
      const { user } = action;

      const isUpdate = !!user.id;

      return {
        isUpdate,
        user
      };
    }),
    RxOperators.mergeMap(({ isUpdate, user }) => {
      if (!isUpdate) {
        return of({
          type: 'companies/update-user-form',
          formUpdate: { visible: true },
          dataUpdate: { ...user }
        });
      }

      return request({
        url: API.users.detail.replace(':userId', user.id),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.map(({ data }) => {
          const normalizedUser = normalizeUser(data);

          return {
            type: 'companies/update-user-form',
            formUpdate: { visible: true },
            dataUpdate: normalizedUser
          };
        }),
        RxOperators.catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'companies/fetch-user-fail'
          });
        })
      );
    })
  );

export default editUserEpic;
