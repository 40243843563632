import { of } from 'rxjs';
import type { Epic } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import qs from 'qs';

import {
  catchError,
  filter as filterOperator,
  map,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';

import { AjaxError } from 'rxjs/ajax';
import { camelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import responseParser from 'common/epicHelpers/responseParser';
import listResponseParser from 'common/epicHelpers/listResponseParser';
import companiesNormalizer from '../../common/transducers/companies/normalizers';
import { companyDenormalizer } from '../../common/transducers/companies/denormalizers';
import request from '../../utils/request';
import { API } from '../../utils/config';

import type { Action } from '../../flow-types/actions/companies';
import debounceEpic from '../../common/epicHelpers/debounceEpic';
import { companiesPageStateSelector } from '../../selectors';

// pipe is working as a reduce() method for an array
// ofType helps us to filter incoming action types
// thus our epic will react only for the given as an argument type
export const saveCompanyEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/save-company'),
    withLatestFrom($state),
    mergeMap(([, state]) => {
      const {
        companiesList: {
          form: { data: company }
        }
      } = companiesPageStateSelector(state);

      const isUpdate = !!company.id;

      return request({
        method: isUpdate ? 'PUT' : 'POST',
        body: companyDenormalizer(company),
        url: isUpdate
          ? API.companies.detail.replace(':company_id', company.id)
          : API.companies.list
      }).pipe(
        // here we dispatch two actions one after another
        mergeMap(() =>
          of(
            { type: 'companies/save-company-success' },
            { type: 'companies/fetch-companies' }
          )
        ),
        // this is our ajax request error handler
        catchError((errorResponse: AjaxError) => {
          const { message, response, status } = errorResponse;
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'companies/save-company-fail',
            ...(status === 422 && {
              error: camelizeKeys(response.data)
            }),
            ...(status !== 422 && {
              error: response ? response.error : message
            })
          });
        })
      );
    })
  );

export const deleteCompanyEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/delete-company'),
    map(action => action.companyId),
    mergeMap(id =>
      request({
        method: 'DELETE',
        url: API.companies.detail.replace(':company_id', id)
      }).pipe(
        mergeMap(() =>
          of(
            { type: 'companies/delete-company-success' },
            { type: 'companies/fetch-companies' }
          )
        ),
        catchError((errorResponse: AjaxError) => {
          const { response, message } = errorResponse;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'companies/delete-company-fail',
            error: response ? response.error : message // we pass either response, or message
          });
        })
      )
    )
  );

export const fetchCompaniesEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/fetch-companies'),
    map((action: Action) => {
      const {
        companies: {
          companiesList: { filter }
        }
      } = $state.value;
      const { filterUpdate } = action;

      return {
        ...filter,
        ...filterUpdate
      };
    }),
    debounceEpic(),
    mergeMap(filter => {
      const params = qs.stringify(filter, { addQueryPrefix: true });
      return request({
        method: 'GET',
        url: `${API.companies.list}${params}`
      }).pipe(
        responseParser,
        listResponseParser,
        map(({ data, pagination }) => ({
          type: 'companies/fetch-companies-success',
          data: companiesNormalizer(data),
          pagination
        })),
        catchError((errorResponse: AjaxError) => {
          const { response, message } = errorResponse;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'companies/fetch-companies-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

// Epic starts when we select company in first column,
// so it starts refetching of data in second column
// Also we should reset page number when (re-)selecting company
const selectCompanyEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/select-company'),
    // we do not fetch users and departments if company if toggled
    // TODO: implement methods in API that resolves claim above
    filterOperator(action => !!action.company),
    mergeMap(() =>
      of(
        {
          type: 'companies/fetch-departments',
          filterUpdate: {
            pn: 0
          }
        },
        {
          type: 'companies/fetch-users',
          filterUpdate: {
            pn: 0
          }
        }
      )
    )
  );

export default combineEpics(
  saveCompanyEpic,
  deleteCompanyEpic,
  fetchCompaniesEpic,
  selectCompanyEpic
);
