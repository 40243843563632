// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Node } from 'react';

type ResponsePropertyLabelProps = {
  fieldName: string
};

export default function ResponsePropertyLabel({
  fieldName
}: ResponsePropertyLabelProps): Node {
  return <FormattedMessage id={`project.responsesPanel.table.${fieldName}`} />;
}
