/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import styled from 'styled-components';
import Flexbox from 'common/components/Flexbox';
import Icon from 'common/components/Icon';
import Header from 'common/components/Header';
import Button from 'common/components/Button';
import useOnClickOutside from 'use-onclickoutside';
import ListItem from '../../../ListItem';
import Input, { InputWrapper } from '../../../Input';

const Btn = styled.button`
  margin-top: -2.65px !important;
  margin-bottom: -2.65px !important;
  margin-right: -0.4em !important;
`;

const InputWrapped = styled(Flexbox)`
  &&&&& {
    input[type='text'] {
      overflow-wrap: anywhere;
      font-size: 1.28571429em;
    }
  }
`;

type Props = {|
  onSelect: Function,
  onChange: Function,
  selected: boolean,
  disabled: boolean,
  title: string,
  data: string
|};

const OtherOption = ({
  onSelect,
  onChange,
  disabled,
  selected,
  data,
  title
}: Props) => {
  const intl = useIntl();

  const [isEditing, set] = useState(false);

  const handleSelect = useCallback(() => {
    if (!data) {
      set(true);
      return;
    }

    if (!onSelect) return;

    onSelect('other');
  }, [data, onSelect]);

  const handleChange = useCallback(
    otherTitle => {
      if (!onChange) return;

      onChange(otherTitle);

      if (selected && (otherTitle === null || otherTitle === '')) {
        handleSelect();
      }
    },
    [handleSelect, onChange, selected]
  );

  const handleCheck = useCallback(() => {
    set(false);
    if (!selected) {
      handleSelect();
    }
  }, [handleSelect, selected]);

  const handleEdit = useCallback(() => {
    set(true);
  }, []);

  const otherOptionRef = React.useRef(null);

  useOnClickOutside(otherOptionRef, () => {
    const sizeOfInput = data?.length ?? 0;

    if (isEditing && sizeOfInput === 0) {
      set(false);
    }
  });

  return (
    <ListItem
      className={cx({ selected, disabled }, 'border-blue')}
      onClick={isEditing ? null : handleSelect}
      ref={otherOptionRef}
    >
      <Flexbox
        $direction="row"
        $alignItems="flex-start"
        gapSize="10"
        gapDimension="px"
      >
        {isEditing && (
          <>
            <InputWrapper tagName={InputWrapped} grow={1} transparent fluid>
              <Input
                autoFocus
                value={data}
                onlyValue
                onChange={handleChange}
                placeholder={
                  title ||
                  intl.formatMessage({
                    id: 'questionForm.fields.otherOption.placeholder'
                  })
                }
                disabled={disabled}
              />
            </InputWrapper>
            <Button
              tagName={Btn}
              size="small"
              className="compact"
              icon
              onClick={handleCheck}
              color="primary"
            >
              <Icon icon="check" />
            </Button>
          </>
        )}
        {!isEditing && (
          <>
            <Flexbox grow={1} ignoreDisplay>
              <Header className="light" color="blue">
                {data || title}
              </Header>
            </Flexbox>
            <Button
              tagName={Btn}
              className="compact"
              size="small"
              color="primary"
              icon
              type="button"
              onClick={handleEdit}
            >
              <Icon icon="pen" />
            </Button>
          </>
        )}
      </Flexbox>
    </ListItem>
  );
};

export default OtherOption;
