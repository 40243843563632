// Helper to decide error config
export function getErrorConfig(
  meta: Object,
  errorPrompt: boolean | Object | undefined
) {
  if (meta.error && meta.touched && errorPrompt) {
    if (errorPrompt === true) {
      return { children: meta.error, prompt: true, pointing: 'above' };
    }
    return { children: meta.error, ...errorPrompt };
  }
  return null;
}
