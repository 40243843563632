// @flow

import map from 'lodash/map';
import type { ResponseReset } from 'flow-types/actions/projects/detail/response/ResponseReset';
import type { $ActionType } from 'flow-types/ActionType';
import type { SelectRecord } from 'flow-types/actions/records/SelectRecord';
import type { RecordsActions } from '../../flow-types/actions/records';
import type { RecordsListState } from '../../flow-types/states/RecordsState';
import type { ResetProject } from '../../flow-types/actions/projects/detail/project/ResetProject';

const initialState: RecordsListState = {
  data: null, // null is used to determine state, when we do not have real data
  filter: {},
  pagination: {
    totalPages: 1,
    totalElements: 0,
    activePage: 0
  },
  loading: false,
  error: null,
  selected: null
};

export const SELECT_RECORD: $ActionType<SelectRecord> = 'records/select';

export default (
  state: RecordsListState = initialState,
  action: RecordsActions | ResetProject | ResponseReset
): RecordsListState => {
  switch (action.type) {
    case 'project-response/reset':
    case 'records/reset':
      return initialState;

    case SELECT_RECORD:
      return {
        ...state,
        selected: action.recordId
      };

    case 'records/update-record':
      return {
        ...state,
        data: map(state.data, record => {
          if (record.id !== action.recordId) return record;

          return {
            ...record,
            ...action.dataUpdate,
            ...(!record.cache && {
              cache: record
            })
          };
        })
      };

    // case 'records/fetch':
    //   return {
    //     ...state,
    //     loading: true,
    //     ...(action.filterUpdate && {
    //       filter: {
    //         ...state.filter,
    //         ...action.filterUpdate
    //       }
    //     }),
    //     ...(action.filter && {
    //       filter: action.filter
    //     })
    //   };

    case 'records/fetch-fail':
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case 'records/fetch-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        loading: false
      };

    default:
      return state;
  }
};
