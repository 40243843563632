import parseISO from 'date-fns/parseISO';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

export default function getDuration(
  from: string | typeof Date = Date.now(),
  to: string | typeof Date = Date.now(),
  options: Object
) {
  try {
    if (!from || !to) {
      throw new Error('either from or to is not valid');
    }

    const startDate = typeof from === 'string' ? parseISO(from) : from;
    const endDate = typeof to === 'string' ? parseISO(to) : to;

    const result = formatDistanceStrict(startDate, endDate, {
      unit: 'second',
      ...(options && options),
      addSuffix: true
    })
      .split(' ')
      .map(i => i.trim());

    if (result[0] === 'in') return +result[1];

    if (result[2] === 'ago') return -result[0];

    return result[0];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`getDuration: ${e.message}`);
    return 0;
  }
}
