/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow

import * as React from 'react';
import map from 'lodash/map';
import Link from 'common/components/Link';
import type { IActionItem, IActions } from './flow';

type OnActionClickCb = (actionKey: string) => void;

type Props = {
  data: IActions,
  onClick?: null | OnActionClickCb
};

export default function Actions({ data, onClick }: Props): React.Node {
  return (
    <div className="actions">
      {map(data, (action: IActionItem, actionClass) => {
        const { as: Component, label } = action;

        if (!Component) {
          return (
            <Link
              className={actionClass}
              onClick={
                onClick
                  ? () => {
                      onClick(actionClass);
                    }
                  : null
              }
              key={actionClass}
            >
              {label}
            </Link>
          );
        }

        return (
          <Component
            key={actionClass}
            className={actionClass}
            onClick={onClick ? () => onClick(actionClass) : null}
            label={label}
          />
        );
      })}
    </div>
  );
}

Actions.defaultProps = {
  onClick: null
};
