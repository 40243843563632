import { combineEpics } from 'redux-observable';
import fetchFilterSettingsCounts from './fetchFilterSettingsCounts';
import fetchFiltersSettingsListGroups, {
  fetchFilterSettingsOnFetchProjectSuccess,
  refetchFilterSettingsOnSaveAndRemoveSuccess
} from './fetchFiltersSettingsListGroups';
import saveFilterSettings, {
  reapplyUserFilterOnSaveSuccess
} from './saveFiltersSettings';
import removeUserFilterSettings, {
  resetUserFilterOnRemoveSuccess
} from './removeUserFilterSettings';

export default combineEpics(
  fetchFiltersSettingsListGroups,
  fetchFilterSettingsCounts,
  fetchFilterSettingsOnFetchProjectSuccess,
  refetchFilterSettingsOnSaveAndRemoveSuccess,
  reapplyUserFilterOnSaveSuccess,
  saveFilterSettings,
  resetUserFilterOnRemoveSuccess,
  removeUserFilterSettings
);
