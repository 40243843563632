// @flow

import { EMPTY } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import reduceRight from 'lodash/reduceRight';

import type { InterviewAppearance } from 'flow-types/states/InterviewState/InterviewRecordState';

import { getStackByQuestionId } from 'common/helpers/interview/getStack';
import {
  INTERVIEW_APPEARANCE,
  INTERVIEW_BLOCK_ROLES_IDS
} from 'common/helpers/interview/constants';

import type { InterviewRegistryState } from 'flow-types/states/InterviewState/InterviewRegistryState';
import type { IRegistryRecord } from 'flow-types/entities/RegistryRecord';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { Move } from 'flow-types/actions/interview/Move';
import type { Epic } from 'flow-types/Epic';

import { createInterviewAppearanceSelector } from '../../selectors/interview/statuses';
import { interviewLogicAwaredStructureSelector } from '../../selectors/interview/answers';
import { interviewRegistrySelector } from '../../selectors/interview/root';

const makeInitialMoveOnPrepareComplete: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/prepare-complete'),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([, state]): [Move] | typeof EMPTY => {
      const appearance: InterviewAppearance = createInterviewAppearanceSelector()(
        state
      );

      const structure = interviewLogicAwaredStructureSelector(state);

      /*
        if current appearance is post-polling,
        then do not make initial move if there is a greeting message
      */
      if (
        [INTERVIEW_APPEARANCE.POST, INTERVIEW_APPEARANCE.PUBLIC].includes(
          appearance
        )
      ) {
        const registryState: InterviewRegistryState = interviewRegistrySelector(
          state
        );

        const stack: ?IInterviewStructureElement = reduceRight(
          registryState.list,
          (result, registryRecord: IRegistryRecord) => {
            if (!!result || !registryRecord.enter) {
              return result;
            }

            const stackByQuestionId = getStackByQuestionId(
              structure,
              registryRecord.questionId
            );

            if (!stackByQuestionId) return result;

            if (
              registryRecord.questionId &&
              stackByQuestionId.stackRoleId !==
                INTERVIEW_BLOCK_ROLES_IDS.FINISHING &&
              stackByQuestionId.stackRoleId !==
                INTERVIEW_BLOCK_ROLES_IDS.ENDING &&
              !stackByQuestionId.hidden
            ) {
              return stackByQuestionId;
            }

            return result;
          },
          null
        );

        // make initial move to the last visited block in a current session in the post-polling scenario
        if (stack) {
          return [
            {
              type: 'interview/move',
              stackId: stack.localId
            }
          ];
        }
      }

      return [
        {
          type: 'interview/move',
          forward: true
        }
      ];
    })
  );

export default makeInitialMoveOnPrepareComplete;
