import map from 'lodash/map';
import isNil from 'lodash/isNil';

// P.S. isNil is required, because null is of Object type...seriously

export default function valueNormalizer({
  value,
  onlyValue,
  multiple,
  valueKey
}) {
  if (multiple) {
    return map(value, valueItem => {
      if (onlyValue && !isNil(valueItem) && typeof valueItem === 'object') {
        return valueItem[valueKey];
      }

      return valueItem;
    });
  }

  if (Array.isArray(value)) {
    return valueNormalizer({
      value: value[0],
      onlyValue,
      multiple,
      valueKey
    });
  }

  if (onlyValue && !isNil(value) && typeof value === 'object') {
    return value[valueKey];
  }

  return value;
}
