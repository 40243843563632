import type { Epic } from 'flow-types/Epic';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  SaveSides,
  SaveSidesSuccess
} from 'flow-types/actions/records/decodings/list/SaveSides';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeAndNormalizeDecoding } from 'common/transducers/uploads/decodingsListNormalizer';
import type { IDecoding } from 'flow-types/entities/Decoding';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import type {
  DecodingMap,
  DecodingsState
} from 'flow-types/states/RecordsState';
import { decamelizeKeys } from 'humps';
import type { AppState } from '../../../flow-types/AppState';
import {
  SAVE_SIDES,
  SAVE_SIDES_SUCCESS
} from '../../../reducers/records/decodings/list';
import type { CloseModal } from '../../../pages/Modals/modals';
import { MODAL_ID } from '../../../pages/Project/components/Panels/ResponsePanel/UploadDecodings/DecodingSides/DecodingSidesModal';
import { decodingsStateSelector } from '../../../selectors/records/decodings';

const saveSides$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_SIDES),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([action, state]: [SaveSides, AppState]) => {
      const { sides, decodingId } = action;

      const {
        list: { decodingsMap }
      }: DecodingsState = decodingsStateSelector(state);

      const { uploadId }: DecodingMap = decodingsMap[decodingId];

      return request({
        url: interpolateString(API.uploads.decodings.detail, {
          uploadId,
          decodingId
        }),
        method: 'PUT',
        body: {
          sides: decamelizeKeys(sides)
        }
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.mergeMap((rawDecoding):
          | [SaveSidesSuccess]
          | [SaveSidesSuccess, CloseModal] => {
          const decoding: IDecoding = camelizeAndNormalizeDecoding(rawDecoding);

          return [
            {
              type: SAVE_SIDES_SUCCESS,
              sides: decoding.sides,
              decodingId
            },
            {
              type: 'modals/close',
              modalId: MODAL_ID
            }
          ];
        })
      );
    })
  );

export default saveSides$;
