import { useMemo } from 'react';
import random from 'lodash/random';

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'brown',
  'pink'
];

let prev = null;

export default function useRowUniqColor() {
  return useMemo(() => {
    let num = prev;

    while (num === prev) {
      num = random(0, 9);
    }

    prev = num;

    return colors[num];
  }, []);
}
