import { of } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { camelizeKeys } from 'humps';
import type {
  CreateInterviewRecord,
  CreateInterviewRecordFail,
  CreateInterviewRecordSuccess
} from 'flow-types/actions/interview/CreateInterviewRecord';
import createInterviewLocalData from '../../common/helpers/createInterviewLocalData';
import request from '../../utils/request';
import { API } from '../../utils/config';
import interviewDenormalizer from '../../common/transducers/interview/denormalizer';
import responseParser from '../../common/epicHelpers/responseParser';
import { interviewRecordSelector } from '../../selectors/interview/root';

// ON SUCCESS
export const createInterviewSuccess = (
  interview
): CreateInterviewRecordSuccess => ({
  type: 'interview/create-interview-record-success',
  interview: camelizeKeys(interview)
});

// ON ERROR
export const createInterviewFail = (error): CreateInterviewRecordFail =>
  of({
    type: 'interview/create-interview-record-fail',
    error
  });

// EPIC
const createInterviewRecord = ($action, state$) =>
  $action.pipe(
    ofType('interview/create-interview-record'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([action, state]: [CreateInterviewRecord, State]) => {
      const { projectId, local } = action;

      const {
        data: { initialInterviewerId }
      } = interviewRecordSelector(state);

      let localInterviewData = createInterviewLocalData();

      if (local) {
        return of(createInterviewSuccess(localInterviewData));
      }

      if (initialInterviewerId) {
        localInterviewData = {
          ...localInterviewData,
          interviewerId: initialInterviewerId
        };
      }

      return request({
        url: API.responses.list,
        method: 'POST',
        body: interviewDenormalizer({
          ...localInterviewData,
          projectId
        })
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map(createInterviewSuccess),
        RxOperators.catchError(createInterviewFail)
      );
    })
  );

export default createInterviewRecord;
