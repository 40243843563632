import styled from 'styled-components';

export const VirtualSubmitInput = styled.input`
  position: absolute;
  z-index: -9999999;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
`;
