// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import size from 'lodash/size';
import type { IUpload } from 'flow-types/entities/Upload';
import Header from 'common/components/Header';
import Attachments from '../../../Interview/components/InterviewProfile/Attachments';
import type { OnAttachmentClickCb } from './flow';

export const hasAttachments = (attachments: ?(IUpload[])): boolean %checks =>
  Array.isArray(attachments) && size(attachments) > 0;

type Props = {
  attachments?: IUpload[],
  otherAttachments?: IUpload[],
  onAttachmentClick?: null | OnAttachmentClickCb
};

export default function AnswerAttachments({
  attachments,
  otherAttachments,
  onAttachmentClick
}: Props) {
  const hasAnyAttachments =
    hasAttachments(attachments) || hasAttachments(otherAttachments);

  return (
    <>
      {hasAnyAttachments && (
        <Header size="tiny">
          <FormattedMessage id="projects.tabs.responses.attachments" />
        </Header>
      )}
      {hasAttachments(attachments) && (
        <Attachments data={attachments} onAttachmentClick={onAttachmentClick} />
      )}
      {hasAttachments(otherAttachments) && (
        <Attachments data={otherAttachments} viewType="link-list" />
      )}
    </>
  );
}

AnswerAttachments.defaultProps = {
  attachments: [],
  otherAttachments: [],
  onAttachmentClick: null
};
