import { debounceTime as base } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';

const debounceEpic = (dueTime = 500, config = {}) =>
  base(dueTime, asyncScheduler, {
    trailing: true,
    leading: true,
    ...config
  });

export default debounceEpic;
