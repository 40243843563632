import revertDictionary from 'utils/revertDictionary';

const responsesMassActionsFormDictionary = {
  'responsesMassActionsForm.fields.actions.isRequired': {
    ru: 'Необходимо добавить как минимум одно действие',
    en: 'At least one action is required'
  }
};

export default revertDictionary(responsesMassActionsFormDictionary);
