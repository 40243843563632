import mergeWith from 'lodash/mergeWith';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import baseDefaultsDeep from 'lodash/defaultsDeep';

// Note: if you change `_.isNil` to `_.isUndefined`
// then you'd get the `_.defaults()` normal behavior
const nilMerge = (a, b) => (isNil(a) ? b : a);

const nilMergeDeep = (a, b) =>
  isObject(a) && !isArray(a)
    ? // recursively merge objects with nilMergeDeep customizer
      mergeWith({}, a, b, nilMergeDeep)
    : // let's use our default customizer
      nilMerge(a, b);

// abstract wrapper around lodash.defaultsDeep,
// but with support for nulls replacement.
// In lodash.defaultsDeep null is a common empty value,
// but in some cases null should be changed to some value
export default function defaultsDeep(value, defaultValues, keepNull = false) {
  if (keepNull) {
    return baseDefaultsDeep(value, defaultValues);
  }

  return nilMergeDeep(value, defaultValues);
}
