import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import withLatestSelectors from 'common/epicHelpers/withLatestSelectors';
import type { RemoveRegionSuccess } from 'flow-types/actions/records/DeleteRecordRegion';
import request from '../../utils/request';
import { API } from '../../utils/config';
import type { Epic } from '../../flow-types/Epic';
import { activeChecklistIdSelector } from '../../pages/Project/components/Panels/ResponsePanel/selectors';

const removeRegionEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('records-regions/remove'),
    RxOperators.withLatestFrom(state$),
    withLatestSelectors([activeChecklistIdSelector]),
    RxOperators.mergeMap(([{ regionId }, , [checklistId]]) =>
      request({
        url: API.uploads.region.replace(':region_id', regionId),
        method: 'DELETE'
      }).pipe(
        RxOperators.map((): RemoveRegionSuccess => ({
          type: 'records-regions/remove-success',
          regionId,
          checklistId
        })),
        RxOperators.catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'records-regions/remove-fail',
            regionId
          });
        })
      )
    )
  );

export default removeRegionEpic;
