import revertDictionary from '../../utils/revertDictionary';

const editResponseAnswerModal = {
  'editResponseAnswerModal.title': {
    en: 'Answer editor',
    ru: 'Редактор ответов'
  }
};

export default revertDictionary(editResponseAnswerModal);
