// @flow

import request from '../../../utils/request';
import { API } from '../../../utils/config';
import interpolateString from '../../helpers/interpolateString';

export default function removeUpload(uploadId: number): Promise<any> {
  return request({
    url: interpolateString(API.uploads.detail, { uploadId }),
    method: 'DELETE'
  }).toPromise();
}
