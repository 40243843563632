// @flow

import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { of, forkJoin } from 'rxjs';
import { toast } from 'react-toastify';
import { API } from 'utils/config';

import { projectNormalizer } from 'common/transducers/projects/projectsNormalizer';

import type { Epic } from 'flow-types/Epic';
import type { IProject } from 'flow-types/entities/Project';

import { ADMIN, COMPANY_ADMIN } from 'common/helpers/user';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { isPreparing } from 'common/helpers/project/getStatus';
import normalizeVisibilitySettingsList from 'common/transducers/projects/visibilitySettings/normalizer';
import { projectIdFromPathSelector } from '../../../selectors/projects';
import { pageStateSelector } from '../../../selectors';
import { authorizedUserRoleSelector } from '../../../selectors/auth';

const fetchProjectEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project/fetch'),
    RxOperators.withLatestFrom($state),
    RxOperators.switchMap(([action, state]) => {
      const page = pageStateSelector(state);
      const userRole = authorizedUserRoleSelector(state);

      const projectId = action.projectId
        ? action.projectId
        : projectIdFromPathSelector(state);

      const projectFetch$ = request({
        url: API.projects.detail.replace(':projectId', projectId),
        method: 'GET'
      });

      const projectResponsesVisibilitySettings$ = request({
        url: interpolateString(API.projects.userFieldsSettings.list, {
          projectId
        }),
        method: 'GET'
      });

      const projectChecksFetch$ = request({
        url: API.projects.checklists.replace(':projectId', projectId),
        query: {
          enabled: 1
        },
        method: 'GET'
      });

      const fetchers$ = forkJoin({
        project: projectFetch$,
        checklists: projectChecksFetch$,
        visibilitySettings: projectResponsesVisibilitySettings$
      });

      return fetchers$.pipe(
        RxOperators.map(result => {
          const { project, visibilitySettings, checklists } = result;

          const { response: checklistsResponse } = checklists;
          const { response: projectResponse } = project;
          const { response: visibilitySettingsResponse } = visibilitySettings;

          return {
            project: projectResponse.data,
            visibilitySettings: visibilitySettingsResponse.data,
            checklists: checklistsResponse.data
          };
        }),
        RxOperators.mergeMap(result => {
          const { project, visibilitySettings, checklists } = result;

          const { enabled: enabledChecklists } = checklists;

          const normalizedProject: IProject = projectNormalizer({
            ...project,
            checklists: enabledChecklists
          });

          // check whether user can access the page
          if (
            isPreparing(normalizedProject.status) &&
            page.name === 'Project' &&
            ![ADMIN, COMPANY_ADMIN].includes(userRole)
          ) {
            return [
              {
                type: 'router/projectsPage'
              },
              {
                type: 'project/fetch-fail',
                error: 403
              }
            ];
          }

          return [
            {
              type: 'project/fetch-visibility-settings-list-success',
              visibilitySettings: normalizeVisibilitySettingsList(
                visibilitySettings
              )
            },
            {
              type: 'project/fetch-success',
              project: normalizedProject
            },
            {
              type: 'project/fetch-search-settings-success',
              data: normalizedProject.searchSettings
            }
          ];
        }),
        RxOperators.catchError(({ response, message }: AjaxError) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'project/fetch-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

export default fetchProjectEpic;
