// @flow
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import pullAll from 'lodash/pullAll';
import { mergeAndConcat } from 'merge-anything';

import type { IFormattedLogicAction } from 'flow-types/entities/Logic';
import type { ILogicResults } from 'flow-types/entities/LogicResults';

import { GOTO_MODES, LOGIC_ACTION } from 'common/helpers/logic/constants';

const oppositeKeys = {
  visibleGroups: ['hiddenGroups', 'disabledGroups'],
  hiddenGroups: 'visibleGroups',
  disabledGroups: 'visibleGroups',
  visibleQuestions: ['hiddenQuestions', 'disabledQuestions'],
  hiddenQuestions: ['visibleQuestions'],
  disabledQuestions: ['visibleQuestions'],
  visibleOptions: ['hiddenOptions', 'disabledOptions'],
  hiddenOptions: 'visibleOptions',
  disabledOptions: 'visibleOptions'
};

export const processIntersection = (
  currentResult: ILogicResults,
  nextResult: ILogicResults
) => {
  if (!nextResult) return currentResult;

  const currentResultCache = { ...(currentResult && currentResult) };
  const nextResultCache = { ...(nextResult && nextResult) };

  // remove opposite values from previous results
  forEach(nextResultCache, (value, key) => {
    const oppositeKey = oppositeKeys[key];
    if (Array.isArray(oppositeKey)) {
      forEach(oppositeKey, oppositeKeyItem => {
        pullAll(currentResultCache[oppositeKeyItem], value);
      });
    } else if (oppositeKey) {
      pullAll(currentResultCache[oppositeKey], value);
    }
  });

  return mergeAndConcat(currentResultCache, nextResultCache);
};

const targetsList = ['groupsIds', 'questionsIds', 'optionsIds'];

const logicResultsKey = {
  optionsIds: {
    visible: 'visibleOptions',
    hidden: 'hiddenOptions',
    disabled: 'disabledOptions'
  },
  questionsIds: {
    visible: 'visibleQuestions',
    hidden: 'hiddenQuestions',
    disabled: 'disabledQuestions',
    required: 'requiredQuestions'
  },
  groupsIds: {
    visible: 'visibleGroups',
    hidden: 'hiddenGroups',
    disabled: 'disabledGroups'
  }
};

function setTargetsStatus(
  targets,
  status: 'visible' | 'hidden' | 'disabled' | 'required' = 'visible'
) {
  return reduce(
    targetsList,
    (result, targetItem) => {
      if (!targets[targetItem]) return result;

      const logicResultKeyForTargetItem = logicResultsKey[targetItem][status];

      return {
        ...result,
        [logicResultKeyForTargetItem]: targets[targetItem]
      };
    },
    {}
  );
}

// analyzes action result, returning visible and hidden elements
export const logicRuleActionResultAnalyzer = (
  action: IFormattedLogicAction,
  passed
) => {
  const { targets, type, settings } = action;

  const { questionsIds = [] } = targets;

  if (type === LOGIC_ACTION.SHOW) {
    if (passed) {
      return setTargetsStatus(targets, 'visible');
    }

    const hasDisabledFlag = settings && settings.onlyDisable;

    return setTargetsStatus(targets, hasDisabledFlag ? 'disabled' : 'hidden');
  }

  if (type === LOGIC_ACTION.HIDE) {
    if (passed) {
      const hasDisabledFlag = settings && settings.onlyDisable;

      return setTargetsStatus(targets, hasDisabledFlag ? 'disabled' : 'hidden');
    }

    return setTargetsStatus(targets, 'visible');
  }

  if (type === LOGIC_ACTION.REQUIRE && passed) {
    return setTargetsStatus(targets, 'required');
  }

  if (type === LOGIC_ACTION.GOTO && passed) {
    let isBothSideGoto = true;

    if (typeof settings === 'object' && settings !== null) {
      isBothSideGoto = settings.gotoMode === GOTO_MODES.BOTH;
    }

    return {
      jumps: [[...questionsIds, isBothSideGoto]]
    };
  }

  return null;
};

// runs analysis for each action inside a rule to find visible and hidden groups
export const logicRuleActionsResultAnalyzer = (actions, passed) =>
  reduce(
    actions,
    (result, action) => {
      const actionResult = logicRuleActionResultAnalyzer(action, passed);

      return processIntersection(result, actionResult);
    },
    {}
  );

// run analyze for each rule and resolves intersections/conflicts
export const logicRulesResultsAnalyzer = rules =>
  reduce(
    rules,
    (result, rule) => {
      const actionsResult = logicRuleActionsResultAnalyzer(
        rule.actions,
        rule.passed
      );

      return processIntersection(result, actionsResult);
    },
    {}
  );
