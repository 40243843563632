// @flow
import * as React from 'react';
import isNil from 'lodash/isNil';
import type { IQuestion } from 'flow-types/entities/Question';
import { FormattedMessage } from 'react-intl';
// import { useSpring, animated as a } from 'react-spring';

type Props = {|
  question: IQuestion
|};

// as soon as component is mounted
// it will redirect "user" wherever url is set
// TODO: in theory we can transition user to one of our routes,
//  so...we would have to set 'localTransition' property to such questions
//  like 'URL' and 'Redirect'

const RedirectQuestionBody = ({ question }: Props) => {
  const [secondsLeft, setSecondsLeft] = React.useState(
    isNil(question.delay) ? 5 : question.delay
  );

  const latestSecondsLeft = React.useRef(secondsLeft);

  const timer = React.useRef(null);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      latestSecondsLeft.current -= 1;

      setSecondsLeft(latestSecondsLeft.current);

      if (latestSecondsLeft.current === 0) {
        clearInterval(timer.current);
        window.location.replace(question.description);
      }
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormattedMessage
        id="question.redirect.message"
        values={{ secondsLeft }}
        tagName="p"
      />
    </div>
  );
};

RedirectQuestionBody.defaultProps = {};

export default RedirectQuestionBody;
