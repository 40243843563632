// @flow

import { string } from 'yup';

export type ValidatorConfig = {
  emailTypeError: mixed,
  emailFormatError: mixed,
  emailRequiredError: mixed
};

type CreateValidatorFn = (params?: ValidatorConfig) => any;

// it will helper to create composite validators
export const createValidator: CreateValidatorFn = ({
  emailTypeError,
  emailFormatError
} = {}) => string(emailTypeError).email(emailFormatError);

const initialConfig = {
  emailTypeError: { messageId: 'Provided value is not a string' },
  emailFormatError: { messageId: 'Provided value is not a valid email' },
  emailRequiredError: { messageId: 'Email is required' }
};

export default function validateEmail(
  value: string,
  config: Object = initialConfig
) {
  return createValidator(config)
    .required(config.emailRequiredError)
    .validateSync(value);
}
