// @flow

import * as React from 'react';
import type { OnChangeCb } from './flow';
import UnionOperatorSelector from './UnionOperatorSelector';
import type { IUnionOperator } from '../../../flow-types/entities/FilterSettings';

type Props = {
  data: IUnionOperator,
  onChange: OnChangeCb,
  index?: string,
  // TODO: describe
  validation?: Object,
  size?: string
};

export default function UnionOperatorForm({
  data,
  onChange,
  index,
  validation,
  size,
  ...props
}: Props) {
  const handleChange = React.useCallback(
    nextData => {
      if (!onChange) return;

      if (!index) return;

      onChange(index, nextData);
    },
    [index, onChange]
  );

  return (
    <div className="three column centered row">
      <div className="column">
        <UnionOperatorSelector
          {...props}
          size={size}
          onlyValue
          error={!!validation}
          value={data}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

UnionOperatorForm.defaultProps = {
  index: 0,
  validation: null,
  size: 'tiny'
};
