import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { EMPTY, of } from 'rxjs';
import { toast } from 'react-toastify';
import reduce from 'lodash/reduce';
import { v4 as uuid } from 'uuid';
import { API } from 'utils/config';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import isNewElement from 'common/helpers/isNewElement';
import interpolateString from 'common/helpers/interpolateString';
import {
  projectFinalDataSelector,
  projectGroupsStateSelector
} from '../../../selectors/projects';

// eslint-disable-next-line
let _toastIds = {};

const updateQuestionsOrderEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/update-questions-order'),
    RxOperators.withLatestFrom($state),
    RxOperators.filter(([, state]) => {
      const projectState = projectFinalDataSelector(state);
      return (
        !isNewElement(projectState) && typeof projectState.id !== 'undefined'
      );
    }),
    RxOperators.concatMap(([action, state]) => {
      const groupsState = projectGroupsStateSelector(state);

      const { questionGroupId, questions } = action;

      if (!questions) return EMPTY;

      const groupTitle = reduce(
        groupsState.data,
        (_title, group) => {
          if (!!_title || group.id !== questionGroupId) return _title;

          return group.title;
        },
        null
      );

      // groups from state are already ordered by front
      const orderedIds = questions.filter(q => !q.isNew).map(q => q.id);

      const toastId = uuid();

      _toastIds[toastId] = toast(
        `Changing questions order for "${groupTitle}" group`,
        {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 90000
        }
      );

      return request({
        url: interpolateString(API.questions.sort, {
          question_group_id: questionGroupId
        }),
        body: orderedIds,
        method: 'PUT'
      }).pipe(
        RxOperators.delay(250),
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map(data => {
          toast.update(_toastIds[toastId], {
            type: toast.TYPE.SUCCESS,
            render: 'Questions order has been changed',
            autoClose: 750,
            position: toast.POSITION.BOTTOM_CENTER,
            onClose: () => {
              delete _toastIds[toastId];
            }
          });
          return {
            type: 'project-groups/update-questions-order-success',
            data,
            questionGroupId
          };
        }),
        RxOperators.catchError(({ response, message, status }: AjaxError) => {
          toast.update(_toastIds[toastId], {
            type: toast.TYPE.ERROR,
            render:
              +status === 422
                ? 'Provided data is not acceptable (422)'
                : message,
            autoClose: 750,
            position: toast.POSITION.BOTTOM_CENTER,
            onClose: () => {
              delete _toastIds[toastId];
            }
          });
          return of({
            type: 'project-groups/update-questions-order-fail',
            error: response ? response.error : message,
            questionGroupId
          });
        })
      );
    })
  );

export default updateQuestionsOrderEpic;
