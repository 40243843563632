// @flow

import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { merge } from 'merge-anything';
import {
  DATE_FNS_FORMAT_DATE_ONLY,
  DATE_FNS_FORMAT_NO_SECONDS
} from 'utils/config';
import Field from '../Form/Field';
import Input from '../Input';
import Form from '../Form';
import DatePicker from '../DatePicker';
import { useAnswer } from '../../contexts/AnswersContext';
import { useAnswerUpdate } from '../../contexts/AnswerUpdateContext';
import type { IInterviewQuestion } from '../../../flow-types/entities/Question';

type DateTimeBodyProps = {|
  question: IInterviewQuestion
|};

const CustomDateTimeInput = React.forwardRef((props, ref) => (
  <Input {...props} fluid ref={ref} />
));

CustomDateTimeInput.displayName = 'CustomDateTimeInput.ForwardRef';

const DateTimeQuestionBody = ({ question }: DateTimeBodyProps): React.Node => {
  const { isInterval, dateOnly, disabled } = question;

  const answer = useAnswer(question.id, { data: null });

  const onChange = useAnswerUpdate(question.id);

  const handleChange = React.useCallback(
    nextValue => {
      if (!onChange) return;

      onChange(merge({ ...answer }, { data: nextValue, errors: null }));
    },
    [answer, onChange]
  );

  const { data: value } = answer;

  const [startDate, setStartDate] = React.useState(null);

  // is used only if isInterval
  const [endDate, setEndDate] = React.useState(null);

  React.useEffect(() => {
    if (isInterval) {
      if (!isEqual(value, [startDate, endDate])) {
        if (Array.isArray(value)) {
          setStartDate(value ? value[0] : null);
          setEndDate(value ? value[1] : null);
        } else {
          setStartDate(null);
          setEndDate(null);
        }
      }
    } else if (!isEqual(value, startDate)) {
      setStartDate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onStartDateChange = val => {
    setStartDate(val);
    if (isInterval) {
      if (val === null && endDate === null) {
        handleChange(null);
        return;
      }
      handleChange([val, endDate]);
    } else {
      handleChange(val);
    }
  };

  const onEndDateChange = val => {
    setEndDate(val);
    if (val === null && startDate === null) {
      handleChange(null);
      return;
    }
    handleChange([startDate, val]);
  };

  return (
    <Form preventEventFlow>
      {isInterval && (
        <Field group wide="two">
          <Field>
            <DatePicker
              disabled={disabled}
              onChange={onStartDateChange}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              selectsStart
              locale="ru"
              disableReactDatepickerWidthOverride={dateOnly}
              showTimeSelect={!dateOnly}
              timeIntervals={5}
              dateFormat={
                dateOnly
                  ? DATE_FNS_FORMAT_DATE_ONLY
                  : DATE_FNS_FORMAT_NO_SECONDS
              }
              placeholderText="Выберите начальную дату"
              popperProps={{
                positionFixed: true
              }}
            />
          </Field>
          <Field>
            <DatePicker
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              onChange={onEndDateChange}
              selected={endDate}
              locale="ru"
              selectsEnd
              disableReactDatepickerWidthOverride={dateOnly}
              showTimeSelect={!dateOnly}
              timeIntervals={5}
              dateFormat={
                dateOnly
                  ? DATE_FNS_FORMAT_DATE_ONLY
                  : DATE_FNS_FORMAT_NO_SECONDS
              }
              placeholderText="Выберите конечную дату"
              popperProps={{
                positionFixed: true
              }}
            />
          </Field>
        </Field>
      )}
      {!isInterval && (
        <Field>
          <DatePicker
            disabled={disabled}
            onChange={onStartDateChange}
            selected={startDate}
            locale="ru"
            showTimeSelect={!dateOnly}
            timeIntervals={5}
            placeholderText="Выберите дату"
            disableReactDatepickerWidthOverride={dateOnly}
            dateFormat={
              dateOnly ? DATE_FNS_FORMAT_DATE_ONLY : DATE_FNS_FORMAT_NO_SECONDS
            }
            customInput={<CustomDateTimeInput />}
            popperProps={{
              positionFixed: true
            }}
          />
        </Field>
      )}
    </Form>
  );
};

export default DateTimeQuestionBody;
