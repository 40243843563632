// @flow
import { createSelector } from 'reselect';

import type { AuthState, AuthState$User } from 'flow-types/states/AuthState';
import type { StateSelector } from 'flow-types/Selector';

import { authStateSelector } from './index';

export const authorizedUserSelector: Function = createSelector(
  authStateSelector,
  (auth: AuthState): AuthState$User => auth.user
);

export const createAuthorizedUserRoleSelector = (): StateSelector<> =>
  createSelector([authorizedUserSelector], (user: AuthState$User):
    | null
    | number => {
    if (!user) return null;

    return user.roleId;
  });

/**
 * @deprecated use createAuthorizedUserRoleSelector
 * @type {OutputSelector<unknown, unknown, (res: unknown) => unknown>}
 */
export const authorizedUserRoleSelector: Function = createAuthorizedUserRoleSelector();
