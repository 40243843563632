// @flow
import * as React from 'react';
import type { VariablesState } from '../../../flow-types/Variable';

export const VariablesContext: React.Context<VariablesState> = React.createContext(
  {
    values: {},
    types: {}
  }
);

export const useVariables = (): VariablesState =>
  React.useContext(VariablesContext);
