// @flow

import * as React from 'react';
import { useTransition } from 'react-spring';
import cx from 'classnames';

import type { NilValue } from 'flow-types/NilValue';
import type { ReactChildren } from 'flow-types/ReactChildren';

import type { $ObjOfType } from 'flow-types/ObjOfType';
import useUniqueId from 'common/hooks/useUniqId';
import { Container } from './styled';

export type DimmerProps = {|
  className?: string,
  /**
   * A dimmer can contain miltiple modals
   */
  modals?: boolean,
  /**
   * A dimmer can be displayed at body level and this should be enabled in this case.
   */
  page?: boolean,
  /**
   * A dimmer can be visible.
   * It works only when Dimmer is located directly inside a container having '.dimmable.dimmed' class.
   */
  visible?: boolean,
  /**
   * A dimmer can be active. It work as visible, but everywhere..
   */
  active?: boolean,
  /**
   * A dimmer can be 'fixed' positioned
   */
  fixed?: boolean,
  /**
   * A dimmer can contain scrolling modal, that is higher than viewport
   */
  scrolling?: boolean,
  inverted?: boolean,
  aligned?: null | false | 'top' | 'bottom',
  side?: NilValue | 'top' | 'center' | 'bottom',
  // we do not want to have default props here
  // eslint-disable-next-line react/require-default-props
  animationRef?: React.Ref<any>,
  onClick?: Function,
  children?: ReactChildren,
  // TODO: temp prop, make up something better,
  //  for example getAnimationProps callback
  isStatic?: boolean,
  immediate?: boolean
|};

// region BodyRootProvider

// Данную штуку нужно вынести в отдельный провайдер управления свойствами body тэга.
// Так уж случилось, что Fomantic/Semantic, как удобнее, в некоторых случаях уж требует,
// чтобы у body были определённые классы.
// Но проблема в том, что если эта штука будет только здесь, то может быть такая ситуация,
// когда какой-нибудь другой компонент захочет что-то в body добавить
// и будет тогда не очень хорошо, так как будут конфликты.
// Поэтому нужно будет создать какой-то центральный Provider,
// который будет шарить контекст для управления стилями
// и другими штуками над body тэгом.
// TODO: вынести данный регион комментария в отдельный контекст-провайдер
const requestedParams: $ObjOfType<{
  pushable?: boolean,
  animating?: boolean,
  in?: boolean,
  dimmable?: boolean,
  dimmed?: boolean
}> = {};

function recomposeBody() {
  let classes = {};

  const latestRequestedParams = { ...requestedParams };

  Object.keys(latestRequestedParams).forEach(elementId => {
    classes = {
      ...classes,
      ...latestRequestedParams[elementId]
    };
  });

  classes = cx(classes);

  if (!document.body) return;

  document.body.className = classes ?? '';
}

// endregion

export const DIMMER_ANIMATION_DURATION = 300;

// This component is a complete rework of an existing components/Modal/Dimmer
// TODO: add different animations
export default function Dimmer({
  className,
  modals,
  page,
  visible,
  active,
  fixed,
  scrolling,
  inverted,
  aligned,
  side,
  children,
  onClick,
  animationRef,
  immediate,
  // TODO: temp prop, make up something better,
  //  for example getAnimationProps callback
  isStatic,
  ...rest
}: DimmerProps): React.Node {
  const id = useUniqueId();

  const transitions = useTransition(visible || active ? ['dimmer'] : [], {
    ref: animationRef,
    key: item => item,
    opacity: 0,
    display: 'flex',
    from: () => ({
      opacity: isStatic ? 0.9 : 0,
      immediate
    }),
    enter: () => ({
      opacity: 1,
      immediate
    }),
    leave: () => ({
      opacity: isStatic ? 0.9 : 0,
      immediate
    }),
    config: { duration: DIMMER_ANIMATION_DURATION },
    onRest({ finished }) {
      if (finished && !visible && requestedParams[id]) {
        delete requestedParams[id];

        recomposeBody();
      }
    }
  });

  React.useLayoutEffect(() => {
    if (page) {
      if (visible) {
        requestedParams[id] = {
          dimmable: true,
          scrolling,
          dimmed: true
        };
      }

      recomposeBody();
    } else if (requestedParams[id]) {
      delete requestedParams[id];
      recomposeBody();
    }
  }, [active, id, page, scrolling, visible]);

  React.useLayoutEffect(() => {
    const latestId = id;

    return () => {
      if (requestedParams[latestId]) {
        delete requestedParams[latestId];

        recomposeBody();
      }
    };
  }, [id]);

  return (
    <>
      {transitions(styles => (
        <Container
          {...rest}
          style={styles}
          className={cx(
            'ui',
            {
              top: aligned === 'top',
              bottom: aligned === 'bottom',
              aligned,
              fixed
            },
            side,
            'dimmer',
            className,
            {
              inverted,
              modals,
              page,
              visible,
              active
            }
          )}
          onClick={onClick}
        >
          {children}
        </Container>
      ))}
    </>
  );
}

Dimmer.defaultProps = {
  className: '',
  aligned: false,
  side: null,
  modals: false,
  page: false,
  visible: false,
  active: false,
  fixed: false,
  scrolling: false,
  inverted: false,
  onClick: null,
  children: null,
  immediate: false,
  isStatic: false
};
