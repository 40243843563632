const quickImportFormDictionary = {
  en: {
    'projects.tabs.interview.groups.quickImportModal.type': 'Import type',
    'projects.tabs.interview.groups.quickImportModal.list': 'Import list'
  },
  ru: {
    'projects.tabs.interview.groups.quickImportModal.type': 'Тип импорта',
    'projects.tabs.interview.groups.quickImportModal.list': 'Список для импорта'
  }
};

export default quickImportFormDictionary;
