// @flow

import includes from 'lodash/includes';
import map from 'lodash/map';

export function hideItemsBy(
  collection: Object[],
  targetProperty: string | ((Object, Array<number | string>) => boolean),
  hiddenItemsPropertyValues: Array<number | string>
): Object[] {
  return map(collection, item => {
    if (typeof targetProperty === 'function') {
      if (targetProperty(item, hiddenItemsPropertyValues)) {
        return {
          ...item,
          hidden: true
        };
      }
    } else if (includes(hiddenItemsPropertyValues, item[targetProperty])) {
      return {
        ...item,
        hidden: true
      };
    }

    return item;
  });
}

/**
 * Function goes through given items list and if ID of an item exists in identifiers list,
 * then 'hidden: true' is added
 */
export default function hideItemsById(
  items: Object[],
  disabledIds: number[]
): Object[] {
  return map(items, item => {
    if (includes(disabledIds, item.id) || includes(disabledIds, `${item.id}`)) {
      return {
        ...item,
        hidden: true
      };
    }

    return item;
  });
}
