import revertDictionary from 'utils/revertDictionary';

export const uploadDecodingsDictionary = revertDictionary({
  'transcriptions.title': {
    ru: 'Транскрипции',
    en: 'Transcriptions'
  },
  'transcriptions.controls.simulateLive': {
    ru: 'Симуляция разговора',
    en: 'Live conversation simulation'
  },
  'transcriptions.controls.stickToBottom': {
    ru: 'Прокручивать к последнему сообщению',
    en: 'Stick scroll cursor to last message'
  },
  'transcriptions.controls.highlight': {
    ru: 'Подсвечивать текущую фразу',
    en: 'Highlight currently spoken phrase'
  },
  'transcriptions.actions.updateSides': {
    ru: 'Обновить данные собеседников',
    en: 'Update conversation participants'
  },
  'transcriptions.actions.decode': {
    ru: 'Дешифровать',
    en: 'Decode'
  },
  'transcriptions.actions.refresh': {
    ru: 'Обновить список',
    en: 'Refresh'
  },
  'transcriptions.actions.createRegion': {
    ru: 'Создать регион',
    en: 'Create region'
  },
  'transcription.providers.1': {
    ru: 'Яндекс',
    en: 'Yandex'
  },
  'transcription.providers.2': {
    ru: 'Google',
    en: 'Google'
  },
  'transcription.decodingTask.status.unregistered': {
    ru: 'Регистрация запроса на дешифровку',
    en: 'Registering'
  },
  'transcription.decodingTask.status.new': {
    ru: 'В очереди на дешифровку',
    en: 'In queue'
  },
  'transcription.decodingTask.status.inProgress': {
    ru: 'В процессе дешифровки',
    en: 'In progress'
  },
  'transcription.decodingTask.status.ready': {
    ru: 'Дешифровка успешно завершена',
    en: 'Processing has completed'
  },
  'transcription.decodingTask.status.error': {
    ru: 'Во время дешифровки произошла ошибка',
    en: 'Processing has failed'
  },
  'transcription.isEmpty': {
    ru: `По данному источнику не было запрошено дешифровки. Для запроса, заполните форму ниже и нажмите кнопку "Дешифровать".`,
    en: `No tasks exist for the selected provider. To request processing fill in the form below and click "Decode" button.`
  },
  'transcription.decodingTask.message.error': {
    ru: 'Ошибка',
    en: 'Error'
  },
  'transcriptions.decodingTask.status.alreadyExists': {
    ru:
      'По записи {audio} уже имеется запрос на дешифровку через сервис {service}',
    en:
      'The task to process record {audio} via {service} service already exists'
  },
  'transcriptions.form.fields.speakerCount.hint': {
    ru: `*Если ограничивать количество участников не требуется, то оставьте поля пустыми`,
    en: '*In case there should be no limitations, just leave fields untouched'
  },
  'transcriptions.form.fields.offset.label': {
    ru: `Разобрать от`,
    en: 'Decode from'
  },
  'transcriptions.form.fields.speakerCount.label': {
    ru: `Допустимое количество участников`,
    en: 'Conversation participants limitation'
  },
  'transcriptions.form.fields.speakerCount.max.label': {
    ru: 'Максимум',
    en: 'Max'
  },
  'transcriptions.form.fields.speakerCount.min.label': {
    ru: 'Минимум',
    en: 'Min'
  },
  'transcriptions.messageForm.fields.body.label': {
    ru: 'Содержание',
    en: 'Content'
  },
  'transcriptions.messageForm.fields.side.label': {
    ru: 'Собеседник',
    en: 'Participant'
  },
  'transcriptions.messageForm.fields.side.placeholder': {
    ru: 'Кто автор сообщения?',
    en: 'Who is an author?'
  },
  'transcriptions.messageForm.fields.timeframesStart.label': {
    ru: 'Начальная точка',
    en: 'Start point'
  },
  'transcriptions.messageForm.fields.timeframesEnd.label': {
    ru: 'Конечная точка',
    en: 'End point'
  },
  'transcriptions.messageForm.actions.addMessage': {
    ru: 'Добавить сообщение',
    en: 'Add message'
  },
  'transcriptions.messageForm.actions.updateMessage': {
    ru: 'Обновить сообщение',
    en: 'Update message'
  },
  'transcriptions.sidesForm.actions.addSide': {
    ru: 'Добавить собеседника',
    en: 'Add participant'
  },
  'transcriptions.sidesForm.messages.confirmSideRemove': {
    ru: 'Удалить участника разговора?',
    en: 'Is participant should be removed?'
  },
  'transcriptions.sidesForm.validation.sidesLimit': {
    ru: 'Достигнут предел количества участников',
    en: 'Participants limit reached'
  },
  'transcriptions.sidesForm.title': {
    ru: 'Управление собеседниками',
    en: 'Conversation participants control'
  }
});
