import type { InterviewAction } from '../../flow-types/actions/interview';
import type { InterviewDirectionState } from '../../flow-types/states/InterviewState';

const initialState: InterviewDirectionState = null;

export default function activeStack(
  state = initialState,
  action: InterviewAction
): InterviewActiveStackState {
  switch (action.type) {
    case 'interview/move':
      return action.forward ? 'forward' : 'back';

    default:
      return state;
  }
}
