import { map as mapOp } from 'rxjs/operators';
import map from 'lodash/map';

/**
 * Special RxJs helper operator,
 * that helps to utilize some common selectors operations.
 *
 * Works only if project is array, i.e. it has to be used after rxjs 'withLatestFrom' operator.
 *
 * It will emit passed list of selectors over state and return selected results list
 * as the last element of a new source value.
 *
 * To each of given selectors,
 * state and action are passed as arguments in an appropriate order.
 *
 * @example
 * import * as RxO from 'rxjs/operators';
 * import { createSelector } from 'reselect';
 *
 * const totalMortgageSelector:Function = createSelector(
 *  mortgageAmountSelector,
 *  (_, { modifier }) => modifier,
 *  (mortgage, modifier) => mortgage * modifier
 * );
 *
 * return action$.pipe(
 *  // { type: 'request-mortgage', mortgage: 2500000 }
 *  ofType('request-mortgage'),
 *
 *  // action --> [action, state]
 *  RxO.withLatestFrom(state$),
 *
 *  // [action, state] --> [action, state, selected]
 *  withLatestSelectors([projectIdFromPathSelector, totalMortgageSelector]),
 *
 *  RxO.mergeMap(([action, state, selected]) => {
 *    const [projectId, totalMortgage] = selected;
 *
 *    return {...};
 *  }
 * )
 */
const withLatestSelectors = (selectors: Function[]) =>
  mapOp(project => {
    if (Array.isArray(project)) {
      const [p, state, ...rest] = project;

      const selectedData = map(selectors, selector => selector(state, p));

      return [p, state, ...rest, selectedData];
    }

    return project;
  });

export default withLatestSelectors;
