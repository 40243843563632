/* eslint-disable react/jsx-props-no-spreading */
// @flow

import * as React from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type { ReactChildren } from 'flow-types/ReactChildren';

type SegmentProps = {
  +className?: string,
  +placeholder?: boolean,
  +circular?: boolean,
  +raised?: boolean,
  +stacked?: boolean,
  +tall?: boolean,
  +piled?: boolean,
  +vertical?: boolean,
  +secondary?: boolean,
  +disabled?: boolean,
  +basic?: boolean,
  +loading?: boolean,
  +fitted?: boolean,
  +usual?: boolean,
  +clearing?: boolean,
  +double?: boolean,
  +inverted?: boolean,
  +compact?: boolean,
  +padded?: boolean,
  +nonUI?: boolean,
  +aligned?: 'right' | 'left' | 'center',
  +color?: 'secondary' | 'tertiary' | string,
  +floated?: 'left' | 'right' | boolean,
  +attached?: 'top' | 'bottom' | boolean,
  +tagName?: ReactComponent
};

const Segment: React.AbstractComponent<SegmentProps, mixed> = React.forwardRef(
  (
    {
      tagName: Component,
      nonUI,
      raised,
      className,
      placeholder,
      stacked,
      secondary,
      tall,
      piled,
      vertical,
      padded,
      color,
      disabled,
      loading,
      usual,
      double,
      inverted,
      fitted,
      attached,
      compact,
      circular,
      clearing,
      floated,
      aligned,
      basic,
      ...props
    }: SegmentProps,
    ref
  ) => {
    if (!Component) return null;

    return (
      <Component
        {...props}
        ref={ref}
        className={cx(
          { ui: !nonUI },
          color,
          {
            placeholder,
            basic,
            tall,
            stacked,
            piled,
            raised,
            vertical,
            secondary,
            disabled,
            padded,
            usual,
            double,
            loading,
            inverted,
            clearing,
            // $FlowIgnore
            [`${aligned} aligned`]: !!aligned,
            // $FlowIgnore
            ...(floated === 'left' && { left: true }),
            // $FlowIgnore
            ...(floated === 'right' && { right: true }),
            // $FlowIgnore
            ...(!!floated && { floated: true }),
            // $FlowIgnore
            ...(attached === 'top' && { top: true }),
            // $FlowIgnore
            ...(attached === 'bottom' && { bottom: true }),
            // $FlowIgnore
            ...(!!attached && { attached: true }),
            fitted,
            circular
          },
          className,
          'segment'
        )}
      />
    );
  }
);

Segment.displayName = 'Segment';

// $FlowIgnore
Segment.defaultProps = {
  tagName: 'div',
  nonUI: false,
  className: noop(),
  placeholder: noop(),
  circular: false,
  raised: false,
  stacked: false,
  basic: false,
  tall: false,
  piled: false,
  vertical: false,
  secondary: false,
  disabled: false,
  loading: false,
  fitted: false,
  usual: false,
  clearing: false,
  double: false,
  inverted: false,
  compact: false,
  padded: false,
  aligned: false,
  color: null,
  floated: false,
  attached: false
};

type SegmentListProps = {|
  className?: string,
  basic?: boolean,
  nonUI?: boolean,
  horizontal?: boolean,
  stackable?: boolean,
  raised?: boolean,
  compact?: boolean,
  stacked?: boolean,
  attached?: boolean | 'top' | 'bottom',
  tagName?: React.ComponentType<mixed>,
  piled?: boolean,
  children?: ReactChildren
|};

export const Segments: React.AbstractComponent<
  SegmentListProps,
  mixed
> = React.forwardRef(
  (
    {
      // eslint-disable-next-line react/prop-types
      nonUI,
      // eslint-disable-next-line react/prop-types
      className,
      // eslint-disable-next-line react/prop-types
      basic,
      // eslint-disable-next-line react/prop-types
      horizontal,
      // eslint-disable-next-line react/prop-types
      stackable,
      // eslint-disable-next-line react/prop-types
      compact,
      // eslint-disable-next-line react/prop-types
      raised,
      // eslint-disable-next-line react/prop-types
      stacked,
      // eslint-disable-next-line react/prop-types
      piled,
      // eslint-disable-next-line react/prop-types
      attached,
      // eslint-disable-next-line react/prop-types
      tagName: Component,
      ...props
    },
    ref
  ): React.Node => {
    if (!Component) return null;

    // $FlowIgnore
    return (
      <Component
        ref={ref}
        className={cx(
          { ui: !nonUI },
          {
            horizontal,
            stackable,
            basic,
            raised,
            stacked,
            piled,
            compact,
            top: attached === 'top',
            bottom: attached === 'bottom',
            attached
          },
          className,
          'segments'
        )}
        {...props}
      />
    );
  }
);

Segments.displayName = 'Segments.ForwardRef';

// $FlowFixMe
Segments.defaultProps = {
  nonUI: false,
  basic: false,
  horizontal: false,
  stackable: false,
  raised: false,
  compact: false,
  stacked: false,
  piled: false,
  attached: false,
  tagName: 'div',
  className: noop(),
  children: null
};

export default Segment;
