// @flow

import * as React from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Draggable, DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'merge-anything';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import fpGet from 'lodash/fp/get';

import { Tab, TabNavItem, TabsProvider } from 'common/contexts/TabsContext';

import List from 'common/components/List';
import ListItem from 'common/components/ListItem';
import Segment from 'common/components/Segment';
import Menu from 'common/components/Menu';
import DraggableList from 'common/containers/DraggableList';
import moveArrayAndReorder from 'common/helpers/moveArrayAndReorder';
import Label from 'common/components/Label';
import Icon from 'common/components/Icon';
import Link from 'common/components/Link';
import Flexbox from 'common/components/Flexbox';
import Modal from 'common/components/animated/Modal';
import Button from 'common/components/Button';
import useModal from 'common/hooks/useModal';

import type { Node } from 'react';
import type { DragEndResponse } from 'flow-types/DragEndResponse';
import type { ReactComponent } from 'flow-types/ReactComponent';
import type {
  IFieldVisibilitySettings$Unified,
  VisibilitySettings
} from 'flow-types/entities/VisibilitySettings';
import type { DimmerProps } from 'common/components/animated/Dimmer';
import type { Provided } from 'react-beautiful-dnd/src/view/droppable/droppable-types';

import { useUserRole } from 'common/containers/RoleGuard';
import VisibilitySettingsForm from './VisibilitySettingsForm';

import { isMutatingVisibilitySettings } from './selectors';
import BlockTitle from '../../QuestionTitle';
import ResponsePropertyLabel from '../../ResponsePropertyLabel';
import {
  ROLES_WHO_CAN_SEE_EXPERT,
  SYSTEM_COLUMNS
} from '../../Panels/ResponsesPanel/constants';

export const MODAL_ID = 'projectResponsesVisibilitySettings';

const dimmerProps: $Shape<DimmerProps> = {
  page: true
};

type VisibilitySettingsDraggableItemProps = {
  index: number,
  data: IFieldVisibilitySettings$Unified
};

function VisibilitySettingsDraggableItem({
  index,
  data
}: VisibilitySettingsDraggableItemProps) {
  return (
    <Draggable draggableId={`field-${data.id}`} index={index}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot;

        const node = (
          <ListItem ref={provided.innerRef} {...provided.draggableProps}>
            <Flexbox flex={1}>
              <Flexbox ignoreDisplay grow={1} alignSelf="center">
                <Label horizontal color="primary">
                  {index + 1}
                </Label>
                {data.type === 'block' ? (
                  <BlockTitle questionId={data.id} />
                ) : (
                  <ResponsePropertyLabel fieldName={data.id} />
                )}
              </Flexbox>
              <Button
                {...provided.dragHandleProps}
                noClickEvent
                tagName={Link}
                icon
                size="tiny"
                buttonType="tertiary"
                compact
              >
                <Icon icon="bars" />
              </Button>
            </Flexbox>
          </ListItem>
        );

        if (isDragging) {
          return createPortal(node, document.body);
        }

        return node;
      }}
    </Draggable>
  );
}

type VisibilitySettingsDraggableListProps = {
  data: IFieldVisibilitySettings$Unified,
  ItemComponent: ReactComponent,
  provided: Provided
};

function VisibilitySettingsDraggableList({
  data,
  ItemComponent,
  provided,
  ...rest
}: VisibilitySettingsDraggableListProps) {
  return (
    <List
      size="large"
      divided
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {map(data, (field, index) => (
        <ItemComponent
          {...rest}
          index={index}
          data={field}
          key={`field-${field.id}`}
        />
      ))}
      {provided.placeholder}
    </List>
  );
}

export default function VisibilitySettingsModal(): Node {
  const [modalState, , close, updateData] = useModal<null | VisibilitySettings>(
    MODAL_ID,
    {
      hideOnUnmount: true
    }
  );

  const dispatch = useDispatch();

  const isMutating = useSelector(isMutatingVisibilitySettings);

  const authUserRoleId = useUserRole();

  const handleSave = React.useCallback(() => {
    dispatch({
      type: 'project/save-visibility-settings',
      visibilitySettings: modalState.data
    });
  }, [dispatch, modalState]);

  const handleChange = React.useCallback(
    nextData => {
      updateData(nextData);
    },
    [updateData]
  );

  const visibleFields = React.useMemo(
    () =>
      sortBy(modalState.data?.fields, 'order')
        .filter(fpGet('isVisible'))
        .filter(field => {
          if (
            [SYSTEM_COLUMNS.EXPERT, SYSTEM_COLUMNS.EXPERT_ID].includes(field.id)
          ) {
            return ROLES_WHO_CAN_SEE_EXPERT.includes(authUserRoleId);
          }

          return true;
        }),
    [authUserRoleId, modalState.data]
  );

  const handleReorder = React.useCallback(
    (dragResponse: DragEndResponse) => {
      const { source, destination } = dragResponse;

      if (source.index === destination.index) return;

      const sorted = moveArrayAndReorder(
        sortBy(visibleFields, 'order'),
        source.index,
        destination.index
      );

      updateData(
        merge(modalState.data, {
          fields: keyBy(sorted, 'id')
        })
      );
    },
    [modalState.data, updateData, visibleFields]
  );

  return (
    <Modal
      size="small"
      dimmerProps={dimmerProps}
      portal
      onClose={() => close(true)}
      visible={modalState.visible}
    >
      <Modal.Header>
        <FormattedMessage id="projects.tabs.responses.visibilitySettingsModal.title" />
      </Modal.Header>
      <Modal.Content scrolling>
        <div className="ui grid">
          <div className="row">
            <div className="column">
              <TabsProvider initialActiveTab="form">
                <Menu secondary>
                  <TabNavItem eventKey="form">
                    <FormattedMessage id="project.responsesFieldsVisibilitySettingsModal.tabs.settingsForm.label" />
                  </TabNavItem>
                  <TabNavItem eventKey="order">
                    <FormattedMessage id="project.responsesFieldsVisibilitySettingsModal.tabs.orderForm.label" />
                  </TabNavItem>
                </Menu>
                <Segment>
                  <Tab eventKey="form">
                    <VisibilitySettingsForm
                      data={modalState.data}
                      onChange={handleChange}
                    />
                  </Tab>
                  <Tab eventKey="order">
                    <DragDropContext onDragEnd={handleReorder}>
                      <DraggableList
                        droppableId="fields"
                        ListComponent={VisibilitySettingsDraggableList}
                        ItemComponent={VisibilitySettingsDraggableItem}
                        data={visibleFields}
                      />
                    </DragDropContext>
                  </Tab>
                </Segment>
              </TabsProvider>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          buttonType="negative"
          onClick={() => close(true)}
          disabled={isMutating}
        >
          <FormattedMessage id="common.labels.cancel" />
        </Button>
        <Button
          buttonType="primary"
          loading={isMutating}
          disabled={isMutating}
          onClick={handleSave}
        >
          <FormattedMessage id="common.labels.submit" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
