import map from 'lodash/map';
import reduce from 'lodash/reduce';

export const flatMapTestGroup = group => {
  const { operator, tests, testGroups } = group;

  const combined = [];

  if (Array.isArray(tests)) {
    combined.push(...tests);
  }

  if (typeof testGroups !== 'undefined') {
    combined.push(...map(testGroups, g => flatMapTestGroup(g)));
  }

  return reduce(
    combined,
    (result, current, index) => {
      if (index + 1 !== combined.length) {
        return [...result, current, operator];
      }
      return [...result, current];
    },
    []
  );
};
