import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import Helmet from 'react-helmet';
import getBrowserLanguage from 'common/helpers/getBrowserLanguage';
import { messagesDictionary } from '../../intl';
import { languageStateSelector } from '../../selectors';

const AppIntlProvider = props => {
  const [locale, setLocale] = useState(getBrowserLanguage());

  const language = useSelector(languageStateSelector);

  useEffect(() => {
    const browserLocale = getBrowserLanguage();

    moment.locale(language || browserLocale);

    setLocale(language || browserLocale);
  }, [language]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <meta httpEquiv="Content-Language" content={locale} />
        <title>{locale === 'en' ? 'Formalist' : 'Формалист'}</title>
      </Helmet>
      <IntlProvider
        locale={locale}
        messages={messagesDictionary[locale]}
        {...props}
      />
    </>
  );
};

export const withAppIntlProvider = Component => props => (
  <AppIntlProvider>
    <Component {...props} />
  </AppIntlProvider>
);

export default AppIntlProvider;
