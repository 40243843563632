// @flow
import isFinite from 'lodash/isFinite';
import { TYPES } from 'common/helpers/question';
import type { IQuestion } from 'flow-types/entities/Question';

export const getValidationConfigForBlock = (question: IQuestion) => {
  const config = {
    type: question.type,
    required: question.required,
    messages: {}
  };

  if (config.required) {
    config.messages.required = { messageId: 'question.validation.required' };
  }

  // eslint-disable-next-line default-case
  switch (question.type) {
    case TYPES.SingleAnswer:
      config.messages.otherRequired = {
        messageId: 'question.validation.fields.otherOption.value.required',
        values: {
          otherTitle: question.otherTitle
        }
      };
      break;

    case TYPES.Checklist:
    case TYPES.MultipleAnswer:
      config.multi = true;

      if (question.maxAnswers && question.maxAnswers !== 0) {
        config.max = question.maxAnswers;
        config.messages.max = {
          messageId: 'question.validation.maxAnswers',
          values: {
            count: question.maxAnswers
          }
        };
      }

      if (question.minAnswers && question.minAnswers !== 0) {
        config.min = question.minAnswers;
        config.messages.min = {
          messageId: 'question.validation.minAnswers',
          values: {
            count: question.minAnswers
          }
        };
      }

      config.messages.otherRequired = {
        messageId: 'question.validation.fields.otherOption.value.required',
        values: {
          otherTitle: question.otherTitle
        }
      };
      break;

    case TYPES.Numeric:
      config.multi = question.type !== TYPES.Rating && question.isInterval;

      if (
        // undefined and null are replaced with false,
        // after that it is checked to be not equal to false
        (question.minValue ?? false) !== false &&
        isFinite(+question.minValue)
      ) {
        config.min = +question.minValue;
        config.messages.min = {
          messageId: 'question.validation.minValue',
          values: { minValue: +question.minValue }
        };
      }

      if (
        (question.minValue ?? false) !== false &&
        isFinite(+question.maxValue)
      ) {
        config.max = +question.maxValue;
        config.messages.max = {
          messageId: 'question.validation.maxValue',
          values: { maxValue: +question.maxValue }
        };
      }

      if (
        (question.scale ?? false) !== false &&
        isFinite(+question.scale) &&
        +question.scale >= 0
      ) {
        config.scale = +question.scale;

        config.messages.scale = {
          messageId: 'question.validation.scaleExceeded',
          values: { scale: +question.scale }
        };
      }
      break;

    case TYPES.DateTime:
      config.min = question.minValue;
      config.max = question.maxValue;
      config.multi = question.type !== TYPES.Rating && question.isInterval;

      config.messages.required = {
        messageId: 'question.validation.required'
      };

      config.messages.typeError = {
        messageId: 'question.validation.mustBeDate'
      };

      config.messages.bothValuesRequired = {
        messageId: 'question.validation.answers.all.mustBeIfOneExist'
      };
      break;

    case TYPES.MobilePhone:
      config.messages.incorrectFormat = {
        messageId: 'question.validation.phone.format'
      };
      break;

    case TYPES.Email:
      config.messages.incorrectFormat = {
        messageId: 'question.validation.email.format'
      };
      break;
  }

  return config;
};
