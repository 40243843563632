// @flow
import { createSelector } from 'reselect';
import type {
  AudioRecordsState,
  DecodingsState
} from 'flow-types/states/RecordsState';
import { recordsStateSelector } from '../index';

export const decodingsStateSelector: Function = createSelector(
  recordsStateSelector,
  (recordsState: AudioRecordsState) => recordsState.decodings
);

export function getTaskStatus(taskId: string): Function {
  return createSelector(
    decodingsStateSelector,
    (decodingsState: DecodingsState) => {
      const {
        tasks: { tasksMap, data }
      } = decodingsState;

      const {
        [taskId]: { uploadId, providerId, index }
      } = tasksMap;

      // numbers are valid things to be used as keys in objects
      // $FlowIgnore
      return data[uploadId].data[providerId][index].status;
    }
  );
}
