import fp from 'lodash/fp';
import values from 'lodash/values';

const mapFp = fp.map.convert({ cap: false });

// TODO: may be it needs flow typing for both state cases
const constructMap = fp.compose(
  fp.reduce(
    (result, item: Object): Object => ({
      ...result,
      [item.id]: {
        providerId: item.providerId,
        uploadId: item.uploadId,
        index: item.index
      }
    }),
    {}
  ),
  fp.flattenDeep,
  // each upload
  fp.map(
    // each upload/provider
    fp.map(
      // each upload/provider/decodings
      mapFp((item, index) => ({
        id: item.id,
        providerId: item.providerId,
        uploadId: item.uploadId,
        index
      }))
    )
  ),
  fp.map((data: Object) => {
    const { data: providers } = data;

    return values(providers);
  })
);

export default constructMap;
