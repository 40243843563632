// @flow
import size from 'lodash/size';
import fp from 'lodash/fp';
import reduce from 'lodash/reduce';
import values from 'lodash/values';
import { camelizeKeys } from 'humps';

import type {
  ILogic,
  ILogicAction,
  ILogicActionTarget,
  ILogicActionType
} from 'flow-types/entities/RawLogic';

import type { ITest } from 'common/containers/TestsForm/flow';
import {
  encapsulateBracketsIntoTests,
  replaceArrayWithBrackets
} from '../logic/logicStructureFormatter';
import { flatMapTestGroup } from '../logic/flatMapTestGroup';
import { toClientFormat as normalizeActionTargets } from './parseActionTargets';

export const convertToTest = (test): ITest => {
  // for group operators like AND, OR, that are located between tests
  if (typeof test === 'string') return test;

  return {
    id: test.id,
    options: test.options,
    source: test.questionId,
    openingBrackets: test.openingBrackets,
    closingBrackets: test.closingBrackets,
    operator: test.operator,
    unionType: test.unionType,
    settings: test.settings || {},
    value: test.value || null
  };
};

/**
 * Converts testGroups to plain tests array
 */
export const testGroupsNormalizer = fp.map(
  fp.compose(
    fp.map(convertToTest),
    encapsulateBracketsIntoTests,
    replaceArrayWithBrackets,
    flatMapTestGroup
  )
);

const logicActionTargetsNormalizer = (targets, actionType: ILogicActionType) =>
  reduce(
    targets,
    (result, target: ILogicActionTarget) => {
      const { instanceTable, ids } = target;

      if (instanceTable === 'question_groups') {
        return {
          ...result,
          groupsIds: values(ids)
        };
      }
      if (instanceTable === 'question_options') {
        return {
          ...result,
          optionsIds: values(ids)
        };
      }
      if (instanceTable === 'questions') {
        return {
          ...result,
          questionsIds: normalizeActionTargets(ids, actionType)
        };
      }

      return result;
    },
    {}
  );

const logicActionNormalizer = action => {
  const { targets, ...safeData }: ILogicAction = action;

  return {
    targets: logicActionTargetsNormalizer(targets, safeData.type),
    ...safeData
  };
};

const logicActionsNormalizer = fp.map(logicActionNormalizer);

const logicNormalizer = fp.compose(condition => {
  const { testGroups, actions, ...safeData }: ILogic = condition;

  const tests = testGroupsNormalizer(testGroups);

  return {
    ...safeData,
    actions: logicActionsNormalizer(actions),
    // in case we passed ev everything inside a single root group
    tests: size(tests) === 1 ? tests[0] : tests
  };
}, camelizeKeys);

export const logicRulesNormalizer = fp.map(logicNormalizer);

export default logicNormalizer;
