// @flow
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { AjaxError } from 'rxjs/ajax';
import request from '../../utils/request';
import responseParser from '../../common/epicHelpers/responseParser';
import { API } from '../../utils/config';
import interpolateString from '../../common/helpers/interpolateString';
import { normalizeUser } from '../../common/transducers/users/normalizer';

import type {
  FetchUser,
  FetchUserFail,
  FetchUserSuccess
} from '../../flow-types/actions/profile/FetchUser';
import type { AppState } from '../../flow-types/AppState';
import type { Epic } from '../../flow-types/Epic';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

const fetchUser: Epic = (action$, state$) =>
  action$.pipe(
    ofType('profile/fetch-user'),
    debounceEpic(),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([action]: [FetchUser, AppState]) => {
      const { userId } = action;

      return request({
        url: interpolateString(API.users.detail, { userId }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map((rawUser): FetchUserSuccess => ({
          type: 'profile/fetch-user-success',
          user: normalizeUser(rawUser)
        })),
        RxOperators.catchError((error: AjaxError): [FetchUserFail] => [
          {
            type: 'profile/fetch-user-fail',
            error: error.response
          }
        ])
      );
    })
  );

export default fetchUser;
