// @flow
import * as React from 'react';

import isMobile from 'is-mobile';
import isIOS from 'is-ios';

import Picker from 'react-datepicker';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import Input, { InputWrapper } from 'common/components/Input';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { languageStateSelector } from '../../selectors';

const Wrapper = styled.div`
  .react-datepicker-wrapper {
    min-width: 100%;

    .react-datepicker__input-container input {
      min-width: 100% !important;
    }
  }
  ${props => {
    if (props.disableReactDatepickerWidthOverride) return null;

    return css`
      .react-datepicker {
        min-width: 298px;
      }
    `;
  }}
`;

type Props = {
  className?: null | string,
  error?: null | boolean,
  fluid?: boolean,
  disableReactDatepickerWidthOverride?: boolean,
  placeholderText?: null | string,
  showTimeSelect?: boolean,
  selected?: string | typeof Date,
  // TODO: resolve later
  onChange?: Function,
  dateFormat?: null | string
};

const PICKER_POPPER_PROPS = {
  placement: 'bottom',
  modifiers: {
    preventOverflow: {
      enabled: false,
      escapeWithReference: false,
      boundariesElement: 'viewport'
    },
    flip: {
      enabled: true,
      boundariesElement: 'viewport'
    }
  }
};

const HIDDEN_NATIVE_PICKER_STYLES = {
  position: 'fixed',
  top: -999,
  left: -999
};

const parseValue = (value, dateFormat): string => {
  let parsedValue = value;

  if (parsedValue instanceof Date) {
    parsedValue = format(parsedValue, dateFormat);
  } else if (typeof parsedValue === 'string') {
    parsedValue = format(parseISO(parsedValue), dateFormat);
  }

  return (parsedValue: any);
};

const DatePicker: React.AbstractComponent<Props, any> = React.forwardRef(
  (
    {
      fluid,
      className,
      disableReactDatepickerWidthOverride,
      error,
      ...props
    }: Props,
    ref
  ) => {
    const dateInputRef = React.useRef<HTMLInputElement | null>(null);

    const language = useSelector(languageStateSelector);

    return (
      <InputWrapper
        fluid={fluid}
        tagName={Wrapper}
        disableReactDatepickerWidthOverride={
          disableReactDatepickerWidthOverride
        }
        className={className}
        error={error}
      >
        {isMobile({ featureDetect: true }) && isIOS ? (
          <>
            <Input
              onClick={() => {
                dateInputRef.current?.focus();
              }}
              value={parseValue(props.selected, props.dateFormat)}
              placeholder={props.placeholderText}
            />
            <Input
              ref={dateInputRef}
              style={HIDDEN_NATIVE_PICKER_STYLES}
              type={props.showTimeSelect ? 'datetime-local' : 'date'}
              value={props.selected}
              onChange={props.onChange}
              onlyValue
            />
          </>
        ) : (
          <Picker
            {...props}
            ref={ref}
            locale={language}
            popperProps={PICKER_POPPER_PROPS}
          />
        )}
      </InputWrapper>
    );
  }
);

// $FlowIgnore
DatePicker.defaultProps = {
  error: null,
  className: null,
  fluid: false,
  disableReactDatepickerWidthOverride: false,
  placeholderText: null,
  showTimeSelect: false,
  selected: null,
  onChange: null,
  dateFormat: null
};

export default DatePicker;
