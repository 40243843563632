// @flow
// eslint-disable-next-line import/order

import { NOT_FOUND, redirect } from 'redux-first-router';
import find from 'lodash/find';
import fp from 'lodash/fp';

import AppEnvironment from 'utils/AppEnvironment';

import type { Route } from 'flow-types/Route';
import type { AppDispatch } from 'flow-types/AppDispatch';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import type { OnBeforeChangeFn } from 'flow-types/router/OnBeforeChangeFn';
import type { PrepareInterviewFail } from 'flow-types/actions/interview/PrepareInterview';

import { authStateSelector } from '../selectors';

import accessGuard from '../common/routeGuards/hasAccessGuard';
import isAuthGuard from '../common/routeGuards/isAuthGuard';
import isUnknownGuard from '../common/routeGuards/isUnknownGuard';
import signInWithHashThunk from '../common/routeThunks/signInWithHash';
import signInWithTokenThunk from '../common/routeThunks/signInWithToken';
import signInWithPublicHashThunk from '../common/routeThunks/signInWithPublicHash';

import createSingOut from '../actions/auth/signOut';

import { INTERVIEW_PREPARE_FAIL_REASON } from '../reducers/interview/record';

import type {
  IAccessCloseError,
  IAccessExpiredError,
  INoHashError,
  INotExistError,
  INoUserDataError
} from '../common/routeThunks/signInWithHash';

const routesComposer = fp.reduce(
  (result, { routeEventKey, routeConfigObject }) => ({
    ...result,
    [routeEventKey]: routeConfigObject
  }),
  {}
);

export type Routes = { [key: string]: Route };

const isHomePageGuard: OnBeforeChangeFn = dispatch => {
  // eslint-disable-next-line no-use-before-define
  const defaultRoute = getInitialRoute();

  if (!defaultRoute) {
    dispatch(redirect({ type: 'router/projectsPage' }));
  } else {
    dispatch(redirect({ type: defaultRoute.routeKey }));
  }

  return true;
};

const onBeforeChangeGuardChainProcessor = guardChain => (...args) => {
  if (!Array.isArray(guardChain)) {
    return;
  }

  let currGuardIndex = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const guardFn = guardChain[currGuardIndex];

    if (typeof guardFn === 'function') {
      if (guardFn(...args)) {
        break;
      }
    }

    currGuardIndex += 1;

    if (currGuardIndex > guardChain.length) {
      break;
    }
  }
};

export const routesMap: {
  [key: string]: {
    routeEventKey: string,
    routeConfigObject: Route
  }
} = {
  LOGIN: {
    routeEventKey: 'router/loginPage',
    routeConfigObject: {
      // it will show Login component, that is exported from ./pages/components
      component: 'Login',
      config: {
        // this path will be shown in address bar
        // after corresponding action is dispatched
        path: '/login',
        thunk: (dispatch, getState) => {
          const state = getState();
          const { isLoggedIn } = authStateSelector(state);
          if (isLoggedIn === null) {
            dispatch(createSingOut({ manual: true }));
          }
        }
      },
      onBeforeChange: (...args) => {
        isUnknownGuard(...args);
      }
    }
  },
  PROJECTS: {
    routeEventKey: 'router/projectsPage',
    routeConfigObject: {
      component: 'Projects',
      initial: true,
      config: {
        path: '/projects',
        thunk: async (...args) => {
          await signInWithTokenThunk(...args);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  COMPANIES: {
    routeEventKey: 'router/companiesPage',
    routeConfigObject: {
      component: 'Companies',
      config: {
        path: '/companies',
        thunk: async (...args) => {
          await signInWithTokenThunk(...args);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  PROJECT: {
    routeEventKey: 'router/projectPage',
    routeConfigObject: {
      component: 'Project',
      config: {
        path: '/projects/:projectId',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  PROJECT_DRAFT: {
    routeEventKey: 'router/projectDraftPage',
    routeConfigObject: {
      component: 'Project',
      config: {
        path: '/projects/:projectId/draft',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  CHECKS: {
    routeEventKey: 'router/checksPage',
    routeConfigObject: {
      component: 'Checks',
      config: {
        path: '/checks',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  INTERVIEW: {
    routeEventKey: 'router/interviewPage',
    routeConfigObject: {
      component: 'Interview',
      config: {
        // TODO: interviewId is actually a projectId,
        //  it tells what project is used
        path: '/interviews/:interviewId',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  INTERVIEWS: {
    routeEventKey: 'router/interviewsPage',
    routeConfigObject: {
      component: 'Interview',
      config: {
        path: '/interviews',
        thunk: async (dispatch, getState, bag: Object) => {
          function failed(
            error: AjaxError<
              | INotExistError
              | IAccessCloseError
              | IAccessExpiredError
              | INoUserDataError
              | INoHashError
            >
          ) {
            const {
              response: { code, message }
            } = error;

            // TODO: fix FT
            // $FlowIgnore
            if (code === 410) {
              if (message === 'Access expired') {
                dispatch(
                  ({
                    type: 'interview/prepare-fail',
                    error: INTERVIEW_PREPARE_FAIL_REASON.ACCESS_EXPIRED
                  }: PrepareInterviewFail)
                );
              } else if (message === 'Access is closed') {
                dispatch(
                  ({
                    type: 'interview/prepare-fail',
                    error: INTERVIEW_PREPARE_FAIL_REASON.ACCESS_CLOSED
                  }: PrepareInterviewFail)
                );
              }
            } else {
              dispatch(redirect({ type: NOT_FOUND }));
            }
          }

          const query = bag.action?.meta?.query;

          if (query && query.publicHash) {
            await signInWithPublicHashThunk(
              dispatch,
              getState,
              bag,
              NOT_FOUND,
              'interviewsPage'
            );
          } else if (query && query.hash) {
            await signInWithHashThunk({
              dispatch,
              getState,
              bag,
              onError: failed,
              routeName: 'interviewsPage'
            });
          } else if (query && query.rid) {
            await signInWithTokenThunk(dispatch, getState);
          }
        }
      },
      onBeforeChange: (dispatch, getState, bag: Object) => {
        const {
          action: { meta }
        } = bag;
        if (
          !meta ||
          !meta.query ||
          (!meta.query.hash && !meta.query.rid && !meta.query.publicHash)
        ) {
          return dispatch(redirect({ type: NOT_FOUND }));
        }
        return false;
      }
    }
  },
  PROFILE: {
    routeEventKey: 'router/profilePage',
    routeConfigObject: {
      component: 'Profile',
      config: {
        path: '/profile/:userId?',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (
        dispatch: AppDispatch,
        getState: Function,
        bag: Object
      ) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(
          dispatch,
          getState,
          bag
        );
      }
    }
  },
  HOME: {
    routeEventKey: 'router/homePage',
    routeConfigObject: {
      component: null,
      config: {
        path: '/'
      },
      onBeforeChange: (...rest) => {
        isHomePageGuard(...rest);
      }
    }
  },
  MEETINGS: {
    routeEventKey: 'router/meetingsPage',
    routeConfigObject: {
      component: 'Meetings',
      config: {
        path: '/meetings',
        thunk: async (dispatch, getState) => {
          await signInWithTokenThunk(dispatch, getState);
        }
      },
      onBeforeChange: (...rest) => {
        onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(...rest);
      }
    }
  },
  RESTRICTED: {
    routeEventKey: 'router/restricted',
    routeConfigObject: {
      component: 'Restricted',
      public: true,
      config: {}
    }
  },
  ...(AppEnvironment.buildings && {
    BUILDINGS: {
      routeEventKey: 'router/buildingsPage',
      routeConfigObject: {
        component: 'Buildings',
        config: {
          path: '/buildings',
          thunk: async (...args) => {
            await signInWithTokenThunk(...args);
          }
        },
        onBeforeChange: (...rest) => {
          onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(
            ...rest
          );
        }
      }
    }
  }),
  ...(AppEnvironment.agitations && {
    AGITATIONS: {
      routeEventKey: 'router/agitationsPage',
      routeConfigObject: {
        component: 'Agitations',
        config: {
          path: '/agitations',
          thunk: async (...args) => {
            await signInWithTokenThunk(...args);
          }
        },
        onBeforeChange: (...rest) => {
          onBeforeChangeGuardChainProcessor([isAuthGuard, accessGuard])(
            ...rest
          );
        }
      }
    }
  }),
  NOT_FOUND: {
    routeEventKey: NOT_FOUND,
    routeConfigObject: {
      component: 'NotFound',
      public: true,
      ignoreAsRoute: true
    }
  }
};

export const routes: Routes = routesComposer(routesMap);

export function getInitialRoute(): { routeKey: string, route: Route } | null {
  const routesKeys = Object.keys(routes);

  const key = find(
    routesKeys,
    routeKey => !!routes[routeKey] && !!routes[routeKey].initial
  );

  if (!key) return null;

  const routeForKey = routes[key];

  if (!routeForKey) return null;

  return { routeKey: key, route: routeForKey };
}
