import type { ProjectLogicState } from 'flow-types/states/ProjectsState/detail';
import updateFilter from 'common/helpers/updateFilter';
import type { ProjectLogicAction } from 'flow-types/actions/projects/detail/logic';

const initialState: ProjectLogicState = {
  loading: false,
  data: [],
  filter: {
    sort: '+id'
  },
  pagination: {},
  form: {
    validation: null,
    visible: false,
    loading: false,
    removing: false,
    data: {}
  }
};

export default (
  state: ProjectLogicState = initialState,
  action: ProjectLogicAction
) => {
  switch (action.type) {
    case 'project/reset':
      return initialState;

    case 'project/delete-logic-rule':
      return {
        ...state,
        form: {
          ...state.form,
          removing: true
        }
      };

    case 'project/delete-logic-rule-fail':
      return {
        ...state,
        form: {
          ...state.form,
          removing: true
        }
      };

    case 'project/delete-logic-rule-success':
      return {
        ...state,
        form: initialState.form
      };

    case 'project/submit-logic-form':
      return {
        ...state,
        form: {
          ...state.form,
          loading: true
        }
      };

    case 'project/set-logic-form-validation':
      return {
        ...state,
        form: {
          ...state.form,
          validation: action.validation
        }
      };

    case 'project/submit-logic-form-fail':
      return {
        ...state,
        form: {
          ...state.form,
          loading: false
        }
      };

    case 'project/submit-logic-form-success':
      return {
        ...state,
        form: initialState.form
      };

    case 'project/close-logic-form':
      return {
        ...state,
        form: initialState.form
      };

    case 'project/open-logic-form':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.initialData
          },
          visible: true
        }
      };

    case 'project/update-logic-form':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          },
          validation: null
        }
      };

    case 'project/set-logic':
      return {
        ...state,
        data: action.logic
      };

    case 'project/fetch-logic':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          updater: action.filterUpdate,
          replacer: action.filter
        }),
        loading: true
      };

    case 'project/fetch-logic-success':
      return {
        ...state,
        data: action.logic,
        loading: false
      };

    case 'project/fetch-logic-fail':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
