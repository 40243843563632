// this validation messages appear when answering a question
import revertDictionary from 'utils/revertDictionary';

const questionValidationErrorsDictionary = {
  'question.validation.seemsLikeYouHaveProblemsWith': {
    en: 'It seems, like you have a problem in the following questions:',
    ru: 'Похоже, что у вас проблемы со следующими вопросами:'
  },
  'question.validation.maxAnswers': {
    en: `You can select no more, than {count, plural,
        =0 {# options}
        one {# option}
        many {# options}
    }`,
    ru: `В этом вопросе вы можете выбрать максимум {count, plural,
        =0 {0 ответов}
        one {# ответ}
        few {# ответа}
        many {# ответов}
    }`
  },
  'question.validation.minAnswers': {
    en: `You have to select no less, than {count, plural,
        =0 {# options}
        one {# option}
        many {# options}
    }`,
    ru: `В этом вопросе вы должны выбрать не менее, чем {count, plural,
        =0 {0 ответов}
        one {# ответ}
        few {# ответа}
        many {# ответов}
    }`
  },
  'question.validation.required': {
    en: 'You have to answer the question!',
    ru: 'Нужно обязательно дать ответ на этот вопрос!'
  },
  'question.validation.fields.otherOption.value.required': {
    en: 'You have to enter value in "{otherTitle}" field!',
    ru: 'Нужно обязательно указать своё значение в поле "{otherTitle}"!'
  },
  'question.validation.required.many': {
    en: 'You have to answer the following questions:',
    ru: 'Необходимо ответить на следующие вопросы:'
  },
  'question.validation.phone.format': {
    en: 'Entered value is not a valid mobile phone',
    ru: 'Введённое значение не соответствует мобильному телефону'
  },
  'question.validation.email.format': {
    en: 'Entered value is not a valid email',
    ru: 'Введённое значение не соответствует электронному адресу'
  },
  'question.validation.answers.all.mustBeIfOneExist': {
    en:
      'You should either fill all the fields of that question, or leave them empty',
    ru: 'Либо заполните все поля вопроса, либо оставьте их пустыми'
  },
  'question.validation.mustBeDate': {
    en: 'Entered value is not a valid date',
    ru: 'Введённое значение не соответствует формату даты'
  },
  'question.validation.outOfRange': {
    en: 'The input value is out of range',
    ru: 'Введенное значение находится вне допустимого диапазона'
  },
  'question.validation.invalidNumber': {
    en: 'Invalid numeric value',
    ru: 'Некорректное числовое значение'
  },
  'question.validation.scaleExceeded': {
    en: 'Value should not contain more than {scale} decimal digit(-s)',
    ru: 'Максимальное число знаков после запятой: {scale}'
  },
  'question.validation.maxValue': {
    en: 'Value should not be more than {maxValue}',
    ru: 'Значение не должно превышать {maxValue}'
  },
  'question.validation.minValue': {
    en: 'Value should not be less than {minValue}',
    ru: 'Значение не должно быть меньше {minValue}'
  },
  'question.validation.attachments.max': {
    en: 'Maximum file limit exceed (max. {count})',
    ru: 'Достигнут предел прикрепления файлов (макс. {count})'
  },
  'question.validation.attachments.min': {
    en: `You have to add at least {count, plural,
       one {# file}
       other {# files}
    }`,
    ru: `Требуется прикрепить как минимум {count, plural, 
       one {# файл}
       few {# файла}
       other {# файлов}
    }`
  }
};

export default revertDictionary(questionValidationErrorsDictionary);
