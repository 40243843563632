import { camelizeKeys, decamelizeKeys } from 'humps';
import fp from 'lodash/fp';

export const crmDataNormalizer = camelizeKeys;

export const crmQueryDenormalizer = fp.compose(decamelizeKeys, query => {
  const { id } = query;
  return {
    ...query,
    ...(!!id &&
      typeof id === 'string' && {
        id: +id
      })
  };
});
