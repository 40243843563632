const companiesDictionary = {
  en: {
    'companies.form': `
      {isEdit, select, 
        1 {Edit company}
        0 {Register company}
        other {Edit company}
      }
    `,
    'companies.form.upload_logo': 'Upload logo',
    'companies.form.upload_logo.placeholder': 'Select or drop file here',
    'companies.form.title': 'Title',
    'companies.form.title.placeholder': 'My new company',
    'companies.form.webpage': 'Webpage',
    'companies.form.webpage.placeholder': 'http://example.com',
    'companies.form.scope': 'Scope',
    'companies.form.scope.placeholder': 'IT, Space, etc...',
    'companies.form.description': 'Description',
    'companies.form.description.placeholder': 'This is my new company',
    'companies.actions.remove.message':
      'Are you definitely want to delete department?',
    'companies.list.noItemsPlaceholder': 'No companies are found'
  },
  ru: {
    'companies.form': `
      {isEdit, select, 
        true {Обновление компании}
        other {Регистрация компании}
      }
    `,
    'companies.form.upload_logo': 'Загрузить лого',
    'companies.form.upload_logo.placeholder':
      'Выберите файл или перенесите его в эту область',
    'companies.form.title': 'Заголовок',
    'companies.form.title.placeholder': 'Моя новая компания',
    'companies.form.webpage': 'Сайт',
    'companies.form.webpage.placeholder': 'http://example.com',
    'companies.form.scope': 'Специализация',
    'companies.form.scope.placeholder': 'ИТ, Космос и т.д...',
    'companies.form.description': 'Описание',
    'companies.form.description.placeholder': 'Это моя новая компания',
    'companies.actions.remove.message':
      'Вы действительно хотите удалить компанию?',
    'companies.list.noItemsPlaceholder': 'Данных по компаниям не найдено'
  }
};

export default companiesDictionary;
