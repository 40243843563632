// @flow

import { generateNewData } from 'common/helpers/generateNewData';
import { merge } from 'merge-anything';
import omit from 'lodash/omit';
import type { IPollingNotification } from 'flow-types/entities/PollingNotification';
import type { PollingNotificationsDetailState } from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import type { PollingNotificationsAction } from 'flow-types/actions/projects/detail/pollingNotifications';
import type { CreatePollingNotification } from 'flow-types/actions/projects/detail/pollingNotifications/detail/CreatePollingNotification';
import type {
  FetchPollingNotification,
  FetchPollingNotificationFail,
  FetchPollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/FetchPollingNotification';
import type { UpdatePollingNotification } from 'flow-types/actions/projects/detail/pollingNotifications/detail/UpdatePollingNotification';
import type {
  SavePollingNotification,
  SavePollingNotificationFail,
  SavePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/SavePollingNotification';
import type { $ActionType } from 'flow-types/ActionType';
import type { SetPollingNotificationValidation } from 'flow-types/actions/projects/detail/pollingNotifications/detail/SetPollingNotificationValidation';
import constructObjectMap from 'common/helpers/constructObjectMap';
import type {
  DeletePollingNotification,
  DeletePollingNotificationFail,
  DeletePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/DeletePollingNotification';
import type {
  TogglePollingNotification,
  TogglePollingNotificationFail,
  TogglePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/TogglePollingNotification';
import { MODAL_ID } from '../../../pages/Project/components/Modals/PollingNotificationModal';
import type { CloseModal } from '../../../pages/Modals/modals';

const createInitialState = (): PollingNotificationsDetailState => ({
  data: null,
  errors: null,
  status: null,
  validation: null
});

export const CREATE: $ActionType<CreatePollingNotification> =
  'project/create-polling-notification';

export const FETCH: $ActionType<FetchPollingNotification> =
  'project/fetch-polling-notification';

export const FETCH_FAIL: $ActionType<FetchPollingNotificationFail> =
  'project/fetch-polling-notification-fail';

export const FETCH_SUCCESS: $ActionType<FetchPollingNotificationSuccess> =
  'project/fetch-polling-notification-success';

export const UPDATE: $ActionType<UpdatePollingNotification> =
  'project/update-polling-notification';

export const SAVE: $ActionType<SavePollingNotification> =
  'project/save-polling-notification';

export const SAVE_FAIL: $ActionType<SavePollingNotificationFail> =
  'project/save-polling-notification-fail';

export const SAVE_SUCCESS: $ActionType<SavePollingNotificationSuccess> =
  'project/save-polling-notification-success';

export const SET_VALIDATION: $ActionType<SetPollingNotificationValidation> =
  'project/set-polling-notification-validation';

export const DELETE: $ActionType<DeletePollingNotification> =
  'project/delete-polling-notification';

export const DELETE_FAIL: $ActionType<DeletePollingNotificationFail> =
  'project/delete-polling-notification-fail';

export const DELETE_SUCCESS: $ActionType<DeletePollingNotificationSuccess> =
  'project/delete-polling-notification-success';

export const TOGGLE: $ActionType<TogglePollingNotification> =
  'project/toggle-polling-notification';

export const TOGGLE_FAIL: $ActionType<TogglePollingNotificationFail> =
  'project/toggle-polling-notification-fail';

export const TOGGLE_SUCCESS: $ActionType<TogglePollingNotificationSuccess> =
  'project/toggle-polling-notification-success';

export default function pollingNotificationReducer(
  state: PollingNotificationsDetailState = createInitialState(),
  action: PollingNotificationsAction | CloseModal
): PollingNotificationsDetailState {
  switch (action.type) {
    case 'modals/close':
      if (action.modalId === MODAL_ID) return createInitialState();
      return state;

    case TOGGLE:
      return {
        ...state,
        status: action.status === 1 ? 'deactivating' : 'activating'
      };

    case TOGGLE_FAIL:
      return {
        ...state,
        status: 'idle'
      };

    case TOGGLE_SUCCESS:
      return {
        ...state,
        status: 'idle',
        // $FlowIgnore
        data: {
          ...state.data,
          status: action.status
        }
      };

    case DELETE:
      return {
        ...state,
        status: 'deleting'
      };

    case DELETE_FAIL:
      return {
        ...state,
        status: 'deleteFail'
      };

    case DELETE_SUCCESS:
      return { ...state, status: 'deleted' };

    case SET_VALIDATION:
      return {
        ...state,
        validation: action.validation
      };

    case CREATE:
      return {
        ...state,
        data: generateNewData<IPollingNotification>({
          title: '',
          tests: [],
          channel: 'all',
          delayType: null,
          delayValue: null,
          questionIds: [],
          questionGroupIds: [],
          questionGroupsAccess: {},
          questionsAccess: {},
          config: {
            title: '',
            content: ''
          },
          status: null
        }),
        errors: null,
        status: 'idle'
      };

    case FETCH:
      return {
        ...state,
        status: 'loading'
      };

    case FETCH_FAIL:
      return {
        ...state,
        status: 'loadingFail'
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        status: 'idle',
        data: action.data
      };

    case UPDATE:
      return {
        ...state,
        data: merge(state.data, action.data),
        validation:
          typeof state.validation === 'object'
            ? omit(
                state.validation,
                constructObjectMap(action.data, { keepPaths: true })
              )
            : null
      };

    case SAVE:
      return {
        ...state,
        status: 'saving'
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        status: 'saved',
        data: action.data
      };

    case SAVE_FAIL:
      return {
        ...state,
        status: 'saveFail',
        errors: action.errors
      };

    default:
      return state;
  }
}
