import * as React from 'react';

export default function useWindowResize(callback) {
  const latestCallback = React.useRef(callback);
  const reactForTouches = React.useRef(false);

  React.useLayoutEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const handler = (...args) => latestCallback.current(...args);

    // TouchEnd does not allow us to understand how many touches
    // were moment before it has been ended, thus we have to use `touchmove` event
    // to catch the moment.
    const touchEndHandler = event => {
      if (reactForTouches.current) {
        reactForTouches.current = false;
        handler(event);
      }
    };

    function touchMoveHandler(event: TouchEvent) {
      // if touchMoveHandler worked once for a touchmove run, then ignore other calls,
      // for now handler should be emitted when touch will be ended.
      if (reactForTouches.current) return;
      reactForTouches.current = event.touches.length === 2;
    }

    // We listen to the resize event
    window.addEventListener('resize', handler, {
      passive: true
    });

    window.addEventListener('touchmove', touchMoveHandler, { passive: false });

    window.addEventListener('orientationchange', handler, {
      passive: true
    });

    window.addEventListener('touchend', touchEndHandler, {
      passive: false
    });

    handler();

    return () => {
      window.removeEventListener('touchmove', touchMoveHandler, {
        passive: false
      });

      // window.removeEventListener('touchstart', touchStartHandler, {
      //   passive: true
      // });
      //
      window.removeEventListener('orientationchange', handler, {
        passive: true
      });

      window.removeEventListener('resize', handler, {
        passive: true
      });

      window.removeEventListener('touchend', touchEndHandler, {
        passive: false
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
