// @flow

import type { ReorderQuestions } from '../../../flow-types/actions/projects/detail/structure/questions/ReorderQuestions';
import type { IQuestion } from '../../../flow-types/entities/Question';

export default function updateQuestionsOrderCreator(
  questionGroupId: number,
  questions: IQuestion[]
): ReorderQuestions {
  return {
    type: 'project-groups/update-questions-order',
    questionGroupId,
    questions
  };
}
