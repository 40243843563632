// @flow

import { camelize } from 'humps';
import { createSelector } from 'reselect';

import { TYPES } from 'common/helpers/question';

import type { AppState } from '../flow-types/AppState';

import { questionsListSelector } from './projects';

import type { CrmStateType } from '../crm/global_context/CrmState.type';

export const crmStateSelector = (state: AppState): CrmStateType => state.crm;

export const variableValuesSelector: Function = createSelector(
  crmStateSelector,
  (crm: CrmStateType) => crm.variableValues
);

export const searchBlocksSelector: Function = createSelector(
  crmStateSelector,
  (crm: CrmStateType) => crm.searchBlocks
);

export const searchBlocksOutputFieldsSelector: Function = createSelector(
  questionsListSelector,
  questions =>
    questions
      .filter(({ type }) => type === TYPES.SearchView)
      .flatMap(question => {
        const { outputFields, entity } = question.settings.dataSettings.query;
        return outputFields.map(fieldName => {
          const field = camelize(fieldName.split('.')[1]);
          const { tableName, title, fields } = entity;

          return {
            ...fields[field],
            tableName,
            entityTitle: title,
            name: fieldName,
            title: `${title} / ${fields[field].title}`
          };
        });
      })
);
