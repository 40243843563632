// @flow
import React, { useCallback, useMemo, useState } from 'react';
import { useHover } from 'react-use-gesture';
import range from 'lodash/range';
import isNil from 'lodash/isNil';
import cx from 'classnames';

import { getActiveColor } from 'common/components/Rating/utils';

import type { Node } from 'react';

import { Rate } from './Rate';

type RatingProps = {
  disabled?: boolean,
  value?: null | number,
  defaultValue?: null | number,
  min?: number,
  max?: number,
  icon?: string,
  onChange?: ((nextValue: number | null) => void | Promise<void>) | null,
  size?: null | string,
  labelled?: boolean,
  shouldResetOnSameCheck?: boolean,
  marks?: null | {
    [string]: {
      title: string
    }
  }
};

const Rating = ({
  value,
  min,
  max,
  icon,
  disabled,
  onChange,
  defaultValue,
  shouldResetOnSameCheck = true,
  size,
  labelled,
  marks
}: RatingProps): Node => {
  const [localValue, setLocalValue] = React.useState(defaultValue);

  const isControlled =
    typeof value !== 'undefined' && typeof onChange !== 'undefined';

  const rateValue = isControlled ? value : localValue;

  const valuesRange = useMemo(() => {
    if (isNil(min)) {
      return range(+max);
    }

    return range(+min, +max + 1);
  }, [max, min]);

  const [hoveredItemValue, setHoveredItemValue] = useState(null);

  const bind = useHover(
    ({ hovering, event }) => {
      if (hovering) {
        const hoveredValue = event.target?.dataset?.value;

        setHoveredItemValue(hoveredValue);
      } else {
        setHoveredItemValue(null);
      }
    },
    { enabled: !disabled }
  );

  const handleClick = useCallback(
    event => {
      if (disabled) return;

      const val = event.target?.dataset?.value || null;

      const nextVal = rateValue === +val && shouldResetOnSameCheck ? null : val;

      if (isControlled) {
        onChange?.(nextVal);
      } else {
        setLocalValue(nextVal);
      }
    },
    [disabled, isControlled, onChange, rateValue, shouldResetOnSameCheck]
  );

  return (
    <div className={cx('ui yellow', size, { disabled, labelled }, 'rating')}>
      {valuesRange.map(rate => {
        // TODO: I'd rather simplify that part to something more clear
        //  in a logic workflow
        const { original, active: isActive } = getActiveColor({
          rateValue: rate,
          currentValue: rateValue,
          marks
        });

        const { darken, active: isHovered } = getActiveColor({
          rateValue: rate,
          currentValue: hoveredItemValue,
          marks
        });

        const iconColor =
          !isNil(hoveredItemValue) && isHovered ? darken : original;

        return (
          <Rate
            key={rate}
            labelled={labelled}
            iconColor={iconColor}
            active={isActive}
            selected={isHovered}
            icon={icon}
            title={marks?.[rate]?.title || rate}
            data-value={rate}
            onClick={handleClick}
            {...bind()}
          />
        );
      })}
    </div>
  );
};

Rating.defaultProps = {
  disabled: false,
  value: undefined,
  defaultValue: null,
  min: 0,
  max: 1,
  icon: 'star',
  size: null,
  labelled: false,
  marks: null,
  onChange: null,
  shouldResetOnSameCheck: true
};

export default Rating;
