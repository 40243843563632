// @flow

import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { merge } from 'merge-anything';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

import Slider, { StyledTrackContainer } from 'common/components/Range';
import {
  parseValue,
  safeParseInt,
  supportsSliderViewType
} from 'common/components/Question/NumericQuestionBody/utils';
import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';

import type { Node } from 'react';
import type { IInterviewQuestion } from 'flow-types/entities/Question';

import Input from '../../Input';

type Props = {
  question: IInterviewQuestion
};

const Container = styled.div`
  ${StyledTrackContainer} {
    height: 6rem;
  }
`;

const getPassedValue = data => (([data || 0.0]: any): number[]);

export default function SingleNumeric({ question, ...rest }: Props): Node {
  const ref = useRef<any>(null);

  const intl = useIntl();

  const answer = useAnswer(question.id);

  const onChange = useAnswerUpdate(question.id);

  const { data } = answer;

  const { disabled } = question;

  const messages = useMemo(
    () => ({
      placeholder: intl.formatMessage({
        id: 'questionForm.fields.answer.placeholder'
      })
    }),
    [intl]
  );

  const handleNumericAnswer = useCallback(
    value => {
      const data1 = parseValue(value);

      if (ref.current) {
        /**
         * В случае рассинхрона значений, когда у нас 112, а в поле написано 0112,
         * то через референс мы правим это значение на наше.
         */
        if (ref.current.value !== data1) {
          ref.current.value = data1;
        }
      }

      onChange(
        merge(
          { ...answer },
          {
            // TODO: fix parseValue to be more clear
            // $FlowIgnore
            data: data1.length === 0 ? '' : data1,
            errors: null
          }
        )
      );
    },
    [answer, onChange]
  );

  if (question.viewType === 'slider' && supportsSliderViewType(question)) {
    return (
      <Container>
        <Slider
          values={getPassedValue(data)}
          min={
            question.minValue !== null ? safeParseInt(question.minValue, 0) : 0
          }
          max={
            question.maxValue !== null ? safeParseInt(question.maxValue, 1) : 0
          }
          onChange={handleNumericAnswer}
          disabled={!!disabled}
          step={1}
        />
      </Container>
    );
  }

  return (
    <Input
      {...rest}
      ref={ref}
      name="numeric_answer"
      disabled={disabled}
      fluid
      type="number"
      min={isNil(question.minValue) ? null : safeParseInt(question.minValue)}
      max={isNil(question.maxValue) ? null : safeParseInt(question.maxValue)}
      step={1}
      placeholder={messages.placeholder}
      value={isNil(data) ? '' : safeParseInt(data, 0)}
      onlyValue
      onChange={handleNumericAnswer}
    />
  );
}
