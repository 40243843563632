import revertDictionary from 'utils/revertDictionary';
import composeDictionaries from 'utils/composeDictionaries';
import buildingsWavesTableDictionary from './BuildingsWavesTable/dictionary';
import wavesFormTableDictionary from './WaveForm/dictionary';
import wavesGenerationFormTableDictionary from './WavesGeneratorForm/dictionary';
import wavesListDictionary from './WavesList/dictionary';

const dictionary = {
  'agitations.header.title': {
    ru: 'Агитации',
    en: 'Agitations'
  },
  'agitations.wavesGeneration.taskCompleted': {
    ru: 'Встречи по волне "{wave}" успешно сгенерированы',
    en: 'Meetings for "{wave}" wave have been successfully generated'
  },
  'agitations.wavesGeneration.taskFailed': {
    ru: 'Во время генерации встреч по волне "{wave}" произошла ошибка',
    en: 'An error occurred while generating meetings for "{wave}" wave'
  }
};

export default revertDictionary(
  composeDictionaries(
    wavesFormTableDictionary,
    wavesGenerationFormTableDictionary,
    buildingsWavesTableDictionary,
    wavesListDictionary,
    dictionary
  )
);
