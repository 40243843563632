import { EMPTY, merge, of } from 'rxjs';
import * as RxOp from 'rxjs/operators';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import { ofType } from 'redux-observable';
import Alert from 'common/components/Alert';
import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import { languageStateSelector } from '../../../../selectors';
import {
  projectGroupsStateSelector,
  selectedGroupDataSelector,
  selectedQuestionDataSelector
} from '../../../../selectors/projects';

import labelsDictionary from '../../../../intl/common/labelsDictionary';
import { isGroupUpdated, isQuestionUpdated } from '../../utils';

const unsavedChangesCheckProcess = (action$, state$) =>
  of({}).pipe(
    RxOp.withLatestFrom(state$),
    RxOp.mergeMap(([, state]) => {
      const success$ = of({
        type: 'project-groups/unsaved-changes-check-success'
      });

      const fail$ = of({
        type: 'project-groups/unsaved-changes-check-fail'
      });

      const {
        data,
        selectedGroupId,
        visibleSelected,
        selectedQuestionId
      }: ProjectGroupsState = projectGroupsStateSelector(state);

      const language = languageStateSelector(state);

      const checkedElement = reduce(
        data,
        (_result, group) => {
          if (_result) return _result;

          if (visibleSelected === 'group' && group.id === selectedGroupId) {
            return group;
          }

          return find(group.questions, q => q.id === selectedQuestionId);
        },
        null
      );

      if (!checkedElement) return success$;

      const { cache, ...updated } = checkedElement;

      if (
        (visibleSelected === 'group' && isGroupUpdated(cache, updated)) ||
        (visibleSelected === 'question' && isQuestionUpdated(cache, updated))
      ) {
        return fail$.pipe(
          RxOp.mergeMap(
            () =>
              new Promise(resolve => {
                const unsavedChangesMsg =
                  labelsDictionary[language]['common.labels.hasUnsavedChanges'];

                const doSaveMsg =
                  labelsDictionary[language]['common.labels.save'];

                Alert({
                  title: `${unsavedChangesMsg} ${doSaveMsg}?`,
                  onClose: () => {
                    resolve('exit');
                  },
                  actions: [
                    {
                      label:
                        labelsDictionary[language]['common.labels.saveAndExit'],
                      buttonType: 'primary',
                      onClick: () => {
                        resolve('save-exit');
                      }
                    },
                    {
                      label:
                        labelsDictionary[language][
                          'common.labels.exitWithoutSave'
                        ],
                      buttonType: 'primary',
                      onClick: () => {
                        resolve('exit');
                      }
                    },
                    {
                      label: labelsDictionary[language]['common.labels.cancel'],
                      buttonType: 'negative',
                      onClick: () => {
                        resolve('cancel');
                      }
                    }
                  ]
                });
              })
          ),
          RxOp.mergeMap((desiredAction: 'cancel' | 'save-exit' | 'exit') => {
            if (desiredAction === 'cancel') return fail$;

            if (desiredAction === 'exit') {
              let reset$ = EMPTY;

              if (visibleSelected === 'group') {
                reset$ = of({
                  type: 'project-groups/reset-group',
                  questionGroupId: selectedGroupId
                });
              }

              if (visibleSelected === 'question') {
                reset$ = of({
                  type: 'project-groups/reset-question',
                  questionId: selectedQuestionId
                });
              }

              // В случае, если выбран новый блок, то блок будет удалён после reset'а,
              // а так как он ещё и выбран, то получим ошибку чтения данных. Таким образом, сперва запускаем success$,
              // после него сразу же отработает селект нового блока или группы и уже после этого отработает ресет предыдущего элемента.
              return merge(success$, reset$);
            }

            if (desiredAction === 'save-exit') {
              const selectedQuestion = selectedQuestionDataSelector(state);
              const selectedGroup = selectedGroupDataSelector(state);

              let save$ = null;
              let saveSuccess$ = null;
              let saveFail$ = null;

              if (visibleSelected === 'group') {
                save$ = of({
                  type: 'project-groups/save-group',
                  group: selectedGroup
                });

                saveFail$ = action$.pipe(
                  ofType('project-groups/save-group-fail'),
                  RxOp.mergeMap(() => fail$),
                  RxOp.take(1),
                  RxOp.takeUntil(
                    action$.pipe(ofType('project-groups/save-group-success'))
                  )
                );

                saveSuccess$ = action$.pipe(
                  ofType('project-groups/save-group-success'),
                  RxOp.mergeMap(() => success$),
                  RxOp.take(1),
                  RxOp.takeUntil(saveFail$)
                );
              }

              if (visibleSelected === 'question') {
                save$ = of({
                  type: 'project-groups/save-question',
                  question: selectedQuestion
                });

                saveFail$ = action$.pipe(
                  ofType('project-groups/save-question-fail'),
                  RxOp.mergeMap(() => fail$),
                  RxOp.take(1),
                  RxOp.takeUntil(
                    action$.pipe(ofType('project-groups/save-question-success'))
                  )
                );

                saveSuccess$ = action$.pipe(
                  ofType('project-groups/save-question-success'),
                  RxOp.mergeMap(() => success$),
                  RxOp.take(1),
                  RxOp.takeUntil(saveFail$)
                );
              }

              return merge(saveFail$, saveSuccess$, save$);
            }

            return fail$;
          })
        );
      }

      return success$;
    })
  );

export default unsavedChangesCheckProcess;
