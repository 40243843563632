import fp from 'lodash/fp';
import { decamelizeKeys } from 'humps';
import type { SaveVisibilitySettings } from 'flow-types/actions/projects/detail/visibilitySettings/SaveVisibilitySettings';
import type {
  FieldVisibilitySettings,
  IFieldVisibilitySettings$Unified,
  IVisibilitySettings$Unified,
  VisibilitySettings
} from 'flow-types/entities/VisibilitySettings';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import values from 'lodash/values';
import { SYSTEM_COLUMNS } from '../../../../pages/Project/components/Panels/ResponsesPanel/constants';

export const filterDataGeneratorMixins = (
  visibilitySettings: SaveVisibilitySettings
) => {
  const { isNew, id, ...safeData } = visibilitySettings;

  if (isNew) return safeData;

  return visibilitySettings;
};

export const denormalizeVisibilitySettingsBackground = (
  visibilitySettings: VisibilitySettings
) => {
  const { fields, ...safeData } = visibilitySettings;
  return {
    ...safeData,
    fields: map(fields, (field: FieldVisibilitySettings) => {
      const fieldSettings = {
        ...field
      };

      if (isNil(fieldSettings.background)) {
        fieldSettings.background = '#fff';
      } else if (typeof fieldSettings.background === 'object') {
        fieldSettings.background = fieldSettings.background.hex;
      }

      return fieldSettings;
    })
  };
};

export const denormalizeToBackendFormat = (
  fieldSettings: IFieldVisibilitySettings$Unified
): FieldVisibilitySettings => {
  const { id, type, ...rest } = fieldSettings;

  if (type === 'block') {
    return {
      questionId: id,
      ...rest
    };
  }

  return {
    fieldName: id,
    ...rest
  };
};

export const denormalizeFields = (
  visibilitySettings: IVisibilitySettings$Unified
) => ({
  ...visibilitySettings,
  fields: fp.map(denormalizeToBackendFormat)(visibilitySettings.fields)
});

export const filterVisibleFields = (visibilitySettings: VisibilitySettings) => {
  const { fields, ...safeData } = visibilitySettings;

  return {
    ...safeData,
    fields: filter(fields, (field: IFieldVisibilitySettings$Unified) => {
      if (values(SYSTEM_COLUMNS).includes(field.id)) return true;

      return field.isVisible;
    })
  };
};

export const denormalizeVisibilitySettings = fp.compose(
  decamelizeKeys,
  filterDataGeneratorMixins,
  denormalizeVisibilitySettingsBackground,
  denormalizeFields,
  filterVisibleFields
);
