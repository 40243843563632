import { useEffect } from 'react';
import useSpeechRecognition from '../../../hooks/useSpeechRecognition';

export default function useRecognition({ questionId, ...props }) {
  const { listening, listen, stop, supported } = useSpeechRecognition(props);

  // BUG HERE: putting stop inside callback does nothing as when callback is called,
  // listening becomes false and stop will not be fired
  useEffect(() => {
    if (listening) {
      stop();
    }
    // we need to stop listening on question change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  return { listening, listen, stop, supported };
}
