import revertDictionary from 'utils/revertDictionary';

// На случай если в разговоре нет сообщений
export const NO_MESSAGES = 'messenger.messages.isEmpty';

// На случай если нет сторон разговора
export const NO_SIDES = 'messenger.sides.isEmpty';

// На случай если не переданы данные о разговоре
export const NO_DATA = 'messenger.data.isEmpty';

export const NO_SIDE_FOR_MESSAGE = 'messenger.message.noSide';

const messengerDictionary = revertDictionary({
  [NO_DATA]: {
    ru: 'Нет данных по разговору...',
    en: 'No data about conversation...'
  },
  [NO_SIDES]: {
    ru: 'Нет данных об участниках разговора',
    en: 'No data about conversation sides'
  },
  [NO_MESSAGES]: {
    ru: 'Диалог ещё не начат...',
    en: 'Dialog is not started yet...'
  },
  [NO_SIDE_FOR_MESSAGE]: {
    ru: 'Сторона не определена',
    en: 'Side is undefined'
  }
});

export default messengerDictionary;
