const initialState = {
  current: null,
  latest: null
};

// TODO: think of multiple scrolls states like modals
export default function sidebarScrollReducer(state = initialState, action) {
  switch (action.type) {
    case 'interview-ui/set-scroll-target':
    case 'interview-ui/set-sidebar-scroll-target':
      return {
        ...state,
        current: action.targetId,
        latest: action.targetId
      };

    case 'interview-ui/complete-sidebar-scroll':
      return {
        ...state,
        current: null
      };

    case 'interview-ui/reset-sidebar-scroll':
      return initialState;

    default:
      return state;
  }
}
