// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { camelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import type { IUser } from 'flow-types/entities/User';
import request from 'utils/request';
import { API } from 'utils/config';
import type { ReactComponent } from 'flow-types/ReactComponent';
import Checkbox from '../../components/Checkbox';
import Header from '../../components/Header';
import Link from '../../components/Link';
import Form, { Field } from '../Form';
import BaseField from '../../components/Form/Field';
import Label from '../../components/Label';
import loginFormReducer from './reducer';
import type { LoginFormState } from './reducer';
import Button from '../../components/Button';
import Input from '../../components/Input';
import validateForm from './validateForm';

import List from '../../components/List';
import ListItem from '../../components/ListItem';

const { useCallback, useReducer, useMemo } = React;

type LoginFormProps = {|
  onSuccess: (user: IUser, token: string, rememberMe: boolean) => void,
  onResetClick?: null | (() => void),
  allowPasswordReset?: boolean
|};

type FormFieldProps = {
  name: string,
  onChange: Function,
  value: mixed,
  InputComponent: null | ReactComponent,
  label?: null | string,
  disabled?: boolean
};

function FormField({
  label,
  InputComponent,
  onChange,
  ...inputProps
}: FormFieldProps): React.Node {
  const handleChange = useCallback(
    nextValue => {
      if (!onChange) return;

      onChange({
        [inputProps.name]: nextValue
      });
    },
    [inputProps, onChange]
  );

  if (!InputComponent) return null;

  return (
    // $FlowIgnore
    <Field name={inputProps.name} disabled={inputProps.disabled}>
      {label && (
        <Label nonUI as="label">
          {label}
        </Label>
      )}
      <InputComponent {...inputProps} onChange={handleChange} />
    </Field>
  );
}

FormField.defaultProps = {
  label: null,
  disabled: false
};

const initialState: LoginFormState = {
  data: {
    email: '',
    password: '',
    rememberMe: false
  },
  isLoading: false,
  validation: null
};

export default function LoginForm({
  onResetClick,
  onSuccess,
  allowPasswordReset
}: LoginFormProps): React.Node {
  const intl = useIntl();

  const [formState, formDispatch] = useReducer(loginFormReducer, initialState);

  const handleDataChange = useCallback(dataUpdate => {
    formDispatch({ type: 'UPDATE_FORM_DATA', dataUpdate });
  }, []);

  const handleValidationChange = useCallback(validation => {
    formDispatch({ type: 'SET_FORM_VALIDATION', validation });
  }, []);

  const messages = useMemo(
    () => ({
      email: {
        placeholder: intl.formatMessage({
          id: 'auth.loginForm.fields.email.placeholder'
        })
      },
      password: {
        placeholder: intl.formatMessage({
          id: 'auth.loginForm.fields.password.placeholder'
        })
      }
    }),
    [intl]
  );

  const handleSubmit = useCallback(async () => {
    try {
      validateForm(
        {
          email: formState.data.email,
          password: formState.data.password
        },
        {
          emailFormatError: {
            field: 'email',
            messageId: 'auth.loginForm.validation.email.incorrectFormat'
          },
          emailRequiredError: {
            field: 'email',
            messageId: 'auth.loginForm.validation.email.isRequired'
          },
          passwordRequiredError: {
            field: 'password',
            messageId: 'auth.loginForm.validation.password.isRequired'
          }
        }
      );
    } catch (e) {
      const { errors } = e;

      const errorsGroupedByField = groupBy(errors, 'field');

      handleValidationChange(errorsGroupedByField);

      toast.error(
        <List>
          {map(errors, (error, index) => {
            const errorMsg = intl.formatMessage(
              { id: error.messageId },
              error.values
            );

            return <ListItem key={index}>{errorMsg}</ListItem>;
          })}
        </List>,
        { position: 'bottom-center', autoClose: 3500 }
      );

      return null;
    }

    // submit request
    try {
      formDispatch({ type: 'LOGIN_REQUEST' });

      const { response } = await request({
        method: 'POST',
        url: API.login,
        body: {
          email: formState.data.email,
          password: formState.data.password
        }
      }).toPromise();

      const {
        user,
        data: { accessToken }
      } = camelizeKeys(response);

      formDispatch({ type: 'LOGIN_REQUEST_SUCCESS' });

      if (!onSuccess) return null;

      onSuccess(user, accessToken, formState.data.rememberMe);
    } catch (error) {
      formDispatch({ type: 'LOGIN_REQUEST_FAIL' });
      toast.error(
        intl.formatMessage({
          id: 'auth.loginForm.validation.invalidCredentials'
        }),
        { autoClose: 3500, position: 'bottom-center' }
      );
    }

    return null;
  }, [
    formState.data.email,
    formState.data.password,
    formState.data.rememberMe,
    handleValidationChange,
    intl,
    onSuccess
  ]);

  return (
    <Form
      validation={formState.validation}
      preventEventFlow
      onSubmit={handleSubmit}
    >
      <Header as="h1">
        <FormattedMessage id="auth.loginForm.title" />
      </Header>
      <FormField
        name="email"
        onChange={handleDataChange}
        placeholder={messages.email.placeholder}
        value={formState.data.email}
        InputComponent={Input}
        size="big"
        onlyValue
      />
      <FormField
        name="password"
        type="password"
        onChange={handleDataChange}
        placeholder={messages.password.placeholder}
        value={formState.data.password}
        InputComponent={Input}
        size="big"
        onlyValue
      />
      <FormField
        name="rememberMe"
        onChange={handleDataChange}
        InputComponent={Checkbox}
        value={formState.data.rememberMe}
      >
        <FormattedMessage id="auth.loginForm.fields.rememberMe.label" />
      </FormField>
      {allowPasswordReset && (
        <BaseField>
          <Link onClick={onResetClick}>
            <FormattedMessage id="auth.loginForm.actions.forgotPassword" />
          </Link>
        </BaseField>
      )}
      <BaseField>
        <Button
          buttonType="primary"
          type="submit"
          disabled={formState.isLoading}
          loading={formState.isLoading}
        >
          <FormattedMessage id="auth.loginForm.actions.singIn" />
        </Button>
      </BaseField>
    </Form>
  );
}

LoginForm.defaultProps = {
  allowPasswordReset: true,
  onResetClick: null
};
