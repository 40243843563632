import { combineEpics } from 'redux-observable';
import AppEnvironment from 'utils/AppEnvironment';
import companiesEpic from './companies';
import initialEpic from './app/initial';
import authEpic from './auth';
import checklistsEpic from './checks';
import projectsEpic from './projects';
import projectEpics from '../pages/Project/epic';
import recordsEpic from './records';
import interviewEpics from './interviews';
import profileEpics from './profile';
import meetingsEpic from '../pages/Meetings/Meetings.epic';
import buildingsEpic from '../pages/Buildings/epic';
import tasksWatchersEpic from '../pages/TasksWatchers/epic';
import queryComposerEpics from '../crm/query/Query.epic';
import crmEpics from '../crm/global_context/Crm.epic';
import { startProcessWatcher } from './sideProcesses';

const epics = [
  startProcessWatcher,
  initialEpic,
  tasksWatchersEpic,
  authEpic,
  checklistsEpic,
  projectsEpic,
  projectEpics,
  companiesEpic,
  recordsEpic,
  interviewEpics,
  meetingsEpic,
  queryComposerEpics,
  profileEpics,
  AppEnvironment.buildings ? buildingsEpic : false,
  crmEpics
].filter(Boolean);

export default combineEpics(...epics);
