// @flow

import reduce from 'lodash/reduce';
import last from 'lodash/last';
import filter from 'lodash/filter';
import values from 'lodash/values';

import type { RecordsRegionsState } from 'flow-types/states/RecordsState';
import type {
  RemoveRegion,
  RemoveRegionSuccess
} from 'flow-types/actions/records/DeleteRecordRegion';
import type { RecordsActions } from 'flow-types/actions/records';
import type { Reducer } from 'flow-types/Reducer';
import belongsToChecklistId from 'common/helpers/regions/belongsToChecklistId';
import type { ResponseReset } from 'flow-types/actions/projects/detail/response/ResponseReset';
import updateFilter from '../../common/helpers/updateFilter';
import isNewRegion from '../../pages/Project/helpers/isNewRegion';

type ActionHandler<A> = Reducer<RecordsRegionsState, A>;

const initialState: RecordsRegionsState = {
  data: null,
  loading: false,
  error: null,
  filter: {},
  pagination: {
    activePage: 0,
    totalElements: 0,
    totalPages: 1
  },
  selected: null
};

const handleRegionRemove: ActionHandler<RemoveRegion> = (
  state,
  { regionId }
) => {
  const next: RecordsRegionsState = { ...state };

  // TODO: fix FT
  // $FlowIgnore
  next.data = { ...next.data };

  // $FlowIgnore
  if (next.data[regionId]) {
    // $FlowIgnore
    next.data[regionId] = {
      ...next.data[regionId],
      status: 'deleting'
    };
  }

  return next;
};

const handleRegionRemoveSuccess: ActionHandler<RemoveRegionSuccess> = (
  state,
  { regionId, checklistId }
) => {
  const next = { ...state };

  // TODO: fix FT
  // $FlowIgnore
  next.data = { ...next.data };

  // $FlowIgnore
  const recordId = next.data[regionId].record.id;

  // $FlowIgnore
  delete next.data[regionId];

  if (!checklistId) {
    next.selected = null;
    return next;
  }

  if (next.selected === regionId) {
    const lastSuitableRegion = last(
      values(
        filter(
          next.data,
          region =>
            region.record.id === recordId &&
            belongsToChecklistId(region, checklistId)
        )
      )
    );

    next.selected = lastSuitableRegion?.id || null;
  }

  return next;
};

export default function regionsReducer(
  state: RecordsRegionsState = initialState,
  action: RecordsActions | ResponseReset
): RecordsRegionsState {
  switch (action.type) {
    // $FlowIgnore
    case 'project-response/reset':
    case 'records/reset':
      return initialState;

    case 'records-regions/create':
      return {
        ...state,
        data: {
          ...(state.data && state.data),
          // $FlowIgnore
          [action.regionId]: action.region
        }
      };

    case 'records-regions/update':
      return {
        ...state,
        data: reduce(
          state.data,
          (result, current) => {
            const { id } = current;

            if (id !== action.regionId) {
              return {
                ...result,
                [id]: current
              };
            }

            const hasCache = !!current.cache;

            return {
              ...result,
              [id]: {
                ...current,
                ...action.dataUpdate,
                ...(!hasCache && {
                  cache: current
                })
              }
            };
          },
          {}
        )
      };

    case 'records-regions/reset':
      return {
        ...state,
        data: reduce(
          state.data,
          (result, current) => {
            const { id } = current;

            if (id !== action.regionId) {
              return {
                ...result,
                [id]: current
              };
            }

            if (isNewRegion(current)) {
              return result;
            }

            const hasCache = !!current.cache;

            return {
              ...result,
              [id]: hasCache ? current.cache : current
            };
          },
          {}
        )
      };

    case 'records-regions/update-state':
      return {
        ...state,
        ...action.stateUpdate
      };

    case 'records-regions/set-selected':
      return {
        ...state,
        selected: action.regionId || null
      };

    case 'records/select':
      return {
        ...state,
        ...(action.resetRegion && {
          selected: null
        })
      };

    case 'records-regions/remove':
      return handleRegionRemove(state, action);

    case 'records-regions/remove-success':
      return handleRegionRemoveSuccess(state, action);

    case 'records-regions/save':
      return {
        ...state,
        data: reduce(
          state.data,
          (result, region) => {
            if (region.id !== action.regionId) {
              return {
                ...result,
                [region.id]: region
              };
            }

            return {
              ...result,
              // Numbers are valid as object keys
              // $FlowIgnore
              [action.regionId]: {
                ...region,
                status: 'saving'
              }
            };
          },
          {}
        )
      };

    case 'records-regions/save-fail':
      return {
        ...state,
        data: reduce(
          state.data,
          (result, region) => {
            if (region.id !== action.regionId) {
              return {
                ...result,
                [region.id]: region
              };
            }

            return {
              ...result,
              // TODO: numbers are valid as object keys
              // $FlowIgnore
              [action.regionId]: {
                ...region,
                status: null,
                error: action.error
              }
            };
          },
          {}
        )
      };

    case 'records-regions/save-success':
      // $FlowIgnore
      return {
        ...state,
        ...(action.originalId &&
          action.originalId === state.selected && {
            selected: action.regionId
          }),
        data: reduce(
          state.data,
          (result, region) => {
            if (
              region.id !== action.regionId &&
              region.id !== action.originalId
            ) {
              return {
                ...result,
                [region.id]: region
              };
            }

            return {
              ...result,
              // Numbers are valid as object keys
              // $FlowIgnore
              [action.regionId]: action.region
            };
          },
          {}
        )
      };

    case 'records-regions/fetch':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case 'records-regions/fetch-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        loading: false
      };

    case 'records-regions/fetch-fail':
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}
