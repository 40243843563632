// @flow

import type { ResponseStatsState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectResponsesAction } from 'flow-types/actions/projects/detail/responses';
import type { ResponseReset } from 'flow-types/actions/projects/detail/response/ResponseReset';
import type { Reducer } from 'flow-types/Reducer';

const initialState: ResponseStatsState = {
  data: {},
  loading: false
};

const responseStatsReducer: Reducer<
  ResponseStatsState,
  ProjectResponsesAction | ResponseReset
> = (state = initialState, action) => {
  switch (action.type) {
    case 'project-response/reset':
      return initialState;

    case 'records/fetch-response-stats':
      return {
        ...state,
        loading: true
      };
    case 'records/fetch-response-stats-fail':
      return {
        ...state,
        loading: false
      };
    case 'records/fetch-response-stats-success':
      return {
        ...state,
        data: action.stats,
        loading: false
      };
    default:
      return state;
  }
};

export default responseStatsReducer;
