// @flow

import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import parseISO from 'date-fns/parseISO';
import type { IView } from 'flow-types/entities/View';
import type { IRegistryRecord } from 'flow-types/entities/RegistryRecord';

// This composed function is used to construct registry from 'views' property of IResponse
const convertViewsToRegistryRecords: Function = fp.compose(
  fp.sortBy(({ enter, exit }) => {
    if (enter) {
      return parseISO(enter);
    }

    return parseISO(exit);
  }),
  fp.flatten,
  fp.map((item: IView): IRegistryRecord[] => {
    const {
      timeEnd,
      timeStart,
      questionId,
      userInfo,
      question: questionInfo
    } = item;
    return [
      {
        questionId,
        enter: timeStart,
        userInfo: userInfo || null,
        questionInfo
      },
      {
        questionId,
        exit: timeEnd,
        userInfo: userInfo || null,
        questionInfo
      }
    ];
  }),
  camelizeKeys
);

export default convertViewsToRegistryRecords;
