import { toast } from 'react-toastify';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import request from 'utils/request';
import { API } from 'utils/config';

import responseParser from 'common/epicHelpers/responseParser';

import type {
  CreateExportTask,
  CreateExportTaskFail,
  CreateExportTaskSuccess
} from 'flow-types/actions/projects/detail/export/CreateExportTask';

import { decamelizeAndDenormalizeExportSettings } from 'common/transducers/projects/exportSettingsDenormalizer';

import type { Epic } from 'flow-types/Epic';
import interpolateString from 'common/helpers/interpolateString';
import type { AppState } from 'flow-types/AppState';

import {
  projectExportSettingsForm,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const createExportTaskEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/create-task'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(
      ([{ settingsId }, state]: [CreateExportTask, AppState]) => {
        const projectId = projectIdFromPathSelector(state);
        let body = null;

        if (settingsId) {
          body = decamelizeKeys({
            settings: {
              userResponsesExportSettingId: settingsId
            }
          });
        } else {
          const { data } = projectExportSettingsForm(state);

          // eslint-disable-next-line prefer-destructuring
          body = decamelizeAndDenormalizeExportSettings(data);
        }

        return request({
          url: interpolateString(API.projects.exportTasks.list, { projectId }),
          method: 'POST',
          body
        }).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.map((data): CreateExportTaskSuccess => ({
            type: 'project-export/create-task-success',
            settingsId: settingsId || null,
            data: camelizeKeys(data)
          })),
          RxOperators.catchError((): [CreateExportTaskFail] => {
            toast.error('error during export task creation process', {
              autoClose: 2500,
              showCloseButton: true,
              position: 'bottom-center'
            });

            return [
              {
                type: 'project-export/create-task-fail',
                settingsId: settingsId || null
              }
            ];
          })
        );
      }
    )
  );

export default createExportTaskEpic;
