import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';

const restartInterview = $action =>
  $action.pipe(
    ofType('interview/start-new-interview'),
    RxO.mergeMapTo(
      of(
        {
          type: 'interview/reset'
        },
        {
          type: 'interview/prepare'
        }
      )
    )
  );

export default restartInterview;
