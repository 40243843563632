/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
// @flow
import React, { Fragment, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
// import { camelize } from 'humps';
import Link from 'common/components/Link';
import Icon from 'common/components/Icon';
import { languageStateSelector } from '../../selectors';
import { crmStateSelector } from '../../selectors/crm';
import Pagination from '../../common/components/Pagination';
import Table, {
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '../../common/components/Table';

type TabViewBodyPropType = {|
  // the settings property of the question object from the Univey system is used to store the view settings
  question: Object,
  // TODO: write a proper types for the whole CRM
  views: Object,
  variables: { [string]: string } // a list of global variables from the CRM store
|};

/**
 * The TabViewBody is used to show the results of query execution in seperate tabs.
 * The view settings are passed in through the question key for compliance with the
 * code of the Univey system.
 *
 * TODOs: All queries should be executed first with the `count` action to get the number of
 * actual records. After openning a tab, a correspoding query should be executed with
 * the `list` action to actually fetch the records. An exception is the active tab for which
 * the `list`-action query should be executed immediately to show the actual records.
 * @param {*} props
 */

function makeFilters(filters = [], variableFilters = [], variables = {}) {
  const fieldFilters = filters.map(filter => ({
    operator: filter.operator.toUpperCase(),
    value: filter.value,
    field: filter.source
  }));

  const varFilters = variableFilters
    .map(variableFilter => ({
      operator: 'EQ',
      value: variables[variableFilter.variable.name],
      field: variableFilter.fieldName
    }))
    .filter(({ value }) => value);

  return [...fieldFilters, ...varFilters];
}

function TabViewBody(props: TabViewBodyPropType) {
  const { question: viewSettings, views, variables } = props;
  const { code, settings: viewState } = viewSettings;
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'crm/tabs-preview',
      code,
      payload: viewState.tabs.map(tab => ({
        tableName: tab.dataSettings.query.entity.tableName,
        // TODO: move to selector
        filters: makeFilters(
          tab.dataSettings.filters,
          tab.dataSettings.variableFilters,
          variables
        )
      }))
    });
    // TODO: check linter rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, variables]);

  useEffect(() => {
    // TODO: make sure we have tabs before render them
    const tab = viewState.tabs[currentTabIndex];

    if (!tab) {
      return;
    }

    const payload = {
      code,
      tabIndex: currentTabIndex,
      // TODO: validate tabs before save them, so we can be sure that query and entity exist
      entity: viewState.tabs[currentTabIndex].dataSettings.query.entity,
      pagination: {
        pageSize: 10,
        pageNumber
      },
      filters: makeFilters(
        tab.dataSettings.filters,
        tab.dataSettings.variableFilters,
        variables
      ),
      sort: null
    };

    if (sortBy) {
      payload.sort = {
        [sortBy.split('.')[1]]: sortDirection
      };
    }

    dispatch({
      type: 'crm/tab-displayed',
      payload
    });

    // TODO: check if we have performance issues because of variables dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, currentTabIndex, pageNumber, sortBy, sortDirection, variables]);

  const changeSortBy = field => {
    if (field === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  return (
    <>
      <div className="ui top attached tabular menu">
        {viewState.tabs.map((tab, tabIndex) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            // TODO: there should be some id
            // eslint-disable-next-line
            key={tabIndex}
            type="button"
            role="button"
            tabIndex="0"
            className={`${currentTabIndex === tabIndex ? 'active' : ''} item`}
            onClick={() => {
              setCurrentTabIndex(tabIndex);
              // for now reset page if tab was changed
              setPageNumber(0);
            }}
          >
            {/* TODO: Why view might be missing? This issue is already described in a reducer, try to solve it */}
            {tab.title}
            {views[code] &&
              views[code][tabIndex] &&
              views[code][tabIndex].total != null &&
              ` (${views[code][tabIndex].total})`}
          </div>
        ))}
      </div>

      {viewState.tabs.map((tab, tabIndex) => (
        <div
          // TODO: there should be some id
          // eslint-disable-next-line
          key={tabIndex}
          className={`ui bottom attached ${
            currentTabIndex === tabIndex ? 'active' : ''
          } tab segment`}
        >
          <Table>
            <TableHead>
              <TableRow>
                {(tab.dataSettings.displayFields || []).map(column => {
                  const active = column.name === sortBy;
                  return (
                    <TableCell
                      key={column.name}
                      width={column.width}
                      as="th"
                      style={{
                        cursor: 'pointer',
                        fontWeight: active ? 700 : 'normal'
                      }}
                      onClick={() => changeSortBy(column.name)}
                    >
                      {column.title}
                      {active && (
                        <Icon
                          icon={`caret ${
                            sortDirection === 'asc' ? 'up' : 'down'
                          }`}
                        />
                      )}
                    </TableCell>
                  );
                })}
                <TableCell as="th" className="center aligned collapsing">
                  <Icon icon="linkify" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: Why view might be missing? */}
              {views[code] &&
                views[code][currentTabIndex] &&
                views[code][currentTabIndex].data &&
                views[code][currentTabIndex].data.map(item => (
                  <TableRow key={item.response_id}>
                    {(tab.dataSettings.displayFields || []).map(column => {
                      /* TODO: why should it be entity.field, it should be just field! */
                      const { name } = column;
                      return (
                        <TableCell key={name} width={column.width}>
                          {item[name.split('.')[1]]}
                        </TableCell>
                      );
                    })}
                    <TableCell className="center aligned">
                      <Link
                        target="_blank"
                        href={`/interviews?rid=${item.response_id}`}
                      >
                        <Icon icon="linkify" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {views[code] &&
            views[code][tabIndex] &&
            !!views[code][tabIndex].pages && (
              <Pagination
                onChange={setPageNumber}
                totalPages={views[code][tabIndex].pages}
                activePage={views[code][tabIndex].current}
                orderOffset={1}
              />
            )}
        </div>
      ))}
    </>
  );
}

const selector: Function = createSelector(
  crmStateSelector,
  languageStateSelector,
  (crmState, language) => ({
    ...crmState,
    language
  })
);

export default injectIntl(connect(selector)(TabViewBody));
