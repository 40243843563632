import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import * as RxOperators from 'rxjs/operators';

import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';

import type { Epic } from 'flow-types/Epic';
import type { ProjectExportSettingsFormState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type {
  RemoveExportSettingsFail,
  RemoveExportSettingsSuccess
} from 'flow-types/actions/projects/detail/export/RemoveExportSettings';

import { toast } from 'react-toastify';
import {
  projectExportSettingsForm,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const settingsIdFromFormSelector = createSelector(
  projectExportSettingsForm,
  (settingsForm: ProjectExportSettingsFormState) => {
    const { data } = settingsForm;

    return data.id;
  }
);

const removeSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/remove-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([, state]) => {
      const projectId = projectIdFromPathSelector(state);
      const settingsId = settingsIdFromFormSelector(state);

      return request({
        url: interpolateString(API.projects.exportSettings.detail, {
          projectId,
          settingsId
        }),
        method: 'DELETE'
      }).pipe(
        RxOperators.map((): RemoveExportSettingsSuccess => ({
          type: 'project-export/remove-settings-success',
          settingsId
        })),
        RxOperators.catchError((): [RemoveExportSettingsFail] => {
          toast.error('error during export settings remove process', {
            autoClose: 2500,
            showCloseButton: true,
            position: 'bottom-center'
          });

          return [
            {
              type: 'project-export/remove-settings-fail',
              settingsId
            }
          ];
        })
      );
    })
  );

export default removeSettingsEpic;
