// @flow
import fp from 'lodash/fp';
import size from 'lodash/size';
import findIndex from 'lodash/findIndex';
import moveItem from 'array-move';
import { camelizeKeys } from 'humps';
import type {
  IChecklistGroupCheckReport,
  IProjectCheckReport,
  IProjectManagerCheckReport,
  ProjectDepartmentCheckReport
} from '../../../flow-types/entities/ProjectCheckReport';

const groupsReportNormalizer = fp.reduce(
  (normalized, group: IChecklistGroupCheckReport) => {
    const { checklistGroupId, avgPercent } = group;
    return {
      ...normalized,
      [checklistGroupId]: avgPercent
    };
  },
  {}
);

const managersReportNormalizer = fp.reduce(
  (normalized, managerReport: IProjectManagerCheckReport) => {
    const { avgPercent, groups, interviewerId, userName } = managerReport;

    const data = {
      id: `interviewer-${interviewerId}`,
      value: avgPercent,
      title: userName,
      groups: groupsReportNormalizer(groups)
    };

    if (!normalized) {
      return [data];
    }

    return [...normalized, data];
  },
  []
);

const normalizeDepartmentsPosition = departments => {
  const unknownIndex = findIndex(
    departments,
    dep => dep.department === 'Unknown'
  );
  return moveItem(departments, unknownIndex, 0);
};

const departmentsReportNormalizer = fp.compose(
  fp.reduce(
    (
      normalized,
      {
        department,
        managers,
        groups,
        departmentId,
        avgPercent
      }: ProjectDepartmentCheckReport
    ) => {
      const data = {
        id: `dep-${departmentId}`,
        value: avgPercent,
        ...(department === 'Unknown' && {
          messageId: 'project.reports.labels.noDepartment'
        }),
        ...(department !== 'Unknown' && {
          title: department
        }),
        groups: groupsReportNormalizer(groups),
        department: true
      };

      if (!normalized) {
        return [data];
      }

      return [...normalized, data, ...managersReportNormalizer(managers)];
    },
    []
  ),
  normalizeDepartmentsPosition
);

const groupNames = fp.reduce(
  (_names, group) => ({
    ..._names,
    [group.checklistGroupId]: group.checklistGroup
  }),
  {}
);

const projectCheckReportNormalizer = fp.compose(
  (report: IProjectCheckReport & {| columns: { [key: string]: string } |}) => {
    if (!report || report.responseCount === 0) {
      return {
        rows: [],
        columns: report.columns
      };
    }

    const { departments, managers, groups, avgPercent, columns } = report;

    let rows = [
      {
        messageId: 'project.reports.labels.company',
        value: avgPercent,
        groups: groupsReportNormalizer(groups)
      }
    ];

    if (size(departments) > 0) {
      rows = [
        ...rows,
        { messageId: 'project.reports.labels.byDepartments', divider: true },
        ...departmentsReportNormalizer(departments)
      ];
    }

    if (size(managers) > 0) {
      rows = [
        ...rows,
        { messageId: 'project.reports.labels.byManagers', divider: true },
        ...managersReportNormalizer(managers)
      ];
    }

    return {
      rows,
      columns
    };
  },
  (report: IProjectCheckReport) => {
    if (size(report) === 0) return null;

    return {
      ...report,
      columns: groupNames(report.groups)
    };
  },
  camelizeKeys
);

export default projectCheckReportNormalizer;
