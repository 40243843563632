// @flow

import type { InterviewAction } from 'flow-types/actions/interview';
import type { Reducer } from '../../flow-types/Reducer';
import type { InterviewComputedPropertiesState } from '../../flow-types/states/InterviewState/InterviewComputedPropertiesState';

const initialState: InterviewComputedPropertiesState = {};

const interviewComputedPropertiesReducer: Reducer<
  InterviewComputedPropertiesState,
  InterviewAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'interview/process-properties-success':
      return action.data;

    default:
      return state;
  }
};

export default interviewComputedPropertiesReducer;
