// @flow
import { object, string, mixed } from 'yup';
import { createTestsValidator } from 'common/containers/TestsForm/validator';

type PollingNotificationValidationSpec = {
  title?: any,
  status?: any,
  tests?: any,
  delayType?: any,
  delayValue?: any,
  questionsAccess?: any,
  questionGroupsAccess?: any,
  config?: any
};

const pollingNotificationValidator: any = object().shape(
  ({
    title: string().required(),
    config: object().nullable(),
    tests: createTestsValidator(),
    delayType: mixed().required(),
    delayValue: mixed().required()

    // В два поля ниже приходит на самом деле объект,
    // однако здесь нам нужна валидация как раз-таки массива
    // questionGroupsAccess: array()
    //   .transform(value => {
    //     if (value === null) return [];
    //
    //     return values(value);
    //   })
    //   .when('questionsAccess', (questionsAccess, schema) => {
    //     if (size(questionsAccess) === 0) {
    //       return schema.min(1).required();
    //     }
    //
    //     return schema.nullable();
    //   }),
    //
    // questionsAccess: array()
    //   .transform(value => {
    //     if (value === null) return [];
    //
    //     return values(value);
    //   })
    //   .when('questionGroupsAccess', (questionGroupsAccess, schema) => {
    //     if (size(questionGroupsAccess) === 0) {
    //       return schema.min(1).required();
    //     }
    //
    //     return schema.nullable();
    //   })
  }: PollingNotificationValidationSpec)
  // [['questionsAccess', 'questionGroupsAccess']]
);

export default pollingNotificationValidator;
