// @flow
import * as React from 'react';

export default function useWindowBeforeUnload(callback: Function) {
  React.useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);
}
