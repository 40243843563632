// @flow

import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import type { ITest$Value } from '../flow';
import type { ICheckOperator } from '../../../../flow-types/entities/FilterSettings';
import Dropdown from '../../../components/Dropdown';
import Input, { InputWrapper } from '../../../components/Input';

type Props = {
  onChange?: Function,
  value?: ITest$Value,
  size?: string,
  operator?: ICheckOperator,
  disabled?: boolean,
  error?: boolean
};

export default function RelativeTimeInput({
  operator,
  onChange,
  size,
  value,
  error,
  disabled
}: Props) {
  const intl = useIntl();

  const messages = useMemo<{
    prev: string,
    current: string,
    next: string,
    second: string,
    minute: string,
    hour: string,
    day: string,
    month: string,
    week: string,
    year: string
  }>(() => {
    let count = 0;

    if (Array.isArray(value)) {
      if (operator === LOGIC_OPERATOR.IN) {
        count = 1;
      } else if (!isNil(value[0])) {
        // eslint-disable-next-line prefer-destructuring
        count = value[0];
      }
    }

    return {
      prev: intl.formatMessage({ id: 'common.labels.prev' }),
      current: intl.formatMessage({ id: 'common.labels.current' }),
      next: intl.formatMessage({ id: 'common.labels.next' }),

      second: intl.formatMessage({ id: 'common.labels.second' }, { count }),
      minute: intl.formatMessage({ id: 'common.labels.minute' }, { count }),
      hour: intl.formatMessage({ id: 'common.labels.hour' }, { count }),
      day: intl.formatMessage({ id: 'common.labels.day' }, { count }),
      month: intl.formatMessage({ id: 'common.labels.month' }, { count }),
      week: intl.formatMessage({ id: 'common.labels.week' }, { count }),
      year: intl.formatMessage({ id: 'common.labels.year' }, { count })
    };
  }, [intl, operator, value]);

  const whenOptions = useMemo(
    () => [
      { id: 'prev', title: messages.prev },
      { id: 'current', title: messages.current },
      { id: 'next', title: messages.next }
    ],
    [messages]
  );

  const options = useMemo(() => {
    if (operator === LOGIC_OPERATOR.IN) {
      return [
        {
          id: 'day',
          title: messages.day
        },
        {
          id: 'week',
          title: messages.week
        },
        {
          id: 'month',
          title: messages.month
        },
        {
          id: 'year',
          title: messages.year
        }
      ];
    }

    return [
      {
        id: 'second',
        title: messages.second
      },
      {
        id: 'minute',
        title: messages.minute
      },
      {
        id: 'hour',
        title: messages.hour
      },
      {
        id: 'day',
        title: messages.day
      },
      {
        id: 'month',
        title: messages.month
      },
      {
        id: 'year',
        title: messages.year
      }
    ];
  }, [
    messages.day,
    messages.hour,
    messages.minute,
    messages.month,
    messages.second,
    messages.week,
    messages.year,
    operator
  ]);

  const handleCountChange = useCallback(
    nextValue => {
      if (!onChange) return;

      if (!Array.isArray(value)) {
        onChange([nextValue, null]);
        return;
      }

      onChange([nextValue, value[1] || null]);
    },
    [onChange, value]
  );

  const handlePeriodChange = useCallback(
    nextPeriod => {
      if (!onChange) return;

      if (!Array.isArray(value)) {
        onChange([null, nextPeriod]);
        return;
      }

      onChange([value[0] || null, nextPeriod]);
    },
    [onChange, value]
  );

  return (
    <div className="ui two column grid">
      <div className="column">
        {operator === 'in' ? (
          <Dropdown
            fluid
            value={Array.isArray(value) ? value[0] : null}
            size={size}
            error={error}
            valueKey="id"
            labelKey="title"
            disabled={disabled}
            options={whenOptions}
            onChange={handleCountChange}
            onlyValue
          />
        ) : (
          <InputWrapper size={size} fluid error={error} disabled={disabled}>
            <Input
              type="number"
              min={0}
              disabled={disabled}
              onChange={handleCountChange}
              value={Array.isArray(value) ? value[0] : null}
              onlyValue
            />
          </InputWrapper>
        )}
      </div>
      <div className="column">
        <Dropdown
          disabled={disabled}
          size={size}
          error={error}
          fluid
          options={options}
          onlyValue
          valueKey="id"
          labelKey="title"
          onChange={handlePeriodChange}
          value={Array.isArray(value) ? value[1] : null}
        />
      </div>
    </div>
  );
}

RelativeTimeInput.defaultProps = {
  onChange: null,
  value: null,
  size: null,
  operator: null,
  error: false,
  disabled: false
};
