/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import SelectionCard from 'common/components/Card/Selection';
import type { IQuestionOption as Option } from '../../../../flow-types/entities/QuestionOption';

type Props = {|
  data: Option,
  onSelect: Function,
  selected: boolean,
  disabled: boolean,
  showLabel: boolean,
  radio: boolean
|};

const ImageOption = ({
  onSelect,
  selected,
  data,
  disabled,
  showLabel,
  radio
}: Props) => {
  const lazyProps = useMemo(
    () => ({
      height: 256,
      width: 256,
      scrollContainer: '.lazy-scroll-box',
      once: true
    }),
    []
  );

  const handleSelect = useCallback(() => {
    if (!onSelect) return;

    onSelect(data.id);
  }, [data.id, onSelect]);

  return (
    <SelectionCard
      disabled={disabled}
      value={selected}
      onChange={handleSelect}
      image={data.image}
      showLabel={showLabel}
      lazyProps={lazyProps}
      label={data.title}
      radio={radio}
    />
  );
};

export default ImageOption;
