// @flow

import type { PollingNotificationsListState } from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import type { PollingNotificationsAction } from 'flow-types/actions/projects/detail/pollingNotifications';
import type {
  FetchPollingNotifications,
  FetchPollingNotificationsFail,
  FetchPollingNotificationsSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/list/FetchPollingNotifications';
import type { ResetProject } from 'flow-types/actions/projects/detail/project/ResetProject';
import type { $ActionType } from 'flow-types/ActionType';
import updateFilter from 'common/helpers/updateFilter';

const createInitialState = (): PollingNotificationsListState => ({
  filter: {
    title: '',
    ps: 10,
    pn: 0
  },
  pagination: {
    activePage: 0,
    totalElements: 0,
    totalPages: 1
  },
  data: [],
  status: null
});

export const FETCH_LIST: $ActionType<FetchPollingNotifications> =
  'project/fetch-polling-notifications';

export const FETCH_LIST_SUCCESS: $ActionType<FetchPollingNotificationsSuccess> =
  'project/fetch-polling-notifications-success';

export const FETCH_LIST_FAIL: $ActionType<FetchPollingNotificationsFail> =
  'project/fetch-polling-notifications-fail';

export default function pollingNotificationsReducer(
  state: PollingNotificationsListState = createInitialState(),
  action: PollingNotificationsAction | ResetProject
): PollingNotificationsListState {
  switch (action.type) {
    case 'project/reset':
      return createInitialState();

    case FETCH_LIST:
      return {
        ...state,
        status: 'loading',
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };

    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        data: [...action.data],
        pagination: { ...action.pagination },
        status: 'success'
      };

    case FETCH_LIST_FAIL:
      return {
        ...state,
        status: 'fail'
      };

    default:
      return state;
  }
}
