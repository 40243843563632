// @flow

import revertDictionary from 'utils/revertDictionary';
import type { IDictionary } from 'flow-types/Dictionary';

const editorDictionary: IDictionary = revertDictionary({
  'htmlEditor.modes.wysiwyg.title': {
    en: 'WYSIWYG',
    ru: 'WYSIWYG'
  },
  'htmlEditor.modes.source.title': {
    en: 'Source',
    ru: 'Исходный код'
  },
  'htmlEditor.modes.preview.title': {
    en: 'Preview',
    ru: 'Предпросмотр'
  }
});

export default editorDictionary;
