import { combineReducers } from 'redux';

import list from './list';
import tasks from './tasks';
import sidesForm from './sidesForm';

export default combineReducers({
  list,
  tasks,
  sidesForm
});
