// @flow

// NPM imports
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import useClickOutside from 'use-onclickoutside';
import split from 'lodash/split';
import { flip, preventOverflow } from '@popperjs/core';
import find from 'lodash/find';

// components imports
import Icon from '../../components/Icon';
import Dropdown from '../../components/Dropdown';
import ConfirmButton from '../../components/ConfirmButton';
import { InputWrapper } from '../../components/Input';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Form from '../../components/Form';
import Field from '../../components/Form/Field';
import Checkbox from '../../components/Checkbox';
import Text from '../../components/Text';

// flow-types imports
import type { ITestSource, SourcesProp } from './flow';
import useUniqueId from '../../hooks/useUniqId';

type Props = SourcesProp & {
  onRemoveClick: () => void,
  size?: null | string,
  value?: string | null,
  onSettingsChange: mixed => void,
  settings: Object
};

const LabelTextBlock = styled.span`
  display: block;
  pointer-events: none;
  ${props => props.title && 'font-weight: 600;'};
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const itemRenderer = (
  { id, title, subTitle, extra }: ITestSource,
  asLabel
): React.Node => (
  <>
    <LabelTextBlock title>{title}</LabelTextBlock>
    {!!subTitle && <LabelTextBlock>{subTitle}</LabelTextBlock>}
    {!asLabel && (
      <>
        {map(extra, (extraInfo, index) => (
          <LabelTextBlock key={`extra-${id}-${index}`}>
            {extraInfo}
          </LabelTextBlock>
        ))}
      </>
    )}
  </>
);

type SourceSettingsButtonProps = {
  value: Object,
  onChange: Function
};

const SourceSettingsButton = ({
  value,
  onChange,
  ...props
}: SourceSettingsButtonProps) => {
  const popupButtonId = useUniqueId('pp-btn');

  const [isPopupVisible, setPopupVisibility] = React.useState(false);

  const [referenceEl, setReference] = React.useState(null);

  const [popperEl, setPopper] = React.useState(null);

  const popperRef = React.useRef(popperEl);

  React.useEffect(() => {
    popperRef.current = popperEl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperEl]);

  const [arrowEl, setArrow] = React.useState(null);

  useClickOutside(popperRef, event => {
    if (isPopupVisible && event) {
      const { target } = event;

      if (target.id !== popupButtonId) {
        setPopupVisibility(false);
      }
    }
  });

  const { attributes, styles } = usePopper(referenceEl, popperEl, {
    // strategy: 'fixed',
    placement: 'bottom',
    // boundary: 'viewport',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowEl
        }
      },
      preventOverflow,
      flip
    ]
  });

  const popupPosition = React.useMemo(() => {
    if (!attributes || !attributes.popper) return null;

    const [position] = split(attributes.popper['data-popper-placement'], '-');

    if (position === 'auto') return null;

    return position;
  }, [attributes]);

  return (
    <>
      <Button
        {...props}
        type="button"
        ref={setReference}
        id={popupButtonId}
        className="no-inner-pointer-events"
        onClick={() => setPopupVisibility(!isPopupVisible)}
      >
        <Icon icon="cog" />
      </Button>
      {isPopupVisible && (
        <Popup
          visible
          transition
          ref={setPopper}
          position={popupPosition}
          style={styles.popper}
          {...(attributes.popper && attributes.popper)}
        >
          <div className="ui compact grid">
            <div className="row">
              <div className="column">
                <Text nonUI={false}>
                  <FormattedMessage id="testsForm.testSettingsForm.title" />
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Form tagName="div">
                  <Field>
                    <Checkbox
                      useNameAsValueKey
                      name="specialTime"
                      onChange={onChange}
                      value={value?.specialTime ?? false}
                    >
                      <FormattedMessage id="testsForm.testSettingsForm.fields.specialTime" />
                    </Checkbox>
                  </Field>
                </Form>
              </div>
            </div>
          </div>
          <div
            className="arrow"
            ref={setArrow}
            style={styles.arrow}
            {...(attributes.arrow && attributes.arrow)}
          />
        </Popup>
      )}
    </>
  );
};

export default function SourceSelector({
  sources,
  size,
  onRemoveClick,
  onSettingsChange,
  value,
  settings,
  ...props
}: Props): React.Node {
  const intl = useIntl();

  const messages = React.useMemo<{ confirmTestDelete: string }>(
    () => ({
      confirmTestDelete: intl.formatMessage({
        id: 'testsForm.actions.confirmTestDelete'
      })
    }),
    [intl]
  );

  const sourceData = React.useMemo<ITestSource | null>(() => {
    const matchedSource = reduce(
      sources,
      (result, optionsGroup) => {
        if (result) return result;

        const findResult = find(
          optionsGroup.options,
          // eslint-disable-next-line eqeqeq
          (source: ITestSource) => source.id == value
        );

        if (!findResult) return null;

        return findResult;
      },
      null
    );

    if (!matchedSource) return null;

    return matchedSource;
  }, [sources, value]);

  const hasSettings = sourceData?.isDate ?? false;

  return (
    <InputWrapper
      className={!hasSettings ? 'left action' : 'left action right action'}
      size={size}
      fluid
    >
      <ConfirmButton
        icon
        size={size}
        type="button"
        onSubmit={onRemoveClick}
        confirmMessage={messages.confirmTestDelete}
        color="negative"
      >
        <Icon icon="trash" noPointerEvents />
      </ConfirmButton>
      <Dropdown
        keepSelected
        renderItem={itemRenderer}
        {...props}
        value={value}
        options={sources}
        size={size}
        groups
        fluid
      />
      {sourceData && !sourceData.settingsDisabled && (
        <SourceSettingsButton
          disabled={!hasSettings}
          value={settings}
          onChange={onSettingsChange}
          icon
          compact
          size={size}
          color="teal"
        />
      )}
    </InputWrapper>
  );
}

SourceSelector.defaultProps = {
  size: null,
  value: null
};
