// @flow
import type { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import type { ReactChildren } from 'flow-types/ReactChildren';
import isDimension from '../helpers/isDimension';

export type Props = {|
  as?: mixed,
  children: ReactChildren,
  inline?: boolean,
  $direction?: 'column' | 'row',
  flex?: number | string,
  // will not set display:(inline-)flex when passed
  ignoreDisplay?: boolean,
  gapSize?: null | number | string,
  gapDimension?: null | string,
  gapMode?: null | string,
  basis?: string | number,
  flow?: string | number,
  grow?: string | number,
  shrink?: string | number,
  wrap?: string | number,
  order?: string | number,
  style?: ?Object,
  justifyContent?: string,
  alignContent?: string,
  $alignItems?: string,
  alignSelf?: string,
  important?: boolean,
  width?: string | number,
  className?: string
|};

const displayPropsParser = ({ inline, important, ignoreDisplay }: Props) => {
  let result;

  if (ignoreDisplay) {
    return null;
  }

  if (inline) {
    result = 'inline-flex';
  } else {
    result = 'flex';
  }

  if (!important) {
    return css`
      display: ${result};
    `;
  }

  return css`
    display: ${result} !important;
  `;
};

const widthPropsParser = (props: Props) => {
  const { width } = props;

  if (!width || typeof width === 'boolean') return null;

  if (isDimension(width)) {
    return width;
  }

  return `${width}px`;
};

const itemsGapParser = props => {
  const {
    gapMode,
    $direction,
    gapDirection,
    gapSize,
    gapDimension = 'px'
  } = props;

  if (!gapSize) {
    return null;
  }

  const axis = gapDirection || $direction;

  const getRightMargin = (negative?: boolean = false) =>
    `margin-right: ${(negative ? -gapSize : gapSize) +
      gapDimension} !important;`;

  const getBottomMargin = (negative?: boolean = false) =>
    `margin-bottom: ${(negative ? -gapSize : gapSize) +
      gapDimension} !important;`;

  if (gapMode === 'compensation') {
    if (axis === 'both') {
      return css`
        ${getRightMargin(true)}
        ${getBottomMargin(true)}

        & > * {
          ${getRightMargin(false)}
          ${getBottomMargin(false)}
        }
      `;
    }

    if (axis === 'column') {
      return css`
        ${getBottomMargin(true)}

        & > * {
          ${getBottomMargin(false)}
        }
      `;
    }

    return css`
      ${getRightMargin(true)}

      & > * {
        ${getRightMargin(false)}
      }
    `;
  }

  if (axis === 'column') {
    return css`
      & > :not(:last-child):not(:first-child) {
        margin-top: ${gapSize / 2 + gapDimension} !important;
        margin-bottom: ${gapSize / 2 + gapDimension} !important;
      }

      & > *:not(:only-child):first-child {
        margin-bottom: ${gapSize / 2 + gapDimension} !important;
      }

      & > *:not(:only-child):last-child {
        margin-top: ${gapSize / 2 + gapDimension} !important;
      }
    `;
  }

  return css`
    & > :not(:last-child):not(:first-child) {
      margin-left: ${gapSize / 2 + gapDimension} !important;
      margin-right: ${gapSize / 2 + gapDimension} !important;
    }

    & > *:not(:only-child):first-child {
      margin-right: ${gapSize / 2 + gapDimension} !important;
    }

    & > *:not(:only-child):last-child {
      margin-left: ${gapSize / 2 + gapDimension} !important;
    }
  `;
};

const flexDirectionParser = ({ $direction }: Props) => {
  if (!$direction) {
    return null;
  }

  return css`
    flex-direction: ${$direction};
  `;
};

const Flexbox: ComponentType<Props> = styled.div`
  ${widthPropsParser};
  ${itemsGapParser};
  ${displayPropsParser};
  ${flexDirectionParser};
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${props =>
    props.basis &&
    css`
      flex-basis: ${props.basis};
    `};
  ${props =>
    props.flow &&
    css`
      flex-flow: ${props.flow};
    `};
  ${props =>
    props.grow &&
    css`
      flex-grow: ${props.grow};
    `};
  ${props =>
    props.shrink &&
    css`
      flex-shrink: ${props.shrink};
    `};
  ${props =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `};
  ${props =>
    props.order &&
    css`
      order: ${props.order};
    `};
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  ${props =>
    props.alignContent &&
    css`
      align-content: ${props.alignContent};
    `};
  ${props =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `};
  ${props =>
    props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `};
`;

export default Flexbox;
