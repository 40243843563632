// @flow
import fp from 'lodash/fp';
import map from 'lodash/map';
import * as h from 'humps';
import type { ITag } from '../../../../flow-types/entities/Tag';
import type { IChecklist } from '../../../../flow-types/entities/Checklist';

const checklistTagsDenormalizer = (checklist: IChecklist) => ({
  ...checklist,
  tags: map(checklist.tags, (tag: ITag) => {
    const { isNew, id, ...safeData } = tag;
    return {
      ...safeData,
      ...(!isNew && {
        id
      })
    };
  })
});

export const checklistDenormalizer = fp.compose(
  h.decamelizeKeys,
  checklistTagsDenormalizer,
  checklist => {
    const { companyId, departmentId, ...safeData } = checklist;

    return {
      ...safeData,
      companyId,
      departmentId
    };
  }
);

export default fp.map(checklistDenormalizer);
