// @flow
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import * as React from 'react';
import cx from 'classnames';
import type { ReactComponent } from 'flow-types/ReactComponent';

export type Props = {
  +group?: boolean,
  +tagName?: ReactComponent,
  +className?: string,
  +wide?:
    | 'one'
    | 'two'
    | 'three'
    | 'four'
    | 'five'
    | 'six'
    | 'seven'
    | 'eight'
    | 'nine'
    | 'ten'
    | 'eleven'
    | 'twelve'
    | 'thirteen'
    | 'fourteen'
    | 'fifteen'
    | 'sixteen',
  +children?: React.Node,
  +label?: string | React.Node,
  +inline?: boolean,
  +disabled?: boolean,
  +required?: boolean,
  +grouped?: boolean,
  +validationState?: null | 'warning' | 'error' | 'success'
};

const Field: React.AbstractComponent<Props, mixed> = React.forwardRef(
  (
    {
      group,
      className,
      children,
      wide,
      label,
      inline,
      validationState,
      disabled,
      required,
      grouped,
      tagName,
      ...rest
    }: Props,
    ref
  ) => {
    const Component = tagName || 'div';

    return (
      <Component
        {...rest}
        ref={ref}
        className={cx(
          className,
          !group && !!wide ? `${wide} wide` : false,
          group && !!wide ? wide : null,
          {
            inline,
            disabled,
            required,
            grouped,
            field: !group,
            fields: group
          },
          validationState
        )}
      >
        {label && <label>{label}</label>}
        {children}
      </Component>
    );
  }
);

Field.displayName = 'Field';

// $FlowIgnore
Field.defaultProps = {
  wide: null,
  children: null,
  label: null,
  inline: false,
  validationState: null,
  group: false,
  disabled: false,
  required: false,
  grouped: false,
  className: null,
  tagName: 'div'
};

export default Field;
