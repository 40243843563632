// @flow

export default function convertDurationObjectToSeconds(duration: {
  hours?: ?number,
  minutes?: ?number,
  seconds?: ?number
}): number {
  let total = 0;

  if (duration.hours) {
    total += duration.hours * 60 * 60;
  }

  if (duration.minutes) {
    total += duration.minutes * 60;
  }

  if (duration.seconds) {
    total += duration.seconds;
  }

  return total;
}
