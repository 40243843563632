// @flow

import { catchError as baseCatchError } from 'rxjs/operators';

import type { SignOut } from 'flow-types/actions/auth/SignOut';
import createSingOut from '../../actions/auth/signOut';

type KeyResolver<ET> = (error: ET) => string;

type CatchErrorConfigObj = {
  // key returns path for a uniq error identifier or is instantly set to its value
  key?: string | KeyResolver,
  responders: {
    // default is required, as soon as it will be called in all other cases,
    // that are not described in responders
    default: (
      err: any,
      caught: rxjs$Observable<any>
    ) => rxjs$ObservableInput<any>,
    // '401': (
    //   err: any,
    //   caught: rxjs$Observable<any>
    // ) => rxjs$ObservableInput<any>,
    [string]: (
      err: any,
      caught: rxjs$Observable<any>
    ) => rxjs$ObservableInput<any>
  }
};

type CatchErrorConfigFunc = (
  err: any,
  caught: rxjs$Observable<any>
) => rxjs$ObservableInput<any>;

const resolvePath = (error, path) => {
  if (typeof path === 'function') return path(error);

  return path;
};

/**
 * Обработчики по-умолчанию для некоторых общих ситуаций
 */
const RESPONDERS = {
  '401': error => [(createSingOut({ manual: false, error }): SignOut)]
};

/**
 * Модифицированный {@link catchError}, который отличается
 * возможностью принимать конфигурацию,
 * для использования при происхождении ошибки.
 *
 * Если в качестве конфигуратора передана функция,
 * то тогда принцип работы как в случае с обычным catchError.
 *
 */
export default function catchError(
  config: CatchErrorConfigObj | CatchErrorConfigFunc
): Function {
  if (typeof config === 'function') {
    return baseCatchError(config);
  }

  if (typeof config !== 'object' || Array.isArray(config)) {
    throw new Error('catchError has wrong config object');
  }

  const { key, responders } = config;

  return baseCatchError((error, caught) => {
    const responderIdentifier = resolvePath(error, key);

    if (
      typeof responderIdentifier !== 'undefined' &&
      responderIdentifier !== null
    ) {
      // Responder это функция, которая отвечает за обработку той или иной ошибки.
      // Когда responder для ошибки не задан, или же ошибку не удалось распознать,
      // то запускается default responder.
      const responder =
        responders?.[responderIdentifier] ||
        RESPONDERS[responderIdentifier] ||
        responders.default;

      return responder(error, caught);
    }

    return responders.default(error, caught);
  });
}
