// @flow

import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  DeletePollingNotification,
  DeletePollingNotificationFail,
  DeletePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/DeletePollingNotification';
import type { Epic } from 'flow-types/Epic';
import { API } from 'utils/config';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import type { FetchPollingNotifications } from 'flow-types/actions/projects/detail/pollingNotifications/list/FetchPollingNotifications';

import {
  DELETE,
  DELETE_SUCCESS
} from '../../../../../reducers/projectDetail/pollingNotifications/detail';
import { projectIdFromPathSelector } from '../../../../../selectors/projects';
import closeModal from '../../../../../actions/modals/close';
import { MODAL_ID } from '../../../components/Modals/PollingNotificationModal';
import { FETCH_LIST } from '../../../../../reducers/projectDetail/pollingNotifications/list';

import type { CloseModal } from '../../../../Modals/modals';

const deletePollingNotification$: Epic = (action$, state$) =>
  action$.pipe<DeletePollingNotification>(
    ofType(DELETE),
    RxO.withLatestFrom(state$),
    RxO.exhaustMap(([{ notificationId }, state]) => {
      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(API.projects.pollingNotifications.detail, {
          projectId,
          notificationId
        }),
        method: 'DELETE'
      }).pipe(
        RxO.mergeMap<
          any,
          [
            FetchPollingNotifications,
            DeletePollingNotificationSuccess,
            CloseModal
          ]
        >(() => [
          {
            type: FETCH_LIST
          },
          {
            type: DELETE_SUCCESS,
            notificationId
          },
          closeModal(MODAL_ID)
        ]),
        RxO.catchError((ajaxError: AjaxError<any>): [
          DeletePollingNotificationFail
        ] => [
          {
            type: 'project/delete-polling-notification-fail',
            notificationId,
            errors: ajaxError.response
              ? ajaxError.response.data
              : ajaxError.message
          }
        ])
      );
    })
  );

export default deletePollingNotification$;
