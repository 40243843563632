import revertDictionary from 'utils/revertDictionary';

const projectFormDictionary = {
  'project.newProject': {
    en: 'New project',
    ru: 'Новый проект'
  },
  'project.form.fields.title.label': {
    en: 'Title',
    ru: 'Название'
  },
  'project.form.fields.recordEnabled.label': {
    en: 'Record audio',
    ru: 'Запись аудио'
  },
  'project.form.fields.shouldAskPermissionToRecord.label': {
    en: 'Ask user for permission to record audio?',
    ru: 'Запрашивать у пользователя разрешения на запись аудио?'
  },
  'project.form.company': {
    en: 'Company',
    ru: 'Компания'
  },
  'project.form.department': {
    en: 'Department',
    ru: 'Отдел'
  },
  'project.form.status': {
    en: 'Last saved',
    ru: 'Последнее сохранение'
  },
  'project.form.departments': {
    en: 'Departments',
    ru: 'Отделы'
  },
  'project.form.checklist.label': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'project.form.checklists': {
    en: 'Checklists',
    ru: 'Чеклисты'
  },
  'project.form.description': {
    en: 'Description',
    ru: 'Описание'
  },
  'project.form.period.start': {
    en: 'Start',
    ru: 'Начало'
  },
  'project.form.period.end': {
    en: 'End',
    ru: 'Конец'
  },
  'project.form.has_post_polling': {
    en: 'Client feedback enabled',
    ru: 'Создание пост-опросов'
  },
  'project.form.for_meetings': {
    en: 'Mobile app meetings enabled',
    ru: 'Создание встреч в мобильном приложении'
  },
  'project.form.fields.showProgress.label': {
    en: 'Show progress bar',
    ru: 'Показывать прогресс'
  },
  'project.form.fields.showTimer.label': {
    en: 'Show timer bar',
    ru: 'Показывать таймер'
  },
  'project.form.fields.should_request_location.label': {
    en: 'Request location on mobile device',
    ru: 'Запрашивать данные геопозиции в мобильном приложении'
  },
  'project.form.settings.post_polling': {
    en: 'Client feedback settings',
    ru: 'Настройки пост-опроса'
  },
  'project.form.post_polling_delay.label': {
    en: 'Delay before link sending',
    ru: 'Задержка перед отправкой ссылки'
  },
  'project.form.post_polling_delay.type.noDelay': {
    en: 'No delay',
    ru: 'Без задержки'
  },
  'project.form.post_polling_delay.type.delay': {
    en: 'With a delay of',
    ru: 'С задержкой в'
  },
  'project.form.post_polling_delay.type.nextDay': {
    en: 'The next day in',
    ru: 'На следующий день в'
  },
  'project.form.post_polling_delay.type.nextWorkingDay': {
    en: 'The next working day in',
    ru: 'На следующий рабочий день в'
  },
  'project.form.checklist.placeholder.selectCompanyToLoad': {
    en: 'Select company to load checklists',
    ru: 'Выберите компанию для загрузки доступных чеклистов'
  },
  'project.form.checklist.placeholder.emptyData': {
    en: 'No checklists',
    ru: 'Нет подходящих чеклистов!'
  },
  'project.form.checklist.placeholder.default': {
    en: 'Choose checklist',
    ru: 'Выберите чеклист для проекта'
  },
  'project.form.checklist.placeholder.isNotSet': {
    en: 'Checklist it not set',
    ru: 'Чеклист не установлен'
  },
  'project.form.poll_link': {
    en: 'Poll link',
    ru: 'Опрос по общей ссылке'
  },
  'project.form.copy_url': {
    en: 'Copy URL',
    ru: 'Копировать ссылку'
  },
  'project.form.copyUrlSuccess': {
    en: 'Copied!',
    ru: 'Скопировано!'
  },
  'project.form.is_entity': {
    en: 'Is entity',
    ru: 'Является сущностью'
  },
  'project.form.entity_name': {
    en: 'Entity name',
    ru: 'Название сущности'
  },
  'project.form.entityLinks': {
    en: 'Links to other entities',
    ru: 'Связи с другими сущностями'
  },
  'project.form.fields.searchSettings.label': {
    ru: 'Настройки поиска',
    en: 'Search settings'
  },
  'project.form.fields.searchSettings.hint': {
    ru: 'Выбирайте блоки в селекторе для использования в поиске по записям',
    en: 'Select block to be used in records search'
  },
  'project.form.fields.settings.label': {
    ru: 'Доп. Настройки',
    en: 'Extra Settings'
  },
  'project.form.fields.settings.mainBrowserTitle.label': {
    ru: 'Заголовок вкладки во время встречи',
    en: 'Tab title while meeting'
  },
  'project.form.fields.settings.postPollingBrowserTitle.label': {
    ru: 'Заголовок вкладки во время пост-опроса',
    en: 'Tab title while post-polling'
  },
  'project.form.fields.settings.publicBrowserTitle.label': {
    ru: 'Заголовок вкладки по публичной ссылке',
    en: 'Tab title when entering via public link'
  },
  'project.form.fields.settings.showRequiredBlockOverlay.label': {
    ru: 'Показывать оверлей-предупреждение об обязательном вопросе',
    en: 'Show required block message overlay'
  },
  'project.form.fields.automaticDataSubmission.label': {
    ru: 'Автоматически отправлять данные из приложения',
    en: 'Instant data synchronization from mobile devices'
  },

  /*
    Project --> Settings --> Appearance Variations
   */
  'project.form.fields.settings.appearance.common.label': {
    ru: 'Общее',
    en: 'Common'
  },
  'project.form.fields.settings.appearance.web.label': {
    en: 'Web-form',
    ru: 'Веб'
  },
  'project.form.fields.settings.appearance.edit.label': {
    en: 'Session Editing',
    ru: 'Редактирование сессии'
  },
  'project.form.fields.settings.appearance.post.label': {
    en: 'Post Polling',
    ru: 'Пост опросы'
  },
  'project.form.fields.settings.appearance.mobile.label': {
    en: 'Mobile App',
    ru: 'Моб. приложение'
  },
  'project.form.fields.settings.appearance.public.label': {
    en: 'Public',
    ru: 'Публичные опросы'
  },
  'project.form.fields.settings.appearance.crm.label': {
    en: 'CRM',
    ru: 'CRM'
  },
  'project.form.fields.settings.appearance.common.enabled.label': {
    en: 'Records creation',
    ru: 'Создание записей'
  },
  'project.form.fields.settings.appearance.post.enabled.label': {
    en: 'Post polling creation',
    ru: 'Создание пост-опросов'
  },
  'project.form.fields.settings.appearance.crm.enabled.label': {
    en: 'Treat as an entity',
    ru: 'Является сущностью'
  }
};

export default revertDictionary(projectFormDictionary);
