// @flow

import { combineReducers } from 'redux';
import type { Action } from 'flow-types/Action';
import type { AppState } from 'flow-types/AppState';
import AppEnvironment from 'utils/AppEnvironment';
import auth from './auth';
import companies from './companies';
import page from './page';
import checks from './checks';
import projectList from './projectList';
import projectDetail from './projectDetail';
import records from './records';
import language from './language';
import interview from './interview';
import connectedRoutes from '../router';
import meetings from '../pages/Meetings/Meetings.reducer';
import tasksWatchers from '../pages/TasksWatchers/reducer';
import modals from '../pages/Modals/modals';
import profile from './profile';
import queryComposer from '../crm/query/Query.reducer';
import crm from '../crm/global_context/Crm.reducer';
import sideProcesses from './sideProcesses';
import buildings from '../pages/Buildings/reducer';

const { reducer: location } = connectedRoutes;

// TODO: optimize reducer, make it more performant.
//  Нужно будет сделать так, чтобы у пользователя, который, например,
//  не имеет доступа к странице опроса попросту не подключался редьюсер этой страницы.
//  По факту это же правило работает и для Epic'ов redux-observable'а.
const appReducer = combineReducers({
  auth,
  companies,
  page,
  location,
  checklists: checks,
  records,
  language,
  interview,
  meetings,
  projectDetail,
  projectList,
  modals,
  queryComposer,
  crm,
  profile,
  sideProcesses,
  tasksWatchers,
  // TODO: limit by extra feature flag
  ...(AppEnvironment.buildings && {
    buildings
  })
});

export default function rootReducer(state: AppState, action: Action): AppState {
  // on user sign-out event, reset all states expect location and page reducers
  if (action.type === 'auth/sign-out') {
    // eslint-disable-next-line no-shadow
    const { location, page, interview, language }: AppState = state;

    const savedState: $Shape<AppState> = { location, page, language };

    if (['Interview', 'Interviews'].includes(page.name)) {
      savedState.interview = interview;
    }

    // eslint-disable-next-line no-param-reassign
    return appReducer(savedState, action);
  }

  return appReducer(state, action);
}
