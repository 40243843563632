// @flow

import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';

import type { IResponse } from 'flow-types/entities/Response';
import type { IInterviewRecord } from 'flow-types/entities/InterviewRecord';

import uploadsNormalizer from '../uploads/normalizers';
import interviewAnswersNormalizer, {
  interviewAnswersCommentsMixin
} from './answersNormalizer';
import convertViewsToRegistryRecords from './convertViewsToRegistryRecords';

const responseUploadsNormalizer = response => {
  const { uploads, attachments, records } = response;

  return {
    ...response,
    uploads: uploadsNormalizer(uploads),
    attachments: uploadsNormalizer(attachments),
    records: uploadsNormalizer(records)
  };
};

const responseAnswersNormalizer = response => {
  const { answers, comments } = response;

  const normalizedAnswers = interviewAnswersNormalizer(answers);

  return {
    ...response,
    answers: interviewAnswersCommentsMixin(normalizedAnswers, comments)
  };
};

const responseCommentsNormalizer = response => {
  const { comments } = response;
  return {
    ...response,
    comments
  };
};

const responseViewsNormalizer = response => {
  const { views } = response;

  const registry = convertViewsToRegistryRecords(views);

  return {
    ...response,
    registry,
    views
  };
};

const normalizer: (response: IResponse) => IInterviewRecord = fp.compose(
  responseAnswersNormalizer,
  responseViewsNormalizer,
  responseCommentsNormalizer,
  responseUploadsNormalizer,
  camelizeKeys
);

export default normalizer;
