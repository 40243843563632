// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import size from 'lodash/size';
import Form from 'common/components/Form';
import Button from 'common/components/Button';
import Field from 'common/components/Form/Field';
import Input, { InputWrapper } from 'common/components/Input';
import ConfirmButton from 'common/components/ConfirmButton';
import Icon from 'common/components/Icon';
import random from 'common/helpers/random';

import type { ISide } from 'common/containers/Messenger/flow';

type Props = {|
  initialData: ISides,
  onSubmit: (sides: ISides) => void
|};

const SidesForm = ({ initialData, onSubmit, ...props }: Props) => {
  const [sides, set] = useState<ISides>([]);

  useEffect(() => {
    if (initialData) {
      set(initialData);
    }
  }, [initialData]);

  const addSide = useCallback(() => {
    set(prev => {
      if (size(prev) >= 255) return prev;

      const side = {
        id: random(
          1,
          255,
          map(prev, p => p.id)
        ),
        side: size(prev) === 0 ? 'left' : 'right'
      };

      return [...prev, side];
    });
  }, []);

  const removeSide = useCallback(sideId => {
    set(prev => prev.filter(side => side.id !== sideId));
  }, []);

  const updateSide = useCallback((sideId, dataUpdate) => {
    set(prev =>
      prev.map((side: ISide) => {
        if (side.id !== sideId) return side;

        return {
          ...side,
          ...dataUpdate
        };
      })
    );
  }, []);

  return (
    <Form {...props} onSubmit={() => onSubmit(sides)}>
      <Field>
        <Button
          onClick={addSide}
          buttonType="primary"
          type="button"
          disabled={size(sides) >= 255}
        >
          <FormattedMessage
            id={
              size(sides) >= 255
                ? 'transcriptions.sidesForm.validation.sidesLimit'
                : 'transcriptions.sidesForm.actions.addSide'
            }
          />
        </Button>
      </Field>
      {map(sides, (side: ISide) => (
        <Field key={side.id}>
          <InputWrapper action="right">
            <Input
              value={side.name}
              onChange={dataUpdate => updateSide(side.id, dataUpdate)}
              name="name"
              useNameAsValueKey
              onlyValue
            />
            <ConfirmButton
              icon
              buttonType="negative"
              type="button"
              onSubmit={() => removeSide(side.id)}
              confirmMessage={
                <FormattedMessage id="transcriptions.sidesForm.messages.confirmSideRemove" />
              }
            >
              <Icon icon="trash" />
            </ConfirmButton>
          </InputWrapper>
        </Field>
      ))}
    </Form>
  );
};

export default SidesForm;
