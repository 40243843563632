// @flow

import type { RecordsActions } from 'flow-types/actions/records';
import type { RecordsPeaksState } from 'flow-types/states/RecordsState';
import type {
  FetchRecordPeaks,
  FetchRecordPeaksFail,
  FetchRecordPeaksSuccess
} from 'flow-types/actions/records/FetchRecordPeaks';
import type { $ActionType } from 'flow-types/ActionType';

type ActionHandler<T> = (
  state: RecordsPeaksState,
  action: T
) => RecordsPeaksState;

const initialState: RecordsPeaksState = {};

export const FETCH_PEAKS: $ActionType<FetchRecordPeaks> = 'records-peaks/fetch';

export const FETCH_PEAKS_FAIL: $ActionType<FetchRecordPeaksFail> =
  'records-peaks/fetch-fail';

export const FETCH_PEAKS_SUCCESS: $ActionType<FetchRecordPeaksSuccess> =
  'records-peaks/fetch-success';

export default (
  state: RecordsPeaksState = initialState,
  action: RecordsActions
): RecordsPeaksState => {
  switch (action.type) {
    case 'records-peaks/fetch':
      // eslint-disable-next-line no-use-before-define
      return handleFetch(state, action);

    case 'records-peaks/fetch-success':
      // eslint-disable-next-line no-use-before-define
      return handleFetchSuccess(state, action);

    default:
      return state;
  }
};

const handleFetch: ActionHandler<FetchRecordPeaks> = (state, action) => {
  const next: RecordsPeaksState = { ...state };

  // $FlowIgnore
  if (!next[action.recordId]) {
    // $FlowIgnore
    next[action.recordId] = {
      status: 'loading',
      data: null
    };
  } else {
    // $FlowIgnore
    next[action.recordId] = {
      ...next[action.recordId],
      status: 'loading'
    };
  }

  return state;
};

const handleFetchSuccess: ActionHandler<FetchRecordPeaksSuccess> = (
  state,
  action
) => {
  const next: RecordsPeaksState = { ...state };

  // $FlowIgnore
  next[action.recordId] = {
    // $FlowIgnore
    ...next[action.recordId],
    status: 'success',
    data: action.peaks
  };

  return next;
};
