// @flow

import { EMPTY, merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import findIndex from 'lodash/findIndex';
import * as RxOperators from 'rxjs/operators';
import { camelizeKeys } from 'humps';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import decamelizeAndDenormalizeQuestionsGroup from 'common/transducers/projects/questionGroupDenormalizer';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeAndNormalizeQuestionGroup } from 'common/transducers/projects/questionGroupsNormalizer';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import moveListAndReorder from 'common/helpers/moveArrayAndReorder';
import { projectGroupsStateSelector } from '../../../selectors/projects';
import { locationStateSelector } from '../../../selectors';
import { hasPostPollSelector } from '../components/Panels/ResponsesPanel/selectors';

const selector: Function = createSelector(locationStateSelector, location => {
  const { payload = {} } = location;
  return {
    ...payload
  };
});

const saveGroupEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/save-group'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([action, state]) => {
      const { id } = selector(state);

      return {
        projectId: id,
        ...action
      };
    }),
    RxOperators.filter(({ projectId }) => projectId !== 'new'),
    RxOperators.mergeMap(action => {
      const { group } = action;

      if (group.logoFile) {
        return request({
          url: API.uploads.binary,
          method: 'POST',
          crossDomain: true,
          body: group.logoFile,
          headers: {
            'Content-Type': group.logoFile.type
          }
        }).pipe(
          responseParser,
          RxOperators.map(({ data }) => {
            const normalizedUploadData = camelizeKeys(data);

            return {
              group: {
                ...group,
                logoToken: normalizedUploadData.uploadToken
              }
            };
          }),
          RxOperators.catchError(() => of({ group, error: true }))
        );
      }

      return of({ group });
    }),
    RxOperators.mergeMap(({ group, revokeUrl }) => {
      const isUpdate = !group.isNew;

      const body = decamelizeAndDenormalizeQuestionsGroup(group);

      const method = group.isNew ? 'POST' : 'PUT';

      return request({
        url: !isUpdate
          ? API.questionGroups.list
          : API.questionGroups.detail.replace(':question_group_id', body.id),
        method,
        body
      }).pipe(
        responseParser,
        RxOperators.mergeMap(response => {
          const { data } = response;

          const normalizedGroup: IQuestionGroup = camelizeAndNormalizeQuestionGroup(
            data
          );

          if (revokeUrl) {
            URL.revokeObjectURL(revokeUrl);
          }

          return merge(
            of({
              type: 'project-groups/select-group',
              questionGroupId: normalizedGroup.id
            }),
            of({
              type: 'project-groups/save-group-success',
              data: normalizedGroup,
              // replace not saved group data with saved
              ...(!isUpdate && {
                originalId: body.id
              })
            }),
            !isUpdate
              ? of({
                  type: 'project-groups/add-question-process',
                  questionGroupId: normalizedGroup.id
                })
              : EMPTY,
            isUpdate
              ? EMPTY
              : of({}).pipe(
                  RxOperators.withLatestFrom($state),
                  RxOperators.mergeMap(([, _state]) => {
                    const hasPostPolling = hasPostPollSelector(_state);

                    if (!hasPostPolling) return EMPTY;

                    // if group is located after post polling stack
                    // then it should be moved before post polling stack
                    const { data: groupList } = projectGroupsStateSelector(
                      _state
                    );

                    const sourceIndex = normalizedGroup.order;
                    const destinationIndex = findIndex(
                      groupList,
                      (g: IQuestionGroup) => g.postPollingBefore
                    );

                    if (
                      destinationIndex !== -1 &&
                      sourceIndex > destinationIndex
                    ) {
                      const nextGroups = moveListAndReorder(
                        groupList,
                        sourceIndex,
                        destinationIndex
                      );

                      return of({
                        type: 'project-groups/update-groups-order',
                        groups: nextGroups,
                        noInfo: true
                      });
                    }

                    return EMPTY;
                  })
                )
          );
        }),
        RxOperators.catchError(({ response, message, status }) => {
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'project-groups/save-group-fail',
            ...(!isUpdate && {
              originalId: body.id
            }),
            data: group,
            error: response ? camelizeKeys(response.data) : message
          });
        })
      );
    })
  );

export default saveGroupEpic;
