// @flow

import fp from 'lodash/fp';
import filter from 'lodash/filter';

import type { IComment } from 'flow-types/entities/Comment';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { IInterviewAnswers$Map } from 'flow-types/states/InterviewState/InterviewAnswersState';

import normalizeAnswer from '../answers/answerNormalizer';

const interviewAnswersNormalizer: Function = fp.compose(
  fp.reduce((answers, answer: IInterviewAnswer) => {
    const { question } = answer;

    if (!question) return answers;

    return {
      ...answers,
      [question.id]: answer
    };
  }, {}),
  fp.map(normalizeAnswer)
);

const uniqIdsGetter = fp.compose(fp.uniq, fp.map(fp.get('questionId')));

export const interviewAnswersCommentsMixin = (
  answers: IInterviewAnswers$Map,
  interviewComments: IComment[]
): Object => {
  const next = { ...answers };

  /**
   * Так как может такое быть, что прикрепление сделано к вопросу,
   * на который может не быть ответа, то необходимо по другому миксить прикпреления к ответу.
   */

  // строим уникальный массив айдишников вопросов,
  // к которым есть комментарии.
  const questionsIds: number[] = uniqIdsGetter(interviewComments);

  questionsIds.forEach(questionId => {
    const comments = filter(
      interviewComments,
      (comment: IComment) => comment.questionId === +questionId
    );

    // $FlowIgnore
    if (!next[questionId]) {
      // $FlowIgnore
      next[questionId] = {
        data: null,
        status: 'submitted',
        questionId,
        comments,
        meta: {}
      };
    } else {
      // TODO: optimize that part later
      // $FlowIgnore
      next[questionId].comments = comments;
    }
  });

  return next;
};

export default interviewAnswersNormalizer;
