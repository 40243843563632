// @flow

import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { AjaxError, AjaxRequest } from 'rxjs/ajax';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import request from '../../utils/request';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import listResponseParser from '../../common/epicHelpers/listResponseParser';
import isNewElement from '../../common/helpers/isNewElement';
import { checkListGroupDenormalizer } from '../../common/transducers/checks/groups/denormalizer';
import { checkListGroupNormalizer } from '../../common/transducers/checks/groups/normalizer';
import decamelizeKeys from '../../common/helpers/decamelizeKeys';
import type { Epic } from '../../flow-types/Epic';
import { checksStateSelector } from '../../selectors';
import type { SaveChecklistGroup } from '../../flow-types/actions/checks/checklistGroups/SaveChecklistGroup';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

const fetchGroupsEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('checklist-test-groups/fetch'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([, state]) => {
      const {
        lists: { selected },
        groups: { filter }
      } = checksStateSelector(state);

      return {
        filter: {
          ...filter,
          checklistId: selected
        }
      };
    }),
    debounceEpic(),
    RxOperators.switchMap(({ filter }) => {
      const query = qs.stringify(decamelizeKeys(filter), {
        addQueryPrefix: true,
        skipNulls: true
      });

      const url = API.testGroups.list;

      const params: AjaxRequest = {
        url: `${url}${query}`,
        method: 'GET'
      };

      return request(params).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'checklist-test-groups/fetch-success',
          data: camelizeKeys(data),
          pagination
        })),
        RxOperators.catchError((error: AjaxError) =>
          of({
            type: 'checklist-test-groups/fetch-fail',
            error: error.response ? error.response.data : error.message
          })
        )
      );
    })
  );

const saveGroupEpic: Epic = $action =>
  $action.pipe(
    ofType('checklist-test-groups/save'),
    RxOperators.map(action => {
      const { testGroup }: SaveChecklistGroup = action;

      const isUpdate = !isNewElement(testGroup);

      return {
        body: checkListGroupDenormalizer(testGroup),
        isUpdate
      };
    }),
    RxOperators.mergeMap(requestData =>
      request({
        url: requestData.isUpdate
          ? API.testGroups.detail.replace(':test_group_id', requestData.body.id)
          : API.testGroups.list,
        method: requestData.isUpdate ? 'PUT' : 'POST',
        body: requestData.body
      }).pipe(
        responseParser,
        RxOperators.mergeMap(response => {
          if (requestData.isUpdate) {
            return of(
              {
                type: 'checklist-test-groups/save-success',
                testGroup: checkListGroupNormalizer(response.data)
              },
              { type: 'checklist-test-groups/fetch' }
            );
          }

          return of(
            {
              type: 'checklist-test-groups/save-success',
              testGroup: checkListGroupNormalizer(response.data)
            },
            { type: 'checklist-test-groups/fetch' },
            {
              type: 'checklist-test-groups/select',
              testGroupId: response.data.id
            }
          );
        }),
        RxOperators.catchError(({ message, response, status }) => {
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'checklist-test-groups/save-fail',
            error: message,
            ...(response &&
              response.data && {
                error: camelizeKeys(response.data)
              })
          });
        })
      )
    )
  );

const deleteGroupEpic: Epic = $action =>
  $action.pipe(
    ofType('checklist-test-groups/delete'),
    RxOperators.pluck('testGroupId'),
    RxOperators.switchMap(groupId =>
      request({
        url: API.testGroups.detail.replace(':test_group_id', groupId),
        method: 'DELETE'
      }).pipe(
        RxOperators.mergeMap(() =>
          of(
            {
              type: 'checklist-test-groups/delete-success',
              originalId: groupId
            },
            {
              type: 'checklist-test-groups/fetch'
            }
          )
        ),
        RxOperators.catchError(errorResponse => ({
          type: 'checklist-test-groups/delete-fail',
          error: errorResponse.response
            ? errorResponse.response.data
            : errorResponse.message
        }))
      )
    )
  );

const onChecklistSelectionEpic = $action =>
  $action.pipe(
    ofType('checklist-test-groups/select'),
    RxOperators.map(() => ({ type: 'checklist-comparators/fetch' }))
  );

export default combineEpics(
  fetchGroupsEpic,
  saveGroupEpic,
  deleteGroupEpic,
  onChecklistSelectionEpic
);
