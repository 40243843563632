import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import size from 'lodash/size';
import qs from 'qs';
import { toast } from 'react-toastify';
import { of } from 'rxjs';
import { createSelector } from 'reselect';
import {
  EXPERT,
  COMPANY_ADMIN,
  DIVISION_HEAD,
  MANAGER,
  OPERATOR,
  RESPONDENT
} from 'common/helpers/user';
import type { Epic } from '../../../flow-types/Epic';
import debounceEpic from '../../../common/epicHelpers/debounceEpic';
import decamelizeKeys from '../../../common/helpers/decamelizeKeys';

import request from '../../../utils/request';
import { API } from '../../../utils/config';
import responseParser from '../../../common/epicHelpers/responseParser';
import listResponseParser from '../../../common/epicHelpers/listResponseParser';
import usersNormalizer from '../../../common/transducers/users/normalizer';
import {
  companiesPageCompaniesSelector,
  companiesPageDepartmentsSelector,
  companiesPageUsersSelector
} from '../../../selectors/companies';

const usersFilterSelector: Function = createSelector(
  companiesPageUsersSelector,
  companiesPageDepartmentsSelector,
  companiesPageCompaniesSelector,
  (users, departments, companies) => {
    const { filter } = users;
    const { selected: company } = companies;
    const { selected: department } = departments;

    return {
      ...filter,
      ...(company && {
        companyId: company.id,
        ...(department && {
          departmentId: department.id
        })
      })
    };
  }
);

const fetchUsersEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/fetch-users'),
    debounceEpic(),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([, state]) => {
      const { search, ...filter } = usersFilterSelector(state);

      return {
        filter: decamelizeKeys({
          ...filter,
          // as we use users api, we have to limit fetched roles
          // we do not do it via reducer because user can turn off all of them,
          // thus making it possible to receive data of all users
          roleId: [
            COMPANY_ADMIN,
            DIVISION_HEAD,
            MANAGER,
            OPERATOR,
            RESPONDENT,
            EXPERT
          ],
          ...(size(filter.roleId) > 0 && {
            roleId: filter.roleId
          }),
          search: search && search.length > 0 ? search : null
        })
      };
    }),
    RxOperators.switchMap(({ filter }) => {
      const query = qs.stringify(filter, {
        skipNulls: true,
        addQueryPrefix: true
      });

      return request({
        url: `${API.users.list}${query}`,
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'companies/fetch-users-success',
          data: usersNormalizer(data),
          pagination
        })),
        RxOperators.catchError(({ response, message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'companies/fetch-users-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

export default fetchUsersEpic;
