// @flow

import { combineReducers } from 'redux';
import type { Reducer } from 'flow-types/Reducer';
import type { AudioRecordsState } from 'flow-types/states/RecordsState';
import type { Action } from 'flow-types/Action';
import list from './recordsList';
import peaks from './recordsPeaks';
import regions from './recordsRegions';
import decodings from './decodings';

export default (combineReducers({
  list,
  peaks,
  regions,
  decodings
}): Reducer<AudioRecordsState, Action>);
