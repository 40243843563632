// @flow
import type { Bag } from 'redux-first-router';
import { NOT_FOUND, redirect } from 'redux-first-router';
import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import request from 'utils/request';
import { API } from 'utils/config';
import type { Thunk } from 'flow-types/router/Thunk';

import interpolateString from '../helpers/interpolateString';

const signInWithPublicHashThunk: Thunk = async (
  dispatch,
  getState,
  { action: { meta } }: Bag,
  fallbackURL,
  routeName
) => {
  const hash = get(meta, 'query.publicHash', null);

  if (!hash) {
    dispatch({ type: 'auth/sign-in-fail' });
    dispatch(redirect({ type: fallbackURL || NOT_FOUND }));
    return true;
  }

  // here we get token for hash
  try {
    const hashResponse = await request({
      // why it is changed look go to api.publicHashLogic var declaration
      url: interpolateString(API.publicHashLogin, { hash }),
      withCredentials: true,
      method: 'GET',
      // body: {
      //   hash
      // },
      headers: {
        Authorization: null
      }
    }).toPromise();

    const {
      response: { data, token, user }
    } = hashResponse;

    if (!data) {
      dispatch(redirect({ type: fallbackURL || NOT_FOUND }));
      dispatch({ type: 'auth/sign-in-fail' });
      return true;
    }

    const responseMeta = camelizeKeys(data);

    dispatch({
      type: 'auth/sign-in-success',
      user: user ? camelizeKeys(user) : null,
      accessToken: token,
      rememberMe: false,
      scope: [routeName],
      meta: responseMeta
    });

    return false;
  } catch (e) {
    dispatch(redirect({ type: fallbackURL || NOT_FOUND }));
    dispatch({ type: 'auth/sign-in-fail' });
    return true;
  }
};

export default signInWithPublicHashThunk;
