// @flow

import type { Node } from 'react';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';

import Rating from 'common/components/Rating';
import Button from 'common/components/Button';
import Slider, { StyledTrackContainer } from 'common/components/Range';

import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';
import type { IInterviewQuestion } from 'flow-types/entities/Question';
import { FormattedMessage } from 'react-intl';
import { processRatingValue } from 'common/components/Question/RatingQuestionBody/utils';

type RatingQuestionBodyProps = {
  question: IInterviewQuestion,
  onAnswerInput: Function
};

const Container = styled.div`
  ${StyledTrackContainer} {
    height: 6rem;
  }
`;

const RatingQuestionBody = ({
  question,
  onAnswerInput
}: RatingQuestionBodyProps): Node => {
  const answer = useAnswer(question.id);

  const update = useAnswerUpdate(question.id);

  const changeAnswer = useCallback(
    nextValue => {
      update(nextValue);
      /**
       * As soon as rating gives a simple data value of type "number" it'll be set on a correct place in reducer.
       * However, onAnswerInput still awaits {@link IInterviewAnswer} format
       */
      onAnswerInput({ data: nextValue });
    },
    [onAnswerInput, update]
  );

  const labels = useMemo(
    () =>
      // $FlowIgnore
      reduce(
        question.settings?.labels,
        (result, settings) => ({
          ...result,
          [settings.value]: settings
        }),
        {}
      ) || null,
    [question.settings]
  );

  const val = answer?.data;

  const min = question.minValue || 0;

  const max = question.maxValue || 1;

  if (question.viewType === 'slider') {
    return (
      <Container>
        <Slider
          showMarkers
          min={min}
          max={max}
          disabled={!!question.disabled}
          values={
            isNil(val)
              ? []
              : // $FlowIgnore
                [processRatingValue(((val: any): number), min, max)]
          }
          step={1}
          onChange={update}
          labels={labels}
        />
        <Button
          compact
          size="small"
          disabled={question.disabled}
          onClick={() => update(null)}
          type="button"
          className="basic"
          buttonType="primary"
        >
          <FormattedMessage id="common.labels.clearSelection" />
        </Button>
      </Container>
    );
  }

  return (
    <Rating
      marks={labels}
      min={min}
      max={max}
      disabled={!!question.disabled}
      value={processRatingValue((val: any), min, max)}
      color="#FFCB08"
      labelled
      icon={question.iconSet || 'star'}
      onChange={changeAnswer}
    />
  );
};

export default RatingQuestionBody;
