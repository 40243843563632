// @flow

import omit from 'lodash/omit';
import { merge } from 'merge-anything';
import isPlainObject from 'lodash/isPlainObject';
import constructObjectMap from 'common/helpers/constructObjectMap';

import type { ProjectState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectsAction } from 'flow-types/actions/projects';
import type { UpdateProjectUnsafe } from 'flow-types/actions/projects/detail/project/UpdateProject';
import type { Reducer } from 'flow-types/Reducer';
import type { reduxFirstRouter$Action } from 'flow-types/redux-first-router';

const initialState: ProjectState = {
  data: null,
  cache: null,
  error: null,
  loading: null,
  silent: false,
  saving: false,
  editingQuestionGroup: null
};

const projectStateReducer: Reducer<
  ProjectState,
  ProjectsAction | reduxFirstRouter$Action
> = (state = initialState, action) => {
  switch (action.type) {
    case 'project/reset':
      return initialState;

    case 'router/projectPage':
      if (action.payload.projectId !== 'new') {
        return state;
      }

      return {
        ...state,
        data: {
          id: -1,
          author: null,
          status: -1,
          title: '',
          description: '',
          haveForeignKeys: false,
          checklists: [],
          companyId: null,
          company: null,
          departmentIds: [],
          departments: [],
          copyOf: null,
          isNew: true,
          timeStart: new Date(),
          timeEnd: new Date(),
          groups: [],
          settings: {
            appearance: {
              crm: {
                enabled: false,
                progress: {
                  enabled: false
                },
                timer: {
                  enabled: false
                },
                showRequiredBlockOverlay: false
              },
              mobile: {
                enabled: false,
                progress: {
                  enabled: false
                },
                timer: {
                  enabled: false
                },
                showRequiredBlockOverlay: false,
                forMeetings: false,
                automaticDataSubmission: false,
                onlyTodaysMeetings: false,
                recordEnabled: false,
                shouldAskPermissionToRecord: false,
                shouldRequestLocation: false
              },
              post: {
                enabled: false,
                progress: {
                  enabled: false
                },
                timer: {
                  enabled: false
                },
                showRequiredBlockOverlay: false
              },
              public: {
                enabled: false,
                progress: {
                  enabled: false
                },
                timer: {
                  enabled: false
                },
                showRequiredBlockOverlay: false
              },
              web: {
                enabled: true,
                progress: {
                  enabled: true
                },
                timer: {
                  enabled: true
                },
                showRequiredBlockOverlay: true
              }
            },
            browserTitles: {
              publicTitle: '',
              postPollingTitle: '',
              mainTitle: ''
            }
          }
        }
      };

    case 'project/save':
      return {
        ...state,
        saving: true
      };
    case 'project/save-fail':
      return {
        ...state,
        saving: false,
        error: action.error
      };
    case 'project/save-success':
      return {
        ...state,
        saving: false,
        error: null
      };

    case 'project/update-unsafe':
      // eslint-disable-next-line no-use-before-define
      return updateHandler(state, action);

    case 'project/update':
      // $FlowIgnore
      return {
        ...state,
        data: {
          ...state.data,
          ...action.dataUpdate
        },
        ...(state.error &&
          isPlainObject(state.error) && {
            error: omit(state.error, Object.keys(action.dataUpdate))
          })
      };
    case 'project/update-cache':
      return {
        ...state,
        cache: {
          ...state.cache,
          ...action.dataUpdate
        }
      };

    case 'project/fetch':
      return {
        ...state,
        silent: !!action.silent,
        loading: true
      };

    case 'project/fetch-fail':
      return {
        ...state,
        error: action.error,
        silent: false,
        loading: false
      };
    case 'project/fetch-success':
      return {
        ...state,
        ...initialState,
        cache: action.project,
        data: action.project,
        silent: false,
        loading: false
      };
    default:
      return state;
  }
};

export default projectStateReducer;

function updateHandler(state: ProjectState, action: UpdateProjectUnsafe) {
  const next = { ...state };

  // COMPANY_ID UPDATE SIDE EFFECT
  // if companyId is passed and its not equal to current companyId,
  // then clear respondent field
  if (
    next.data &&
    action.data.companyId &&
    next.data.companyId !== action.data.companyId
  ) {
    next.data.departmentIds = [];

    next.data.checklists = [];
    // TODO: remove after FORMS-442
    next.data.checklistId = null;
  }

  next.data = merge(state.data, action.data);

  if (typeof next.error === 'object') {
    next.error = omit(
      next.error,
      constructObjectMap(action.data, { keepPaths: true })
    );
  }

  return next;
}
