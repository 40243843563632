import fp from 'lodash/fp';
import { decamelizeKeys } from 'humps';

const axisToBackend = (axis = 'rows') => settingsObj => {
  let { settings } = settingsObj;

  settings = {
    ...settings,
    [axis]:
      settings?.[axis]?.map(settingsRow => {
        const [prefix, id] = settingsRow.split(':');

        if (prefix === 'ch') {
          return {
            checklistId: +id
          };
        }

        if (prefix === 'chg') {
          return {
            checklistGroupId: +id
          };
        }

        if (prefix === 'chi') {
          return {
            checklistItemId: +id
          };
        }

        if (prefix === 'f') {
          return {
            field: id
          };
        }

        return {
          questionId: +id
        };
      }) ?? []
  };

  return { ...settingsObj, settings };
};

const toBackend = fp.compose(
  decamelizeKeys,
  axisToBackend('rows'),
  axisToBackend('columns')
);

export const toBackendMap = fp.map(toBackend);

export default toBackend;
