/* eslint-disable react/jsx-props-no-spreading */
// @flow

import React from 'react';
import cx from 'classnames';

import type { Node } from 'react';

type Props = {
  className?: null | string
};

const Actions = ({ className, ...props }: Props): Node => (
  <div {...props} className={cx('actions', className)} />
);

Actions.defaultProps = {
  className: null
};

export default Actions;
