// @flow

import LogRocket from 'logrocket';
import { LOGROCKET, LOGROCKET_APP_ID } from 'utils/config';

import type { IUser } from 'flow-types/entities/User';

export const isLogRocketEnabled: boolean =
  LOGROCKET === true || LOGROCKET === 'true';

export function identifyInLogRocket(user: IUser): void {
  LogRocket.identify(`user-${user.id}`, {
    name: user.name,
    email: user.email,
    roleId: user.roleId
  });
}

export function initLogRocket(): void {
  LogRocket.init(LOGROCKET_APP_ID);
}
