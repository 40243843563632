import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import { API } from 'utils/config';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import { projectIdAndGroupsSelector } from '../../../selectors/projects';

// eslint-disable-next-line
let _toastId = null;

const updateGroupsOrderEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/update-groups-order'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(([{ noInfo }, state]) => {
      if (!noInfo && toast.isActive(_toastId)) {
        toast.dismiss(_toastId);
      }

      if (!noInfo) {
        _toastId = toast('Changing groups order...', {
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2500
        });
      }

      const { projectId, groups } = projectIdAndGroupsSelector(state);

      // groups from state are already ordered by front
      const orderedIds = groups.filter(g => !g.isNew).map(g => g.id);

      return {
        projectId,
        orderedIds,
        noInfo
      };
    }),
    RxOperators.filter(
      ({ projectId }) =>
        projectId !== 'new' &&
        projectId !== null &&
        typeof projectId !== 'undefined'
    ),
    RxOperators.mergeMap(
      (requestConfig: {|
        +projectId: number,
        orderedIds: Array<number>,
        noInfo: boolean
      |}) => {
        const { projectId, orderedIds, noInfo } = requestConfig;

        return request({
          url: API.questionGroups.sort.replace(':project_id', projectId),
          body: orderedIds,
          method: 'PUT'
        }).pipe(
          responseParser,
          RxOperators.map((response: {| data: Array |}) => {
            const { data } = response;
            if (!noInfo) {
              toast.update(_toastId, {
                type: toast.TYPE.SUCCESS,
                render: 'Order has been changed',
                autoClose: 750,
                hideProgressBar: true,
                closeButton: true
              });
            }
            return {
              type: 'project-groups/update-order-success',
              data
            };
          }),
          RxOperators.catchError(({ response, message, status }: AjaxError) => {
            if (!noInfo) {
              toast.update(_toastId, {
                type: toast.TYPE.ERROR,
                render:
                  +status === 422
                    ? 'Provided data is not acceptable (422)'
                    : message,
                autoClose: 1500
              });
            }
            return of({
              type: 'project-groups/update-order-fail',
              error: response ? response.error : message
            });
          })
        );
      }
    )
  );

export default updateGroupsOrderEpic;
