import React, { useCallback, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import useWindowResize from 'common/hooks/useWindowResize';

const GlobalStyles = createGlobalStyle`
  :root {
    --vh: ${props => (props.vh ? `${props.vh}px` : null)};
    --vw: ${props => (props.vw ? `${props.vw}px` : null)};
  };
`;

export default function AppSizeSpy() {
  // sizes reducer --
  const [vh, setVH] = useState(100);
  const [vw, setVW] = useState(100);
  const latestVH = React.useRef(vh);
  const latestVW = React.useRef(vw);
  // sizes reducer --

  const timerRef = React.useRef(null);

  const resizeCallback = useCallback(event => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    function resize() {
      const nextVH = window.innerHeight * 0.01;
      const nextVW = window.innerWidth * 0.01;

      if (latestVH.current !== nextVH) {
        latestVH.current = nextVH;
        setVH(nextVH);
      }

      if (latestVW.current !== nextVW) {
        latestVW.current = nextVW;
        setVW(nextVW);
      }
    }

    // When `touchend` ends there could be some time gap between `touchend` being just emitted
    // and its "final" 'end'.
    // To understand the term "final" here try to zoom out on, for example, some iOS device and you'll see
    // that it takes some time for WebView of browser to take up all available space again,
    // when you've zoomed out enough to make WebView smaller,
    // than your device's screen.
    //
    // AFAIK, `window.innerHeight` is adjusted according to the zoom level and I may be wrong,
    // but I wanna guarantee that correct values are used.
    if (event?.type === 'touchend') {
      timerRef.current = setTimeout(resize, 300);
      return;
    }

    resize();
  }, []);

  useWindowResize(resizeCallback);

  return <GlobalStyles vh={vh} vw={vw} />;
}
