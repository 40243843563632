export const ADMIN = 1;
export const OPERATOR = 2;
export const MANAGER = 3;
export const COMPANY_ADMIN = 4;
export const DIVISION_HEAD = 5;
export const RESPONDENT = 6;
export const TMP_RESPONDENT = 7;
export const EXPERT = 8;

export const MANAGER_TYPE = [MANAGER, OPERATOR, EXPERT];

export const ADMIN_INTL_MESSAGE_ID = 'userRoles.admin';
export const OPERATOR_INTL_MESSAGE_ID = 'userRoles.operator';
export const MANAGER_INTL_MESSAGE_ID = 'userRoles.manager';
export const COMPANY_ADMIN_MESSAGE_ID = 'userRoles.companyAdmin';
export const DIVISION_HEAD_INTL_MESSAGE_ID = 'userRoles.divisionHead';
export const RESPONDENT_INTL_MESSAGE_ID = 'userRoles.respondent';
export const EXPERT_INTL_MESSAGE_ID = 'userRoles.expert';

export const getUserRoleINTLMessageId = role => {
  switch (role) {
    case ADMIN:
      return ADMIN_INTL_MESSAGE_ID;
    case OPERATOR:
      return OPERATOR_INTL_MESSAGE_ID;
    case MANAGER:
      return MANAGER_INTL_MESSAGE_ID;
    case COMPANY_ADMIN:
      return COMPANY_ADMIN_MESSAGE_ID;
    case DIVISION_HEAD:
      return DIVISION_HEAD_INTL_MESSAGE_ID;
    case RESPONDENT:
    case TMP_RESPONDENT:
      return RESPONDENT_INTL_MESSAGE_ID;
    case EXPERT:
      return EXPERT_INTL_MESSAGE_ID;
    default:
      return '';
  }
};
