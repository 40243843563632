import values from 'lodash/values';
import { LOGIC_ACTION } from 'common/helpers/logic/constants';
import type { ILogicActionType } from '../../../flow-types/entities/RawLogic';

export const toClientFormat = (ids, actionType: ILogicActionType) => {
  if (actionType !== LOGIC_ACTION.GOTO) {
    return values(ids);
  }
  const { from, to } = ids;
  return [from, to];
};

export const toBackendFormat = (ids, actionType: ILogicActionType) => {
  if (actionType !== LOGIC_ACTION.GOTO) {
    return ids;
  }
  const [from, to] = ids;
  return {
    from,
    to
  };
};
