/**
 * Sometimes we need to display question title without being
 * able to get question data w/o store access
 */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import find from 'lodash/find';

import takeSecondArg from 'common/helpers/takeSecondArg';

import { projectFlatQuestionsSelector } from './Panels/ResponsesPanel/selectors';

const selectorFactory = () =>
  createSelector(
    [projectFlatQuestionsSelector, takeSecondArg],
    (questions, questionId) => {
      const question = find(questions, { id: questionId });

      return question?.title || null;
    }
  );

export default function BlockTitle({ questionId }) {
  const selector = useMemo(selectorFactory, []);

  return useSelector(state => selector(state, questionId));
}
