import fp from 'lodash/fp';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {
  DATE_FNS_FORMAT_DATE_ONLY,
  DATE_FNS_FORMAT_NO_SECONDS
} from 'utils/config';

import { filterNewGeneratedProperties } from 'common/helpers/generateNewData';

import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';

import decamelizeKeys from '../../helpers/decamelizeKeys';
import { TYPES } from '../../helpers/question';

const dataFormat = (data, isInterval, dateOnly) => {
  if (!data) return null;

  if (typeof data === 'string') {
    return dataFormat(parseISO(data), isInterval, dateOnly);
  }

  if (!isInterval) {
    return format(
      data,
      dateOnly ? DATE_FNS_FORMAT_DATE_ONLY : DATE_FNS_FORMAT_NO_SECONDS
    );
  }

  return map(data, item => dataFormat(item, false, dateOnly));
};

export const processAnswerValue = ({
  questionId,
  data,
  question,
  meta,
  ...rest
}: IInterviewAnswer) => {
  const next = {
    ...rest,
    questionId
  };

  if (data) {
    switch (question.type) {
      case TYPES.Signature:
        next.value = data ? data.id : null;
        break;

      case TYPES.MultipleAnswer:
      case TYPES.Checklist:
        next.value = map(data, val => {
          if (val !== 'other') {
            if (typeof val !== 'string') return val;

            return +val;
          }

          return meta.other ? `${meta.other}` : '';
        });
        break;

      case TYPES.SingleAnswer:
      case TYPES.Status:
        next.value = data !== 'other' ? data : get(meta, 'other', '');
        break;

      case TYPES.DateTime:
        next.value = dataFormat(data, question.isInterval, question.dateOnly);
        break;

      case TYPES.Video:
        next.value = typeof data === 'object' && size(data) > 0 ? data : null;
        break;

      default:
        next.value = data;
    }
  } else if (data === null) {
    next.value = null;
  }

  return next;
};

const processAnswerComments = ({ comments, ...answer }) => {
  const next = { ...answer };

  if (Array.isArray(comments) && comments.length > 0) {
    next.comments = comments.map(comment =>
      filterNewGeneratedProperties(comment)
    );

    next.comments = next.comments.map(comment => ({
      ...comment,
      questionId: answer.questionId
    }));
  }

  return next;
};

export const denormalizeAnswer = fp.compose(
  processAnswerComments,
  processAnswerValue
);

const decamelizeAndDenormalizeAnswer = fp.compose(
  decamelizeKeys,
  denormalizeAnswer
);

export default decamelizeAndDenormalizeAnswer;
