export const dictionary = {
  // region Table Headers
  'agitations.buildingsWavesTable.headers.buildingId': {
    ru: 'ИД Здания',
    en: 'Building ID'
  },
  'agitations.buildingsWavesTable.headers.project': {
    ru: 'Проект',
    en: 'Project'
  },

  'agitations.buildingsWavesTable.headers.wave': {
    ru: 'Волна',
    en: 'Wave'
  },

  'agitations.buildingsWavesTable.headers.manager': {
    ru: 'Менеджер',
    en: 'Manager'
  },

  'agitations.buildingsWavesTable.headers.startDate': {
    ru: 'Начало',
    en: 'Beginning'
  },

  'agitations.buildingsWavesTable.headers.endDate': {
    ru: 'Окончание',
    en: 'Ending'
  },

  'agitations.buildingsWavesTable.headers.region': {
    ru: 'Регион',
    en: 'State'
  },

  'agitations.buildingsWavesTable.headers.district': {
    ru: 'Район',
    en: 'District'
  },

  'agitations.buildingsWavesTable.headers.city': {
    ru: 'Город',
    en: 'City'
  },

  'agitations.buildingsWavesTable.headers.street': {
    ru: 'Улица',
    en: 'Street'
  },

  'agitations.buildingsWavesTable.headers.number': {
    ru: 'Номер дома',
    en: 'House'
  },

  'agitations.buildingsWavesTable.headers.roomsNumber': {
    ru: 'Кол-во помещений',
    en: 'Apartments count'
  },

  'agitations.buildingsWavesTable.headers.meetingsNumber': {
    ru: 'Кол-во встреч',
    en: 'Meetings count'
  },
  // endregion

  // region Totals Stats
  'agitation.statsList.totalBuildings': {
    ru: 'Всего зданий: {count}',
    en: 'Total buildings: {count}'
  },
  'agitation.statsList.totalRooms': {
    ru: 'Всего помещений: {count}',
    en: 'Total rooms: {count}'
  },
  'agitation.statsList.totalMeetings': {
    ru: 'Всего встреч: {count}',
    en: 'Total meetings: {count}'
  }
  // endregion
};

export default dictionary;
