import findIndex from 'lodash/findIndex';
import {
  getFirstVisible,
  getLastVisible,
  getStackById,
  getStackByQuestionId
} from 'common/helpers/interview/getStack';
import type { IInterviewStructureElement } from '../../../../flow-types/entities/InterviewStructureElement';
import { getItemsBefore, getItemsFrom } from '../../../../common/helpers/list';

// Returns target stack identifier from the current structure
// in relation to activeStackId
// according to the passed search params:
// + stackId
// + forward
// + backward
export const getTargetStackId = ({
  stackId,
  questionId,
  forward,
  backward,
  structure,
  activeStackId
}) => {
  let targetStackId;

  const activeStackIndex = findIndex(
    structure,
    (el: IInterviewStructureElement) => el.localId === activeStackId
  );

  const activeStackData = getStackById(activeStackId, structure);

  if (stackId) {
    targetStackId = stackId;
  } else if (questionId) {
    // we want to receive stack container that is displayed on a page,
    // not one that is related to block
    targetStackId =
      getStackByQuestionId(structure, questionId, {
        recursive: true,
        rootOnly: true
      })?.localId ?? null;
  } else if (forward) {
    if (activeStackData) {
      targetStackId = activeStackData.next.localId ?? -1;
    } else {
      const nextFirstVisibleStack = getFirstVisible(
        getItemsFrom(structure, activeStackIndex)
      );

      targetStackId = nextFirstVisibleStack
        ? nextFirstVisibleStack.localId
        : -1;
    }
  } else if (backward) {
    if (activeStackData) {
      targetStackId = activeStackData?.prev?.localId ?? null;
    } else {
      const prevFirstVisibleStack = getLastVisible(
        getItemsBefore(structure, activeStackIndex)
      );

      targetStackId = prevFirstVisibleStack
        ? prevFirstVisibleStack.localId
        : null;
    }
  }

  return targetStackId;
};
