import { LOGIC_OPERAND } from 'common/helpers/logic/constants';

const priorities = {
  [LOGIC_OPERAND.AND]: 1,
  [LOGIC_OPERAND.OR]: 2
};

export const getTestsGroupOperatorPriority = testsGroupOperator => {
  if (typeof testsGroupOperator === 'string') {
    return priorities[testsGroupOperator];
  }

  const { priority, type } = testsGroupOperator;

  return priority || priorities[type];
};
