// @flow

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { AbstractComponent } from 'react';
import cx from 'classnames';
import type { ReactChildren } from 'flow-types/ReactChildren';
import useDidMount from 'common/hooks/useDidMount';
import useWillUnmount from 'common/hooks/useWillUnmount';
import { VirtualSubmitInput } from 'common/components/Form/styled';
import stopEventLifting from '../../helpers/stopEventLifting';

type FormSize = 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive' | null;

export type FormProps = {
  +children?: ReactChildren,
  +className?: string,
  +loading?: boolean,
  +success?: boolean,
  +error?: boolean,
  +warning?: boolean,
  +inverted?: boolean,
  +size?: FormSize,
  +equalWidth?: boolean,
  +onSubmit?: Function,
  +preventEventFlow?: boolean,
  +virtualSubmit?: boolean,
  +tagName?: string,
  +onFormInstanceReceived?: Function
};

const Form: AbstractComponent<FormProps, mixed> = React.forwardRef(
  (
    {
      children,
      loading,
      size,
      success, // will show any success message
      error, // will show any error message
      warning, // will show any warning message
      className,
      equalWidth,
      inverted,
      onSubmit,
      preventEventFlow,
      tagName: Component,
      onFormInstanceReceived,
      virtualSubmit,
      ...rest
    }: FormProps,
    ref
  ) => {
    const formSubmitHandler = useCallback(
      e => {
        if (preventEventFlow && e) {
          stopEventLifting(e);
        }

        if (onSubmit) onSubmit();
      },
      [onSubmit, preventEventFlow]
    );

    const formInstance = useRef({
      submit: formSubmitHandler
    });

    useWillUnmount(() => {
      if (onFormInstanceReceived) {
        onFormInstanceReceived(null);
      }
    });

    useDidMount(() => {
      if (onFormInstanceReceived) {
        onFormInstanceReceived(formInstance);
      }
    });

    useEffect(() => {
      formInstance.current = {
        submit: formSubmitHandler
      };
    }, [formSubmitHandler]);

    const classNames = useMemo(() => {
      let classConfig = {
        // [size]: !!size,
        loading,
        success,
        error,
        warning,
        // [className]: !!className,
        'equal width': !!equalWidth,
        inverted
      };

      if (size) {
        classConfig = {
          // $FlowFixMe
          [size]: true,
          ...classConfig
        };
      }

      if (className) {
        classConfig = {
          ...classConfig,
          [className]: true
        };
      }

      return cx('ui', classConfig, 'form');
    }, [
      className,
      equalWidth,
      error,
      inverted,
      loading,
      size,
      success,
      warning
    ]);

    if (!Component) return null;

    return (
      <Component
        {...rest}
        ref={ref}
        className={classNames}
        onSubmit={formSubmitHandler}
      >
        {children}
        {virtualSubmit && <VirtualSubmitInput type="submit" tabIndex="-1" />}
      </Component>
    );
  }
);

Form.displayName = 'Form';

// $FlowIgnore
Form.defaultProps = {
  preventEventFlow: false,
  virtualSubmit: false,
  tagName: 'form',
  children: null,
  className: '',
  loading: false,
  success: false,
  error: false,
  warning: false,
  inverted: false,
  size: null,
  equalWidth: false,
  onSubmit: null,
  onFormInstanceReceived: null
};

export default Form;
