// @flow
import omit from 'lodash/omit';
import type { Action } from '../../flow-types/actions/companies';
import type { DepartmentsState } from '../../flow-types/states/CompaniesState';
import updateFilter from '../../common/helpers/updateFilter';

const initialState: DepartmentsState = {
  data: [],
  loading: false,
  filter: {
    ps: 5,
    pn: 0,
    sort: '-id'
  },
  pagination: {},
  selected: null,
  form: {
    loading: false,
    visible: false,
    data: {
      title: '',
      description: ''
    },
    error: null
  }
};

export default (state: DepartmentsState = initialState, action: Action) => {
  switch (action.type) {
    case 'companies/select-company':
      // $FlowIgnore
      return {
        ...state,
        data: [],
        selected: null
      };

    case 'companies/select-department':
      return {
        ...state,
        selected: action.department
      };

    case 'companies/save-department':
      return {
        ...state,
        form: {
          ...state.form,
          loading: true
        }
      };
    case 'companies/save-department-success':
      return {
        ...state,
        form: initialState.form
      };
    case 'companies/save-department-fail':
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          error: action.error
        }
      };
    case 'companies/fetch-departments':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          updater: action.filterUpdate,
          replacer: action.filter
        })
      };
    case 'companies/fetch-departments-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'companies/fetch-departments-success':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        loading: false,
        error: null
      };
    case 'companies/edit-department-cancel':
      return {
        ...state,
        form: initialState.form
      };
    case 'companies/add-new-department':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.initialData
          },
          visible: true
        }
      };
    case 'companies/update-department-form':
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            ...action.dataUpdate
          },
          // $FlowIgnore
          ...(typeof state.form.error === 'object' && {
            error: omit(state.form.error, Object.keys(action.dataUpdate))
          })
        }
      };
    case 'companies/edit-department':
      return {
        ...state,
        form: {
          ...state.form,
          visible: true,
          data: {
            ...action.department
          }
        }
      };
    default:
      return state;
  }
};
