// @flow

import type { Reducer } from 'flow-types/Reducer';
import type { ProjectsAction } from 'flow-types/actions/projects';
import type { ProjectsListState } from 'flow-types/states/ProjectsState/ProjectsListState';

import updateFilter from '../../common/helpers/updateFilter';

const initialState: ProjectsListState = {
  data: [],
  pagination: {
    activePage: 0,
    totalPages: 1,
    totalElements: 0
  },
  filter: {
    ps: 10,
    pn: 0,
    sort: '-id'
  },
  error: null,
  loading: false,
  projectCreationModal: {
    visible: false,
    showScriptInputs: false,
    form: {
      file: null,
      script: null
    }
  }
};

const projectsListReducer: Reducer<ProjectsListState, ProjectsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'projectsList/show-script-inputs':
      return {
        ...state,
        projectCreationModal: {
          ...state.projectCreationModal,
          showScriptInputs: true
        }
      };
    case 'projectsList/open-creation-modal':
      return {
        ...state,
        projectCreationModal: {
          ...state.projectCreationModal,
          visible: true
        }
      };
    case 'projectsList/close-creation-modal':
      return {
        ...state,
        projectCreationModal: initialState.projectCreationModal
      };
    case 'projectsList/update-creation-modal-form':
      return {
        ...state,
        projectCreationModal: {
          ...state.projectCreationModal,
          form: {
            ...state.projectCreationModal.form,
            ...action.formUpdate
          }
        }
      };
    case 'projectsList/fetch-projects':
      return {
        ...state,
        loading: true,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        })
      };
    case 'projectsList/fetch-projects-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'projectsList/fetch-projects-success':
      return {
        ...state,
        pagination: action.pagination,
        loading: false,
        data: action.data,
        error: null
      };
    default:
      return state;
  }
};

export default projectsListReducer;
