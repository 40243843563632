// returns question identifiers, that relates to current activeIds stack
import flatMapDeep from 'lodash/flatMapDeep';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';

/**
 * @param stack
 * @param relatedToStack
 * @returns {any[]|*[]}
 */
export const getFullQuestionIdsForStack = (
  stack: IInterviewStructureElement,
  relatedToStack: IInterviewStructureElement
) => {
  if (!stack) return [];

  const { questionId, parentQuestionId, items } = stack;

  const result = new Set();

  if (questionId) {
    result.add(questionId);
  }

  // if activeIds stack has parentQuestionId also include it in activeIds list,
  // but only if it is not equal to targetStack's parentQuestionId
  if (parentQuestionId) {
    const stackParentQuestionId = stack?.parentQuestionId ?? null;
    const relatedStackParentQuestionId =
      relatedToStack?.parentQuestionId ?? null;

    if (stackParentQuestionId !== relatedStackParentQuestionId) {
      result.add(parentQuestionId);
    }
  }

  if (Array.isArray(items)) {
    flatMapDeep(items, item =>
      getFullQuestionIdsForStack(item)
    ).forEach(itemId => result.add(itemId));
  }

  return [...result];
};

export const getQuestionsIdsForStackTransition = (activeStack, targetStack) => {
  const activeIds = getFullQuestionIdsForStack(activeStack, targetStack);

  const targetIds = getFullQuestionIdsForStack(targetStack, activeStack);

  return [activeIds, targetIds];
};
