// @flow
import fp from 'lodash/fp';
import size from 'lodash/size';
import { camelizeKeys } from 'humps';
import type {
  ICompanyGroupDurationReport,
  IProjectDepartmentDurationReport,
  IProjectManagerDurationReport,
  IQuestionGroupDurationReport,
  ProjectDurationReport
} from '../../../flow-types/entities/ProjectDurationReport';

const groupsReportNormalizer = fp.reduce(
  (normalized, group: IQuestionGroupDurationReport) => {
    const { questionGroupId, maxDuration, minDuration, avgDuration } = group;
    return {
      ...normalized,
      [questionGroupId]: {
        max: maxDuration,
        min: minDuration,
        avg: avgDuration
      }
    };
  },
  {}
);

const managersReportNormalizer = fp.reduce(
  (normalized, managerReport: IProjectManagerDurationReport) => {
    const {
      groups,
      interviewerId,
      userName,
      avgDuration,
      maxDuration,
      minDuration,
      responseCount
    } = managerReport;

    const data = {
      id: `manager-${interviewerId}`,
      responseCount,
      value: {
        min: minDuration,
        max: maxDuration,
        avg: avgDuration
      },
      title: userName,
      groups: groupsReportNormalizer(groups)
    };

    if (!normalized) {
      return [data];
    }

    return [...normalized, data];
  },
  []
);

const departmentsReportNormalizer = fp.reduce(
  (
    normalized,
    {
      department,
      managers,
      groups,
      departmentId,
      avgDuration,
      maxDuration,
      minDuration,
      responseCount
    }: IProjectDepartmentDurationReport
  ) => {
    const data = {
      department: true,
      id: `dep-${departmentId}`,
      responseCount,
      value: {
        min: minDuration,
        max: maxDuration,
        avg: avgDuration
      },
      ...(department !== 'Unknown' && {
        title: department
      }),
      ...(department === 'Unknown' && {
        messageId: 'project.reports.labels.noDepartment'
      }),
      groups: groupsReportNormalizer(groups)
    };

    if (!normalized) {
      return [data];
    }

    return [...normalized, data, ...managersReportNormalizer(managers)];
  },
  []
);

const groupNames = fp.reduce(
  (_names, group: ICompanyGroupDurationReport) => ({
    ..._names,
    [group.questionGroupId]: group.questionGroup || 'N/A'
  }),
  {}
);

const projectCheckReportNormalizer = fp.compose(
  (
    report: ProjectDurationReport & {| columns: { [key: string]: string } |}
  ) => {
    if (!report || report.responseCount === 0) {
      return {
        rows: [],
        columns: report.columns
      };
    }

    const {
      departments,
      managers,
      groups,
      columns,
      avgDuration,
      maxDuration,
      minDuration,
      responseCount
    } = report;

    let rows = [
      {
        id: 'company',
        messageId: 'project.reports.labels.company',
        responseCount,
        value: {
          min: minDuration,
          max: maxDuration,
          avg: avgDuration
        },
        groups: groupsReportNormalizer(groups)
      }
    ];

    if (size(departments) > 0) {
      rows = [
        ...rows,
        {
          id: 'by-departments',
          messageId: 'project.reports.labels.byDepartments',
          divider: true
        },
        ...departmentsReportNormalizer(departments)
      ];
    }

    if (size(managers) > 0) {
      rows = [
        ...rows,
        {
          id: 'by-managers',
          messageId: 'project.reports.labels.byManagers',
          divider: true
        },
        ...managersReportNormalizer(managers)
      ];
    }

    return {
      rows,
      columns
    };
  },
  (report: ProjectDurationReport) => {
    if (size(report) === 0) return null;

    return {
      ...report,
      columns: groupNames(report.groups)
    };
  },
  camelizeKeys
);

export default projectCheckReportNormalizer;
