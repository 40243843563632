/* eslint-disable no-case-declarations */
// @flow
import type { QueryType, QueryStateType } from './Query.type';
import type { QueryActionType } from './Query.action';

const mockQuery = {
  inputFields: [],
  outputFields: [],
  query: [],
  entity: null,
  action: null,
  filters: []
};

const initialState: QueryStateType = {
  loading: false,
  queries: [],
  currentQuery: { ...mockQuery },
  composerOpened: false
};

export default (state: QueryType = initialState, action: QueryActionType) => {
  switch (action.type) {
    case 'crm/fetch-queries':
      return {
        ...state,
        loading: true
      };
    case 'crm/fetch-queries-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'crm/fetch-queries-success':
      return {
        ...state,
        loading: false,
        queries: [...action.data],
        error: null
      };
    case 'crm/query/add-input-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          inputFields: [...state.currentQuery.inputFields, '.']
        }
      };
    case 'crm/query/delete-input-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          inputFields: [
            ...state.currentQuery.inputFields.filter(
              (f, i) => i !== action.fieldIndex
            )
          ]
        }
      };
    case 'crm/query/save-input-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          inputFields: state.currentQuery.inputFields.map((field, index) =>
            // TODO: before ESLint fixed it was writen in this way:
            // state.currentQuery.inputFields[action.fieldIndex] = action.fieldString;
            index === action.fieldIndex ? action.fieldString : field
          )
        }
      };
    case 'crm/query/add-output-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          outputFields: [...state.currentQuery.outputFields, '.']
        }
      };
    case 'crm/query/delete-output-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          outputFields: [
            ...state.currentQuery.outputFields.filter(
              (f, i) => i !== action.fieldIndex
            )
          ]
        }
      };
    case 'crm/query/change-entity':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          entity: { ...action.entity }
        }
      };
    case 'crm/query/save-output-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          outputFields: state.currentQuery.outputFields.map((field, index) =>
            index === action.fieldIndex ? action.fieldString : field
          )
        }
      };
    case 'crm/query/add-query-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          query: [...state.currentQuery.query, '.']
        }
      };
    case 'crm/query/delete-query-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          query: [
            ...state.currentQuery.query.filter(
              (f, i) => i !== action.fieldIndex
            )
          ],
          outputFields: [
            ...state.currentQuery.outputFields.filter(
              (f, i) => i !== action.fieldIndex
            )
          ]
        }
      };
    case 'crm/query/save-query-field':
      return {
        ...state,
        currentQuery: {
          ...state.currentQuery,
          query: state.currentQuery.query.map((query, index) =>
            index === action.fieldIndex ? action.fieldString : query
          ),
          outputFields: state.currentQuery.outputFields.map((field, index) =>
            index === action.fieldIndex ? action.fieldString : field
          )
        }
      };

    case 'crm/query/save-success':
      return {
        ...state,
        currentQuery: { ...action.data },
        composerOpened: false
      };
    case 'crm/query/add-success':
      return {
        ...state,
        currentQuery: { ...action.data },
        composerOpened: false
      };
    case 'crm/query/change-title':
      return {
        ...state,
        currentQuery: { ...state.currentQuery, title: action.newTitle }
      };
    case 'crm/query/edit':
      return {
        ...state,
        composerOpened: true,
        currentQuery: { ...state.queries[action.queryIndex] }
      };
    case 'crm/query/new':
      return {
        ...state,
        composerOpened: true,
        currentQuery: { ...mockQuery }
      };
    case 'crm/query/close-composer':
      return {
        ...state,
        composerOpened: false
      };
    case 'crm/query/delete-success':
      return {
        ...state,
        composerOpened: false,
        currentQuery: { ...mockQuery }
      };
    default:
      return state;
  }
};
