import revertDictionary from '../../utils/revertDictionary';

const userFilterSettingsModal = {
  'userFilterSettingsListGroupModal.title': {
    en: 'New filters group',
    ru: 'Новая группа фильтров'
  },
  'userFilterSettingsListGroupModal.actions.confirmRemove': {
    ru: 'Вы хотите удалить группу фильтров?',
    en: 'Do you want to remove filters group?'
  },
  'userFilterSettingsListGroupForm.fields.title.label': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'userFilterSettingsListGroupForm.fields.bgColor.label': {
    en: 'Background color',
    ru: 'Цвет фона'
  },
  'userFilterSettingsListGroupForm.fields.fontColor.label': {
    en: 'Font color',
    ru: 'Цвет шрифта'
  }
};

export default revertDictionary(userFilterSettingsModal);
