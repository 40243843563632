import type { Epic } from 'flow-types/Epic';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import LogRocket from 'logrocket';
import { EMPTY } from 'rxjs';
import { isLogRocketEnabled } from '../../services/logrocket';

const identifyOnSignInSuccess$: Epic = action$ =>
  action$.pipe(
    ofType('auth/sign-in-success'),
    RxO.mergeMap(action => {
      if (isLogRocketEnabled) {
        // TODO: support public-link-interview-cases and post-polling-cases
        LogRocket.identify(action.user.id, {
          name: action.user.name,
          email: action.user.email,
          domain: window?.hostname || 'localhost',
          roleId: action.user.roleId
          // Add your own custom user variables here, ie:
          // subscriptionType: 'classic'
        });
      }

      return EMPTY;
    }),
    RxO.catchError(() => EMPTY)
  );

export default identifyOnSignInSuccess$;
