import fp from 'lodash/fp';
import decamelizeKeys from '../../helpers/decamelizeKeys';

export const companyDenormalizer = fp.compose(decamelizeKeys, company => {
  const { imageFile, ...safeData } = company;

  return {
    ...safeData,
    ...(imageFile && {
      imageFile: imageFile || null
    })
  };
});

export default fp.map(companyDenormalizer);
