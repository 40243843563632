// @flow

// NPM imports
import * as React from 'react';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';

// components imports
import { useIntl } from 'react-intl';
import { LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import Dropdown from '../../components/Dropdown';

// utilities imports

// flow-types imports
import type { ICheckOperator } from '../../../flow-types/entities/FilterSettings';

type Props = {
  // list of excluded (hidden) operators
  excluded?: null | ICheckOperator[],
  // labels for shown operators
  labels?: null | {
    [key: ICheckOperator]: string
  },
  value?: null | ICheckOperator
};

export default function CheckOperatorSelector({
  excluded,
  labels,
  ...props
}: Props) {
  const intl = useIntl();

  const options = React.useMemo(
    () =>
      reduce(
        LOGIC_OPERATOR,
        (result, operator) => {
          if (includes(excluded, operator)) return result;

          const defaultIntlLabel = intl.formatMessage(
            {
              id: `logicRuleForm.testForm.operator.${operator}`
            },
            {
              ...([LOGIC_OPERATOR.IN, LOGIC_OPERATOR.NOT_IN].includes(
                operator
              ) && {
                multi: false
              })
            }
          );

          const customLabel = labels ? labels[operator] : null;

          return [
            ...result,
            {
              value: operator,
              label: customLabel || defaultIntlLabel
            }
          ];
        },
        []
      ),
    [excluded, intl, labels]
  );

  return <Dropdown {...props} fluid options={options} />;
}

CheckOperatorSelector.defaultProps = {
  excluded: null,
  labels: null,
  value: null
};
