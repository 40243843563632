// @flow
import { createSelector } from 'reselect';
import type { StateSelector } from 'flow-types/Selector';
import { companiesPageStateSelector } from '../index';
import type { CompaniesState } from '../../flow-types/states/CompaniesState';

export const companiesPageCompaniesSelector: StateSelector = createSelector(
  companiesPageStateSelector,
  (companiesState: CompaniesState) => companiesState.companiesList
);

export const companiesPageDepartmentsSelector: StateSelector = createSelector(
  companiesPageStateSelector,
  (companiesState: CompaniesState) => companiesState.departmentsList
);

export const companiesPageUsersSelector: StateSelector = createSelector(
  companiesPageStateSelector,
  (companies: CompaniesState) => companies.usersList
);
