// @flow

import fp from 'lodash/fp';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import parseISO from 'date-fns/parseISO';
import { camelizeKeys } from 'humps';
import type {
  IExportSettings,
  IExportSettings$Raw,
  IExportSettings$Settings
} from 'flow-types/entities/ExportSettings';

const datesFiltersKeys = [
  'dateCreatedFrom',
  'dateCreatedTo',
  'dateEndFrom',
  'dateEndTo'
];

const normalizeExportSettingsFilters = (
  exportSettings: IExportSettings
): IExportSettings => {
  const { settings, ...rest } = exportSettings;

  const next: IExportSettings$Settings = cloneDeep(settings);

  if (next.filters) {
    forEach(next.filters, (filterValue, filterKey) => {
      if (datesFiltersKeys.includes(filterKey) && !isNil(filterValue)) {
        next.filters[filterKey] = parseISO(filterValue);
      }
    });
  }

  return {
    ...rest,
    settings: next
  };
};

const processExportSettingsFields = (
  exportSettings: IExportSettings$Raw
): IExportSettings => {
  const { settings, ...rest } = exportSettings;

  if (!settings) {
    return {
      ...rest,
      settings: {
        fields: {},
        flags: {},
        filters: {}
      }
    };
  }

  const { fields, filters = {}, flags, questionIds } = settings;

  const nextFields = fromPairs([
    ...map(fields, field => [
      field,
      { fieldType: 'property', fieldCode: field, exported: true }
    ]),
    ...map(questionIds, questionId => [
      questionId,
      { fieldType: 'block', fieldCode: questionId, exported: true }
    ])
  ]);

  return {
    ...rest,
    settings: {
      flags,
      filters,
      fields: nextFields
    }
  };
};

export const normalizeExportSettings = fp.compose(
  normalizeExportSettingsFilters,
  processExportSettingsFields
);

export const camelizeAndNormalizeExportSettings = fp.compose(
  normalizeExportSettings,
  camelizeKeys
);

export const camelizeAndNormalizeExportSettingsList = fp.compose(
  fp.map(normalizeExportSettings),
  camelizeKeys
);
