import * as React from 'react';
import { animated as a } from 'react-spring';
import tc2 from 'tinycolor2';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import Icon from 'common/components/Icon';

type RateProps = {
  selected: boolean,
  active: boolean,
  title: string,
  labelled: boolean,
  iconColor: null | string
};
export const Rate = ({
  active,
  selected,
  title,
  labelled,
  iconColor,
  ...props
}: RateProps) => {
  const style = React.useMemo(() => {
    let textShadow = null;

    if (iconColor) {
      const textShadowColor = tc2(iconColor)
        .darken(15)
        .toHexString();

      textShadow = `
          0 -1px 0 ${textShadowColor},
          -1px 0 0 ${textShadowColor},
           0 1px 0 ${textShadowColor},
           1px 0 0 ${textShadowColor}
      `;
    }

    return { color: iconColor, textShadow };
  }, [iconColor]);

  if (labelled) {
    return (
      <div className="rate">
        <Icon
          {...props}
          tagName={a.i}
          style={style}
          className={cx({ active, selected })}
        />
        {!isNil(title) && <div className="title">{title}</div>}
      </div>
    );
  }

  return (
    <Icon
      {...props}
      tagName={a.i}
      style={style}
      className={cx({ active, selected })}
    />
  );
};
