import React from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import type { IInterviewQuestion } from 'flow-types/entities/Question';
import isDevelopment from 'utils/isDevelopment';
import Link from '../Link';

type Props = {
  question: IInterviewQuestion
};

export default function URLQuestionBody({ question }: Props) {
  const linkRef = React.useRef(null);

  const proxyUrl = isDevelopment
    ? // See https://cors-anywhere.herokuapp.com/corsdemo for more info.
      `https://cors-anywhere.herokuapp.com`
    : `${window.location.origin}/cors`;

  return (
    <div>
      <ReactTinyLink
        cardSize="small"
        showGraphic
        maxLine={2}
        minLine={1}
        url={question.description}
        proxyUrl={proxyUrl}
        autoPlay={false}
        onClick={() => {
          linkRef.current.click();
        }}
      />
      {/* <br /> */}
      <Link
        ref={linkRef}
        href={question.description}
        rel="noopener noreferrer"
        target="_blank"
        className="ui link"
      />
    </div>
  );
}
