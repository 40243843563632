// @flow

import React from 'react';
import size from 'lodash/size';
import find from 'lodash/find';
import entries from 'lodash/entries';

import { TYPES } from 'common/helpers/question';

import type { Node } from 'react';
import type { IQuestion } from 'flow-types/entities/Question';
import type { IAnswer } from 'flow-types/entities/Answer';
import type { IUpload } from 'flow-types/entities/Upload';
import type { IComment } from 'flow-types/entities/Comment';
import type { ReactComponent } from 'flow-types/ReactComponent';

import Image from 'common/components/Image';
import { FormattedMessage } from 'react-intl';
import Content from 'common/components/Content';
import Table from 'common/components/Table';
import Header from '../../../../common/components/Header';
import List from '../../../../common/components/List';
import ListItem from '../../../../common/components/ListItem';
import Link from '../../../../common/components/Link';

import AnswerAttachments from './Attachments';
import AnswerComments from './Comments';

import type { OnAttachmentClickCb } from './flow';

type IQuestionExtended = {|
  ...IQuestion,
  answer: IAnswer,
  // images
  attachments?: IUpload[],
  // non-images
  otherAttachments?: IUpload[],
  comments?: IComment[]
|};

type Props = {
  data: IQuestionExtended,
  onAttachmentClick?: null | OnAttachmentClickCb,
  tagName?: string | ReactComponent,
  showHeader?: boolean,
  showAttachments?: boolean,
  // show date in human readable form near every comment
  showCommentDate?: boolean,
  // showComments as 'last' will show only the very last comment
  // that was added to that block
  showComments?: boolean | 'last'
};

export const hasAnswer = (answer: IAnswer | null): boolean %checks =>
  !!answer && (!!answer.options || !!answer.answer);

type AnswerContentProps = {
  answer: IAnswer,
  question: IQuestion,
  attachments: IUpload[]
};

const AnswerContent = ({
  answer,
  question,
  attachments
}: AnswerContentProps) => {
  const { answer: text, options, meta } = answer;

  if (question.type === TYPES.Video) {
    const labels = question.settings?.labels;

    return (
      <Table veryBasic veryCompact celled>
        <thead>
          <Table.Row>
            <Table.Cell as="th">
              <FormattedMessage id="Answer.videoRatesTable.columns.time" />
            </Table.Cell>
            <Table.Cell as="th">
              <FormattedMessage id="Answer.videoRatesTable.columns.rate" />
            </Table.Cell>
          </Table.Row>
        </thead>
        <tbody>
          {entries(meta).map(([time, rate]) => (
            <Table.Row key={time}>
              <Table.Cell>{time}</Table.Cell>
              <Table.Cell>{labels?.[rate] ?? rate}</Table.Cell>
            </Table.Row>
          ))}
        </tbody>
      </Table>
    );
  }

  if (question.type === TYPES.Signature) {
    const src = find(attachments, { id: +text });

    if (!src) return null;

    return <Image src={src} size="small" />;
  }

  if (
    question.type === TYPES.MultipleAnswer ||
    question.type === TYPES.Checklist ||
    question.type === TYPES.SingleAnswer ||
    question.type === TYPES.Status ||
    question.type === TYPES.Ranging
  ) {
    return (
      <List>
        {options?.map(option => (
          <ListItem key={option.id}>{option.title}</ListItem>
        )) ?? null}
        {text && <ListItem>{text}</ListItem>}
      </List>
    );
  }

  if (question.type === TYPES.MobilePhone) {
    return text ? <Link href={`tel:${text}`}>{text}</Link> : null;
  }

  if (question.type === TYPES.Email) {
    return text ? <Link href={`mailto:${text}`}>{text}</Link> : null;
  }

  if (
    question.type === TYPES.TextBlock ||
    question.type === TYPES.SecondaryPhones
  ) {
    return text;
  }

  if (
    question.type === TYPES.DateTime ||
    question.type === TYPES.Rating ||
    question.type === TYPES.Numeric
  ) {
    const [start = null, end = null] = text
      .split(';')
      .map(item => item.trim())
      .map(item => (item === '' ? null : item));

    if (question.isInterval) {
      if (start === null && end !== null) {
        return (
          <FormattedMessage id="Answer.interval.onlyTo" values={{ end }} />
        );
      }
      if (start !== null && end === null) {
        return (
          <FormattedMessage id="Answer.interval.onlyFrom" values={{ start }} />
        );
      }
      return (
        <FormattedMessage id="Answer.interval.fromTo" values={{ start, end }} />
      );
    }

    return start;
  }

  return text;
};

export const Answer = ({
  data,
  onAttachmentClick,
  tagName: Component = 'div',
  showHeader,
  showComments,
  showCommentDate,
  showAttachments,
  ...props
}: Props): Node => {
  const { answer, comments, attachments, otherAttachments, ...question } = data;

  if (question.type === TYPES.Video && !question.settings?.pollingEnabled) {
    return null;
  }

  return (
    <Component {...props}>
      {showHeader && <Header as="h4">{`${data.id}. ${data.title}`}</Header>}
      {hasAnswer(answer) && (
        <Content>
          <AnswerContent
            question={question}
            answer={answer}
            attachments={attachments}
          />
        </Content>
      )}
      {showAttachments && (
        <AnswerAttachments
          attachments={attachments}
          otherAttachments={otherAttachments}
          onAttachmentClick={onAttachmentClick}
        />
      )}
      {showComments && size(comments) > 0 && (
        <AnswerComments
          onlyLast={showComments === 'last'}
          showCommentDate={!!showCommentDate}
          data={comments}
        />
      )}
    </Component>
  );
};

Answer.defaultProps = {
  tagName: 'div',
  showHeader: false,
  showAttachments: false,
  showComments: false,
  showCommentDate: false,
  onAttachmentClick: null
};
