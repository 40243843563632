import map from 'lodash/map';

export default function optionsNormalizer(options, valueKey) {
  return map(options, option => {
    if (option.group) {
      return {
        ...option,
        options: optionsNormalizer(option.options, valueKey)
      };
    }
    return {
      ...option,
      [valueKey]: `${option[valueKey]}`
    };
  });
}
