import { SCOPE } from '../../reducers/auth';

const hasAccessToRoute = (scope, routeKey) => {
  if (scope === SCOPE.ALL) return true;

  if (scope === SCOPE.NONE) return false;

  return Array.isArray(scope) && scope.includes(routeKey);
};

export default hasAccessToRoute;
