// @flow
import { createSelector } from 'reselect';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import entries from 'lodash/entries';
import map from 'lodash/map';
import type { IRegionRate } from 'flow-types/entities/RecordRegion';
import type {
  AudioRecordsState,
  RecordsRegionsState,
  RecordsListState,
  RecordsPeaksState,
  IRegion
} from 'flow-types/states/RecordsState';
import type { StateSelector } from 'flow-types/Selector';
import { recordsStateSelector } from '../index';
import type {
  IUpload,
  IUpload as Record
} from '../../flow-types/entities/Upload';

export const recordsListStateSelector: StateSelector = createSelector(
  recordsStateSelector,
  (recordsState: AudioRecordsState) => recordsState.list
);

export const getRecordName: (recordId: number) => StateSelector = recordId =>
  createSelector(recordsListStateSelector, ({ data }: RecordsListState) => {
    const record: ?IUpload = find(data, { id: +recordId });

    if (!record) return null;

    return record.originalFilename;
  });

export const recordsRegionsStateSelector: StateSelector = createSelector(
  recordsStateSelector,
  (recordsState: AudioRecordsState): RecordsRegionsState => recordsState.regions
);

export const recordsPeaksStateSelector: StateSelector = createSelector(
  recordsStateSelector,
  (recordsState: AudioRecordsState): RecordsPeaksState => recordsState.peaks
);

export const activeRecordIdSelector: StateSelector = createSelector(
  recordsListStateSelector,
  (recordsListState: RecordsListState) => recordsListState.selected
);

export const activeRegionIdSelector: StateSelector = createSelector(
  recordsRegionsStateSelector,
  (
    regionsState: RecordsRegionsState
  ): $PropertyType<RecordsRegionsState, 'selected'> => regionsState.selected
);

export const activeRecordDataSelector: StateSelector = createSelector(
  recordsListStateSelector,
  (recordsListState: RecordsListState) => {
    const { data, selected } = recordsListState;

    return find(data, (record: Record) => record.id === selected);
  }
);

export const regionsListSelector: StateSelector = createSelector(
  recordsRegionsStateSelector,
  (regionsState: RecordsRegionsState) => {
    const { data } = regionsState;

    const regions = data || {};

    return reduce(
      entries(regions),
      (result, [regionId, region]: [number, IRegion]) => {
        const updatedRegion = {
          ...region,
          checklistItems: map(region.checklistItems, (item): IRegionRate => ({
            ...item,
            start: region.start,
            end: region.end,
            color: region.color,
            recordId: !!region.record && region.record.id,
            regionId: region.id
          }))
        };

        return {
          ...result,
          [regionId]: updatedRegion
        };
      },
      {}
    );
  }
);

/**
 * Creates plain rates list, where each region represents only ONE mark
 */
export const ratesListSelector: StateSelector = createSelector(
  regionsListSelector,
  (regionsList: Array<IRegion & {| checklistItems: Array<IRegionRate> |}>) =>
    reduce(
      regionsList,
      (semiResult, region: IRegion): Array<IRegionRate> => {
        const { checklistItems } = region;

        return [...semiResult, ...checklistItems];
      },
      []
    )
);
