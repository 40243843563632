import messengerDictionary from 'common/containers/Messenger/Messenger.dictionary';
import editorDictionary from 'common/components/Editor/dictionary';
import revertDictionary from 'utils/revertDictionary';
import AppEnvironment from 'utils/AppEnvironment';
import responseRegionsListDictionary from './responseRegionsListDictionary';
import labelsDictionary from './common/labelsDictionary';
import { checklistsDictionary } from './checklistsDictionary';
import responseStatsDictionary from './responseStatsDictionary';
import userRolesDictionary from './userRolesDictionary';
import projectReportsTabDictionary from './projectReportsDictionary';
import projectLogicDictionary from './projectLogicDictionary';
import questionValidationErrorsDictionary from './questionValidationErrorsDictionary';
import commentsDictionary from './common/commentsDictionary';
import questionTypesLabelsDictionary from './questionTypesLabelsDictionary';
import interviewDictionary from '../pages/Interview/Interview.dictionary.json';
import questionDictionary from '../common/components/Question/Question.dictionary.json';
import recordsDictionary from './recordsDictionary';
import testElementsDictionary from './testElementsDictionary';
import testGroupsDictionary from './testGroupsDictionary';
import usersDictionary from './usersDictionary';
import departmentsDictionary from './departmentsDictionary';
import companiesDictionary from './companiesDictionary';
import authDictionary from './authDictionary';
import statusesDictionary from './common/statusesDictionary';
import projectsDictionary from './projectsDictionary';
import headersDictionary from './common/headersDictionary';
import meetingDictionary from './meetingDictionary';
import projectResponseDictionary from './projectResponseDictionary';
import { userDictionary } from './userDictionary';
import projectResponsesDictionary from './projectResponsesDictionary';
import profileDictionary from './profileDictionary';
import composeDictionaries from '../utils/composeDictionaries';
import projectInterviewTabDictionary from './projectInterviewTabDictionary';
import projectDictionary from './projectDictionary';
import formsDictionaries from './forms';
import attachmentsDictionary from './common/fileUploaderDictionary';
import regionWithUnsavedChangesModal from './modals/regionWithUnsavedChangesModal';
import editResponseAnswerModal from './modals/editResponseAnswerModal';
import queryDictionary from '../crm/query/Query.dictionary';
import viewDictionary from '../crm/view/View.dictionary';
import userFilterSettingsForm from './forms/userFiltersSettingsForm';
import userFilterSettingsModal from './modals/userFilterSettingsModal';
import userFilterSettingsListGroupModal from './modals/userFilterSettingsListGroupModal';
import responseAssignExpertModal from './modals/responseAssignExpertModal';
import projectAccessSettingsTabDictionary from './projectAccessSettingsTabDictionary';
import projectExportPanelDictionary from './projectExportPanelDictionary';
import pivotTablesDictionary from './pivotTablesDictionary';
import levelDictionary from './common/levelDictionary';
import { uploadDecodingsDictionary } from '../pages/Project/components/Panels/ResponsePanel/UploadDecodings/UploadDecodings.dictionary';
import pollingNotificationDictionary from '../pages/Project/components/Panels/PollingNotificationsPanel/PollingNotificationPanel.dictionary';
import ajaxErrorsDictionary from './ajaxErrorsDictionary';
import iconsSetsDictionary from './common/iconsSetsDictionary';
import appDictionary from './common/appDictionary';
import variablesDictionary from './variablesDictionary';
import interviewChangeHistoryDictionary from '../pages/Interview/components/InterviewHistory/dictionary.json';
import tasksTableDictionary from './common/tasksTable.dictionary';

import tradeInExportPanelDictionary from '../pages/Project/components/Panels/TradeInExportPanel/TradeInExportPanel.dictionary.json';
import editorPanelDictionary from '../pages/Project/components/Panels/EditorPanel/EditorPanel.dictionary';
import answerDictionary from '../pages/Project/components/Answers/Answer.dictionary';
import agitationsDictionary from '../pages/Agitations/dictionary';
import buildingsDictionary from '../pages/Buildings/dictionary';

// TODO: dictionary composition is now taking much time on app start.
//  From my POV it can take place somewhere else, for example - build time.
//  By composing dictionaries at build time, we will reduce start-up time,
//  user will not wait until the whole app dictionary is built.
export const messagesDictionary = composeDictionaries(
  projectLogicDictionary,
  revertDictionary(interviewDictionary),
  revertDictionary(interviewChangeHistoryDictionary),
  revertDictionary(questionDictionary),
  editorDictionary,
  headersDictionary,
  labelsDictionary,
  statusesDictionary,
  authDictionary,
  companiesDictionary,
  departmentsDictionary,
  usersDictionary,
  checklistsDictionary,
  testGroupsDictionary,
  testElementsDictionary,
  recordsDictionary,
  responseRegionsListDictionary,
  responseStatsDictionary,
  userRolesDictionary,
  questionTypesLabelsDictionary,
  questionValidationErrorsDictionary,
  commentsDictionary,
  meetingDictionary,
  projectResponseDictionary,
  userDictionary,
  projectResponsesDictionary,
  projectReportsTabDictionary,
  projectInterviewTabDictionary,
  projectAccessSettingsTabDictionary,
  editorPanelDictionary,
  projectDictionary,
  projectsDictionary,
  profileDictionary,
  formsDictionaries,
  attachmentsDictionary,
  editResponseAnswerModal,
  regionWithUnsavedChangesModal,
  userFilterSettingsForm,
  userFilterSettingsModal,
  queryDictionary,
  viewDictionary,
  responseAssignExpertModal,
  userFilterSettingsListGroupModal,
  projectExportPanelDictionary,
  pivotTablesDictionary,
  levelDictionary,
  messengerDictionary,
  uploadDecodingsDictionary,
  pollingNotificationDictionary,
  ajaxErrorsDictionary,
  iconsSetsDictionary,
  appDictionary,
  variablesDictionary,
  tasksTableDictionary,
  answerDictionary,
  AppEnvironment.tiRealty
    ? revertDictionary(tradeInExportPanelDictionary)
    : false,
  agitationsDictionary,
  buildingsDictionary
);
