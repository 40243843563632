import revertDictionary from 'utils/revertDictionary';

const answerDictionary = {
  'Answer.interval.fromTo': {
    en: 'From {start} to {end}',
    ru: 'От {start} до {end}'
  },
  'Answer.interval.onlyTo': {
    en: 'To {end}',
    ru: 'До {end}'
  },
  'Answer.interval.onlyFrom': {
    en: 'From {start}',
    ru: 'От {start}'
  },
  'Answer.videoRatesTable.columns.time': {
    en: 'Time',
    ru: 'Время'
  },
  'Answer.videoRatesTable.columns.rate': {
    en: 'Rate',
    ru: 'Оценка'
  }
};

export default revertDictionary(answerDictionary);
