import revertDictionary from 'utils/revertDictionary';

const editorPanelDictionary = {
  'EditorPanel.Controls.NewElementButton.Tooltip': {
    en: 'Add block',
    ru: 'Добавить блок'
  },
  'EditorPanel.Controls.BackwardButton.Tooltip': {
    en: 'Previous block/group',
    ru: 'Предыдущий блок/группа'
  },
  'EditorPanel.Controls.ForwardButton.Tooltip': {
    en: 'Next block/group',
    ru: 'Следующий блок/группа'
  },
  'EditorPanel.Controls.PreviewButton.Tooltip': {
    en: 'Preview Block',
    ru: 'Предпросмотр блока'
  },
  'EditorPanel.Controls.DeleteButton.Tooltip': {
    en: 'Delete block/group',
    ru: 'Удалить блок/группу'
  },
  'EditorPanel.Controls.SaveButton.Tooltip': {
    en: 'Save block/group',
    ru: 'Сохранить блок/группу'
  }
};

export default revertDictionary(editorPanelDictionary);
