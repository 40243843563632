import head from 'lodash/head';
import last from 'lodash/last';
import range from 'lodash/range';

export default function getMarkBackground({
  index = 0,
  step = 1,
  min,
  max,
  values,
  inactiveColor,
  activeColor
}) {
  const valuesRange = range(min, max).map(i => i * step);

  const precisionValue = valuesRange[index];

  if (Array.isArray(values)) {
    const firstThumbValue = head(values);

    if (values.length > 1) {
      const lastThumbValue = last(values);

      return firstThumbValue <= precisionValue &&
        precisionValue <= lastThumbValue
        ? activeColor
        : inactiveColor;
    }

    return precisionValue <= firstThumbValue ? activeColor : inactiveColor;
  }

  return inactiveColor;
}
