import { object, string } from 'yup';
import { createValidator as createEmailValidator } from '../../validators/validateEmail';
import type { ValidatorConfig as EmailValidatorConfig } from '../../validators/validateEmail';

type ValidatorConfig = EmailValidatorConfig & { passwordRequiredError: string };

export default function validateForm(value, config: ValidatorConfig) {
  return object({
    email: createEmailValidator(config).required(config.emailRequiredError),
    password: string().required(config.passwordRequiredError)
  }).validateSync(value, { context: value, abortEarly: false });
}
