const initialState = {
  current: null,
  latest: null
};

// TODO: think of multiple scrolls states like modals
export default function bodyScrollReducer(state = initialState, action) {
  switch (action.type) {
    case 'interview-ui/set-scroll-target':
    case 'interview-ui/set-body-scroll-target':
      return {
        ...state,
        current: action.targetId,
        latest: action.current
      };

    case 'interview-ui/complete-body-scroll':
      return {
        ...state,
        current: null
      };

    case 'interview-ui/reset-body-scroll':
      return initialState;

    default:
      return state;
  }
}
