// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';

// TODO: is it still required
const saveProjectLogicEpic: Epic = $action =>
  $action.pipe(
    ofType('project/save-logic'),
    RxO.map(() => ({ type: 'smth' }))
  );

export default saveProjectLogicEpic;
