// @flow

import * as React from 'react';
import cx from 'classnames';

export type LoaderProps = {
  className?: null | string,
  state?: null | 'indeterminate' | 'active' | 'disabled',
  inline?: boolean | 'centered',
  speed?: null | 'slow' | 'fast',
  color?: null | string,
  size?: null | string,
  text?: boolean,
  inverted?: boolean,
  animation?: null | 'elastic' | 'double'
};

export const loaderClassNamesComposer = (
  props: {
    className?: null | string,
    state?: null | 'indeterminate' | 'active' | 'disabled',
    inline?: boolean | 'centered',
    speed?: null | 'slow' | 'fast',
    color?: null | string,
    size?: null | string,
    text?: boolean,
    inverted?: boolean,
    animation?: null | 'elastic' | 'double'
  } = {}
): null | string => {
  if (!props) return null;

  const {
    className,
    text,
    inline,
    inverted,
    state,
    speed,
    size,
    color,
    animation
  } = props;

  return cx(
    className,
    {
      text,
      inline,
      ...(typeof inline === 'string' && {
        [`${inline} inline`]: !!inline
      }),
      inverted
    },
    state,
    speed,
    size,
    color,
    animation
  );
};

function Loader({
  className,
  state,
  inline,
  speed,
  color,
  size,
  text,
  inverted,
  animation,
  ...props
}: LoaderProps): React.Node {
  return (
    <div
      {...props}
      className={cx(
        'ui',
        loaderClassNamesComposer({
          className,
          state,
          inline,
          speed,
          color,
          size,
          text,
          inverted,
          animation
        }),
        'loader'
      )}
    />
  );
}

Loader.defaultProps = {
  size: null,
  color: null,
  animation: null,
  className: null,
  state: null,
  inline: false,
  speed: null,
  text: false,
  inverted: false
};

export default Loader;
