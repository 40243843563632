// @flow
import fp from 'lodash/fp';
import isNil from 'lodash/isNil';
import defaults from 'lodash/defaults';

import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';

import decamelizeKeys from '../../helpers/decamelizeKeys';

const processGroupShortTitle = (group: IQuestionGroup) => ({
  ...group,
  // if shortTitle is not specified, then use full title
  shortTitle: group.shortTitle || group.title
});

// adds default values if fields are not set, i.e. 'undefined'
// fields that should be watched, have to be added to this parser manually
const processQuestionsGroupDefaults = (
  group: IQuestionGroup
): IQuestionGroup => {
  const next = { ...group };

  const defaultValues: $Shape<IQuestionGroup> = {
    showTitleOnTop: false,
    showDescriptionOnTop: false,
    isSinglePage: false
  };

  return defaults(next, defaultValues);
};

const processQuestionsGroupLogo = (group: IQuestionGroup): IQuestionGroup => {
  const { logoToken, ...next } = group;

  if (isNil(next.logoPathSrc) && !logoToken) {
    next.logo = null;
  } else if (logoToken) {
    next.logo = logoToken;
  }

  return next;
};

// Extracts fields, that can be either independently updated by user,
// or that are CLIENT_SIDE only
const extractExtraData = fp.omit([
  'questions',
  'cache',
  'isNew',
  'logoFile',
  'loading'
]);

const denormalizeQuestionsGroup = fp.compose(
  processGroupShortTitle,
  processQuestionsGroupLogo,
  processQuestionsGroupDefaults,
  extractExtraData
);

const decamelizeAndDenormalizeQuestionsGroup = fp.compose(
  decamelizeKeys,
  denormalizeQuestionsGroup
);

export default decamelizeAndDenormalizeQuestionsGroup;
