import keyBy from 'lodash/keyBy';
import pullAll from 'lodash/pullAll';
import without from 'lodash/without';
import type { ExportAction } from 'flow-types/actions/projects/detail/export';
import type { ProjectExportTasksState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type {
  CreateExportTask,
  CreateExportTaskSuccess
} from 'flow-types/actions/projects/detail/export/CreateExportTask';
import updateFilter from 'common/helpers/updateFilter';
import type { UpdateExportTaskStatus } from 'flow-types/actions/projects/detail/export/UpdateExportTaskStatus';
import { TASK_STATUS } from 'common/helpers/response';

export const EXPORT_FILTER_FORM_CURRENT_VALUES_ID = -999;

const createInitialState = (): ProjectExportTasksState => ({
  data: null,
  isLoading: null,
  creationQueue: [],
  watchedTasks: [],
  filter: {
    ps: 5,
    pn: 0,
    sort: '-id'
  },
  pagination: {
    totalPages: 1,
    activePage: 1,
    totalElements: 0
  }
});

function createTask(
  state: ProjectExportTasksState,
  action: CreateExportTask
): ProjectExportTasksState {
  const next = { ...state };

  next.creationQueue = [
    ...next.creationQueue,
    // -999 is an alias for 'current export settings form values'
    action.settingsId ? action.settingsId : EXPORT_FILTER_FORM_CURRENT_VALUES_ID
  ];

  return next;
}

function handleTaskCreation(
  state: ProjectExportTasksState,
  action: CreateExportTaskSuccess
) {
  const next = { ...state };

  pullAll(next.creationQueue, [
    action.settingsId ? action.settingsId : EXPORT_FILTER_FORM_CURRENT_VALUES_ID
  ]);

  return next;
}

function handleTaskFailedCreation(
  state: ProjectExportTasksState,
  action: CreateExportTaskSuccess
) {
  const next = { ...state };

  pullAll(next.creationQueue, [
    action.settingsId ? action.settingsId : EXPORT_FILTER_FORM_CURRENT_VALUES_ID
  ]);

  return next;
}

function updateTaskStatus(
  state: ProjectExportTasksState,
  action: UpdateExportTaskStatus
): ProjectExportTasksState {
  if (
    !state.data[action.taskId] ||
    state.data[action.taskId].status === action.status
  ) {
    return state;
  }

  const next = { ...state };

  next.data[action.taskId] = { ...action.data };

  if (
    [TASK_STATUS.READY, TASK_STATUS.ERROR].includes(action.status) &&
    next.watchedTasks.includes(action.taskId)
  ) {
    pullAll(next.watchedTasks, [action.taskId]);
  }

  return next;
}

export default function tasksReducer(
  state: ProjectExportTasksState = createInitialState(),
  action: ExportAction
): ProjectExportTasksState {
  switch (action.type) {
    case 'project-export/update-export-task-status':
      return updateTaskStatus(state, action);

    case 'project-export/watch-task':
      return {
        ...state,
        watchedTasks: [...state.watchedTasks, action.taskId]
      };

    case 'project-export/unwatch-task':
      return {
        ...state,
        watchedTasks: without(state.watchedTasks, action.taskId)
      };

    case 'project-export/create-task':
      return createTask(state, action);

    case 'project-export/create-task-fail':
      return handleTaskFailedCreation(state, action);

    case 'project-export/create-task-success':
      return handleTaskCreation(state, action);

    case 'project-export/fetch-tasks':
      return {
        ...state,
        filter: updateFilter({
          base: state.filter,
          replacer: action.filter,
          updater: action.filterUpdate
        }),
        isLoading: true
      };

    case 'project-export/fetch-tasks-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project-export/fetch-tasks-success':
      return {
        ...state,
        isLoading: false,
        data: keyBy(action.data, 'id'),
        pagination: action.pagination
      };

    default:
      return state;
  }
}
