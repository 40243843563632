// @flow

import * as React from 'react';
import { merge } from 'merge-anything';
import { useIntl } from 'react-intl';

import type { IInterviewQuestion } from 'flow-types/entities/Question';

import { useAnswer } from '../../contexts/AnswersContext';
import { useAnswerUpdate } from '../../contexts/AnswerUpdateContext';

import Input from '../Input';

type Props = {
  question: IInterviewQuestion
};

export default function EmailQuestionBody({
  question,
  ...rest
}: Props): React.Node {
  const intl = useIntl();

  const answer = useAnswer(question.id, { data: '' });

  const onChange = useAnswerUpdate(question.id);

  const handleChange = React.useCallback(
    nextValue => {
      if (!onChange) return;
      onChange(
        merge(
          { ...answer },
          {
            data: typeof nextValue === 'string' ? nextValue.trim() : nextValue,
            errors: null
          }
        )
      );
    },
    [answer, onChange]
  );

  const { data } = answer;

  const { disabled } = question;

  return (
    <Input
      {...rest}
      fluid
      name="email"
      disabled={disabled}
      placeholder={intl.formatMessage({
        id: 'questionForm.fields.answer.placeholder'
      })}
      value={((data: any): string | null) || ''}
      onlyValue
      onChange={handleChange}
    />
  );
}
