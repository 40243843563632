// @flow

import fp from 'lodash/fp';
import type { IRegion } from 'flow-types/entities/RecordRegion';
import type { IChecklistItem } from 'flow-types/entities/ChecklistItem';

/**
 * Checks whether region belongs to checklistId
 * via checking that at least one of its checklistItems
 * belongs to specified checklistId
 */
const belongsToChecklistId = (
  region: IRegion,
  checklistId: number
): boolean => {
  const checker = fp.compose(
    fp.some((checklistItem: IChecklistItem) =>
      checklistId ? checklistItem.checklistId === checklistId : false
    ),
    fp.getOr([], 'checklistItems')
  );

  return checker(region);
};

export default belongsToChecklistId;
