import { array, number, object } from 'yup';
import every from 'lodash/every';
import some from 'lodash/some';
import isNaN from 'lodash/isNaN';
import type { IRegion, IRegionRate } from 'flow-types/entities/RecordRegion';
import { checkNoAudio } from '../helpers/tags';

export default function validateRegion(region: IRegion, sync: boolean = false) {
  const { checklistItems } = region;

  const isAtLeaseOneChecklistItemSelected = some(
    checklistItems,
    item =>
      typeof item.checklistItemId !== 'undefined' &&
      item.checklistItemId !== null &&
      item.checklistItemId > 0 &&
      !isNaN(item.checklistItemId)
  );

  const isFramesSet = !checkNoAudio(region);

  // start and end are required if checklist does not allow to save rate without region
  const isRegionFramesRequired = some(
    checklistItems,
    (item: IRegionRate) =>
      isAtLeaseOneChecklistItemSelected && !item.availableWithoutAudio
  );

  // is required if every checklistItem allows to create region without audio-fragment,
  // but if audio-fragment is selected, then mark is not required
  // So, to be required, every checklistItem should allow to create region w/o audio-fragment,
  // and at the same time, there shouldn't be any region frames set
  let isMarkRequired = every(
    checklistItems,
    (item: IRegionRate) => item.availableWithoutAudio
  );

  if (isFramesSet) {
    isMarkRequired = false;
  }

  // let startValidator = mixed().nullable();
  //
  // let endValidator = mixed().nullable();

  const checklistItemsValidator = array(
    object({
      checklistItemId: number()
        .typeError({
          field: 'checklistItemId',
          messageId: 'regionForm.validation.checklistItemId.required'
        })
        .required({
          field: 'checklistItemId',
          messageId: 'regionForm.validation.checklistItemId.required'
        })
    })
  ).required();

  const schema = {
    checklistItems: checklistItemsValidator
  };

  if (isRegionFramesRequired) {
    schema.start = number()
      .min(0, {
        field: 'start',
        messageId: 'regionForm.validation.start.required'
      })
      .required({
        field: 'start',
        messageId: 'regionForm.validation.start.required'
      });

    schema.end = number()
      .min(0, {
        field: 'end',
        messageId: 'regionForm.validation.end.required'
      })
      .required({
        field: 'end',
        messageId: 'regionForm.validation.end.required'
      });
  }

  if (isMarkRequired) {
    schema.checklistItems = array(
      object({
        checklistItemId: number()
          .typeError({
            field: 'checklistItemId',
            messageId: 'regionForm.validation.checklistItemId.required'
          })
          .required({
            field: 'checklistItemId',
            messageId: 'regionForm.validation.checklistItemId.required'
          }),
        checklistItemOptionId: number()
          .typeError({
            field: 'checklistItemOptionId',
            messageId: 'regionForm.validation.checklistItemOptionId.required'
          })
          .required({
            field: 'checklistItemOptionId',
            messageId: 'regionForm.validation.checklistItemOptionId.required'
          })
      })
    ).required({
      field: 'checklistItemId',
      messageId: 'regionForm.validation.checklistItemId.required'
    });
  }

  const validator = object(schema);

  if (sync) {
    return validator.validateSync(region, {
      abortEarly: !isAtLeaseOneChecklistItemSelected
    });
  }

  return validator.validate(region, {
    abortEarly: !isAtLeaseOneChecklistItemSelected
  });
}
