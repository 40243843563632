import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import size from 'lodash/size';
import { decamelizeKeys } from 'humps';

import request from 'utils/request';
import { API } from 'utils/config';

import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import { responseNormalizer } from 'common/transducers/response/responsesNormalizer';
import { postNormalizer as recordsNormalizer } from 'common/transducers/uploads/recordsNormalizer';

import type { Epic } from 'flow-types/Epic';
import type { FetchResponseSuccess } from 'flow-types/actions/projects/detail/response/FetchResponse';
import type { FetchRecordsSuccess } from 'flow-types/actions/records/FetchRecords';
import type { IResponse } from 'flow-types/entities/Response';
import type { SelectRecord } from 'flow-types/actions/records/SelectRecord';
import { ERROR } from '../../../reducers/projectDetail/response';
import ajaxErrorsDictionary from '../../../intl/ajaxErrorsDictionary';
import { languageStateSelector } from '../../../selectors';
import { projectIdFromPathSelector } from '../../../selectors/projects';

const fetchResponse: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/fetch-response'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([action, state]) => {
      const language = languageStateSelector(state);

      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(
          API.responses.detail,
          decamelizeKeys({ responseId: action.responseId })
        ),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.mergeMap((rawResponse): FetchResponseSuccess => {
          if (!rawResponse) {
            return of({
              type: 'project/fetch-response-fail',
              error: ERROR.NO_ACCESS
            });
          }

          const response: IResponse = responseNormalizer(rawResponse);

          const records = recordsNormalizer(response.records);

          const fetchRecordsSuccess: FetchRecordsSuccess = {
            type: 'records/fetch-success',
            data: records
          };

          const fetchRegions = {
            type: 'records-regions/fetch',
            responseId: response.id
          };

          const fetchStats = {
            type: 'records/fetch-response-stats',
            responseId: response.id,
            projectId
          };

          const fetchResponseSuccess = {
            type: 'project/fetch-response-success',
            response
          };

          const selectFirstRecord: SelectRecord | null =
            size(records) > 0
              ? {
                  type: 'records/select',
                  recordId: records[0].id
                }
              : null;

          return [
            fetchRecordsSuccess,
            fetchRegions,
            fetchStats,
            fetchResponseSuccess,
            selectFirstRecord
          ].filter(Boolean);
        }),
        RxO.catchError(({ message, response, status }: AjaxError) => {
          if (!status) {
            return of({
              type: 'project/fetch-response-fail',
              error: ajaxErrorsDictionary[language]['ajax.error.NA']
            });
          }

          return of({
            type: 'project/fetch-response-fail',
            error: response ? response.data : message
          });
        })
      );
    })
  );

export default fetchResponse;
