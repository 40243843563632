import type { Epic } from 'flow-types/Epic';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  RemoveMessage,
  RemoveMessageFail,
  RemoveMessageSuccess
} from 'flow-types/actions/records/decodings/list/RemoveMessage';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import {
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_FAIL,
  REMOVE_MESSAGE_SUCCESS
} from '../../../reducers/records/decodings/list';

const removeMessage$: Epic = action$ =>
  action$.pipe(
    ofType(REMOVE_MESSAGE),
    RxO.mergeMap((action: RemoveMessage) => {
      const { decodingId, messageId, uploadId } = action;

      return request({
        url: interpolateString(API.uploads.decodings.messages.detail, {
          uploadId,
          decodingId,
          messageId
        }),
        method: 'DELETE'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map((): RemoveMessageSuccess => ({
          type: REMOVE_MESSAGE_SUCCESS,
          decodingId,
          messageId,
          uploadId
        })),
        RxO.catchError((ajaxError: AjaxError): [RemoveMessageFail] => {
          const { message, response } = ajaxError;

          return [
            {
              type: REMOVE_MESSAGE_FAIL,
              decodingId,
              messageId,
              uploadId,
              error: response ? response.data : message
            }
          ];
        })
      );
    })
  );

export default removeMessage$;
