import revertDictionary from 'utils/revertDictionary';

const questionTypesLabelsDictionary = {
  'question.systemType.blocks': {
    en: 'Blocks',
    ru: 'Вопросы'
  },
  'question.systemType.system': {
    en: 'System',
    ru: 'Служебные'
  },
  'question.type.multiple': {
    en: 'Multi-choice',
    ru: 'Вопрос с несколькими ответами'
  },
  'question.type.single': {
    en: 'Single-choice',
    ru: 'Вопрос с одним ответом'
  },
  'question.type.text': {
    en: 'Text',
    ru: 'Текст'
  },
  'question.type.url': {
    en: 'URL',
    ru: 'URL'
  },
  'question.type.html': {
    en: 'HTML',
    ru: 'HTML'
  },
  'question.type.email': {
    en: 'Email',
    ru: 'Email'
  },
  'question.type.phone': {
    en: 'Mobile phone',
    ru: 'Мобильный телефон'
  },
  'question.type.secondaryPhones': {
    en: 'Secondary phones',
    ru: 'Дополнительные телефоны'
  },
  'question.type.checklist': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'question.type.video': {
    en: 'Video',
    ru: 'Видео'
  },
  'question.type.dateTime': {
    en: 'Datetime',
    ru: 'Дата/время'
  },
  'question.type.table': {
    en: 'Table-like',
    ru: 'Табличный'
  },
  'question.type.redirect': {
    en: 'Redirect',
    ru: 'Переход'
  },
  'question.type.numeric': {
    en: 'Numeric',
    ru: 'Числовой'
  },
  'question.type.ranging': {
    en: 'Draggable list',
    ru: 'Список с перестановкой'
  },
  'question.type.rating': {
    en: 'Rating',
    ru: 'Рейтинг'
  },
  'question.type.signature': {
    en: 'Signature',
    ru: 'Подпись'
  },
  'question.type.status': {
    en: 'Status',
    ru: 'Статус'
  },
  'crm.view.type.tab': {
    en: 'Tabs',
    ru: 'Вкладки'
  },
  'crm.view.type.search': {
    en: 'Search',
    ru: 'Поиск'
  }
};

export default revertDictionary(questionTypesLabelsDictionary);
