// @flow

import map from 'lodash/map';
import size from 'lodash/size';
import splitFp from 'lodash/fp/split';
import trimFp from 'lodash/fp/trim';
import mapFp from 'lodash/fp/map';
import compose from 'lodash/fp/compose';

import type { IAnswer } from 'flow-types/entities/Answer';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';

import parse from 'date-fns/parseISO';
import {
  DATE_FNS_FORMAT_DATE_ONLY,
  DATE_FNS_FORMAT_NO_SECONDS
} from 'utils/config';
import { TYPES } from '../../helpers/question';

export const splitValueComposedByCommaWithDot = compose(
  mapFp(trimFp),
  splitFp(';')
);

const dateParser = (date: string, isInterval: boolean, dateOnly: boolean) => {
  if (!date) return null;

  if (!isInterval) {
    return parse(
      date,
      dateOnly ? DATE_FNS_FORMAT_DATE_ONLY : DATE_FNS_FORMAT_NO_SECONDS,
      new Date()
    );
  }

  const [startDate, endDate] = splitValueComposedByCommaWithDot(date);

  return [
    startDate ? dateParser(startDate, false, dateOnly) : null,
    endDate ? dateParser(endDate, false, dateOnly) : null
  ];
};

const normalizeAnswer = (answer: IAnswer): IInterviewAnswer => {
  const { answer: value, options, question, meta: answerMeta, upload } = answer;

  let data = null;
  let meta = null;

  switch (question?.type) {
    case TYPES.Signature:
      data = value && upload ? upload : null;
      break;

    case TYPES.Numeric:
      if (!!question.isInterval && typeof value === 'string') {
        const [start, end] = splitValueComposedByCommaWithDot(value);
        data = [start, end];
      } else {
        data = value;
      }
      break;

    case TYPES.SingleAnswer:
    case TYPES.Status:
      if (value) {
        data = 'other';
        meta = {
          other: value
        };
      } else if (size(options) > 0) {
        data = options[0].id;
      }
      break;

    case TYPES.Ranging:
      data = map(answerMeta, orderInfo => orderInfo.optionId);
      break;

    case TYPES.DateTime:
      // $FlowFixMe
      data = dateParser(value, question.isInterval, question.dateOnly);
      break;

    case TYPES.Video:
      if (value === '@meta' && size(answerMeta) > 0) {
        data = answerMeta;
      } else {
        data = null;
      }
      break;

    case TYPES.MultipleAnswer:
    case TYPES.Checklist:
      data = map(options, opt => opt.id);
      if (value) {
        data = [...data, 'other'];
        meta = {
          other: value
        };
      }
      break;

    default:
      data = value;
  }

  // TODO: resolve later
  // $FlowIgnore
  return {
    cache: null,
    data,
    meta,
    question,
    questionId: question?.id,
    status: 'submitted'
  };
};

export default normalizeAnswer;
