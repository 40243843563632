// @flow

import * as React from 'react';

import type { IInterviewQuestion } from 'flow-types/entities/Question';

import useVariablesCompiledString from 'common/components/Question/hooks/useCompileString';

type HtmlQuestionBodyProps = {| question: IInterviewQuestion |};

const HTMLQuestionBody = ({ question }: HtmlQuestionBodyProps): React.Node => {
  const compile = useVariablesCompiledString();

  /* eslint-disable react/no-danger */
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: question.description ? compile(question.description) : ''
        }}
      />
    </div>
  );
  /* eslint-enable react/no-danger */
};

export default HTMLQuestionBody;
