// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import { merge } from 'merge-anything';
import styled from 'styled-components';
import * as K from 'keyboard-key';
import type { IInterviewQuestion } from 'flow-types/entities/Question';

import Slider, { StyledTrackContainer } from 'common/components/Range';
import {
  multiValueGuard,
  parseValue,
  safeParseInt,
  supportsSliderViewType
} from 'common/components/Question/NumericQuestionBody/utils';
import { Field } from 'common/components/Form';
import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';

import type { OnAnswerInput } from 'common/components/Question/flow';
import Input from '../../Input';

type Props = {
  question: IInterviewQuestion,
  onAnswerInput: OnAnswerInput
};

const Container = styled.div`
  ${StyledTrackContainer} {
    height: 6rem;
  }
`;

export default function MultiNumeric({
  question,
  onAnswerInput,
  ...rest
}: Props): React.Node {
  const intl = useIntl();

  const endInputRef = React.useRef(null);

  const answer = useAnswer(question.id, { data: null });

  const onChange = useAnswerUpdate(question.id);

  const { data } = answer;

  const { disabled } = question;

  const handleNumericAnswer = React.useCallback(
    value => {
      onChange(
        merge(
          { ...answer },
          {
            data: parseValue(value, true),
            errors: null
          }
        )
      );
    },
    [answer, onChange]
  );

  if (question.viewType === 'slider' && supportsSliderViewType(question)) {
    return (
      <Container>
        <Slider
          values={multiValueGuard(
            data,
            question.minValue || 0,
            question.maxValue || 1
          )}
          min={safeParseInt(question.minValue, 0)}
          max={safeParseInt(question.maxValue, 1)}
          onChange={handleNumericAnswer}
          disabled={disabled}
          allowOverlap
          step={question.step || 1}
        />
      </Container>
    );
  }

  const start = safeParseInt(data?.[0], '');
  const end = safeParseInt(data?.[1], '');

  const placeholder = intl.formatMessage({
    id: 'questionForm.fields.answer.placeholder'
  });

  return (
    <Field group wide="two">
      <Field>
        <Input
          {...rest}
          name="numeric_answer"
          disabled={disabled}
          fluid
          type="number"
          onKeyDown={event => {
            if (K.getCode(event) === K.Enter) {
              endInputRef.current.focus();
            }
          }}
          min={safeParseInt(question.minValue, null)}
          max={safeParseInt(data?.[1], safeParseInt(question.maxValue, null))}
          placeholder={placeholder}
          value={start}
          onlyValue
          onChange={val => {
            handleNumericAnswer([val, end]);
          }}
        />
      </Field>
      <Field>
        <Input
          ref={endInputRef}
          name="numeric_answer"
          disabled={disabled}
          fluid
          type="number"
          onKeyDown={event => {
            if (K.getCode(event) === K.Enter) {
              onAnswerInput({ data: [start, end] }, { forceSubmission: true });
            }
          }}
          min={safeParseInt(data?.[0], safeParseInt(question.minValue, null))}
          max={safeParseInt(question.maxValue, null)}
          placeholder={placeholder}
          value={end}
          onlyValue
          onChange={val => {
            handleNumericAnswer([start, val]);
          }}
        />
      </Field>
    </Field>
  );
}
