import revertDictionary from 'utils/revertDictionary';

const projectResponseDictionary = revertDictionary({
  'projectResponse.page.title': {
    en: 'Project #{projectId} / Response #{responseId}',
    ru: 'Проект #{projectId} / Запись #{responseId}'
  },

  'project.modals.checklistSelection.title': {
    en: 'Select checklist',
    ru: 'Выберите чеклист'
  },
  'project.actions.changeChecklist': {
    en: 'Change checklist',
    ru: 'Сменить чеклист'
  },
  'project.tabs.response.info': {
    en: 'Information',
    ru: 'Общая информация'
  },
  'project.tabs.response.rates': {
    en: 'Rates',
    ru: 'Оценки'
  },
  'project.tabs.response.history': {
    en: 'History',
    ru: 'История'
  },
  'project.tabs.accessRules': {
    en: 'Access',
    ru: 'Доступ'
  },
  'projectResponse.title': {
    en: 'Response #{what}',
    ru: 'Встреча #{what}'
  },
  'projectResponse.newMark': {
    en: 'New mark',
    ru: 'Новая оценка'
  },

  /**
   * REVIEW STAGES
   */
  'projectResponse.reviewStage.noMarks': {
    en: 'No marks',
    ru: 'Без оценок'
  },
  'projectResponse.reviewStage.inProgress': {
    en: 'Evaluating',
    ru: 'Оценивается'
  },
  'projectResponse.reviewStage.completed': {
    en: 'Evaluated',
    ru: 'Оценена'
  },

  /**
   * MEETING STAGE
   */
  'projectResponse.meetingStage.inProgress': {
    en: 'In progress',
    ru: 'Идёт'
  },
  'projectResponse.meetingStage.completedNoData': {
    en: 'Completed, no data',
    ru: 'Завершена, нет данных'
  },
  'projectResponse.meetingStage.completedWithData': {
    en: 'Completed, has data',
    ru: 'Завершена, есть данные'
  },

  /**
   * Transition History
   */
  'projectResponse.transitionHistory.contextMenu.showOnPlayer': {
    en: 'Show on player',
    ru: 'Показать на плеере'
  },

  'projectResponse.transitionHistory.messages.thereIsNotSuitedRecordsForTimestamp': {
    en: 'There is not suited record for that timestamp',
    ru: 'Нет подходящей аудиозаписи'
  },

  /**
   * ERRORS
   */
  'projectResponse.error.noAccess.title': {
    en: 'Access error',
    ru: 'Ошибка доступа'
  },
  'projectResponse.error.noAccess.content': {
    en: "You're not allowed to view that response yet!",
    ru: 'Данная встреча ещё не доступна для просмотра!'
  }
});

export default projectResponseDictionary;
