import {
  EXPERT,
  ADMIN,
  COMPANY_ADMIN,
  DIVISION_HEAD,
  OPERATOR
} from 'common/helpers/user';

export const SYSTEM_COLUMNS = {
  ID_WITH_DATE: 'id_and_date',
  INTERVIEWER_AND_DURATION: 'interviewer_and_duration',
  STATUS: 'status',
  RATE: 'rate',
  EXPERT_ID: 'expert_id',
  EXPERT: 'expert'
};

export const ROLES_WHO_CAN_ENTER_EXISTING_SESSION = [
  ADMIN,
  COMPANY_ADMIN,
  DIVISION_HEAD,
  EXPERT,
  OPERATOR
];

export const ROLES_WHO_CAN_ASSIGN_EXPERT = [ADMIN, COMPANY_ADMIN];

export const ROLES_WHO_CAN_SEE_RATES_ONLY_IF_RECORD_IS_REVIEWED = [
  DIVISION_HEAD
];

export const ROLES_WHO_CAN_SEE_EXPERT = [ADMIN, COMPANY_ADMIN];
