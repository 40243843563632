import { PROJECT_STATUSES } from 'common/helpers/project/constants';

/**
 * @param projectStatus
 * @returns {boolean}
 */
export function isActive(projectStatus: number | string): boolean %checks {
  if (!projectStatus || Number.isNaN(+projectStatus)) return false;
  return +projectStatus === PROJECT_STATUSES.ACTIVE;
}

/**
 * @param projectStatus
 * @returns {boolean}
 */
export function isOutdated(projectStatus: number | string): boolean %checks {
  if (!projectStatus || Number.isNaN(+projectStatus)) return false;
  return +projectStatus === PROJECT_STATUSES.OUTDATED;
}

/**
 * @param projectStatus
 * @returns {boolean}
 */
export function isPreparing(projectStatus: number | string): boolean %checks {
  if (!projectStatus || Number.isNaN(+projectStatus)) return false;
  return +projectStatus === PROJECT_STATUSES.PREPARING;
}
