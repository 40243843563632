import { combineEpics } from 'redux-observable';
import fetchList, {
  fetchListOnRecordSelection$,
  fetchListOnTaskSuccess$
} from './fetchList';
import fetchTasks, { fetchTasksOnRecordSelection$ } from './fetchTasks';
import watchTask, { watchActiveTasksOnFetchTasks$ } from './watchTask';
import createTask from './createTask';
import removeMessage from './removeMessage';
import saveMessage from './saveMessage';
import saveSides from './saveSides';

export default combineEpics(
  watchActiveTasksOnFetchTasks$,
  fetchList,
  fetchTasks,
  fetchListOnTaskSuccess$,
  fetchListOnRecordSelection$,
  fetchTasksOnRecordSelection$,
  watchTask,
  createTask,
  removeMessage,
  saveMessage,
  saveSides
);
