// @flow

import revertDictionary from 'utils/revertDictionary';

// $FlowIgnore
const variablesDictionary = revertDictionary({
  'common.variables.dataType.string.title': {
    en: 'String',
    ru: 'Текст'
  },
  'common.variables.dataType.number.title': {
    en: 'Number',
    ru: 'Число'
  },
  'common.variables.dataType.option.title': {
    en: 'Option',
    ru: 'Опция'
  },
  'common.variables.dataType.options.title': {
    en: 'Options',
    ru: 'Список опций'
  },
  'common.variables.dataType.null.title': {
    en: 'No info',
    ru: 'Нет данных'
  },

  'common.variables.origin.block.title': {
    en: 'Block',
    ru: 'Блок'
  },
  'common.variables.origin.computed.title': {
    en: 'Computed property',
    ru: 'Вычисляемая переменная'
  },

  'common.variables.varchar.title': {
    en: 'Limited String',
    ru: 'Лимитированная строка'
  },
  // 'common.variables.varchar.description': {
  // TODO: sync with russian translation
  // en: 'Data type, considering strings storage with a fixed max length.',
  // ru: 'Текстовые данные с ограничением на количество символов в строке.'
  // },

  'common.variables.text.title': {
    en: 'Text',
    ru: 'Текст'
  },

  // 'common.variables.text.description': {
  //   en: 'Text data',
  //   ru: 'Текстовые данные'
  // },

  'common.variables.integer.title': {
    en: 'Integer',
    ru: 'Целое число'
  },
  // 'common.variables.integer.description': {  },

  'common.variables.decimal.title': {
    en: 'Decimal',
    ru: 'Дробное число'
  },
  // 'common.variables.decimal.description': {},

  'common.variables.timestamp.title': {
    en: 'Date & Time',
    ru: 'Дата и время'
  },
  // 'common.variables.timestamp.description': {},

  'common.variables.date.title': {
    en: 'Date',
    ru: 'Дата'
  },
  // 'common.variables.date.description': {}

  /**
   * PROJECT PAGE UI RELATED LOCAL STRINGS
   */
  'project.variablesPanel.createVariableButton.title': {
    en: 'Create',
    ru: 'Создать переменную'
  },
  'project.variableForm.unsavedChangesWillBeLostConfirm.title': {
    en: 'Form has been updated. All changes will lost. Confirm to proceed?',
    ru:
      'Форма была обновлена. При выходе будут утеряны все изменения. Продолжить?'
  }
});

export default variablesDictionary;
