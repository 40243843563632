// @flow

import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';

import type { Epic } from 'flow-types/Epic';
import type { AppState } from 'flow-types/AppState';
import type { UpdateAnswer } from 'flow-types/actions/interview/UpdateAnswer';
import type { InterviewLogicState } from 'flow-types/states/InterviewState/InterviewLogicState';

import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import reduce from 'lodash/reduce';
import entries from 'lodash/entries';
import find from 'lodash/find';
import processLogicResultsAction from '../../actions/interview/processLogicResults';
import { interviewLogicRulesSelector } from '../../selectors/interview/logic';
import {
  interviewAnswersSelector,
  interviewLogicStateSelector,
  interviewProjectDataSelector
} from '../../selectors/interview/root';
import { interviewQuestionsSelector } from '../../selectors/interview/questions';

const processLogicOnAnswerChangeEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/update-answer'),
    RxOperators.withLatestFrom(state$),
    RxOperators.filter(([action: UpdateAnswer, state]) => {
      const project = interviewProjectDataSelector(state);
      const logic: InterviewLogicState = interviewLogicStateSelector(state);

      return (
        (project.isLogicLiveExecution ?? true) &&
        // Do not reprocess logic for questions
        // that are not used as sources
        logic.sources.includes(action.questionId)
      );
    }),
    RxOperators.map(([, state]: [UpdateAnswer, AppState]) => {
      const answers = interviewAnswersSelector(state);
      const rules = interviewLogicRulesSelector(state);
      const questions = interviewQuestionsSelector(state);

      const flatQuestions = flatQuestionsSubQuestions(questions);

      const combinedDataOfAnswersAndQuestion = reduce(
        entries(answers),
        (combinedAnswers, [questionId, answer]) => {
          // eslint-disable-next-line eqeqeq
          const question = find(flatQuestions, { id: +questionId });

          return {
            ...combinedAnswers,
            [questionId]: {
              ...answer,
              question
            }
          };
        },
        {}
      );

      return processLogicResultsAction(combinedDataOfAnswersAndQuestion, rules);
    })
  );

export default processLogicOnAnswerChangeEpic;
