import { ofType } from 'redux-observable';
import { forkJoin } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { decamelizeKeys } from 'humps';
import { toast } from 'react-toastify';

import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import projectCheckReportNormalizer from 'common/transducers/projects/projectCheckReportNormalizer';
import projectDurationReportNormalizer from 'common/transducers/projects/projectDurationReportNormalizer';
import type { FetchReportsSuccess } from 'flow-types/actions/projects/detail/reports/FetchReports';
import debounceEpic from 'common/epicHelpers/debounceEpic';
import ajaxErrorsDictionary from '../../../intl/ajaxErrorsDictionary';
import { languageStateSelector } from '../../../selectors';
import { reportsFilterSelector } from '../../../selectors/projects/reports';
import { projectIdFromPathSelector } from '../../../selectors/projects';

const fetchProjectReports: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-reports/fetch'),
    RxO.withLatestFrom($state),
    RxO.map(([, state]) => {
      const language = languageStateSelector(state);

      const projectId = projectIdFromPathSelector(state);

      const filter = reportsFilterSelector(state);

      return { filter, projectId, language };
    }),
    debounceEpic(),
    RxO.switchMap(({ filter, projectId, language }) =>
      forkJoin({
        check: request({
          url: interpolateString(
            API.projects.checkReport,
            decamelizeKeys({ projectId })
          ),
          query: decamelizeKeys(filter)
        }),
        duration: request({
          url: interpolateString(
            API.projects.durationReport,
            decamelizeKeys({ projectId })
          ),
          query: decamelizeKeys(filter)
        })
      }).pipe(
        RxO.map((result): FetchReportsSuccess => {
          const { check, duration } = result;
          const {
            response: { data: checkData }
          } = check;
          const {
            response: { data: durationData }
          } = duration;

          return {
            type: 'project-reports/fetch-success',
            checkReport: checkData
              ? projectCheckReportNormalizer(checkData)
              : null,
            durationReport: durationData
              ? projectDurationReportNormalizer(durationData)
              : null
          };
        }),
        RxO.catchError(errorResponse => {
          toast.error(ajaxErrorsDictionary[language][errorResponse.code], {
            autoClose: 1500
          });
          return [{ type: 'project-reports/fetch-fail' }];
        })
      )
    )
  );

export default fetchProjectReports;
