// @flow

/**
 * This component is a wrapper around swiper/react
 */
import React from 'react';
import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import fp from 'lodash/fp';
import map from 'lodash/map';

import type { AbstractComponent } from 'react';
import type { ReactComponent } from 'flow-types/ReactComponent';

const SliderContainer = styled.div`
  height: ${fp.get('$height')};
  min-height: ${fp.get('$minHeight')};
  max-height: ${fp.getOr('100vh', 'maxHeight')};

  & > .swiper-container {
    height: 100%;
  }
`;

type Props = {
  data: mixed[],
  SlideComponent: ReactComponent,
  swiperMinHeight?: string,
  swiperHeight?: string,
  slideProps?: Object
};

SwiperCore.use([Pagination, Navigation, Scrollbar]);

const Slider: AbstractComponent<Props> = React.forwardRef(
  (
    {
      data,
      SlideComponent,
      swiperMinHeight,
      swiperHeight,
      slideProps,
      ...props
    }: Props,
    ref
  ) => (
    <SliderContainer $height={swiperHeight} $minHeight={swiperMinHeight}>
      <Swiper {...props} ref={ref}>
        {map(data, (slide: mixed, index) => (
          <SwiperSlide key={index}>
            <SlideComponent {...(slideProps && slideProps)} data={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  )
);

// $FlowIgnore
Slider.defaultProps = {
  swiperMinHeight: null,
  swiperHeight: null,
  slideProps: null
};

export default Slider;
