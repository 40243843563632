import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import interpolateString from 'common/helpers/interpolateString';
import type { Epic } from '../../../flow-types/Epic';
import type { SaveComparator } from '../../../flow-types/actions/checks/checklistItems/SaveChecklistItem';
import isNewElement from '../../../common/helpers/isNewElement';
import { API } from '../../../utils/config';
import request from '../../../utils/request';
import { comparatorDenormalizer } from '../../../common/transducers/checks/testElements/denornalizer';
import responseParser from '../../../common/epicHelpers/responseParser';
import { comparatorNormalizer } from '../../../common/transducers/checks/testElements/normalizer';

export const saveComparatorEpic: Epic = $action =>
  $action.pipe(
    ofType('checklist-comparators/save'),
    RxOperators.map(action => {
      const { comparator }: SaveComparator = action;

      const isNew = isNewElement(comparator);

      return {
        isNew,
        comparator
      };
    }),
    RxOperators.mergeMap(({ isNew, comparator }) => {
      const url = !isNew
        ? interpolateString(API.comparators.detail, {
            comparatorId: comparator.id
          })
        : API.comparators.list;

      return request({
        url,
        body: comparatorDenormalizer(comparator),
        method: isNew ? 'POST' : 'PUT'
      }).pipe(
        responseParser,
        RxOperators.map(response => ({
          type: 'checklist-comparators/save-success',
          data: comparatorNormalizer(response.data),
          originalId: comparator.id
        })),
        RxOperators.mergeMap(successAction =>
          of(successAction, {
            type: 'checklist-comparators/fetch'
          })
        ),
        RxOperators.catchError(({ response, message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'checklist-comparators/save-fail',
            error: message,
            ...(response &&
              response.data && {
                error: response.data,
                originalId: comparator.id
              })
          });
        })
      );
    })
  );
