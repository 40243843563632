// @flow
import revertDictionary from '../../utils/revertDictionary';

import type { IMessageKeyedDictionary } from '../../flow-types/Dictionary';

const fileUploaderDictionary: IMessageKeyedDictionary = {
  'fileUploader.labelFileLoading': {
    en: 'Loading',
    ru: 'Скачивание'
  },
  'fileUploader.labelFileProcessing': {
    en: 'Uploading',
    ru: 'Загрузка'
  },
  'fileUploader.labelFileProcessingComplete': {
    en: 'Upload complete',
    ru: 'Завершено'
  },
  'fileUploader.labelTapToCancel': {
    en: 'tap to cancel',
    ru: 'отменить'
  },
  'fileUploader.labelTapToRetry': {
    en: 'tap to retry',
    ru: 'повторить'
  },
  'fileUploader.limitReached': {
    en:
      "File limit has been reached. To attach another file you have to remove already existed attachment by clicking on attachments's top-left crossing button.",
    ru:
      'Вы прикрепили максимально возможное число файлов. Чтобы прикрепить другой файл, удалите один из уже прикрепленных, нажав на фотографии крестик.'
  },
  'fileUploader.labelIdle': {
    en: `Attach your files`,
    ru: `Прикрепить файлы`
  },
  'fileUploader.minFiles': {
    en: `{count, plural, other {min. # files} one {min. # file}}`,
    ru: `{count, plural, other {мин. # файлов} one {мин. # файл} few {мин. # файла}}`
  },
  'fileUploader.maxFiles': {
    en: `{count, plural, other {max. # files} one {max. # file} =0 ""}`,
    ru: `{count, plural, other {макс. # файлов} one {макс. # файл} few {макс. # файла}}`
  }
};

export default revertDictionary(fileUploaderDictionary);
