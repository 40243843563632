// @flow
import fp from 'lodash/fp';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import isNil from 'lodash/isNil';
import shuffle from 'lodash/shuffle';
import { merge } from 'merge-anything';
import { camelizeKeys } from 'humps';

import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { IQuestion } from 'flow-types/entities/Question';

import type { IQuestionOption } from 'flow-types/entities/QuestionOption';
import type { IProject } from 'flow-types/entities/Project';
import toClient from 'common/transducers/projects/computedProperties/toClient';
import {
  projectSettingsNormalizer,
  projectCompaniesNormalizer,
  projectGroupsMapper,
  projectGroupsNormalizer as baseGroupsNormalizer,
  projectPeriodNormalizer
} from '../projects/projectsNormalizer';

import { SELECTABLE_TYPES, TYPES } from '../../helpers/question';

const projectQuestionsMapper = project => ({
  ...project,
  mappedQuestions: reduce(
    project.groups,
    (result, group) => {
      const { mappedQuestions } = group;
      return {
        ...result,
        ...mappedQuestions
      };
    },
    {}
  )
});

const projectOptionsMapper = project => ({
  ...project,
  mappedOptions: reduce(
    project.groups,
    (result, group) => {
      const { questions } = group;
      return {
        ...result,
        ...reduce(
          questions,
          (subResult, question) => {
            const { mappedOptions } = question;
            return {
              ...subResult,
              ...(mappedOptions && {
                ...mappedOptions
              })
            };
          },
          {}
        )
      };
    },
    {}
  )
});

export const processProjectGroupsQuestionAsset = question => {
  const next = { ...question };

  next.assets = [];

  if (next.image) {
    next.assets = next.assets.concat([
      // {
      //   type: 'image',
      //   url: question.image.url
      // },
      // {
      //   type: 'image',
      //   url: question.image.thumb128
      // },
      {
        type: 'image',
        url: question.image.thumb256
      }
    ]);
  }

  if (next.showImages) {
    next.options?.forEach(option => {
      if (option.image) {
        next.assets = next.assets.concat([
          // {
          //   type: 'image',
          //   url: option.image.url
          // },
          // {
          //   type: 'image',
          //   url: option.image.thumb128
          // },
          {
            type: 'image',
            url: option.image.thumb256
          }
        ]);
      }
    });
  }

  if (next.settings?.enablePolygonOptions && next.settings?.imageSrc) {
    next.assets.push({
      type: 'image',
      url: next.settings.imageSrc
    });
  }

  // console.log(next.assets);

  return next;
};

export const projectGroupsQuestionOptionsShuffler = (
  question: IQuestion
): IQuestion => {
  const { type } = question;

  const isShufflingSupported =
    [...SELECTABLE_TYPES, TYPES.Ranging].includes(type) &&
    !!question.settings?.dynamicOptionsSorting;

  if (!isShufflingSupported) {
    return question;
  }

  const next = { ...question };

  const { excludingOptions } = next;

  let ordered = [];

  if (next.settings.dynamicOptionsSorting === 'all') {
    ordered = [...next.options];
    next.options = [];
  } else if (next.settings.dynamicOptionsSorting === 'except_excluding') {
    ordered = remove(
      next.options,
      (option: IQuestionOption) => !includes(excludingOptions, option.id)
    );
  } else if (next.settings.dynamicOptionsSorting === 'only_excluding') {
    ordered = remove(next.options, (option: IQuestionOption) =>
      includes(excludingOptions, option.id)
    );
  }

  ordered = shuffle(ordered);

  next.options = [...ordered, ...next.options].map((option, order) => ({
    ...option,
    order,
    sortOrder: order
  }));

  return next;
};

const injectOtherOption = question => {
  if (!SELECTABLE_TYPES.includes(question.type)) {
    return question;
  }

  return {
    ...question,
    options: question.otherEnabled
      ? [
          ...question.options,
          { id: 'other', title: question.otherTitle, userInput: true }
        ]
      : question.options
  };
};

const processProjectGroupsQuestionRequired = (question: IQuestion) => {
  if (
    !SELECTABLE_TYPES.includes(question.type) ||
    TYPES.SingleAnswer === question.type
  ) {
    return question;
  }

  return {
    ...question,
    required:
      question.required ||
      // TODO: check that scale, (min/max)Answers and (min/max)Value are either null,
      //  or of type "number" and will never come as strings.
      (typeof question.minAnswers === 'number' && question.minAnswers > 0)
  };
};

const normalizeQuestion = fp.compose(
  injectOtherOption,
  processProjectGroupsQuestionRequired,
  processProjectGroupsQuestionAsset,
  projectGroupsQuestionOptionsShuffler
);

const projectGroupsQuestionsNormalizer = project => {
  const { groups, ...rest } = project;

  return {
    ...rest,
    groups: reduce(
      groups,
      (_groups, group: IQuestionGroup) => {
        const { isSinglePage } = group;

        return [
          ..._groups,
          {
            ...group,
            questions: map(group.questions, (question: IQuestion) => {
              const {
                type,
                settings,
                minValue,
                maxValue,
                scale,
                isEditable
              } = question;

              const isTable = type === TYPES.Table;

              const isSinglePageTableQuestion =
                isTable && settings && settings.viewType === 'single';

              let updates = [{ forPostPollingOnly: group.forPostPollingOnly }];

              if (isEditable === false) {
                updates = [...updates, { disabled: true }];
              }

              if (isTable) {
                updates = [
                  ...updates,
                  {
                    subQuestions: map(question.subQuestions, sub =>
                      normalizeQuestion({
                        ...sub,
                        parentTitle: question.title,
                        ...(isEditable === false && {
                          disabled: true
                        })
                      })
                    )
                  }
                ];
              }

              if (isSinglePage && isSinglePageTableQuestion) {
                updates = [
                  ...updates,
                  {
                    settings: {
                      viewType: 'table'
                    }
                  }
                ];
              }

              if (type === TYPES.Numeric) {
                const normalizedScale = !isNil(scale)
                  ? parseInt(scale, 10)
                  : null;
                updates = [
                  ...updates,
                  {
                    scale: normalizedScale,
                    minValue: !isNil(minValue)
                      ? parseFloat(minValue).toFixed(normalizedScale)
                      : null,
                    maxValue: !isNil(maxValue)
                      ? parseFloat(maxValue).toFixed(normalizedScale)
                      : null
                  }
                ];
              }

              return normalizeQuestion(merge({ ...question }, ...updates));
            })
          }
        ];
      },
      []
    )
  };
};

export const projectVariablesNormalizer = (project: Object): IProject => ({
  ...project,
  variables: toClient(project.variables)
});

export const projectGroupsNormalizer = fp.compose(
  projectGroupsQuestionsNormalizer,
  baseGroupsNormalizer
);

export default fp.compose(
  projectOptionsMapper,
  projectQuestionsMapper,
  projectCompaniesNormalizer,
  projectGroupsMapper,
  projectSettingsNormalizer,
  projectPeriodNormalizer,
  projectVariablesNormalizer,
  projectGroupsNormalizer,
  camelizeKeys
);
