// @flow

import type { FetchProject } from '../../flow-types/actions/projects/detail/project/FetchProject';

export default function fetchProjectCreator(
  projectId: number,
  silent: boolean
): FetchProject {
  return {
    type: 'project/fetch',
    projectId,
    silent
  };
}
