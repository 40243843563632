import { camelizeKeys } from 'humps';

export const LOGROCKET = process.env.REACT_APP_LOGROCKET || false;

export const LOGROCKET_APP_ID =
  process.env.REACT_APP_LOGROCKET_APP_ID || 'jclk7m/test1';

export const API_PREFIX =
  process.env.REACT_APP_API_PREFIX || 'https://api-univey.kbvesna.ru';

export const CRM_HOST =
  process.env.REACT_APP_CRM_HOST ||
  `https://crm.dmfs.ru/MAIN/main.aspx?etn=appointment&pagetype=entityrecord&id=:guid_id`;

export const YANDEX_MAPS_API_KEY =
  process.env.REACT_APP_YANDEX_API_KEY ||
  'ae4bb257-1963-4b65-b94e-923179543219';

/*
****************
   CONSTANTS
****************
*/

// how much time (in seconds) should be skipped when clicking on rewind buttons
export const WAVESURFER_REWIND_AMOUNT = 15;

export const DEFAULT_PAGE_TITLE = 'Formalist';

export const ALLOWED_VIDEO_FILE_TYPES = ['video/mp4', 'video/x-m4v', 'video/*'];

export const VARIABLE_PREFIX = {
  formula: '$',
  handlebars: '$'
};

/*
****************
      API
****************
*/

const createAPI = path => `${API_PREFIX}${path}`;

// TODO: all snake_cased API params turn in to camelCase
export const API = {
  userFieldsSettings: {
    list: `${API_PREFIX}/api/user_fields_settings`,
    detail: `${API_PREFIX}/api/user_fields_settings/:settingsId`
  },
  password: {
    change: `${API_PREFIX}/api/password/change`,
    code: `${API_PREFIX}/api/password/code`,
    /**
     * fast change current user password without a code
     */
    fastChange: `${API_PREFIX}/api/users/password`
  },
  login: `${API_PREFIX}/api/login`,
  hashLogin: `${API_PREFIX}/api/login/hash`,
  // I set here :hash placeholder, to make it clear what should be passed here
  // As soon as public hash logic requires hash to be passed at the end
  // it would be more clear to describe how this endpoint works by just putting placeholder
  // at the right place
  // Thus, there will be no need to search in code for that endpoint usage to understand it
  publicHashLogin: `${API_PREFIX}/api/projects/load/:hash`,
  meetingFields: {
    list: `${API_PREFIX}/api/meeting_fields`
  },
  responseReviewStages: `${API_PREFIX}/api/response_review_stages`,
  responses: {
    list: `${API_PREFIX}/api/responses`,
    load: `${API_PREFIX}/api/responses/:response_id/load`,
    complete: `${API_PREFIX}/api/responses/:response_id/complete`,
    completeReview: `${API_PREFIX}/api/responses/:response_id/complete_review`,
    reviewStage: `${API_PREFIX}/api/responses/:response_id/review_stage`,
    comment: `${API_PREFIX}/api/responses/:response_id/comment`,
    detail: `${API_PREFIX}/api/responses/:response_id`,
    answer: `${API_PREFIX}/api/responses/:response_id/answer`,
    answers: `${API_PREFIX}/api/responses/:responseId/answers/`,
    changesHistory: `${API_PREFIX}/api/responses/:responseId/history/`,
    viewsHistory: `${API_PREFIX}/api/responses/:responseId/views/`,
    questionChangesHistory: `${API_PREFIX}/api/responses/:responseId/question/:questionId/history/`,
    questionViewsHistory: `${API_PREFIX}/api/responses/:responseId/question/:questionId/views/`,
    views: `${API_PREFIX}/api/responses/:responseId/views/`,
    stats: `${API_PREFIX}/api/responses/:response_id/stats`,
    massActions: {
      tasksDraftsList: `${API_PREFIX}/api/projects/:projectId/action_tasks/drafts`,
      tasksDraft: `${API_PREFIX}/api/projects/:projectId/action_tasks/drafts/:draftId`,
      applyDraft: `${API_PREFIX}/api/projects/:projectId/action_tasks/drafts/:draftId/apply`,
      tasksList: `${API_PREFIX}/api/projects/:projectId/action_tasks`,
      taskStatus: `${API_PREFIX}/api/projects/:projectId/action_tasks/:taskId/status`,
      filterCounter: `${API_PREFIX}/api/projects/:projectId/action_tasks/filter_count`
    },
    disableTestElements: `${API_PREFIX}/api/responses/:response_id/disable_checklist_items`,
    enableTestElements: `${API_PREFIX}/api/responses/:response_id/enable_checklist_items`,
    syncTestElements: `${API_PREFIX}/api/responses/:response_id/sync_checklist_items`,
    disableTestElement: `${API_PREFIX}/api/responses/:response_id/checklist_items/:test_element_id/disable`,
    enableTestElement: `${API_PREFIX}/api/responses/:response_id/checklist_items/:test_element_id/enable`,
    assignExpert: `${API_PREFIX}/api/responses/:responseId/assign_expert`,
    availableExperts: `${API_PREFIX}/api/responses/:responseId/available_experts`
  },
  companies: {
    list: `${API_PREFIX}/api/companies`,
    detail: `${API_PREFIX}/api/companies/:company_id`
  },
  departments: {
    list: `${API_PREFIX}/api/companies/:company_id/departments`,
    detail: `${API_PREFIX}/api/companies/:company_id/departments/:department_id`
  },
  checklists: {
    list: `${API_PREFIX}/api/checklists`,
    detail: `${API_PREFIX}/api/checklists/:checklist_id`
  },
  testGroups: {
    list: `${API_PREFIX}/api/checklist_groups`,
    detail: `${API_PREFIX}/api/checklist_groups/:test_group_id`
  },
  comparators: {
    list: `${API_PREFIX}/api/checklist_items`,
    detail: `${API_PREFIX}/api/checklist_items/:comparatorId`
  },
  postPollingDelayTypes: `${API_PREFIX}/post_polling_delay_types`,
  projects: {
    list: `${API_PREFIX}/api/projects`,
    shortList: `${API_PREFIX}/api/projects/short`,
    script: `${API_PREFIX}/api/projects/script`,
    detail: `${API_PREFIX}/api/projects/:projectId`,
    detailInterview: `${API_PREFIX}/api/projects/:projectId/interview`,
    variables: {
      list: `${API_PREFIX}/api/project_variables`,
      detail: `${API_PREFIX}/api/project_variables/:variableId`
    },
    accessSettings: {
      structured: `${API_PREFIX}/api/projects/access_settings`,
      list: `${API_PREFIX}/api/projects/:projectId/access_settings`,
      detail: `${API_PREFIX}/api/projects/:projectId/access_settings/:settingsId`,
      users: `${API_PREFIX}/api/projects/:projectId/access_settings/:settingsId/users`
    },
    pollingNotifications: {
      list: `${API_PREFIX}/api/projects/:projectId/polls_to_send`,
      detail: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId`,
      activate: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId/activate`,
      deactivate: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId/stop`,
      suitedResponsesCount: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId/count_responses`,
      declinedResponsesCount: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId/declined_responses`,
      responsesCodes: `${API_PREFIX}/api/projects/:projectId/polls_to_send/:notificationId/responses_codes`
    },
    projectLogic: `${API_PREFIX}/api/projects/:project_id/rules`,
    projectRules: `${API_PREFIX}/api/project_rules`,
    projectRule: `${API_PREFIX}/api/project_rules/:rule_id`,
    lastUpdateDate: `${API_PREFIX}/api/projects/:project_id/updated_at`,
    clone: `${API_PREFIX}/api/projects/:project_id/clone`,
    audio_records: `${API_PREFIX}/api/projects/:project_id/audio_records`,
    responses: `${API_PREFIX}/api/projects/:project_id/response`,
    checkReport: `${API_PREFIX}/api/projects/:project_id/check_report`,
    durationReport: `${API_PREFIX}/api/projects/:project_id/duration_report`,
    checklists: `${API_PREFIX}/api/projects/:projectId/checklists`,
    searchSettings: {
      list: `${API_PREFIX}/api/projects/:projectId/search_settings`,
      detail: `${API_PREFIX}/api/projects/:projectId/search_settings/:settingsId`
    },
    exportTasks: {
      list: `${API_PREFIX}/api/projects/:projectId/responses_export_tasks`
    },
    exportSettings: {
      list: `${API_PREFIX}/api/projects/:projectId/user_responses_export_settings`,
      detail: `${API_PREFIX}/api/projects/:projectId/user_responses_export_settings/:settingsId`
    },
    userFieldsSettings: {
      list: `${API_PREFIX}/api/projects/:projectId/user_fields_settings`,
      detail: `${API_PREFIX}/api/:projectId/user_fields_settings/:settingsId`
    },
    userFilterSettingsListGroups: {
      list: `${API_PREFIX}/api/projects/:projectId/user_filters_settings_groups`,
      sort: `${API_PREFIX}/api/projects/:projectId/user_filters_settings_groups/sort`,
      detail: `${API_PREFIX}/api/:projectId/user_filters_settings_groups/:groupId`
    },
    userFilterSettings: {
      list: `${API_PREFIX}/api/projects/:projectId/user_filters_settings`,
      counts: `${API_PREFIX}/api/projects/:projectId/user_filters_settings/counts`,
      detail: `${API_PREFIX}/api/projects/:projectId/user_filters_settings/:settingsId`,
      sort: `${API_PREFIX}/api/projects/:projectId/user_filters_settings_groups/:groupId/filters_settings_sort`
    },
    statisticTables: {
      list: `${API_PREFIX}/api/projects/:projectId/new_statistic_table`
    },
    statisticTablesSettings: {
      list: `${API_PREFIX}/api/projects/:projectId/user_new_statistic_table_settings`,
      detail: `${API_PREFIX}/api/projects/:projectId/user_new_statistic_table_settings/:settingsId`,
      availableFields: `${API_PREFIX}/api/projects/:projectId/user_new_statistic_table_settings/available_fields`
    },
    pivotTable: {
      list: `${API_PREFIX}/api/projects/:projectId/user_statistic_table_settings`,
      table: `${API_PREFIX}/api/projects/:projectId/user_statistic_table_settings/:tableId`,
      availableFields: `${API_PREFIX}/api/projects/:projectId/user_statistic_table_settings/available_fields`
    },
    statisticTable: `${API_PREFIX}/api/projects/:projectId/statistic_table`,
    availableQuestions: `${API_PREFIX}/api/projects/:projectId/available_questions`
  },
  questionTypes: {
    list: `${API_PREFIX}/api/question_types`,
    detail: `${API_PREFIX}/api/question_types/:question_type_id`
  },
  questionGroups: {
    list: `${API_PREFIX}/api/question_groups`,
    detail: `${API_PREFIX}/api/question_groups/:question_group_id`,
    sort: `${API_PREFIX}/api/projects/:project_id/question_groups/sort`
  },
  questions: {
    list: `${API_PREFIX}/api/questions`,
    detail: `${API_PREFIX}/api/questions/:question_id`,
    sort: `${API_PREFIX}/api/question_groups/:question_group_id/questions/sort`
  },
  options: {
    sort: `${API_PREFIX}/api/questions/:question_id/options/sort`
  },
  uploads: {
    list: `${API_PREFIX}/api/uploads`,
    detail: `${API_PREFIX}/api/uploads/:uploadId`,
    binary: `${API_PREFIX}/api/uploads/binary`,
    peaks: `${API_PREFIX}/api/uploads/:upload_id/peaks`,
    regions: `${API_PREFIX}/api/record_regions`,
    region: `${API_PREFIX}/api/record_regions/:region_id`,
    rating: `${API_PREFIX}/api/record_regions/:region_id/rating`,
    decodings: {
      list: `${API_PREFIX}/api/uploads/:uploadId/decoding`,
      detail: `${API_PREFIX}/api/uploads/:uploadId/decoding/:decodingId`,
      messages: {
        list: `${API_PREFIX}/api/uploads/:uploadId/decoding/:decodingId/messages`,
        detail: `${API_PREFIX}/api/uploads/:uploadId/decoding/:decodingId/messages/:messageId`
      },
      tasks: {
        list: `${API_PREFIX}/api/uploads/:uploadId/decoding/tasks`,
        detail: `${API_PREFIX}/api/uploads/:uploadId/decoding/tasks/:taskId`
      }
    }
  },
  // regions: {
  //   list: `${API_PREFIX}/api/records_regions`
  // },
  users: {
    projectsAccessSettings: `${API_PREFIX}/api/users/:userId/access_settings`,
    list: `${API_PREFIX}/api/users`,
    detail: `${API_PREFIX}/api/users/:userId`,
    self: `${API_PREFIX}/api/users/self`
  },
  roles: {
    list: `${API_PREFIX}/api/roles`
  },
  meetings: {
    list: `${API_PREFIX}/api/meetings`,
    filters: `${API_PREFIX}/api/meetings/filters`,
    count: `${API_PREFIX}/api/meetings/count`,
    add: `${API_PREFIX}/api/meetings`
  },
  managers: {
    list: `${API_PREFIX}/api/managers`
  },
  crm: {
    entities: `${API_PREFIX}/api/crm/models`,
    queries: `${API_PREFIX}/api/crm/queries`,
    views: `${API_PREFIX}/api/crm/views`,
    pages: `${API_PREFIX}/api/crm/pages`,
    executeQuery: `${API_PREFIX}/api/crm`,
    projectVariables: `${API_PREFIX}/api/project_variables`,
    setVariables: `${API_PREFIX}/api/responses/:response_id/set_variables`
  },

  // region Geolocation API
  regions: {
    list: createAPI(`/api/regions`),
    detail: createAPI(`/api/regions/:regionId`)
  },
  districts: {
    list: createAPI('/api/districts'),
    detail: createAPI('/api/districts/:districtId')
  },
  cities: {
    list: createAPI('/api/cities'),
    detail: createAPI('/api/cities/:cityId')
  },
  streets: {
    list: createAPI('/api/streets'),
    detail: createAPI('/api/streets/:streetId')
  },
  buildings: {
    list: createAPI('/api/buildings'),
    detail: createAPI('/api/buildings/:buildingId')
  },
  rooms: {
    list: createAPI(`/api/rooms`),
    detail: createAPI('/api/rooms/:roomId')
  },
  // endregion

  // region Waves of Meetings
  meetingsWaves: {
    buildingsWaves: createAPI('/api/buildings/waves'),
    plainBuildingsWaves: createAPI('/api/buildings/waves/plain'),
    changeBuildingManager: createAPI(
      '/api/waves_of_meetings/:waveId/change_building_manager'
    ),
    list: createAPI('/api/waves_of_meetings'),
    detail: createAPI('/api/waves_of_meetings/:waveId'),
    status: createAPI('/api/waves_of_meetings/:waveId/status')
  },
  meetingsWavesGenerationTasks: {
    list: createAPI(`/api/waves_of_meetings_generation_tasks`),
    detail: createAPI(`/api/waves_of_meetings_generation_tasks/:waveId`),
    status: createAPI(`/api/waves_of_meetings_generation_tasks/:waveId/status`)
  }
  // endregion
};

/*
****************
   FILEPOND
****************
*/

export const createFilePondConfig = ({ onProcessOnLoad } = {}) => ({
  url: `${API_PREFIX}/api`,
  process: {
    url: '/uploads',
    method: 'POST',
    onload: rawRes => {
      const response = camelizeKeys(JSON.parse(rawRes));

      if (onProcessOnLoad) {
        onProcessOnLoad(response.data);
      }

      return response.data.uploadToken;
    }
  },
  load: {
    url: '/uploads/download/',
    method: 'GET'
  },
  // TODO: restore API is not supported
  restore: null,
  fetch: {
    url: '/uploads/download/',
    method: 'GET'
  }
});

/*
***************************
   DATE FORMATS
***************************
*/

/* for 'date-fns' package */

export const DATE_FNS_FORMAT_DATE_ONLY: 'yyyy-MM-dd' = 'yyyy-MM-dd';

export const DATE_FNS_FORMAT_TIME_ONLY_SHORT: 'HH:mm' = 'HH:mm';

export const DATE_FNS_FORMAT_TIME_ONLY_FULL: 'HH:mm:ss' = 'HH:mm:ss';

export const DATE_FNS_FORMAT: 'yyyy-MM-dd HH:mm:ss' = `${DATE_FNS_FORMAT_DATE_ONLY} ${DATE_FNS_FORMAT_TIME_ONLY_FULL}`;

export const DATE_FNS_FORMAT_NO_SECONDS: 'yyyy-MM-dd HH:mm' = `${DATE_FNS_FORMAT_DATE_ONLY} ${DATE_FNS_FORMAT_TIME_ONLY_SHORT}`;

export const DAY_DURATION = 86400;

// this time formats are used inside tests for programmatic filters
export const SPECIAL_TIME_FORMAT_FORMAT = {
  ADVANCED: '(\\d+)\\s+(second|minute|hour|day|month|year)',
  REGULAR: '^(prev|current|next)\\s+(day|week|month|year)$'
};
