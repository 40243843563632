import revertDictionary from '../utils/revertDictionary';

const projectsDictionary = {
  'projects.buttons.forceEdit': {
    en: 'Edit without cloning',
    ru: 'Редактировать без клонирования'
  },
  'projects.forceEdit.message': {
    en: 'Submit editing without cloning!',
    ru: 'Подтвердите редактирование без клонирования!'
  },
  'projects.edit.message': {
    en: 'Submit to create draft for the project!',
    ru: 'Подтвердите создание черновика для проекта!'
  },
  'projects.restore.message': {
    en: 'Do you want to reactivate this project?',
    ru:
      'Для повторной активации проекта требуется подтверждение. Активировать проект?'
  },
  'projects.deactivate.message': {
    en: 'This project will be locked. Deactivate project?',
    ru: 'Данный проект будет заморожен. Остановить проект?'
  },
  'projects.clone.message': {
    en:
      'This project will be frozen. On basis of that project, new one will be created.',
    ru:
      'Текущий проект будет заморожен. На основе данного проекту будет создан новый.'
  },
  'projects.activate.message': {
    en: 'After activation project will be locked for further updates',
    ru: 'После активации проект нельзя будет редактировать'
  },
  'projects.filter.date': {
    en: 'Date',
    ru: 'Дата создания'
  },
  'projects.filter.status': {
    en: 'Status',
    ru: 'Статус'
  },
  'projects.filter.title.placeholder': {
    en: 'Search by title...',
    ru: 'Искать по названию...'
  },
  'projects.table.title': {
    en: 'Project',
    ru: 'Проект'
  },
  'projects.table.status': {
    en: 'Status',
    ru: 'Состояние'
  },
  'projects.table.answers': {
    en: 'Answers',
    ru: 'Ответы'
  },
  'projects.table.createdAt': {
    en: 'Created at',
    ru: 'Создано'
  },
  'projects.table.activity': {
    en: 'Activity',
    ru: 'Активность'
  },
  'projects.table.actions.open': {
    en: 'Open',
    ru: 'Открыть'
  },
  'projects.table.actions.actionModal': {
    en: 'Choose action',
    ru: 'Выберите действие'
  },
  'projects.createModal.title': {
    en: 'How to create project?',
    ru: 'Выберите способ создания проекта'
  },
  'projects.createModal.title.script': {
    en: 'Input script or attach file',
    ru: 'Введите скрипт или прикрепите файл'
  },
  'projects.createModal.script': {
    en: 'Script',
    ru: 'Скрипт'
  },
  'projects.createModal.scriptForm.script.placeholder': {
    en: 'Input script here...',
    ru: 'Введите скрипт создания проекта'
  },
  'projects.createModal.scriptForm.hint': {
    en: 'Script file has an advantage over script text',
    ru: 'Файл имеет приоритет относительно текста'
  },
  'projects.createModal.form': {
    en: 'Form',
    ru: 'Форма'
  }
};

export default revertDictionary(projectsDictionary);
