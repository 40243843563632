// @flow

import type { IDictionary } from 'flow-types/Dictionary';

import revertDictionary from '../../utils/revertDictionary';

// I used message keyed dictionary as soon as it is more comfortable to maintain
// For normalizing it to languageKeyedDictionary revertDictionary method is used below
const questionFormDictionary: IDictionary = revertDictionary({
  'questionForm.fields.showTextOptions': {
    en: 'Show text options',
    ru: 'Показать текстовые опции'
  },
  'questionForm.fields.enablePolygonOptions': {
    en: 'Enable polygon options',
    ru: 'Варианты ответа на картинке'
  },
  'questionForm.fields.instantNavigation.always': {
    en: 'Instant navigation on option selection',
    ru: 'Автопереход при выборе опции'
  },
  'questionForm.fields.instantNavigation.excludingOnly': {
    en: 'Instant navigation on excluding option selection',
    ru: 'Автопереход при выборе исключающей опции'
  },
  'questionForm.fields.type.label': {
    en: 'Type',
    ru: 'Тип'
  },
  'questionForm.fields.meetingField.label': {
    en: 'Meeting field',
    ru: 'Поле встречи'
  },
  'questionForm.fields.excludingOptions.label': {
    en: 'Excluding options',
    ru: 'Исключающие опции'
  },
  'questionForm.fields.defaultAnswer': {
    en: 'Default answer',
    ru: 'Ответ по умолчанию'
  },
  'questionForm.fields.viewType.label': {
    en: 'Appearance',
    ru: 'Оформление'
  },
  'questionForm.field.viewType.options.unsupported': {
    en: 'Unsupported view type for the block type',
    ru: 'Неподдерживаемый стиль отображения для выбранного типа блока'
  },
  'questionForm.fields.viewType.options.select': {
    en: 'Select',
    ru: 'Селектор'
  },
  'questionForm.fields.viewType.options.standard': {
    ru: 'Стандартный',
    en: 'Standard'
  },
  'questionForm.fields.viewType.options.list': {
    en: 'List',
    ru: 'Список'
  },
  'questionForm.fields.viewType.options.slider': {
    en: 'Slider',
    ru: 'Слайдер'
  },
  'questionForm.fields.title.label': {
    en: 'Question',
    ru: 'Текст вопроса'
  },
  'questionForm.fields.title.placeholder': {
    en: 'Enter question',
    ru: 'Введите текст вопроса'
  },
  'questionForm.fields.shortTitle.label': {
    en: 'Question (shortly)',
    ru: 'Краткий текст вопроса'
  },
  'questionForm.fields.shortTitle.placeholder': {
    en: 'Will be taken from title if not set',
    ru: 'Будет равен тексту вопроса, если не задан'
  },
  'questionForm.fields.code.label': {
    en: 'Code',
    ru: 'Код вопроса'
  },
  'questionForm.fields.useForSms.label': {
    en: 'Use for SMS message',
    ru: 'Использовать для СМС-информирования'
  },
  'questionForm.fields.useForEmail.label': {
    en: 'Use for Email message',
    ru: 'Использовать для Email-информирования'
  },
  'questionForm.fields.description.label': {
    en: 'Description',
    ru: 'Описание'
  },
  'questionForm.fields.description.placeholder': {
    en: 'Input your description here...',
    ru: 'Введите здесь описание этого вопроса...'
  },
  'questionForm.fields.hint.placeholder': {
    en: 'Input hint here...',
    ru: 'Введите здесь подсказку к вопросу'
  },
  'questionForm.fields.hint.label': {
    en: 'Hint',
    ru: 'Подсказка'
  },
  'questionForm.fields.otherTitle.label': {
    en: 'Title for "other" option',
    ru: 'Заголовок для опции другое'
  },
  'questionForm.fields.otherTitle.placeholder': {
    en: 'Other',
    ru: 'Другое'
  },
  'questionForm.fields.otherTitle.initialValue': {
    en: 'Enter your answer...',
    ru: 'Другой ответ, укажите'
  },
  'questionForm.fields.otherEnabled.label': {
    en: '"Other" option',
    ru: 'Опция "другое"'
  },
  'questionForm.fields.required.label': {
    en: 'Required',
    ru: 'Обязательный'
  },
  'questionForm.fields.maxAnswers.label': {
    en: 'Max answers',
    ru: 'Максиальное кол-во ответов'
  },
  'questionForm.fields.minAnswers.label': {
    en: 'Min answers',
    ru: 'Минимальное кол-во ответов'
  },
  'questionForm.fields.width.label': {
    en: 'Field width',
    ru: 'Ширина поля'
  },
  'questionForm.fields.height.label': {
    en: 'Field height',
    ru: 'Высота поля'
  },
  'questionForm.fields.recommendedTime.label': {
    en: 'Recommended time',
    ru: 'Рекомендованное время'
  },
  'questionForm.fields.commentsEnabled.label': {
    en: 'Comments',
    ru: 'Комментируемый'
  },
  'questionForm.fields.commentsEditable.label': {
    en: 'Allow to edit and remove comments',
    ru: 'Позволить обновлять и удалять комментарии'
  },
  'questionForm.fields.unloadable.label': {
    en: 'Unloadable',
    ru: 'Загружаемый'
  },
  'questionForm.fields.editableAfterCompletion.label': {
    en: 'Editable',
    ru: 'Редактируемый'
  },
  'questionForm.fields.inProfile.label': {
    en: 'Show in profile',
    ru: 'Показать в профиле'
  },
  'questionForm.fields.showTitle.label': {
    en: 'Show title',
    ru: 'Показать вопрос/заголовок'
  },
  'questionForm.fields.showImages.label': {
    en: 'Show images',
    ru: 'Показать изображения'
  },
  'questionForm.fields.filesEnabled.label': {
    en: 'File Attachments',
    ru: 'Прикрепление файлов'
  },
  'questionForm.fields.minFiles.label': {
    en: 'Min attachments',
    ru: 'Мин. кол-во прикреплений'
  },
  'questionForm.fields.maxFiles.label': {
    en: 'Max attachments',
    ru: 'Макс. кол-во прикреплений'
  },
  'questionForm.fields.withPoints.label': {
    en: 'Show points',
    ru: 'Показать баллы'
  },
  'questionForm.fields.showLabels.label': {
    en: 'Show option label',
    ru: 'Показать заголовки ответов'
  },
  'questionForm.fields.answer.placeholder': {
    en: 'Enter answer',
    ru: 'Введите ответ'
  },
  'questionForm.fields.audioAnswer.placeholder': {
    en: 'Say answer',
    ru: 'Говорите ответ'
  },
  'questionForm.fields.url.placeholder': {
    en: 'Enter URL',
    ru: 'Введите ссылку'
  },
  'questionForm.fields.availableVariables.label': {
    en: 'Available variables',
    ru: 'Доступные переменные'
  },
  'questionForm.fields.relocator.groupStart': {
    en: 'Start',
    ru: 'Начало'
  },
  'questionForm.fields.relocator.label': {
    en: 'Place after',
    ru: 'Разместить после'
  },
  'questionForm.fields.relocator.placeholder': {
    en: 'Select where to move question',
    ru: 'Выберите куда переместить вопрос'
  },
  'questionForm.fields.relocator.confirm_message': {
    en: 'Submit if you want to move question',
    ru: 'Подтвердите перенос вопроса'
  },
  'questionForm.fields.relocator.current_location': {
    en: 'Current location',
    ru: 'Текущее расположение'
  },
  'questionForm.fields.dateOnly.label': {
    en: 'Turn off time selection',
    ru: 'Отключить выбор времени'
  },
  'questionForm.fields.isInterval.label': {
    en: 'Interval',
    ru: 'Интервал'
  },
  'questionForm.fields.subQuestions.label': {
    en: 'Sub questions',
    ru: 'Подвопросы'
  },
  'questionForm.fields.options.label': {
    en: 'Answer options',
    ru: 'Ответы'
  },
  'questionForm.fields.otherOption.placeholder': {
    en: 'Enter your answer',
    ru: 'Введите ваш ответ'
  },
  'optionForm.fields.title.placeholder': {
    en: 'Enter option answer',
    ru: 'Введите вариант ответа'
  },
  'optionForm.fields.points.placeholder': {
    en: 'Points',
    ru: 'Баллы'
  },
  'optionForm.fields.code.placeholder': {
    en: 'Code',
    ru: 'Код'
  },
  'subQuestionForm.fields.title.placeholder': {
    en: 'Question title',
    ru: 'Заголовок вопроса'
  },
  'subQuestionForm.fields.points.placeholder': {
    en: 'Points',
    ru: 'Баллы'
  },
  'subQuestionForm.fields.code.placeholder': {
    en: 'Code',
    ru: 'Код'
  },
  'questionSettings.fields.type.label': {
    en: 'Sub-questions type',
    ru: 'Тип вложенных вопросов'
  },
  'questionSettings.fields.viewType.label': {
    en: 'Appearance',
    ru: 'Оформление'
  },
  'questionSettings.viewTypes.single': {
    en: 'Per page',
    ru: 'Один вопрос на страницу'
  },
  'questionSettings.viewTypes.table': {
    en: 'Table',
    ru: 'Таблица'
  },
  'questionSettings.viewTypes.list': {
    en: 'List',
    ru: 'Список'
  },
  'questionSettings.viewTypes.accordion': {
    en: 'Accordion',
    ru: 'Аккордион'
  },
  'questionSettings.validation.viewType.unsupportedSingleTableViewTypeInSinglePageGroup': {
    en: `
      Single page groups allows table appearance only. 
      Question will be rendered as table.
    `,
    ru: `Группы с включённым отображением всех вопросов на одной странице не поддерживают табличные вопрос с оформлением "один вопрос на страницу".`
  },

  'questionSettings.validation.dynamicOptionsSorting.supportsOnlyAll': {
    en: `
      Current block type supports only 'all' mode.
    `,
    ru: `Выбранный тип блока поддерживает только режим "все".`
  },
  'questionForm.fields.minValue.label': {
    en: 'Minimal value',
    ru: 'Минимальное значение'
  },
  'questionForm.fields.maxValue.label': {
    en: 'Maximal value',
    ru: 'Максимальное значение'
  },
  'questionForm.fields.scale.label': {
    en: 'Number of digits after the decimal point',
    ru: 'Количество знаков после запятой'
  },
  'questionForm.fields.mainImage.label': {
    en: 'Banner',
    ru: 'Баннер'
  },
  'questionForm.fields.mainVideo.label': {
    en: 'Video',
    ru: 'Видео'
  },
  'questionForm.visibilitySettings.label': {
    en: 'Show when',
    ru: 'Показывать при'
  },
  'questionForm.fields.isVisibleForMain.label': {
    en: 'record is created via web-form',
    ru: 'создании записи через веб-форму'
  },
  'questionForm.fields.isVisibleForPost.label': {
    en: 'going through post-polling',
    ru: 'прохождении пост-опроса'
  },
  'questionForm.fields.isVisibleForEdit.label': {
    en: 'editing record',
    ru: 'редактировании записи'
  },
  'questionForm.fields.isVisibleForPublic.label': {
    en: 'record is created via public link',
    ru: 'создании записи через публичную ссылку'
  },
  'questionForm.fields.isVisibleForMobile.label': {
    en: 'record is created via mobile app',
    ru: 'создании записи через мобильное приложение'
  },
  'questionForm.fields.dynamicOptionsSorting.label': {
    en: 'Dynamic options ordering',
    ru: 'Динамическая сортировка опций'
  },
  'questionForm.fields.dynamicOptionsSorting.all': {
    en: 'All',
    ru: 'Все'
  },
  'questionForm.fields.dynamicOptionsSorting.only_excluding': {
    en: 'Only excluding',
    ru: 'Только исключающие'
  },
  'questionForm.fields.dynamicOptionsSorting.except_excluding': {
    en: 'Except excluding',
    ru: 'Все, кроме исключающих'
  },

  'questionForm.fields.iconSet.label': {
    en: 'Icons collection',
    ru: 'Набор иконок'
  },

  // MESSAGES, PLACEHOLDERS, etc.
  'questionForm.messages.missedVideo': {
    en: 'Oops! It seems that someone missed a video here!',
    ru:
      'К сожалению, по данному вопросу информация о видео-фрагменте отсутствует.'
  },
  'questionForm.add_option': {
    en: 'Add option',
    ru: 'Добавить опцию'
  },
  'questionForm.no_options': {
    en: 'Question has no options',
    ru: 'Опции не заданы'
  },
  'questionForm.messages.missedData': {
    en:
      'Oops! It seems there is no data about block. Try to select another element.',
    ru:
      'Упс! Кажется, что нет данных по блоку. Попробуйте выбрать другой элемент.'
  },

  // region Question Media Content Rating Related Fields
  'questionForm.fields.pollingEnabled.label': {
    en: 'Enable user feedback',
    ru: 'Разрешить пользователям оценивать видео'
  },
  'questionForm.fields.pollingMode.label': {
    en: 'How users can rate the video?',
    ru: 'Как пользователи могут оценивать видео?'
  },
  'questionForm.fields.pollingMode.options.free': {
    en: 'Manually, at any time',
    ru: 'Вручную, в свободном режиме'
  },
  'questionForm.fields.pollingMode.options.interval': {
    en: 'Periodically, with an interval',
    ru: 'Периодично, согласно интервалу'
  },
  'questionForm.fields.pollingMode.options.mixed': {
    en: 'Mixed, periodically with manual support',
    ru: 'Смешано, периодичное и свободное проставление оценок'
  },
  'questionForm.fields.pollingInterval.label': {
    en: 'What is an interval between each feedback?',
    ru: 'Каков интервал между оценками?'
  },
  'questionForm.fields.pollingInterval.hours.hint': {
    en: 'hours',
    ru: 'часы'
  },
  'questionForm.fields.pollingInterval.minutes.hint': {
    en: 'minutes',
    ru: 'минуты'
  },
  'questionForm.fields.pollingInterval.seconds.hint': {
    en: 'seconds',
    ru: 'секунды'
  },
  // endregion

  // VALIDATION MESSAGES
  'questionForm.validation.fields.questionGroupId.isRequired': {
    ru: 'Необходимо указать группу вопроса',
    en: 'Question group should be set'
  },
  'questionForm.validation.fields.type.isRequired': {
    ru: 'Необходимо указать тип вопроса',
    en: 'Question type should be set'
  },
  'questionForm.validation.fields.title.isRequired': {
    ru: 'Необходимо указать заголовок вопроса',
    en: 'Question title should be set'
  },
  'questionForm.validation.fields.shortTitle.isRequired': {
    ru: 'Необходимо указать краткий заголовок вопроса',
    en: 'Question short title should be set'
  },
  'questionForm.validation.fields.description.isRequired': {
    ru: 'Необходимо указать описание вопроса',
    en: 'Question description should be set'
  },
  'questionForm.validation.fields.contents.isRequired': {
    ru: 'Необходимо указать содержимое блока',
    en: 'Question contents should be set'
  },
  'questionForm.validation.fields.minValue.shouldBeLessThanMax': {
    ru: 'Минимальное число не может превышать максимальное',
    en: 'Min value cannot exceed maximum'
  },
  'questionForm.validation.fields.maxValue.isRequiredWhenSlider': {
    ru:
      'Максимальное допустимое число является обязательным полем для режима "Слайдер"',
    en: 'Max value is required, when appearance is set to "Slider"'
  },
  'questionForm.validation.fields.minValue.isRequiredWhenSlider': {
    ru:
      'Минимально допустимое число является обязательным полем для режима "Слайдер"',
    en: 'Min value is required, when appearance is set to "Slider"'
  },
  'questionForm.validation.fields.maxValue.shouldBeMoreThanMin': {
    ru: 'Максимальное число не может быть ниже минимума',
    en: 'Max value cannot be lower than minimum'
  },
  'questionForm.validation.fields.minFiles.shouldBeLessThanMax': {
    ru: 'Минимальное кол-во файлов не может превышать максимальное',
    en: 'Min files count cannot exceed maximum'
  },
  'questionForm.validation.fields.maxFiles.shouldBeMoreThanMin': {
    ru: 'Максимальное кол-во файлов не может быть ниже минимума',
    en: 'Max files count cannot be lower than minimum'
  },
  'questionForm.validation.fields.order.isRequired': {
    ru: 'Необходимо указать порядковое число вопроса',
    en: 'You have to set question order'
  },
  'questionForm.validation.fields.options.isRequired': {
    ru: 'Необходимо указать опции выбора',
    en: 'Options is a required field'
  },
  'questionForm.validation.fields.optionTitle.isRequired': {
    ru: 'Необходимо указать заголовок опции',
    en: 'You have to set title for the option'
  },
  'questionForm.validation.fields.optionOrder.isRequired': {
    ru: 'Необходимо указать порядковое число опции',
    en: 'You have to set order for the option'
  },
  'questionForm.validation.fields.optionPoints.isRequired': {
    ru: 'Необходимо указать баллы опции',
    en: 'You have to set points for the option'
  },
  'questionForm.validation.fields.optionImage.isRequired': {
    ru: 'Необходимо указать изображение опции',
    en: 'You have to assign image for an option'
  },
  'questionForm.validation.fields.subQuestions.isRequired': {
    ru: 'Необходимо указать подвопросы',
    en: 'Subquestion is a required field'
  },
  'questionForm.validation.fields.subQuestionTitle.isRequired': {
    ru: 'Необходимо указать заголовок подвопроса',
    en: 'Subquestion title should be set'
  },
  'questionForm.validation.fields.subQuestionOrder.isRequired': {
    ru: 'Необходимо указать порядковое число подвопроса',
    en: 'Subquestion order should be set'
  },
  'questionForm.validation.fields.settings.isRequired': {
    ru: 'Необходимо указать настройки вопроса',
    en: 'Question settings should be set'
  },
  'questionForm.validation.fields.settingType.isRequired': {
    ru: 'Необходимо указать тип подвопросов',
    en: 'Subquestions type should be set'
  },
  'questionForm.validation.fields.settingViewType.isRequired': {
    ru: 'Необходимо указать внешний стиль вывода подвопросов',
    en: 'Subquestions view type should be set'
  },
  'questionForm.validation.fields.settingLabels.value.isRequired': {
    en: 'Value is a required field',
    ru: 'Необходимо задать значение'
  },
  'questionForm.validation.fields.settingLabels.title.isRequired': {
    en: 'Title is a required field',
    ru: 'Необходимо задать заголовок'
  },
  'questionForm.validation.fields.editable.isRequired': {
    ru: 'Необходимо указать редактируемость вопроса',
    en: 'It should be set, whether question is editable'
  },
  'questionForm.validation.fields.unloaded.isRequired': {
    ru: 'Необходимо указать можно ли выгружать вопрос',
    en: 'It should be set, whether question can be exported'
  },
  'questionForm.validation.fields.showTitle.isRequired': {
    ru: 'Необходимо указать нужно ли показывать заголовок вопроса',
    en: 'It should be set, whether to show question title'
  },
  'questionForm.validation.fields.showLabels.isRequired': {
    ru: 'Необходимо указать нужно ли показывать заголовки опций выбора',
    en: 'It should be set, whether to show option labels'
  },
  'questionForm.validation.fields.showImages.isRequired': {
    ru: 'Необходимо указать нужно ли показывать изображения',
    en: 'It should be set, whether to show images'
  },
  'questionForm.validation.fields.inProfile.isRequired': {
    ru: 'Необходимо указать нужно ли показывать ответ в профиле',
    en: 'It should be set, whether to show answer in profile'
  },
  'questionForm.validation.fields.videoToken.isRequired': {
    ru: 'Необходимо указать видео-фрагмент если не указана ссылка.',
    en: 'Either video file, or url should be set.'
  },
  'questionForm.validation.fields.videoSrc.isRequired': {
    ru:
      'Необходимо указать ссылку на видео-фрагмент, если видео-фрагмент не указан.',
    en: 'Either video file, or url should be set.'
  },

  /**
   *  ACTIONS
   */
  'questionForm.actions.addLabelSettings': {
    en: 'Add label settings',
    ru: 'Добавить настройку'
  }
});

export default questionFormDictionary;
