import revertDictionary from 'utils/revertDictionary';

const ajaxErrorsDictionary = revertDictionary({
  'ajax.error.400': {
    en: 'Server error!',
    ru: 'Ошибка на сервере!'
  },
  'ajax.error.401': {
    ru: 'Нет доступа!',
    en: 'No access'
  },
  'ajax.error.403': {
    ru: 'Нет доступа!',
    en: 'No access'
  },
  'ajax.error.404': {
    ru: 'Искомый ресурс не найден',
    en: 'Not found!'
  },
  'ajax.error.405': {
    en: 'Server error!',
    ru: 'Ошибка на сервере!'
  },
  'ajax.error.408': {
    en: 'Timeout!',
    ru: 'Превышено время ожидания от сервиса!'
  },
  'ajax.error.422': {
    en: 'Sent data is not accepted!',
    ru: 'Ошибка в переданных данных!'
  },
  'ajax.error.429': {
    en: 'Too many operations with a service!',
    ru: 'Слишком много запросов к сервису!'
  },
  'ajax.error.500': {
    en: 'Server error!',
    ru: 'При обработке запроса на сервере возникла непредвиденная ошибка!'
  },
  'ajax.error.500+': {
    en: 'Server error!',
    ru: 'При обработке запроса на сервере возникла непредвиденная ошибка!'
  },
  'ajax.error.NA': {
    en: 'Unknown error!',
    ru: 'Неопознанная ошибка!'
  }
});

export default ajaxErrorsDictionary;
