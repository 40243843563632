import flatMapDeep from 'lodash/flatMapDeep';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import keys from 'lodash/keys';

import type { AppState } from 'flow-types/AppState';
import type { IInterviewProject } from 'flow-types/entities/Project';
import type { Move } from 'flow-types/actions/interview/Move';

import { MODAL_ID as BLOCK_PROBLEMS_MODAL_ID } from '../../../../pages/Interview/components/Dialogs/BlocksProblemsDialog';
import { MODAL_ID as BLOCK_IS_REQUIRED_MODAL_ID } from '../../../../pages/Interview/components/Dialogs/BlockIsRequiredDialog';

import { interviewProjectDataSelector } from '../../../../selectors/interview/root';
import { interviewActiveStackDataSelector } from '../../../../selectors/interview/answers';
import { createInterviewAppearanceSelector } from '../../../../selectors/interview/statuses';

import setAnswersValidation from '../../../../actions/interview/setAnswersValidation';
import openModal from '../../../../actions/modals/open';

import { validateAnswers } from './validateAnswers';

export default function checkRunner(action: Move, state: AppState) {
  // prevent validation checks
  if (action.force) return null;

  const appearance = createInterviewAppearanceSelector()(state);

  const activeStack = interviewActiveStackDataSelector(state);

  if (!activeStack) return null;

  const { localId: activeStackId } = activeStack;

  const {
    settings,
    mappedQuestions
  }: IInterviewProject = interviewProjectDataSelector(state);

  const appearanceSettings = settings.appearance[appearance];

  // let's validation current answers
  const checkResult = validateAnswers(action, state);

  // if no errors, then return null
  if (!checkResult) return null;

  let eventsList = [
    { type: 'interview/move-fail' },
    setAnswersValidation(checkResult)
  ];

  let dialogAction = null;

  // it is also a scroll target
  const issuedQuestions = sortBy(
    keys(checkResult),
    questionId => mappedQuestions[questionId]
  ).map(i => +i);

  const firstIssueQuestionIdInStack = issuedQuestions.find(
    id =>
      activeStack.questionId === id || activeStack.questionsIds?.includes(id)
  );

  if (!action.complete) {
    const stackErrors = flatMapDeep(values(checkResult));

    const hasRequiredMessageId =
      findIndex(stackErrors, error =>
        [
          'question.validation.attachments.min',
          'question.validation.required'
        ].includes(error.messageId)
      ) !== -1;

    // TODO: make pre-complete answers checking with logic jumps accounting
    if (
      hasRequiredMessageId &&
      !!appearanceSettings?.showRequiredBlockOverlay &&
      appearance !== 'post' &&
      appearance !== 'public'
    ) {
      dialogAction = openModal(BLOCK_IS_REQUIRED_MODAL_ID, {
        firstIssueQuestionId: firstIssueQuestionIdInStack,
        activeStackId,
        checkResult,
        originalAction: action
      });
    }
  } else {
    // TODO: make pre-complete answers checking with logic jumps accounting
    // TODO: if instant completion would be possible in post polling and public
    //  then it should be removed in this case because user could not answer on required questions,
    //  that is located afterwards the point,
    //  where user is currently stays.
    //  Otherwise, it will be required to make a forced move to the first stack with
    //  an issued block from issuedQuestions

    if (appearance === 'post' || appearance === 'public') return null;

    dialogAction = openModal(BLOCK_PROBLEMS_MODAL_ID, {
      firstIssueQuestionId: firstIssueQuestionIdInStack,
      activeStackId,
      checkResult,
      originalAction: action
    });
  }

  if (!dialogAction) {
    /*
     If there is no dialogAction,
     then set scroll targets and use forced move
     to avoid another check.
     In theory, in web dialogs would be shown and in public and post polling
     required blocks and blocks with problems are not skippable.
    */
    eventsList = activeStack.isSinglePage
      ? [
          ...eventsList,
          {
            type: 'interview-ui/set-scroll-target',
            targetId: +firstIssueQuestionIdInStack
            // firstIssueStackId,
            // firstIssueQuestionIdInStack: +firstIssueQuestionIdInStack,
            // activeStackId
          }
        ]
      : eventsList;
  } else {
    eventsList.push(dialogAction);
  }

  return eventsList;
}
