// @flow

import * as React from 'react';
import map from 'lodash/map';

import type { IMetaItem, IMetadata } from 'common/components/Comment/flow';

type Props = {|
  data: IMetadata
|};

export default function Metadata({ data }: Props): React.Node {
  return (
    <div className="metadata">
      {map(data, (meta: IMetaItem, metaClass) => {
        const { as: Component, value } = meta;

        if (!Component) {
          return (
            <div className={metaClass} key={metaClass}>
              {value}
            </div>
          );
        }

        return (
          <Component key={metaClass} className={metaClass} value={value} />
        );
      })}
    </div>
  );
}
