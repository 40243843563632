import revertDictionary from 'utils/revertDictionary';

const dictionary = {
  'responseAssignExpertModal.title': {
    ru: `Выбор эксперта для прикрепления к записи {responseId}`,
    en: `Specialist selection to attach to record {responseId}`
  },
  'responseAssignExpertModal.expertsTable.columns.name': {
    ru: 'ФИО',
    en: 'Full name'
  },
  'responseAssignExpertModal.actions.detachCurrentExpert': {
    ru: 'Открепить текущего эксперта',
    en: 'Detach current specialist'
  }
};

export default revertDictionary(dictionary);
