import { Epic, ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';

import type { CreateRecordRegion } from '../../flow-types/actions/records/CreateRecordRegion';
import type { AppState } from '../../flow-types/AppState';
import { recordsRegionsStateSelector } from '../../selectors/records';
import isNewRegion from '../../pages/Project/helpers/isNewRegion';

import { MODAL_ID } from '../../pages/Project/components/Modals/RegionHasUnsavedChangesModal';

const createRegion: Epic = (action$, state$) =>
  action$.pipe(
    ofType('records-regions/create'),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([action, state]: [CreateRecordRegion, AppState]) => {
      const { regionId, region } = action;

      const { selected: selectedRegionId, data } = recordsRegionsStateSelector(
        state
      );

      if (!selectedRegionId) {
        return of({
          type: 'records-regions/set-selected',
          regionId,
          recordId: region.recordId
        });
      }

      let selectedRegion = null;

      if (selectedRegionId) {
        selectedRegion = find(data, { id: selectedRegionId });
      }

      if (!selectedRegion) {
        return of({
          type: 'records-regions/set-selected',
          regionId,
          recordId: region.recordId
        });
      }

      const isNew = isNewRegion(selectedRegion);

      if (isNew) {
        return of({
          type: 'modals/open',
          modalId: MODAL_ID,
          data: {
            sourceRegionId: selectedRegionId,
            targetRegionId: regionId
          }
        });
      }

      const { cache, ...rest } = selectedRegion;

      // no cache is an evidence for 'non changed' state
      const isChanged = cache ? !isEqual(cache, rest) : false;

      if (isChanged) {
        return of({
          type: 'modals/open',
          modalId: MODAL_ID,
          data: {
            sourceRegionId: selectedRegionId,
            targetRegionId: regionId
          }
        });
      }

      return of({
        type: 'records-regions/set-selected',
        regionId,
        recordId: region.recordId
      });
    })
  );

export default createRegion;
