import { TYPES } from 'common/helpers/question';

const shouldMoveForward = ({
  type,
  viewType,
  subType,
  isExcludingOptionSelected,
  instantNagivation
}): boolean => {
  if (!instantNagivation) {
    return false;
  }

  if (type === TYPES.Rating) {
    // Instant Navigation feature is available only
    // for 'list' view type, that is visible by default (i.e. when viewType is null)
    return viewType !== 'slider';
  }

  if (type === TYPES.Table) {
    return shouldMoveForward({
      type: subType,
      viewType: null,
      instantNagivation,
      isExcludingOptionSelected
    });
  }

  return (
    type === TYPES.SingleAnswer ||
    (type === TYPES.MultipleAnswer && isExcludingOptionSelected)
  );
};

export default shouldMoveForward;
