import * as React from 'react';
import { useIntl } from 'react-intl';
import { merge } from 'merge-anything';

import type { IInterviewQuestion } from 'flow-types/entities/Question';

import useIsMobile from 'common/hooks/useIsMobile';
import Input, { InputWrapper } from '../Input';
import Icon from '../Icon';
import Label from '../Label';

import { useAnswer } from '../../contexts/AnswersContext';
import { useAnswerUpdate } from '../../contexts/AnswerUpdateContext';

import getValueFromDOMEvent from '../../helpers/getValueFromDOMEvent';
import punctuationReplacer from './helpers/punctuationReplaceer';

import useRecognition from './hooks/useRecognition';

type Props = {|
  question?: IInterviewQuestion
|};

const onMouseDown = e => {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

const TextQuestionBody = ({ question, ...rest }: Props) => {
  const { current: isMobile } = useIsMobile();

  const intl = useIntl();

  const [tempValue, setTempValue] = React.useState('');

  const temp = React.useRef({
    startIndex: null,
    endIndex: null
  });

  const inputRef = React.useRef(null);

  const answer = useAnswer(question.id, { data: '' });

  const onChange = useAnswerUpdate(question.id);

  const handleRecognition = React.useCallback(recognitionResult => {
    const result = punctuationReplacer(recognitionResult);

    setTempValue(result);
  }, []);

  const { supported, stop, listening, listen } = useRecognition({
    questionId: question.id,
    onResult: handleRecognition
  });

  const handleChange = React.useCallback(
    event => {
      if (!onChange) return;

      const nextValue = getValueFromDOMEvent(event);

      onChange(merge({ ...answer }, { data: nextValue, errors: null }));
    },
    [answer, onChange]
  );

  const { disabled } = question;

  const { data } = answer;

  const onClick = () => {
    if (listening) {
      let result = tempValue;

      if (temp.current.startIndex !== null && answer.data?.length > 0) {
        result =
          answer.data.slice(0, temp.current.startIndex) +
          result +
          answer.data.slice(temp.current.startIndex);

        temp.current.startIndex = null;
      }

      onChange(
        merge(
          { ...answer },
          {
            data: result,
            errors: null
          }
        )
      );

      setTempValue('');
      stop();
    } else {
      // get selection start
      const { selectionStart } = inputRef.current;

      // if input is in focus,
      // then check cursor position inside that element
      if (document.activeElement === inputRef.current) {
        temp.current.startIndex = selectionStart;
      }
      // save it to temporal variable
      listen({ continuous: true });
    }
  };

  let value = data;

  if (listening) {
    value = tempValue;
  }

  const placeholder = intl.formatMessage({
    id: `questionForm.fields.${
      listening ? 'audioAnswer' : 'answer'
    }.placeholder`
  });

  return (
    <InputWrapper
      labeled={supported && !question.disabled}
      corner={supported && !question.disabled ? 'left' : null}
      fluid
    >
      {!isMobile && supported && !question.disabled && (
        <Label
          corner="left"
          onMouseDown={onMouseDown}
          icon
          color={listening ? 'red' : 'blue'}
          onClick={onClick}
        >
          <Icon icon={listening ? 'stop' : 'microphone'} />
        </Label>
      )}
      <Input
        {...rest}
        ref={inputRef}
        tagName="textarea"
        disabled={disabled || listening}
        type="textarea"
        data-swipe-ignore="true"
        className="h-sm"
        value={value}
        placeholder={placeholder}
        onChange={listening ? null : handleChange}
      />
    </InputWrapper>
  );
};

TextQuestionBody.defaultProps = {
  question: {
    id: null
  }
};

export default TextQuestionBody;
