// @flow

import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import isNaN from 'lodash/isNaN';
import reduceRight from 'lodash/reduceRight';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import type { IComputedProperty } from 'flow-types/entities/ComputedProperty';
import type {
  IExpression,
  IExpression$Element,
  IExpression$Operator
} from 'flow-types/entities/Expression';
import type { $ObjOfType } from 'flow-types/ObjOfType';
import type { ComputedPropertiesProcessorThrowable } from './utils';
import {
  IncorrectDataTypeIsUsedError,
  IncorrectExpressionOperatorUsage,
  UndefinedFormulaStructureError,
  UndefinedPropertyUsageError,
  UnknownOperatorError,
  UnknownTypeError,
  UnresolvableExpressionElement,
  VariableCycleDependencyError
} from './utils';

export type ComputedPropertyValue = string | number | null;

type ComputedPropertySuccessState = {|
  status: 'success',
  value: ComputedPropertyValue
|};

type ComputedPropertyErrorState = {|
  status: 'error',
  value: null,
  errors: ComputedPropertiesProcessorThrowable[]
|};

type ComputedPropertyState =
  | ComputedPropertySuccessState
  | ComputedPropertyErrorState;

type ComputedPropertiesProcessorState = {
  [variableId: string | number]: ComputedPropertyState
};

class ComputedPropertiesProcessor {
  /**
   * This property is used to store an information
   * about computed properties;
   */
  properties: $ObjOfType<IComputedProperty> = {};

  /**
   * This property is used to store variables values.
   */
  state: ComputedPropertiesProcessorState = {};

  /**
   * Здесь прячутся статические значения,
   * которые не поменяются в зависимости от результатов расчёта.
   * К примеру ими могут являться переменные задаваемые блоками.
   */
  statics: $ObjOfType<ComputedPropertyValue> = {};

  constructor(
    {
      properties = [],
      staticValues = {}
    }: {
      properties?:
        | Array<IComputedProperty>
        | $ObjOfType<IComputedProperty>
        | null,
      staticValues?: $ObjOfType<ComputedPropertyValue> | null
    } = {
      properties: null,
      staticValues: null
    }
  ) {
    if (properties) {
      this.setProperties(properties);
    }

    if (staticValues) {
      this.setStatics(staticValues);
    }
  }

  setStatics(statics: $ObjOfType<ComputedPropertyValue>) {
    this.statics = statics;
  }

  setProperties(properties: Array<Object> | $ObjOfType<IComputedProperty>) {
    if (Array.isArray(properties)) {
      this.properties = (keyBy(properties || [], 'code'): {
        [variableId: string | number]: IComputedProperty
      });
    } else if (properties !== null && typeof properties === 'object') {
      this.properties = properties;
    }
  }

  /**
   * Returns information about property.
   * In case undefined property is used null is returned.
   */
  getPropertyInfo(variableId: string | number): IComputedProperty | null {
    return this.properties[variableId] || null;
  }

  /**
   * Saves value inside state
   */
  savePropertyState(variableId: string | number, value: ComputedPropertyState) {
    this.state[variableId] = value;
  }

  /**
   * Returns variable value
   */
  getVariableValue(variableId: string | number): ComputedPropertyValue {
    if (this.statics[variableId]) return this.statics[variableId];

    return this.state?.[variableId]?.value || null;
  }

  /**
   * Итак. В рамках этого метода проверяется валидность самого выражения.
   * То бишь, можем ли мы провести операцию, определённую оператором, с двумя операторами.
   * Можем ли мы далее провести операцию с учётом того, чем является левый и правый.
   *
   * Собственно что здесь происходит.
   * Мы не можем провести некоторые операции,
   * если значения в левом и правом элементе этого не позволяют.
   *
   *
   * ##
   * ## Numbers & Strings
   * Единственная операция, которая допустима между
   * любым числом и любой строкой это "+".
   * Остальные операции имеют некоторые особенности.
   * Когда мы пишем 2 + "2", то здесь мы точно хотим объединить строки.
   * Когда мы пишем 2 * "2", то умножать строки в JS нельзя,
   * но умножать числа можно,
   * а так как второй элемент это число,
   * то вполне возможно провести операцию умножения, что не скажешь о "2" * "abc".
   * Операции деления, вычитания и все-все-все остальные работают именно по такому же принципу.
   *
   * Исходя из этого можно сделать вывод, что
   * существует два возможных действия при осуществлении операций между строкой и числом:
   * 1. соединение
   * 2. все остальные операции
   *
   * В случае операции сложения (+) - всегда отработает (1) вариант,
   * в то время как все остальные операции отработают только тогда,
   * когда оба элемента выражения можно привести к числу
   * (да, все они используют только 2-ой вариант и не могут соединить строки).
   * В ином случае результатом их работы будет NaN.
   *
   * ##
   * ### Dates
   * Между датами допустимы только операции сложения и вычитания. Можно конечно сказать:
   * - Переводим в число, умножаем, получаем новую дату.
   * На что я скажу следующее:
   * - Да, это возможно, но юзер может не понять как получился результат и может вообще ожидать иной от полученного.
   *
   * В плане дат нужно чётко определить когда операции можно проводить,
   * как их нужно проводить и что делать тогда, когда данные можно
   * понять двояко или же вызывать ошибку AmbiguousExpressionInputError.
   *
   * Понять выражение 2020-02-12 13:00 + 32 можно очень по разному: 32 это кол-во
   * лет/месяцев/недель/дней/часов/минут/секунд?
   * Это не всё. Что делать если вместо сложения будет
   * умножение или любая другая операция отличных от сложения.
   *
   * Да, кстати, даты на первых порах возможно не выйдут...
   * TODO: добавить поддержку дат (со временем)
   *
   */
  // validateExpressionOrThrowError({
  //   left,
  //   leftValue,
  //   operator,
  //   right,
  //   rightValue
  // }: {
  //   ...IExpression,
  //   leftValue: ComputedPropertyValue,
  //   rightValue: ComputedPropertyValue
  // }): boolean {
  // if right element is not present, left element is used as an expression result
  // if (
  //   right === null ||
  //   rightValue === null ||
  //   left === null ||
  //   leftValue === null
  // ) {
  //   return true;
  // }

  // numbers and numbers as strings cases, with numbers you can do any operation
  // also we check here, cases when one of elements can be number as string
  // if both elements can be turned to numbers, its okay and
  // there is no difference what operator is used
  //
  // C С другой стороны, оператор "+" позволяет провести операции со строками и другими типами,
  // что нельзя сказать обо всех остальных.
  // if ((!isNaN(leftValue) && !isNaN(rightValue)) || operator === '+') {
  //   return true;
  // }

  // После проверок на NaN сюда попадут только те случаи,
  // когда один элемент или оба элемента содержат буквы в значении,
  // а так же оператором не является "+".
  //   return false;
  // }

  /**
   * Checks whether variable is processed.
   * Take into account, that it firstly checks
   * {@link statics} and then {@link state}.
   */
  isVariableProcessed(variableId: string): boolean {
    // check for static value
    if (typeof this.statics[variableId] !== 'undefined') return true;

    return ['error', 'success'].includes(this.state?.[variableId]?.status);
  }

  /**
   * Runs process of every property in properties list.
   * Be careful! It can thrown an error, thus it is more safer to
   * put its call into try-catch block.
   */
  process() {
    // clears state
    // TODO: optimize recalculation by passing either initial state or something like that.
    //  Actually, anything that can help us to tell what should be actually recalculated.
    this.state = {};

    forEach(this.properties, (_, key) => {
      if (this.isVariableProcessed(key)) {
        return;
      }
      try {
        this.resolveVariableById(key, [key]);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    });
  }

  getCompactState(): $ObjOfType<ComputedPropertyValue> {
    return reduceRight(
      this.state,
      (result, propertyState: ComputedPropertyState, propertyCode: string) => ({
        ...result,
        [propertyCode]: propertyState.value
      }),
      {}
    );
  }

  /**
   * This method is used to process variables value using its
   * id to get required for processing data.
   * As soon as information about variables and their formula
   * is stored inside {@link ComputedPropertiesProcessor.properties} we can easily
   * get info on any level of recursion.
   *
   * @throws {UndefinedPropertyUsageError} is thrown when undefined property is used.
   * @throws {UndefinedFormulaStructureError} is thrown when there is no formulaAst inside {@link IComputedProperty}.
   * @throws {VariableCycleDependencyError} is thrown when cycle dependency has been found.
   */
  resolveVariableById(variableId: string, processChain: string[] = []) {
    const cycleDepGuard = filter(
      processChain,
      chainEl => chainEl === variableId
    );
    /*
     Если переменная упоминается дважды в цепочке вычисления,
     тогда считаем, что образовалась циклическая
     зависимость переменной от самой себя.
    */
    if (cycleDepGuard.length > 1) {
      /*
       Если переменная уже есть в массиве
       эта ошибка всплывёт к ней.
      */
      throw new VariableCycleDependencyError(processChain, variableId);
    }

    const property = this.getPropertyInfo(variableId);
    /*
     если используется не заданная переменная,
     то сохраняем соответствующую ошибку
    */
    if (typeof property === 'undefined' || property === null) {
      /*
        Переменная, которая не существует может вызвать ошибку
        только в том случае,
        когда её расчёт вызван в рамках расчёта другой переменной.
        Соответственно, нет необходимости говорить о том,
        что "переменную А" не удалось рассчитать, так как её нет.
        Лучше сообщить, что "переменную Б" не удалось рассчитать, так
        как не существует "переменной А". В этом больше смысла.
       */
      throw new UndefinedPropertyUsageError(processChain, variableId);
    }

    if (
      typeof property.formulaAst === 'undefined' ||
      property.formulaAst === null
    ) {
      const undefinedFormulaStructureError = new UndefinedFormulaStructureError(
        /*
         Переменная существует, однако именно у неё нет формулы,
         которая позволяет сделать расчёты.
         То есть здесь уже не важен полный контекст вызова.
         Однако, итак будет понятно какая переменная вызвала ошибку,
         так как она будет последней в массиве.
         А в случае определения ошибок на разных
         уровнях потребуется весь массив вызова расчёта.
        */
        processChain,
        variableId
      );

      this.savePropertyState(variableId, {
        status: 'error',
        value: null,
        errors: [undefinedFormulaStructureError]
      });

      throw undefinedFormulaStructureError;
    }

    // RECURSION STARTS FROM
    try {
      const value = this.resolveElement(property.formulaAst, processChain);

      this.savePropertyState(variableId, {
        status: 'success',
        value
      });
    } catch (error) {
      this.savePropertyState(variableId, {
        status: 'error',
        errors: [error],
        value: null
      });

      throw error;
    }
  }

  getLastProcessContextAsProperty(
    processChain: string[] = []
  ): IComputedProperty {
    const allProperties: string[] = keys(this.properties);

    const lastProcessContext = reduceRight(
      processChain,
      (result, processChainElement) => {
        if (
          result ||
          processChainElement === 'right' ||
          processChainElement === 'left'
        ) {
          return result;
        }

        if (allProperties.includes(processChainElement)) {
          return processChainElement;
        }

        return result;
      },
      null
    );

    return this.properties[lastProcessContext];
  }

  /**
   * This method is used to process value of {@link IExpression}.
   * Note, that expression can be an element of another expression,
   * that enforces this method to return value.
   * Maybe, there are other ways to avoid value return and so on,
   * but I decided to do it the way it is.
   *
   * @throws {UnresolvableExpressionElement}
   * @throws {IncorrectExpressionOperatorUsage}
   * @throws {IncorrectDataTypeIsUsedError}
   */
  resolveExpression(
    expression: IExpression,
    processChain: string[] = []
  ): ComputedPropertyValue {
    let left =
      expression.left === null
        ? null
        : this.resolveElement(expression.left, [...processChain, 'left']);

    /**
     * Мне кажется, что такие элемент в принципе не пройдут проверку на сохранение
     * ещё при создании переменной через админку.
     * Однако, нет гарантий, что такой переменной в принципе быть не может.
     */
    if (left === null) {
      throw new UnresolvableExpressionElement(`left is missing`, [
        ...processChain,
        'left'
      ]);
    }

    // if right is not set, return left value
    // this is a case for one-element-expressions for formulas like the following:
    // formula: 2
    let right =
      expression.right === null
        ? null
        : this.resolveElement(expression.right, [...processChain, 'right']);

    // right is not present, thus we're assuming this formula
    // to have only one element
    if (expression.right === null) {
      return left;
    }

    // right is set, but we cannot calculate
    // its value, thus we cannot calculate expression
    // value correctly
    if (right === null) {
      return null;
    }

    const context = this.getLastProcessContextAsProperty(processChain);

    const nonNumberRestrictedOperations: IExpression$Operator[] = [
      '*',
      '-',
      '/'
    ];

    const isStringDataType = ['varchar', 'text'].includes(context.varType);

    if (isStringDataType) {
      if (nonNumberRestrictedOperations.includes(expression.operator)) {
        throw new IncorrectExpressionOperatorUsage(
          processChain,
          context.varType,
          ['+'],
          expression.operator
        );
      }

      // this is only one operation that can be done with strings
      return `${left}${right}`;
    }

    left = +left;
    right = +right;
    right = +right;

    if (isNaN(left)) {
      throw new IncorrectDataTypeIsUsedError(
        [...processChain, 'left'],
        context.varType,
        // TODO: for now only numbers can reach that point,
        //  and only opposite type is 'string' here
        'string'
      );
    }

    if (isNaN(right)) {
      throw new IncorrectDataTypeIsUsedError(
        [...processChain, 'right'],
        context.varType,
        // TODO: for now only numbers can reach that point,
        //  and only opposite type is 'string' here
        'string'
      );
    }

    switch (expression.operator) {
      case '+':
        return left + right;
      case '*':
        // case is checked above
        // $FlowIgnore
        return left * right;
      case '-':
        // case is checked above
        // $FlowIgnore
        return left - right;
      case '/':
        // case is checked above
        // $FlowIgnore
        return left / right;
      default:
        throw new UnknownOperatorError(processChain, expression.operator);
    }
  }

  // /**
  //  * Resolves value of an elements of an expression.
  //  * @throws {UnknownTypeError} - is thrown when unsupported or unknown type is used.
  //  */
  resolveElement(
    element: IExpression$Element,
    processChain: string[] = []
  ): ComputedPropertyValue {
    switch (element.type) {
      case 'EXPR':
        return this.resolveExpression(element, [...processChain]);
      case 'VAR':
        // if variable was processed earlier,
        // it is returned immediately
        if (!this.isVariableProcessed(element.value)) {
          this.resolveVariableById(element.value, [
            ...processChain,
            element.value
          ]);
        }

        return this.getVariableValue(element.value);
      case 'CONST':
        return element.value;
      default:
        throw new UnknownTypeError(processChain, element.type);
    }
  }
}

export default ComputedPropertiesProcessor;
