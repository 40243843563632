// @flow
import { createSelector } from 'reselect';
import type {
  ProjectDetailState,
  ResponseStatsState
} from 'flow-types/states/ProjectsState/detail';

import type { LocationState } from 'redux-first-router/dist/flow-types';

import { locationStateSelector, projectDetailStateSelector } from '../index';

export const responseIdFromPathSelector: Function = createSelector(
  locationStateSelector,
  (location: LocationState) => {
    const { query } = location;
    return (query && query.responseId) || null;
  }
);

export const projectResponseStateSelector: Function = createSelector(
  projectDetailStateSelector,
  (projectDetail: ProjectDetailState) => projectDetail.response
);

export const responseStatsStateSelector: Function = createSelector(
  projectDetailStateSelector,
  (projectsDetail: ProjectDetailState): ResponseStatsState => {
    const { stats } = projectsDetail;
    return stats;
  }
);
