// @flow
import * as React from 'react';
import styled from 'styled-components';
import Checkbox from 'common/components/Checkbox';
import Content from 'common/components/Content';
import Header from 'common/components/Header';
import LazyImage from 'common/components/Image/lazy';
import Image from 'common/components/Image';

import type { Props as CheckboxProps } from 'common/components/Checkbox';

import Card from './index';

const Check = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  background-size: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #1d253f;

  &::before {
    content: '';
    display: flex;
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    bottom: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    background: #1d254f;
    transform: scale(0);
    transition: transform 0.2s;
    //border-radius: 50% !important;
    transform-origin: center;
  }

  input[type='radio'] + & {
    border-radius: 50% !important;
    &::before {
      border-radius: 50% !important;
    }
  }

  input[type='checkbox'] + & {
    border-radius: 0.125rem !important;

    &::before {
      border-radius: 0.125rem !important;
    }
  }

  input:checked + & {
    &::before {
      transform: scale(0.9);
    }
  }
`;

const StyledCard = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  // checkbox will be positioned
  // relatively to image
  & > .ui.image {
    position: relative;
    user-select: none;
    pointer-events: none;
  }

  & > .ui.image > .checkbox,
  & > .ui.image > .radio {
    position: absolute !important;
    top: 0;
    left: -999px;
  }
`;

type Props = CheckboxProps & {
  label: string,
  image: Object | string,
  disabled: boolean,
  lazyProps?: Object,
  showLabel: boolean,
  lazy?: boolean,
  radio?: boolean
};

export default function SelectionCard({
  label,
  image,
  lazyProps,
  showLabel,
  radio,
  value,
  lazy,
  ...props
}: Props): React.Node {
  const imageEl = React.useMemo(() => {
    if (!image) {
      // TODO: add some placeholder
      return <Image />;
    }

    if (!lazy) {
      return <Image src={image.thumb256} />;
    }

    if (typeof image === 'string') {
      return <LazyImage src={image} lazyProps={lazyProps} />;
    }

    return <LazyImage src={image.thumb256} lazyProps={lazyProps} />;
  }, [image, lazy, lazyProps]);

  return (
    <Card tagName={StyledCard} className={value ? `highlighted-primary` : null}>
      <div className="ui lazy-container image">
        {imageEl}
        <Checkbox
          {...props}
          value={value}
          fitted
          fragment
          radio={radio}
          LabelComponent={Check}
          tabIndex={-1}
        />
      </div>
      {showLabel && (
        <Content>
          <Header size="small">{label}</Header>
        </Content>
      )}
    </Card>
  );
}

SelectionCard.defaultProps = {
  lazyProps: {},
  radio: true
};
