// @flow

import type { SetAnswersValidation } from '../../flow-types/actions/interview/SetAnswersValidation';

export default function setAnswersValidation(validation): SetAnswersValidation {
  return {
    type: 'interview/set-answers-validation',
    validation
  };
}
