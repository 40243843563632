import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';
import { createAuthorizedUserRoleSelector } from '../../selectors/auth';

type Props = {|
  +children: ReactChildren,
  +allowed?: Array<number>,
  // content is allowed if we have not data about current user roleId
  +allowOnUnknown?: boolean,
  // makes check that current user does not belong to any of passed groups
  +inverted?: boolean
|};

export function useUserRole() {
  const roleId$ = useMemo(createAuthorizedUserRoleSelector, []);

  return useSelector(roleId$);
}

export default function RoleGuard({
  children,
  // TODO: rename to triggerValues, may be
  allowed,
  // TODO: rename to triggerOnUnknown, may be
  allowOnUnknown,
  inverted
}: Props) {
  const currentUserRoleId = useUserRole();

  const [isVisible, setVisibility] = useState(false);

  useEffect(() => {
    if (!currentUserRoleId) {
      setVisibility(allowOnUnknown);
    }

    const roleCheck =
      Array.isArray(allowed) && allowed.includes(currentUserRoleId);

    setVisibility(inverted ? !roleCheck : roleCheck);
  }, [allowOnUnknown, allowed, currentUserRoleId, inverted]);

  // const isVisible = useMemo(() => {
  //   if (!currentUserRoleId) {
  //     return allowOnUnknown;
  //   }
  //
  //   const roleCheck =
  //     Array.isArray(allowed) && allowed.includes(currentUserRoleId);
  //
  //   // if not inverted, then if role is enlisted, then user can see contents
  //   if (inverted) return !roleCheck;
  //
  //   return roleCheck;
  // }, [allowOnUnknown, allowed, currentUserRoleId, inverted]);

  if (!isVisible) {
    return null;
  }

  return children;
}

RoleGuard.defaultProps = {
  allowOnUnknown: false,
  children: null,
  allowed: []
};
