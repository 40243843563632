// @flow

import type { Reducer } from 'flow-types/Reducer';
import type { Move } from 'flow-types/actions/interview/Move';
import type { $ObjOfType } from 'flow-types/ObjOfType';

type SideProcessesState = $ObjOfType<
  boolean | { enabled: true, config: Object, timeEnd?: string }
>;

const initialState = {};

type StartSideProcess = {
  type: 'process/start',
  processId: string,
  config: Object,
  timeEnd?: string
};

type CompleteSideProcess = {
  type: 'process/complete',
  processId: string
};

type Action = StartSideProcess | CompleteSideProcess | Move;

const startProcess: Reducer<SideProcessesState, StartSideProcess> = (
  state,
  action
) => ({
  ...state,
  [action.processId]: {
    ...action,
    enabled: true
  }
});

const sideProcesses: Reducer<SideProcessesState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'process/start':
      return startProcess(state, action);

    case 'process/complete':
      // $FlowIgnore
      return Object.keys(state).reduce((nextState, processId) => {
        if (processId === action.processId) {
          return nextState;
        }

        return {
          ...nextState,
          [processId]: state[processId]
        };
      }, {});

    case 'interview/move':
      if (action.force) return {};

    // eslint-disable-next-line no-fallthrough
    default:
      return state;
  }
};

export default sideProcesses;
