// @flow

import * as React from 'react';
import cx from 'classnames';
import flatMapDeep from 'lodash/fp/flatMapDeep';
import isNil from 'lodash/isNil';
import every from 'lodash/every';
import keyBy from 'lodash/keyBy';
import { merge } from 'merge-anything';
import { useIntl } from 'react-intl';

import Flexbox from 'common/components/Flexbox';
import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import {
  DATE_FNS_FORMAT_NO_SECONDS,
  DATE_FNS_FORMAT_TIME_ONLY_SHORT
} from 'utils/config';

import type {
  ICheckOperator,
  IUnionOperator
} from 'flow-types/entities/FilterSettings';

import parseISO from 'date-fns/parseISO';
import Input, { InputWrapper } from '../../components/Input';
import DatePicker from '../../components/DatePicker';

import SourceSelector from './SourceSelector';
import CheckOperatorSelector from './CheckOperatorSelector';
import UnionOperatorSelector from './UnionOperatorSelector';
import TextInput from './TestCheckedValueFields/TextInput';
import SelectableInput from './TestCheckedValueFields/SelectableInput';
import RelativeTimeInput from './TestCheckedValueFields/RelativeTimeInput';

import { excludedChecksBy, processNextSourceSelection } from './utils';

import type { IOptionsGroup } from '../../components/Dropdown/flow';
import type { ITest, ITestSource, OnChangeCb, OnRemoveCb } from './flow';

type Props = {
  disabled: boolean,
  index: string,
  data: ITest,
  onChange: OnChangeCb,
  onRemove: OnRemoveCb,
  enableSourceSelection?: boolean,
  enableBracketsInput?: boolean,
  className?: string | null,
  excludedCheckOperators?: null | ICheckOperator[],
  excludedUnionOperators?: null | IUnionOperator[],
  sources: IOptionsGroup<ITestSource>[],
  validation?: null | {|
    source: mixed,
    operator: mixed,
    unionType: mixed,
    value: mixed,
    options: mixed
  |},
  size?: string
};

const flattenSource = flatMapDeep(({ options }) => options);

const getOperatorColumnWidth = options => {
  if (options?.canSelectValue) {
    return 'two';
  }

  return null;
};

const getColumnsCount = options => {
  if (!options?.enableSourceSelection && !options?.enableBracketsInput) {
    return 'two';
  }

  if (options?.canSelectValue) {
    return 'five';
  }

  return 'four';
};

export const isInMultiMode = (source: ITestSource, test: ITest): boolean =>
  source?.isMulti || test.unionType === LOGIC_OPERAND.OR;

export default function TestForm({
  data,
  index,
  sources,
  excludedCheckOperators,
  excludedUnionOperators,
  disabled,
  // $FlowFixMe
  onChange,
  onRemove,
  validation,
  size,
  enableSourceSelection,
  enableBracketsInput,
  className
}: Props): React.Node {
  const intl = useIntl();

  const sourcesMap = React.useRef(keyBy(flattenSource(sources), 'id'));

  const selectedSource = !data ? null : sourcesMap.current[data.source];

  const meta = React.useMemo(() => {
    const nextMeta = {
      isCheckOperatorDisabled: false,
      isUnionOperatorDisabled: false,
      isRegularInputVisible: false,
      isDateInputVisible: false,
      isRelativeDateInputVisible: false,
      isSelectableInputVisible: false,
      isOperatorSelectorDisabled: false,
      isTargetValueInputAvailable: false,
      isCheckOperatorVisible: true,
      isUnionOperatorVisible: false
    };

    if (selectedSource) {
      const {
        isDate,
        isSelectable: isSourceSelectable,
        disableCheckOperator,
        disableUnionOperator,
        hideUnionOperator,
        hideCheckOperator
      } = selectedSource;

      // defaults
      if (isSourceSelectable) {
        nextMeta.isSelectableInputVisible = true;
        nextMeta.isUnionOperatorVisible = true;
      } else if (isDate) {
        const { settings } = data;
        nextMeta.isRelativeDateInputVisible =
          !!settings && !!settings.specialTime;

        nextMeta.isDateInputVisible = !nextMeta.isRelativeDateInputVisible;
      } else {
        nextMeta.isRegularInputVisible = true;
      }

      // overrides
      nextMeta.isTargetValueInputAvailable = !!data.operator && !disabled;
      nextMeta.isCheckOperatorDisabled = disableCheckOperator || disabled;
      nextMeta.isUnionOperatorDisabled = disableUnionOperator || disabled;

      if (hideCheckOperator) {
        nextMeta.isCheckOperatorVisible = false;
      }

      if (hideUnionOperator) {
        nextMeta.isUnionOperatorVisible = false;
      }
    } else {
      nextMeta.isTargetValueInputAvailable = !!data.operator && !disabled;
      nextMeta.isCheckOperatorDisabled = disabled;
      nextMeta.isUnionOperatorDisabled = disabled;
      nextMeta.isRegularInputVisible = true;
    }

    return nextMeta;
  }, [data, disabled, selectedSource]);

  const excludedChecks = React.useMemo(() => {
    if (!selectedSource) return excludedCheckOperators;

    let resultExcluded = excludedCheckOperators;

    if (Array.isArray(selectedSource.excludedCheckOperators)) {
      resultExcluded = selectedSource.excludedCheckOperators;
      // return selectedSource.excludedCheckOperators;
    }

    if (selectedSource.isDate) {
      if (data.settings) {
        if (data.settings.specialTime) {
          return Array.isArray(resultExcluded)
            ? [...resultExcluded, LOGIC_OPERATOR.NOT_IN, LOGIC_OPERATOR.EQUAL]
            : [LOGIC_OPERATOR.NOT_IN, LOGIC_OPERATOR.EQUAL];
        }
      }
      return Array.isArray(resultExcluded)
        ? [...resultExcluded, LOGIC_OPERATOR.NOT_IN, LOGIC_OPERATOR.IN]
        : [LOGIC_OPERATOR.NOT_IN, LOGIC_OPERATOR.IN];
    }

    if (selectedSource.isSelectable) {
      return Array.isArray(resultExcluded)
        ? [...resultExcluded, ...excludedChecksBy.selectable]
        : [...excludedChecksBy.selectable];
    }

    let result = [...excludedChecksBy.unselectable];

    if (Array.isArray(resultExcluded)) {
      result = [...result, ...resultExcluded];
    }

    if (selectedSource.isString) {
      result = [...result, ...excludedChecksBy.text];
    }

    return result;
  }, [data.settings, excludedCheckOperators, selectedSource]);

  const excludedUnions = React.useMemo(() => {
    if (!selectedSource) return excludedUnionOperators;

    const {
      isSelectable,
      excludedUnionOperators: localExcludedUnionOperators
    } = selectedSource;

    if (Array.isArray(localExcludedUnionOperators)) {
      return localExcludedUnionOperators;
    }

    if (isSelectable) {
      return excludedUnionOperators ? [...excludedUnionOperators] : [];
    }

    return excludedUnionOperators ? [...excludedUnionOperators] : [];
  }, [excludedUnionOperators, selectedSource]);

  const isSelectable = !!selectedSource?.isSelectable;

  const handleOpeningBracketsChange = React.useCallback(
    (nextOpeningBrackets: string) => {
      if (!onChange) return;

      onChange(index, {
        openingBrackets: nextOpeningBrackets
      });
    },
    [index, onChange]
  );

  const handleClosingBracketsChange = React.useCallback(
    (nextClosingBrackets: string) => {
      if (!onChange) return;

      onChange(index, {
        closingBrackets: nextClosingBrackets
      });
    },
    [index, onChange]
  );

  const handleSourceChange = React.useCallback(
    (nextSource: ITestSource) => {
      if (!onChange) return;

      if (selectedSource && nextSource && selectedSource.id === nextSource.id) {
        return;
      }

      if (nextSource === null) {
        onChange(
          index,
          // TODO: resolve later
          // $FlowIgnore
          {
            source: null,
            operator: null,
            unionType: null,
            options: [],
            value: null,
            settings: {}
          }
        );
        return;
      }

      const update: $Shape<ITest> = processNextSourceSelection(nextSource);

      onChange(index, update);
    },
    [index, onChange, selectedSource]
  );

  const handleSettingsChange = React.useCallback(
    nextSettingsUpdate => {
      if (!onChange) return;

      const update: $Shape<ITest> = {
        settings: merge(data.settings ? { ...data.settings } : {}, {
          ...nextSettingsUpdate
        })
      };

      if (data.settings) {
        // on specialTime change, reset value
        // $FlowFixMe
        if (data.settings.specialTime !== update.settings.specialTime) {
          update.value = null;
        }
      }

      onChange(index, update);
    },
    [data.settings, index, onChange]
  );

  const handleCheckOperatorChange = React.useCallback(
    (nextCheckOperator: ICheckOperator) => {
      if (!onChange) return;

      const update: $Shape<ITest> = { operator: nextCheckOperator };

      if (selectedSource && selectedSource.isDate) {
        if (data.settings && data.settings.specialTime) {
          const isCurrentAndNextOfTheSameType = every(
            [nextCheckOperator, data.operator],
            o =>
              [
                LOGIC_OPERATOR.LESS_THAN_OR_EQUAL,
                LOGIC_OPERATOR.GREATER_THAN_OR_EQUAL
              ].includes(o)
          );

          if (nextCheckOperator !== data.operator) {
            if (nextCheckOperator === LOGIC_OPERATOR.IN) {
              update.unionType = LOGIC_OPERAND.AND;
            }

            if (!isCurrentAndNextOfTheSameType) {
              update.value = [null, null];
            }
          }
        }
      }

      onChange(index, update);
    },
    [data.operator, data.settings, index, onChange, selectedSource]
  );

  const handleUnionOperatorChange = React.useCallback(
    (nextUnionOperator: IUnionOperator) => {
      if (!onChange) return;

      onChange(index, { unionType: nextUnionOperator });
    },
    [index, onChange]
  );

  const handleValueChange = React.useCallback(
    (nextValue: null | mixed) => {
      if (!onChange) return;

      onChange(index, { value: nextValue });
    },
    [index, onChange]
  );

  const isSourceMulti = isInMultiMode(selectedSource, data);

  React.useEffect(() => {
    if (
      !isSourceMulti &&
      Array.isArray(data.options) &&
      data.options.length > 1
    ) {
      onChange(index, {
        options: data.options.slice(0, 1)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.options, index, isSourceMulti]);

  React.useEffect(() => {
    if (
      [LOGIC_OPERATOR.EMPTY, LOGIC_OPERATOR.NOT_EMPTY].includes(data.operator)
    ) {
      onChange(index, {
        value: null,
        options: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.operator]);

  const handleSelectableInputChange = React.useCallback(
    (nextOptions: null | number[] | string | number) => {
      if (!onChange) return;

      if (selectedSource) {
        const { targetField } = selectedSource;
        if (targetField === 'value') {
          if (nextOptions === null) {
            onChange(index, { value: null });
          } else {
            // $FlowFixMe
            onChange(index, {
              value: nextOptions
            });
          }
          return;
        }
      }

      if (nextOptions === null) {
        onChange(index, { options: null });
      } else {
        // $FlowFixMe
        onChange(index, {
          options: isSourceMulti ? nextOptions : [nextOptions]
        });
      }
    },
    [index, isSourceMulti, onChange, selectedSource]
  );

  const handleRemoveClick = React.useCallback(() => {
    if (!onRemove) return;

    onRemove(index, data);
  }, [data, index, onRemove]);

  const selectableValue = React.useMemo(() => {
    if (selectedSource) {
      const { targetField } = selectedSource;

      if (targetField) {
        return data[targetField] || null;
      }
    }

    if (!isSourceMulti) {
      if (Array.isArray(data.options)) {
        return data.options[0];
      }
      return null;
    }

    return data.options;
  }, [data, isSourceMulti, selectedSource]);

  const testCheckOperatorLabels = React.useMemo(() => {
    if (!selectedSource) return null;

    const {
      isMulti,
      isString,
      isNumber,
      isDate,
      isSelectable: isSourceSelectable
    } = selectedSource;

    if (!isSourceSelectable) {
      if (isNumber) {
        return {
          empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
          not_empty: intl.formatMessage({
            id: 'testsForm.testOperator.not_empty'
          }),
          lte: intl.formatMessage({
            id: 'testsForm.testOperator.lte'
          }),
          eq: intl.formatMessage({
            id: 'testsForm.testOperator.eq'
          }),
          gte: intl.formatMessage({
            id: 'testsForm.testOperator.gte'
          })
        };
      }

      if (isString) {
        return {
          empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
          not_empty: intl.formatMessage({
            id: 'testsForm.testOperator.not_empty'
          }),
          eq: intl.formatMessage({
            id: 'testsForm.testOperator.eq'
          }),
          in: intl.formatMessage({
            id: 'testsForm.testOperator.contains'
          }),
          not_in: intl.formatMessage({
            id: 'testsForm.testOperator.not_contains'
          })
        };
      }

      if (isDate) {
        if (data.settings) {
          if (data.settings.specialTime) {
            return {
              empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
              not_empty: intl.formatMessage({
                id: 'testsForm.testOperator.not_empty'
              }),
              lte: intl.formatMessage({
                id: 'testsForm.testOperator.wentMoreThan'
              }),
              in: intl.formatMessage({
                id: 'testsForm.testOperator.withingPeriod'
              }),
              gte: intl.formatMessage({
                id: 'testsForm.testOperator.wentLessThan'
              })
            };
          }
        }

        return {
          empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
          not_empty: intl.formatMessage({
            id: 'testsForm.testOperator.not_empty'
          }),
          lt: intl.formatMessage({
            id: 'testsForm.testOperator.before'
          }),
          lte: intl.formatMessage({
            id: 'testsForm.testOperator.beforeOrMatch'
          }),
          eq: intl.formatMessage({
            id: 'testsForm.testOperator.match'
          }),
          gte: intl.formatMessage({
            id: 'testsForm.testOperator.afterOrMatch'
          }),
          gt: intl.formatMessage({
            id: 'testsForm.testOperator.after'
          })
        };
      }
    }

    if (isMulti) {
      return {
        empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
        not_empty: intl.formatMessage({
          id: 'testsForm.testOperator.not_empty'
        }),
        eq: intl.formatMessage({
          id: 'testsForm.testOperator.eq'
        }),
        in: intl.formatMessage(
          {
            id: 'testsForm.testOperator.in'
          },
          { multi: data.unionType === LOGIC_OPERAND.AND }
        ),
        not_in: intl.formatMessage(
          {
            id: 'testsForm.testOperator.not_in'
          },
          { multi: data.unionType === LOGIC_OPERAND.AND }
        )
      };
    }

    return {
      empty: intl.formatMessage({ id: 'testsForm.testOperator.empty' }),
      not_empty: intl.formatMessage({ id: 'testsForm.testOperator.not_empty' }),
      eq: intl.formatMessage({
        id: 'testsForm.testOperator.eq'
      }),
      in: intl.formatMessage(
        {
          id: 'testsForm.testOperator.in'
        },
        { multi: false }
      ),
      not_in: intl.formatMessage(
        {
          id: 'testsForm.testOperator.not_in'
        },
        { multi: false }
      )
    };
  }, [data.settings, data.unionType, intl, selectedSource]);

  const testUnionOperatorLabels = React.useMemo(() => {
    if (!selectedSource || !selectedSource.isSelectable) return null;

    // return null;
    if (!selectedSource.isMulti) {
      return {
        or: intl.formatMessage({
          id: 'testsForm.testUnionOperator.oneOf'
        }),
        and: intl.formatMessage({
          id: 'testsForm.testUnionOperator.only'
        })
      };
    }

    return {
      or: intl.formatMessage({
        id: 'testsForm.testUnionOperator.oneOf'
      }),
      and: intl.formatMessage({
        id: 'testsForm.testUnionOperator.allOf'
      })
    };
  }, [intl, selectedSource]);

  React.useEffect(() => {
    if (selectedSource && selectedSource.isDate) {
      if (
        data.operator === LOGIC_OPERATOR.IN &&
        data.settings &&
        data.settings.specialTime
      ) {
        if (isNil(data.unionType)) {
          handleUnionOperatorChange(LOGIC_OPERAND.AND);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.operator,
    data.settings,
    data.unionType,
    // handleUnionOperatorChange,
    selectedSource
  ]);

  const canSelectValue = ![
    LOGIC_OPERATOR.EMPTY,
    LOGIC_OPERATOR.NOT_EMPTY
  ].includes(data.operator);

  return (
    <div
      className={cx(
        className,
        getColumnsCount({
          canSelectValue,
          enableSourceSelection,
          enableBracketsInput
        }),
        `column equal width row`
      )}
    >
      {enableBracketsInput && (
        <div className="one wide column">
          <InputWrapper fluid size={size} disabled={disabled}>
            <Input
              disabled={disabled}
              onlyValue
              onChange={handleOpeningBracketsChange}
              value={data.openingBrackets}
            />
          </InputWrapper>
        </div>
      )}
      {enableSourceSelection && (
        <div className="column">
          <SourceSelector
            disabled={disabled}
            value={data.source}
            sources={sources}
            error={validation ? !!validation.source : false}
            onChange={handleSourceChange}
            onRemoveClick={handleRemoveClick}
            onSettingsChange={handleSettingsChange}
            valueKey="id"
            labelKey="title"
            size={size}
            settings={data.settings}
          />
        </div>
      )}
      <Flexbox ignoreDisplay grow={1} className="column">
        {meta.isCheckOperatorVisible && !meta.isUnionOperatorVisible && (
          <CheckOperatorSelector
            labels={testCheckOperatorLabels}
            onChange={handleCheckOperatorChange}
            disabled={meta.isCheckOperatorDisabled}
            error={validation ? !!validation.operator : false}
            value={data.operator}
            onlyValue
            excluded={excludedChecks}
            size={size}
          />
        )}
        {meta.isCheckOperatorVisible && meta.isUnionOperatorVisible && (
          <div
            className={cx(
              'ui',
              getOperatorColumnWidth({ canSelectValue }),
              'column grid'
            )}
          >
            <div className="column">
              <CheckOperatorSelector
                labels={testCheckOperatorLabels}
                error={validation ? !!validation.operator : false}
                onChange={handleCheckOperatorChange}
                disabled={meta.isCheckOperatorDisabled}
                onlyValue
                value={data.operator}
                excluded={excludedChecks}
                size={size}
              />
            </div>
            {canSelectValue && (
              <div className="column">
                <UnionOperatorSelector
                  labels={testUnionOperatorLabels}
                  checkOperator={data.operator}
                  error={validation ? !!validation.unionType : false}
                  onChange={handleUnionOperatorChange}
                  disabled={meta.isUnionOperatorDisabled}
                  value={data.unionType}
                  onlyValue
                  excluded={excludedUnions}
                  size={size}
                />
              </div>
            )}
          </div>
        )}
      </Flexbox>
      {canSelectValue && (
        <div className="column">
          {meta.isDateInputVisible && (
            <DatePicker
              className={size ? `fluid ${size}` : 'fluid'}
              selected={
                typeof data.value === 'string'
                  ? parseISO(data.value)
                  : data.value
              }
              error={validation ? !!validation.value : false}
              dateFormat={DATE_FNS_FORMAT_NO_SECONDS}
              disabled={!meta.isTargetValueInputAvailable}
              showTimeSelect
              timeIntervals={10}
              timeFormat={DATE_FNS_FORMAT_TIME_ONLY_SHORT}
              onChange={handleValueChange}
            />
          )}
          {meta.isRelativeDateInputVisible && (
            <RelativeTimeInput
              size={size}
              onChange={handleValueChange}
              value={data.value}
              operator={data.operator}
              disabled={!meta.isTargetValueInputAvailable}
              error={validation ? !!validation.value : false}
            />
          )}
          {meta.isRegularInputVisible && (
            <TextInput
              CustomInputComponent={
                selectedSource ? selectedSource.CustomInputComponent : null
              }
              fluid
              size={size}
              onChange={handleValueChange}
              disabled={!meta.isTargetValueInputAvailable}
              value={data.value}
              onlyValue
              error={
                validation
                  ? !!validation[isSelectable ? 'options' : 'value']
                  : false
              }
            />
          )}
          {meta.isSelectableInputVisible && (
            <SelectableInput
              fluid
              size={size}
              async={selectedSource ? !!selectedSource.isAsync : false}
              onChange={handleSelectableInputChange}
              disabled={!meta.isTargetValueInputAvailable}
              error={validation ? !!validation.options : false}
              value={selectableValue}
              multiple={isSourceMulti}
              options={selectedSource ? selectedSource.options : []}
              valueKey="id"
              labelKey="title"
              CustomInputComponent={
                selectedSource ? selectedSource.CustomInputComponent : null
              }
              onlyValue
              {...(!!selectedSource &&
                !!selectedSource.props &&
                selectedSource.props)}
            />
          )}
        </div>
      )}
      {enableBracketsInput && (
        <div className="one wide column">
          <InputWrapper fluid size={size} disabled={disabled}>
            <Input
              disabled={disabled}
              onlyValue
              onChange={handleClosingBracketsChange}
              value={data.closingBrackets}
            />
          </InputWrapper>
        </div>
      )}
    </div>
  );
}

TestForm.defaultProps = {
  data: {},
  index: 0,
  className: null,
  excludedCheckOperators: [],
  excludedUnionOperators: [],
  enableSourceSelection: true,
  enableBracketsInput: true,
  validation: null,
  size: 'tiny'
};
