// @flow
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';
import clone from 'lodash/clone';

import type {
  DecodingListState,
  DecodingMap
} from 'flow-types/states/RecordsState';
import type { FetchRecordsSuccess } from 'flow-types/actions/records/FetchRecords';
import type { IUpload } from 'flow-types/entities/Upload';
import type {
  FetchDecodings,
  FetchDecodingsSuccess
} from 'flow-types/actions/records/decodings/list/FetchDecodings';
import type {
  SaveMessage,
  SaveMessageSuccess
} from 'flow-types/actions/records/decodings/list/SaveMessage';
import type {
  RemoveMessage,
  RemoveMessageFail,
  RemoveMessageSuccess
} from 'flow-types/actions/records/decodings/list/RemoveMessage';
import isNewElement from 'common/helpers/isNewElement';
import { orderByTimeframe } from 'common/transducers/uploads/decodingsListNormalizer';
import type { SaveSidesSuccess } from 'flow-types/actions/records/decodings/list/SaveSides';
import type { Reducer } from 'flow-types/Reducer';
import type { IDecoding, IDecodingMessage } from 'flow-types/entities/Decoding';

import constructMap from './utils';

const FETCH_DECODINGS = 'records/fetch-decodings';

const FETCH_DECODINGS_SUCCESS = 'records/fetch-decodings-success';

const SAVE_MESSAGE = 'records/save-decoding-message';
const SAVE_MESSAGE_FAIL = 'records/save-decoding-message-fail';
const SAVE_MESSAGE_SUCCESS = 'records/save-decoding-message-success';

const SAVE_SIDES = 'records/save-decoding-sides';
const SAVE_SIDES_FAIL = 'records/save-decoding-sides-fail';
const SAVE_SIDES_SUCCESS = 'records/save-decoding-sides-success';

const REMOVE_MESSAGE = 'records/remove-decoding-message';
const REMOVE_MESSAGE_FAIL = 'records/remove-decoding-message-fail';
const REMOVE_MESSAGE_SUCCESS = 'records/remove-decoding-message-success';

const createInitialState = (): DecodingListState => ({
  data: {},
  decodingsMap: {}
});

const handleRecordsFetchSuccess = (state, action): DecodingListState => {
  const next = clone(state);

  next.data = reduce(
    action.data,
    (nextState, record: IUpload): DecodingListState => {
      const { id } = record;

      if (nextState[id]) return nextState;

      return {
        ...nextState,
        [id]: {
          status: null,
          data: {}
        }
      };
    },
    { ...state.data }
  );

  next.decodingsMap = constructMap(next.data);

  return next;
};

const fetchList = Object.freeze({
  request: (
    state: DecodingListState,
    action: FetchDecodings
  ): DecodingListState => {
    const next = { ...state };

    next.data[action.uploadId] = {
      ...next[action.uploadId],
      status: 'loading'
    };

    return next;
  },
  success: (
    state: DecodingListState,
    { data, uploadId }: FetchDecodingsSuccess
  ): DecodingListState => {
    const next = { ...state };

    next.data[uploadId] = {
      data: groupBy(data, 'providerId'),
      status: 'success'
    };

    next.decodingsMap = constructMap(next.data);

    return next;
  }
});

const saveMessage = Object.freeze({
  request: (
    state: DecodingListState,
    action: SaveMessage
  ): DecodingListState => {
    const next = { ...state };

    const { decodingsMap } = state;

    const { data, decodingId } = action;

    const isNew = isNewElement(data);

    const { providerId, uploadId, index }: DecodingMap = decodingsMap[
      decodingId
    ];

    const decoding = clone(next.data[uploadId].data[providerId][index]);

    if (isNew) {
      decoding.messages = [...decoding.messages, { ...data, status: 'saving' }];
    } else {
      const messageIndex = findIndex(decoding.messages, { id: data.id });

      decoding.messages[messageIndex] = { ...data, status: 'saving' };
    }

    decoding.messages = orderByTimeframe(decoding.messages);

    next.data[uploadId].data[providerId][index] = decoding;

    return next;
  },
  success: (
    state: DecodingListState,
    action: SaveMessageSuccess
  ): DecodingListState => {
    const next = { ...state };

    const { decodingsMap } = state;

    const { data, decodingId, messageId } = action;

    const { uploadId, providerId, index }: DecodingMap = decodingsMap[
      decodingId
    ];

    const decoding: IDecoding = clone(
      next.data[uploadId].data[providerId][index]
    );

    const messageIndex = findIndex(decoding.messages, { id: messageId });

    decoding.messages[messageIndex] = { ...data, status: 'success' };
    decoding.messages = [...decoding.messages];

    next.data[uploadId].data[providerId][index] = decoding;

    return next;
  },
  fail: (state: DecodingListState): DecodingListState => state
});

const removeMessage = Object.freeze({
  request: (
    state: DecodingListState,
    { message }: RemoveMessage
  ): DecodingListState => {
    const next = { ...state };

    const { decodingsMap } = state;
    const { uploadDecodingId } = message;

    const {
      providerId,
      index: decodingIndex,
      uploadId
    }: DecodingMap = decodingsMap[uploadDecodingId];

    const decoding: IDecoding =
      next.data[uploadId].data[providerId][decodingIndex];

    const messageIndex = findIndex(decoding.messages, { id: message.id });

    decoding.messages[messageIndex] = { ...message, status: 'removing' };

    return next;
  },
  fail: (
    state: DecodingListState,
    { message }: RemoveMessageFail
  ): DecodingListState => {
    const next = { ...state };

    const { decodingsMap } = state;
    const { uploadDecodingId } = message;

    const {
      providerId,
      index: decodingIndex,
      uploadId
    }: DecodingMap = decodingsMap[uploadDecodingId];

    const decoding: IDecoding =
      next.data[uploadId].data[providerId][decodingIndex];

    const messageIndex = findIndex(decoding.messages, { id: message.id });

    decoding.messages[messageIndex] = { ...message, status: 'error' };

    return state;
  },
  success: (
    state: DecodingListState,
    action: RemoveMessageSuccess
  ): DecodingListState => {
    const { decodingsMap } = state;

    const { messageId, decodingId } = action;

    const decodingMap: DecodingMap = decodingsMap[decodingId];

    if (!decodingMap) return state;

    const next: DecodingListState = clone(state);

    const { uploadId, providerId, index } = decodingMap;

    const decoding: IDecoding = clone(
      next.data[uploadId].data[providerId][index]
    );

    decoding.messages = decoding.messages.filter(
      (msg: IDecodingMessage) => msg.id !== messageId
    );

    next.data[uploadId].data[providerId][index] = decoding;

    return next;
  }
});

const saveSides = Object.freeze({
  // request: state => state,
  // fail: state => state,
  success(
    state: DecodingListState,
    action: SaveSidesSuccess
  ): DecodingListState {
    const { decodingId, sides } = action;

    const { decodingsMap } = state;

    const decodingPath = decodingsMap[decodingId];

    if (!decodingPath) return state;

    const { providerId, uploadId, index }: DecodingMap = decodingPath;

    const next: DecodingListState = { ...state };

    next.data[uploadId].data[providerId][index] = {
      ...next.data[uploadId].data[providerId][index],
      sides
    };

    return next;
  }
});

// TODO: update action types
const decodingListReducer: Reducer<
  DecodingListState,
  Object | FetchRecordsSuccess
> = (state = createInitialState(), action) => {
  switch (action.type) {
    // case SAVE_SIDES:
    //   return saveSides.request(state, action);
    //
    // case SAVE_SIDES_FAIL:
    //   return saveSides.fail(state, action);

    case SAVE_SIDES_SUCCESS:
      return saveSides.success(state, action);

    case REMOVE_MESSAGE:
      return removeMessage.request(state, action);

    case REMOVE_MESSAGE_FAIL:
      return removeMessage.fail(state, action);

    case REMOVE_MESSAGE_SUCCESS:
      return removeMessage.success(state, action);

    case SAVE_MESSAGE:
      return saveMessage.request(state, action);

    case SAVE_MESSAGE_FAIL:
      return saveMessage.fail(state);

    case SAVE_MESSAGE_SUCCESS:
      return saveMessage.success(state, action);

    case FETCH_DECODINGS:
      return fetchList.request(state, action);

    case FETCH_DECODINGS_SUCCESS:
      return fetchList.success(state, action);

    case 'records/fetch-success':
      return handleRecordsFetchSuccess(state, action);

    default:
      return state;
  }
};

export default decodingListReducer;

export {
  FETCH_DECODINGS,
  FETCH_DECODINGS_SUCCESS,
  SAVE_MESSAGE,
  SAVE_MESSAGE_FAIL,
  SAVE_MESSAGE_SUCCESS,
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_FAIL,
  REMOVE_MESSAGE_SUCCESS,
  SAVE_SIDES,
  SAVE_SIDES_FAIL,
  SAVE_SIDES_SUCCESS
};
