import React from 'react';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';

import Table from 'common/components/Table';

import type { AccessSettings } from 'flow-types/entities/AccessSettings';

import { useProjectGroups } from '../../../../hooks/useProjectGroups';
import GroupAccessSettings from './GroupAccessSettings';
import type { AccessType } from './flow';
import { AccessTypesContext, useAccessTypes } from './AccessTypesProvider';

type Props = {|
  data: AccessSettings,
  onChange: (prevData: AccessSettings) => AccessSettings,
  accessTypes: AccessType[],
  disabled?: boolean
|};

function TableHeader() {
  const accessTypes = useAccessTypes();

  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell as="th" />
        <Table.Cell as="th">
          <FormattedMessage id="accessSettingsForm.fields.settingsTable.columns.title" />
        </Table.Cell>
        <Table.Cell as="th">
          <FormattedMessage id="accessSettingsForm.fields.settingsTable.columns.type" />
        </Table.Cell>
        {accessTypes.map(type => (
          <Table.Cell as="th" key={type.value}>
            {type.label}
          </Table.Cell>
        ))}
      </Table.Row>
    </Table.Head>
  );
}

export default function ProjectAccessSettingsTable({
  data,
  onChange,
  accessTypes,
  disabled
}: Props) {
  const groups = useProjectGroups();

  return (
    <Table>
      <AccessTypesContext.Provider value={accessTypes}>
        <TableHeader />
        <Table.Body>
          {map(groups, (group, index) => (
            <GroupAccessSettings
              disabled={disabled}
              data={group}
              key={group.id}
              settings={data}
              index={index}
              onChange={onChange}
            />
          ))}
        </Table.Body>
      </AccessTypesContext.Provider>
    </Table>
  );
}

ProjectAccessSettingsTable.defaultProps = {
  disabled: false
};
