import revertDictionary from '../../utils/revertDictionary';

const interviewerSelectionForm = {
  'interviewerSelectionForm.table.columns.id': {
    en: 'ID',
    ru: 'ИД'
  },
  'interviewerSelectionForm.table.columns.name': {
    en: 'Full name',
    ru: 'ФИО'
  },
  'interviewerSelectionForm.table.columns.phone': {
    en: 'Phone',
    ru: 'Моб. телефон'
  }
};

export default revertDictionary(interviewerSelectionForm);
