import baseRand from 'lodash/random';

export default function random(min, max, excluded) {
  const num = baseRand(min, max);

  if (Array.isArray(excluded) && excluded.includes(num)) {
    return random(min, max, excluded);
  }

  return num;
}
