import React, { useState } from 'react';
import cx from 'classnames';

import ListItem from 'common/components/ListItem';
import Checkbox from 'common/components/Checkbox';
import Flexbox from 'common/components/Flexbox';
import Header from 'common/components/Header';
import Link from 'common/components/Link';

import type { IQuestionOption } from 'flow-types/entities/QuestionOption';

type Props = {|
  disabled: boolean,
  selected: boolean,
  data: IQuestionOption,
  onSelect: Function,
  onHover: () => void,
  onLeave: () => void,
  type: string
|};

function Option({
  disabled,
  selected,
  data,
  onSelect,
  onHover,
  onLeave,
  type
}: Props) {
  const [valueChanged, setValueChanged] = useState(() => false);

  return (
    <ListItem
      className={cx(
        { selected, transition: selected && valueChanged, disabled },
        'border-blue'
      )}
      onMouseEnter={disabled ? null : onHover}
      onMouseLeave={disabled ? null : onLeave}
      onClick={() => {
        if (!onSelect) return;

        setValueChanged(true);

        onSelect(data.id);
      }}
      as={Link}
    >
      <Flexbox>
        <Flexbox ignoreDisplay grow={1}>
          <div className="ui grid">
            <div className="middle aligned column">
              <Checkbox
                radio={type === 'radio'}
                tabIndex="0"
                disabled={disabled}
                value={selected}
                className="blue"
              />
            </div>
            <div className="fourteen wide column">
              <Header className="light" color="blue">
                {data.title}
              </Header>
            </div>
          </div>
        </Flexbox>
      </Flexbox>
    </ListItem>
  );

  /*
  return (
    <OptionContainer
      className="item"
      onFocus={onHover}
      onBlur={onLeave}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
    >
      <Checkbox
        radio={!multi}
        tabIndex={index}
        name={
          !multi
            ? `question-${data.questionId}`
            : `question-${data.questionId}-option-${data.id}`
        }
        data-option-id={data.id}
        disabled={disabled}
        onClick={handleSelect}
        value={selected}
        labelClassName="question__options-item-label"
      >
        {data.title}
      </Checkbox>
    </OptionContainer>
  );
*/
}

export default Option;
