// @flow

import isNil from 'lodash/isNil';
import split from 'lodash/split';
import join from 'lodash/join';
import type { ITest$Value } from '../containers/TestsForm/flow';
import { SPECIAL_TIME_FORMAT_FORMAT } from '../../utils/config';

// accepts string, returns object
export const toClientFormat = (backendFormatValue: string) => {
  const r1 = new RegExp(SPECIAL_TIME_FORMAT_FORMAT.ADVANCED, 'gm');

  const r2 = new RegExp(SPECIAL_TIME_FORMAT_FORMAT.REGULAR);

  const isRelativeAdvancedFormat = r1.test(backendFormatValue);

  const isRelativeRegularFormat = r2.test(backendFormatValue);

  const [count, period] = split(backendFormatValue, /\s+/);

  if (isRelativeAdvancedFormat) {
    return [+count, period];
  }

  if (isRelativeRegularFormat) {
    return [count, period];
  }

  return [count, period];
};

// accepts object, returns string
export const toBackendFormat = (
  clientFormatValue: ITest$Value
): null | string => {
  if (!Array.isArray(clientFormatValue)) return null;

  if (isNil(clientFormatValue[0]) || isNil(clientFormatValue[1])) {
    return null;
  }

  return join(clientFormatValue, ' ');
};
