// @flow

import { merge } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxOp from 'rxjs/operators';
import uniqueId from 'lodash/uniqueId';
import type { Epic } from 'flow-types/Epic';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { IProject } from 'flow-types/entities/Project';
import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import getNextOrder from 'common/helpers/getNextOrder';
import {
  projectFinalDataSelector,
  projectGroupsStateSelector
} from '../../../selectors/projects';
import unsavedChangesCheckProcess from './subProcesses/unsavedChangesCheck';

const addGroup: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-groups/add-group-process'),
    RxOp.withLatestFrom(state$),
    RxOp.exhaustMap(([, state]) => {
      const { data }: ProjectGroupsState = projectGroupsStateSelector(state);
      const { id: projectId }: IProject = projectFinalDataSelector(state);

      const nextOrder = getNextOrder(data);

      const nextGroupData: IQuestionGroup = {
        title: '',
        shortTitle: '',
        order: nextOrder,
        id: +uniqueId('-'),
        isNew: true,
        questions: [],
        projectId
      };

      const cancel$ = action$.pipe(
        ofType('project-groups/unsaved-changes-check-fail')
      );

      return merge(
        action$.pipe(
          ofType('project-groups/unsaved-changes-check-success'),
          RxOp.mergeMap(() => [
            {
              type: 'project-groups/add-group',
              group: nextGroupData
            },
            {
              type: 'project-groups/select-group',
              questionGroupId: nextGroupData.id
            }
          ]),
          RxOp.take(2),
          RxOp.takeUntil(cancel$)
        ),
        unsavedChangesCheckProcess(action$, state$)
      );
    })
  );

export default addGroup;
