// @flow

import * as React from 'react';
import Modal from 'common/components/animated/Modal';
import Icon from 'common/components/Icon';
import useModal from 'common/hooks/useModal';
import { FormattedMessage } from 'react-intl';

export const PAGE_CRASHED_DIALOG_MODAL_ID = 'page-crashed-dialog';

const dimmerProps = {
  page: true,
  fixed: true,
  inverted: true
};

export default function PageCrashedDialog(): React.Node {
  const [state] = useModal(PAGE_CRASHED_DIALOG_MODAL_ID);

  return (
    <Modal
      basic
      active={state.visible}
      visible={state.visible}
      portal
      dimmerProps={dimmerProps}
    >
      <Modal.Header icon>
        <Icon icon="frown outline" />
        <FormattedMessage id="interview.modals.pageCrashed.title" />
      </Modal.Header>
    </Modal>
  );
}
