// @flow
import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import find from 'lodash/find';

import { convertVariableTypeToJSType } from 'common/helpers/computedProperties/core';
import { convertBlockTypeToJSType, TYPES } from 'common/helpers/question';
import { disableItemsBy } from 'common/helpers/disableItems';
import { hideItemsBy } from 'common/helpers/hideItems';
import {
  logicResultsToStructureApplier,
  structureElementsFlowConstructor
} from 'common/helpers/interview/structureConstructor';

import { VARIABLE_PREFIX } from 'utils/config';
import registerSelectors from 'utils/registerSelectors';

import type { StateSelector } from 'flow-types/Selector';
import type { InterviewState } from 'flow-types/states/InterviewState';
import type { IInterviewQuestion } from 'flow-types/entities/Question';
import type { InterviewAnswersState } from 'flow-types/states/InterviewState/InterviewAnswersState';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { ComputedPropertyValue } from 'common/helpers/computedProperties';
import type { IComputedProperty } from 'flow-types/entities/ComputedProperty';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { IQuestionOption } from 'flow-types/entities/QuestionOption';
import type { VariableValue } from 'flow-types/Variable';
import type { $ObjOfType } from 'flow-types/ObjOfType';

import type { ILogicResults } from 'flow-types/entities/LogicResults';
import { ANSWER_STATUS } from '../../reducers/interview/answers';
import { interviewQuestionsSelector } from './questions';
import {
  interviewLogicResultsSelector,
  interviewLogicSourceQuestionsSelector
} from './logic';
import {
  interviewActiveStackSelector,
  interviewAnswersSelector,
  interviewLogicStateSelector,
  interviewProjectDataSelector,
  interviewStructureSelector
} from './root';
import { interviewStateSelector } from '../index';
import type { InterviewStructureState } from '../../reducers/interview/structure';

export const interviewAnswersListSelector: StateSelector<
  IInterviewAnswer[]
> = createSelector(
  interviewStateSelector,
  (interviewState: InterviewState): InterviewAnswersState =>
    // TODO: why answers could be an array? FORMS-1034
    Array.isArray(interviewState.answers)
      ? interviewState.answers
          .map((answer, index) =>
            answer ? { ...answer, questionId: index } : null
          )
          .filter(answer => !!answer)
      : // TODO: resolve later
        // $FlowFixMe
        Object.keys(interviewState.answers).map(questionId => ({
          ...interviewState.answers[questionId],
          questionId: +questionId
        }))
);

export const failedAnswersSelector: StateSelector<> = createSelector(
  interviewAnswersListSelector,
  (answers: InterviewAnswersState): boolean =>
    filter(answers, ({ status }) => status === ANSWER_STATUS.FAILED)
);

export const interviewLogicAnswersForSourceQuestionsSelector: StateSelector<> = createSelector(
  interviewAnswersSelector,
  interviewLogicStateSelector,
  interviewLogicSourceQuestionsSelector,
  (answers, logic, sourceQuestions) =>
    reduce(
      logic.sources,
      (result, questionId) => {
        if (!answers[questionId]) {
          return result;
        }
        return {
          ...result,
          [questionId]: {
            ...answers[questionId],
            question: find(sourceQuestions, { id: questionId })
          }
        };
      },
      {}
    )
);

export const interviewGroupsSelector: StateSelector<> = createSelector(
  [interviewProjectDataSelector, interviewLogicResultsSelector],
  ({ groups }, { hiddenGroups, disabledGroups }) => {
    let nextGroups = hideItemsBy(groups, 'id', hiddenGroups);

    nextGroups = disableItemsBy(nextGroups, 'id', disabledGroups);

    return nextGroups;
  }
);

export const logicAwaredStructureComposer = (
  structure: InterviewStructureState,
  logicResults: ILogicResults
): Array<IInterviewStructureElement> =>
  structureElementsFlowConstructor(
    logicResultsToStructureApplier(structure, logicResults)
  );

export const interviewLogicAwaredStructureSelector: StateSelector<> = createSelector(
  interviewStructureSelector,
  interviewLogicResultsSelector,
  logicAwaredStructureComposer
);

export const interviewStructureDictionarySelector: StateSelector<
  $ObjOfType<IInterviewStructureElement>
> = createSelector(interviewLogicAwaredStructureSelector, structure =>
  keyBy(structure, 'localId')
);

export const interviewActiveStackDataSelector: StateSelector<> = createSelector(
  interviewActiveStackSelector,
  interviewStructureDictionarySelector,
  (stackId, structure) => {
    if (!stackId) return null;

    return structure[stackId];
    // return getStackById(stackId, structure);
  }
);

export const interviewComputedPropertiesStateSelectorCreator = (): Function =>
  createSelector(
    interviewStateSelector,
    interview => interview.computedProperties
  );
/**
 * composes variables value for question according
 * to its type
 */
const composeValue = (
  question: IInterviewQuestion,
  answer: IInterviewAnswer,
  appearance: 'classic' | 'handlebars' = 'handlebars'
): VariableValue => {
  if (isNil(answer.data)) {
    return null;
  }

  const { data, meta } = answer;

  let result;

  switch (question.type) {
    case TYPES.SingleAnswer:
    case TYPES.Status:
      result = find(question.options, opt => opt.id === data);

      if (appearance === 'classic') {
        return result?.title || '';
      }

      break;
    case TYPES.MultipleAnswer:
    case TYPES.Checklist:
      // eslint-disable-next-line no-case-declarations
      result = filter(question.options, opt => includes(data, opt.id));

      if (includes(data, 'other')) {
        result = [
          ...result,
          { id: 'other', title: meta?.other || '', points: null }
        ];
      }

      if (appearance === 'classic') {
        return result.reduce((resultedString, option: IQuestionOption) => {
          if (resultedString.length === 0) {
            return option.title;
          }

          return `${resultedString}, ${option.title}`;
        }, '');
      }

      break;
    case TYPES.Ranging:
      result = reduce(
        data,
        (resulted, optionId) => {
          const option = find(question.options, { id: optionId });

          if (!option) return [...resulted, { title: 'Нет данных' }];

          return [...resulted, option];
        },
        []
      );

      if (appearance === 'classic') {
        return result.reduce((resultedString, option: IQuestionOption) => {
          if (resultedString.length === 0) {
            return option.title;
          }

          return `${resultedString}, ${option.title}`;
        }, '');
      }
      break;
    case TYPES.Email:
    case TYPES.TextBlock:
    case TYPES.Rating:
    case TYPES.Numeric:
    case TYPES.MobilePhone:
    case TYPES.SecondaryPhones:
    case TYPES.DateTime:
      result = data;
      break;
    default:
      return null;
  }

  return result;
};
const projectPropertiesSelector = createSelector(
  interviewProjectDataSelector,
  project => project.variables
);
export const interviewVariablesSelectorCreator = (): Function =>
  createSelector(
    interviewAnswersListSelector,
    interviewQuestionsSelector,
    interviewComputedPropertiesStateSelectorCreator(),
    projectPropertiesSelector,
    (answers, questions, computedValues, properties) => {
      const computedTypes = reduce(
        properties,
        (result, property: IComputedProperty) => ({
          ...result,
          [property.code]: convertVariableTypeToJSType(property.varType)
        }),
        {}
      );

      return reduce(
        answers,
        (
          result: $ObjOfType<ComputedPropertyValue>,
          answer: IInterviewAnswer
        ) => {
          const { questionId } = answer;

          const question = questions.find(q => q.id === questionId);

          const code = question?.code;

          if (!code) {
            return result;
          }

          const varType = convertBlockTypeToJSType(question.type);

          const classicValue = composeValue(question, answer, 'classic');
          const hndlbrsValue = composeValue(question, answer, 'handlebars');

          return {
            values: {
              ...result.values,
              // FORCE CAST TO STRING
              // we're checking code above in if-else statement
              [((code: any): string)]: classicValue,
              [((`${VARIABLE_PREFIX.handlebars}${code}`: any): string)]: hndlbrsValue
            },
            types: {
              ...result.types,
              // FORCE CAST TO STRING
              // we're checking code above in if-else statement
              [((code: any): string)]: varType
            }
          };
        },
        {
          values: {
            ...reduce(
              computedValues,
              (result, property, code) => ({
                ...result,
                [code]: property,
                [`${VARIABLE_PREFIX.handlebars}${code}`]: property
              }),
              {}
            )
          },
          types: {
            ...computedTypes
          }
        }
      );
    }
  );

registerSelectors({
  interviewAnswersListSelector,
  failedAnswersSelector,
  interviewLogicAnswersForSourceQuestionsSelector,
  interviewGroupsSelector,
  interviewLogicAwaredStructureSelector,
  interviewActiveStackDataSelector,
  projectPropertiesSelector
});
