import { combineReducers } from 'redux';
import record from './record';
import answers from './answers';
import registry from './registry';
import project from './project';
import logic from './logic';
import activeStack from './activeStack';
import direction from './direction';
import log from './log';
import ui from './ui';
import computedProperties from './computedProperties';
import structure from './structure';

export default combineReducers({
  record,
  answers,
  registry,
  project,
  logic,
  activeStack,
  log,
  direction,
  computedProperties,
  structure,
  ui
});
