// @flow
import type { ProjectDetailState } from 'flow-types/states/ProjectsState/detail';
import type { LocationState } from 'redux-first-router/dist/flow-types';
import type { StateSelector } from 'flow-types/Selector';
import registerSelectors from 'utils/registerSelectors';
import type { ChecksState } from '../flow-types/states/ChecksState';
import type { AuthState } from '../flow-types/states/AuthState';
import type { AudioRecordsState } from '../flow-types/states/RecordsState';
import type { InterviewState } from '../flow-types/states/InterviewState';
import type { CompaniesState } from '../flow-types/states/CompaniesState';
import type { MeetingsState } from '../pages/Meetings/Meetings.state';
import type { AppState } from '../flow-types/AppState';
import type { ProjectsListState } from '../flow-types/states/ProjectsState/ProjectsListState';
import type { PageState } from '../flow-types/states/PageState';
import type { LanguageState } from '../flow-types/states/LanguageState';
import type { ModalsState } from '../pages/Modals/modals';
import type { QueryStateType } from '../crm/query/Query.type';
import type { ProfileState } from '../flow-types/states/ProfileState';

export const languageStateSelector = (state: AppState): LanguageState =>
  state.language;

export const companiesPageStateSelector = (state: AppState): CompaniesState =>
  state.companies;

export const ownPropsSelector = (state: AppState, ownProps: Object): Object =>
  ownProps;

export const authStateSelector = (state: AppState): AuthState => state.auth;

export const pageStateSelector = (state: AppState): PageState => state.page;

export const locationStateSelector = (state: AppState): LocationState =>
  state.location;

export const projectListStateSelector = (state: AppState): ProjectsListState =>
  state.projectList;

export const projectDetailStateSelector = (
  state: AppState
): ProjectDetailState => state.projectDetail;

export const checksStateSelector = (state: AppState): ChecksState =>
  state.checklists;

export const recordsStateSelector = (state: AppState): AudioRecordsState =>
  state.records;

export const interviewStateSelector = (state: AppState): InterviewState =>
  state.interview;

export const meetingsStateSelector = (state: AppState): MeetingsState =>
  state.meetings;

export const modalsStateSelector = (state: AppState): ModalsState =>
  state.modals;

export const profileStateSelector = (state: AppState): ProfileState =>
  state.profile;

export const queryComposerStateSelector = (state: AppState): QueryStateType =>
  state.queryComposer;

export const sideProcessesSelector: StateSelector<Object> = state =>
  state.sideProcesses;

registerSelectors({
  languageStateSelector,
  companiesPageStateSelector,
  ownPropsSelector,
  authStateSelector,
  pageStateSelector,
  locationStateSelector,
  projectListStateSelector,
  projectDetailStateSelector,
  checksStateSelector,
  recordsStateSelector,
  interviewStateSelector,
  meetingsStateSelector,
  modalsStateSelector,
  profileStateSelector,
  queryComposerStateSelector,
  sideProcessesSelector
});
