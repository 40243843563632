import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import type { Epic } from '../../flow-types/Epic';

const resetTracksEpic: Epic = $action =>
  $action.pipe(
    ofType('router/recordsPage'),
    RxOperators.mapTo({
      type: 'records/reset'
    })
  );

export default resetTracksEpic;
