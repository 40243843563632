import { mergeAndCompare } from 'merge-anything';

export const DELETED_FLAG = '__DELETED__';

function comparator(prev, next) {
  if (typeof next === 'object' && next !== null && !Array.isArray(next)) {
    const result = { ...next };

    Object.keys(result).forEach(path => {
      if (result[path] === DELETED_FLAG) {
        delete result[path];
      }
    });

    return result;
  }

  return next;
}

function merge(origin: mixed, ...newComers) {
  // eslint-disable-next-line prefer-rest-params
  return mergeAndCompare(comparator, origin, ...newComers);
}

export default merge;
