import * as React from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import type { Move } from 'flow-types/actions/interview/Move';

import useSideProcess from 'common/hooks/useSideProcess';
import { MOVEMENT_RESTRICTED } from '../../../epics/interviews/applyRestrictionsOnStackChange';
import { interviewRecordSelector } from '../../../selectors/interview/root';

const createDisabledSelector = () =>
  createSelector(interviewRecordSelector, record => record.locked);

export default function useMove(
  predefinedPayload: Object
): { run: (payload: $Shape<Move>, force: boolean) => void, disabled: boolean } {
  const dispatch = useDispatch();

  const [state, , complete] = useSideProcess(MOVEMENT_RESTRICTED);

  const disabledSelector = React.useMemo(createDisabledSelector, []);

  const disabled = useSelector(disabledSelector);

  const latestDisabled = React.useRef(disabled);

  React.useLayoutEffect(() => {
    latestDisabled.current = disabled || state.enabled;
  });

  const run = React.useCallback(
    (payload: $Shape<Move>, force: boolean = false) => {
      if (latestDisabled.current && !force) {
        return;
      }

      if (force) {
        complete();
      }

      dispatch({
        type: 'interview/move',
        ...(predefinedPayload && predefinedPayload),
        ...(payload && payload)
      });
    },
    [complete, dispatch, predefinedPayload]
  );

  return { run, disabled };
}
