/* eslint-disable no-underscore-dangle */
import { ofType } from 'redux-observable';
import * as RxOp from 'rxjs/operators';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import filter from 'lodash/filter';
import size from 'lodash/size';
import move from 'array-move';
import { EMPTY, forkJoin, merge, of, zip } from 'rxjs';
import sortingItemsNormalizer from 'common/transducers/sortingItemsNormalizer';

import type { Epic } from 'flow-types/Epic';
import type { IQuestion } from 'flow-types/entities/Question';
import type { ProjectGroupsState } from 'flow-types/states/ProjectsState/detail';
import type { MoveQuestionToGroup } from 'flow-types/actions/projects/detail/structure/questions/MoveQuestionToGroup';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { AppState } from 'flow-types/AppState';
import { projectGroupsStateSelector } from '../../../selectors/projects';
import updateQuestionsOrder from '../../../actions/project/groups/updateQuestionsOrder';

const moveQuestionToGroup: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-groups/move-question-to-group'),
    RxOp.withLatestFrom(state$),
    RxOp.exhaustMap(([action, state]: [MoveQuestionToGroup, AppState]) => {
      const {
        targetOrder,
        sourceQuestionGroupId,
        targetQuestionGroupId,
        questionId,
        sourceOrder
      } = action;
      const { data: groups }: ProjectGroupsState = projectGroupsStateSelector(
        state
      );

      let question = reduce(
        groups,
        (_question, group) => {
          if (_question) return _question;

          return find(group.questions, (q: IQuestion) => q.id === questionId);
        },
        null
      );

      const _sourceGroup: IQuestionGroup = find(groups, {
        id: sourceQuestionGroupId
      });

      const _targetGroup: IQuestionGroup = find(groups, {
        id: targetQuestionGroupId
      });

      if (sourceQuestionGroupId === targetQuestionGroupId) {
        const updatedQuestions = move(
          _sourceGroup.questions,
          sourceOrder,
          targetOrder
        );

        return of(
          updateQuestionsOrder(targetQuestionGroupId, updatedQuestions)
        );
      }

      if (!question) {
        return EMPTY;
      }

      const questionUpdate = {
        order: size(_targetGroup.questions),
        ...(typeof targetOrder !== 'undefined' &&
          targetOrder >= 0 && {
            order: targetOrder
          }),
        questionGroupId: action.targetQuestionGroupId
      };

      question = {
        ...question,
        ...questionUpdate
      };

      const sourceGroupUpdate$ = of(_sourceGroup).pipe(
        RxOp.map(group => {
          const { questions, ...rest } = group;
          return {
            ...rest,
            questions: sortingItemsNormalizer(
              filter(questions, (q: IQuestion) => q.id !== action.questionId)
            )
          };
        })
      );

      const targetGroupUpdate$ = of(_targetGroup).pipe(
        RxOp.map(group => {
          const { questions, ...rest } = group;

          const nextQuestions = [...(questions && questions), question];

          return {
            ...rest,
            questions: sortingItemsNormalizer(
              typeof targetOrder !== 'undefined' && targetOrder >= 0
                ? move(nextQuestions, nextQuestions.length - 1, targetOrder)
                : nextQuestions,
              true
            )
          };
        })
      );

      return forkJoin([sourceGroupUpdate$, targetGroupUpdate$]).pipe(
        RxOp.mergeMap(([sourceGroup, targetGroup]) => {
          const updateSourceGroupQuestionOrder$ = of({
            type: 'project-groups/update-questions-order',
            questionGroupId: sourceGroup.id,
            questions: sourceGroup.questions
          });

          const updateTargetGroupQuestionOrder$ = of({
            type: 'project-groups/update-questions-order',
            questionGroupId: targetGroup.id,
            questions: targetGroup.questions
          });

          const updateSourceGroup$ = of({
            type: 'project-groups/update-group',
            dataUpdate: sourceGroup,
            cache: false
          });

          const updateTargetGroup$ = of({
            type: 'project-groups/update-group',
            dataUpdate: targetGroup,
            cache: false
          });

          const saveQuestion$ = of({
            type: 'project-groups/save-question',
            question
          });

          const saveQuestionFailWatcher$ = action$.pipe(
            ofType('project-groups/save-question-fail')
          );

          return merge(
            action$.pipe(
              ofType('project-groups/save-question-success'),
              RxOp.mergeMap(() => {
                const ordersUpdated$ = zip(
                  action$.pipe(
                    ofType('project-groups/update-questions-order'),
                    RxOp.filter(
                      _action => _action.questionGroupId === sourceGroup.id
                    )
                  ),
                  action$.pipe(
                    ofType('project-groups/update-questions-order'),
                    RxOp.filter(
                      _action => _action.questionGroupId === targetGroup.id
                    )
                  )
                );

                return merge(
                  ordersUpdated$.pipe(
                    RxOp.mergeMap(() =>
                      merge(updateSourceGroup$, updateTargetGroup$)
                    )
                  ),
                  updateSourceGroupQuestionOrder$,
                  updateTargetGroupQuestionOrder$
                );
              }),
              RxOp.take(4),
              RxOp.takeUntil(saveQuestionFailWatcher$)
            ),
            saveQuestion$
          );
        })
      );
    })
  );

export default moveQuestionToGroup;
