import without from 'lodash/without';

import type { AccessSettingsState } from 'flow-types/states/ProjectsState/detail';
import type { AccessSettingsAction } from '../../flow-types/actions/projects/detail/accessRules';

const initialState: AccessSettingsState = {
  data: null,
  isLoading: false,
  isMutating: false,
  isLoadingFor: []
};

export default function accessSettings(
  state: AccessSettingsState = initialState,
  action: AccessSettingsAction
) {
  switch (action.type) {
    case 'project/fetch-access-settings':
      return {
        ...state,
        // TODO: for now we can only fetch one access settings at the same time
        isLoadingFor: [action.settingsId]
      };

    case 'project/fetch-access-settings-fail':
    case 'project/fetch-access-settings-success':
      return {
        ...state,
        isLoadingFor: without([...state.isLoadingFor], action.settingsId)
      };

    case 'project/fetch-access-settings-list':
      return {
        ...state,
        isLoading: true
      };

    case 'project/fetch-access-settings-list-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project/fetch-access-settings-list-success':
      return {
        ...state,
        data: action.data,
        isLoading: false
      };

    case 'project/remove-access-settings':
    case 'project/save-access-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/remove-access-settings-fail':
    case 'project/save-access-settings-fail':
      return {
        ...state,
        isMutating: false
      };

    // TODO: on [remove/save]-access-settings-success we should refresh our access settings list,
    //  with that purpose fetchAccessSettingsList epic already watches these events
    case 'project/remove-access-settings-success':
    case 'project/save-access-settings-success':
      return {
        ...state,
        isMutating: false,
        isLoading: true
      };

    default:
      return state;
  }
}
