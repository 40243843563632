import revertDictionary from 'utils/revertDictionary';

const projectDictionary = {
  'project.tabs.settings': {
    en: 'Settings',
    ru: 'Настройки'
  },
  'project.tabs.variables': {
    en: 'Variables',
    ru: 'Переменные'
  },
  'project.tabs.editor': {
    en: 'Editor',
    ru: 'Конструктор'
  },
  'project.tabs.reports': {
    en: 'Reports',
    ru: 'Отчёты'
  },
  'project.tabs.pollingNotifications': {
    en: 'Polling Notifications',
    ru: 'Рассылка опросов'
  },
  'project.tabs.responses': {
    en: 'Responses',
    ru: 'Записи'
  },
  'project.tabs.export': {
    en: 'Export',
    ru: 'Экспорт'
  },
  'project.tabs.response': {
    en: 'Response #{what}',
    ru: 'Встреча'
  },
  'project.tabs.logic': {
    en: 'Logic',
    ru: 'Логика'
  },
  'project.tabs.test': {
    en: 'Test',
    ru: 'Тест'
  },
  'project.tabs.start': {
    en: 'Create record',
    ru: 'Создать запись'
  },
  'project.export.responses.fileName': {
    en: 'report',
    ru: 'отчёт'
  },
  'project.actions.delete': {
    en: 'Submit to delete a project',
    ru: 'Вы действительно хотите удалить текущий проект?'
  },
  'project.page.title.saved': {
    en: 'Project #{projectId}',
    ru: 'Проект #{projectId}'
  },
  'project.page.title.new': {
    en: 'New project',
    ru: 'Новый проект'
  }
};

export default revertDictionary(projectDictionary);
