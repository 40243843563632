import * as React from 'react';
import isMobile from 'is-mobile';

export default function useIsMobile(config) {
  const isMobileRef = React.useRef(isMobile(config));

  // This way it will re-check on every render
  React.useLayoutEffect(() => {
    isMobileRef.current = isMobile(config);
  });

  return isMobileRef;
}
