// @flow
import fp from 'lodash/fp';
import isPlainObject from 'lodash/isPlainObject';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import { decamelizeKeys } from 'humps';
import type {
  ICompanyAdmin,
  IDivisionHead,
  IManager,
  IOperator,
  IUser
} from '../../../flow-types/entities/User';
import { DIVISION_HEAD, MANAGER } from '../../helpers/user';

type UserType = IUser | ICompanyAdmin | IDivisionHead | IOperator | IManager;

const userCompaniesTreeDenormalizer = (user: UserType) => {
  const { companiesTree } = user;

  return {
    ...user,
    companies: map(companiesTree, c => ({
      id: isPlainObject(c) ? c.id : c
    })),
    departments: reduce(
      companiesTree,
      (result, company) => {
        const { departments } = company;

        if (!Array.isArray(departments)) return result;

        return [...result, ...map(departments, d => ({ id: d.id || d.value }))];
      },
      []
    )
  };
};

const userDepartmentsDenormalizer = (user: UserType) => {
  const { departmentsIds } = user;

  return {
    ...user,
    departments: map(departmentsIds, departmentId => ({ id: departmentId }))
  };
};

const userDivisionHeadDenormalizer = fp.compose(userDepartmentsDenormalizer);

const userManagerDenormalizer = fp.compose(userCompaniesTreeDenormalizer);

const userRoleSpecificDenormalizer = (user: UserType) => {
  if (user.roleId === MANAGER) {
    return userManagerDenormalizer(user);
  }

  if (user.roleId === DIVISION_HEAD) {
    return userDivisionHeadDenormalizer(user);
  }

  return user;
};

export const userDenormalizer = fp.compose(
  decamelizeKeys,
  fp.omit(['company', 'department']),
  userRoleSpecificDenormalizer,
  user => {
    const { companyId, departmentId } = user;
    return {
      ...user,
      ...(!!companyId &&
        typeof companyId === 'string' && {
          companyId: +companyId
        }),
      ...(!!departmentId &&
        typeof departmentId === 'string' && {
          departmentId: +departmentId
        })
    };
  }
);

export default fp.map(userDenormalizer);
