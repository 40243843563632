// @flow
import { array } from 'yup';

import type { IUpload } from '../../flow-types/entities/Upload';

type ValidateAttachments$Config = {|
  min: number,
  max: number,
  enabled?: boolean
|};

const initialConfig: ValidateAttachments$Config = {
  enabled: false,
  max: 0,
  min: 0
};

const createValidator = (min: number, max: number) => (
  attachments: IUpload[]
) => {
  let validator = array();

  if (min && min > 0) {
    validator = validator.min(min, {
      messageId: 'question.validation.attachments.min',
      values: {
        count: min
      }
    });
  }

  if (max && max > 0) {
    validator = validator.max(max, {
      messageId: 'question.validation.attachments.max',
      values: {
        count: max
      }
    });
  }

  return validator.validateSync(attachments, { abortEarly: false });
};

export default function validateAttachments(
  attachments: IUpload[],
  config: ValidateAttachments$Config = initialConfig
): Object | null {
  if ((config.min === 0 && config.max === 0) || (!config.min && !config.max)) {
    return null;
  }

  return createValidator(config.min, config.max)(attachments);
}
