/* eslint-disable react/no-array-index-key */
// @flow

// NPM imports
import React, { useCallback } from 'react';
import map from 'lodash/map';
import lengthOf from 'lodash/size';
import pullAt from 'lodash/pullAt';

import type { Node } from 'react';

import { FormattedMessage } from 'react-intl';
import { isTestsGroupingOperator } from 'common/transducers/logic/isTestsGroupingOperator';
import type {
  ICheckOperator,
  IUnionOperator
} from 'flow-types/entities/FilterSettings';
import type { ValidationIntlMessage } from 'flow-types/Validation';

import Message from 'common/components/Message';
import { LOGIC_OPERAND } from 'common/helpers/logic/constants';
import { generateNewData } from '../../helpers/generateNewData';
import Button from '../../components/Button';

import TestForm from './TestForm';
import UnionOperatorForm from './UnionOperatorForm';

import type {
  DataItem,
  ITestSource,
  OnChangeCb,
  OnRemoveCb,
  SourcesProp
} from './flow';

type Props = {
  isDisabled: boolean,
  data: Array<DataItem>,
  excludedUnionOperators?: IUnionOperator[],
  excludedCheckOperators?: ICheckOperator[],
  // is called whenever some path is changed
  onChange?: null | OnChangeCb,
  onRemove?: null | OnRemoveCb,
  // is called when the whole data is changed
  onDataChange?: null | Function,
  onCreate?: null | Function,
  validation?: null | {
    title: ValidationIntlMessage<Object>[],
    tests: ValidationIntlMessage<Object>[]
  },
  size: string,
  showAddButton?: boolean,
  padded?: string
} & SourcesProp;

export default function TestsForm({
  data,
  sources,
  onChange,
  onRemove,
  onDataChange,
  onCreate,
  isDisabled,
  excludedCheckOperators,
  excludedUnionOperators,
  showAddButton,
  validation,
  size,
  padded
}: Props): Node {
  const handleChange = useCallback<OnChangeCb>(
    (path, dataUpdate) => {
      if (onChange) {
        onChange(path, dataUpdate);
      } else if (onDataChange) {
        const nextData = Array.isArray(data) ? [...data] : [];

        if (isTestsGroupingOperator(nextData[path])) {
          nextData[path] = dataUpdate;
        } else {
          nextData[path] = {
            ...nextData[path],
            ...dataUpdate
          };
        }

        onDataChange(nextData);
      }
    },
    [data, onChange, onDataChange]
  );

  const handleRemove = useCallback<OnRemoveCb>(
    (path, testData) => {
      if (onRemove) {
        onRemove(path, testData);
      } else if (onDataChange) {
        const nextData = Array.isArray(data) ? [...data] : [];

        if (path === 0) {
          // we can remove only tests,
          // thus it is safe
          pullAt(nextData, [path, +path + 1]);
        } else {
          pullAt(nextData, [path, +path - 1]);
        }

        onDataChange(nextData);
      }
    },
    [data, onDataChange, onRemove]
  );

  const handleCreate = useCallback(() => {
    const nextData: ITestSource = generateNewData();

    if (onCreate) {
      onCreate(nextData);
    } else if (onDataChange) {
      if (lengthOf(data) === 0) {
        onDataChange([nextData]);
      } else {
        onDataChange([...data, LOGIC_OPERAND.AND, nextData]);
      }
    }
  }, [data, onCreate, onDataChange]);

  return (
    <>
      {Array.isArray(validation) &&
        validation.map((message, index) => {
          if (typeof message === 'string') {
            return (
              <Message key={index} status="error">
                {message}
              </Message>
            );
          }

          if (!message?.messageId) return null;

          return (
            <Message key={index} visible status="error">
              <FormattedMessage id={message.messageId} />
            </Message>
          );
        })}
      <div className={`ui compact ${padded ? `${padded} padded ` : ''}grid`}>
        {map(data, (test: DataItem, index) => {
          const innerValidation = validation ? validation[index] : null;

          if (typeof test === 'string') {
            return (
              <UnionOperatorForm
                size={size}
                disabled={isDisabled}
                key={`test-${index}`}
                index={index}
                validation={innerValidation}
                data={test}
                onChange={handleChange}
                onRemove={handleRemove}
                excluded={excludedUnionOperators}
              />
            );
          }

          return (
            <TestForm
              size={size}
              key={`test-${index}`}
              disabled={isDisabled}
              validation={innerValidation}
              index={index}
              data={test}
              sources={sources}
              onChange={handleChange}
              onRemove={handleRemove}
              excludedCheckOperators={excludedCheckOperators}
              excludedUnionOperators={excludedUnionOperators}
            />
          );
        })}
        {!!showAddButton && (
          <div className="row">
            <div className="sixteen wide column">
              <Button
                fluid
                type="button"
                size="mini"
                onClick={handleCreate}
                disabled={isDisabled}
              >
                <FormattedMessage id="testsForm.actions.addTest" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

TestsForm.defaultProps = {
  excludedUnionOperators: [],
  excludedCheckOperators: [],
  onRemove: null,
  onCreate: null,
  onChange: null,
  onDataChange: null,
  validation: null,
  showAddButton: true,
  padded: null
};
