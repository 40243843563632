import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { decamelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import React from 'react';
import type { ChangePassword } from '../../flow-types/actions/auth/ChangePassword';
import { API } from '../../utils/config';
import request from '../../utils/request';
import createSingOut from '../../actions/auth/signOut';

export default function passwordChange(action$) {
  return action$.pipe(
    ofType('auth/change-password'),
    RxO.mergeMap(({ credentials }: ChangePassword) =>
      request({
        url: API.password.fastChange,
        body: decamelizeKeys(credentials),
        method: 'PUT'
      }).pipe(
        RxO.mergeMap(() =>
          merge(
            of({ type: 'auth/change-password-success' }),
            of({}).pipe(
              RxO.tap(() => {
                toast.info(
                  <div>
                    <p>Ваш пароль успешно обновлён!</p>
                    <p>Выход из системы через 4 сек...</p>
                  </div>,
                  {
                    autoClose: 4000,
                    position: 'bottom-center'
                  }
                );
              }),
              RxO.delay(4500),
              RxO.map(() => createSingOut({ manual: true }))
            )
          )
        ),
        RxO.catchError(() => of({ type: 'auth/change-password-fail' }))
      )
    )
  );
}
