// @flow

import type { SearchSettingsState } from 'flow-types/states/ProjectsState/detail';
import type { SearchSettingsAction } from '../../flow-types/actions/projects/detail/searchSettings';
import type { ResetProject } from '../../flow-types/actions/projects/detail/project/ResetProject';

const initialState: SearchSettingsState = {
  data: null,
  isMutating: false,
  isLoading: false
};

export default function searchSettings(
  state: SearchSettingsState = initialState,
  action: SearchSettingsAction | ResetProject
): SearchSettingsState {
  switch (action.type) {
    case 'project/reset':
      return initialState;

    case 'project/fetch-search-settings':
      return {
        ...state,
        isLoading: true
      };

    case 'project/fetch-search-settings-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project/fetch-search-settings-success':
      return {
        ...state,
        data: action.data,
        isLoading: false
      };

    case 'project/save-search-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/save-search-settings-fail':
      return {
        ...state,
        isMutating: false
      };

    case 'project/save-search-settings-success':
      return {
        ...state,
        isMutating: false,
        data: action.searchSettings
      };

    case 'project/remove-search-settings':
      return {
        ...state,
        isMutating: true
      };

    case 'project/remove-search-settings-fail':
      return {
        ...state,
        isMutating: false
      };

    case 'project/remove-search-settings-success':
      return {
        ...state,
        data: initialState.data,
        isMutating: false
      };

    default:
      return state;
  }
}
