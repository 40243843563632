// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';

import Modal from 'common/components/animated/Modal';
import CancelButton from 'common/components/Button/CancelButton';
import SubmitButton from 'common/components/Button/SubmitButton';

const CanvasContainer = styled.div`
  min-height: 24rem;
  position: relative;
  margin: -1.5rem;

  * {
    user-select: none;
  }

  & > .signature-canvas-container__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  .signature-canvas-container-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .signature-canvas__canvas {
    width: 100%;
    height: 100%;
  }
`;

type SignatureModalProps = {
  visible: boolean,
  loading: boolean,
  // TODO: resolve later
  onCancel: Function,
  // TODO: resolve later
  onSubmit: Function
};

export default function SignatureModal({
  visible,
  loading,
  onCancel,
  onSubmit
}: SignatureModalProps): React.Node {
  const dimmerProps = React.useRef({ page: true, fixed: true });

  const canvasProps = React.useRef({
    className: 'signature-canvas__canvas'
  });

  const canvasRef = React.useRef(null);

  return (
    <Modal portal dimmerProps={dimmerProps.current} visible={visible}>
      <Modal.Header>
        <FormattedMessage id="question.signature.yourSignature" />
        <br />
        <Modal.Header sub>
          <FormattedMessage id="question.signature.hint" />
        </Modal.Header>
      </Modal.Header>
      <Modal.Content>
        <CanvasContainer>
          <div className="signature-canvas-container__inner">
            <div className="signature-canvas-container-wrapper">
              <SignatureCanvas
                ref={ref => {
                  canvasRef.current = ref;
                }}
                canvasProps={canvasProps.current}
                perColor="blue"
              />
            </div>
          </div>
        </CanvasContainer>
      </Modal.Content>
      <Modal.Actions>
        <CancelButton onClick={onCancel} />
        <SubmitButton
          loading={loading}
          onClick={() => {
            canvasRef.current.getTrimmedCanvas().toBlob((blob: File) => {
              onSubmit(blob);
            });
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
