import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import { logicRulesNormalizer } from 'common/transducers/projects/logicNormalizer';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import type { ProjectLogicState } from 'flow-types/states/ProjectsState/detail';
import {
  projectIdFromPathSelector,
  projectLogicStateSelector
} from '../../../selectors/projects';

const fetchProjectLogicEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project/fetch-logic'),
    RxO.withLatestFrom($state),
    RxO.map(([action, state]) => {
      if (action.projectId) {
        return [action.projectId, state];
      }

      return [projectIdFromPathSelector(state), state];
    }),
    RxO.switchMap(([projectId, state]) => {
      const logicState: ProjectLogicState = projectLogicStateSelector(state);

      const { filter } = logicState;

      return request({
        url: interpolateString(
          API.projects.projectLogic,
          { project_id: projectId },
          ':'
        ),
        query: decamelizeKeys(filter),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map(rules => logicRulesNormalizer(rules)),
        RxO.map(rules => ({
          type: 'project/fetch-logic-success',
          logic: rules
        })),
        RxO.catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({ type: 'project/fetch-logic-fail' });
        })
      );
    })
  );

// export const logicFetcherProjectFetchCatcher = $action =>
//   $action.pipe(
//     ofType('project/fetch'),
//     RxO.mapTo({ type: 'project/fetch-logic', filter: { ps: 10, pn: 0 } })
//   );

export default fetchProjectLogicEpic;
