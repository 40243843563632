// @flow
import qs from 'qs';
import reduce from 'lodash/reduce';
import { connectRoutes } from 'redux-first-router';

import type { Bag, GetState } from 'redux-first-router/dist/flow-types.js.flow';
import type { Route } from 'flow-types/Route';

import { routes } from './routes';

const routesMap = reduce(
  routes,
  (result, currentRoute, routeKey: string) => {
    const { config, ignoreAsRoute } = currentRoute;
    if (ignoreAsRoute) {
      return result;
    }
    return {
      ...result,
      [routeKey]: config
    };
  },
  {}
);

export const options = {
  querySerializer: qs,
  // CAUTION!!!: it doesn't start epic
  onBeforeChange: (dispatch, getState: Function, bag: Bag, ...rest) => {
    const route: Route = routes[bag.action.type];

    if (route.onBeforeChange) {
      route.onBeforeChange(dispatch, getState, bag, ...rest);
    }
  },
  onAfterChange: (dispatch, getState: GetState, bag: Bag, ...rest) => {
    const route: Route = routes[bag.action.type];
    if (route.onAfterChange) {
      route.onAfterChange(dispatch, getState, bag, ...rest);
    }
  }
};

const connectedRoutes = connectRoutes(routesMap, options);

export default connectedRoutes;
