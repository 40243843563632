import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { of } from 'rxjs';
import { decamelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import { projectResponseStateSelector } from '../../../selectors/projects/response';

import {
  saveResponseCommentFail,
  saveResponseCommentSuccess
} from '../../../actions/project/response/saveResponseСomment';

const saveResponseComment: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/save-response-comment'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([, state]) => {
      const { data } = projectResponseStateSelector(state);

      const { reviewerComment, id } = data;

      return request({
        url: interpolateString(
          API.responses.comment,
          decamelizeKeys({ responseId: id })
        ),
        method: 'PUT',
        body: decamelizeKeys({ reviewerComment })
      }).pipe(
        RxO.map(
          () => saveResponseCommentSuccess(),
          RxO.catchError(() => {
            toast.error('Не удалось обновить комментарий по встрече', {
              autoClose: 1500,
              closeButton: true
            });

            return of(saveResponseCommentFail());
          })
        )
      );
    })
  );

export default saveResponseComment;
