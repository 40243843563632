// @flow
import { combineEpics } from 'redux-observable';

// eslint-disable-next-line import/order
import passwordResetEpic from './passwordReset';
import passwordChange from './passwordChange';
import identifyOnSignInSuccess from './indetifyOnSignInSuccess';
import { signOutEpic } from './signOut';
import { signInEpic } from './signIn';
import { signInWithHashEpic } from './signInWithHash';
import { signInWithTokenEpic } from './signInWithToken';
import { signInSuccessEpic } from './redirectOnSignInSuccess';

export default combineEpics(
  signInEpic,
  signOutEpic,
  signInSuccessEpic,
  passwordResetEpic,
  signInWithTokenEpic,
  signInWithHashEpic,
  passwordChange,
  identifyOnSignInSuccess
);
