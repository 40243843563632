// @flow

import get from 'lodash/get';
import { camelizeKeys } from 'humps';
import request from 'utils/request';
import { API } from 'utils/config';

import type { Thunk } from 'flow-types/router/Thunk';
import type { IErrorResponse } from 'flow-types/ErrorResponse';

export type INotExistError = IErrorResponse<any> & {|
  code: 403
|};

export type IAccessCloseError = IErrorResponse<any> & {|
  code: 410,
  message: 'Access is closed'
|};

export type IAccessExpiredError = IErrorResponse<any> & {|
  code: 410,
  message: 'Access expired'
|};

export type INoUserDataError = IErrorResponse<any> & {
  code: '404.0'
};

export type INoHashError = IErrorResponse<any> & {
  code: '404.1'
};

export const ERROR_CODES = {
  NO_DATA: '404.0',
  NO_HASH: '404.1'
};

const signInWithHashThunk: Thunk = async ({
  dispatch,
  bag,
  onError,
  onSuccess,
  routeName
}) => {
  const {
    action: { meta: actionMeta }
  } = bag;

  const hash = get(actionMeta, 'query.hash', null);

  function failed(error = null) {
    if (onError) {
      onError(error);
    }

    dispatch({ type: 'auth/sign-in-fail' });
  }

  function success(data) {
    const { accessToken: token, user, ...meta } = data;

    if (onSuccess) {
      onSuccess(data);
    }

    dispatch({
      type: 'auth/sign-in-success',
      user: user ? camelizeKeys(user) : null,
      accessToken: token,
      rememberMe: false,
      scope: [routeName],
      meta
    });
  }

  if (!hash) {
    const error = new Error('No hash');

    // $FlowIgnore
    error.code = ERROR_CODES.NO_HASH;

    failed(error);

    return true;
  }
  // here we get token for hash,
  // we save it locally and set clearOnReload to true

  try {
    const hashResponse = await request({
      url: API.hashLogin,
      method: 'POST',
      body: {
        hash
      },
      headers: {
        Authorization: null
      }
    }).toPromise();

    const {
      response: { data }
    } = hashResponse;

    if (!data) {
      const error = new Error('No user data');

      // $FlowIgnore
      error.code = ERROR_CODES.NO_DATA;

      failed(error);
      return true;
    }

    const parsed = camelizeKeys(data);

    success(parsed);

    return false;
  } catch (e) {
    failed(e);
    return true;
  }
};

export default signInWithHashThunk;
