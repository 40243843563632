// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import type {
  FetchPollingNotifications,
  FetchPollingNotificationsFail,
  FetchPollingNotificationsSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/list/FetchPollingNotifications';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeKeys } from 'humps';
import debounceEpic from 'common/epicHelpers/debounceEpic';
import { createSelector } from 'reselect';
import type { PollingNotificationsState } from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import normalizePagination from 'common/transducers/paginationNormalizer';
import type { AppState } from 'flow-types/AppState';
import {
  projectIdFromPathSelector,
  projectPollingNotificationsStateSelector
} from '../../../../../selectors/projects';
import {
  FETCH_LIST,
  FETCH_LIST_FAIL,
  FETCH_LIST_SUCCESS
} from '../../../../../reducers/projectDetail/pollingNotifications/list';

const filterSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (state: PollingNotificationsState) => state.list.filter
);

const fetchPollingNotifications$: Epic = (action$, state$) =>
  action$.pipe<FetchPollingNotifications>(
    ofType(FETCH_LIST),
    debounceEpic(),
    RxO.withLatestFrom(state$),
    RxO.switchMap<[FetchPollingNotifications, AppState], any, any>(
      ([, state]) => {
        const projectId = projectIdFromPathSelector(state);

        const filter = filterSelector(state);

        return request({
          url: interpolateString(API.projects.pollingNotifications.list, {
            projectId
          }),
          query: filter,
          queryParserConfig: {
            strictNullHandling: true,
            strictStringHandling: true
          },
          method: 'GET'
        }).pipe(
          responseParser,
          RxO.map((rawData): FetchPollingNotificationsSuccess => {
            const pagination = normalizePagination(rawData.pagination);

            return {
              type: FETCH_LIST_SUCCESS,
              data: camelizeKeys(rawData.data),
              pagination
            };
          }),
          RxO.catchError((): [FetchPollingNotificationsFail] => [
            { type: FETCH_LIST_FAIL }
          ])
        );
      }
    )
  );

export default fetchPollingNotifications$;
