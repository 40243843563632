// @flow
import { TYPES } from 'common/helpers/question';
import type { IQuestion } from 'flow-types/entities/Question';

export default function showExpandButton(question?: IQuestion) {
  if (!question) return false;

  switch (question.type) {
    case TYPES.MultipleAnswer:
    case TYPES.SingleAnswer:
    case TYPES.Checklist:
    case TYPES.Ranging:
      return !!question.showImages;

    case TYPES.Table:
      return question.settings?.viewType === 'table';

    default:
      return false;
  }
}
