// @flow

import type { ModalId, OpenModal } from '../../pages/Modals/modals';

export default function openModal(
  modalId: ModalId,
  initialData: mixed
): OpenModal {
  return {
    type: 'modals/open',
    modalId,
    data: initialData
  };
}
