import values from 'lodash/values';
import findIndex from 'lodash/findIndex';
import pullAt from 'lodash/pullAt';

/**
 * Orders items according to sequence array, containing ordered values,
 * that are stored in some specific property of each items list element.
 * @param items
 * @param sequence
 * @param fieldName
 */
export default function orderBySequence(
  items: Array<Object>,
  sequence: Array<mixed>,
  fieldName: string = 'id'
) {
  if (!Array.isArray(sequence) || sequence.length === 0) {
    return items;
  }

  if (!Array.isArray(items)) {
    return orderBySequence(values(items), sequence);
  }

  const cache = [...items];

  const sortable = [];

  sequence.forEach(optionId => {
    const optionIndex = findIndex(cache, { [fieldName]: optionId });

    if (optionIndex === -1) return;

    if (cache[optionIndex].hidden) return;

    sortable.push(cache[optionIndex]);

    pullAt(cache, [optionIndex]);
  });

  // items, that were not in sequence
  return sortable.concat(cache).filter(o => !o.hidden);
}
