// @flow

import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { AjaxError } from 'rxjs/ajax';
import interpolateString from 'common/helpers/interpolateString';

import request from 'utils/request';
import { API } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import type {
  RemoveFilterSettings,
  RemoveFilterSettingsFail,
  RemoveFilterSettingsSuccess
} from 'flow-types/actions/projects/detail/filtersSettings/RemoveFilterSettings';
import type { FetchResponses } from 'flow-types/actions/projects/detail/responses/FetchResponses';
import type { AppState } from 'flow-types/AppState';
import closeModal from '../../../../actions/modals/close';
import {
  projectIdFromPathSelector,
  projectResponsesStateSelector
} from '../../../../selectors/projects';

const removeUserFilterSettings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/remove-filter-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.concatMap(
      ([action, state]: [RemoveFilterSettings, AppState]) => {
        const projectId = projectIdFromPathSelector(state);

        return request({
          url: interpolateString(API.projects.userFilterSettings.detail, {
            projectId,
            settingsId: action.settingsId
          }),
          method: 'DELETE'
        }).pipe(
          RxOperators.mergeMap(() =>
            [
              action.modalId && closeModal(action.modalId),
              ({
                type: 'project/remove-filter-settings-success',
                settingsId: action.settingsId
              }: RemoveFilterSettingsSuccess)
            ].filter(Boolean)
          ),
          RxOperators.catchError((ajaxError: AjaxError) => [
            ({
              type: 'project/remove-filter-settings-fail',
              settingsId: action.settingsId,
              message: ajaxError.message,
              response: ajaxError.response
            }: RemoveFilterSettingsFail)
          ])
        );
      }
    )
  );

export const resetUserFilterOnRemoveSuccess: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/remove-filter-settings-success'),
    RxOperators.withLatestFrom(state$),
    RxOperators.filter(
      ([action, state]: [RemoveFilterSettingsSuccess, AppState]) => {
        const { settingsId } = action;
        const { filter } = projectResponsesStateSelector(state);

        return filter.userFilterSettingId === settingsId;
      }
    ),
    RxOperators.map(([, state]: [RemoveFilterSettingsSuccess, AppState]) => {
      const { filter } = projectResponsesStateSelector(state);

      const nextFilter = { ...filter };

      delete nextFilter.userFilterSettingId;

      return ({
        type: 'project-responses/fetch',
        filter: nextFilter
      }: FetchResponses);
    })
  );

export default removeUserFilterSettings;
