// @flow

import React, { createContext, useContext } from 'react';
import type { Node } from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';

type IAnswers = {
  [key: string | number]: IInterviewAnswer
};

const AnswersContext = createContext<IAnswers>({});

type ProviderProps = {|
  answers: IAnswers,
  children: ReactChildren
|};

/**
 * Broadcasts passed answers to subscribed components
 * @param answers
 * @param children
 * @returns {*}
 * @constructor
 */
const AnswersContextProvider = ({ answers, children }: ProviderProps): Node => (
  <AnswersContext.Provider value={answers}>{children}</AnswersContext.Provider>
);

export const useAnswers = (): IAnswers => useContext(AnswersContext);

const fallbackAnswer: IInterviewAnswer = {
  data: null,
  cache: null,
  comments: [],
  error: null,
  errors: null
};

export const useAnswer = (
  questionId: number,
  fallbackValue: $Shape<IInterviewAnswer> = fallbackAnswer
): IInterviewAnswer => {
  const ctx = useAnswers();

  return ctx?.[questionId] ?? fallbackValue;
};

export default AnswersContextProvider;
