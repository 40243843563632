import type { Epic } from 'redux-observable';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import qs from 'qs';
import { toast } from 'react-toastify';
import request from '../../utils/request';
import { API } from '../../utils/config';
import type { Action } from '../../flow-types/actions/companies';
import type { IDepartment } from '../../flow-types/entities/Department';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

const saveDepartmentEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/save-department'),
    map(() => {
      const {
        companies: { departmentsList }
      } = $state.value;
      const {
        form: { data }
      } = departmentsList;

      return data;
    }),
    mergeMap((department: IDepartment) => {
      const isUpdate = !!department.id;

      if (!department.companyId) {
        return of({
          type: 'companies/save-department-fail',
          error: {
            companyId: ['Company field is required']
          }
        });
      }

      return request({
        method: isUpdate ? 'PUT' : 'POST',
        body: decamelizeKeys(department),
        url: isUpdate
          ? API.departments.detail
              .replace(':company_id', department.companyId)
              .replace(':department_id', department.id)
          : API.departments.list.replace(':company_id', department.companyId)
      }).pipe(
        // here we dispatch two actions one after another
        mergeMap(() =>
          of(
            { type: 'companies/save-department-success' },
            { type: 'companies/fetch-departments' }
          )
        ),
        // this is our ajax request error handler
        catchError((errorResponse: AjaxError) => {
          const { message, response, status } = errorResponse;

          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );

          return of({
            type: 'companies/save-department-fail',
            ...(status === 422 && {
              error: camelizeKeys(response.data)
            }),
            ...(status !== 422 && {
              error: response ? response.error : message
            })
          });
        })
      );
    })
  );

const deleteDepartmentEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/delete-department'),
    map(action => action.department),
    mergeMap(({ id, companyId }: IDepartment) =>
      request({
        method: 'DELETE',
        url: API.departments.detail
          .replace(':company_id', companyId)
          .replace(':department_id', id)
      }).pipe(
        mergeMap(() =>
          of(
            { type: 'companies/delete-department-success' },
            { type: 'companies/fetch-departments' }
          )
        ),
        catchError((errorResponse: AjaxError) => {
          const { response, message } = errorResponse;

          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'companies/delete-department-fail',
            error: response ? response.error : message // we pass either response, or message
          });
        })
      )
    )
  );

const fetchDepartmentsEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('companies/fetch-departments'),
    map((action: Action) => {
      const {
        companies: {
          departmentsList: { filter: departmentsFilter },
          companiesList: { selected }
        }
      } = $state.value;
      const { filterUpdate } = action;

      return {
        filter: {
          ...departmentsFilter,
          ...filterUpdate
        },
        company: selected
      };
    }),
    filter(({ company }) => !!company),
    debounceEpic(),
    mergeMap(({ filter: finalFilter, company }) => {
      const params = qs.stringify(finalFilter, {
        addQueryPrefix: true,
        skipNulls: true
      });

      const url = API.departments.list.replace(':company_id', company.id);

      return request({
        method: 'GET',
        url: `${url}${params}`
      }).pipe(
        map((response: AjaxResponse) => {
          const {
            response: {
              data,
              pagination: {
                total: totalElements,
                pages: totalPages,
                current: activePage
              } = {}
            }
          } = response;

          return {
            type: 'companies/fetch-departments-success',
            data: camelizeKeys(data),
            pagination: {
              totalElements,
              totalPages,
              activePage
            }
          };
        }),
        catchError((errorResponse: AjaxError) => {
          const { response, message } = errorResponse;
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'companies/fetch-departments-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

// Epic starts when we select company in first column,
// so it starts refetching of data in second column
// Also we should reset page number when (re-)selecting company
const selectDepartmentEpic: Epic = $action =>
  $action.pipe(
    ofType('companies/select-department'),
    mergeMap(() =>
      of({
        type: 'companies/fetch-users',
        filterUpdate: {
          pn: 0
        }
      })
    )
  );

export default combineEpics(
  fetchDepartmentsEpic,
  saveDepartmentEpic,
  deleteDepartmentEpic,
  selectDepartmentEpic
);
