// @flow
import fp from 'lodash/fp';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { camelizeKeys } from 'humps';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import { API_PREFIX } from 'utils/config';

import sortingItemsNormalizer from '../sortingItemsNormalizer';
import { normalizeQuestions } from './questions/questionsNormalizer';

export const mapQuestionGroupQuestions = (
  questionGroup: IQuestionGroup
): IQuestionGroup => {
  const { questions } = questionGroup;

  const mappedQuestions = reduce(
    questions,
    (result, question, index) => ({
      ...result,
      [question.id]: index
    }),
    {}
  );

  return {
    ...questionGroup,
    mappedQuestions
  };
};

export const processLogoPathSrcForQuestionGroup = (
  questionGroup: IQuestionGroup
): IQuestionGroup => {
  const { logoInfo } = questionGroup;

  const logoInfoPath = get(logoInfo, 'path', '');

  const logoInfoFilename = get(logoInfo, 'filename', '');

  const logoPathSrc = `${logoInfoPath}${logoInfoFilename}`;

  const logoSrc = `${API_PREFIX}/uploads${logoPathSrc}`;

  return {
    ...questionGroup,
    ...(logoInfo && {
      logoSrc,
      logoPathSrc
    })
  };
};

export const normalizeQuestionGroupQuestions = (
  questionGroup: IQuestionGroup
): IQuestionGroup => {
  const { questions } = questionGroup;

  return {
    ...questionGroup,
    questions: normalizeQuestions(questions)
  };
};

// const normalizeQuestionGroup = (group: IQuestionGroup) => {
//   const logoInfoPath = get(group, 'logoInfo.path', '');
//   const logoInfoFilename = get(group, 'logoInfo.filename', '');
//
//   const logoPathSrc = `${logoInfoPath}${logoInfoFilename}`;
//
//   const mappedQuestions = reduce(
//     group.questions,
//     (result, question, index) => {
//       return {
//         ...result,
//         [question.id]: index
//       };
//     },
//     {}
//   );
//
//   return {
//     ...group,
//     ...(group.logoInfo && {
//       logoSrc: `${API_PREFIX}/uploads${logoPathSrc}`,
//       logoPathSrc
//     }),
//     questions: normalizeQuestions(group.questions),
//     mappedQuestions
//   };
// };

export const normalizeQuestionGroup = fp.compose(
  processLogoPathSrcForQuestionGroup,
  mapQuestionGroupQuestions,
  normalizeQuestionGroupQuestions
);

export const camelizeAndNormalizeQuestionGroup = fp.compose(
  normalizeQuestionGroup,
  camelizeKeys
);

const questionGroupsNormalizer = fp.compose(
  sortingItemsNormalizer,
  fp.map(camelizeAndNormalizeQuestionGroup)
);

export default questionGroupsNormalizer;
