import tinycolor from 'tinycolor2';

export function createColorFieldParser(field = null) {
  return value => {
    if (!field || !value || typeof value !== 'object') {
      return value;
    }

    if (typeof value[field] === 'string' || !value[field]) return value;

    const nextValue = { ...value };

    const parsed = tinycolor(nextValue[field]).toRgbString();

    nextValue[field] = parsed;

    return nextValue;
  };
}
