import React from 'react';
import * as RxO from 'rxjs/operators';
import { toast } from 'react-toastify';
import { ofType } from 'redux-observable';
import find from 'lodash/find';

import { API } from 'utils/config';
import request from 'utils/request';

import { decamelizeAndDenormalizeDecodingTask } from 'common/transducers/uploads/decodingTasksDenormalizer';
import { camelizeAndNormalizeDecodingTask } from 'common/transducers/uploads/decodingTasksNormalizer';

import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import localizeMessage from 'common/helpers/localizeMessage';

import type { Epic } from 'flow-types/Epic';
import type {
  CreateDecodingTask,
  CreateDecodingTaskFail
} from 'flow-types/actions/records/decodings/tasks/CreateDecodingTask';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import type { IUpload } from 'flow-types/entities/Upload';

import {
  CREATE_TASK,
  CREATE_TASK_FAIL,
  CREATE_TASK_SUCCESS
} from '../../../reducers/records/decodings/tasks';

import { languageStateSelector } from '../../../selectors';
import { recordsListStateSelector } from '../../../selectors/records';

const createTask$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_TASK),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([action, state]: [CreateDecodingTask, State]) => {
      const { data } = action;

      const { data: records } = recordsListStateSelector(state);

      const record: IUpload = find(records, { id: action.data.uploadId });

      const lang = languageStateSelector(state);

      const body = decamelizeAndDenormalizeDecodingTask(data);

      return request({
        url: interpolateString(API.uploads.decodings.tasks.list, {
          uploadId: data.uploadId
        }),
        body,
        method: 'POST'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.mergeMap(rawData => [
          {
            type: CREATE_TASK_SUCCESS,
            taskId: data.id,
            uploadId: data.uploadId,
            providerId: data.providerId,
            data: camelizeAndNormalizeDecodingTask(rawData)
          }
        ]),
        RxO.catchError(({ response }: AjaxError): [CreateDecodingTaskFail] => {
          const { code, data: errorData } = response;

          if (code === 422) {
            if (
              typeof data === 'object' &&
              errorData.upload_id &&
              Array.isArray(errorData.upload_id)
            ) {
              if (
                errorData.upload_id[0] ===
                'The upload id has already been taken.'
              ) {
                toast.error(
                  <>
                    <div>
                      {localizeMessage(
                        'transcriptions.decodingTask.status.alreadyExists',
                        lang
                      )({
                        audio: `"${record?.originalFilename || data.uploadId}"`,
                        service: `"${localizeMessage(
                          `transcription.providers.${data.providerId}`,
                          lang
                        )()}"`
                      })}
                    </div>
                    <div>Обновите список дешифровок.</div>
                  </>,
                  {
                    autoClose: false,
                    showCloseButton: true
                  }
                );
              }
            } else {
              toast.error(localizeMessage('ajax.error.422', lang)(), {
                autoClose: false,
                showCloseButton: true
              });
            }
          } else if (code === 500) {
            toast.error(localizeMessage(`ajax.error.500`, lang)(), {
              autoClose: false,
              showCloseButton: true
            });
          } else {
            toast.error(localizeMessage(`ajax.error.N/A`, lang)(), {
              autoClose: false,
              showCloseButton: true
            });
          }

          return [
            {
              type: CREATE_TASK_FAIL,
              uploadId: data.uploadId,
              taskId: data.id,
              providerId: data.providerId
            }
          ];
        })
      );
    })
  );

export default createTask$;
