import React, { createContext, useCallback, useContext } from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';

const AnswerUpdateContext = createContext(null);

export function useAnswerUpdate(questionId) {
  const updaterFn = useContext(AnswerUpdateContext);

  return useCallback(
    (answerUpdate, isUsingMerge = false) => {
      updaterFn(questionId, answerUpdate, isUsingMerge);
    },
    [questionId, updaterFn]
  );
}

type Props = {|
  onUpdate: Function,
  children: ReactChildren
|};

export default function AnswerUpdateContextProvider({
  onUpdate,
  children
}: Props) {
  const memoizedCallback = useCallback(onUpdate, [onUpdate]);

  return (
    <AnswerUpdateContext.Provider value={memoizedCallback}>
      {children}
    </AnswerUpdateContext.Provider>
  );
}
