import { ofType } from 'redux-observable';
import { interval } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { TASK_STATUS } from 'common/helpers/response';
import { camelizeKeys } from 'humps';
import type { IExportTask } from 'flow-types/entities/ExportTask';
import type { WatchExportTask } from 'flow-types/actions/projects/detail/export/WatchExportTask';
import { toast } from 'react-toastify';
import React, { useMemo } from 'react';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import Link from 'common/components/Link';
import IntlMessageFormat from 'intl-messageformat';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

import projectExportPanelDictionary from '../../../../intl/projectExportPanelDictionary';
import { languageStateSelector } from '../../../../selectors';

const SuccessMessage = ({
  link,
  projectId,
  taskId,
  locale,
  closeToast
}: {|
  link: string,
  projectId: number,
  taskId: number,
  locale: string,
  // eslint-disable-next-line react/require-default-props
  closeToast?: Function
|}) => {
  const title = new IntlMessageFormat(
    projectExportPanelDictionary[locale][
      'projectExportPanel.exportTasks.messages.taskIsReady'
    ]
  ).format({
    taskId,
    projectId
  });

  return (
    <div className="ui padded compact grid">
      <div className="row column">{title}</div>
      <div className="row column">
        <Button
          buttonType="basic"
          inverted
          fluid
          onClick={closeToast}
          tagName={Link}
          href={link}
          icon
          compact
          target="_blank"
        >
          <Icon icon="download" />
        </Button>
      </div>
    </div>
  );
};

const ErrorMessage = ({
  taskId,
  projectId,
  locale,
  closeToast
}: {|
  taskId: number,
  projectId: number,
  locale: string,
  // eslint-disable-next-line react/require-default-props
  closeToast?: Function
|}) => {
  const title = useMemo(() => {
    const msg = new IntlMessageFormat(
      projectExportPanelDictionary[locale][
        'projectExportPanel.exportTasks.messages.taskIsFailed'
      ]
    );

    return msg.format({
      taskId,
      projectId
    });

    // return `Export task #${taskId} in project #${projectId} has failed`;
  }, [locale, projectId, taskId]);

  return (
    <div className="ui padded compact grid">
      <div className="row column">{title}</div>
      <div className="row column">
        <Button buttonType="basic" inverted fluid onClick={closeToast} compact>
          OK
        </Button>
      </div>
    </div>
  );
};

// TODO: может можно сделать отслеживание разных тасков, а не только экспортов
const watchExportTaskEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/watch-task'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([action, state]: [WatchExportTask, AppState]) => {
      const { taskId } = action;

      const projectId = projectIdFromPathSelector(state);

      return interval(3000).pipe(
        RxOperators.switchMap(() =>
          request({
            url: interpolateString(API.projects.exportTasks.list, {
              projectId
            }),
            query: {
              id: [taskId]
            }
          }).pipe(
            responseParser,
            RxOperators.pluck('data'),
            RxOperators.mergeMap(data => data)
          )
        ),
        RxOperators.takeWhile(data => {
          const task: IExportTask = camelizeKeys(data);

          return ![TASK_STATUS.READY, TASK_STATUS.ERROR].includes(task.status);
        }, true),
        RxOperators.withLatestFrom(state$),
        RxOperators.mergeMap(([data, freshState]) => {
          const language = languageStateSelector(freshState);
          const normalizedData = camelizeKeys(data);

          if (TASK_STATUS.READY === normalizedData.status) {
            toast.success(
              <SuccessMessage
                taskId={normalizedData.id}
                projectId={normalizedData.projectId}
                link={normalizedData.link}
                locale={language}
              />,
              {
                autoClose: false,
                showCloseButton: true,
                position: 'top-right'
              }
            );
          } else if (TASK_STATUS.ERROR === normalizedData.status) {
            toast.error(
              <ErrorMessage
                taskId={normalizedData.id}
                projectId={normalizedData.projectId}
                locale={language}
              />,
              {
                autoClose: false,
                showCloseButton: true,
                position: 'top-right'
              }
            );
          }

          return [
            {
              type: 'project-export/update-export-task-status',
              taskId,
              status: normalizedData.status,
              data: normalizedData
            }
          ];
        }),
        RxOperators.takeUntil(
          action$.pipe(
            ofType('project-export/unwatch-task'),
            RxOperators.filter(
              unwatchAction => unwatchAction.taskId === action.taskId
            )
          )
        )
      );
    }),
    RxOperators.takeUntil(action$.pipe(ofType('auth/sign-out')))
  );

export default watchExportTaskEpic;
