// @flow

import * as RxOperators from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import { ofType } from 'redux-observable';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import listResponseParser from 'common/epicHelpers/listResponseParser';
import responsesNormalizer from 'common/transducers/response/responsesNormalizer';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import debounceEpic from 'common/epicHelpers/debounceEpic';
import type { FetchResponses } from 'flow-types/actions/projects/detail/responses/FetchResponses';
import denormalizeFilter from 'common/transducers/projects/responsesFilterDenormalizer';
import { projectDetailStateSelector } from '../../../selectors';

const fetchProjectResponsesEpic: Epic = ($action, $state) =>
  $action.pipe<FetchResponses>(
    ofType('project-responses/fetch'),
    RxOperators.withLatestFrom($state),
    debounceEpic(),
    RxOperators.switchMap(([, state]) => {
      const {
        responses: { filter }
      } = projectDetailStateSelector(state);

      const normalizedFilter = denormalizeFilter(filter);

      const url = API.projects.responses.replace(
        ':project_id',
        state.location.payload.projectId
      );

      return request({
        url,
        query: decamelizeKeys(normalizedFilter),
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'project-responses/fetch-success',
          data: responsesNormalizer(data),
          pagination
        })),
        RxOperators.catchError(({ response, message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });

          return of({
            type: 'project-responses/fetch-fail',
            error: response ? response.error : message
          });
        })
      );
    })
  );

export default fetchProjectResponsesEpic;
