import { createSelector } from 'reselect';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import type {
  ProjectDetailState,
  ProjectResponseState,
  ResponseStatsState
} from 'flow-types/states/ProjectsState/detail';
import type { LocationState } from 'redux-first-router/dist/flow-types';
import type { IRegionRate } from 'flow-types/entities/RecordRegion';
import type { IProject } from 'flow-types/entities/Project';
import type { IChecklistGroup } from 'flow-types/entities/ChecklistGroup';
import type { IChecklistItem } from 'flow-types/entities/ChecklistItem';
import type { IChecklist } from 'flow-types/entities/Checklist';

import { projectFinalDataSelector } from '../../../../../selectors/projects';

import {
  locationStateSelector,
  projectDetailStateSelector
} from '../../../../../selectors';

import {
  projectResponseStateSelector,
  responseStatsStateSelector
} from '../../../../../selectors/projects/response';
import { ratesListSelector } from '../../../../../selectors/records';

export const checklistsSelector = createSelector(
  projectFinalDataSelector,
  (project: IProject) => {
    if (!project) return [];

    return project.checklists;
  }
);

export const checklistIdFromPathSelector = createSelector(
  locationStateSelector,
  (location: LocationState): string | null => {
    if (!location || !location.query || !location.query.checkId) {
      return null;
    }

    return location.query.checkId;
  }
);

/**
 * Returns currently selected checklistId context
 * @type {OutputSelector<AppState, *|number, (res1: (string|null), res2: ([]|Array<IChecklist>)) => (*|number)>}
 */
export const activeChecklistIdSelector = createSelector(
  [checklistIdFromPathSelector, checklistsSelector],
  (checklistId, checklists) => {
    const checkId = checklistId || checklists?.[0]?.id || null;

    return checkId === null ? checkId : +checkId;
  }
);

export const enablingTestElementsStateSelector = createSelector(
  projectDetailStateSelector,
  (projectsDetail: ProjectDetailState) => projectsDetail.enablingTestElements
);

export const disablingTestElementsStateSelector = createSelector(
  projectDetailStateSelector,
  (projectsDetail: ProjectDetailState) => projectsDetail.disablingTestElements
);

export const responseStructuredRatingsListSelector = createSelector(
  [
    responseStatsStateSelector,
    projectResponseStateSelector,
    activeChecklistIdSelector,
    checklistsSelector,
    ratesListSelector,
    enablingTestElementsStateSelector,
    disablingTestElementsStateSelector
  ],
  (
    stats: ResponseStatsState,
    { data: response }: ProjectResponseState,
    checklistId: string | null,
    checklists: IChecklist[],
    ratesList,
    enablingChecks,
    disablingChecks
  ) => {
    if (!checklistId) return [];

    const checklist = checklists.find(checkL => checkL.id === +checklistId);

    const checklistGroups = checklist?.checklistGroups || [];

    const disabledChecks = response ? response.disabledChecklistItems : [];

    const checkedTestElements = map(
      ratesList,
      (rate: IRegionRate) => rate.checklistItemId
    );

    return reduce(
      checklistGroups,
      (_structured, checklistGroup: IChecklistGroup) => {
        const { checklistItems, ...safeData } = checklistGroup;

        const statsForChecklist = stats[checklistGroup.checklistId];

        let statsForGroup = null;

        if (statsForChecklist) {
          statsForGroup = statsForChecklist.groups[checklistGroup.id] || {
            percent: 0
          };
        } else {
          statsForGroup = {
            percent: 0
          };
        }

        return [
          ..._structured,
          {
            ...safeData,
            stats: statsForGroup,
            items: reduce(
              checklistItems,
              (_structuredItems, testElement: IChecklistItem) => {
                const { id } = testElement;

                return [
                  ..._structuredItems,
                  {
                    ...testElement,
                    title: `${checklistGroup.id}.${testElement.id}. ${testElement.title}`,
                    disabled: includes(
                      [...enablingChecks, ...disablingChecks],
                      id
                    ),
                    disabling: includes(disablingChecks, id),
                    enabling: includes(enablingChecks, id),
                    isDisabled: includes(disabledChecks, id),
                    isChecked: includes(checkedTestElements, id),
                    regions: reduce(
                      ratesList,
                      (_regions, rate: IRegionRate) => {
                        // console.log(region);
                        if (+rate.checklistItemId !== +testElement.id) {
                          return _regions;
                        }

                        return [..._regions, rate];
                      },
                      []
                    )
                  }
                ];
              },
              []
            )
          }
        ];
      },
      []
    );
  }
);
