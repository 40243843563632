import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import type { ReactChildren } from 'flow-types/ReactChildren';
import type { NilValue } from 'flow-types/NilValue';
import ListItem from '../components/ListItem';

const ActiveTabContext = createContext(null);

const UpdateActiveTabContext = createContext(null);

function useActiveTab() {
  return useContext(ActiveTabContext);
}

function useSetActiveTab() {
  return useContext(UpdateActiveTabContext);
}

type OnEnterCb = (prev, next) => void;

type OnExitCb = (prev, next) => void;

type OnChangeCb = (eventKey: string) => void;

export type Props = {|
  children?: ReactChildren,
  initialActiveTab?: string,
  activeTab?: string,
  onChange?: NilValue | OnChangeCb
|};

export const TabsProvider = ({
  children,
  initialActiveTab,
  activeTab,
  onChange
}: Props) => {
  const [localActiveTab, setActiveTab] = useState(initialActiveTab);

  const latestCurrentTab = React.useRef(localActiveTab);

  const isControlled = typeof activeTab !== 'undefined';

  // React.useEffect(() => {
  //   if (isControlled) {
  //     setActiveTab(activeTab);
  //   }
  // }, [activeTab, isControlled]);

  const handleActiveTabChange = useCallback(
    eventKey => {
      if (latestCurrentTab.current === eventKey) return;

      latestCurrentTab.current = eventKey;

      if (isControlled) {
        onChange?.(eventKey);
        return;
      }

      setActiveTab(eventKey);
    },
    [isControlled, onChange]
  );

  return (
    <UpdateActiveTabContext.Provider value={handleActiveTabChange}>
      <ActiveTabContext.Provider
        value={isControlled ? activeTab || initialActiveTab : localActiveTab}
      >
        {children}
      </ActiveTabContext.Provider>
    </UpdateActiveTabContext.Provider>
  );
};

TabsProvider.defaultProps = {
  children: null,
  initialActiveTab: null,
  activeTab: undefined,
  onChange: undefined
};

export type TabNavItemProps = {|
  eventKey: string
|};

export const TabNavItem = ({ eventKey, ...props }: TabNavItemProps) => {
  const activeTab = useActiveTab();

  const open = useSetActiveTab();

  const handleClick = useCallback(() => {
    if (!open) return;

    open(eventKey);
  }, [eventKey, open]);

  return (
    <ListItem
      {...props}
      active={activeTab === eventKey}
      onClick={handleClick}
    />
  );
};

export type TabProps = {|
  active: boolean,
  eventKey: string,
  children: ReactChildren,
  onEnter?: OnEnterCb,
  onExit?: OnExitCb
|};

export const Tab = ({
  eventKey,
  active: externallySetActive,
  children,
  onEnter,
  onExit
}: TabProps) => {
  const lastVisibilityStateCache = React.useRef(false);

  const activeTab = useActiveTab();

  useEffect(() => {
    if (
      (externallySetActive || activeTab === eventKey) &&
      !lastVisibilityStateCache.current
    ) {
      lastVisibilityStateCache.current = true;
      if (onEnter) {
        onEnter(eventKey);
      }
    }

    if (
      !externallySetActive &&
      activeTab !== eventKey &&
      !!lastVisibilityStateCache.current
    ) {
      lastVisibilityStateCache.current = false;
      if (onExit) {
        onExit(eventKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, externallySetActive]);

  // wa can also force it to be active
  if (activeTab !== eventKey && !externallySetActive) return null;

  return children;
};

Tab.defaultProps = {
  onEnter: null,
  active: false,
  onExit: null
};

/**
 * Displays content when there is no active tab
 * @returns {null|*}
 * @constructor
 */
export const DefaultTab = ({ children }) => {
  const activeTab = useActiveTab();

  if (activeTab) return null;

  return children;
};
