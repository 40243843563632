// @flow

import * as React from 'react';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import type { ReactChildren } from 'flow-types/ReactChildren';

const LabelStyledComponent = styled.label`
  cursor: pointer !important;
  ${props =>
    props.inlined &&
    css`
      &&& {
        display: inline-block !important;
      }
    `}
`;

type Props = {
  as?: any,
  className?: ?string,
  nonUI?: boolean,
  image?: boolean,
  pointing?: boolean | 'below' | 'left' | 'right',
  color?: string,
  corner?: 'left' | 'right' | null,
  tag?: boolean,
  horizontal?: boolean,
  ribbon?: 'left' | 'right' | null,
  attachedX?: null | 'left' | 'right',
  attachedY?: null | 'top' | 'bottom',
  floating?: 'top' | 'bottom' | 'left' | null,
  icon?: 'left' | 'right' | null,
  circular?: boolean | 'empty',
  size?: string,
  group?: boolean,
  children: ReactChildren,
  onClick?: Function
};

const Label = ({
  as: Component,
  className,
  nonUI,
  image,
  color,
  pointing,
  corner,
  tag,
  ribbon,
  attachedX,
  attachedY,
  horizontal,
  floating,
  icon,
  circular,
  size,
  group,
  ...props
}: Props): React.Node => {
  const cls = cx(
    {
      ui: !nonUI,
      tag,
      horizontal,
      empty: circular === 'empty',
      circular
    },
    [size],
    [color],
    {
      right: icon === 'right',
      icon
    },
    {
      bottom: floating === 'bottom',
      left: floating === 'left',
      floating
    },
    {
      right: pointing === 'right',
      left: pointing === 'left',
      pointing,
      below: pointing === 'below'
    },
    {
      left: corner === 'left',
      right: corner === 'right',
      corner
    },
    {
      right: ribbon === 'right',
      ribbon
    },
    {
      top: attachedY === 'top',
      bottom: attachedY === 'bottom',
      left: attachedX === 'left',
      right: attachedX === 'right',
      attached: attachedX || attachedY
    },
    { image },
    [className],
    !group ? 'label' : 'labels'
  );

  return (
    <LabelStyledComponent
      as={Component}
      {...(props: $Rest<
        Props,
        {
          as?: any,
          className?: ?string,
          nonUI?: boolean,
          image?: boolean,
          pointing?: boolean | 'below' | 'left' | 'right',
          color?: string,
          corner?: 'left' | 'right' | null,
          tag?: boolean,
          horizontal?: boolean,
          ribbon?: 'left' | 'right' | null,
          attachedX?: null | 'left' | 'right',
          attachedY?: null | 'top' | 'bottom',
          floating?: 'top' | 'bottom' | 'left' | null,
          icon?: 'left' | 'right' | null,
          circular?: boolean | 'empty',
          size?: string,
          group?: boolean
        }
      >)}
      className={cls}
    />
  );
};

Label.defaultProps = {
  as: 'label',
  color: '',
  corner: null,
  ribbon: null,
  attachedX: null,
  attachedY: null,
  floating: null,
  size: '',
  group: false,
  className: '',
  nonUI: false,
  image: false,
  pointing: false,
  tag: false,
  horizontal: false,
  icon: null,
  circular: false,
  onClick: null
};

export default Label;
