import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import getToken from 'utils/getToken';
import { of } from 'rxjs';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeKeys } from 'humps';
import { authStateSelector } from '../../selectors';
import createSingOut from '../../actions/auth/signOut';

export const signInWithTokenEpic = ($action, $state) =>
  $action.pipe(
    ofType('auth/sign-in-with-token'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([, state]) => {
      const { isLoggedIn } = authStateSelector(state);
      const token = getToken();

      if (!token) return of({ type: 'auth/sign-in-fail' });

      return request({
        url: API.users.self,
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.mergeMap(user =>
          of({
            type: 'auth/sign-in-success',
            user: camelizeKeys(user),
            token,
            // TODO: when user exits the page save its scope
            //  if token is not set for clearance and restore it with token here
            scope: '*',
            initialLoggedInState: isLoggedIn
          })
        ),
        RxO.catchError(() =>
          of({ type: 'auth/sign-in-fail' }, createSingOut({ manual: true }))
        )
      );
    })
  );
