import type { InterviewProjectState } from 'flow-types/states/InterviewState/InterviewProjectState';

const initialState: InterviewProjectState = {
  data: {},
  cache: {},
  loading: false
};

// stores project data that is  relative to interview
export default (state = initialState, action) => {
  switch (action.type) {
    case 'interview/reset':
      return {
        ...initialState,
        cache: state.cache,
        data: state.cache
      };

    case 'interview/prepare-complete':
      return action.isNext
        ? {
            ...state,
            data: action.project,
            cache: action.project,
            loading: false
          }
        : state;

    default:
      return state;
  }
};
