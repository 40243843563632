import * as React from 'react';
import mergeRefs from 'react-merge-refs';
import useOnClickOutside from 'use-onclickoutside';
import cx from 'classnames';

type Props = {
  +className: string,
  +onClickOutside: Function
};

const Container = React.forwardRef(
  ({ className, onClickOutside, ...props }: Props, ref) => {
    const localRef = React.useRef(null);

    useOnClickOutside(localRef, onClickOutside);

    return (
      <div
        ref={mergeRefs([localRef, ref])}
        className={cx('ui', [className], 'selection dropdown')}
        {...props}
      />
    );
  }
);

export default Container;
