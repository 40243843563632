// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './index';

const CancelButton: React.AbstractComponent<
  Object,
  HTMLElement
> = React.forwardRef((props, ref) => (
  <Button {...props} ref={ref} color="black" buttonType="deny">
    <FormattedMessage id="common.labels.cancel" />
  </Button>
));

export default CancelButton;
