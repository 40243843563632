// @flow
import * as RxO from 'rxjs/operators';
import reduce from 'lodash/reduce';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';

import { TYPES } from 'common/helpers/question';
import ComputedPropertiesProcessor from 'common/helpers/computedProperties';

import type { PrepareInterviewSuccess } from 'flow-types/actions/interview/PrepareInterview';
import type { ComputedPropertyValue } from 'common/helpers/computedProperties';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';
import type { IComputedProperty } from 'flow-types/entities/ComputedProperty';
import type { UpdateAnswer } from 'flow-types/actions/interview/UpdateAnswer';
import type { $ObjOfType } from 'flow-types/ObjOfType';
import type { Epic } from 'flow-types/Epic';

import { interviewAnswersListSelector } from '../../selectors/interview/answers';
import { interviewQuestionsSelector } from '../../selectors/interview/questions';
import { interviewProjectDataSelector } from '../../selectors/interview/root';

const codesAndValuesSelector: Function = createSelector(
  interviewAnswersListSelector,
  interviewQuestionsSelector,
  (answers, questions) =>
    reduce(
      answers,
      (result: $ObjOfType<ComputedPropertyValue>, answer: IInterviewAnswer) => {
        const { questionId, data } = answer;

        const question = questions.find(q => q.id === questionId);

        const type = question?.type;
        const code = question?.code;

        if (
          code &&
          ![
            TYPES.Numeric,
            TYPES.TextBlock,
            TYPES.Email,
            TYPES.MobilePhone
          ].includes(type)
        ) {
          return result;
        }

        return {
          ...result,
          // FORCE CAST TO STRING
          // we're checking code above in if-else statement
          [((code: any): string)]: data
        };
      },
      {}
    )
);

const projectPropertiesSelector = createSelector(
  interviewProjectDataSelector,
  project => project.variables
);

const processComputedProperties: Epic = (action$, state$) =>
  action$.pipe<UpdateAnswer | PrepareInterviewSuccess>(
    ofType('interview/update-answer', 'interview/prepare-complete'),
    RxO.withLatestFrom(state$),
    // В целом нам всё равно что было в прошлом обновлении ответа если
    // ответы будут меняться очень часто.
    RxO.switchMap(([, state]) => {
      const staticValues: $ObjOfType<ComputedPropertyValue> = codesAndValuesSelector(
        state
      );

      const properties: $ObjOfType<IComputedProperty> = projectPropertiesSelector(
        state
      );

      const cpp = new ComputedPropertiesProcessor({
        properties,
        staticValues
      });

      cpp.process();

      return [
        {
          type: 'interview/process-properties-success',
          data: cpp.getCompactState()
        }
      ];
    })
  );

export default processComputedProperties;
