// @flow

import { array, mixed, number, object, string } from 'yup';
import some from 'lodash/some';
import type { IFormattedLogic } from 'flow-types/entities/Logic';
import type { YupValidationError } from 'flow-types/Validation';
import { LOGIC_ACTION } from 'common/helpers/logic/constants';

const checkCommonTargetsExistence = targets =>
  some(
    ['groupsIds', 'questionsIds', 'optionsIds'],
    targetKey =>
      Array.isArray(targets[targetKey]) && targets[targetKey].length > 0
  );

type ActionTargetsValidatorConfig = {|
  projectIdIsRequired: string,
  titleIsRequired: string,
  actions: {|
    actionsAreRequired: string,
    typeIsRequired: string,
    targets: {|
      fromIsRequired: string,
      toIsRequired: string,
      targetsAreRequired: string,
      questionsIdsIsRequired: string
    |}
  |},
  tests: {|
    testsAreRequired: string
  |}
|};

const ERROR_MESSAGES: ActionTargetsValidatorConfig = {
  projectIdIsRequired: 'logicRuleForm.validation.fields.projectId.isRequired',
  titleIsRequired: 'logicRuleForm.validation.fields.title.isRequired',
  actions: {
    actionsAreRequired: 'logicRuleForm.validation.fields.actions.isRequired',
    typeIsRequired: 'logicRuleForm.validation.fields.actions.type.isRequired',
    targets: {
      fromIsRequired:
        'logicRuleForm.validation.fields.actions.targets.from.isRequired',
      toIsRequired:
        'logicRuleForm.validation.fields.actions.targets.to.isRequired',
      targetsAreRequired:
        'logicRuleForm.validation.fields.actions.targets.isRequired',
      questionsIdsIsRequired:
        'logicRuleForm.validation.fields.actions.targets.questionsIds.isRequired'
    }
  },
  tests: {
    testsAreRequired: 'logicRuleForm.validation.fields.tests.isRequired'
  }
};

export const createActionTargetsValidator = (
  config: ActionTargetsValidatorConfig = ERROR_MESSAGES
) =>
  mixed()
    .when('type', type => {
      switch (type) {
        case LOGIC_ACTION.GOTO:
        // return object().shape({
        //   from: number().required({
        //     field: 'from',
        //     messageId: config.actions.targets.fromIsRequired
        //   }),
        //   to: number().required({
        //     field: 'to',
        //     messageId: config.actions.targets.toIsRequired
        //   })
        // });
        // eslint-disable-next-line no-fallthrough
        case LOGIC_ACTION.REQUIRE:
          return object().shape({
            questionsIds: array()
              .of(number())
              .required({
                field: 'targets',
                messageId: config.actions.targets.questionsIdsIsRequired
              })
              .min(1, {
                field: 'targets',
                messageId: config.actions.targets.questionsIdsIsRequired
              })
          });
        default:
          return object().test(
            'targets',
            {
              fields: 'targets',
              messageId: config.actions.targets.targetsAreRequired
            },
            val => val && checkCommonTargetsExistence(val)
          );
      }
    })
    .required({
      field: 'targets',
      messageId: config.actions.targets.targetsAreRequired
    });

export const createActionValidator = (
  config: ActionTargetsValidatorConfig = ERROR_MESSAGES
) =>
  object().shape({
    type: string()
      .oneOf([
        LOGIC_ACTION.HIDE,
        LOGIC_ACTION.SHOW,
        LOGIC_ACTION.REQUIRE,
        LOGIC_ACTION.GOTO
      ])
      .required({
        field: 'type',
        messageId: config.actions.typeIsRequired
      }),
    targets: createActionTargetsValidator()
  });

export const createActionsValidator = (
  config: ActionTargetsValidatorConfig = ERROR_MESSAGES
) =>
  array()
    .of(createActionValidator())
    .required({
      field: 'actions',
      messageId: config.actions.actionsAreRequired
    });

export const createValidator = (
  config: ActionTargetsValidatorConfig = ERROR_MESSAGES
) =>
  object().shape({
    title: string().required({
      field: 'title',
      messageId: config.titleIsRequired
    }),
    projectId: number()
      .typeError({
        field: 'projectId',
        messageId: config.projectIdIsRequired
      })
      .required({
        field: 'projectId',
        messageId: config.projectIdIsRequired
      }),
    tests: array().required({
      field: 'tests',
      messageId: config.tests.testsAreRequired
    }),
    actions: createActionsValidator(config)
  });

export default function validateLogicRule(
  logicRule: IFormattedLogic,
  sync: boolean = false
):
  | Promise<YupValidationError | IFormattedLogic>
  | YupValidationError
  | IFormattedLogic {
  const schema = createValidator();

  if (!sync) {
    return schema.validate(logicRule, { abortEarly: false });
  }

  return schema.validateSync(logicRule, { abortEarly: false });
}
