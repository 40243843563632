export const checklistsDictionary = {
  en: {
    'checklists.formTitle.edit': 'Edit checklist',
    'checklists.formTitle.create': 'Register checklist',
    'checklists.form.title': 'Title',
    'checklists.form.rates': 'Rates',
    'checklists.form.addRate': 'Add rate',
    'checklists.form.emptyRates': 'No rates!',
    'checklists.form.minPoints': 'Min points',
    'checklists.form.maxPoints': 'Max points',
    'checklists.form.company': 'Company',
    'checklists.form.department': 'Department',
    'checklists.form.tags.label': 'Tags list',
    'checklists.list.noItemsPlaceholder': 'No checklists found',
    'checklists.actions.remove.message':
      'Are you definitely want to delete checklist?'
  },
  ru: {
    'checklists.form.tags.label': 'Список тэгов',
    'checklists.formTitle.edit': 'Обновление чеклиста',
    'checklists.formTitle.create': 'Регистрация чеклиста',
    'checklists.form.title': 'Название',
    'checklists.form.rates': 'Оценки',
    'checklists.form.addRate': 'Добавить оценку',
    'checklists.form.emptyRates': 'Нет оценок!',
    'checklists.form.minPoints': 'Мин. балл',
    'checklists.form.maxPoints': 'Макс. балл',
    'checklists.form.company': 'Компания',
    'checklists.form.department': 'Отдел',
    'checklists.list.noItemsPlaceholder': 'Нет данных по чеклистам',
    'checklists.actions.remove.message':
      'Вы действительно хотите удалить чеклист?'
  }
};

export default checklistsDictionary;
