/**
 * Adaptive modal is a wrapper around animated Modal that is displayed
 * as sticking to the bottom edge of a screen on mobile devices or
 * as usual in other cases
 */
import React from 'react';
import { usePortrait } from 'common/components/Responsive/Portrait';
import { useMobileScreen } from 'common/components/Responsive/Mobile';
import Modal from 'common/components/animated/Modal';

export default function AdaptiveModal(props) {
  const isPortrait = usePortrait();
  const isMobile = useMobileScreen();

  return (
    <Modal {...props} position={isPortrait && isMobile ? 'bottom' : null} />
  );
}
