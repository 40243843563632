// @flow

import React from 'react';
import type { Node } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import type { ReactComponent } from 'flow-types/ReactComponent';

type Props = {
  disabled?: boolean,
  droppableId?: string,
  ItemComponent?: null | ReactComponent,
  ListComponent?: null | ReactComponent,
  data?: Array<Object>
};

/**
 * Common component for draggable list creation
 * @param disabled
 * @param droppableId
 * @param ItemComponent
 * @param ListComponent
 * @param fieldName
 * @param rest
 * @return {JSX.Element|null}
 * @constructor
 */
function DraggableList({
  disabled,
  droppableId,
  ItemComponent,
  ListComponent,
  ...rest
}: Props): Node {
  if (!ItemComponent || !ListComponent) return null;

  return (
    <Droppable isDropDisabled={disabled} droppableId={droppableId}>
      {provided => (
        <ListComponent
          {...rest}
          provided={provided}
          ItemComponent={ItemComponent}
          disabled={disabled}
        />
      )}
    </Droppable>
  );
}

DraggableList.defaultProps = ({
  ItemComponent: null,
  ListComponent: null,
  disabled: false,
  droppableId: 'options',
  fieldName: 'options',
  data: []
}: Props);

export default DraggableList;
