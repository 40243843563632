// @flow
import { mergeAndConcat } from 'merge-anything';
import size from 'lodash/size';
import setWith from 'lodash/setWith';
import reduce from 'lodash/reduce';

import type { YupValidationError } from 'flow-types/Validation';

type Config = {
  structured: boolean
};

const initialConfig: Config = {
  structured: false
};

const parseYupValidation = (
  errorObject: YupValidationError,
  // TODO: describe
  config: Config = initialConfig
) => {
  const parsedErrorObject = { ...errorObject };

  if (size(parsedErrorObject.inner) <= 0) {
    const savePath = parsedErrorObject.path || 'uncategorized';

    const result = {};

    if (config.structured) {
      setWith(result, savePath, parsedErrorObject.errors, Object);
    } else {
      result[savePath] = parsedErrorObject.errors;
    }

    return result;
  }

  return reduce(
    parsedErrorObject.inner,
    (result, innerError) => {
      const parsedInnerError = parseYupValidation(innerError, config);

      if (typeof parsedInnerError !== 'object') {
        return [...(result && result), parsedInnerError].filter(Boolean);
      }

      if (Array.isArray(parsedInnerError)) {
        return Array.isArray(result)
          ? [...result, ...parsedInnerError].filter(Boolean)
          : [result, ...parsedInnerError].filter(Boolean);
      }

      return mergeAndConcat(result ? { ...result } : {}, {
        ...parsedInnerError
      });
    },
    null
  );
};

export default parseYupValidation;
