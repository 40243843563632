// @flow

import React, { useCallback, useMemo } from 'react';

import type { Node } from 'react';

import Button from 'common/components/Button';
import useI18N from 'common/hooks/useI18N';

import { POLLING_NOTIFICATION_STATUS } from 'common/helpers/project/constants';
import {
  MODAL_FORM_ACTION_ACTIVATE,
  MODAL_FORM_ACTION_DEACTIVATE
} from './PollingNotificationPanel.dictionary';

type Props = {
  status: number,
  onChange: (status: number) => void
};

export default function PollingNotificationSwitchStatusButton({
  status,
  onChange,
  ...props
}: Props): Node {
  const msgConfig = useMemo(
    () => ({
      activate: {
        id: MODAL_FORM_ACTION_ACTIVATE
      },
      stop: {
        id: MODAL_FORM_ACTION_DEACTIVATE
      }
    }),
    []
  );

  const messages = useI18N(msgConfig);

  const onClick = useCallback(() => {
    onChange(
      status === POLLING_NOTIFICATION_STATUS.STOPPED
        ? POLLING_NOTIFICATION_STATUS.ACTIVE
        : POLLING_NOTIFICATION_STATUS.STOPPED
    );
  }, [onChange, status]);

  return (
    <Button {...props} onClick={onClick}>
      {status === POLLING_NOTIFICATION_STATUS.ACTIVE
        ? messages.stop
        : messages.activate}
    </Button>
  );
}
