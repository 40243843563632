import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

export default function useFuse(items, options) {
  const [fuse, setFuse] = useState(null);

  useEffect(() => {
    const searchableItems = items || [];

    setFuse(new Fuse(searchableItems, options));
  }, [items, options]);

  return fuse;
}
