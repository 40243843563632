/*
 Default semantic breakpoints
 @mobileBreakpoint            : 320px;
 @tabletBreakpoint            : 768px;
 @computerBreakpoint          : 992px;
 @largeMonitorBreakpoint      : 1200px;
 @widescreenMonitorBreakpoint : 1920px;
*/

export const mobileBreakpoint = 320;
export const tabletBreakpoint = 768;
export const computerBreakpoint = 992;
export const largeMonitorBreakpoint = 1200;
export const widescreenMonitorBreakpoint = 1920;

export const largestMobileScreen = tabletBreakpoint - 1;
export const largestTabletScreen = computerBreakpoint - 1;
export const largestSmallMonitor = largeMonitorBreakpoint - 1;
export const largestLargeMonitor = widescreenMonitorBreakpoint - 1;
