/* eslint-disable no-unsafe-finally */
import { useVariables } from 'common/components/Question/Variables';
import * as Handlebars from 'handlebars';
import { useCallback } from 'react';
import isDevelopment from 'utils/isDevelopment';

export default function useVariablesCompiledString() {
  const { values } = useVariables();

  return useCallback(
    str => {
      try {
        return Handlebars.compile(str)(values);
      } catch (e) {
        if (isDevelopment) {
          // eslint-disable-next-line no-console
          console.error('useVariablesCompiledString:', e);
        }
        return '';
      }
    },
    [values]
  );
}
