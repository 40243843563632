// @flow

/* eslint-disable jsx-a11y/anchor-is-valid,react/no-danger */
import * as React from 'react';
import size from 'lodash/size';
import cx from 'classnames';

import Link from 'common/components/Link';
import Image from 'common/components/Image';
import Content from 'common/components/Content';

import type { IActions } from 'common/components/Comment/flow';

import type { ReactChildren } from 'flow-types/ReactChildren';
import type { IUpload } from 'flow-types/entities/Upload';
import Metadata from './Metadata';
import Actions from './Actions';

type Props = {
  tagName?: string,
  className?: string,
  active?: boolean,
  actions?: IActions,
  avatar?: null | IUpload | string,
  children?: ReactChildren,
  // TODO: make it an Array to be able
  //  to control at least an order of metadata
  metadata?: null | Object,
  body?: null | string,
  author?: null | string,
  onActionClick?: null | Function
};

/**
 * In future I wanna create something like this:
 * <Comment>
 *  <Comment.Avatar /
 *  <Comment.Content> --> it's just a common {@link Content} component
 *    <Comment.Author />
 *    <Comment.Metadata />
 *    <Comment.Body />
 *    <Comment.Actions />
 *  </Comment.Content>
 * </Comment>
 */

/**
 * Common comment component
 */
const Comment: React.AbstractComponent<Props, mixed> = React.forwardRef(
  (
    {
      tagName = 'div',
      className,
      metadata,
      avatar,
      author,
      active,
      actions,
      onActionClick,
      body: __html,
      children,
      ...props
    }: Props,
    ref
  ) => {
    const Component = tagName ?? 'div';

    const classNames = cx(className, { active }, 'comment');

    const hasMeta = size(metadata) > 0;

    const hasActions = size(actions) > 0;

    return (
      <Component {...props} className={classNames} ref={ref}>
        {avatar && (
          <Link className="avatar">
            <Image src={avatar} />
          </Link>
        )}
        <Content>
          {author && <Link className="author">{author}</Link>}
          {hasMeta && (
            <Metadata
              data={
                // check has been made above
                // $FlowIgnore
                metadata
              }
            />
          )}
          {__html && (
            <div className="text" dangerouslySetInnerHTML={{ __html }} />
          )}
          {children}
          {hasActions && (
            <Actions
              data={actions}
              onClick={
                onActionClick ? actionKey => onActionClick(actionKey) : null
              }
            />
          )}
        </Content>
      </Component>
    );
  }
);

Comment.displayName = 'Comment.ForwardRef';

export default Comment;

// $FlowIgnore
Comment.defaultProps = {
  tagName: 'div',
  actions: null,
  className: null,
  onActionClick: null,
  children: null,
  metadata: null,
  avatar: null,
  body: null,
  author: null,
  active: false
};
