const meetingsDictionary = {
  en: {
    'meeting.header.title': 'Meetings',
    'meeting.filters.company': 'Companies',
    'meeting.filters.departments': 'Departments',
    'meeting.filters.managers': 'Managers',
    'meeting.filters.projects': 'Projects',
    'meeting.filters.source': 'Meeting source',
    'meeting.filters.status': 'Status',
    'meeting.filters.recordStartedAt': 'Record started at',
    'meeting.filters.recordEndedAt': 'Record ended at',
    'meeting.filters.basicQuestions': 'Basic Questions',
    'meeting.filters.respondentPhone': 'Respondent phone',
    'meeting.popup.header': 'Meeting information',
    'meeting.popup.details': 'Details',
    'meeting.popup.close': 'Close',
    'meeting.popup.source': 'Source: ',
    'meeting.popup.scheduledTime': 'Scheduled Time: ',
    'meeting.popup.startedAt': 'Started at: ',
    'meeting.popup.endedAt': 'Ended at: ',
    'meeting.popup.duration': 'Duration: ',
    'meeting.popup.project': 'Project: ',
    'meeting.popup.manager': 'Manager: ',
    'meeting.popup.company': 'Company: ',
    'meeting.popup.department': 'Department: ',
    'meeting.noData': 'No data',
    'meeting.calendar.today': 'Today',
    'meeting.calendar.month': 'Month',
    'meeting.calendar.week': 'Week',
    'meeting.calendar.day': 'Day',
    'meeting.calendar.fiveDay': '5 Days',
    'meeting.form.buttons.saveCsv': 'Download (.csv)',
    'meeting.form.buttons.resetFilters': 'Reset',
    'meeting.form.buttons.showMeetings': 'Show',
    'meeting.form.result.found': 'Found: ',
    'meeting.form.result.at': ' at ',
    'meeting.createForm.respondentName': 'Respondent name',
    'meeting.createForm.respondentPhone': 'Respondent phone',
    'meeting.createForm.theme': 'Theme',
    'meeting.createForm.latitude': 'Latitude',
    'meeting.createForm.longitude': 'Longitude',
    'meeting.createForm.errorListHeader': 'Error!',
    'meeting.createForm.formHeader': 'Add meeting',
    'meeting.createForm.formHeaderEdit': 'Edit meeting',
    'meeting.createForm.submit': 'Save',
    'meeting.createForm.timeBegin': 'Started at',
    'meeting.createForm.manager': 'Manager',
    'meeting.createForm.managerNotSet': 'Manager field is empty',
    'meeting.createForm.respondentNameNotSet': 'Respondent name is empty',
    'meeting.createForm.respondentPhoneNotSet':
      'Respondent phone field is empty',
    'meeting.createForm.respondentPhoneWrongFormat':
      'Respondent phone format is wrong',
    'meeting.createForm.themeNotSet': 'Theme field is empty',
    'meeting.createForm.latitudeNotSet': 'Latitude field is empty',
    'meeting.createForm.longitudeNotSet': 'Longitude field is empty',
    'meeting.createForm.latitudeNotANumber': "Latitude isn't a number",
    'meeting.createForm.longitudeNotANumber': "Longitude isn't a number",
    'meeting.createForm.address': 'Address',
    'meeting.createForm.showOnMapButton': 'Show on map',
    'meeting.createForm.company': 'Company',
    'meeting.createForm.department': 'Department',
    'meeting.createForm.showMap': 'Show on map',
    'meeting.createForm.hideMap': 'Hide map',
    'meeting.createForm.close': 'Cancel',
    'meeting.createForm.source': 'Source',
    'meeting.createForm.project': 'Project',
    'meeting.simpleSearchDropdown.add': 'Add'
  },
  ru: {
    'meeting.header.title': 'Записи',
    'meeting.filters.company': 'Компании',
    'meeting.filters.departments': 'Департаменты',
    'meeting.filters.managers': 'Менеджеры',
    'meeting.filters.projects': 'Проекты',
    'meeting.filters.source': 'Источник встречи',
    'meeting.filters.status': 'Статус',
    'meeting.filters.recordStartedAt': 'Конец записи (начало)',
    'meeting.filters.recordEndedAt': 'Конец записи (конец)',
    'meeting.filters.basicQuestions': 'Базовые вопросы',
    'meeting.filters.respondentPhone': 'Телефон респондента',
    'meeting.popup.header': 'Информация о встрече',
    'meeting.popup.details': 'Подробнее',
    'meeting.popup.close': 'Закрыть',
    'meeting.popup.source': 'Источник: ',
    'meeting.popup.scheduledTime': 'Плановое время: ',
    'meeting.popup.startedAt': 'Начало (факт): ',
    'meeting.popup.endedAt': 'Окончание: ',
    'meeting.popup.duration': 'Продолжительность: ',
    'meeting.popup.project': 'Проект: ',
    'meeting.popup.manager': 'Менеджер: ',
    'meeting.popup.company': 'Компания: ',
    'meeting.popup.department': 'Отдел: ',
    'meeting.noData': 'Нет данных',
    'meeting.calendar.today': 'Сегодня',
    'meeting.calendar.month': 'Месяц',
    'meeting.calendar.week': 'Неделя',
    'meeting.calendar.day': 'День',
    'meeting.calendar.fiveDay': '5 дней',
    'meeting.form.buttons.saveCsv': 'Скачать (.csv)',
    'meeting.form.buttons.resetFilters': 'Сбросить',
    'meeting.form.buttons.showMeetings': 'Показать',
    'meeting.form.result.found': 'Найдено: ',
    'meeting.form.result.at': ' от ',
    'meeting.createForm.errorListHeader': 'Ошибка!',
    'meeting.createForm.formHeader': 'Добавление встречи',
    'meeting.createForm.formHeaderEdit': 'Редактирование встречи',
    'meeting.createForm.submit': 'Сохранить',
    'meeting.createForm.timeBegin': 'Время начала встречи',
    'meeting.createForm.respondentName': 'ФИО',
    'meeting.createForm.respondentPhone': 'Телефон респондента',
    'meeting.createForm.manager': 'Менеджер',
    'meeting.createForm.theme': 'Тема',
    'meeting.createForm.latitude': 'Широта',
    'meeting.createForm.longitude': 'Долгота',
    'meeting.createForm.managerNotSet': 'Не выбран менеджер',
    'meeting.createForm.respondentNameNotSet': 'Не указано ФИО респондента',
    'meeting.createForm.respondentPhoneNotSet':
      'Не указан контактный телефон респондента',
    'meeting.createForm.respondentPhoneWrongFormat':
      'Неверный формат телефона респондента',
    'meeting.createForm.themeNotSet': 'Не указана тема встречи',
    'meeting.createForm.latitudeNotSet': 'Не указана широта',
    'meeting.createForm.longitudeNotSet': 'Не указана долгота',
    'meeting.createForm.latitudeNotANumber':
      'Указанная широта не является числом',
    'meeting.createForm.longitudeNotANumber':
      'Указанная долгота не является числом',
    'meeting.createForm.address': 'Адрес',
    'meeting.createForm.showOnMapButton': 'Указать на карте',
    'meeting.createForm.company': 'Компания',
    'meeting.createForm.department': 'Отдел',
    'meeting.createForm.showMap': 'Указать на карте',
    'meeting.createForm.hideMap': 'Скрыть карту',
    'meeting.createForm.close': 'Отмена',
    'meeting.createForm.source': 'Источник',
    'meeting.createForm.project': 'Проект',
    'meeting.simpleSearchDropdown.add': 'Добавить'
  }
};

export default meetingsDictionary;
