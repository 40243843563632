// @flow
import * as React from 'react';
import useMeasure from 'react-use-measure';
import { FormattedMessage } from 'react-intl';
import { merge } from 'merge-anything';
import styled from 'styled-components';

import { useAnswer } from 'common/contexts/AnswersContext';
import { useAnswerUpdate } from 'common/contexts/AnswerUpdateContext';

import isSelected from 'common/helpers/isSelected';
import useWillUnmount from 'common/hooks/useWillUnmount';
import useQuestionType from 'common/hooks/useQuestionType';
import BackgroundImage from 'common/components/Image/Background';

import Icon from 'common/components/Icon';
import Slider from 'common/containers/Slider';
import Button from 'common/components/Button';
import Header from 'common/components/Header';
import Options from 'common/components/Question/Options';

import type { IInterviewQuestion } from 'flow-types/entities/Question';
import type { IQuestionOption } from 'flow-types/entities/QuestionOption';
import type { OnAnswerInput } from 'common/components/Question/flow';
import { useSelection } from 'common/hooks/useSelection';
import getOnlyVisible from 'common/helpers/getOnlyVisible';

type Props = {
  question: IInterviewQuestion,
  appearance: 'list' | 'slider',
  onAnswerInput: ?OnAnswerInput
};

const SliderContainer = styled.div`
  // override pagination color
  --swiper-pagination-color: white;

  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    border: 1px solid rgb(255, 255, 255, 0.9);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination {
    bottom: 5px;
  }

  padding-top: 20px;
  padding-bottom: 20px;
`;

const SlideTitle = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1.5rem !important;
  background-color: rgba(0, 0, 0, 0.2);
`;

type SlideProps = {
  data: IQuestionOption,
  showLabel?: boolean
};

const Slide = ({ data, showLabel }: SlideProps) => (
  <BackgroundImage image={data.image} height="100%" maxHeight="unset">
    {showLabel && (
      <Header inverted as={SlideTitle}>
        {data.title}
      </Header>
    )}
  </BackgroundImage>
);

Slide.defaultProps = {
  showLabel: false
};

const SelectionBody = ({
  question,
  appearance,
  onAnswerInput
}: Props): React.Node => {
  const { options, showImages, showLabels, disabled, type } = question;

  const { isMultipleAnswer, isChecklist, isRangingAnswer } = useQuestionType(
    type
  );

  const multi = isMultipleAnswer || isChecklist;

  const answer = useAnswer(question.id, {
    data: multi ? [] : null,
    meta: {}
  });

  const { data, meta } = answer;

  const settings = question.settings || {};

  // const isOtherSelected = !isSelected(data, 'other', multi);

  const otherValue = meta?.other ?? null;

  const [otherCache, setCache] = React.useState(meta ? otherValue : '');

  // if onUpdate is not defined, all update actions are redundant
  const onUpdate = useAnswerUpdate(question.id);

  // clear valueCache
  useWillUnmount(() => {
    onUpdate(
      {
        meta: {
          valueCache: null
        }
      },
      true
    );
  });

  // if question has been changed,
  // then we should clear cache
  React.useEffect(() => {
    setCache(meta?.other ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id]);

  const changeOther = React.useCallback(
    nextOtherValue => {
      if (!onUpdate) return;
      setCache(nextOtherValue);
      onUpdate(
        merge(
          { ...answer },
          {
            errors: null,
            meta: {
              other: nextOtherValue
            }
          }
        )
      );
    },
    [answer, onUpdate]
  );

  const { handleSelectionByMulti, isExcludingItemSelected } = useSelection({
    items: options,
    selected: data,
    userInputs: meta,
    onUserInputChange: (nextAnswer, nextUserInput) => {
      changeOther(nextUserInput?.other);
    },
    onSelectionChange: (
      nextSelected,
      _,
      { isExcludingItemSelected: isExcludingOptionSelected }
    ) => {
      // если выбрана опция "другое", то берётся её текст из второго аргумента и вставляется в мету
      // если нет, то ничего
      const hasOther = isSelected(nextSelected, 'other', multi);

      let updates = [{ data: nextSelected, errors: null }];

      if (!hasOther) {
        updates = [...updates, { meta: { other: null } }];
      } else {
        updates = [...updates, { meta: { other: otherCache ?? '' } }];
      }

      const nextAnswer = merge({ ...answer }, ...updates);

      onUpdate(nextAnswer);

      if (onAnswerInput) {
        onAnswerInput(nextAnswer, {
          isExcludingOptionSelected
        });
      }
    },
    multi,
    max:
      // TODO: move this check into normalization
      (question.maxAnswers ?? 0) && +question.maxAnswers > 0
        ? +question.maxAnswers
        : null,
    showMaxWarningOnExceedingAttempts: true
  });

  const items = React.useMemo(
    () =>
      getOnlyVisible(options).map((opt: IQuestionOption) => {
        if (isExcludingItemSelected && opt.isExcluding) {
          if (isSelected(data, opt.id, multi)) {
            return opt;
          }
        }

        return {
          ...opt,
          disabled: opt.disabled || isExcludingItemSelected
        };
      }),
    [data, isExcludingItemSelected, multi, options]
  );

  if (appearance === 'slider') {
    return (
      <SelectionSlider
        items={items.filter(
          item =>
            // other cannot have image an so on
            item.id !== 'other'
        )}
        selected={data}
        showToggleButton={!isRangingAnswer}
        onSelect={handleSelectionByMulti}
        isMulti={multi}
        disabled={disabled}
        showLabels={showLabels}
      />
    );
  }

  return (
    <Options
      imageSrc={settings.imageSrc}
      showTextOptions={settings.showTextOptions}
      questionId={question.id}
      multi={multi}
      items={items}
      selected={data}
      disabled={disabled}
      showImages={showImages}
      showLabels={showLabels}
      otherOption={meta?.other ?? ''}
      onSelect={handleSelectionByMulti}
      onOtherOptionChange={changeOther}
    />
  );
};

type SelectionSliderProps = {
  items: IQuestionOption[],
  selected: number[],
  onSelect: Function,
  showLabels?: boolean,
  showToggleButton?: boolean,
  isMulti: boolean
};

function SelectionSlider({
  items,
  selected,
  onSelect,
  isMulti,
  showLabels,
  showToggleButton
}: SelectionSliderProps) {
  const [wrapperRef, { width }] = useMeasure();

  const ref = React.useRef(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const sliderParams = React.useMemo(
    () => ({
      slidesPerView: 1,
      loop: true,
      spaceBetween: 20,
      navigation: true,
      pagination: {
        type: 'bullets'
      },
      slideProps: { showLabel: showLabels }
    }),
    [showLabels]
  );

  /**
   * Calculate minimal image height across all of the given images
   * in relation to current width of the container.
   * Being set as swiper's height it will result in all slides
   * to be displayed equal in height.
   */
  // const swiperHeight = React.useMemo(() => {
  //   if (!width) return null;
  //
  //   return min(
  //     map(imagesInfos, imageRatio => getHeightForRatio(width, imageRatio))
  //   );
  // }, [imagesInfos, width]);

  const onSwiper = React.useCallback(swiper => {
    ref.current = swiper;
  }, []);

  const onSlideChange = React.useCallback(() => {
    setActiveIndex(ref.current?.realIndex || 0);
  }, []);

  const item = items[activeIndex];

  const isItemDisabled = item?.disabled;

  return (
    <SliderContainer ref={wrapperRef}>
      {!!width && (
        <>
          <Slider
            {...sliderParams}
            onSlideChange={onSlideChange}
            onSwiper={onSwiper}
            swiperHeight="350px"
            swiperMinHeight="350px"
            data={items}
            SlideComponent={Slide}
          />
          <br />
        </>
      )}
      {showToggleButton && (
        <Button
          fluid
          labeled
          icon
          side="right"
          size="large"
          disabled={isItemDisabled || false}
          buttonType={
            isSelected(selected, item?.id || null, isMulti)
              ? 'negative'
              : 'primary'
          }
          onClick={() => {
            if (isItemDisabled) return;

            onSelect(item?.id);
          }}
        >
          {isSelected(selected, item?.id || null, isMulti) ? (
            <>
              <FormattedMessage id="common.labels.uncheck" />
              <Icon icon="times circle outline" />
            </>
          ) : (
            <>
              <FormattedMessage id="common.labels.check" />
              <Icon icon="check circle outline" />
            </>
          )}
        </Button>
      )}
    </SliderContainer>
  );
}

SelectionSlider.defaultProps = {
  showLabels: false,
  showToggleButton: false
};

export default SelectionBody;
