import revertDictionary from 'utils/revertDictionary';

const visibilitySettingsFormDictionary = revertDictionary({
  'visibilitySettingsFormDictionary.systemFields.label': {
    en: 'System fields',
    ru: 'Системные поля'
  },
  'visibilitySettingsForm.columns.title': {
    en: 'Field',
    ru: 'Поле'
  }
});

export default visibilitySettingsFormDictionary;
