/* eslint-disable array-callback-return */
// @flow
import { EMPTY, forkJoin } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import forEach from 'lodash/forEach';
import type { Epic } from 'flow-types/Epic';
import type { PrepareInterviewSuccess } from 'flow-types/actions/interview/PrepareInterview';
import type { ChangeActiveStack } from 'flow-types/actions/interview/ChangeActiveStack';
import type { PreloadAssets } from 'flow-types/actions/interview/PreloadAssets';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { AppState } from '../../flow-types/AppState';

/*
  Purpose of this epic is to preload assets of blocks, that would be shown to a user in near future.
*/

const registry = {
  /**
   * set of images identifiers, that were already told to be loaded
   */
  images: []
};

const preloadAssets: Epic = (action$, state$) =>
  action$.pipe(
    ofType(
      'interview/prepare-complete',
      // TODO: create action FTD for "interview/preload-assets"
      'interview/preload-assets',
      'interview/change-active-stack'
    ),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(
      ([action]: [
        PrepareInterviewSuccess | ChangeActiveStack | PreloadAssets,
        AppState
      ]) => {
        if (action.type === 'interview/preload-assets') {
          const imageLoaders = [];

          forEach(action.structure, (el: IInterviewStructureElement) => {
            if (el.isSinglePage) {
              if (!el.items) return;
              el.items.forEach(stack => {
                stack.assets?.map(asset => {
                  if (registry.images.includes(asset.url)) {
                    return;
                  }

                  // set that image has been already requested
                  registry.images.push(asset.url);

                  imageLoaders.push(
                    new Promise(resolve => {
                      const img = new Image();

                      img.src = asset.url;

                      // TODO: #UNIFIED_LOADED_ASSETS_MECHANISM
                      img.onload = resolve;
                    })
                  );
                });
              });
              return;
            }

            el.assets?.map(asset => {
              if (registry.images.includes(asset.url)) {
                return;
              }

              // set that image has been already requested
              registry.images.push(asset.url);

              imageLoaders.push(
                new Promise(resolve => {
                  const img = new Image();

                  img.src = asset.url;

                  // TODO: #UNIFIED_LOADED_ASSETS_MECHANISM
                  img.onload = resolve;
                })
              );
            });
          });

          if (imageLoaders.length === 0) {
            return [
              {
                type: 'interview/preload-assets-complete'
              }
            ];
          }

          return forkJoin(...imageLoaders).pipe(
            /*
               If loading does not got in time (1.5 sec)
               then response with success event to proceed.
             */
            RxO.timeoutWith(1500, [
              {
                type: 'interview/preload-assets-complete'
              }
            ]),
            RxO.mapTo({ type: 'interview/preload-assets-complete' })
          );
        }

        // получаем информацию о вопросах в первых трёх стеках,
        // получаем данные об их картинках и загружаем их,
        // а также их разные вариации (в случае токена)

        return EMPTY;
      }
    )
  );

export default preloadAssets;
