import type { ProjectResponseState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectDetailAction } from 'flow-types/actions/projects/detail';
import filter from 'lodash/filter';

export const ERROR = {
  NO_ACCESS: 'projectResponse.error.noAccess'
};

const initialState: ProjectResponseState = {
  data: null,
  loading: false,
  completingReview: false,
  error: null
};

export default function responseReducer(
  state: ProjectResponseState = initialState,
  action: ProjectDetailAction
) {
  switch (action.type) {
    case 'project-response/complete-review':
      return {
        ...state,
        completingReview: true
      };

    case 'project-response/complete-review-fail':
      return {
        ...state,
        completingReview: false
      };

    case 'project-response/complete-review-success':
      return {
        ...state,
        ...(!!action.reviewStageId && {
          data: {
            ...state.data,
            reviewStageId: action.reviewStageId
          }
        }),
        completingReview: false
      };

    case 'project-response/update-review-stage':
      return {
        ...state,
        data: {
          ...state.data,
          reviewStageId: action.reviewStageId
        }
      };

    case 'project-response/reset':
      return initialState;

    case 'project/fetch-response':
      return {
        ...state,
        error: null,
        loading: true
      };

    case 'project/fetch-response-fail':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case 'project/fetch-response-success':
      return {
        ...state,
        data: action.response,
        error: null,
        loading: false
      };

    case 'project/update-response':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.dataUpdate
        }
      };

    case 'project-responses/enable-test-element-success':
      return {
        ...state,
        data: {
          ...state.data,
          disabledChecklistItems: filter(
            state.data.disabledChecklistItems,
            id => id !== action.testElementId
          )
        }
      };

    case 'project-responses/disable-test-element-success':
      return {
        ...state,
        data: {
          ...state.data,
          disabledChecklistItems: Array.isArray(
            state.data.disabledChecklistItems
          )
            ? [...state.data.disabledChecklistItems, action.testElementId]
            : [action.testElementId]
        }
      };

    case 'project/save-response-comment':
      return {
        ...state,
        loading: true
      };

    case 'project/save-response-comment-fail':
    case 'project/save-response-comment-success':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
