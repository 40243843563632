/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Field from 'common/components/Form/Field';
import Dropdown from 'common/components/Dropdown';

import { TYPES, getQuestionTypeLocaleMessageId } from 'common/helpers/question';

type Props = {|
  value: number,
  onChange: Function,
  editable: boolean,
  noLabel: boolean,
  validationState: any,
  filterOption?: null | ((item: Object, groupId: 'block' | 'system') => boolean)
|};

export const postPollingFilter = item =>
  [TYPES.HTML, TYPES.Redirect].includes(item.id);

export const nonStatusBlockFilter = item => item.id !== TYPES.Status;

const QuestionTypeSelector = ({
  value,
  onChange,
  editable,
  validationState,
  noLabel,
  filterOption,
  ...props
}: Props) => {
  const intl = useIntl();

  const options = useMemo(() => {
    let types = [
      {
        id: 'blocks',
        title: intl.formatMessage({ id: 'question.systemType.blocks' }),
        options: [
          TYPES.TextBlock,
          TYPES.Numeric,
          TYPES.MobilePhone,
          TYPES.SecondaryPhones,
          TYPES.Email,
          TYPES.SingleAnswer,
          TYPES.MultipleAnswer,
          TYPES.Checklist,
          TYPES.Signature,
          TYPES.Ranging,
          TYPES.Rating,
          TYPES.Table,
          TYPES.DateTime,
          TYPES.URL,
          TYPES.Video,
          TYPES.HTML
        ].map(type => ({
          id: type,
          title: intl.formatMessage({
            id: getQuestionTypeLocaleMessageId(type)
          })
        })),
        group: true
      },
      {
        id: 'system',
        title: intl.formatMessage({ id: 'question.systemType.system' }),
        options: [
          TYPES.SearchView,
          TYPES.TabView,
          TYPES.Redirect,
          TYPES.Status
        ].map(type => ({
          id: type,
          title: intl.formatMessage({
            id: getQuestionTypeLocaleMessageId(type)
          })
        })),
        group: true
      }
    ];

    if (filterOption) {
      types = types.map(typesGroup => ({
        ...typesGroup,
        options: typesGroup.options.filter(item =>
          filterOption(item, typesGroup.id)
        )
      }));
    }

    return types;
  }, [filterOption, intl]);

  return (
    <Field id="question_type" validationState={validationState}>
      {!noLabel && (
        <label>
          <FormattedMessage id="questionForm.fields.type.label" />
        </label>
      )}
      <Dropdown
        {...props}
        valueKey="id"
        labelKey="title"
        disabled={!editable}
        onChange={onChange}
        keepSelected
        resetValue={null}
        value={value}
        options={options}
        onlyValue
      />
    </Field>
  );
};

QuestionTypeSelector.defaultProps = {
  filterOption: null
};

export default QuestionTypeSelector;
