import { EMPTY, merge, of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  delay,
  catchError,
  mapTo,
  withLatestFrom
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { INTERVIEW_MODE } from 'common/helpers/interview/constants';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import request from 'utils/request';
import { API } from 'utils/config';

import completeInterview from '../../actions/interview/completeInterview';
import createSingOut from '../../actions/auth/signOut';
import { authStateSelector } from '../../selectors';
import { failedAnswersSelector } from '../../selectors/interview/answers';
import { interviewRecordSelector } from '../../selectors/interview/root';
import { interviewStatusesSelector } from '../../selectors/interview/statuses';

export const completeInterviewEpic = (action$, state$) =>
  action$.pipe(
    ofType('interview/complete'),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const { data: record } = interviewRecordSelector(state);
      const { token } = authStateSelector(state);

      const hasFailedAnswers = failedAnswersSelector(state).length > 0;

      if (hasFailedAnswers) {
        // interview/submit-answers action has own retry mechanist
        // if some of the answers are not sent yet – we just have to wait

        // @TODO semanticaly it makes more sense to throw an error
        // and handle it with catchError operator, like this
        // return throwError(new Error('unsubmitted_answers'));
        return of({
          type: 'interview/complete-fail',
          message: 'unsubmitted_answers'
        });
      }

      const {
        isPostPolling,
        isPublicPolling,
        mode: interviewMode
      } = interviewStatusesSelector(state);

      let responseId;

      if (record) {
        responseId = record.id;
      }

      if (interviewMode !== INTERVIEW_MODE.REMOTE) {
        return of({}).pipe(
          delay(300),
          mergeMap(() =>
            [
              { type: 'interview/complete-success' },
              isPostPolling && {
                type: 'interview/move',
                forward: true,
                restart: false,
                silent: true
              }
            ].filter(Boolean)
          )
        );
      }

      return request({
        url: interpolateString(
          API.responses.complete,
          decamelizeKeys({
            responseId
          })
        ),
        method: 'POST',
        token: (isPostPolling || isPublicPolling) && token
      }).pipe(
        responseParser,
        mergeMap(() => {
          // PostPolling only:
          // we create sign out action to reuse sing-out epic,
          // but we don't want to move to LoginPage afterwards,
          // also we want to remove only session token
          const signOut$ = isPostPolling
            ? of(createSingOut({ manual: true }))
            : EMPTY;

          const move$ = isPostPolling
            ? of({
                type: 'interview/move',
                forward: true,
                restart: false,
                silent: true
              })
            : EMPTY;

          return merge(
            move$,
            of({ type: 'interview/complete-success' }),
            signOut$
          );
        }),
        catchError(({ message }: AjaxError) =>
          of({ type: 'interview/complete-fail', message })
        )
      );
    })
  );

export const retryCompleteInterviewEpic = action$ =>
  // @TODO should we handle 422? FORMS-1050
  action$.pipe(
    ofType('interview/complete-fail'),
    switchMap(() => of(null).pipe(delay(15000), mapTo(completeInterview())))
  );
