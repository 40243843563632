import { of } from 'rxjs';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import keyBy from 'lodash/keyBy';
import { toast } from 'react-toastify';
import listResponseParser from 'common/epicHelpers/listResponseParser';
import request from '../../utils/request';
import { API } from '../../utils/config';
import regionsNormalizer from '../../common/transducers/regions/normalizer';
import decamelizeKeys from '../../common/helpers/decamelizeKeys';
import responseParser from '../../common/epicHelpers/responseParser';
import type { Epic } from '../../flow-types/Epic';
import { recordsRegionsStateSelector } from '../../selectors/records';

const fetchRegionsEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('records-regions/fetch'),
    RxOperators.withLatestFrom($state),
    RxOperators.map(combined => {
      const [action, state] = combined;

      const { filter } = recordsRegionsStateSelector(state);

      return {
        filter,
        ...action
      };
    }),
    RxOperators.switchMap(({ filter, responseId }) =>
      request({
        url: API.uploads.regions,
        query: decamelizeKeys({ ...filter, responseId }),
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        RxOperators.map(({ data, pagination }) => ({
          type: 'records-regions/fetch-success',
          data: keyBy(regionsNormalizer(data), 'id'),
          pagination
        })),
        RxOperators.catchError(({ response, message, status }) => {
          toast.error(`something wrong while loading regions (${status})`, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'records-regions/fetch-fail',
            error: response ? response.data : message
          });
        })
      )
    )
  );

export default fetchRegionsEpic;
