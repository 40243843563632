// @flow

import type { CloseModal, ModalId } from '../../pages/Modals/modals';

export default function closeModal(
  modalId: ModalId,
  dataReset?: boolean = false
): CloseModal {
  return {
    type: 'modals/close',
    modalId,
    dataReset
  };
}
