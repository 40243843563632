import { EMPTY, interval } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { toast } from 'react-toastify';
import reject from 'lodash/reject';
import type { Epic } from 'flow-types/Epic';
import type { CreateDecodingTaskSuccess } from 'flow-types/actions/records/decodings/tasks/CreateDecodingTask';
import type { IDecodingTask } from 'flow-types/entities/DecodingTask';
import { DECODING_TASK_STATUSES } from 'common/helpers/response';
import type { UpdateDecodingTaskStatus } from 'flow-types/actions/records/decodings/tasks/UpdateDecodingTaskStatus';
import type { FetchDecodings } from 'flow-types/actions/records/decodings/list/FetchDecodings';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import { camelizeAndNormalizeDecodingTask } from 'common/transducers/uploads/decodingTasksNormalizer';
import request from 'utils/request';
import type { WatchTask } from 'flow-types/actions/records/decodings/tasks/WatchTask';
import type { FetchDecodingTasksSuccess } from 'flow-types/actions/records/decodings/tasks/FetchDecodingTasks';
import localizeMessage from 'common/helpers/localizeMessage';
import {
  CREATE_TASK_SUCCESS,
  FETCH_TASKS_SUCCESS,
  UPDATE_TASK_STATUS
} from '../../../reducers/records/decodings/tasks';
import { FETCH_DECODINGS } from '../../../reducers/records/decodings/list';
import { getTaskStatus } from '../../../selectors/records/decodings';
import { getRecordName } from '../../../selectors/records';
import { languageStateSelector } from '../../../selectors';

const WATCH_TASK = 'records/watch-decoding-task';

const watchTask$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(WATCH_TASK, CREATE_TASK_SUCCESS),
    RxO.mergeMap(({ data }: CreateDecodingTaskSuccess | WatchTask) => {
      const { id: taskId, uploadId, providerId } = data;

      return interval(5000).pipe(
        RxO.exhaustMap((): [UpdateDecodingTaskStatus] =>
          request({
            url: interpolateString(API.uploads.decodings.tasks.detail, {
              uploadId,
              taskId
            }),
            method: 'GET'
          }).pipe(
            responseParser,
            RxO.pluck('data'),
            RxO.withLatestFrom(state$),
            RxO.mergeMap(([rawTask, state]) => {
              const currentStatus = getTaskStatus(taskId)(state);
              const recordName = getRecordName(uploadId)(state);
              const lang = languageStateSelector(state);

              const {
                status
              }: IDecodingTask = camelizeAndNormalizeDecodingTask(rawTask);

              // no need to dispatch unnecessary action
              if (currentStatus === status) return EMPTY;

              if (status === DECODING_TASK_STATUSES.ERROR) {
                const providerLabel = localizeMessage(
                  `transcription.providers.${providerId}`,
                  lang
                )();

                toast.error(
                  `Во время дешифровки записи ${recordName} через сервис "${providerLabel}" произошла ошибка`,
                  { autoClose: false, showCloseButton: true }
                );
              }

              return [
                {
                  type: UPDATE_TASK_STATUS,
                  taskId,
                  providerId,
                  uploadId,
                  status
                }
              ];
            }),
            RxO.catchError(() => EMPTY)
          )
        ),
        RxO.takeUntil(
          action$.pipe(
            ofType(UPDATE_TASK_STATUS, FETCH_DECODINGS),
            RxO.filter((action: UpdateDecodingTaskStatus | FetchDecodings) => {
              if (action.type === FETCH_DECODINGS) return true;

              if (
                action.providerId === providerId &&
                action.uploadId === uploadId &&
                action.taskId === taskId
              ) {
                return [
                  DECODING_TASK_STATUSES.READY,
                  DECODING_TASK_STATUSES.ERROR
                ].includes(action.status);
              }

              return false;
            })
          )
        )
      );
    })
  );

export const watchActiveTasksOnFetchTasks$: Epic = action$ =>
  action$.pipe(
    ofType(FETCH_TASKS_SUCCESS),
    RxO.mergeMap((action: FetchDecodingTasksSuccess) => {
      const onlyActive = reject(action.data, (task: IDecodingTask) =>
        [DECODING_TASK_STATUSES.ERROR, DECODING_TASK_STATUSES.READY].includes(
          task.status
        )
      );

      return onlyActive.map((data: IDecodingTask): WatchTask => ({
        type: WATCH_TASK,
        data
      }));
    })
  );

export default watchTask$;
