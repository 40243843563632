const dictionary = {
  'agitations.waveForm.fields.title.label': {
    ru: 'Название волны',
    en: 'Wave title'
  },
  'agitations.waveForm.fields.projectId.label': {
    ru: 'Проект',
    en: 'Project'
  },
  'agitations.waveForm.fields.period.label': {
    ru: 'Сроки проведения',
    en: 'Schedule'
  },
  'agitations.waveForm.fields.config.label': {
    ru: 'Список распределения по менеджерам',
    en: 'Delegation list by managers'
  },
  'agitations.waveForm.actions.addConfig': {
    ru: 'Добавить распределение',
    en: 'Add delegation'
  },
  'agitations.waveForm.fields.config.emptyListMessage': {
    ru: 'Список распределения пуст',
    en: 'Assignment list is empty'
  },
  'agitations.waveForm.fields.config.manager.placeholder': {
    ru: 'Укажите менеджера',
    en: 'Select manager'
  },
  'agitations.waveForm.fields.config.manager.noOptionsMessage': {
    ru: 'Не нашлось подходящих менеджеров',
    en: 'No suited managers'
  },
  'agitations.waveForm.fields.config.buildings.placeholder': {
    ru: 'Укажите здания',
    en: 'Select buildings'
  },
  'agitations.waveForm.fields.config.buildings.noOptionsMessage': {
    ru: 'Нет подходящих зданий',
    en: 'No suited building'
  },
  'agitations.waveForm.fields.config.rooms.placeholder': {
    ru: 'Укажите помещения (опционально)',
    en: 'Select rooms (optional)'
  },
  'agitations.waveForm.fields.config.rooms.noOptionsMessage': {
    ru: 'Нет подходящих помещений',
    en: 'No suited rooms'
  }
};

export default dictionary;
