// @flow

import React from 'react';
import cx from 'classnames';
import type { Node } from 'react';
import type { ReactComponent } from 'flow-types/ReactComponent';

type Props = {
  stackable?: boolean,
  doubling?: boolean,
  colCount?: string,
  group?: boolean,
  fluid?: boolean,
  centered?: boolean,
  raised?: boolean,
  inverted?: boolean,
  color?: null | string,
  className?: null | string,
  tagName?: ReactComponent
};

export default function Card({
  group,
  fluid,
  className,
  stackable,
  doubling,
  colCount,
  centered,
  raised,
  inverted,
  color,
  tagName,
  ...props
}: Props): Node {
  const Component = tagName ?? 'div';

  const classNames = cx(
    'ui',
    color,
    {
      fluid,
      raised,
      centered,
      inverted,
      doubling: group && doubling,
      stackable: group && stackable
    },
    group ? colCount : null,
    !group ? 'card' : 'cards',
    className
  );

  return <Component {...props} className={classNames} />;
}

Card.defaultProps = {
  tagName: 'div',
  group: false,
  className: null,
  stackable: false,
  doubling: false,
  colCount: false,
  fluid: false,
  centered: false,
  raised: false,
  inverted: false,
  color: null
};
