// @flow

import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { camelizeKeys } from 'humps';
import { API } from 'utils/config';
import request from 'utils/request';
import decamelizeAndDenormalizeAnswer from 'common/transducers/answers/answerDenormalizer';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import type {
  IInterviewAnswer,
  ISubmittableInterviewAnswer
} from 'flow-types/entities/InterviewAnswer';
import type { IAnswer } from 'flow-types/entities/Answer';
import type { IComment } from 'flow-types/entities/Comment';
import type { IView } from 'flow-types/entities/View';
import type { UpdateResponseAnswerSuccess } from 'flow-types/actions/projects/detail/responses/UpdateResponseAnswer';
import closeModal from '../../../actions/modals/close';
import { MODAL_ID } from '../components/Modals/EditResponseAnswerModal';

type UpdateResponseAnswer = {
  type: 'project/update-response-answer',
  answer: IInterviewAnswer,
  responseId: number
};

type SubmitAnswerSuccessResponse$Data = {
  answer: IAnswer,
  comments: IComment[],
  views: IView[]
};

const updateResponseAnswer = action$ =>
  action$.pipe(
    ofType('project/update-response-answer'),
    RxOperators.concatMap(
      ({
        answer,
        timeEnd,
        timeStart,
        localId,
        responseId,
        question
      }: UpdateResponseAnswer) => {
        const mixedAnswer: ISubmittableInterviewAnswer = {
          ...answer,
          question,
          timeEnd,
          timeStart,
          localId,
          questionId: question.id
        };

        /* {
        timeEnd: timeStamp,
        timeStart: lastEntryStamp || timeStamp,
        question: question || null,
        localId,
        questionId
      */

        const normalizedAnswer = decamelizeAndDenormalizeAnswer(mixedAnswer);

        return request({
          url: interpolateString(API.responses.answer, {
            response_id: responseId
          }),
          method: 'POST',
          body: normalizedAnswer
        }).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.map(data => camelizeKeys(data)),
          RxOperators.mergeMap(
            ({
              answer: nextAnswer,
              comments,
              views
            }: SubmitAnswerSuccessResponse$Data): [
              UpdateResponseAnswerSuccess
            ] => [
              closeModal(MODAL_ID, true),
              {
                type: 'project/update-response-answer-success',
                responseId,
                questionId: question.id,
                answer: nextAnswer,
                question,
                comments,
                views
              }
            ]
          ),
          RxOperators.catchError(() => [
            {
              type: 'project/update-response-answer-fail'
            },
            {
              type: 'modals/update-data',
              modalId: MODAL_ID,
              dataUpdate: {
                isLoading: false
              }
            }
          ])
        );
      }
    )
    // here goes the code
  );

export default updateResponseAnswer;
