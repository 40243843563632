import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import find from 'lodash/find';

import Modal from '../../../../common/components/animated/Modal';
import Button from '../../../../common/components/Button';
import Icon from '../../../../common/components/Icon';

import { recordsRegionsStateSelector } from '../../../../selectors/records';
import useModal from '../../../../common/hooks/useModal';

const dimmerProps = {
  scrolling: true,
  fixed: true,
  page: true
};

const isCurrentRegionIsSavingSelector = createSelector(
  recordsRegionsStateSelector,
  regionsState => {
    const { data, selected } = regionsState;

    const region = find(data, { id: selected });

    if (!region) return false;

    return !!region.loading;
  }
);

export const MODAL_ID = 'activeRegionHasUnsavedChanges';

export default function RegionHasUnsavedChangesModal() {
  const dispatch = useDispatch();

  const [state, , close] = useModal(MODAL_ID);

  const isCurrentRegionIsSaving = useSelector(isCurrentRegionIsSavingSelector);

  const handleExit = useCallback(() => {
    if (!state.data) return;

    dispatch({
      type: 'records-regions/reset',
      regionId: state.data.sourceRegionId
    });

    dispatch({
      type: 'records-regions/select',
      regionId: state.data.targetRegionId
    });
    close();
  }, [close, dispatch, state.data]);

  const handleSave = useCallback(() => {
    if (!state.data) return;

    dispatch({
      type: 'records-regions/save',
      regionId: state.data.sourceRegionId
    });

    dispatch({
      type: 'records-regions/reset',
      regionId: state.data.targetRegionId
    });
  }, [dispatch, state.data]);

  const handleSaveAndExit = useCallback(() => {
    if (!state.data) return;

    dispatch({
      type: 'records-regions/save',
      regionId: state.data.sourceRegionId,
      nextRegionId: state.data.targetRegionId
    });
  }, [dispatch, state.data]);

  const handleCancel = useCallback(() => {
    if (!state.data) return;

    dispatch({
      type: 'records-regions/reset',
      regionId: state.data.targetRegionId
    });

    close();
  }, [close, dispatch, state.data]);

  const headerIcon = useMemo(() => {
    if (!state.visible || !isCurrentRegionIsSaving) {
      return <Icon icon="exclamation triangle" />;
    }

    return <Icon icon="notched circle loading" />;
  }, [isCurrentRegionIsSaving, state.visible]);

  return (
    <Modal
      size="small"
      dimmerProps={dimmerProps}
      basic
      visible={state.visible}
      active={state.visible}
      onClose={handleCancel}
      portal
    >
      <Modal.Header icon>
        {headerIcon}
        <FormattedMessage id="regionWithUnsavedChangesModal.title" />
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage
          id="regionWithUnsavedChangesModal.content"
          tagName="p"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" inverted onClick={handleSaveAndExit}>
          <FormattedMessage id="common.labels.saveAndExit" />
        </Button>
        <Button color="blue" inverted onClick={handleSave}>
          <FormattedMessage id="common.labels.save" />
        </Button>
        <Button color="red" inverted onClick={handleExit}>
          <FormattedMessage id="regionWithUnsavedChangesModal.actions.cancelChanges" />
        </Button>
        <Button inverted onClick={handleCancel}>
          <FormattedMessage id="common.labels.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
