// @flow
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import type { Node } from 'react';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import React from 'react';
import { projectIdFromPathSelector } from '../../../../../selectors/projects';

const query = async (key, projectId, notificationId): Promise<any> => {
  const response = await request({
    url: interpolateString(API.projects.pollingNotifications.responsesCodes, {
      projectId,
      notificationId
    }),
    method: 'GET'
  }).toPromise();

  return response?.response?.data || [];
};

type Props = {
  notificationId: number
};

export default function SentPollingNotificationsCounter({
  notificationId
}: Props): Node {
  const projectId = useSelector(projectIdFromPathSelector);

  const { data } = useQuery(
    ['sentPollingNotificationsCounter', projectId, notificationId],
    () => query(null, projectId, notificationId),
    {
      keepPreviousData: true,
      enabled: !!projectId && !!notificationId,
      initialData: () => [],
      forceFetchOnMount: true,
      refetchInterval: 3000
    }
  );

  return <span>{data.length}</span>;
}
