import fp from 'lodash/fp';
import { createColorFieldParser } from 'common/transducers/colorPickerValueParser';
import { filterNewGeneratedProperties } from '../../helpers/generateNewData';
import sortingItemsNormalizer from '../sortingItemsNormalizer';
import decamelizeKeys from '../../helpers/decamelizeKeys';

const processOptionSortOrder = option => {
  const { order, ...rest } = option;

  return {
    ...rest,
    sortOrder: order
  };
};

const appearanceProcessor = fp.compose(
  createColorFieldParser('textColor'),
  createColorFieldParser('backgroundColor')
);

const processAppearance = settings => {
  if (!settings || !settings.appearance) return settings;

  const appearance = appearanceProcessor(settings.appearance);

  return {
    ...settings,
    appearance
  };
};

const processSettings = fp.compose(processAppearance);

const processItemSettings = ({ settings, ...item }) => ({
  ...item,
  settings: processSettings(settings)
});

export const denormalizeOption = fp.compose(
  filterNewGeneratedProperties,
  processOptionSortOrder,
  processItemSettings
);

export const decamelizeAndDenormalizeOption = fp.compose(
  decamelizeKeys,
  denormalizeOption
);

const decamelizeAndDenormalizeOptions = fp.compose(
  fp.map(decamelizeAndDenormalizeOption),
  sortingItemsNormalizer
);

export default decamelizeAndDenormalizeOptions;
