import get from 'lodash/get';

const getValidationState = (error, path, informative = false) => {
  const validationState = get(error, path);

  if (validationState) {
    if (!informative) {
      return 'error';
    }

    return {
      state: 'error',
      error: validationState
    };
  }

  if (!informative) {
    return null;
  }

  return {
    state: null,
    error: null
  };
};

export default getValidationState;
