// special use case for that button is when you need to confirm your action

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import uniqueId from 'lodash/uniqueId';
import useClickOutside from 'use-onclickoutside';
import { usePopper } from 'react-popper';
import { flip, preventOverflow } from '@popperjs/core';
import split from 'lodash/split';
import { FormattedMessage } from 'react-intl';
import type { ReactComponent } from 'flow-types/ReactComponent';
import stopEventLifting from 'common/helpers/stopEventLifting';
import Button from './Button';
import Popup from './Popup';
import Text from './Text';

const offsetCalc = () => [0, 10];

type Props = {
  id?: string,
  popupProps?: Object,
  popperOptions?: Object,
  // enables popper preventOverflow
  allowOverflow?: boolean,
  confirmMessage?: string,
  tagName?: ReactComponent,
  onSubmit?: null | (() => void)
};

export default function ConfirmButton({
  confirmMessage,
  onSubmit,
  id,
  popupProps,
  popperOptions,
  tagName: Component,
  allowOverflow,
  ...props
}: Props) {
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const [referenceElement, setReference] = useState(null);
  const [popupElement, setPopupElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const popupElementRef = useRef(null);

  useEffect(() => {
    popupElementRef.current = popupElement;
  }, [popupElement]);

  useClickOutside(popupElementRef, event => {
    if (isPopupVisible && event) {
      const { target } = event;

      if (target.id === referenceElement.id) return;

      setPopupVisibility(false);
    }
  });

  const { styles, attributes } = usePopper(referenceElement, popupElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: 'offset',
        options: {
          offset: offsetCalc
        }
      },
      allowOverflow && preventOverflow,
      flip
    ].filter(Boolean),
    ...(popperOptions && popperOptions)
  });

  const popupPosition = useMemo(() => {
    if (!attributes || !attributes.popper) return null;

    const [position] = split(attributes.popper['data-popper-placement'], '-');

    if (position === 'auto') return null;

    return position;
  }, [attributes]);

  const handleButtonClick = useCallback(e => {
    if (e) {
      stopEventLifting(e);
    }

    setPopupVisibility(prev => !prev);
  }, []);

  const handleSubmit = useCallback(
    e => {
      setPopupVisibility(false);

      if (!onSubmit) return;

      onSubmit(e);
    },
    [onSubmit]
  );

  const handleCancel = useCallback(() => {
    setPopupVisibility(false);
  }, []);

  const buttonId = useMemo(() => {
    if (!id) return uniqueId('button-');

    return id;
  }, [id]);

  if (!Component) {
    return null;
  }

  return (
    <>
      <Component
        {...props}
        id={buttonId}
        onClick={handleButtonClick}
        ref={setReference}
      />
      {isPopupVisible && (
        <Popup
          {...(popupProps && popupProps)}
          visible
          transition
          ref={setPopupElement}
          position={popupPosition}
          style={styles.popper}
          {...(attributes.popper && attributes.popper)}
        >
          <div className="ui compact grid">
            <div className="row">
              <div className="column">
                <Text nonUI={false}>{confirmMessage}</Text>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <Button
                  type="button"
                  size="mini"
                  compact
                  onClick={handleCancel}
                >
                  <FormattedMessage id="common.labels.cancel" />
                </Button>
                <Button
                  size="mini"
                  type="button"
                  compact
                  buttonType="primary"
                  onClick={handleSubmit}
                >
                  <FormattedMessage id="common.labels.ok" />
                </Button>
              </div>
            </div>
          </div>
          <div
            className="arrow"
            ref={setArrowElement}
            style={styles.arrow}
            {...(attributes.arrow && attributes.arrow)}
          />
        </Popup>
      )}
    </>
  );
}

ConfirmButton.defaultProps = {
  confirmMessage: 'Do you want to execute action?',
  onSubmit: null,
  id: null,
  popupProps: null,
  popperOptions: null,
  allowOverflow: false,
  tagName: Button
};
