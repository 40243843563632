import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import type { Epic } from 'flow-types/Epic';
import type {
  FetchSearchSettings,
  FetchSearchSettingsFail,
  FetchSearchSettingsSuccess
} from 'flow-types/actions/projects/detail/searchSettings/FetchSearchSettings';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import { normalizeSearchSettings } from 'common/transducers/projects/searchSettingsNormalizer';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

const fetchSearchSettings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/fetch-search-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([, state]: [FetchSearchSettings, AppState]) => {
      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(API.projects.searchSettings.list, { projectId }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map((rawData): FetchSearchSettingsSuccess => ({
          type: 'project/fetch-search-settings-success',
          data: normalizeSearchSettings(rawData)
        })),
        RxOperators.catchError((error: AjaxError): [
          FetchSearchSettingsFail
        ] => [
          {
            type: 'project/fetch-search-settings-fail',
            error: error.response
          }
        ])
      );
    })
  );

export default fetchSearchSettings;
