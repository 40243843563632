import React, { useMemo } from 'react';
import ModernOther from 'common/components/Question/Options/Other/ModernOther';
import ClassicOther from 'common/components/Question/Options/Other/ClassicOther';

const THEMED = {
  classic: ClassicOther,
  modern: ModernOther
};

type Props = {
  theme: 'classic' | 'modern'
};

const Option = ({ theme, ...props }: Props) => {
  const Component = useMemo(() => THEMED[theme], [theme]);

  return <Component {...props} />;
};

export default Option;
