import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import type {
  RemoveSearchSettings,
  RemoveSearchSettingsFail,
  RemoveSearchSettingsSuccess
} from 'flow-types/actions/projects/detail/searchSettings/RemoveSearchSettings';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

const removeSearchSettings = (action$, state$) =>
  action$.pipe(
    ofType('project/remove-search-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(
      ([action, state]: [RemoveSearchSettings, AppState]) => {
        const projectId = projectIdFromPathSelector(state);

        return request({
          url: interpolateString(API.projects.searchSettings.detail, {
            projectId,
            settingsId: action.settingsId
          }),
          method: 'DELETE'
        }).pipe(
          RxOperators.map((): RemoveSearchSettingsSuccess => ({
            type: 'project/remove-search-settings-success',
            settingsId: action.settingsId
          })),
          RxOperators.catchError((error: AjaxError): [
            RemoveSearchSettingsFail
          ] => [
            {
              type: 'project/remove-search-settings-fail',
              settingsId: action.settingsId,
              error: error.response
            }
          ])
        );
      }
    )
  );

export default removeSearchSettings;
