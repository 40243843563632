// @flow

import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import { createSelector } from 'reselect';
import takeSecondArg from 'common/helpers/takeSecondArg';
import { modalsStateSelector } from '../../selectors';

import type {
  CloseModal,
  ModalId,
  ModalState,
  OpenModal,
  UpdateModalData
} from '../../pages/Modals/modals';
import useWillUnmount from './useWillUnmount';

type UseModal$Config = {
  hideOnUnmount?: boolean
};

const createModalsStatesSelectorCreator = (): Function =>
  createSelector(
    [modalsStateSelector, takeSecondArg],
    (modalsState, modalsIds) => {
      if (size(modalsIds) === 0) return modalsState;

      return reduce(
        modalsIds,
        (result, modalId) => {
          let modalState = {
            visible: false
          };

          if (modalsState[modalId]) {
            modalState = { ...modalsState[modalId] };
          }

          return { ...result, [modalId]: modalState };
        },
        {}
      );
    }
  );

export const openModal$ = (
  dispatch: Function,
  modalId: string,
  data: any
): void => {
  dispatch(
    ({
      type: 'modals/open',
      modalId,
      data
    }: OpenModal)
  );
};

export const closeModal$ = (
  dispatch: Function,
  modalId: string,
  dataReset: boolean
) => {
  const action: CloseModal = {
    type: 'modals/close',
    modalId,
    dataReset
  };

  dispatch(action);
};

export const updateData$ = (
  dispatch: Function,
  modalId: string,
  dataUpdate: any
) => {
  const action: UpdateModalData = {
    type: 'modals/update-data',
    modalId,
    dataUpdate
  };

  dispatch(action);
};

export function useModals(modalsIds: Array<ModalId>): [ModalState<any>[]] {
  const selector = useMemo(createModalsStatesSelectorCreator, []);

  const modalsState: ModalState<any>[] = useSelector(state =>
    selector(state, modalsIds)
  );

  return [modalsState];
}

const modalStateSelectorCreator = () =>
  createSelector(modalsStateSelector, takeSecondArg, (modals, modalId) => {
    if (!modalId || !modals[modalId]) {
      return {
        visible: false,
        data: null
      };
    }

    return modals[modalId];
  });

function useModal<MyState>(
  modalId: ModalId,
  config?: UseModal$Config
): [ModalState<null | MyState>, Function, Function, Function] {
  const dispatch = useDispatch();

  // own instance of selector for each useModal usage
  const modalStateSelector = useMemo(modalStateSelectorCreator, []);

  const state = useSelector(
    appState => modalStateSelector(appState, modalId),
    shallowEqual
  );

  const open = useCallback(
    (data: mixed = null) => {
      openModal$(dispatch, modalId, data);
    },
    [dispatch, modalId]
  );

  const close = useCallback(
    (dataReset: boolean) => {
      closeModal$(dispatch, modalId, !!dataReset);
    },
    [dispatch, modalId]
  );

  const updateData = useCallback(
    dataUpdate => {
      updateData$(dispatch, modalId, dataUpdate);
    },
    [dispatch, modalId]
  );

  useWillUnmount(() => {
    if (state.visible && config && config.hideOnUnmount) {
      close(true);
    }
  });

  return [state, open, close, updateData];
}

export function useOpenModal(modalId: ModalId): Function {
  const dispatch = useDispatch();

  return useCallback(
    (data: mixed = null) => {
      openModal$(dispatch, modalId, data);
    },
    [dispatch, modalId]
  );
}

export default useModal;
