// @flow
// IS AUTHENTICATED ROUTE GUARD
// if token does not exist and isLoggedIn is false then redirects user to loginPage
import type { GetState } from 'redux-first-router';
import { redirect } from 'redux-first-router';
import type { OnBeforeChangeFn } from 'flow-types/router/OnBeforeChangeFn';
import type { AppDispatch } from 'flow-types/AppDispatch';
import type { AuthState } from 'flow-types/states/AuthState';
import { authStateSelector } from '../../selectors';
import getToken from '../../utils/getToken';

const isAuthGuard: OnBeforeChangeFn = (
  dispatch: AppDispatch,
  getState: GetState
) => {
  try {
    const state = getState();
    const auth: AuthState = authStateSelector(state);
    const token = getToken();
    if (!auth.isLoggedIn && !token) {
      dispatch(redirect({ type: 'router/loginPage' }));
      return true;
    }
    return false;
  } catch (e) {
    dispatch(redirect({ type: 'router/loginPage' }));
    return true;
  }
};

export default isAuthGuard;
