import Responsive from 'react-responsive';
import React, { useMemo } from 'react';
import defaults from 'lodash/defaults';
import { useMediaQuery } from 'react-responsive/src';
import { largestMobileScreen } from '../../../utils/semanticBreakpoints';

type UseMobileScreenOptions = {|
  only?: boolean,
  from?: boolean
|};

const initialOptions = {
  only: false,
  from: false
};

export function useMobileScreen(
  options: UseMobileScreenOptions = initialOptions
): boolean {
  const { only, from } = useMemo(() => defaults(options, initialOptions), [
    options
  ]);

  const query = useMemo(() => {
    if (only) {
      return `only screen and (max-width: ${largestMobileScreen}px)`;
    }

    if (from) {
      return `only screen and (min-width: ${largestMobileScreen}px)`;
    }

    return `only screen and (min-width: 0px)`;
  }, [from, only]);

  return useMediaQuery({ query });
}

const Mobile = ({
  only,
  from,
  ...props
}: {
  +only?: boolean,
  +from?: boolean
}) => {
  let query = `only screen and (min-width: 0px)`;

  if (only) {
    query = `only screen and (max-width: ${largestMobileScreen}px)`;
  } else if (from) {
    query = `only screen and (min-width: ${largestMobileScreen}px)`;
  }

  return <Responsive {...props} query={query} />;
};

Mobile.defaultProps = {
  only: false,
  from: false
};

export default Mobile;
