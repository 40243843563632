import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import * as Operators from 'rxjs/operators';

import request from '../../utils/request';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import listResponseParser from '../../common/epicHelpers/listResponseParser';

import type { Epic } from '../../flow-types/Epic';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

import {
  crmDataNormalizer,
  crmQueryDenormalizer
} from '../common/CrmDataParser';

const fetchQueries: Epic = $action =>
  $action.pipe(
    ofType('crm/fetch-queries'),
    debounceEpic(),
    Operators.switchMap(() =>
      request({
        url: `${API.crm.queries}`,
        // url: 'http://localhost:8000/api/crm/queries',
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        Operators.map(response => {
          const { data } = response;
          // console.log(crmDataNormalizer(data));
          return {
            type: 'crm/fetch-queries-success',
            data: crmDataNormalizer(data)
          };
        }),
        Operators.catchError(({ response, message }) =>
          of({
            type: 'crm/fetch-queries-fail',
            error: response ? response.error : message
          })
        )
      )
    )
  );

const saveQuery: Epic = ($action, $state) =>
  $action.pipe(
    ofType('crm/query/save'),
    Operators.withLatestFrom($state),
    debounceEpic(),
    Operators.switchMap(epicData => {
      const state = epicData[1];
      const {
        queryComposer: { currentQuery }
      } = state;
      return request({
        url: `${API.crm.queries}/${currentQuery.id}`,
        method: 'PUT',
        body: crmQueryDenormalizer(currentQuery)
      }).pipe(
        responseParser,
        listResponseParser,
        Operators.switchMap(() => [
          {
            type: 'crm/query/close-composer'
          },
          {
            type: 'crm/fetch-queries'
          }
        ]),
        Operators.catchError(({ response, message }) =>
          of({
            type: 'crm/query/save-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

const addNewQuery: Epic = ($action, $state) =>
  $action.pipe(
    ofType('crm/query/add'),
    Operators.withLatestFrom($state),
    debounceEpic(),
    Operators.switchMap(epicData => {
      const state = epicData[1];
      const {
        queryComposer: { currentQuery }
      } = state;
      return request({
        url: `${API.crm.queries}`,
        method: 'POST',
        body: crmQueryDenormalizer(currentQuery)
      }).pipe(
        responseParser,
        listResponseParser,
        Operators.switchMap(() => [
          {
            type: 'crm/query/close-composer'
          },
          {
            type: 'crm/fetch-queries'
          }
        ]),
        Operators.catchError(({ response, message }) =>
          of({
            type: 'crm/query/save-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

const deleteQuery: Epic = $action =>
  $action.pipe(
    ofType('crm/query/delete'),
    debounceEpic(),
    Operators.switchMap(action =>
      request({
        url: `${API.crm.queries}/${action.id}`,
        method: 'DELETE'
      }).pipe(
        responseParser,
        listResponseParser,
        Operators.switchMap(() => [
          {
            type: 'crm/query/delete-success'
          },
          {
            type: 'crm/fetch-queries'
          }
        ]),
        Operators.catchError(({ response, message }) =>
          of({
            type: 'crm/query/delete-fail',
            error: response ? response.error : message
          })
        )
      )
    )
  );

const executeQuery: Epic = ($action, $state) =>
  $action.pipe(
    ofType('crm/query/execute'),
    Operators.withLatestFrom($state),
    // debounceEpic(),
    Operators.switchMap(epicData => {
      const [action, state] = epicData;
      const { code, filters } = action;
      const { query } = state.crm.views[code];
      query.filters = [...filters];
      return request({
        url: `${API.crm.executeQuery}`,
        method: 'POST',
        body: [crmQueryDenormalizer(query)]
      }).pipe(
        Operators.switchMap(response => {
          const {
            response: { data }
          } = response;
          return [
            {
              type: 'crm/view/set-query-result',
              code,
              queryResult: crmDataNormalizer(data[0])
            }
          ];
        }),
        Operators.catchError(({ response, message }) =>
          of({
            type: 'crm/query/execute-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

export default combineEpics(
  fetchQueries,
  saveQuery,
  deleteQuery,
  addNewQuery,
  executeQuery
);
