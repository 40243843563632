import revertDictionary from 'utils/revertDictionary';

const testGroupsDictionary = revertDictionary({
  'testGroups.formTitle.create': {
    en: 'Register test group',
    ru: 'Регистрация тест группы'
  },
  'testGroups.formTitle.edit': {
    en: 'Edit test group',
    ru: 'Обновление тест группы'
  },
  'testGroups.form.title': {
    en: 'Title',
    ru: 'Название'
  },
  'testGroups.form.guid': {
    en: 'GUID',
    ru: 'GUID'
  },
  'testGroups.form.points': {
    en: 'Points',
    ru: 'Баллы'
  },
  'testGroups.form.checklist': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'testGroups.form.color': {
    en: 'Color',
    ru: 'Цвет'
  },
  'testGroups.list.noItemsPlaceholder': {
    en: 'No test groups found',
    ru: 'Нет данных по группам'
  },
  'testGroups.actions.remove.message': {
    en: 'Are you definitely want to delete test group?',
    ru: 'Вы действительно хотите удалить тест-группу?'
  }
});

export default testGroupsDictionary;
