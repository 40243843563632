// @flow

import keyBy from 'lodash/keyBy';
import type { ExportAction } from 'flow-types/actions/projects/detail/export';
import type { ProjectExportSettingsListState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type { SaveExportSettingsSuccess } from 'flow-types/actions/projects/detail/export/SaveExportSettings';
import type { RemoveExportSettingsSuccess } from 'flow-types/actions/projects/detail/export/RemoveExportSettings';

const createInitialState = (): ProjectExportSettingsListState => ({
  data: {},
  isLoading: null,
  filter: {}
});

function handleSettingsSaved(
  state: ProjectExportSettingsListState,
  action: SaveExportSettingsSuccess
) {
  const next = { ...state };

  // just to suit flow that data is null
  if (next.data === null) return state;

  if (!next.data[action.data.id]) {
    next.data[action.data.id] = { ...action.data };
  } else {
    next.data[action.data.id] = {
      ...next.data[action.data.id],
      ...action.data
    };
  }

  next.isLoading = false;

  return next;
}

function handleSettingsRemoved(
  state: ProjectExportSettingsListState,
  action: RemoveExportSettingsSuccess
): ProjectExportSettingsListState {
  const next = { ...state };

  // just to suit flow that data is null
  if (next.data === null) return state;

  if (next.data[action.settingsId]) {
    delete next.data[action.settingsId];
  }

  return next;
}

export default function settingsListReducer(
  state: ProjectExportSettingsListState = createInitialState(),
  action: ExportAction
): ProjectExportSettingsListState {
  switch (action.type) {
    case 'project-export/fetch-settings-list':
      return {
        ...state,
        isLoading: true
      };

    case 'project-export/save-settings-success':
      return handleSettingsSaved(state, action);

    case 'project-export/fetch-settings-list-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'project-export/fetch-settings-list-success':
      return {
        ...state,
        isLoading: false,
        data: keyBy(action.data, 'id')
      };

    case 'project-export/remove-settings-success':
      return handleSettingsRemoved(state, action);

    default:
      return state;
  }
}
