// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { of } from 'rxjs';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import type { Action } from 'flow-types/Action';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import responseParser from 'common/epicHelpers/responseParser';
import type { AppState } from 'flow-types/AppState';
import {
  createCompleteReviewFail,
  createCompleteReviewSuccess
} from '../../../actions/project/response/completeReview';
import { languageStateSelector } from '../../../selectors';
import ajaxErrorsDictionary from '../../../intl/ajaxErrorsDictionary';
import { responseIdFromPathSelector } from '../../../selectors/projects/response';

const completeResponseReview: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-response/complete-review'),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([, state]: [Action, AppState]) => {
      const responseId = responseIdFromPathSelector(state);
      const language = languageStateSelector(state);
      return request({
        url: interpolateString(
          API.responses.completeReview,
          decamelizeKeys({ responseId })
        ),
        method: 'POST'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.pluck('review_stage_id'),
        RxO.map(reviewStageId => createCompleteReviewSuccess(reviewStageId)),
        RxO.catchError(() => {
          const ajaxErrorsDictionaryLocalized = ajaxErrorsDictionary[language];
          toast.error(ajaxErrorsDictionaryLocalized['ajax.error.NA'], {
            autoClose: 2500
          });
          return of(createCompleteReviewFail());
        })
      );
    })
  );

export default completeResponseReview;
