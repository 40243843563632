// @flow
import reduce from 'lodash/reduce';

/**
 * @param data
 * @param rootPath
 * @returns {*}
 * @example
 * // returns ["settings.likes[0].id", "settings.likes[0].title"]
 * constructUpdatedPaths({ settings: { likes: [{ id: 1, title: '111' }] } })
 */
export default function constructObjectMap(data, { rootPath, keepPaths } = {}) {
  return reduce(
    data,
    (result, value, key) => {
      const subPath = Array.isArray(data) ? `[${key}]` : `.${key}`;

      const path = rootPath ? `${rootPath}${subPath}` : key;

      if (typeof value === 'object') {
        const innerObjectMap = constructObjectMap(value, {
          rootPath: path,
          keepPaths
        });

        return keepPaths
          ? [...result, path, ...innerObjectMap]
          : [...result, ...innerObjectMap];
      }

      return [...result, path];
    },
    []
  );
}
