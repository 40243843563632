// @flow

import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  FetchPollingNotification,
  FetchPollingNotificationFail,
  FetchPollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/FetchPollingNotification';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import type { IPollingNotification } from 'flow-types/entities/PollingNotification';
import { camelizeAndNormalizePollingNotification } from 'common/transducers/projects/pollingNotifications/normalize';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../../selectors/projects';

const fetchPollingNotification: Epic = (action$, state$) =>
  action$.pipe<FetchPollingNotification>(
    ofType('project/fetch-polling-notification'),
    RxO.withLatestFrom(state$),
    RxO.switchMap<[FetchPollingNotification, AppState]>(([action, state]) => {
      const { notificationId } = action;

      const projectId = projectIdFromPathSelector(state);

      return request({
        url: interpolateString(API.projects.pollingNotifications.detail, {
          projectId,
          notificationId
        })
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map<Object, IPollingNotification>(
          camelizeAndNormalizePollingNotification
        ),
        RxO.map<IPollingNotification, FetchPollingNotificationSuccess>(
          data => ({
            type: 'project/fetch-polling-notification-success',
            data,
            notificationId
          })
        ),
        RxO.catchError<any, [FetchPollingNotificationFail]>(
          (ajaxError: AjaxError<any>) => {
            const { response, message } = ajaxError;

            return [
              {
                type: 'project/fetch-polling-notification-fail',
                error: response ? response.data : message
              }
            ];
          }
        )
      );
    })
  );

export default fetchPollingNotification;
