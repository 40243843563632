// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import fp from 'lodash/fp';
import cx from 'classnames';
import type { ReactChildren } from 'flow-types/ReactChildren';
import useProgressiveImage from 'common/components/Image/useProgressiveImage';

type Props = {
  className?: null | string,
  height: string | number,
  /**
   * Можно выбрать тот размер который компонент постарается использовать.
   * В случае если указанного размера нет - используется 'original'.
   */
  size?: null | '128' | '256' | 'original',
  image:
    | string
    | {
        imageInfo: {
          /**
           * Original image width
           */
          width: number,
          /**
           * Original image height
           */
          height: number,
          /**
           * @CLIENT_ONLY
           * This field is calculated on basis of a given
           * {@link IQuestion$Image#imageInfo#width} and {@link IQuestion$Image#imageInfo#height}
           */
          ratio: number
        },
        thumb256: string,
        thumb128: string,
        url: string
      },
  children?: ReactChildren
};

const ImageDiv = styled(animated.div)`
  position: relative;
  width: ${fp.getOr('100%', 'width')};
  max-height: ${fp.get('maxHeight')};

  background-size: contain;

  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

  ${props => {
    if (!props.$imageSet) return null;

    return css`
      background-image: image-set(
        url(${props.$imageSet['1x']}) 1x,
        url(${props.$imageSet['2x']}) 2x,
        url(${props.$imageSet['3x']}) 3x
      );
    `;
  }}
`;

// Я решил сделать здесь отдельный компонент для lazyload'инга внутри компонента
export default function BackgroundImage({
  // TODO: rename to src
  image,
  height,
  children,
  size,
  className,
  ...props
}: Props): React.Node {
  const spring = useSpring({
    height
  });

  const bgImage = useProgressiveImage({
    previewSrc:
      image !== null && typeof image === 'object' ? image.thumb256 : null,
    // $FlowIgnore
    src: image !== null && typeof image === 'object' ? image.url : image
  });

  return (
    <ImageDiv
      {...props}
      className={cx(className, 'lazyload-wrapper')}
      style={{
        ...spring,
        backgroundImage: bgImage ? `url(${bgImage})` : null
      }}
    >
      {children}
    </ImageDiv>
  );
}

BackgroundImage.defaultProps = {
  className: null,
  children: null,
  size: null
};
