// @flow

import { createSelector } from 'reselect';
import size from 'lodash/size';
import includes from 'lodash/includes';
import { INTERVIEW_MODE } from 'common/helpers/interview/constants';
import { isOpenedViaPublicLink } from 'common/helpers/interview/isOpenedViaPublicLink';

import type { StateSelector } from 'flow-types/Selector';
import type { IInterviewProject } from 'flow-types/entities/Project';
import type {
  InterviewAppearance,
  InterviewRecordState
} from 'flow-types/states/InterviewState/InterviewRecordState';

import type { IResponse } from 'flow-types/entities/Response';
import type { PageState } from 'flow-types/states/PageState';
import { locationStateSelector, pageStateSelector } from '../index';
import { interviewProjectDataSelector, interviewRecordSelector } from './root';
import { interviewLogicRulesSelector } from './logic';

export type InterviewStatusesSelector$Return = {|
  mode: null | string,
  prepared: boolean,
  preparing: boolean,
  started: boolean,
  completed: boolean,
  completing: boolean,
  state: null | string,
  running: boolean,
  paused: boolean,
  failed: boolean,
  stopped: boolean,
  hasPostPolling: boolean,
  isPostPolling: boolean,
  isPublicPolling: boolean,
  isLogicDriven: boolean,
  controlsLocked: boolean,
  mainFinished: boolean,
  showProgress: boolean,
  showTimer: boolean,
  postPollingFinished: boolean,
  // Means that real HTTP(S) request should be skipped and instead of it
  // instant success case should be emitted.
  // Some times it means, that some data should be mocked up.
  skipRequest: boolean,
  // Means that related for answers submission events should be emitted
  submitRequestsEnabled: boolean
|};

export const getInterviewPageAppearance = (
  record: IResponse,
  location: Object,
  page: PageState,
  forceAppearance?: string | null = null
): string | null => {
  if (page.key === 'interviewPage') {
    if (forceAppearance !== null) {
      return forceAppearance;
    }

    if (location?.payload?.interviewId) {
      return 'web';
    }

    return null;
  }
  if (page.key === 'interviewsPage') {
    if (location?.query?.rid) {
      /*
       Here we have to identify whether it is
       a session continuation or session edit.
      */
      if (record?.mainFinished) {
        return 'edit';
      }

      return 'web';
    }

    if (location?.query?.hash) {
      return 'post';
    }

    if (isOpenedViaPublicLink({ location })) {
      return 'public';
    }
  }

  return null;
};

export const createInterviewAppearanceSelector = (): StateSelector<InterviewAppearance> =>
  createSelector(
    [interviewRecordSelector, locationStateSelector, pageStateSelector],
    (record: InterviewRecordState, location, page): InterviewAppearance =>
      getInterviewPageAppearance(
        record.data,
        location,
        page,
        record.forcedAppearance
      )
  );

export const interviewStatusesSelector: StateSelector<InterviewStatusesSelector$Return> = createSelector(
  [
    interviewProjectDataSelector,
    interviewRecordSelector,
    interviewLogicRulesSelector,
    locationStateSelector,
    createInterviewAppearanceSelector()
  ],
  (
    project: IInterviewProject,
    record: InterviewRecordState,
    logic,
    location,
    appearance: InterviewAppearance
  ): InterviewStatusesSelector$Return => {
    const {
      completed,
      state,
      preparing,
      mode,
      data,
      prepared,
      completing,
      paused,
      running,
      stopped,
      started,
      locked,
      failed
    } = record;

    return {
      mode,
      prepared,
      preparing,
      started,
      completed,
      completing,
      state,
      running,
      paused,
      stopped,
      failed,
      controlsLocked: locked,
      hasPostPolling: !!project?.settings?.appearance?.post?.enabled,
      isPostPolling: appearance === 'post',
      isPublicPolling: appearance === 'public',
      isLogicDriven: size(logic) > 0,
      mainFinished: !!data.mainFinished,
      postPollingFinished: !!data.postPollingFinished,
      skipRequest: mode === INTERVIEW_MODE.LOCAL,
      submitRequestsEnabled:
        appearance === 'edit' ||
        includes(
          [INTERVIEW_MODE.EDIT, INTERVIEW_MODE.REMOTE, INTERVIEW_MODE.LOCAL],
          mode
        ),
      showProgress:
        // decided not to show progress in edit
        appearance !== null && appearance !== 'edit'
          ? !!project?.settings?.appearance?.[appearance]?.progress?.enabled
          : false,
      showTimer:
        // decided not to show timer in edit
        appearance !== null && appearance !== 'edit'
          ? !!project?.settings?.appearance?.[appearance]?.timer?.enabled
          : false
    };
  }
);
