// @flow
import { camelizeKeys } from 'humps';
import fp from 'lodash/fp';
import type { IChecklistItem } from '../../../../flow-types/entities/ChecklistItem';

export const comparatorNormalizer = fp.compose(
  ({
    checklistGroup,
    fullMatchKeywords,
    partialMatchKeywords,
    options,
    ...safeData
  }): IChecklistItem => ({
    ...safeData,
    rates: options,
    ...(checklistGroup && {
      checklistId: checklistGroup.checklistId
    }),
    checklistGroup,
    fullMatchKeywords: '',
    ...(Array.isArray(fullMatchKeywords) && {
      fullMatchKeywords: fullMatchKeywords.join('; ')
    }),
    partialMatchKeywords: '',
    ...(Array.isArray(partialMatchKeywords) && {
      partialMatchKeywords: partialMatchKeywords.join('; ')
    })
  }),
  camelizeKeys
);

export default fp.map(comparatorNormalizer);
