// @flow

export const TASK_STATUS = Object.freeze({
  NEW: 1,
  IN_PROGRESS: 2,
  READY: 3,
  ERROR: 4
});

export const getTaskStatusIntlMessageId = status => {
  if (status === TASK_STATUS.NEW) {
    return 'common.statuses.registered';
  }

  if (status === TASK_STATUS.IN_PROGRESS) {
    return 'common.statuses.inProgress';
  }

  if (status === TASK_STATUS.READY) {
    return 'common.statuses.ready';
  }

  if (status === TASK_STATUS.ERROR) {
    return 'common.statuses.failed';
  }

  return 'common.statuses.unknown';
};

export const getTaskStatusFomanticUIColor = status => {
  if (status === TASK_STATUS.NEW) {
    return 'aqua';
  }

  if (status === TASK_STATUS.IN_PROGRESS) {
    return 'primary';
  }

  if (status === TASK_STATUS.READY) {
    return 'green';
  }

  if (status === TASK_STATUS.ERROR) {
    return 'red';
  }

  return 'grey';
};

export const REVIEW_STAGE = Object.freeze({
  NO_MARKS: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3
});

export const MEETING_STAGE = Object.freeze({
  IN_PROGRESS: 102,
  COMPLETED_NO_DATA: 103,
  COMPLETED_WITH_DATA: 104
});

export const EXPORT_FORMAT = Object.freeze({
  EXCEL: 1,
  SPSS: 2
});

// Флаги, которые доступы для форматов выгрузки
export const EXPORT_FORMAT_FLAGS: { [string]: string[] } = Object.freeze({
  [EXPORT_FORMAT.EXCEL]: [
    'showViews',
    'showGroups',
    'withAudio',
    'withAttachments',
    'showAnswers',
    'showCodes',
    'expandOptions',
    'showComments',
    'showChecklists',
    'showHistory'
  ],
  [EXPORT_FORMAT.SPSS]: [
    'showGroups',
    'withAudio',
    'withAttachments',
    'showAnswers',
    'showComments'
  ]
});

export const getMeetingStageMessageId = (
  meetingStage: number
): string | null => {
  switch (meetingStage) {
    case MEETING_STAGE.IN_PROGRESS:
      return `projectResponse.meetingStage.inProgress`;
    case MEETING_STAGE.COMPLETED_NO_DATA:
      return `projectResponse.meetingStage.completedNoData`;
    case MEETING_STAGE.COMPLETED_WITH_DATA:
      return `projectResponse.meetingStage.completedWithData`;
    default:
      return null;
  }
};

export const getReviewStageColor = (reviewStage: number): string => {
  switch (reviewStage) {
    case REVIEW_STAGE.IN_PROGRESS:
      return 'yellow';
    case REVIEW_STAGE.COMPLETED:
      return 'green';
    case REVIEW_STAGE.NO_MARKS:
    default:
      return 'blue';
  }
};

export const getReviewStageMessageId = (reviewStage: number): string | null => {
  switch (reviewStage) {
    case 1:
      return 'projectResponse.reviewStage.noMarks';
    case 2:
      return 'projectResponse.reviewStage.inProgress';
    case 3:
      return 'projectResponse.reviewStage.completed';
    default:
      return null;
  }
};

// Statuses that is used in audio upload decoding
export const DECODING_TASK_STATUSES = Object.freeze({
  /**
   * @CLIENT_ONLY
   */
  UNREGISTERED: -1,
  NEW: 1,
  IN_PROGRESS: 2,
  READY: 3,
  ERROR: 4
});

export type DecodingStatus = $Values<typeof DECODING_TASK_STATUSES>;

// Reasons for task error status
export const DECODING_TASK_ERROR_REASON = {
  // This reason will be set only if there is already a task,
  // that covers the same uploadId+providerId pair
  DUPLICATION: '1'
};

export const RESPONSES_MASS_ACTIONS_TYPES = {
  UPDATE: 'update'
};
