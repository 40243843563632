// @flow

import React, { forwardRef } from 'react';
import cx from 'classnames';

type Props = {
  nonUI?: boolean,
  className?: null | string,
  wide?: null | boolean | 'very',
  position?: 'top ' | 'left' | 'right' | 'bottom',
  size?: null | string,
  fixed?: boolean,
  basic?: boolean,
  fluid?: boolean,
  flowing?: boolean,
  inverted?: boolean,
  visible?: boolean,
  transition?: boolean,
  tagName?: string
};

// $FlowFixMe
const Popup = forwardRef(
  (
    {
      nonUI,
      className,
      tagName,
      fixed,
      fluid,
      flowing,
      inverted,
      basic,
      size,
      wide,
      visible,
      transition,
      position,
      ...props
    }: Props,
    ref
  ) => {
    const Component = tagName ?? 'div';

    const classNames = cx(
      {
        ui: !nonUI,
        very: wide === 'very',
        wide,
        fluid,
        inverted,
        flowing,
        basic
      },
      size,
      position,
      className,
      'univey-popup',
      {
        fixed,
        visible,
        transition
      }
    );

    return <Component {...props} ref={ref} className={classNames} />;
  }
);

Popup.displayName = 'Popup.ForwardRef';

// $FlowFixMe
Popup.defaultProps = {
  nonUI: false,
  className: null,
  size: null,
  wide: false,
  basic: false,
  flowing: false,
  inverted: false,
  fixed: false,
  fluid: false,
  visible: false,
  transition: false,
  position: 'bottom',
  tagName: 'div'
};

export default Popup;
