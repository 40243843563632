const projectReportsDictionary = {
  en: {
    'project.reports.labels.company': 'Company',
    'project.reports.labels.byDepartments': 'Departments',
    'project.reports.labels.byManagers': 'Managers',
    'project.reports.labels.noDepartment': 'No department',
    'project.reports.labels.noAvailableDepartments': 'No available departments',
    'project.reports.labels.noAvailableManagers': 'No appropriate managers',
    'project.reports.labels.noAvailableChecklists': 'No appropriate checklists',
    'project.reports.filter.by.departments': 'Show by departments',
    'project.reports.filter.by.managers': 'Show by managers',
    'project.reports.headers.points': 'Meetings points report',
    'project.reports.headers.duration': 'Meetings duration report',
    'project.reports.labels.responseCount': 'Response count',
    'project.reports.labels.byAllQuestions': 'All questions',
    'project.reports.labels.byAllChecklistGroups': 'All groups',
    'project.reports.messages.noDataForSearchParams': `
      No data for selected search params.
      Try to change search params!
    `,
    'project.reports.actions.retry': 'Retry',
    'project.reports.message.dataNotRequested':
      'You have not requested any data yet!'
  },
  ru: {
    'project.reports.labels.company': 'Компания',
    'project.reports.labels.byDepartments': 'Отделы',
    'project.reports.labels.byManagers': 'Менеджеры',
    'project.reports.labels.noDepartment': 'Без отдела',
    'project.reports.labels.noAvailableDepartments': 'Нет доступных отделов',
    'project.reports.labels.noAvailableManagers': 'Нет подходящих менеджеров',
    'project.reports.labels.noAvailableChecklists': 'Нет подходящих чеклистов',
    'project.reports.filter.by.departments': 'Показать по отделам',
    'project.reports.filter.by.managers': 'Показать по менеджерам',
    'project.reports.headers.points': 'Оценки встреч',
    'project.reports.headers.duration': 'Длительность встреч',
    'project.reports.labels.responseCount': 'Число встреч',
    'project.reports.labels.byAllQuestions': 'По всем вопросам',
    'project.reports.labels.byAllChecklistGroups': 'По всем группам',
    'project.reports.messages.noDataForSearchParams':
      'Нет данных по вашему запросу. Попробуйте изменить параметры запроса!',
    'project.reports.message.dataNotRequested':
      'Вы ещё не загрузили данных по отчётам!'
  }
};

export default projectReportsDictionary;
