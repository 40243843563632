// @flow

import reduce from 'lodash/reduce';
import some from 'lodash/some';
import find from 'lodash/find';
import includes from 'lodash/includes';
import every from 'lodash/every';
import isEqualDates from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import toNum from 'lodash/toNumber';
import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';
import { isTestsGroupingOperator } from './isTestsGroupingOperator';

import { TYPES, isSelectable } from '../../helpers/question';

import type {
  IFormattedLogicItem,
  IFormattedLogicOperand
} from '../../../flow-types/entities/Logic';

import type {
  ILogicTestOperator,
  ILogicTestUnionType
} from '../../../flow-types/entities/RawLogic';
import type { IInterviewAnswer$Value } from '../../../flow-types/entities/InterviewAnswer';
import type {
  LogicRunnerFn,
  TestNumberRunnerFn,
  TestRunnerFn,
  TestsRunnerFn,
  TestStringRunnerFn
} from './flow';

type LogicTest$Options = Array<number>;

const testOptionSelectionRunner = (operator, selected) => option => {
  const normalizedSelected = Array.isArray(selected) ? selected : [selected];
  if (operator === LOGIC_OPERATOR.IN) {
    // eslint-disable-next-line eqeqeq
    return !!find(normalizedSelected, opt => opt == option);
  }
  if (operator === LOGIC_OPERATOR.NOT_IN) {
    // eslint-disable-next-line eqeqeq
    return !find(normalizedSelected, opt => opt == option);
  }

  return false;
};

export const testSelectionRunner = (
  options: LogicTest$Options,
  selected: IInterviewAnswer$Value,
  operator: ILogicTestOperator,
  unionType: ILogicTestUnionType
) => {
  if (unionType === LOGIC_OPERAND.OR) {
    return some(options, testOptionSelectionRunner(operator, selected));
  }
  return every(options, testOptionSelectionRunner(operator, selected));
};

export const testNumberCheckRunner: TestNumberRunnerFn = (
  // equality operator
  operator,
  // current value in answer
  value,
  // value to be used to check current value
  againstValue
) => {
  switch (operator) {
    case LOGIC_OPERATOR.EQUAL:
      return toNum(value) === toNum(againstValue);

    case LOGIC_OPERATOR.GREATER_THAN_OR_EQUAL:
      return toNum(value) >= toNum(againstValue);

    case LOGIC_OPERATOR.GREATER_THAN:
      return toNum(value) > toNum(againstValue);

    case LOGIC_OPERATOR.LESS_THAN_OR_EQUAL:
      return toNum(value) <= toNum(againstValue);

    case LOGIC_OPERATOR.LESS_THAN:
      return toNum(value) < toNum(againstValue);

    case LOGIC_OPERATOR.IN:
    case LOGIC_OPERATOR.NOT_IN:
    default:
      return false;
  }
};

export const testDateCheckRunner: TestNumberRunnerFn = (
  operator,
  value,
  targetValue
) => {
  if (!value || !targetValue) return false;

  // even if either value or targetValue are of Date type,
  // they will be correctly used by new Date
  // even if they will be of string type, if they're valid strings,
  // then it will be correct
  switch (operator) {
    case LOGIC_OPERATOR.EQUAL:
      return isEqualDates(new Date(value), new Date(targetValue));

    case LOGIC_OPERATOR.GREATER_THAN_OR_EQUAL:
      return (
        testDateCheckRunner(LOGIC_OPERATOR.EQUAL, value, targetValue) ||
        isAfter(new Date(value), new Date(targetValue))
      );

    case LOGIC_OPERATOR.LESS_THAN_OR_EQUAL:
      return (
        testDateCheckRunner(LOGIC_OPERATOR.EQUAL, value, targetValue) ||
        isBefore(new Date(value), new Date(targetValue))
      );

    case LOGIC_OPERATOR.GREATER_THAN:
      return isAfter(new Date(value), new Date(targetValue));

    case LOGIC_OPERATOR.LESS_THAN:
      return isBefore(new Date(value), new Date(targetValue));

    default:
      return false;
  }
};

export const testStringCheckRunner: TestStringRunnerFn = (
  operator,
  value,
  targetValue
) => {
  switch (operator) {
    case LOGIC_OPERATOR.EQUAL:
      return value === `${targetValue}`;
    case LOGIC_OPERATOR.IN:
      return value ? includes(value, `${targetValue}`) : false;
    case LOGIC_OPERATOR.NOT_IN:
      return value ? !includes(value, `${targetValue}`) : false;
    default:
      return false;
  }
};

export const testRunner: TestRunnerFn = (test, { answers } = {}) => {
  if (typeof test === 'boolean') {
    return test;
  }

  const { questionId, operator, unionType, options, value } = test;

  // $FlowFixMe
  const { [questionId]: answer } = answers;

  if (!answer || (answer && !answer.data)) {
    switch (operator) {
      case LOGIC_OPERATOR.NOT_IN:
        return true;
      default:
        return false;
    }
  }

  const { data, question } = answer;

  const questionType = question && question.type;

  if (isSelectable(questionType)) {
    switch (operator) {
      case LOGIC_OPERATOR.IN:
      case LOGIC_OPERATOR.NOT_IN:
        return testSelectionRunner(options, data, operator, unionType);
      default:
        return false;
    }
  }

  // Numeric question comparisons
  if ([TYPES.Numeric, TYPES.Rating].includes(questionType)) {
    // $FlowFixMe
    return testNumberCheckRunner(operator, data, value);
  }

  if (TYPES.DateTime === questionType) {
    // $FlowFixMe
    return testDateCheckRunner(operator, data, value);
  }

  // Text question comparisons
  // $FlowFixMe
  return testStringCheckRunner(operator, data, value);
};

function calculatePassed(
  prev: boolean,
  operand: 'and' | 'or',
  next: boolean
): boolean {
  if (operand === 'or') {
    return prev || next;
  }

  return prev && next;
}

export const testsRunner: TestsRunnerFn = (
  tests: IFormattedLogicItem[],
  { answers, currentSourceId } = {}
) => {
  let lastGroupingOperator = LOGIC_OPERAND.AND;

  return reduce(
    tests,
    (passed, test: IFormattedLogicItem | IFormattedLogicOperand) => {
      const isGroupingOperator = isTestsGroupingOperator(test);

      // Если это AND или OR, то устанавливаем его
      // как предыдущий оператор соединения тестов/групп тестов
      // и возвращаем последний результат проверки
      if (isGroupingOperator) {
        lastGroupingOperator = test;

        return passed;
      }

      if (typeof test === 'boolean') {
        return calculatePassed(passed, lastGroupingOperator, test);
      }

      if (['true', 'false'].includes(test)) {
        return calculatePassed(passed, lastGroupingOperator, test === 'true');
      }

      if (Array.isArray(test)) {
        // We checked it above
        // $FlowFixMe
        return calculatePassed(
          passed,
          lastGroupingOperator,
          testsRunner(test, { answers, currentSourceId })
        );
      }

      // We checked it above
      // $FlowFixMe
      return calculatePassed(
        passed,
        lastGroupingOperator,
        testRunner(test, { answers, currentSourceId })
      );
    },
    true
  );
};

/**
 * @param rules
 * @param answers
 * @param currentSourceId
 */
export const logicRunner: LogicRunnerFn = (
  rules,
  { answers, currentSourceId } = {}
) =>
  reduce(
    rules,
    (result, rule) => {
      const { id, title, tests, actions } = rule;
      const passed = testsRunner(tests, { answers, currentSourceId });
      return [
        ...result,
        {
          id,
          title,
          actions,
          passed
        }
      ];
    },
    []
  );
