import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import * as Operators from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import request from '../../utils/request';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import listResponseParser from '../../common/epicHelpers/listResponseParser';
import projectsNormalizer from '../../common/transducers/projects/projectsNormalizer';

import type { Epic } from '../../flow-types/Epic';
import { projectListStateSelector } from '../../selectors';
import debounceEpic from '../../common/epicHelpers/debounceEpic';

const fetchProjectsEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('projectsList/fetch-projects'),
    Operators.withLatestFrom($state),
    debounceEpic(),
    Operators.switchMap(epicData => {
      const [, state] = epicData;

      const { filter } = projectListStateSelector(state);

      return request({
        url: API.projects.list,
        query: filter,
        method: 'GET'
      }).pipe(
        responseParser, // extracts response.response
        listResponseParser, // extracts data and pagination from above
        Operators.map(response => {
          const { data, pagination } = response;
          return {
            type: 'projectsList/fetch-projects-success',
            data: projectsNormalizer(data),
            pagination
          };
        }),
        Operators.catchError(({ response, message }: AjaxError) =>
          of({
            type: 'projectsList/fetch-projects-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

export default fetchProjectsEpic;
