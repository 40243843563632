import mapFp from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import isDate from 'date-fns/isDate';
import format from 'date-fns/format';
import type { IQuestion, LabelSettings } from 'flow-types/entities/Question';

import {
  DATE_FNS_FORMAT_DATE_ONLY,
  DATE_FNS_FORMAT_NO_SECONDS
} from 'utils/config';

import {
  isOptionsRelated,
  TABLE_QUESTION_VIEW_TYPES,
  TYPES
} from 'common/helpers/question';
import defaultsDeep from 'common/helpers/defaults';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import { filterNewGeneratedProperties } from 'common/helpers/generateNewData';

import type { IQuestionOption } from 'flow-types/entities/QuestionOption';

import fp from 'lodash/fp';
import { createColorFieldParser } from 'common/transducers/colorPickerValueParser';
import baseOptionsDenormalizer from '../questionOptionsDenormalizer';

const removeClientOnlyFields = (question: IQuestion) => {
  const next = { ...question };

  delete next.uploads;
  delete next.mappedOptions;
  delete next.inherits;
  delete next.submittable;
  delete next.cache;

  return next;
};

const denormalizeQuestionOptions = (question: IQuestion) => {
  const { options, withPoints, type, ...rest } = question;

  // if question type is not intended to use options,
  // then remove all options of that question and set withPoints to false
  if (!isOptionsRelated(question.type)) {
    return {
      options: [],
      withPoints: false,
      type,
      ...rest
    };
  }

  let result = [...options];

  if (!Array.isArray(result)) {
    result = [];
  }

  result = map(result, option => {
    if (withPoints) {
      if (!isNil(option.points)) {
        return option;
      }

      // set default value for option points,
      // if user forgot to do it
      return {
        ...option,
        points: 0
      };
    }

    return {
      ...option,
      points: null
    };
  });

  return {
    ...rest,
    type,
    withPoints: !!withPoints,
    options: baseOptionsDenormalizer(result)
  };
};

// adds default values if they were not set by user
const addDefaultQuestionFieldsValues = (question: IQuestion) => {
  const defaultValues: $Shape<IQuestion> = {
    settings: {
      type: TYPES.SingleAnswer,
      viewType: TABLE_QUESTION_VIEW_TYPES.SINGLE
    },
    recommendedTime: 0,
    ...(question.filesEnabled && {
      minFiles: 0,
      maxFiles: 0
    })
  };

  return defaultsDeep(question, defaultValues);
};

const denormalizeExcludingOptions = (question: IQuestion) => {
  const { type, options, excludingOptions, settings, ...rest } = question;

  // TODO: allow excludingOptions for TableQuestions with MultipleAnswerQuestions inside
  if (
    type === TYPES.SingleAnswer ||
    (type === TYPES.Table && settings.type === TYPES.SingleAnswer)
  ) {
    return {
      ...rest,
      type,
      settings: {
        ...settings
      },
      options: map(options, (opt: IQuestionOption) => ({
        ...opt,
        isExcluding: false
      }))
    };
  }

  return {
    ...rest,
    type,
    settings,
    options: map(options, (opt: IQuestionOption) => ({
      ...opt,
      isExcluding:
        includes(excludingOptions, opt.id) ||
        includes(excludingOptions, `${opt.id}`)
    }))
  };
};

const denormalizeDefaultAnswer = (question: IQuestion) => {
  if (!question.defaultAnswer || question.type !== TYPES.DateTime) {
    return question;
  }

  const { defaultAnswer, ...rest } = question;

  if (!isDate(defaultAnswer)) {
    return question;
  }

  return {
    ...rest,
    defaultAnswer: format(
      defaultAnswer,
      question.dateOnly ? DATE_FNS_FORMAT_DATE_ONLY : DATE_FNS_FORMAT_NO_SECONDS
    )
  };
};

const denormalizeDefaultOptions = (question: IQuestion) => {
  const { options, defaultOptions, ...rest } = question;

  return {
    ...rest,
    options: map(options, opt => {
      const isDefault =
        includes(defaultOptions, `${opt.id}`) ||
        includes(defaultOptions, opt.id);

      return {
        ...opt,
        isDefault
      };
    })
  };
};

const questionSubQuestionsDenormalizer = (question: IQuestion) => {
  const { subQuestions } = question;

  const result =
    Array.isArray(subQuestions) && subQuestions.length > 0
      ? // eslint-disable-next-line no-use-before-define
        denormalizeQuestionSubQuestions(subQuestions)
      : [];

  return {
    ...question,
    subQuestions: result
  };
};

const processQuestionShortTitle = (question: IQuestion) => {
  const { shortTitle, title } = question;
  return {
    ...question,
    ...(!shortTitle && {
      shortTitle: title
    })
  };
};

const denormalizeQuestionDecimalValues = (question: IQuestion) => {
  const { scale, minValue, maxValue } = question;
  return {
    ...question,
    scale: !isNil(scale) ? parseInt(scale, 10) : null,
    min_value: !isNil(minValue) ? parseFloat(minValue) : null,
    max_value: !isNil(maxValue) ? parseFloat(maxValue) : null
  };
};

const processQuestionMaxMaxAnswers = (question: IQuestion): IQuestion => {
  const next = { ...question };

  if (question.maxAnswers === '') {
    next.maxAnswers = null;
  }

  if (question.minAnswers === '') {
    next.minAnswers = null;
  }

  return next;
};

/**
 * Processes {@link IQuestionSettings.labels} so,
 * that in there {@link LabelSettings.localId} can be found.
 */
export const processQuestionsSliderLabels = (question: $Shape<IQuestion>) => {
  const labelsSettings = question?.settings?.labels;

  if (!labelsSettings) {
    return question;
  }

  return {
    ...question,
    settings: {
      ...question.settings,
      // Case below is covered in condition above.
      // $FlowIgnore
      labels: reduce(
        question.settings.labels,
        (results, value: LabelSettings) => {
          // localId is ignored
          const { localId, ...rest } = value;
          return {
            ...results,
            [value.value]: rest
          };
        },
        {}
      )
    }
  };
};

const appearanceProcessor = fp.compose(
  createColorFieldParser('textColor'),
  createColorFieldParser('backgroundColor')
);

const processAppearance = settings => {
  if (!settings || !settings.appearance) return settings;

  const appearance = appearanceProcessor(settings.appearance);

  return {
    ...settings,
    appearance
  };
};

const processSettings = fp.compose(processAppearance);

const processQuestionSettings = ({ settings, ...question }) => ({
  ...question,
  settings: processSettings(settings)
});

const denormalizeQuestion = compose(
  processQuestionSettings,
  removeClientOnlyFields,
  filterNewGeneratedProperties,
  questionSubQuestionsDenormalizer,
  processQuestionShortTitle,
  denormalizeQuestionOptions,
  denormalizeDefaultAnswer,
  denormalizeDefaultOptions,
  denormalizeExcludingOptions,
  denormalizeQuestionDecimalValues,
  addDefaultQuestionFieldsValues,
  processQuestionMaxMaxAnswers
);

const denormalizeQuestionSubQuestions = mapFp(denormalizeQuestion);

const decamelizeAndDenormalizeQuestion = compose(
  decamelizeKeys,
  denormalizeQuestion
);

export default decamelizeAndDenormalizeQuestion;
