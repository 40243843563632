import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { camelizeKeys } from 'humps';
import { AjaxError } from 'rxjs/ajax';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import { normalizeAccessSettings } from 'common/transducers/projects/accessSettings/accessSettingsNormalizer';

import type { Epic } from 'flow-types/Epic';
import type { EditAccessSettings } from 'flow-types/actions/projects/detail/accessRules/EditAccessSettings';
import type {
  FetchAccessSettingsFail,
  FetchAccessSettingsSuccess
} from 'flow-types/actions/projects/detail/accessRules/FetchAccessSettings';
import type { AppState } from 'flow-types/AppState';

import { MODAL_ID } from '../../components/Modals/AccessRuleFormModal';
import { projectIdFromPathSelector } from '../../../../selectors/projects';

import type { OpenModal } from '../../../Modals/modals';

const editAccessSettings: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project/edit-access-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([action, state]: [EditAccessSettings, AppState]) => {
      const projectId = projectIdFromPathSelector(state);

      const { settingsId } = action;

      return request({
        url: interpolateString(API.projects.accessSettings.detail, {
          projectId,
          settingsId
        }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.mergeMap((raw): [FetchAccessSettingsSuccess, OpenModal] => {
          const data = normalizeAccessSettings(camelizeKeys(raw));

          return [
            {
              type: 'project/fetch-access-settings-success',
              settingsId
            },
            {
              type: 'modals/open',
              data,
              modalId: MODAL_ID
            }
          ];
        }),
        RxOperators.catchError((error: AjaxError): [
          FetchAccessSettingsFail
        ] => {
          const { response, message } = error;
          return [
            {
              type: 'project/edit-access-settings-fail',
              settingsId,
              error: response || message
            }
          ];
        })
      );
    })
  );

export const fetchAccessSettings: Epic = action$ =>
  action$.pipe(
    ofType('project/edit-access-settings'),
    RxOperators.map((action: EditAccessSettings) => ({
      type: 'project/fetch-access-settings',
      settingsId: action.settingsId
    }))
  );

export default editAccessSettings;
