import { merge } from 'rxjs';
import * as RxOp from 'rxjs/operators';
import { ofType } from 'redux-observable';

import type { Epic } from 'flow-types/Epic';

import unsavedChangesCheckProcess from './subProcesses/unsavedChangesCheck';

const addQuestion: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-groups/add-question-process'),
    RxOp.exhaustMap(({ type, ...payload }) => {
      const cancel$ = action$.pipe(
        ofType('project-groups/unsaved-changes-check-fail')
      );

      return merge(
        action$.pipe(
          ofType('project-groups/unsaved-changes-check-success'),
          RxOp.map(() => ({
            type: 'project-groups/add-question',
            ...payload
          })),
          RxOp.take(1),
          RxOp.takeUntil(cancel$)
        ),
        unsavedChangesCheckProcess(action$, state$)
      );
    })
  );

export default addQuestion;
