import revertDictionary from 'utils/revertDictionary';

const projectResponsesDictionary = {
  'project.responsesFieldsVisibilitySettingsModal.tabs.settingsForm.label': {
    en: 'Main',
    ru: 'Настройки'
  },

  'project.responsesFieldsVisibilitySettingsModal.tabs.orderForm.label': {
    en: 'Display order',
    ru: 'Последовательность отображения'
  },

  'project.responsesPanel.state.isLoading': {
    en: 'Loading data',
    ru: 'Загружаем данные'
  },
  'project.responsesPanel.lightBoxPreview.simpleTitle': {
    en: 'Attachments',
    ru: 'Прикрепления'
  },
  'project.responsesPanel.lightBoxPreview.title': {
    en: 'Attachments for question "{title}"',
    ru: 'Прикрепления для вопроса "{title}"'
  },
  'project.responsesPanel.filter.byBlocksValues.actions.confirmRemove': {
    en: 'Do you want to remove field filter?',
    ru: 'Вы действительно хотите удалить фильтр поля?'
  },
  'project.responsesPanel.filter.byBlocksValues.actions.addFilter': {
    en: 'Add field filter',
    ru: 'Добавить фильтр поля'
  },
  'project.responsesPanel.filter.byBlocksValues': {
    en: 'Field filters',
    ru: 'Фильтры по полям'
  },
  'project.responsesPanel.filter.executioner': {
    en: 'Who',
    ru: 'Кто'
  },
  'project.responsesPanel.filter.expert': {
    en: 'Specialist',
    ru: 'Эксперт'
  },
  'project.responsesPanel.table.id_and_date': {
    en: 'Num / Date',
    ru: 'Номер / Дата проведения'
  },
  'project.responsesPanel.table.expert_id': {
    en: 'Specialist (ID)',
    ru: 'Эксперт (ИД)'
  },
  'project.responsesPanel.table.expert': {
    en: 'Specialist (Name)',
    ru: 'Эксперт (Имя)'
  },
  'project.responsesPanel.table.interviewer_and_duration': {
    en: 'Who / Duration',
    ru: 'Проводил / Продолжительность'
  },
  'project.responsesPanel.table.status': {
    en: 'Status',
    ru: 'Статус'
  },
  'project.responsesPanel.table.rate': {
    en: 'Mark',
    ru: 'Оценка'
  },
  'project.responsesPanel.table.rate.noMarksData': {
    en: 'No data',
    ru: 'Нет данных'
  },
  'project.responsesPanel.table.totalItems': {
    en: 'Total found',
    ru: 'Всего найдено'
  },
  'projects.tabs.responses': {
    en: 'Responses',
    ru: 'Записи'
  },
  'projects.tabs.responses.who': {
    en: 'Who',
    ru: 'Кто'
  },
  'projects.tabs.responses.duration': {
    en: 'Duration',
    ru: 'Продолжительность'
  },
  'projects.tabs.responses.respondent': {
    en: 'Client',
    ru: 'Клиент'
  },
  'projects.tabs.responses.comments': {
    en: 'Comments',
    ru: 'Комментарии'
  },
  'projects.tabs.responses.lastComment': {
    en: 'Last comment',
    ru: 'Последний комментарий'
  },
  'projects.tabs.responses.attachments': {
    en: 'Attachments',
    ru: 'Прикреплённые файлы'
  },
  'projects.tabs.responses.actions.openInCRM': {
    en: 'Open in CRM',
    ru: 'Открыть в CRM'
  },
  'projects.tabs.responses.actions.changeDepartment': {
    en: 'Change department',
    ru: 'Изменить отдел'
  },

  // new
  'projects.tabs.responses.filterTab': {
    en: 'System filters',
    ru: 'Системные фильтры'
  },
  'projects.tabs.responses.filter.who': {
    en: 'Who',
    ru: 'Кто'
  },
  'projects.tabs.responses.filter.expert': {
    en: 'Specialist',
    ru: 'Эксперт'
  },
  'projects.tabs.responses.filter.dateCreated': {
    en: 'Date of creation',
    ru: 'Дата начала'
  },
  'projects.tabs.responses.filter.postPollingStart': {
    en: 'Post polling start',
    ru: 'Начало пост-опроса'
  },
  'projects.tabs.responses.filter.postPollingEnd': {
    en: 'Post polling end',
    ru: 'Завершение пост-опроса'
  },
  'projects.tabs.responses.filter.status': {
    en: 'Marking status',
    ru: 'Статус оценивания'
  },
  'projects.tabs.responses.filter.department': {
    en: 'Departments',
    ru: 'Отдел'
  },
  'projects.tabs.responses.filter.mainFinished': {
    en: 'Completed (main part)',
    ru: 'Завершено (осн. часть)'
  },
  'projects.tabs.responses.filter.postPollingFinished': {
    en: 'Completed (post polling)',
    ru: 'Завершено (пост опрос)'
  },

  'projects.tabs.responses.filter.updatedAt': {
    en: 'Last update',
    ru: 'Последнее обновление'
  },

  // region Regular Filters
  'projects.tabs.responses.filter.dateCreatedFrom': {
    en: 'Date created (begin)',
    ru: 'Начало записи (начало интервала)'
  },
  'projects.tabs.responses.filter.dateCreatedTo': {
    en: 'Date created (range end)',
    ru: 'Начало записи (конец интервала)'
  },
  'projects.tabs.responses.filter.dateEndFrom': {
    en: 'End date (range start)',
    ru: 'Конец записи (начало интервала)'
  },
  'projects.tabs.responses.filter.dateEndTo': {
    en: 'End date (range end)',
    ru: 'Конец записи (конец интервала)'
  },
  'projects.tabs.responses.filter.updatedAtFrom': {
    en: 'Last update (range start)',
    ru: 'Последнее обновление (начало интервала)'
  },
  'projects.tabs.responses.filter.updatedAtTo': {
    en: 'Last update (range end)',
    ru: 'Последнее обновление (конец интервала)'
  },
  'projects.tabs.responses.filter.onlyChecked': {
    en: 'Only checked',
    ru: 'Только обработанные'
  },
  // endregion

  'projects.tabs.responses.answers': {
    en: 'Answers',
    ru: 'Ответы'
  },
  'projects.tabs.responses.answers.placeholder': {
    en: 'Select meeting by clicking specified button to view answers',
    ru: 'Выберите встречу кликнув по соответствующей кнопке'
  },
  'projects.tabs.responses.answers.noItems': {
    en: 'Selected meeting has not answers',
    ru: 'В результате встречи не было зафиксировано текстовых ответов'
  },
  'projects.tabs.responses.status.active': {
    en: 'In progress',
    ru: 'В процессе'
  },
  'projects.tabs.responses.status.completed': {
    en: 'Completed',
    ru: 'Основная часть завершена'
  },
  'projects.tabs.responses.status.waitForPostPolling': {
    en: 'Waiting for client response',
    ru: 'Ожидается ответ клиента'
  },
  'projects.tabs.responses.status.postPollingCompleted': {
    en: 'Client response received',
    ru: 'Получен ответ клиента'
  },
  'projects.tabs.responses.visibilitySettingsModal.title': {
    en: 'Responses Fields Visibility Settings',
    ru: 'Настройка видимости полей'
  },
  'visibilitySettingsForm.fields.displayed.label': {
    en: 'Display',
    ru: 'Отобразить'
  },
  'visibilitySettingsForm.fields.title.label': {
    en: 'Field',
    ru: 'Поле'
  },

  /**
   * Responses Mass Actions Tasks
   */
  'responsesMassActionsTasks.messages.taskIsReady': {
    ru: 'Задача #{taskId} по проекту #{projectId} выполнена',
    en: 'Task #{taskId} in project #{projectId} is completed'
  },
  'responsesMassActionsTasks.messages.taskIsFailed': {
    ru:
      'Произошла ошибка при выполнении задачи #{taskId} в рамках проекта #{projectId}',
    en:
      'Error occurred while running task #{taskId} over records in project #{projectId}'
  }
};

export default revertDictionary(projectResponsesDictionary);
