// @flow

type Options = {|
  +token: string,
  +remember?: boolean
|};

const saveToken = (options: Options): void => {
  if (!options) return;

  const { token, remember } = options;

  if (remember) {
    localStorage.setItem('authToken', token);
  } else {
    sessionStorage.setItem('authToken', token);
  }
};

export default saveToken;
