import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import type { Epic } from 'flow-types/Epic';
import type { FetchDecodings } from 'flow-types/actions/records/decodings/list/FetchDecodings';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import type { SelectRecord } from 'flow-types/actions/records/SelectRecord';
import { camelizeAndNormalizeDecodingsList } from 'common/transducers/uploads/decodingsListNormalizer';
import { DECODING_TASK_STATUSES } from 'common/helpers/response';
import type { UpdateDecodingTaskStatus } from 'flow-types/actions/records/decodings/tasks/UpdateDecodingTaskStatus';
import {
  FETCH_DECODINGS,
  FETCH_DECODINGS_SUCCESS
} from '../../../reducers/records/decodings/list';
import { SELECT_RECORD } from '../../../reducers/records/recordsList';
import { UPDATE_TASK_STATUS } from '../../../reducers/records/decodings/tasks';

const fetchList$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_DECODINGS),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([action]: [FetchDecodings, State]) => {
      const { uploadId } = action;

      return request({
        url: interpolateString(API.uploads.decodings.list, { uploadId }),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        // add catchError case
        RxO.map(data => ({
          type: FETCH_DECODINGS_SUCCESS,
          uploadId,
          data: camelizeAndNormalizeDecodingsList(data)
        }))
      );
    })
  );

export const fetchListOnRecordSelection$: Epic = action$ =>
  action$.pipe(
    ofType(SELECT_RECORD),
    RxO.map((action: SelectRecord): FetchDecodings => ({
      type: FETCH_DECODINGS,
      uploadId: action.recordId
    }))
  );

export const fetchListOnTaskSuccess$: Epic = action$ =>
  action$.pipe(
    ofType(UPDATE_TASK_STATUS),
    RxO.filter(action => DECODING_TASK_STATUSES.READY === action.status),
    RxO.map((action: UpdateDecodingTaskStatus): FetchDecodings => ({
      type: FETCH_DECODINGS,
      uploadId: action.uploadId
    }))
  );

export default fetchList$;
