// @flow

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { merge } from 'merge-anything';
import format from 'date-fns/format';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../common/components/animated/Modal';
import Button from '../../../../../common/components/Button';
import useModal from '../../../../../common/hooks/useModal';
import AnswersContextProvider, {
  useAnswer
} from '../../../../../common/contexts/AnswersContext';
import AnswerUpdateContextProvider, {
  useAnswerUpdate
} from '../../../../../common/contexts/AnswerUpdateContext';

import { hasAnswer } from '../../Answers/Answer';
import Question from '../../../../../common/components/Question';
import interviewAnswersNormalizer, {
  interviewAnswersCommentsMixin
} from '../../../../../common/transducers/interview/answersNormalizer';
import QuestionComments from '../../../../../common/containers/Comments';
import { DATE_FNS_FORMAT } from '../../../../../utils/config';

import type {
  IQuestion,
  IQuestionId
} from '../../../../../flow-types/entities/Question';
import { projectResponsesStateSelector } from '../../../../../selectors/projects';
import type { IResponse } from '../../../../../flow-types/entities/Response';
import Uploads from '../../../../../common/containers/Uploads';
import { useProjectQuestions } from '../../../hooks/useProjectQuestions';
import type { IInterviewAnswers$Map } from '../../../../../flow-types/states/InterviewState/InterviewAnswersState';

export const MODAL_ID = 'responseAnswerEditModal';

const dimmerProps = { page: true, scrolling: true, fixed: true };

type InterviewQuestionModalState = null | {
  questionId: IQuestionId,
  responseId: number,
  isLoading?: boolean
};

type QuestionDisplayProps = {
  questionId?: null | IQuestionId,
  responseId?: null | number,
  isLoading?: boolean,
  // TODO: I do not have strong opinion about ResponseDisplay being as it is now
  onCancel: Function,
  onSubmit: Function
};

const ResponseQuestionComments = ({
  questionId
}: {
  questionId: IQuestionId
}) => {
  const { comments } = useAnswer(questionId, { comments: [] });

  const updateAnswer = useAnswerUpdate(questionId);

  const changeComments = useCallback(
    nextComments => {
      updateAnswer({
        comments: nextComments
      });
    },
    [updateAnswer]
  );

  return (
    <QuestionComments comments={comments} onCommentsChange={changeComments} />
  );
};

const ResponseDisplay = ({
  questionId,
  responseId,
  isLoading,
  onSubmit,
  onCancel
}: QuestionDisplayProps) => {
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    if (!onCancel) return;

    onCancel();
  }, [onCancel]);

  const { data: responses } = useSelector(projectResponsesStateSelector);

  const questions = useProjectQuestions({ flatten: true });

  const timestamp = useRef(null);

  const [response, setResponse] = useState<IResponse | null>(null);

  const [question, setQuestion] = useState<IQuestion | null>(null);

  const [answers, setAnswers] = useState<IInterviewAnswers$Map>({});

  const [attachments, setAttachments] = useState([]);

  const handleSubmit = useCallback(() => {
    if (!questionId || !response) return;

    if (onSubmit) {
      onSubmit();
    }

    dispatch({
      type: 'project/update-response-answer',
      answer: answers[questionId],
      responseId,
      question,
      localId: response.localId,
      timeStart: timestamp.current,
      timeEnd: format(new Date(), DATE_FNS_FORMAT)
    });
  }, [answers, dispatch, onSubmit, question, questionId, response, responseId]);

  const handleUploadSuccess = useCallback(
    upload => {
      dispatch({
        type: 'project/add-response-upload',
        upload,
        responseId,
        questionId
      });
    },
    [dispatch, questionId, responseId]
  );

  const handleUploadRemove = useCallback(
    uploadId => {
      dispatch({
        type: 'project/remove-response-upload',
        uploadId,
        responseId,
        questionId
      });
    },
    [dispatch, questionId, responseId]
  );

  useEffect(() => {
    if (questionId) {
      timestamp.current = format(new Date(), DATE_FNS_FORMAT);

      const matchedResponse = find(responses, { id: responseId });

      if (matchedResponse) {
        const matchedQuestion = find(questions, {
          id: questionId
        });

        if (matchedQuestion) {
          const questionAnswer = find(matchedResponse.answers, {
            questionId
          });

          const questionComments = filter(
            matchedResponse.comments,
            comment => comment.questionId === questionId
          );

          if (hasAnswer(questionAnswer)) {
            let interviewAnswers = interviewAnswersNormalizer([
              {
                ...questionAnswer,
                question: matchedQuestion
              }
            ]);

            interviewAnswers = interviewAnswersCommentsMixin(
              interviewAnswers,
              questionComments
            );

            setAnswers(interviewAnswers);
          }

          const questionAttachments = filter(matchedResponse.uploads, {
            questionId
          });

          setAttachments(questionAttachments);
          setQuestion(matchedQuestion);
          setResponse(matchedResponse);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const updateAnswer = useCallback(
    (localQuestionId, answerUpdate) => {
      setAnswers(prev => {
        if (!questionId) return prev;

        const nextState = {
          ...prev
        };

        nextState[questionId] = merge(nextState[questionId], {
          ...answerUpdate
        });

        return nextState;
      });
    },
    [questionId]
  );

  return (
    <>
      <Modal.Content className="lazy-scroll-box">
        <AnswerUpdateContextProvider onUpdate={updateAnswer}>
          <AnswersContextProvider answers={answers}>
            {question && (
              <div className="ui grid">
                <div className="row">
                  <div className="column">
                    <Question question={question} />
                  </div>
                </div>
                {questionId && question.filesEnabled && (
                  <div className="row">
                    <div className="column">
                      <Uploads
                        onUploadSuccess={handleUploadSuccess}
                        onUploadRemove={handleUploadRemove}
                        attachments={attachments}
                        questionId={questionId}
                        headers={{
                          'X-response': responseId,
                          'X-question': questionId
                        }}
                      />
                    </div>
                  </div>
                )}
                {questionId && question.commentsEnabled && (
                  <div className="row">
                    <div className="column">
                      <ResponseQuestionComments questionId={questionId} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </AnswersContextProvider>
        </AnswerUpdateContextProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleCancel}>
          <FormattedMessage id="common.labels.cancel" />
        </Button>
        <Button buttonType="primary" onClick={handleSubmit} loading={isLoading}>
          <FormattedMessage id="common.labels.submit" />
        </Button>
      </Modal.Actions>
    </>
  );
};

ResponseDisplay.defaultProps = {
  questionId: null,
  responseId: null,
  isLoading: false
};

export default function EditResponseAnswerModal() {
  // eslint-disable-next-line prettier/prettier
  const [
    modalState,
    ,
    close,
    updateData
  ] = useModal<InterviewQuestionModalState>(MODAL_ID);

  return (
    <Modal
      visible={modalState.visible}
      dimmerProps={dimmerProps}
      onClose={modalState.data && modalState.data.isLoading ? null : close}
      portal
    >
      <Modal.Header>
        <FormattedMessage id="editResponseAnswerModal.title" />
      </Modal.Header>
      {modalState.visible && modalState.data && (
        <ResponseDisplay
          {...modalState.data}
          onCancel={close}
          onSubmit={() => updateData({ isLoading: true })}
        />
      )}
    </Modal>
  );
}
