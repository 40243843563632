const dictionary = {
  'agitations.wavesGeneratorForm.fields.title.label': {
    ru: 'Название генерации',
    en: 'Generation title'
  },
  'agitations.wavesGeneratorForm.fields.title.placeholder': {
    ru: 'Введите название',
    en: 'Enter title'
  },
  'agitations.wavesGeneratorForm.fields.projectId.label': {
    ru: 'Проект',
    en: 'Project'
  },
  'agitations.wavesGeneratorForm.fields.projectId.placeholder': {
    ru: 'Укажите проект',
    en: 'Select project'
  },
  'agitations.wavesGeneratorForm.fields.waves.label': {
    ru: 'Список волн для генерации',
    en: 'Waves generation list'
  },
  'agitations.wavesGeneratorForm.fields.waves.title.placeholder': {
    ru: 'Название волны',
    en: 'Wave title'
  },
  'agitations.wavesGeneratorForm.fields.waves.period.placeholder': {
    ru: 'Сроки проведения',
    en: 'Schedule'
  },
  'agitations.wavesGeneratorForm.fields.waves.emptyListMessage': {
    ru: 'Список волн на генерацию пуст',
    en: 'Generation list is empty'
  },
  'agitations.wavesGeneratorForm.actions.addWave': {
    ru: 'Добавить волну',
    en: 'Add wave'
  }
};

export default dictionary;
