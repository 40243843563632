import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import find from 'lodash/find';

import type {
  RecordsRegionsState,
  RecordsListState
} from 'flow-types/states/RecordsState';
import type { SelectRegion } from 'flow-types/actions/records/SelectRecordRegion';
import type { IRegion } from 'flow-types/entities/RecordRegion';
import type { AppState } from 'flow-types/AppState';

import {
  recordsListStateSelector,
  recordsRegionsStateSelector
} from '../../selectors/records';

// first of all we can safely find region and take its regionId because
// we can select only region that exists inside regions list
const selectRegionWatcher = ($action, $state) =>
  $action.pipe(
    ofType('records-regions/set-selected'),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([action, state]: [SelectRegion, AppState]) => {
      const { recordId: implicitRecordId, regionId } = action;

      let recordId = implicitRecordId;

      const { selected }: RecordsListState = recordsListStateSelector(state);

      const {
        selected: selectedRegionId,
        data
      }: RecordsRegionsState = recordsRegionsStateSelector(state);

      const nextRegion: IRegion = find(data, { id: regionId });

      if (nextRegion) {
        // eslint-disable-next-line prefer-destructuring
        recordId = nextRegion.recordId;
      }

      if (!recordId || !selectedRegionId || recordId === selected) return EMPTY;

      return [
        {
          type: 'records/select',
          recordId,
          resetRegion: false
        }
      ];
    })
  );

export default selectRegionWatcher;
