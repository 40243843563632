// @flow

import * as React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import tinycolor from 'tinycolor2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTransition, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import LazyLoad from 'react-lazyload';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import Modal, {
  MODAL_ANIMATION_DURATION
} from 'common/components/animated/Modal';
import Button from 'common/components/Button';
import AdaptiveModal from 'common/components/Question/AdaptiveModal';
import { getHeightForRatio } from 'common/helpers/getDimension';
import showExpandButton from 'common/components/Question/helpers/showExpandButton';
import BackgroundImage from 'common/components/Image/Background';
import RangingQuestionBody from 'common/components/Question/RangingQuestionBody';
import VideoQuestionBody from 'common/components/Question/VideoQuestionBody';
import RatingQuestionBody from 'common/components/Question/RatingQuestionBody';
import RedirectQuestionBody from 'common/components/Question/RedirectQuestionBody';
import useDidMount from 'common/hooks/useDidMount';
import { Field } from 'common/components/Form';
import Icon from 'common/components/Icon';

import type { IInterviewQuestion } from 'flow-types/entities/Question';
import type { IInterviewAnswer } from 'flow-types/entities/InterviewAnswer';

import shouldMoveForward from 'common/components/Question/helpers/shouldMoveForward';
import SignatureQuestionBody from 'common/components/Question/SignatureQuestionBody';

import useVariablesCompiledString from 'common/components/Question/hooks/useCompileString';
import useQuestionType from '../../hooks/useQuestionType';
import { useAnswer } from '../../contexts/AnswersContext';

import { Content, ContentBar, Header, Hint } from './styled';

import DropdownSelectionQuestionBody from './DropdownSelectionBody';
import MobilePhoneQuestionBody from './MobilePhoneQuestionBody';
import DateTimeQuestionBody from './DateTimeQuestionBody';
import NumericQuestionBody from './NumericQuestionBody';
import EmailQuestionBody from './EmailQuestionBody';
import TableQuestionBody from './TableQuestionBody';
import TextQuestionBody from './TextQuestionBody';
import HTMLQuestionBody from './HTMLQuestionBody';
import URLQuestionBody from './URLQuestionBody';
import SelectionBody from './SelectionBody';

import SearchViewBody from '../../../crm/view/SearchViewBody';
import TabViewBody from '../../../crm/view/TabViewBody';
import Form from '../../containers/Form';

import ListItem from '../ListItem';
import Flexbox from '../Flexbox';
import Message from '../Message';
import List from '../List';

import type { UseQuestionType } from '../../hooks/useQuestionType';

type Props = {
  // question to draw
  question: IInterviewQuestion,
  // TODO: resolve later
  onProgress: Function,
  // TODO: resolve later
  uploadAttachment: Function,
  // active visible sub question
  activeSubQuestionId?: null | number,
  showTitle?: boolean,
  /**
   * will try to focus into an input that
   * is used in current question type
   */
  autoFocus?: boolean,
  onAnswerInput?: Function,
  /**
   * Defines what set of components should be used
   * By default it is 'classic'.
   */
  theme?: {
    componentsTheme: 'classic' | 'modern'
  }
};

const ModalSubQuestionContent = styled.div`
  min-height: calc(calc(var(--vh, 1vh) * 100) - 3.7rem) !important;
  //min-height: 100vh !important;
  // min-height of original '.content' inside overlay modal is calc(100vh - 9.1rem)
  padding-top: 2.55rem !important;
  padding-bottom: 2.55rem !important;

  overflow-x: hidden !important;

  // this way absolutely positioned subQuestions will account padding of Content
  & > div {
    position: relative;
    min-height: 100%;
  }
`;

// Currently, only dropdown and list styles generation is implemented
function generateStyles(props) {
  const { appearance, $enabled } = props;

  if (!$enabled || !appearance) {
    return null;
  }

  // language=LESS
  const dropdownOverrides = `
      .ui.selection.dropdown {
        background: ${appearance.backgroundColor ?? null};
        color: ${appearance.textColor ?? null};
      }
  `;

  // language=LESS
  const textOverrides = appearance.textColor
    ? `
    .ui.selection.visible.dropdown > .text:not(.default) {
      color: ${appearance.textColor};
    }
  `
    : '';

  // language=LESS
  const searchOverrides = appearance.textColor
    ? `
    .ui.search.dropdown.active > input.search,
    .ui.search.dropdown.visible > input.search {
      color: ${appearance.textColor};
    }

    .ui.active.search.dropdown input.search:focus + .text {
      color: ${tinycolor(appearance.textColor)
        .desaturate(35)
        .toRgbString()} !important;
    }
  `
    : '';

  // language=LESS
  const searchSelectionOverrides =
    appearance.textColor && appearance.backgroundColor
      ? `
    .ui.dropdown textarea::-webkit-selection,
    .ui.dropdown input::-webkit-selection {
      background-color: ${appearance.textColor};
      color: ${appearance.backgroundColor};
    }
    .ui.dropdown textarea::-moz-selection,
    .ui.dropdown input::-moz-selection {
      background-color: ${appearance.textColor};
      color: ${appearance.backgroundColor};
    }
    .ui.dropdown textarea::selection,
    .ui.dropdown input::selection {
      background-color: ${appearance.textColor};
      color: ${appearance.backgroundColor};
    }
  `
      : '';

  return css`
    ${dropdownOverrides}
    ${textOverrides}
    ${searchOverrides}
    ${searchSelectionOverrides};
  `;
}

const StyledForm = styled.div`
  ${props => generateStyles(props)};
`;

function QuestionTitle({ title }: { title: string }): React.Node {
  const compile = useVariablesCompiledString();

  return compile(title);
}

function Question({
  onAnswerInput,
  onProgress,
  theme,
  question,
  showTitle,
  activeSubQuestionId,
  uploadAttachment,
  autoFocus
}: Props): React.Node {
  const intl = useIntl();

  const [showSelectionModal, setShowSelectionModal] = React.useState(false);

  const { id, type, title, hint, required, image } = question;

  const answer: IInterviewAnswer = useAnswer(id, {});

  const {
    isChecklist,
    isEmail,
    isHTML,
    isMobilePhone,
    isMultipleAnswer,
    isRangingAnswer,
    isRedirect,
    isRating,
    isSecondaryPhones,
    isTextBlock,
    isURL,
    isVideo,
    isOneAnswer,
    isDateTime,
    isTable,
    isNumeric,
    isTabView,
    isSearchView,
    isSignature,
    isStatus
  }: UseQuestionType = useQuestionType(type);

  const [ref, { width }, forceMeasure] = useMeasure({ scroll: false });

  useDidMount(() => {
    forceMeasure();
  });

  const isSinglePageTable = isTable && question.settings?.viewType === 'single';

  const TitleHeader = isSinglePageTable ? 'h4' : null;

  const answerErrors = answer?.errors ?? [];

  const imageInfo = image?.imageInfo;

  const heightForRatio = imageInfo
    ? getHeightForRatio(width, imageInfo?.ratio, imageInfo?.height)
    : null;

  return (
    <ThemeProvider theme={theme}>
      <Form
        appearance={question.settings ? question.settings.appearance : null}
        $enabled={isOneAnswer || isMultipleAnswer || isChecklist || isStatus}
        tagName={StyledForm}
        validation={answerErrors.length > 0 ? answerErrors : null}
        preventEventFlow
        ref={ref}
      >
        <Flexbox $direction="row">
          <ContentBar>
            {showTitle && (
              <Header as={TitleHeader} light={isSinglePageTable}>
                <QuestionTitle title={title} />
                {!isSinglePageTable && required && (
                  <Icon className="top-aligned" icon="red tiny asterisk" />
                )}
              </Header>
            )}
            {hint && (
              <Hint>
                <span className="ui light grey text">{hint}</span>
              </Hint>
            )}
            {!!image && (
              <Field>
                {width > 0 && (
                  <LazyLoad
                    scrollContainer=".lazy-scroll-box"
                    height={heightForRatio}
                    offset={100}
                  >
                    <BackgroundImage
                      className="ui rounded image"
                      image={image}
                      height={heightForRatio}
                      maxHeight="75vh"
                    />
                  </LazyLoad>
                )}
              </Field>
            )}
            {answerErrors.length > 0 && (
              <Message status="error" visible>
                <List as="ul" nonUI>
                  {map(uniqBy(answerErrors, 'messageId'), (error, index) => {
                    const errorMsg: string =
                      typeof error === 'string'
                        ? error
                        : intl.formatMessage(
                            {
                              // TODO: localize
                              id: error.messageId ?? 'unknown.error',
                              // TODO: localize
                              defaultMessage: error.messageId
                                ? `Код ошибки: ${error.messageId}`
                                : 'Неопознанная ошибка'
                            },
                            error.values
                          );

                    return (
                      <ListItem
                        key={
                          typeof error === 'string'
                            ? `message-${index}`
                            : error.messageId
                        }
                      >
                        {errorMsg}
                        {error.items && (
                          <List>
                            {map(error.items, item => (
                              <ListItem>{item}</ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </Message>
            )}
            <Content noLeftMargin={isSinglePageTable}>
              {showExpandButton(question) && (
                <Button
                  onClick={() => setShowSelectionModal(true)}
                  icon
                  compact
                  buttonType="basic"
                  labeled
                >
                  <Icon icon="expand" />
                  <FormattedMessage id="common.labels.detailView" />
                </Button>
              )}
              {isTable && question.settings?.viewType === 'table' && (
                <TableQuestionBody question={question} />
              )}
              {isSinglePageTable && (
                <Question
                  showNavigation={false}
                  noContentMargin
                  question={find(question.subQuestions, {
                    id: activeSubQuestionId
                  })}
                  showId={false}
                  showTitle
                  theme={theme}
                  onAnswerInput={onAnswerInput}
                />
              )}
              {(isMultipleAnswer || isChecklist || isOneAnswer || isStatus) &&
                question.viewType === 'select' && (
                  <DropdownSelectionQuestionBody
                    question={question}
                    onAnswerInput={onAnswerInput}
                  />
                )}
              {(isMultipleAnswer || isChecklist || isOneAnswer || isStatus) &&
                question.viewType !== 'select' && (
                  <SelectionBody
                    appearance="list"
                    question={question}
                    onAnswerInput={onAnswerInput}
                  />
                )}
              {isRangingAnswer && <RangingQuestionBody question={question} />}
              {isRedirect && <RedirectQuestionBody question={question} />}
              {isRating && (
                <RatingQuestionBody
                  onAnswerInput={onAnswerInput}
                  question={question}
                />
              )}
              {(isMultipleAnswer ||
                isChecklist ||
                isOneAnswer ||
                isRangingAnswer) &&
                question.showImages && (
                  <AdaptiveModal
                    data-swipe-ignore="true"
                    visible={showSelectionModal}
                    onClose={() => setShowSelectionModal(false)}
                    portal
                    dimmerProps={{
                      page: true
                    }}
                  >
                    <Modal.Content>
                      <SelectionBody
                        appearance="slider"
                        question={question}
                        onAnswerInput={(nextAnswer, meta) => {
                          if (
                            shouldMoveForward({
                              type: question.type,
                              viewType: question.viewType,
                              isExcludingOptionSelected:
                                meta?.isExcludingOptionSelected,
                              instantNagivation:
                                question.settings?.instantNagivation
                            })
                          ) {
                            setShowSelectionModal(false);
                            setTimeout(() => {
                              onAnswerInput?.(nextAnswer, meta);
                            }, MODAL_ANIMATION_DURATION);
                          } else {
                            onAnswerInput?.(nextAnswer, meta);
                          }
                        }}
                      />
                    </Modal.Content>
                  </AdaptiveModal>
                )}
              {isTable && (
                <QuestionsSliderModal
                  data-swipe-ignore="true"
                  visible={showSelectionModal}
                  onClose={() => setShowSelectionModal(false)}
                  portal
                  overlay
                  size="fullscreen"
                  theme={theme}
                  questions={filter(question.subQuestions, q => !q.hidden)}
                />
              )}
              {isURL && <URLQuestionBody question={question} />}
              {isVideo && (
                <VideoQuestionBody
                  onProgress={onProgress}
                  question={question}
                />
              )}
              {isEmail && (
                <EmailQuestionBody question={question} autoFocus={autoFocus} />
              )}
              {(isMobilePhone || isSecondaryPhones) && (
                <MobilePhoneQuestionBody
                  question={question}
                  autoFocus={autoFocus}
                />
              )}
              {isTextBlock && (
                <TextQuestionBody question={question} autoFocus={autoFocus} />
              )}
              {isSignature && (
                <SignatureQuestionBody
                  questionId={question.id}
                  uploadAttachment={uploadAttachment}
                  onAnswerInput={onAnswerInput}
                />
              )}
              {isHTML && <HTMLQuestionBody question={question} />}
              {isDateTime && <DateTimeQuestionBody question={question} />}
              {isNumeric && (
                <NumericQuestionBody
                  question={question}
                  autoFocus={autoFocus}
                  onAnswerInput={onAnswerInput}
                />
              )}
              {isTabView && <TabViewBody question={question} />}
              {isSearchView && <SearchViewBody question={question} />}
            </Content>
          </ContentBar>
        </Flexbox>
      </Form>
    </ThemeProvider>
  );
}

type QuestionsSliderModalProps = {
  questions: IInterviewQuestion[],
  onClose: Function,
  visible: boolean,
  theme: $PropertyType<Props, 'theme'>
};

const questionSliderDimmerProps = { page: true };

/**
 * TODO: if somewhen it will ba supported to dynamically hide subquestions,
 *  this component should be able to go through that event
 */
function QuestionsSliderModal({
  questions,
  onClose,
  visible,
  theme,
  ...props
}: QuestionsSliderModalProps) {
  const [[index, dir], set] = React.useState([0, 'right']);

  React.useEffect(() => {
    if (!visible) {
      set([0, 'right']);
    }
  }, [visible]);

  const slidePrev = React.useCallback(
    () =>
      set(([prevIndex]) => [
        prevIndex - 1 < 0 ? questions.length - 1 : prevIndex - 1,
        'left'
      ]),
    [questions.length]
  );

  const slideNext = React.useCallback(() => {
    set(([prevIndex]) => [
      prevIndex + 1 > questions.length - 1 ? 0 : prevIndex + 1,
      'right'
    ]);
  }, [questions.length]);

  const transitions = useTransition(questions[index], {
    key: questions?.[index]?.id,
    expires: 0,
    from: {
      opacity: 0.5,
      transform: `translate3d(${dir === 'right' ? 100 : -100}%,0,0)`
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)'
    },
    leave: {
      opacity: 0.5,
      transform: `translate3d(${dir === 'right' ? -100 : 100}%,0,0)`
    }
  });

  return (
    // $FlowIgnore
    <Modal {...props} dimmerProps={questionSliderDimmerProps} visible={visible}>
      <Modal.Content scrolling tagName={ModalSubQuestionContent}>
        <div>
          {transitions((style, question) => (
            <animated.div
              style={{
                ...style,
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              }}
            >
              <Question
                question={question}
                showNavigation={false}
                noContentMargin
                showId={false}
                theme={theme}
              />
            </animated.div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions className="touch-action-manipulation">
        <Button onClick={onClose} color="black">
          <FormattedMessage id="common.labels.close" />
        </Button>
        <Button
          icon
          onClick={slidePrev}
          compact
          disabled={index === 0}
          buttonType="primary"
        >
          <Icon icon="chevron left" />
        </Button>
        <Button
          icon
          onClick={slideNext}
          compact
          disabled={index + 1 === questions.length}
          buttonType="primary"
        >
          <Icon icon="chevron right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

Question.defaultProps = {
  autoFocus: false,
  showTitle: true,
  activeSubQuestionId: null,
  onAnswerInput: null,
  theme: {
    componentsTheme: 'classic'
  }
};

export default Question;
