// @flow

import React, { useMemo } from 'react';
import { FilePond as BaseComponent } from 'react-filepond';
import { useIntl } from 'react-intl';
import type { Node, AbstractComponent } from 'react';
import type { FilePondFile } from './flow';

export type FilePondProps = {
  messageId: string,
  values: Object,
  // The root element of the FilePond instance. This property has no setter
  element: mixed,
  // Returns the current status of the FilePond instance, use the FilePond.Status enum to determine the status. Has no setter.
  // eslint-disable-next-line react/require-default-props
  status?: number,
  // The input field name to use
  name: string,
  // Additional CSS class to add to the root element
  className: string | null,
  // Sets the required attribute to the output field
  required: boolean,
  // Sets the disabled attribute to the output field
  disabled?: boolean,
  // Sets the given value to the capture attribute
  captureMethod: string | null,
  // Enable or disable drag n’ drop
  allowDrop: boolean,
  // Enable or disable file browser
  allowBrowse: boolean,
  // Enable or disable pasting of files.
  // Pasting files is not supported on all browesrs.
  allowPaste: boolean,
  // Enable or disable adding multiple files
  allowMultiple: boolean,
  // Allow drop to replace a file,
  // only works when allowMultiple is false
  allowReplace: boolean,
  // Allow users to reorder files with drag and drop interaction. Note that this only works in single column mode.
  // It also only works on browsers that support pointer events.
  allowReorder: boolean,
  // Allows the user to revert file upload
  allowRevert: boolean,
  // Set to true to require the file to be successfully
  // reverted before continuing
  forceRevert: boolean,
  // The maximum number of files that the pond can handle
  maxFiles: number | null,
  // The maxmimum number of files that
  // can be uploaded in parallel
  maxParallelUploads: number | null,
  // Set to true to enable custom validity messages.
  // FilePond will throw an error when a parent form
  // is submitted and it contains invalid files.
  checkValidity: boolean,
  // Set to 'after' to add files to end of list
  // (when dropped at the top of the list or added using browse or paste),
  // set to 'before' to add files at start of list.
  // Set to a compare function to automatically sort items when added.
  itemInsertLocation: 'before' | 'after' | Function,
  // The interval to use before showing each item being
  // added to the list
  itemInsertInterval: number,

  // FilePond will catch all files dropped on the webpage
  dropOnPage: boolean,
  // Require drop on the FilePond element itself to catch the file.
  dropOneElement: boolean,
  // When enabled, files are validated before they are dropped.
  // A file is not added when it’s invalid.
  dropValidation: boolean,
  // Ignored file names when handling dropped directories.
  // Dropping directories is not supported on all browsers.
  ignoredFiles: string[],

  // A list of file locations that should be loaded Immediately,
  // read more about setting the initial files
  files: FilePondFile[],
  // Immediately upload new files to the server
  instantUpload: boolean,
  // Server API configuration
  server: string | null | Object,
  // Enable chunked uploads, when enabled
  // will automatically cut up files in chunkSize
  // chunks before upload
  chunkUploads: boolean,
  // Force chunks even for files smaller
  // than the set chunkSize
  chunkForce: boolean,
  // The size of a chunk in bytes
  chunkSize: number,
  // Amount of times, and delayes,
  // between retried uploading of a chunk
  chunkRetryDelays: Array<number>,

  labelDecimalSeparator: 'auto' | number,
  labelThousandsSeparator: 'auto' | number,
  labelIdle: string,
  labelInvalidField: string,
  labelFileWaitingForSize: string,
  labelFileSizeNotAvailable: string,
  labelFileLoading: string,
  labelFileLoadError: string,
  labelFileProcessing: string,
  labelFileProcessingComplete: string,
  labelFileProcessingAborted: string,
  labelFileProcessingError: string,
  labelFileProcessingRevertError: string,
  labelFileRemoveError: string,
  labelTapToCancel: string,
  labelTapToRetry: string,
  labelTapToUndo: string,
  labelButtonRemoveItem: string,
  labelButtonAbortItemLoad: string,
  labelButtonRetryItemLoad: string,
  labelButtonAbortItemProcessing: string,
  labelButtonUndoItemProcessing: string,
  labelButtonRetryItemProcessing: string,
  labelButtonProcessItem: string,

  /**
   * 	Enable or disable file type validation
   */
  allowFileTypeValidation?: boolean,

  /**
   *Array of accepted file types.
   * Can be mime types or wild cards.
   * For instance ['image/*'] will accept all images,
   * when ['image/png', 'image/jpeg'] will only accepts PNGs and JPEGs.
   */
  acceptedFileTypes?: string[],

  /**
   * Message shown when an invalid file is added
   */
  labelFileTypeNotAllowed?: string,

  /**
   * Message shown to indicate the allowed file types.
   * Available placeholders are {allTypes}, {allButLastType}, {lastType}.
   */
  fileValidateTypeLabelExpectedTypes?: string,

  /**
   * Allows mapping the file type to a more visually appealing label,
   * { 'image/jpeg': '.jpg' } will show .jpg in the expected types label.
   * Set to null to hide a type from the label.
   */
  fileValidateTypeLabelExpectedTypesMap?: {
    [string]: string
  },

  /**
   * A function that receives a file and the type detected by FilePond,
   * should return a Promise,
   * resolve with detected file type,
   * reject if can’t detect.
   */
  fileValidateTypeDetectType?: null | Function,

  innerRef: Function,
  oninit: () => void,
  onupdatefiles: (fileItem: FilePondFile[]) => void,
  onwarning: (error: mixed, file: FilePondFile, status: mixed) => void
};

const FilePond: AbstractComponent<FilePondProps, any> = React.forwardRef(
  (
    { labelIdle, messageId, values, innerRef, ...rest }: FilePondProps,
    ref
  ): Node => {
    const intl = useIntl();

    const label = useMemo(
      () => (messageId ? intl.formatMessage({ id: messageId }, values) : null),
      [intl, messageId, values]
    );

    return <BaseComponent {...rest} ref={ref} labelIdle={label || labelIdle} />;
  }
);

// $FlowIgnore
FilePond.defaultProps = {
  disabled: false,
  labelIdle: `
    Drag & Drop your files or <span class="filepond--label-action"> Browse </span>
  `,
  allowFileTypeValidation: undefined,
  acceptedFileTypes: undefined,
  labelFileTypeNotAllowed: undefined,
  fileValidateTypeLabelExpectedTypes: undefined,
  fileValidateTypeLabelExpectedTypesMap: undefined,
  fileValidateTypeDetectType: undefined
};

FilePond.displayName = 'FilePond.Forwarded';

export default FilePond;
