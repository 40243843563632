import { combineEpics } from 'redux-observable';
import saveRegionEpic from './saveRegion';
import removeRegionEpic from './removeRegion';
import fetchRecordPeaks, { fetchPeaksOnRecordSelection } from './fetchPeaks';
import fetchRegions from './fetchRegions';
import resetTracksEpic from './resetTracks';
import selectRegionWatcher from './syncRecordWithRegionId';
import createRegion from './createRegion';
import selectRegionEpic from './selectRegion';
import decodingsEpic from './decodings';

export default combineEpics(
  resetTracksEpic,
  createRegion,
  saveRegionEpic,
  removeRegionEpic,
  fetchRecordPeaks,
  fetchRegions,
  selectRegionWatcher,
  fetchPeaksOnRecordSelection,
  selectRegionEpic,
  decodingsEpic
);
