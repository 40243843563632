import fp from 'lodash/fp';
import pullAt from 'lodash/pullAt';
import values from 'lodash/values';
import forEach from 'lodash/forEach';
import size from 'lodash/size';

const sortById = fp.compose(
  fp.map.convert({ cap: false })((g, i) => ({ ...g, order: i })),
  fp.sortBy('id')
);

const sortByOrder = fp.compose(
  fp.map.convert({ cap: false })((g, i) => ({ ...g, order: i })),
  fp.sortBy('order')
);

const sortByPosition = fp.map.convert({ cap: false })((item, index) => ({
  ...item,
  order: index
}));

const someHasOder = fp.some(g => !!g.order);

const everyHasOrder = fp.every(g => !!g.order);

// places elements in the proper order according to existing information
const sortingItemsNormalizer = (
  items,
  strict = false,
  moveNullableOrderToEnd = false
) => {
  if (!items || size(items) === 0) return [];

  // TODO: server should fix items being of 'object' type case,
  //  afterwards it can be replaced with [...items]
  let result = values(items);

  if (moveNullableOrderToEnd) {
    const unorderedItems = [];

    forEach(items, (item, index) => {
      if (item.order === null) {
        unorderedItems.push(item);
        pullAt(result, [index]);
      }
    });

    result = [...sortingItemsNormalizer(result), ...unorderedItems];

    return sortingItemsNormalizer(result, true);
  }

  if (strict) {
    return sortByPosition(result);
  }

  const sortingStatus: {| some: boolean, all: boolean |} = {
    some: someHasOder(result),
    all: everyHasOrder(result)
  };

  if (sortingStatus.all) return fp.sortBy('order')(result);

  if (!sortingStatus.some) {
    return sortById(result);
  }

  return sortByOrder(result);
};

export function recalculateOrder(list) {
  return list.map((item, index) => ({ ...item, order: index }));
}

export default sortingItemsNormalizer;
