// @flow

import { ofType, combineEpics } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import {
  switchMap,
  mergeMap,
  map,
  catchError,
  withLatestFrom
} from 'rxjs/operators';
import request from '../../utils/request';
import { API } from '../../utils/config';
import responseParser from '../../common/epicHelpers/responseParser';
import listResponseParser from '../../common/epicHelpers/listResponseParser';
import { variableValuesSelector } from '../../selectors/crm';

import type { Epic } from '../../flow-types/Epic';

import {
  crmDataNormalizer,
  crmQueryDenormalizer
} from '../common/CrmDataParser';

const fetchEntities: Epic = $action =>
  $action.pipe(
    ofType('crm/fetch-entities'),
    switchMap(() =>
      request({
        url: `${API.crm.entities}`,
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        map(response => {
          const { data } = response;
          return {
            type: 'crm/fetch-entities-success',
            data: crmDataNormalizer(data)
          };
        }),
        catchError(({ response, message }) =>
          of({
            type: 'crm/fetch-entities-fail',
            error: response ? response.error : message
          })
        )
      )
    )
  );

const tabsPreviewEpic: Epic = $action =>
  $action.pipe(
    ofType('crm/tabs-preview'),
    switchMap(({ payload, code }) => {
      const body = payload.map(({ tableName, filters }) => ({
        table_name: tableName,
        action: 'count',
        filters
      }));

      return request({
        url: API.crm.executeQuery,
        method: 'POST',
        body
      }).pipe(
        mergeMap(({ response: { data } }) =>
          data.map(({ total }, tabIndex) => ({
            type: 'crm/tabs-preview-loaded-success',
            payload: {
              code,
              total,
              tabIndex
            }
          }))
        ),
        catchError(({ response, message }) =>
          of({
            type: 'crm/tabs-preview-loaded-failure',
            error: response ? response.error : message
          })
        )
      );
    })
  );

// TODO: we have a similar epic called executeQuery, we should try to reuse common logic
const tabsDataEpic: Epic = $action =>
  $action.pipe(
    ofType('crm/tab-displayed'),
    switchMap(action => {
      const { payload } = action;
      return request({
        url: `${API.crm.executeQuery}`,
        method: 'POST',
        body: [crmQueryDenormalizer(payload)]
      }).pipe(
        map(({ response: { data } }) => {
          const { items, pages, current } = data[0];
          return {
            type: 'crm/tab-data-loaded-success',
            meta: {
              code: payload.code,
              tabIndex: payload.tabIndex
            },
            payload: {
              data: items,
              pages,
              current
            }
          };
        }),
        catchError(({ response, message }) =>
          of({
            type: 'crm/tab-data-loaded-failure',
            error: response ? response.error : message
          })
        )
      );
    })
  );

// TODO: we don't need this epic at all, just send a single event and handle where it's needed
const updateQueryFilters: Epic = $action =>
  $action.pipe(
    ofType('crm/view/update-query-filters'),
    switchMap(action => {
      const { code, filters } = action;
      return [
        {
          type: 'crm/view/set-query-filters',
          code,
          filters
        },
        {
          type: 'crm/query/execute',
          code,
          filters
        }
      ];
    }),
    catchError(({ response, message }) =>
      of({
        type: 'crm/query/execute-fail',
        error: response ? response.error : message
      })
    )
  );

// TODO: the same story as a previous one
const setSelectedOption: Epic = $action =>
  $action.pipe(
    ofType('crm/view/set-selected-option'),
    switchMap(action => {
      const { code, selectedOption } = action;
      return [
        {
          type: 'crm/view/save-selected-option',
          code,
          selectedOption
        },
        {
          type: 'crm/variables-value-changed',
          code,
          selectedOption
        }
      ];
    }),
    catchError(({ response, message }) =>
      of({
        type: 'crm/query/execute-fail',
        error: response ? response.error : message
      })
    )
  );

const restoreSearchBlocks: Epic = ($action, $state) =>
  $action.pipe(
    ofType('crm/search-blocks-displayed'),
    withLatestFrom($state),
    mergeMap(([action, state]) => {
      const { code, query, variableId } = action.payload;
      const variableValues = variableValuesSelector(state);

      if (!variableValues) {
        return EMPTY;
      }

      const variableValue = variableValues.find(
        ({ projectVariableId }) => projectVariableId === variableId
      );

      if (!variableValue) {
        return EMPTY;
      }

      const body = crmQueryDenormalizer({
        ...query,
        filters: [
          {
            field: `${query.entity.tableName}.id`,
            operator: 'EQ',
            value: variableValue.value
          }
        ]
      });

      // TODO: probably it can be simplified, check it working on tabs
      return request({
        url: API.crm.executeQuery,
        method: 'POST',
        body: [body]
      }).pipe(
        switchMap(response => {
          const {
            response: { data }
          } = response;

          const queryResult = crmDataNormalizer(data[0]);

          return [
            {
              type: 'crm/view/set-query-result',
              code,
              queryResult
            },
            {
              type: 'crm/variables-value-changed',
              code,
              // here we should always have a sindle item
              selectedOption: queryResult.items[0]
            }
          ];
        }),
        catchError(({ response, message }) =>
          of({
            type: 'crm/query/execute-fail',
            error: response ? response.error : message
          })
        )
      );
    })
  );

export default combineEpics(
  tabsDataEpic,
  tabsPreviewEpic,
  fetchEntities,
  updateQueryFilters,
  setSelectedOption,
  restoreSearchBlocks
);
