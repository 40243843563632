// @flow
import * as React from 'react';
import type { $ObjOfType } from 'flow-types/ObjOfType';
import isSelected from 'common/helpers/isSelected';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import { toast } from 'react-toastify';
import isNaN from 'lodash/isNaN';
import { useIntl } from 'react-intl';

/**
 * @example
 *   const {
    // когда за раз меняется статус выборки одного элемента
    handleSelectionBySingle,
    // когда за раз меняется статус выборки нескольких элементов
    handleSelectionByMulti,
    // обрабатывает ввод пользовательского значения для опции, которая его принимает,
    handleUserInput,
    // показывает, выбрана ли исключающая опция
    // isExcludingSelected,
    // текущие выбранные, приходят также в nextAnswer в onChange
    // selected
    // текущие вводы от пользователя в опции, которые принимают значения
    // userInputs
  } = useSelection({
    items: [],
    // will be used as a source of truth for selected
    selected: data,
    // will be used as a source of truth for userInputs
    userInputs: meta,
    // вызывается, когда меняется текст опции с "польз.вводом"
    // понадобится если нужно сохранять "другое" в мете ответа, когда оно меняется
    onUserInputChange: (nextAnswer, nextUserInput) => {},
    // вызывается, когда меняется выборка опций
    onSelectionChange: (nextAnswer, nextUserInput) => {
      // если выбрана опция "другое", то берётся её текст из второго аргумента и вставляется в мету
      // если нет, то ничего
    },
    multi: !isOneAnswer,
    max: question.maxAnswers,
    // будет показывать сообщение о превышении лимита выборки
    // при попытке выбрать более, чем {max} элементов,
    // не применяется если {max} === null || {max} === 0,
    // так как это противоречит самой идее что-то выбрать.
    showMaxWarningOnExceedingAttempt: true
  });
 */

type UseSelectionConfig = {
  items: Object[],
  selected: Array<string | number>,
  userInputs: $ObjOfType<?string>,
  onUserInputChange?: (
    nextAnswer: mixed[],
    nextUserInputs: $ObjOfType<?string>
  ) => void,
  onSelectionChange: (
    nextAnswer: mixed[],
    nextUserInputs: $ObjOfType<?string>,
    meta: {
      isExcludingItemSelected: boolean
    }
  ) => void,
  multi: boolean,
  max?: number | null,
  showMaxWarningOnExceedingAttempts?: boolean
};

export function useSelection({
  items,
  selected,
  userInputs,
  onUserInputChange,
  onSelectionChange,
  multi,
  max,
  showMaxWarningOnExceedingAttempts
}: UseSelectionConfig): {
  isExcludingItemSelected: boolean,
  handleSelectionByMulti: Function,
  handleUserInput: Function
} {
  const intl = useIntl();

  const [excludingItemsIds, setExcludingItemsIds] = React.useState([]);

  const selectedTemp = React.useRef(null);

  React.useEffect(() => {
    setExcludingItemsIds(items.filter(i => i.isExcluding).map(i => i.id));
  }, [items]);

  const isExcludingItemSelected = isSelected(
    excludingItemsIds,
    selected,
    multi,
    true
  );

  const handleSelectionByMulti = optionsIds => {
    // TODO: resolve later
    // $FlowIgnore
    // eslint-disable-next-line no-use-before-define
    let nextAnswerData = normalizeSelectedOptionsIds(optionsIds);

    // может быть такой случай, когда во множественном вопросе,
    // мы меняем статус одного элемента за раз
    if (
      multi &&
      !Array.isArray(optionsIds) &&
      (typeof optionsIds === 'string' || typeof optionsIds === 'number')
    ) {
      if (selected?.includes(optionsIds)) {
        nextAnswerData = selected.filter(itemId => itemId !== optionsIds);
      } else {
        nextAnswerData = [...(selected ?? []), optionsIds];
      }
    } else if (!multi && selected === nextAnswerData) {
      nextAnswerData = null;
    }

    const isNextExcludingItemSelected = isSelected(
      excludingItemsIds,
      nextAnswerData,
      multi,
      multi
    );

    if (isNextExcludingItemSelected) {
      // $FlowFixMe
      // eslint-disable-next-line no-nested-ternary
      selectedTemp.current = !multi
        ? selected
        : Array.isArray(selected)
        ? [...selected]
        : [];

      /*
       Если ожидается множественная выборка,
       то в этом случае, если выбрана исключающая опция,
       то нужно убрать из ответа все те,
       что не попадают под определение "исключающая опция"
      */
      if (multi) {
        nextAnswerData = filter(
          nextAnswerData,
          optionId =>
            includes(excludingItemsIds, optionId) ||
            includes(excludingItemsIds, `${optionId}`)
        );
      }
    } else if (isExcludingItemSelected) {
      // reset value check
      if (
        nextAnswerData === null ||
        (Array.isArray(nextAnswerData) && nextAnswerData.length === 0)
      ) {
        nextAnswerData = selectedTemp.current;
      }
    }

    if (
      showMaxWarningOnExceedingAttempts &&
      multi &&
      typeof max === 'number' &&
      max > 0 &&
      size(nextAnswerData) > max
    ) {
      toast.error(
        intl.formatMessage(
          {
            id: 'question.validation.maxAnswers'
          },
          { count: max }
        ),
        {
          autoClose: 2500
        }
      );
      return;
    }

    onSelectionChange?.(nextAnswerData, userInputs, {
      isExcludingItemSelected: isNextExcludingItemSelected
    });
  };

  const handleUserInput = (optionId, optionValue) => {
    const nextUserInput = { ...(userInputs ?? {}) };

    nextUserInput[optionId] = optionValue;

    onUserInputChange?.(selected, nextUserInput);
  };

  return {
    // selected,
    // userInputs,
    isExcludingItemSelected,
    handleSelectionByMulti,
    handleUserInput
  };
}

function normalizeSelectedOptionsIds(
  nextData?: null | string | number | string[] | number[]
): any {
  if (isNil(nextData) || nextData === 'other') return nextData;

  if (Array.isArray(nextData)) {
    return nextData.map(optionId => {
      if (optionId === 'other') return optionId;

      if (!isNaN(optionId)) {
        return +optionId;
      }

      return optionId;
    });
  }

  if (!isNaN(nextData)) {
    return +nextData;
  }

  return nextData;
}
