// @flow

import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';

/**
 * Method checks that a given value suits min and max and corrects
 * it when required
 */
export const processRatingValue = (
  data?: null | number,
  min: number,
  max: number
): number | null => {
  let result = null;

  if (Array.isArray(data)) {
    const val = data?.[0];
    result = isNil(val) ? 1 : val;
  } else if (typeof data === 'number') {
    result = data;
  } else if (typeof data === 'string' && !isNaN(data)) {
    result = +data;
  } else {
    return null;
  }

  if (result < min) {
    return min;
  }

  if (result > max) {
    return max;
  }

  return result;
};
