import type { Epic } from 'flow-types/Epic';
import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  SaveExportSettings,
  SaveExportSettingsFail,
  SaveExportSettingsSuccess
} from 'flow-types/actions/projects/detail/export/SaveExportSettings';
import isNewElement from 'common/helpers/isNewElement';
import type { ProjectExportSettingsFormState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import { API } from 'utils/config';
import request from 'utils/request';
import interpolateString from 'common/helpers/interpolateString';
import { decamelizeAndDenormalizeExportSettings } from 'common/transducers/projects/exportSettingsDenormalizer';
import responseParser from 'common/epicHelpers/responseParser';
import type { IExportSettings$Raw } from 'flow-types/entities/ExportSettings';
import { camelizeAndNormalizeExportSettings } from 'common/transducers/projects/exportSettingsNormalizer';
import { AjaxError } from 'rxjs/ajax';
import { toast } from 'react-toastify';
import type { AppState } from 'flow-types/AppState';
import {
  projectExportSettingsForm,
  projectIdFromPathSelector
} from '../../../../selectors/projects';

const saveSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-export/save-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(
      ([{ asNew }, state]: [SaveExportSettings, AppState]) => {
        const projectId = projectIdFromPathSelector(state);

        const formState: ProjectExportSettingsFormState = projectExportSettingsForm(
          state
        );

        const data = { ...formState.data };

        if (asNew) {
          delete data.id;
        }

        const isNew = isNewElement(data, 'id');

        const method = isNew ? 'POST' : 'PUT';

        const url = interpolateString(
          isNew
            ? API.projects.exportSettings.list
            : API.projects.exportSettings.detail,
          {
            projectId,
            settingsId: data.id || null
          }
        );

        const body = decamelizeAndDenormalizeExportSettings(data);

        return request({
          url,
          method,
          body
        }).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.map(
            (nextSettings: IExportSettings$Raw): SaveExportSettingsSuccess => ({
              type: 'project-export/save-settings-success',
              data: camelizeAndNormalizeExportSettings(nextSettings)
            })
          ),
          RxOperators.catchError(({ message, response }: AjaxError): [
            SaveExportSettingsFail
          ] => {
            toast.error('error during export settings save process', {
              autoClose: 2500,
              showCloseButton: true,
              position: 'bottom-center'
            });

            return [
              {
                type: 'project-export/save-settings-fail',
                message,
                response
              }
            ];
          })
        );
      }
    )
  );

export default saveSettingsEpic;
