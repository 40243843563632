import { merge } from 'merge-anything';

import type { ILanguageKeyedDictionary } from 'flow-types/Dictionary';

const composeDictionaries = (...dictionaries): ILanguageKeyedDictionary =>
  dictionaries.reduce(
    (_messagesDictionary, dictionary) =>
      dictionary
        ? merge({ ..._messagesDictionary }, { ...dictionary })
        : _messagesDictionary,
    {}
  );

export default composeDictionaries;
