import revertDictionary from '../../utils/revertDictionary';

const logicRuleFormDictionary = {
  'logicRuleForm.title': {
    en: 'Project logic',
    ru: 'Логика проекта'
  },
  'logicRuleForm.mode': {
    en: 'New rule',
    ru: 'Новое условие'
  },
  'logicRuleForm.fields.title.label': {
    en: 'Title',
    ru: 'Название условия'
  },
  'logicRuleForm.fields.priority.label': {
    en: 'Priority',
    ru: 'Приоритет'
  },
  'logicRuleForm.actionForm.title': {
    en: 'Actions',
    ru: 'Действия'
  },
  'logicRuleForm.actionForm.type.show': {
    en: 'Show',
    ru: 'Показать'
  },
  'logicRuleForm.actionForm.type.hide': {
    en: 'Hide',
    ru: 'Скрыть'
  },
  'logicRuleForm.actionForm.type.sort': {
    en: 'Sort',
    ru: 'Сортировать'
  },
  'logicRuleForm.actionForm.type.goto': {
    en: 'Move',
    ru: 'Перейти'
  },
  'logicRuleForm.actionForm.type.required': {
    en: 'Require',
    ru: 'Сделать обязательным'
  },
  'logicRuleForm.actionForm.type.send_sms': {
    en: 'Send SMS',
    ru: 'Отправить SMS'
  },
  'logicRuleForm.actionForm.group.placeholder': {
    en: 'Group',
    ru: 'Группа'
  },
  'logicRuleForm.testForm.title': {
    en: 'Tests',
    ru: 'Проверки'
  },
  'logicRuleForm.testForm.operator.and': {
    en: 'AND',
    ru: 'И'
  },
  'logicRuleForm.testForm.operator.or': {
    en: 'OR',
    ru: 'ИЛИ'
  },
  'logicRuleForm.testForm.operator.in': {
    en: 'includes',
    ru: '{multi, select, true {выбраны} false {выбрано} other {выбраны}}'
  },
  'logicRuleForm.testForm.operator.not_in': {
    en: 'excludes',
    ru:
      '{multi, select, true {не выбраны} false {не выбрано} other {не выбраны}}'
  },
  'logicRuleForm.testForm.operator.eq': {
    en: 'equals',
    ru: 'равно'
  },
  'logicRuleForm.testForm.operator.lte': {
    en: 'less than or equal',
    ru: 'меньше или равно'
  },
  'logicRuleForm.testForm.operator.gte': {
    en: 'greater than or equal',
    ru: 'больше или равно'
  },
  'logicRuleForm.testForm.operator.lt': {
    en: 'less than',
    ru: 'меньше'
  },
  'logicRuleForm.testForm.operator.gt': {
    en: 'greater than',
    ru: 'больше'
  },
  'logicRuleForm.testForm.operator.empty': {
    en: 'is empty',
    ru: 'без значения'
  },
  'logicRuleForm.testForm.operator.not_empty': {
    en: 'is filled',
    ru: 'содержит значение'
  },
  'logicRuleForm.testForm.operator.placeholder': {
    en: 'Choose an operator',
    ru: 'Выберите оператор'
  },
  'logicRuleForm.testForm.union_type.allOf': {
    en: 'all of',
    ru: 'все из'
  },
  'logicRuleForm.testForm.union_type.oneOf': {
    en: 'one of',
    ru: 'одно из'
  },
  'logicRuleForm.testForm.union_type.only': {
    en: 'only',
    ru: 'только'
  },
  'logicRuleForm.testForm.operator.contains': {
    en: 'contains',
    ru: 'содержит'
  },
  'logicRuleForm.testForm.operator.not_contains': {
    en: 'not contains',
    ru: 'не содержит'
  },
  'logicRuleForm.testForm.operator.before': {
    en: 'before',
    ru: 'раньше'
  },
  'logicRuleForm.testForm.operator.beforeOrMatch': {
    en: 'before or match',
    ru: 'раньше или соответствует'
  },
  'logicRuleForm.testForm.operator.match': {
    en: 'match',
    ru: 'соответствует'
  },
  'logicRuleForm.testForm.operator.afterOrMatch': {
    en: 'after or match',
    ru: 'после или соответствует'
  },
  'logicRuleForm.testForm.operator.after': {
    en: 'after',
    ru: 'после'
  },
  'logicRuleForm.testForm.question.placeholder': {
    en: 'Choose question',
    ru: 'выберите вопрос'
  },
  'logicRuleForm.testForm.value.select.placeholder': {
    en: 'Choose options',
    ru: 'Выберите опции'
  },
  'logicRuleForm.testForm.value.input.placeholder': {
    en: 'Enter value',
    ru: 'Введите ответ'
  },
  'logicRuleForm.testForm.actions.confirmDelete': {
    en: 'Do you want to delete this check?',
    ru: 'Вы точно хотите удалить эту проверку?'
  },
  'logicRuleForm.no_defined_actions': {
    en: 'Condition has no defined actions',
    ru: 'В условии нет действий'
  },
  'logicRuleForm.no_defined_tests': {
    en: 'Condition has no defined tests',
    ru: 'В условии нет проверок'
  },
  'logicRuleForm.addAction': {
    en: 'Add action',
    ru: 'Добавить действие'
  },
  'logicRuleForm.addTest': {
    en: 'Add test',
    ru: 'Добавить проверку'
  },
  'logicRuleForm.actions.confirmDelete': {
    en: 'Do you want to remove that rule?',
    ru: 'Вы действительно хотите удалить это условие?'
  },

  // Validation
  'logicRuleForm.validation.fields.tests.isRequired': {
    en: 'Add at least one test',
    ru: 'Необходимо добавить минимум одно условие'
  }
};

export default revertDictionary(logicRuleFormDictionary);
