/* eslint-disable eqeqeq */
// @flow

import fp from 'lodash/fp';

import times from 'lodash/times';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import type { IProject } from 'flow-types/entities/Project';

import { DATE_FNS_FORMAT } from 'utils/config';

import { POST_POLLING_DELAY_TYPES } from 'common/helpers/project/constants';
import decamelizeKeys from '../../helpers/decamelizeKeys';

export const processProjectSettings = (project: IProject): IProject => {
  const next = { ...project };

  forEach(next.settings.browserTitles, (value, key) => {
    if (isEmpty(value)) {
      next.settings.browserTitles[key] = null;
    }
  });

  return next;
};

// processes postPollingDelayValue field
export const processPostPollingDelayField = (project: IProject): IProject => {
  const EXPECTED_LENGTH = 3;

  if (project.postPollingDelayType !== POST_POLLING_DELAY_TYPES.TIMER) {
    return project;
  }

  const next = { ...project };

  if (typeof next.postPollingDelayValue === 'string') {
    const splittedValue = next.postPollingDelayValue.split(':');

    // calculate shortage from splitted value
    const valueShortage = EXPECTED_LENGTH - splittedValue.length;

    if (valueShortage > 0) {
      // generate required amount of zeroes
      const generatedShortage = times(valueShortage, () => '00').join(':');

      // add them to a given value
      next.postPollingDelayValue = `${next.postPollingDelayValue}:${generatedShortage}`;
    }
  }

  return next;
};

// processes timeStart & timeEnd fields
export const processProjectTimeframes = (project: IProject): IProject => {
  const { timeEnd, timeStart, ...rest } = project;

  const nextTimeStart =
    // eslint-disable-next-line no-nested-ternary
    timeStart instanceof Date
      ? format(timeStart, DATE_FNS_FORMAT)
      : typeof timeStart === 'string'
      ? format(parseISO(timeStart), DATE_FNS_FORMAT)
      : null;

  const nextTimeEnd =
    // eslint-disable-next-line no-nested-ternary
    timeEnd instanceof Date
      ? format(timeEnd, DATE_FNS_FORMAT)
      : typeof timeEnd === 'string'
      ? format(parseISO(timeEnd), DATE_FNS_FORMAT)
      : null;

  return {
    ...rest,
    timeStart: nextTimeStart,
    timeEnd: nextTimeEnd
  };
};

// removes fields, that are updated independently from project data
const extractIndependentlyUpdatedFields = fp.omit(['groups']);

export const denormalizeProject = fp.compose(
  fp.omitBy(fp.isNil),
  extractIndependentlyUpdatedFields,
  processPostPollingDelayField,
  processProjectTimeframes
);

export const decamelizeAndDenormalizeProject = fp.compose(
  decamelizeKeys,
  denormalizeProject
);

export default fp.map(decamelizeAndDenormalizeProject);
