import type { AppState } from 'flow-types/AppState';
import { createSelector } from 'reselect';
import type { Pagination } from 'flow-types/Pagination';
import type { BuildingsPageState } from './reducer/state.flow';
import derivePaginationPropsFromPaginationState$ from '../../selectors/derivePaginationPropsFromPaginationState';

export const buildingsState$ = (state: AppState): BuildingsPageState =>
  state.buildings;

export const buildingsList$ = createSelector(
  buildingsState$,
  buildingsState => buildingsState.list.data
);

export const buildingsLoading$ = createSelector(
  buildingsState$,
  buildingsState => buildingsState.list.status === 'loading'
);

export const buildingsFilter$ = createSelector(
  buildingsState$,
  state => state.list.filter
);

export const buildingsPagination$ = createSelector(
  buildingsState$,
  (state): Pagination =>
    derivePaginationPropsFromPaginationState$(
      state.list.pagination,
      state.list.filter
    )
);

export const buildingsSearchFilter$ = createSelector(
  buildingsState$,
  state => state.list.filter.search ?? ''
);
