// @flow

import * as React from 'react';
import { FastField, Field as FormikField } from 'formik';

type FieldProps = {
  fast?: boolean
};

const Field = ({ fast, ...restProps }: FieldProps): React.Node => {
  if (fast) {
    return <FastField {...restProps} />;
  }
  return <FormikField {...restProps} />;
};

Field.defaultProps = {
  fast: false
};

export default Field;
