// @flow
import * as React from 'react';
import { useFormikContext } from 'formik';

import BaseForm from '../Form';

import type { FormProps } from '../Form/Form';

const Form = (props: FormProps): React.Node => {
  const formik = useFormikContext();

  return <BaseForm {...props} onSubmit={formik.handleSubmit} />;
};

export default Form;
