// @flow

import reduce from 'lodash/reduce';
import entries from 'lodash/entries';
import forEach from 'lodash/forEach';
import type { Reducer } from 'flow-types/Reducer';
import type {
  SubmitAnswers,
  SubmitAnswersFail,
  SubmitAnswersSuccess
} from 'flow-types/actions/interview/SubmitAnswers';
import deleteAwareMerge from 'common/helpers/deleteAwareMerge';
import keyedDataUpdater from '../../common/helpers/keyedDataUpdater';
import type { InterviewAction } from '../../flow-types/actions/interview';
import type { InterviewAnswersState } from '../../flow-types/states/InterviewState/InterviewAnswersState';

type ActionHandler<T> = Reducer<InterviewAnswersState, T>;

export const ANSWER_STATUS = {
  SUBMITTED: 'submitted',
  COMPLETED: 'completed', // the same as above, but with no requirement to submit action, cannot be updated
  UPDATED: 'updated',
  FAILED: 'failed',
  NEW: null
};

const initialState: InterviewAnswersState = {};

const submitAnswersHandler: ActionHandler<SubmitAnswers> = (
  state,
  { questionsIds, only }
) => {
  if (Array.isArray(only) && !only.includes('answers')) return state;

  const next = { ...state };

  forEach(questionsIds, questionId => {
    if (next[questionId]) {
      next[questionId] = {
        ...next[questionId],
        loading: true
      };
    } else {
      next[questionId] = {
        loading: true
      };
    }
  });

  return next;
};

const submitAnswersFailHandler: ActionHandler<SubmitAnswersFail> = (
  state,
  action
) => {
  if (Array.isArray(action.only) && !action.only.includes('answers')) {
    return state;
  }

  const next = { ...state };

  forEach(action.questionsIds, questionId => {
    next[questionId] = {
      ...next[questionId],
      loading: false,
      status: ANSWER_STATUS.FAILED,
      error: action.error
    };
  });

  return next;
};

const submitAnswersSuccessHandler: ActionHandler<SubmitAnswersSuccess> = (
  state,
  { questionsIds, only }
) => {
  if (Array.isArray(only) && !only.includes('answers')) return state;

  const next = { ...state };

  forEach(questionsIds, questionId => {
    next[questionId] = {
      ...next[questionId],
      status: ANSWER_STATUS.SUBMITTED,
      cache: null,
      loading: false,
      errors: null,
      error: null
    };
  });

  return next;
};

export default (
  state: InterviewAnswersState = initialState,
  action: InterviewAction
): InterviewAnswersState => {
  switch (action.type) {
    // if user has uploaded something relative to question,
    // then, reset validation for that question, as like after answer update
    case 'interview/add-upload':
      if (!action.upload.questionId) return state;
      return keyedDataUpdater(state, `${action.upload.questionId}`, answer => ({
        ...answer,
        errors: null
      }));

    case 'interview/set-answers-validation':
      return reduce(
        entries(action.validation),
        (_state, [questionId, errors]) =>
          keyedDataUpdater(_state, questionId, answer => ({
            ...answer,
            errors
          })),
        state
      );

    case 'interview/prepare-complete':
      return action.isNext ? action.answers : state;

    case 'interview/fetch-answers-success':
      return action.answers || initialState;

    case 'interview/reset':
      if (action.keepAnswers) return state;
      return initialState;

    case 'interview/update-answer-comments':
      return keyedDataUpdater(state, `${action.questionId}`, answer => {
        const shouldMakeCache =
          answer && answer.status === ANSWER_STATUS.SUBMITTED && !answer.cache;

        return {
          ...answer,
          comments: Array.isArray(action.comments)
            ? [...action.comments]
            : action.comments,
          ...(shouldMakeCache && {
            cache: answer
          }),
          status: ANSWER_STATUS.UPDATED
        };
      });

    case 'interview/update-answer':
      return keyedDataUpdater(state, `${action.questionId}`, answer => {
        const shouldMakeCache =
          answer && answer.status === ANSWER_STATUS.SUBMITTED && !answer.cache;

        /**
         * If simple value is passed,
         * then it will to be assigned directly to answer.data
         */
        if (
          ['string', 'number', 'boolean'].includes(
            typeof action.answerUpdate
          ) ||
          action.answerUpdate === null ||
          Array.isArray(action.answerUpdate)
        ) {
          return {
            ...answer,
            error: null,
            errors: null,
            data: action.answerUpdate,
            ...(shouldMakeCache && {
              cache: answer
            }),
            status: ANSWER_STATUS.UPDATED
          };
        }

        if (action.isUsingMerge) {
          return deleteAwareMerge(
            {
              ...answer,
              ...(shouldMakeCache && {
                cache: { ...answer }
              }),
              status: ANSWER_STATUS.UPDATED
            },
            action.answerUpdate
          );
        }

        return {
          ...answer,
          ...action.answerUpdate,
          ...(shouldMakeCache && {
            cache: answer
          }),
          status: ANSWER_STATUS.UPDATED
        };
      });

    case 'interview/submit-answers':
      return submitAnswersHandler(state, action);

    case 'interview/submit-answers-fail':
      return submitAnswersFailHandler(state, action);

    case 'interview/submit-answers-success':
      return submitAnswersSuccessHandler(state, action);

    default:
      return state;
  }
};
