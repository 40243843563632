import map from 'lodash/map';

// Приводит значение в нормализованный вид: разнородный массив к однородному.
// Если передан параметр onlyValue === true и multiple === true,
// то значение, которое мы отправляем при изменении будет массивом содержащим
// только значения полей [valueKey], выбранных опций
//
// Если передан параметр onlyValue === true и multiple === false,
// то значение, которое мы отправляем при изменении будет
// значением поля [valueKey] выбранной опции
//
// Если передан параметр onlyValue === false и multiple === true,
// то значение, которое мы отправляем при изменении будет массивом содержащим
// объекты выбранных опций
//
// Если передан параметр onlyValue === false и multiple === false,
// то значение, которое мы отправляем при изменении будет
// объектом выбранной опции
export default function valueDenormalizer(
  value = null,
  options,
  onlyValue,
  valueKey,
  labelKey,
  multiple
) {
  if (!value) return value;

  if (multiple) {
    return map(value, currentValue => {
      if (!currentValue) return false;

      if (onlyValue && typeof currentValue === 'object') {
        return currentValue[valueKey];
      }

      if (!onlyValue && typeof currentValue !== 'object') {
        let optionForValue = options.find(
          option => option[valueKey] === currentValue
        );

        if (!optionForValue) {
          optionForValue = { [labelKey]: value, [valueKey]: value };
        }

        return optionForValue;
      }

      return currentValue;
    }).filter(Boolean);
  }

  if (onlyValue && typeof value === 'object') {
    return value[valueKey];
  }

  if (!onlyValue && typeof value !== 'object') {
    const optionForValue = options.find(option => option[valueKey] === value);
    if (!optionForValue) return { [valueKey]: value, [labelKey]: value };
    return optionForValue;
  }

  return value;
}
