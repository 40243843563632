// @flow
import size from 'lodash/size';
import fp from 'lodash/fp';
import values from 'lodash/values';
import { camelizeKeys } from 'humps';

import type {
  ILogic,
  ILogicAction,
  ILogicActionTarget
} from 'flow-types/entities/RawLogic';

import { flatMapTestGroup } from '../logic/flatMapTestGroup';

const conditionTestGroupsNormalizer = fp.map(flatMapTestGroup);

const logicActionTargetsNormalizer = fp.reduce(
  (result, target: ILogicActionTarget) => {
    const { instanceTable, ids } = target;

    if (instanceTable === 'question_groups') {
      return {
        ...result,
        groupsIds: values(ids)
      };
    }
    if (instanceTable === 'question_options') {
      return {
        ...result,
        optionsIds: values(ids)
      };
    }
    if (instanceTable === 'questions') {
      return {
        ...result,
        questionsIds: values(ids)
      };
    }

    return result;
  },
  {}
);

const logicActionNormalizer = action => {
  const { targets, ...safeData }: ILogicAction = action;

  return {
    targets: logicActionTargetsNormalizer(targets),
    ...safeData
  };
};

const logicActionsNormalizer = fp.map(logicActionNormalizer);

const logicNormalizer = fp.compose(condition => {
  const { testGroups, actions, ...safeData }: ILogic = condition;

  const tests = conditionTestGroupsNormalizer(testGroups);

  return {
    ...safeData,
    actions: logicActionsNormalizer(actions),
    // in case we passed ev everything inside a single root group
    tests: size(tests) === 1 ? tests[0] : tests
  };
}, camelizeKeys);

export const logicRulesNormalizer = fp.compose(
  fp.map(logicNormalizer),
  fp.orderBy(['priority', 'id'], ['asc', 'asc'])
);

export default logicNormalizer;
