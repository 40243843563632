// @flow

export const LOGIC_OPERAND = Object.freeze({
  AND: 'and',
  OR: 'or'
});

export type $LogicOperand = $Values<typeof LOGIC_OPERAND>;

export const LOGIC_OPERATOR = Object.freeze({
  IN: 'in',
  NOT_IN: 'not_in',
  LESS_THAN: 'lt',
  LESS_THAN_OR_EQUAL: 'lte',
  GREATER_THAN_OR_EQUAL: 'gte',
  GREATER_THAN: 'gt',
  EQUAL: 'eq',
  EMPTY: 'empty',
  NOT_EMPTY: 'not_empty'
});

export type LogicOperatorObjMap = typeof LOGIC_OPERATOR;

export const LOGIC_ACTION = Object.freeze({
  GOTO: 'goto',
  HIDE: 'hide',
  SHOW: 'show',
  REQUIRE: 'required'
});

export type $LogicAction = typeof LOGIC_ACTION;

export const LOGIC_PRIORITY = Object.freeze({
  VERY_LOW: 1,
  LOW: 2,
  MEDIUM: 3,
  HIGH: 4,
  VERY_HIGH: 5,
  SYSTEM: 999999
});

export type $LogicPriority = typeof LOGIC_PRIORITY;

type $GotoModes = {
  FORWARD: 'forward',
  BOTH: 'both'
};

export const GOTO_MODES: $Exact<$GotoModes> = Object.freeze({
  FORWARD: 'forward',
  BOTH: 'both'
});
