// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'common/components/animated/Modal';
import LoginForm from 'common/containers/LoginForm/index';
import useModal from 'common/hooks/useModal';

export const LOGIN_FORM_DIALOG = 'loginFormDialog';

const ModalLoginForm = (): React.Node => {
  const dispatch = useDispatch();

  const [state, , close] = useModal(LOGIN_FORM_DIALOG);

  const handleSingedIn = React.useCallback(
    (user, accessToken, rememberMe) => {
      dispatch({
        type: 'auth/sign-in-success',
        user,
        accessToken,
        rememberMe,
        scope: '*',
        initialLoggedInState: false
      });

      close();
    },
    [close, dispatch]
  );

  return (
    <Modal visible={state.visible}>
      <Modal.Content>
        <LoginForm onSuccess={handleSingedIn} allowPasswordReset={false} />
      </Modal.Content>
    </Modal>
  );
};

export default ModalLoginForm;
