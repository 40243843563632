import React, { useCallback, useMemo } from 'react';
import fp from 'lodash/fp';
import usersNormalizer from '../../../../../common/transducers/users/normalizer';
import { AsyncDropdown } from '../../../../../common/components/Dropdown';
import request from '../../../../../utils/request';
import { API } from '../../../../../utils/config';

type Props = {|
  value: number | Array<number>,
  valueKey?: string,
  labelKey?: string
|};

const createExtractor = (valueKey: string, labelKey: string) =>
  fp.compose(
    fp.map(manager => ({
      [valueKey || 'value']: manager.id,
      [labelKey || 'label']: manager.name
    })),
    usersNormalizer,
    fp.getOr([], 'response.data')
  );

const ResponseReviewStagesSelector = ({
  value,
  valueKey,
  labelKey,
  ...rest
}: Props) => {
  const extractor = useMemo(() => createExtractor(valueKey, labelKey), [
    labelKey,
    valueKey
  ]);

  const fetcher = useCallback(
    (filter, search) =>
      request({
        url: API.responseReviewStages,
        query: {
          search
        }
      }).toPromise(),
    []
  );

  return (
    <AsyncDropdown
      searchable
      fetchOnFilterUpdate
      {...rest}
      valueKey={valueKey}
      labelKey={labelKey}
      value={value}
      fetcher={fetcher}
      dataExtractor={extractor}
    />
  );
};

ResponseReviewStagesSelector.defaultProps = {
  labelKey: 'label',
  valueKey: 'value'
};

export default ResponseReviewStagesSelector;
