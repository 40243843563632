import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import fp from 'lodash/fp';
import { EXPERT, MANAGER, OPERATOR } from 'common/helpers/user';
import type { NilValue } from 'flow-types/NilValue';
import usersNormalizer from '../../../../../common/transducers/users/normalizer';
import { AsyncDropdown } from '../../../../../common/components/Dropdown';
import request from '../../../../../utils/request';
import { API } from '../../../../../utils/config';
import { projectStateSelector } from '../../../../../selectors/projects';

type Props = {|
  value: NilValue | number | Array<number>,
  valueKey?: string,
  labelKey?: string
|};

const createExtractor = (valueKey: string, labelKey: string) =>
  fp.compose(
    fp.map(manager => ({
      [valueKey || 'value']: manager.id,
      [labelKey || 'label']: manager.name
    })),
    usersNormalizer,
    fp.getOr([], 'response.data')
  );

const ProjectManagersSelector = ({
  value,
  valueKey,
  labelKey,
  ...rest
}: Props) => {
  const { cache: project } = useSelector(projectStateSelector);

  const extractor = useMemo(() => createExtractor(valueKey, labelKey), [
    labelKey,
    valueKey
  ]);

  const fetcher = useCallback((filter, search) => {
    if (!filter.projectId) {
      return Promise.resolve(null);
    }
    return request({
      url: API.users.list,
      query: {
        search,
        company_id: filter.companyId,
        role_id: filter.roleId
      }
    }).toPromise();
  }, []);

  const filter = useMemo(
    () => ({
      roleId: [MANAGER, OPERATOR, EXPERT],
      companyId: project ? project.companyId : null,
      projectId: project ? project.id : null
    }),
    [project]
  );

  return (
    <AsyncDropdown
      searchable
      fetchOnFilterUpdate
      {...rest}
      valueKey={valueKey}
      labelKey={labelKey}
      value={value}
      fetcher={fetcher}
      dataExtractor={extractor}
      filter={filter}
    />
  );
};

ProjectManagersSelector.defaultProps = {
  labelKey: 'label',
  valueKey: 'value'
};

export default ProjectManagersSelector;
