// @flow

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import * as RxOperators from 'rxjs/operators';
import { EMPTY, merge, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { toast } from 'react-toastify';

import request from 'utils/request';
import { API } from 'utils/config';

import { logicRulesNormalizer } from 'common/transducers/interview/logicNormalizer';
import { getTargetMode } from 'common/helpers/interview/getTargetMode';

import type { ActionsObservable, Epic, StateObservable } from 'flow-types/Epic';
import type { AppState } from 'flow-types/AppState';
import type { AuthState } from 'flow-types/states/AuthState';
import type { IInterviewProject } from 'flow-types/entities/Project';
import type { IInterviewRecord } from 'flow-types/entities/InterviewRecord';
import type { LocationState } from 'redux-first-router/dist/flow-types';
import type { PrepareInterview } from 'flow-types/actions/interview/PrepareInterview';
import type { InterviewMode } from 'flow-types/states/InterviewState/InterviewRecordState';

import { isOutdated, isPreparing } from 'common/helpers/project/getStatus';
import { TYPES } from 'common/helpers/question';
import constructProjectStructure from '../../common/helpers/interview/constructProjectStructure';
import responseNormalizer from '../../common/transducers/interview/responseNormalizer';
import responseParser from '../../common/epicHelpers/responseParser';
import interviewProjectNormalizer from '../../common/transducers/interview/projectNormalizer';
import interpolateString from '../../common/helpers/interpolateString';
import ajaxErrorsDictionary from '../../intl/ajaxErrorsDictionary';
import decamelizeKeys from '../../common/helpers/decamelizeKeys';
import {
  authStateSelector,
  languageStateSelector,
  locationStateSelector,
  pageStateSelector
} from '../../selectors';
import openModal from '../../actions/modals/open';
import { PAGE_CRASHED_DIALOG_MODAL_ID } from '../../pages/Interview/components/Dialogs/PageCrashedDialog';
import { getInterviewPageAppearance } from '../../selectors/interview/statuses';

import logicReducer, {
  initialState as logicReducerInitialState
} from '../../reducers/interview/logic';

const selector: Function = createSelector(
  locationStateSelector,
  authStateSelector,
  (location: LocationState, authState: AuthState) => {
    const { query, payload }: LocationState = location;

    let restorationResponseId = null;

    const { meta } = authState;

    // $FlowFixMe
    if (!!meta && !!meta.responseId) {
      restorationResponseId = meta.responseId;
    }

    if (query && query.rid) {
      restorationResponseId = query.rid;
    }

    return {
      projectId: payload?.interviewId || null,
      restorationResponseId
    };
  }
);

type GetProjectIdFn = ({
  projectId: number,
  meta: Object
}) => null | number;

const getProjectId: GetProjectIdFn = ({ projectId, meta }) => {
  if (projectId) return projectId;

  if (meta && meta.project) {
    return meta.project.id;
  }

  return null;
};

// TODO: use it
// eslint-disable-next-line no-unused-vars
const failCaseWatcher: Epic = action$ =>
  action$.pipe(
    ofType(
      'interview/fetch-project-fail',
      'interview/create-interview-record-fail'
    ),
    RxOperators.mergeMap(() => [
      openModal(PAGE_CRASHED_DIALOG_MODAL_ID, null),
      {
        type: 'interview/prepare-fail',
        error: 'Ooops!'
      }
    ]),
    RxOperators.takeUntil(action$.pipe(ofType('interview/prepare-complete')))
  );

/**
 * There will be a lot of reference mutations, however,
 * they're safe as soon as this data is not in state yet.
 */
const injectProjectWithRecordPollToSend = (
  project: IInterviewProject,
  pollToSend: $PropertyType<IInterviewRecord, 'pollToSend'>
) => {
  const config = pollToSend?.config;

  if (!config) return project;

  const next = { ...project };

  if (config.greeting) {
    if (next.groups[0].questions[0].postPollingDefault) {
      next.groups[0].questions[0].title = config.greeting.title;
      next.groups[0].questions[0].description = config.greeting.content;
    }
  }

  if (config.ending) {
    const lastGroupIndex = next.groups.length - 1;

    const lastGroup = next.groups[lastGroupIndex];

    if (lastGroup.questions.length === 0) return project;

    const lastQuestion = lastGroup.questions[lastGroup.questions.length - 1];

    if (!lastQuestion.postPollingDefault) return project;

    lastQuestion.type = config.ending.type;
    lastQuestion.title = config.ending.title || '';

    if (lastQuestion.type === TYPES.Redirect) {
      lastQuestion.description = config.ending.url;
    } else {
      lastQuestion.description = config.ending.content;
    }
  }

  return next;
};

function preprocessLogic(isLogicDriven, rules, answers) {
  return isLogicDriven
    ? logicReducer(
        { data: rules },
        { type: 'interview/process-logic-results', answers }
      ).lastComputedResults
    : logicReducerInitialState.lastComputedResults;
}

// region RestoreSession
// special function, that handles preparations for any kind of session restoration workflow,
// i.e. post-polling, public link, session edit, session continuation
function restoreSession(
  action$: ActionsObservable,
  state$: StateObservable,
  { restorationResponseId, language } = {}
) {
  return request({
    url: interpolateString(
      API.responses.load,
      decamelizeKeys({ responseId: restorationResponseId })
    ),
    method: 'GET'
  }).pipe(
    responseParser,
    RxOperators.withLatestFrom(state$),
    RxOperators.mergeMap(([response, state]) => {
      const { data: interviewRecord } = response;

      const location = locationStateSelector(state);
      const page = pageStateSelector(state);

      const normalizedRecord: IInterviewRecord = responseNormalizer(
        interviewRecord
      );

      const {
        registry,
        answers,
        project: rawProject,
        variableValues
      } = normalizedRecord;

      let project: IInterviewProject = interviewProjectNormalizer(rawProject);

      if (normalizedRecord.pollToSend) {
        // TODO: resolve later
        // $FlowIgnore
        project = injectProjectWithRecordPollToSend(
          project,
          normalizedRecord.pollToSend
        );
      }

      const isLogicDriven =
        Array.isArray(project.rules) && project.rules.length > 0;

      const logic = isLogicDriven
        ? logicRulesNormalizer([...project.rules])
        : [];

      const initialLogicResults = preprocessLogic(
        isLogicDriven,
        logic,
        answers
      );

      const appearance = getInterviewPageAppearance(
        normalizedRecord,
        location,
        page
      );

      const mode: InterviewMode = getTargetMode({
        project,
        response: normalizedRecord,
        appearance,
        location
      });

      const structure = constructProjectStructure({
        // TODO: resolve later
        // $FlowIgnore
        mode,
        registry,
        // TODO: resolve later
        // $FlowIgnore
        appearance,
        project
      });

      return merge(
        action$.pipe(
          ofType('interview/preload-assets-complete'),
          RxOperators.map(() => ({
            type: 'interview/prepare-complete',
            isNext: true,
            project,
            mode,
            variables: variableValues,
            answers: answers ?? {},
            registry: registry ?? {},
            record: normalizedRecord,
            initialLogicResults,
            // TODO: просчитать
            initialStackId: false,
            logic,
            structure
          }))
        ),
        [
          {
            type: 'interview/preload-assets',
            structure
          }
        ]
      );
    }),
    RxOperators.catchError(({ status, response, message }: AjaxError) => {
      let ajaxErrorMessage =
        ajaxErrorsDictionary[language][`ajax.error.${status}`];

      if (!ajaxErrorMessage) {
        ajaxErrorMessage = ajaxErrorsDictionary[language]['ajax.error.NA'];
      }

      toast.error(ajaxErrorMessage, { autoClose: 2500 });

      return [
        {
          type: 'interview/prepare-fail',
          error: response ? response.data : message
        }
      ];
    })
  );
}

// endregion

// epic that carries out interview preparations
const prepareInterviewEpic: Epic = (
  action$: ActionsObservable,
  state$: StateObservable
) =>
  action$.pipe(
    ofType('interview/prepare'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(
      ([{ forceAppearance }, state]: [PrepareInterview, AppState]) => {
        const location = locationStateSelector(state);
        const page = pageStateSelector(state);

        const { projectId, restorationResponseId } = selector(state);

        const language = languageStateSelector(state);

        const { meta } = authStateSelector(state);

        // This case should only be applied for post-polling and session restoration,
        // i.e. continuation of existing, but not completed, session
        // The main reason for that decision is that when we get logged in with public hash,
        // we should not make a request for project.
        // P.S. when project isPreparing(projectStatus) === true,
        // there will be no restorationResponseId,
        // thus post-polling will be set up via default workflow
        if (restorationResponseId) {
          return restoreSession(action$, state$, {
            restorationResponseId,
            language
          });
        }

        const targetProjectId = getProjectId({
          projectId,
          meta
        });

        if (!targetProjectId) return EMPTY;

        // В мету запись попадает только в том случае, если происходит опрос по публичной ссылке,
        // так как там принцип как раз в том, чтобы залогиниться с помощью ключа
        if (meta && meta.project && meta.project.id) {
          const record = responseNormalizer({ ...meta });

          const {
            registry,
            answers,
            project: rawProject,
            variableValues
          } = record;

          const project: IInterviewProject = interviewProjectNormalizer(
            rawProject
          );

          const isLogicDriven =
            Array.isArray(project.rules) && project.rules.length > 0;

          const logic = isLogicDriven
            ? logicRulesNormalizer([...project.rules])
            : [];

          const initialLogicResults = preprocessLogic(
            isLogicDriven,
            logic,
            answers
          );

          const appearance = getInterviewPageAppearance(
            record,
            location,
            page,
            forceAppearance
          );

          const mode: InterviewMode = getTargetMode({
            project,
            response: record,
            appearance,
            location
          });

          const structure = constructProjectStructure({
            // TODO: resolve later
            // $FlowIgnore
            mode,
            registry,
            // TODO: resolve later
            // $FlowIgnore
            appearance,
            project
          });

          return merge(
            action$.pipe(
              ofType('interview/preload-assets-complete'),
              RxOperators.map(() => ({
                type: 'interview/prepare-complete',
                isNext: true,
                project,
                mode,
                variables: variableValues,
                answers: answers ?? {},
                registry: registry ?? {},
                record,
                initialLogicResults,
                // TODO: просчитать
                initialStackId: false,
                logic,
                structure
              }))
            ),
            [
              {
                type: 'interview/preload-assets',
                structure
              }
            ]
          );
        }

        return request({
          url: interpolateString(API.projects.detailInterview, {
            projectId: targetProjectId
          })
        }).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.switchMap(rawRecord => {
            // Despite of project being still `raw`, answers are normalized
            const artificialRecord = responseNormalizer(rawRecord);

            const { project: rawProject, answers } = artificialRecord;

            const project = interviewProjectNormalizer(rawProject);

            const isLogicDriven =
              Array.isArray(project.rules) && project.rules.length > 0;

            const logic = isLogicDriven
              ? logicRulesNormalizer([...project.rules])
              : [];

            // Рассчитываемые переменные пока не предрасчитывал здесь,
            // но нужно будет, если `логика` будет с ними работать.
            const initialLogicResults = preprocessLogic(
              isLogicDriven,
              logic,
              answers
            );

            const shouldCreateRecordLocally =
              isPreparing(project.status) || isOutdated(project.status);

            return merge(
              action$.pipe(
                ofType('interview/create-interview-record-success'),
                RxOperators.take(1),
                RxOperators.switchMap(({ interview: record }) => {
                  const appearance = getInterviewPageAppearance(
                    record,
                    location,
                    page,
                    forceAppearance
                  );

                  const mode: InterviewMode = getTargetMode({
                    project,
                    response: record,
                    appearance,
                    location
                  });

                  const structure = constructProjectStructure({
                    // TODO: resolve later
                    // $FlowIgnore
                    mode,
                    registry: [],
                    // TODO: resolve later
                    // $FlowIgnore
                    appearance,
                    project
                  });

                  return merge(
                    action$.pipe(
                      ofType('interview/preload-assets-complete'),
                      RxOperators.map(() => ({
                        type: 'interview/prepare-complete',
                        isNext: true,
                        project,
                        mode,
                        variables: {},
                        answers: answers ?? {},
                        registry: [],
                        record,
                        initialLogicResults,
                        // TODO: просчитать
                        initialStackId: false,
                        logic,
                        structure
                      }))
                    ),
                    [
                      {
                        type: 'interview/preload-assets',
                        structure
                      }
                    ]
                  );
                })
              ),
              of({
                type: 'interview/create-interview-record',
                projectId,
                local: shouldCreateRecordLocally
              })
            );
          })
        );
      }
    )
  );

export default prepareInterviewEpic;
