// @flow

import * as React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import size from 'lodash/size';
import map from 'lodash/map';
import minFrom from 'lodash/min';
import maxFrom from 'lodash/max';
import toNumber from 'lodash/toNumber';
import { getTrackBackground, Range } from 'react-range';
import { useSpring, animated, config } from 'react-spring';
import Flexbox from 'common/components/Flexbox';
import randomColor from 'common/helpers/randomColor';
import useMeasure from 'react-use-measure';
import getMarkBackground from 'common/components/Range/utils/getMarkBackground';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import reduceRight from 'lodash/reduceRight';
import tc2 from 'tinycolor2';
import type { LabelsSettings } from 'flow-types/entities/Question';

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

const COLORS = {
  IN_RANGE: '#548bf4',
  // IN_RANGE: '#ff00d5',
  OUT_OF_RANGE: '#CCC'
};

export const StyledMark: React.ComponentType<mixed> = styled.div`
  height: 1rem;
  width: 0.25rem;
`;

export const StyledThumbLabel: React.ComponentType<mixed> = styled(
  animated.div
)`
  position: absolute;
  top: calc(-100% - 1rem);
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.25rem;
  min-width: 3rem;
  text-align: center;
  border-radius: 0.25rem;
  background-color: #548bf4;
`;

export const StyledThumbLine: React.ComponentType<mixed> = styled(animated.div)`
  height: 1rem;
  width: 0.3rem;
`;

export const StyledThumb: React.ComponentType<mixed> = styled(Flexbox)`
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 50%;
  background-color: #fff;
  will-change: box-shadow;
  outline: none;
`;

export const StyledTrack: React.ComponentType<mixed> = styled(Flexbox)`
  height: 0.75rem;
  width: 100%;
  border-radius: 0.25rem;
`;

export const StyledTrackContainer: React.ComponentType<mixed> = styled(Flexbox)`
  height: 2.25rem;
  width: 100%;
`;

type GetColorArgs = {
  marks?: null | LabelsSettings,
  value?: number | null,
  defaultColor?: null | string,
  virtualValue?: number | null,
  withRelation?: number | null,
  darken?: null | number
};

const getColor = ({
  marks = null,
  value = null,
  defaultColor = null,
  virtualValue = null,
  withRelation = null,
  darken = null
}: GetColorArgs = {}) => {
  if (virtualValue !== null && withRelation !== null) {
    if (withRelation <= virtualValue) {
      return getColor({
        marks,
        defaultColor,
        value: virtualValue,
        darken: 10
      });
    }
  }

  if (isNil(value)) return null;

  if (withRelation !== null) {
    // non-null check is above
    // $FlowIgnore
    if (withRelation <= value) {
      return getColor({
        marks,
        value,
        defaultColor: null
      });
    }

    return null;
  }

  if (!marks) return defaultColor;

  const suitableMarksList = filter(marks, mark => mark.value <= value);

  const color = reduceRight(
    suitableMarksList,
    (result, mark) => {
      if (result) return result;

      return mark.color;
    },
    null
  );

  let result = color || defaultColor;

  if (result !== null) {
    if (darken) {
      result = tc2(result)
        .darken(darken)
        .toString();
    }
  }

  return result;
};

type ThumbProps = {
  isDragging: boolean,
  label: string,
  style: Object,
  labelBackground: null | string
};

const Thumb: React.AbstractComponent<ThumbProps> = React.forwardRef(
  (
    { style, isDragging, label, labelBackground, ...props }: ThumbProps,
    ref
  ): Node => {
    const [labelRef, { height: labelHeight }] = useMeasure();

    const thumbSpring = useSpring({
      boxShadow: isDragging
        ? '0px 0px 6px rgb(84, 139, 244)'
        : '0px 2px 6px #AAA',
      config: config.gentle
    });

    const { top, backgroundColor } = useSpring({
      top: -(labelHeight + 4),
      backgroundColor: isDragging ? '#548BF4' : '#CCC',
      config: config.gentle
    });

    return (
      <StyledThumb
        {...props}
        style={{ ...thumbSpring, ...style }}
        as={animated.div}
        ref={ref}
        justifyContent="center"
        $alignItems="center"
      >
        <StyledThumbLabel
          style={{ top, background: labelBackground }}
          ref={
            // $FlowIgnore
            labelRef
          }
        >
          {label}
        </StyledThumbLabel>
        <StyledThumbLine style={{ backgroundColor }} />
      </StyledThumb>
    );
  }
);

Thumb.displayName = 'Thumb.ForwardRef';

type TrackProps = {
  background: string,
  children: React.Node
};

const Track: React.AbstractComponent<TrackProps> = React.forwardRef(
  ({ background, children, ...props }: TrackProps, ref): Node => (
    <StyledTrackContainer {...props}>
      <StyledTrack
        ref={ref}
        as={animated.div}
        ignoreDisplay
        alignSelf="center"
        style={{
          background
        }}
      >
        {children}
      </StyledTrack>
    </StyledTrackContainer>
  )
);

Track.displayName = 'Track.ForwardRef';

type MarkProps = {
  background: string,
  style?: Object
};

const Mark: React.AbstractComponent<MarkProps> = React.forwardRef(
  ({ background, style, ...markProps }: MarkProps, ref) => (
    <StyledMark
      {...markProps}
      ref={ref}
      style={{
        ...style,
        background
      }}
    />
  )
);

Mark.displayName = 'Mark.ForwardRef';

// $FlowIgnore
Mark.defaultProps = {
  style: null
};

type SliderProps = {
  values: number[],
  disabled: boolean,
  onChange: Function,
  onFinalChange?: Function,
  rtl?: boolean,
  direction?: 'to right' | 'to left' | 'to bottom' | 'to top',
  allowOverlap?: boolean,
  draggableTrack?: boolean,
  min: null | number,
  max: null | number,
  step: number,
  labels?: null | {
    [string]: {
      value: null | string,
      title: null | string,
      color: null | string
    }
  },
  showMarkers?: boolean,
  dynamicColors?: boolean
};

const calcMinMax = (values, min, max) => {
  const parsedValues = map(values, v => toNumber(v));

  if (Array.isArray(parsedValues)) {
    const minValue = minFrom(parsedValues);
    const maxValue = maxFrom(parsedValues);

    return [minValue <= min ? minValue : min, maxValue >= max ? maxValue : max];
  }

  return [min, max];
};

const Slider = ({
  showMarkers,
  labels,
  values,
  min: outerMin,
  max: outerMax,
  step,
  dynamicColors,
  ...props
}: SliderProps): Node => {
  const [[min, max], set] = React.useState(() =>
    calcMinMax(values, +outerMin, +outerMax)
  );

  React.useEffect(() => {
    if (outerMin !== min || outerMax !== max) {
      set(() => calcMinMax(values, +outerMin, +outerMax));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, outerMin, outerMax]);

  const isSingle = size(values) <= 1;

  const colors = React.useMemo(() => {
    const total = (values?.length || 1) + 1;

    return range(total).map(pos => {
      if (pos === 0 || pos + 1 === total) {
        return COLORS.OUT_OF_RANGE;
      }

      if (dynamicColors) {
        return randomColor(1);
      }

      return COLORS.IN_RANGE;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size(values)]);

  return (
    <Range
      {...props}
      values={values}
      min={min}
      max={max}
      step={step}
      renderMark={
        showMarkers
          ? ({ props: markProps, index }) => (
              <Mark
                {...markProps}
                ref={markProps.ref}
                background={getMarkBackground({
                  step: +step,
                  index,
                  values,
                  min,
                  max,
                  activeColor: '#FFF',
                  inactiveColor: '#000'
                })}
              />
            )
          : null
      }
      renderTrack={({ props: trackProps, children }) => (
        <Track
          ref={trackProps.ref}
          style={trackProps.style}
          onMouseDown={trackProps.onMouseDown}
          onTouchStart={trackProps.onTouchStart}
          background={getTrackBackground({
            values,
            colors,
            min,
            max
          })}
        >
          {children}
        </Track>
      )}
      renderThumb={({ props: thumbProps, isDragged, value: thumbValue }) => {
        const labelBackground =
          isSingle &&
          getColor({
            // TODO: fix FT
            // $FlowFixMe
            marks: labels,
            defaultColor: null,
            value: thumbValue
          });

        return (
          <Thumb
            {...thumbProps}
            isDragging={isDragged}
            labelBackground={labelBackground}
            label={
              labels?.[thumbValue]?.title ||
              (+thumbValue || 0).toFixed(Number.isInteger(+step) ? 0 : 1)
            }
          />
        );
      }}
    />
  );
};

Slider.defaultProps = {
  values: [0, 0],
  step: STEP,
  min: MIN,
  max: MAX,
  labels: null,
  dynamicColors: false,
  rtl: false,
  direction: 'to right',
  allowOverlap: false,
  draggableTrack: false,
  showMarkers: false,
  onFinalChange: null
};

export default Slider;
