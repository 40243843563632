import fp from 'lodash/fp';
import type { IRawRegion } from 'flow-types/entities/RawRecordRegion';
import type { IRegionRate } from 'flow-types/entities/RecordRegion';
import decamelizeKeys from '../../helpers/decamelizeKeys';

const checklistItemsDenormalizer = fp.map((item: IRegionRate) => {
  const { checklistItemId, checklistItemOptionId, ratingComment } = item;
  return {
    checklistItemId,
    checklistItemOptionId,
    ratingComment
  };
});

const regionChecklistItemsDenormalizer = region => ({
  ...region,
  checklistItems: checklistItemsDenormalizer(region.checklistItems)
});

export const regionDenormalizer = fp.compose(
  decamelizeKeys,
  regionChecklistItemsDenormalizer,
  (region: Region): IRawRegion => {
    const { checklistItems, recordId, color, end, start, id, isNew } = region;

    return {
      ...(!isNew && {
        id
      }),
      uploadId: recordId,
      color,
      end: (typeof end !== 'undefined' && end) || -1,
      start: (typeof start !== 'undefined' && start) || -1,
      checklistItems
    };
  }
);

export default fp.map(regionDenormalizer);
