import find from 'lodash/find';
import difference from 'lodash/difference';

export const getItemsFrom = (list = [], index = 0) => list.slice(index + 1);

export const getItemsBefore = (list = [], index = 1) => list.slice(0, index);

export const findWithDefault = (list, iterator, defaultValue = null) => {
  const founded = find(list, iterator);

  if (!founded || typeof founded === 'undefined' || founded === -1) {
    return defaultValue;
  }

  return founded;
};

/**
 * This function helps to understand, which items were removed from the first list
 * and were added in the second.
 * TODO: support complex arrays (i.e. with objects inside)
 */
export default function getChangesBetween(
  source,
  target
): { removed: mixed[], added: mixed[] } {
  const removed = difference(source, target);
  const added = difference(target, source);

  return {
    removed,
    added
  };
}
