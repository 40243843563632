// @flow

import { reducerAwaredUpdateFilter } from 'common/helpers/updateFilter';
import type { BuildingsPageListState } from './state.flow';

const initialState: BuildingsPageListState = {
  status: 'idle',
  error: null,
  data: null,
  pagination: {
    activePage: 1,
    totalPages: 1,
    totalElements: 0
  },
  filter: {
    ps: 10,
    pn: 0,
    sort: '-id'
  }
};

export default function buildingsList(
  state: BuildingsPageListState = initialState,
  // TODO: resolve later
  action: Object
): BuildingsPageListState {
  switch (action.type) {
    case 'buildings/fetch':
      return {
        status: 'loading',
        data: state.data,
        filter: reducerAwaredUpdateFilter(state, action),
        pagination: state.pagination,
        error: null
      };

    case 'buildings/fetch-success':
      return {
        status: 'idle',
        data: action.data,
        filter: state.filter,
        pagination: action.pagination,
        error: null
      };

    case 'buildings/fetch-fail':
      return {
        status: 'error',
        error: action.error,
        filter: state.filter,
        pagination: action.pagination,
        data: state.data
      };

    default:
      return state;
  }
}
