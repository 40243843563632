import { EMPTY, interval, merge, of } from 'rxjs';
import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import some from 'lodash/some';

import { INTERVIEW_MOVE_EPIC_REQUIRED_DELAY } from 'common/helpers/interview/constants';

import type { Epic } from 'flow-types/Epic';
import type { Move, MoveFail } from 'flow-types/actions/interview/Move';
import type { AppState } from 'flow-types/AppState';

import localizeMessage from 'common/helpers/localizeMessage';
import {
  getStackById,
  getStackByQuestionId
} from 'common/helpers/interview/getStack';
import type { AddRegistryRecord } from 'flow-types/actions/interview/AddRegistryRecord';
import format from 'date-fns/format';
import { DATE_FNS_FORMAT } from 'utils/config';
import isNil from 'lodash/isNil';
import { logicRunner } from 'common/transducers/logic/logicRunner';
import { logicRulesResultsAnalyzer } from 'common/transducers/logic/logicResultsAnalyzer';
import type { SetLogicResults } from 'flow-types/actions/interview/SetLogicResults';
import type { LogicJump } from 'flow-types/entities/Logic';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { ILogicResults } from 'flow-types/entities/LogicResults';
import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import reduce from 'lodash/reduce';
import entries from 'lodash/entries';
import find from 'lodash/find';
import {
  languageStateSelector,
  sideProcessesSelector
} from '../../../selectors';
import { interviewStatusesSelector } from '../../../selectors/interview/statuses';
import checkRunner from './helpers/checkRunner';
import {
  interviewAnswersSelector,
  interviewStructureSelector
} from '../../../selectors/interview/root';
import {
  interviewActiveStackDataSelector,
  interviewLogicAwaredStructureSelector,
  logicAwaredStructureComposer
} from '../../../selectors/interview/answers';
import { getTargetStackId } from './helpers/getTargetStackId';
import { getQuestionsIdsForStackTransition } from './helpers/getQuestionIdsFromStackTransition';
import changeActiveStack from '../../../actions/interview/changeActiveStack';
import completeInterview from '../../../actions/interview/completeInterview';
import { interviewLogicRulesSelector } from '../../../selectors/interview/logic';
import { interviewQuestionsSelector } from '../../../selectors/interview/questions';

const hasRunningSideProcessesSelector = createSelector(
  sideProcessesSelector,
  processes => some(processes, process => !!process.enabled)
);

function moveFailed(): MoveFail {
  return { type: 'interview/move-fail' };
}

const moveEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/move'),
    RxO.delayWhen((action: Move) =>
      action.requiresDelay
        ? interval(INTERVIEW_MOVE_EPIC_REQUIRED_DELAY)
        : EMPTY
    ),
    RxO.withLatestFrom(state$),
    RxO.mergeMap(([action, state]: [Move, AppState]) => {
      // when epic was called
      // that timestamp identifies exit/enter time for questions
      const timestamp = format(new Date(), DATE_FNS_FORMAT);

      const {
        force,
        backward,
        forward,
        complete,
        stackId,
        silent,
        skipAnswersSubmit
      } = action;

      const language = languageStateSelector(state);

      const hasRunningSideProcesses = hasRunningSideProcessesSelector(state);

      if (!force && hasRunningSideProcesses) {
        if (
          // eslint-disable-next-line no-alert
          !window.confirm(
            localizeMessage(
              'common.labels.waitForActiveProcessesToComplete',
              language
            )()
          )
        ) {
          return [moveFailed()];
        }
      }

      // validate current answers
      const checkResult = checkRunner(action, state);

      if (checkResult) return checkResult;

      const {
        isLogicDriven,
        skipRequest,
        submitRequestsEnabled
      } = interviewStatusesSelector(state);

      const answers = interviewAnswersSelector(state);

      let structure = interviewLogicAwaredStructureSelector(state);

      const activeStackData = interviewActiveStackDataSelector(state);

      const activeStackId = activeStackData?.localId;

      if (backward && !activeStackData) {
        return [moveFailed()];
      }

      // Stage #1
      let targetStackId;
      let targetStackData;
      const moveActions = [];

      if (!isLogicDriven) {
        targetStackId = getTargetStackId({
          stackId,
          backward,
          forward,
          structure,
          activeStackId
        });

        targetStackData = getStackById(targetStackId, structure);
      } else {
        const rules = interviewLogicRulesSelector(state);

        const questions = flatQuestionsSubQuestions(
          interviewQuestionsSelector(state)
        );

        const combinedDataOfAnswersAndQuestion = reduce(
          entries(answers),
          (combinedAnswers, [questionId, answer]) => {
            // eslint-disable-next-line eqeqeq
            const question = find(questions, { id: +questionId });

            return {
              ...combinedAnswers,
              [questionId]: {
                ...answer,
                question
              }
            };
          },
          {}
        );

        const logicResults: ILogicResults = logicRulesResultsAnalyzer(
          logicRunner(rules, { answers: combinedDataOfAnswersAndQuestion })
        );

        // В этом случае нужна чистая структура
        // без текущих логических примесей.
        // Так как вычислять следующее состояние
        // будем сразу здесь.
        structure = interviewStructureSelector(state);

        // Теперь мы получили по сути то состояние структуры, которое вернёт нам
        // interviewLogicAwaredStructureSelector после того как мы вернём все данные.
        structure = logicAwaredStructureComposer(structure, logicResults);

        targetStackId = getTargetStackId({
          stackId,
          backward,
          forward,
          structure,
          activeStackId
        });

        targetStackData = getStackById(targetStackId, structure);

        // region Jumps Processing
        const jumps = logicResults.jumps ?? [];

        const hasJumps = Array.isArray(jumps) && jumps.length > 0;

        // Нецеленаправленный move это переход с указанием направления,
        // но без указания конкретной страницы.
        const nonDirectMove = (forward || backward) && !stackId;

        // Переходы отрабатывают только если прыжок не целенаправленный,
        // то есть нет указания на stackId
        if (nonDirectMove) {
          if (activeStackData && hasJumps) {
            let matchedJumps: LogicJump[] = [];

            if (forward) {
              // Если движение идёт вперёд, то фильтруем все прыжки по from
              matchedJumps = jumps.filter((jump: LogicJump) => {
                const [from] = jump;
                // текущий jump подходит если вопрос текущего стека или
                // хотя бы один из вопросов стека (в случае одностраничных групп и тд)
                // имеется в качестве from
                const {
                  isSinglePage,
                  questionId: activeStackQuestionId,
                  items
                } = activeStackData;

                if (!isSinglePage) {
                  return activeStackQuestionId === from;
                }

                return some(
                  items,
                  (item: IInterviewStructureElement) => item.questionId === from
                );
              });
            } else if (backward) {
              // Если движение идёт назад, то фильтруем все прыжки по to
              matchedJumps = jumps.filter((jump: LogicJump) => {
                const [, to, canGoBack] = jump;

                // if jump does not allowed to move user from endpoint to startpoint,
                // then it is filtered
                if (!canGoBack) return false;

                // текущий jump подходит если вопрос текущего стека или
                // хотя бы один из вопросов стека (в случае одностраничных групп и тд)
                // имеется в качестве to
                const {
                  isSinglePage,
                  questionId: activeStackQuestionId,
                  items
                } = activeStackData;

                if (!isSinglePage) {
                  return activeStackQuestionId === to;
                }

                return some(
                  items,
                  (item: IInterviewStructureElement) => item.questionId === to
                );
              });
            }

            // Последний переход превалирует,
            // а потому, чтобы получить последний переход как первый,
            // делаем реверс полученного массива.
            matchedJumps = matchedJumps.reverse();

            // eslint-disable-next-line no-restricted-syntax
            for (const matchedJump of matchedJumps) {
              const [from, to] = matchedJump;

              if (forward) {
                const toStack = getStackByQuestionId(structure, to, {
                  recursive: true,
                  rootOnly: false
                });

                if (toStack && !toStack.hidden) {
                  // eslint-disable-next-line prefer-destructuring
                  targetStackId = toStack.path[0];
                  targetStackData = getStackById(toStack.path[0]);
                  break;
                }
              } else if (backward) {
                const fromStack = getStackByQuestionId(structure, from, {
                  recursive: true,
                  rootOnly: false
                });

                if (fromStack && !fromStack.hidden) {
                  // eslint-disable-next-line prefer-destructuring
                  targetStackId = fromStack.path[0];
                  targetStackData = getStackById(fromStack.path[0]);
                  break;
                }
              }
            }
          }

          // Если оказалось, что точка перехода по прыжку недоступна,
          // то делается поиск следующей доступной страницы до/после,
          // указанного в jump'е.
          if (targetStackData && targetStackData.hidden) {
            if (forward) {
              const nextFirstVisible: IInterviewStructureElement = getTargetStackId(
                {
                  forward: true,
                  stackId: null,
                  structure,
                  activeStackId: targetStackId
                }
              );
              targetStackId = nextFirstVisible;
              targetStackData = getStackById(nextFirstVisible, structure);
            } else if (backward) {
              const prevLastVisible: IInterviewStructureElement = getTargetStackId(
                {
                  backward: true,
                  stackId: null,
                  structure,
                  activeStackId: targetStackId
                }
              );
              targetStackId = prevLastVisible;
              targetStackData = getStackById(prevLastVisible, structure);
            }
          }
        }
        // endregion

        // Устанавливаем результаты вычисления логики
        moveActions.push(
          of(
            ({
              type: 'interview/set-logic-results',
              results: logicResults
            }: SetLogicResults)
          )
        );
      }

      // рассчитываем необходимость завершения сессии
      const shouldCompleteSession = complete || targetStackId === -1;
      // рассчитываем необходимость смены стека
      const shouldChangeStack = targetStackId !== -1 && !isNil(targetStackId);

      // We have to call change active stack whether we want change it or not,
      // some application parts are listening it.
      moveActions.push(
        // && вернёт первое falsy value или последнее указанное
        of(changeActiveStack(shouldChangeStack && targetStackId))
      );

      if (shouldCompleteSession) {
        moveActions.push(of(completeInterview()));
      }

      // Если проект не управляется логикой,
      // тогда мы вычисляем все значения и сразу переходим к стадии 2.

      // Если проект управляется логикой, то мы вычисляем сперва логику
      // на основании имеющихся ответов, то бишь внутренний вызов вычислений логики,
      // после чего применяем все изменения над чистой структурой (используем селектор чистой структуры).
      // После этого вычисляем точки перехода на основании новой структуры

      // Stage #2
      const [
        activeStackQuestionIds,
        targetStackQuestionIds
      ] = getQuestionsIdsForStackTransition(activeStackData, targetStackData);

      // Stage #3
      // if we have set of questions that would disappear from the screen,
      // then create redux action, that will add appropriate information
      // in 'registry' store
      if (activeStackId && activeStackId !== targetStackId) {
        moveActions.push(
          of(
            ({
              type: 'interview/registry',
              payload: {
                questionIds: activeStackQuestionIds,
                exit: timestamp
              }
            }: AddRegistryRecord)
          )
        );
      }

      // if we have set of questions that would appear on the screen,
      // then create redux action, that will add appropriate information
      // in 'registry' store
      moveActions.push(
        of({
          type: 'interview/registry',
          payload: {
            questionIds: targetStackQuestionIds,
            enter: timestamp
          }
        })
      );

      // let enterRegistryRecord$ = of({
      //   type: 'interview/registry',
      //   payload: {
      //     questionIds: targetStackQuestionIds,
      //     enter: timestamp
      //   }
      // });

      const move$ = merge(
        ...moveActions
        // of(changeActiveStack(shouldChangeStack ? targetStackId : false)),
        // exitRegistryRecord$,
        // enterRegistryRecord$,
        // shouldCompleteSession ? of(completeInterview()) : EMPTY
      );

      // В случае если ничего не отправляем, то сразу возвращаем
      if (!submitRequestsEnabled || activeStackQuestionIds.length === 0) {
        return move$;
      }

      const submitAnswers$ = of({
        type: 'interview/submit-answers',
        instantSubmit: !!skipRequest || !!silent,
        // if skipAnswersSubmit is passed, then send only views
        only: skipAnswersSubmit ? ['views'] : null,
        timeStamp: timestamp,
        questionsIds: activeStackQuestionIds
      });

      return merge(move$, submitAnswers$);
    })
  );

export default moveEpic;
