/**
 * Я постарался сделать данный редьюсер универсальным в том плане,
 * что необязательно будет его использовать исключительно в Redux.
 * Как можно помнить, то в useReducer хуке, `default` case принято считать за нечто сродни ошибки,
 * а потому нужно учесть данное обстоятельство.
 *
 * Таким образом, если кому-нибудь придёт мысль перенести данный функционал в компонент из redux,
 * то он сможет спокойно использовать данный редьюсер в useReducer сразу с учётом проверки на default экшны.
 */
import type {
  RegisterTask,
  TasksWatchersAction,
  UpdateTaskStatus
} from './action.flow';
import type { TasksWatchersState } from './state.flow';
import { composeId } from '../utils';

function registerWatcher(
  state: TasksWatchersState,
  action: RegisterTask
): TasksWatchersState {
  const id = composeId(action);

  if (state[id]) return state;

  return {
    ...state,
    [id]: {
      taskId: action.taskId,
      taskType: action.taskType,
      status: action.status,
      // status that is considered as successful for that task
      targetSuccessStatus: action.targetSuccessStatus,
      // status that is considered as failure for that task
      targetFailureStatus: action.targetFailureStatus,
      remote: {
        ...action.remote
      }
    }
  };
}

function updateStatus(
  state: TasksWatchersState,
  action: UpdateTaskStatus
): TasksWatchersState {
  const id = composeId(action);

  if (!state[id]) return state;

  return {
    ...state,
    [id]: {
      ...state[id],
      status: action.status
    }
  };
}

function createTasksWatchersReducer(allowDefaultCase = true) {
  const initialState = {};

  return function tasksWatchersReducer(
    state: TasksWatchersState = initialState,
    action: TasksWatchersAction
  ) {
    switch (action.type) {
      case 'tasks-watchers/register':
        return registerWatcher(state, action);

      case 'tasks-watchers/unregister':
        return Object.keys(state).reduce((result, id) => {
          if (composeId(action) === id) return result;

          return {
            ...state,
            [id]: state[id]
          };
        }, {});

      case 'tasks-watchers/update-status':
        return updateStatus(state, action);

      default:
        if (allowDefaultCase) return state;

        throw new Error(
          `tasks watchers reducer: ${action.type} is not supported!`
        );
    }
  };
}

export default createTasksWatchersReducer();
