import revertDictionary from '../../utils/revertDictionary';

const userFormDictionary = {
  'users.form.name': {
    en: 'Name',
    ru: 'Имя'
  },
  'users.form.email': {
    en: 'Email',
    ru: 'Email'
  },
  'users.form.phone': {
    en: 'Phone',
    ru: 'Мобильный телефон'
  },
  'users.form.password': {
    en: 'Password {confirmation, select, true {confirmation} other {}}',
    ru: '{confirmation, select, true {Подветрждение пароля} other {Пароль}}'
  },
  'users.form.company': {
    en: 'Company',
    ru: 'Компания'
  },
  'users.form.department': {
    en: 'Department',
    ru: 'Отдел'
  },
  'users.form.departments': {
    en: 'Departments',
    ru: 'Отделы'
  },
  'users.form.role': {
    en: 'Role',
    ru: 'Роль'
  },
  'users.form.add_company': {
    en: 'Add company',
    ru: 'Добавить компанию'
  },
  'users.form.fields.active.label': {
    en: 'Status',
    ru: 'Статус'
  },
  'users.form.fields.webAccess.label': {
    en: 'Allowed to view projects and create records',
    ru: 'Доступ к просмотру проектов и созданию записей'
  },
  'users.form.fields.active.options.true': {
    en: 'Active',
    ru: 'Активный'
  },
  'users.form.fields.active.options.false': {
    en: 'Unactive',
    ru: 'Неактивный'
  }
};

export default revertDictionary(userFormDictionary);
