// @flow

import filter from 'lodash/filter';
import map from 'lodash/map';

import type { InterviewAppearance } from 'flow-types/states/InterviewState/InterviewRecordState';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { IProject } from 'flow-types/entities/Project';

import { INTERVIEW_MODE } from './constants';
import { nextStructureConstructor } from './structureConstructor';

type Params = {
  project?: IProject,
  mode?: string,
  appearance: InterviewAppearance
};

const initialParams: Params = {
  appearance: null,
  mode: INTERVIEW_MODE.LOCAL
};

const filterVisible = (items, params: Params = initialParams) =>
  filter(
    items,
    (item: {
      isVisibleForEdit: boolean,
      isVisibleForPost: boolean,
      isVisibleForPublic: boolean,
      isVisibleForMain: boolean
    }) => {
      switch (params.appearance) {
        case 'edit':
          return item.isVisibleForEdit;
        case 'post':
          return item.isVisibleForPost;
        case 'public':
          return item.isVisibleForPublic;
        case 'web':
          return item.isVisibleForMain;
        default:
          return false;
      }
    }
  );

export default function constructProjectStructure(
  params: Params = initialParams
): IInterviewStructureElement[] {
  if (!params || !params.project) return [];

  const { project } = params;

  const { groups } = project;

  const result = map(filterVisible(groups, params), group => {
    const { questions, ...safeGroup } = group;
    return {
      questions: filterVisible(questions, params),
      ...safeGroup
    };
  });

  // pass selection into construction
  return nextStructureConstructor(result);
}
