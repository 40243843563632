const queryDictionary = {
  en: {
    'project.tabs.queries': 'Queries',
    'queryComposer.form.header': 'Query Composer',
    'queryComposer.form.inputFields': 'Input Fields',
    'queryComposer.form.entity': 'Query Entity',
    'queryComposer.form.queryFields': 'Query Fields',
    'queryComposer.form.saveButton': 'Save Query',
    'queryComposer.form.deleteButton': 'Delete Query',
    'queryComposer.form.minChars': 'Min chars.',
    'queryComposer.form.searchBy': 'Search by',
    'queryBrowser.charsLeft':
      'Enter {charsLeft, plural, one {# character more} other {# characters more}}',
    'queryBrowser.header': 'Queries',
    'queryBrowser.queryId': 'Query ID',
    'queryBrowser.queryTitle': 'Query Title',
    'queryBrowser.queryEntity': 'Query Entity'
  },
  ru: {
    'project.tabs.queries': 'Запросы',
    'queryComposer.form.header': 'Редактирование запроса',
    'queryComposer.form.inputFields': 'Входные параметры',
    'queryComposer.form.entity': 'Запращиваемая сущность',
    'queryComposer.form.queryFields': 'Запращиваемые поля',
    'queryComposer.form.saveButton': 'Сохранить запрос',
    'queryComposer.form.deleteButton': 'Удалить запрос',
    'queryComposer.form.minChars': 'Мин. символов',
    'queryComposer.form.searchBy': 'Поиск по',
    'queryBrowser.charsLeft':
      'Введите еще {charsLeft, plural, one {# символ} few {# символа} other {# символов}}',
    'queryBrowser.header': 'Запросы',
    'queryBrowser.queryId': 'Идентификатор',
    'queryBrowser.queryTitle': 'Название',
    'queryBrowser.queryEntity': 'Используемая Сушность'
  }
};

export default queryDictionary;
