import { combineEpics } from 'redux-observable';
import { deleteComparatorEpic } from './deleteTestElement';
import { saveComparatorEpic } from './saveTestElement';
import { fetchComparatorsEpic } from './fetchTestElements';

export default combineEpics(
  fetchComparatorsEpic,
  saveComparatorEpic,
  deleteComparatorEpic
);
