import { combineEpics } from 'redux-observable';
import fetchUsersEpic from './fetchUsers';
import saveUserEpic, { saveUserSuccessWatcher } from './saveUser';
import deleteUserEpic from './removeUser';
import editUserEpic from './editUser';

export default combineEpics(
  fetchUsersEpic,
  saveUserSuccessWatcher,
  saveUserEpic,
  deleteUserEpic,
  editUserEpic
);
