// @flow

import type { SignInSuccess } from 'flow-types/actions/auth/SignIn';
import type { AuthState } from 'flow-types/states/AuthState';
import type { AuthAction } from 'flow-types/actions/auth';

export const SCOPE = {
  ALL: '*',
  NONE: 'none'
};

const initialState: AuthState = {
  isLoggedIn: null,
  isLoading: false,
  token: null,
  error: null,
  user: null,
  scope: null,
  view: 'login',
  // meta is some extra info being received as the result of singing in
  meta: null,
  passwordResetForm: {
    uuid: null,
    loading: false
  }
};

function handleSignInSuccess(state: AuthState, action: SignInSuccess) {
  return {
    ...state,
    isLoggedIn: true,
    user: action.user,
    scope: action.scope,
    meta: action.meta,
    token: action.accessToken
  };
}

export default (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'set-scope':
      return {
        ...state,
        scope: action.scope
      };

    case 'auth/reset-password':
      return {
        ...state,
        isLoading: true
      };

    case 'auth/reset-password-success':
    case 'auth/reset-password-fail':
      return {
        ...state,
        isLoading: false
      };

    case 'auth/change-view':
      return {
        ...state,
        view: action.view
      };
    case 'auth/sign-in-with-token':
    case 'auth/sign-in-with-hash':
    case 'auth/sign-in':
      return {
        ...state,
        isLoading: true
      };

    case 'auth/sign-in-fail':
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false
      };

    case 'auth/sign-in-success':
      return handleSignInSuccess(state, action);

    case 'auth/sign-out':
      return {
        ...initialState,
        isLoggedIn: false
      };
    default:
      return state;
  }
};
