// @flow

import React, { useMemo } from 'react';
import type { AbstractComponent } from 'react';
import cx from 'classnames';
import type { ReactComponent } from 'flow-types/ReactComponent';

export type HeaderProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div' | ReactComponent,
  size?: null | 'tiny' | 'small' | 'medium' | 'big' | 'large' | 'huge',
  className?: string,
  icon?: boolean,
  sub?: boolean,
  dividing?: boolean,
  block?: boolean,
  attached?: boolean | 'top' | 'bottom',
  floated?: false | 'left' | 'right',
  inverted?: boolean,
  color?: null | string,
  disabled?: boolean,
  nonUI?: boolean
};

const Header: AbstractComponent<HeaderProps, mixed> = React.forwardRef(
  (
    {
      as: Component,
      className,
      sub,
      icon,
      size,
      dividing,
      block,
      attached,
      floated,
      inverted,
      color,
      disabled,
      nonUI,
      ...props
    }: HeaderProps,
    ref
  ) => {
    const classN = useMemo(
      () =>
        cx(
          nonUI ? '' : 'ui',
          color,
          {
            sub,
            icon,
            dividing,
            block,
            disabled,
            'left floated': floated === 'left',
            'right floated': floated === 'right',
            'top attached': attached === 'top',
            'bottom attached': attached === 'bottom',
            attached: attached !== 'top' && attached !== 'bottom' && attached,
            inverted
          },
          size,
          'header',
          className
        ),
      [
        attached,
        block,
        className,
        color,
        disabled,
        dividing,
        floated,
        icon,
        inverted,
        nonUI,
        size,
        sub
      ]
    );

    if (!Component) return null;

    return <Component {...props} ref={ref} className={classN} />;
  }
);

// $FlowIgnore
Header.defaultProps = {
  size: null,
  as: 'div',
  className: null,
  icon: null,
  sub: false,
  dividing: false,
  block: false,
  attached: false,
  floated: false,
  inverted: false,
  color: null,
  disabled: false,
  nonUI: false
};

Header.displayName = 'Header.ForwardRef';

export default Header;
