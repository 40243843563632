import reduce from 'lodash/reduce';

// This method can be used to flat any type of error data
// First of all we use reduce, thus errors can be either an array, or an object
// we take values of both of them and flat them further
// If you can have errors that should be localized with react-intl first,
// then use intl first and then flat all errors
export default function flatErrors(errors): string[] {
  return reduce(
    errors,
    (flatMessagesList, error) => {
      if (Array.isArray(error)) {
        return [...flatMessagesList, ...flatErrors(error)];
      }

      if (typeof error === 'object') {
        return [...flatMessagesList, ...flatErrors(error)];
      }

      if (typeof error === 'string') {
        return [...flatMessagesList, error];
      }

      return flatMessagesList;
    },
    []
  );
}
