/* eslint-disable react/jsx-props-no-spreading */
// @flow

import * as React from 'react';
import { createCallable } from 'react-callable';
import map from 'lodash/map';
import size from 'lodash/size';
import Modal from './animated/Modal';
import type { Props as ButtonProps } from './Button';
import Button from './Button';

const callable = createCallable({
  customRoot: () => document.body
});

type Props = {
  title: string,
  actions: Array<{ label: string } & ButtonProps>,
  conclude: Function,
  onClose: Function
};

const dimmerProps = {
  fixed: true
};

const Component = ({
  title,
  actions,
  conclude,
  onClose,
  ...rest
}: Props): React.Node => (
  <Modal
    {...rest}
    dimmerProps={dimmerProps}
    onClose={() => {
      if (onClose) {
        onClose();
      }
      conclude();
    }}
    visible
    basic
  >
    {title && (
      <Modal.Header icon>
        <i className="exclamation triangle icon" />
        {title}
      </Modal.Header>
    )}
    {size(actions) > 0 && (
      <Modal.Actions>
        {map(actions, action => {
          const { onClick, label, ...buttonProps } = action;
          return (
            <Button
              onClick={() => {
                if (onClick) onClick();
                conclude();
              }}
              {...buttonProps}
            >
              {label}
            </Button>
          );
        })}
      </Modal.Actions>
    )}
  </Modal>
);

export default (callable(Component): Function);
