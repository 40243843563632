import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import request from '../../utils/request';
import { API } from '../../utils/config';
import interpolateString from '../../common/helpers/interpolateString';
import responseParser from '../../common/epicHelpers/responseParser';

import type {
  SaveAccessSettings,
  SaveAccessSettingsFail
} from '../../flow-types/actions/profile/SaveAccessSettings';
import type { AppState } from '../../flow-types/AppState';
import { normalizeUser } from '../../common/transducers/users/normalizer';

const saveAccessSettings = (action$, state$) =>
  action$.pipe(
    ofType('profile/save-access-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([action]: [SaveAccessSettings, AppState]) => {
      const { userId, accessSettings } = action;

      return request({
        url: interpolateString(API.users.projectsAccessSettings, { userId }),
        method: 'PUT',
        body: {
          project_access_settings_ids: accessSettings
        }
      }).pipe(
        responseParser,
        RxOperators.pluck('data'),
        RxOperators.map(data => {
          const { projectsAccessSettings } = normalizeUser(data);

          return {
            type: 'profile/save-access-settings-success',
            data: projectsAccessSettings
          };
        }),
        RxOperators.catchError((error: AjaxError): [SaveAccessSettingsFail] => [
          {
            type: 'profile/save-access-settings-fail',
            error: error.response
          }
        ])
      );
    })
    // here goes the code
  );

export default saveAccessSettings;
