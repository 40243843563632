import { combineEpics, ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { toast } from 'react-toastify';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import { normalizeStats } from 'common/transducers/response/normalizeStats';

import type { RecordsActions } from 'flow-types/actions/records';
import type { PageState } from 'flow-types/states/PageState';
import type {
  FetchResponsesStats,
  FetchResponsesStatsSuccess
} from 'flow-types/actions/projects/detail/responses/FetchResponseStats';
import responseStatsDictionary from '../../../intl/responseStatsDictionary';
import {
  languageStateSelector,
  locationStateSelector,
  pageStateSelector
} from '../../../selectors';

const fetchResponseStats = ($action, $state) =>
  $action.pipe(
    ofType('records/fetch-response-stats'),
    RxO.map((action: FetchResponsesStats) => {
      const { responseId } = action;
      return responseId;
    }),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([responseId, state]) => {
      const language = languageStateSelector(state);

      return request({
        url: interpolateString(
          API.responses.stats,
          {
            response_id: responseId
          },
          ':'
        ),
        method: 'GET'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.map((rawStats): FetchResponsesStatsSuccess => ({
          type: 'records/fetch-response-stats-success',
          stats: normalizeStats(rawStats)
        })),
        RxO.catchError(error => {
          toast.error(
            responseStatsDictionary[language][
              `response.stats.fetch.error.${error.code || 'unknown'}`
            ],
            {
              autoClose: 2500
            }
          );
          return of({ type: 'records/fetch-response-stats-fail', error });
        })
      );
    })
  );

const responseStatsRefetchWatcher = ($action, $state) =>
  $action.pipe(
    RxO.filter((action: RecordsActions) => {
      const { type } = action;
      return (
        type === 'records-regions/remove-success' ||
        type === 'records-regions/save-success' ||
        type === 'project-responses/disable-test-element-success' ||
        type === 'project-responses/enable-test-element-success'
      );
    }),
    RxO.withLatestFrom($state),
    RxO.mergeMap(([, state]): FetchResponsesStats => {
      const { query } = locationStateSelector(state);
      const { name: page }: PageState = pageStateSelector(state);
      let responseId;
      if (page === 'Project') {
        responseId = query ? query.responseId : null;
        return of({
          type: 'records/fetch-response-stats',
          responseId
        });
      }
      return EMPTY;
    })
  );

export default combineEpics(fetchResponseStats, responseStatsRefetchWatcher);
