const responseStatsDictionary = {
  en: {
    'response.progress': 'response efficiency',
    'response.stats.fetch.error.500':
      'Failed to load response stats: server error!',
    'response.stats.fetch.error.unknown': 'Unknown error occurred!'
  },
  ru: {
    'response.progress': 'оценка встречи',
    'response.stats.fetch.error.500':
      'Не удалось загрузить статистику по встрече: ошибка на удалённом ресурсе!',
    'response.stats.fetch.error.unknown': 'Произошла неизвестная ошибка!'
  }
};

export default responseStatsDictionary;
