import React, { useCallback, useMemo, useRef } from 'react';
import size from 'lodash/size';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Sizer = styled.span`
  position: fixed;
  z-index: -9999;
  visibility: hidden;
  left: 0;
  top: 0;
`;

type Props = {|
  value: string,
  autoGrow: boolean,
  onChange: Function,
  style: Object,
  opened: boolean
|};

const Search = React.forwardRef((props: Props, ref) => {
  const { value, autoGrow, onChange, style = null, opened, ...rest } = props;

  const bufferRef = useRef(null);

  const handleChange = useCallback(event => onChange(event.target.value), [
    onChange
  ]);

  const searchWidth = useMemo(() => {
    if (!autoGrow) return null;

    if (size(value) !== 0 && bufferRef.current) {
      bufferRef.current.innerHTML = value;

      return bufferRef.current.clientWidth + 75;
    }
    return opened ? 75 : 0;
  }, [autoGrow, opened, value]);

  return (
    <>
      <input
        autoComplete="off"
        tabIndex="0"
        ref={ref}
        onChange={handleChange}
        value={value}
        style={autoGrow ? { ...(style && style), width: searchWidth } : style}
        className="search"
        {...rest}
      />
      {createPortal(
        <Sizer className="sizer" ref={bufferRef}>
          {value}
        </Sizer>,
        // $FlowIgnore
        document.body
      )}
    </>
  );
});

export default Search;
