// @flow

import type {
  DecodingTasksState,
  TaskMap
} from 'flow-types/states/RecordsState';
import clone from 'lodash/clone';
import groupBy from 'lodash/groupBy';
import reject from 'lodash/reject';
import { DECODING_TASK_STATUSES } from 'common/helpers/response';

import type { DecodingsAction } from 'flow-types/actions/records/decodings';
import type {
  FetchDecodingTasks,
  FetchDecodingTasksSuccess
} from 'flow-types/actions/records/decodings/tasks/FetchDecodingTasks';
import type {
  CreateDecodingTask,
  CreateDecodingTaskFail,
  CreateDecodingTaskSuccess
} from 'flow-types/actions/records/decodings/tasks/CreateDecodingTask';
import type { UpdateDecodingTaskStatus } from 'flow-types/actions/records/decodings/tasks/UpdateDecodingTaskStatus';
import type { IDecodingTask } from 'flow-types/entities/DecodingTask';

import type { Reducer } from 'flow-types/Reducer';
import constructMap from './utils';

const createInitialState = (): DecodingTasksState => ({
  data: {},
  tasksMap: {}
});

const FETCH_TASKS = 'records/fetch-decoding-tasks';

const FETCH_TASKS_SUCCESS = 'records/fetch-decoding-tasks-success';

const CREATE_TASK = 'records/create-decoding-task';

const CREATE_TASK_SUCCESS = 'records/create-decoding-task-success';

const CREATE_TASK_FAIL = 'records/create-decoding-task-fail';

const UPDATE_TASK_STATUS = 'records/update-decoding-task-status';

function handleTasksFetch(
  state: DecodingTasksState,
  { uploadId }: FetchDecodingTasks
) {
  const next: DecodingTasksState = clone(state);

  if (!next.data[uploadId]) {
    next.data[uploadId] = {
      status: 'loading',
      data: {}
    };
  } else {
    next.data[uploadId].status = 'loading';
  }

  next.tasksMap = constructMap(next.data);

  return next;
}

function handleTasksFetchSuccess(
  state: DecodingTasksState,
  { data, uploadId }: FetchDecodingTasksSuccess
): DecodingTasksState {
  const next: DecodingTasksState = clone(state);

  // save only tasks that is in progress
  const onlyActiveTasks = reject(data, (task: IDecodingTask) =>
    [DECODING_TASK_STATUSES.ERROR, DECODING_TASK_STATUSES.READY].includes(
      task.status
    )
  );

  next.data[uploadId] = {
    status: 'success',
    data: groupBy(onlyActiveTasks, 'providerId')
  };

  next.tasksMap = constructMap(next.data);

  return next;
}

function handleTaskCreate(
  state: DecodingTasksState,
  { data }: CreateDecodingTask
): DecodingTasksState {
  const next: DecodingTasksState = clone(state);

  const tasks = next.data[data.uploadId].data[data.providerId];

  next.data[data.uploadId].data[data.providerId] = Array.isArray(tasks)
    ? [...tasks, data]
    : [data];

  next.tasksMap = constructMap(next.data);

  return next;
}

function handleTaskCreateSuccess(
  state: DecodingTasksState,
  { data, taskId }: CreateDecodingTaskSuccess
): DecodingTasksState {
  const next: DecodingTasksState = clone(state);

  const { tasksMap } = state;

  const { index, providerId, uploadId }: TaskMap = tasksMap[taskId];

  next.data[uploadId].data[providerId][index] = { ...data };

  next.tasksMap = constructMap(next.data);

  return next;
}

function handleTaskStatusUpdate(
  state: DecodingTasksState,
  action: UpdateDecodingTaskStatus
): DecodingTasksState {
  const next: DecodingTasksState = clone(state);

  const { taskId, status } = action;

  const taskMap = state.tasksMap[taskId];

  if (!taskMap) return state;

  const { uploadId, providerId, index }: TaskMap = taskMap;

  next.data[uploadId].data[providerId][index].status = status;

  return next;
}

function handleTaskCreateFail(
  state: DecodingTasksState,
  { providerId, taskId, uploadId }: CreateDecodingTaskFail
): DecodingTasksState {
  const next: DecodingTasksState = clone(state);

  const tasks = next.data[uploadId].data[providerId];

  next.data[uploadId].data[providerId] = tasks.filter(
    task => taskId !== task.id
  );

  next.tasksMap = constructMap(next.data);

  return next;
}

const decodingTasksReducer: Reducer<DecodingTasksState, DecodingsAction> = (
  state = createInitialState(),
  action
) => {
  switch (action.type) {
    case CREATE_TASK_FAIL:
      return handleTaskCreateFail(state, action);

    case UPDATE_TASK_STATUS:
      return handleTaskStatusUpdate(state, action);

    case CREATE_TASK:
      return handleTaskCreate(state, action);

    case CREATE_TASK_SUCCESS:
      return handleTaskCreateSuccess(state, action);

    case FETCH_TASKS:
      return handleTasksFetch(state, action);

    case FETCH_TASKS_SUCCESS:
      return handleTasksFetchSuccess(state, action);

    default:
      return state;
  }
};

export default decodingTasksReducer;

export {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAIL,
  UPDATE_TASK_STATUS
};
