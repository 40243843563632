// @flow

import React, { useCallback, useMemo } from 'react';
import type { Node } from 'react';
import Form, { Field } from 'common/containers/Form';
import Input, { InputWrapper } from 'common/components/Input';
import Label from 'common/components/Label';
import Dropdown from 'common/components/Dropdown';
import Menu from 'common/components/Menu';
import Editor from 'common/components/Editor';
import Button from 'common/components/Button';
import useI18N from 'common/hooks/useI18N';
import Text from 'common/components/Text';

import { Tab, TabNavItem, TabsProvider } from 'common/contexts/TabsContext';

import type { IPollingNotification } from 'flow-types/entities/PollingNotification';

import isNewElement from 'common/helpers/isNewElement';
import { TYPES } from 'common/helpers/question';
import Segment from 'common/components/Segment';
import Header from 'common/components/Header';
import Icon from 'common/components/Icon';
import DeleteButton from 'common/components/Button/DeleteButton';
import PostPollingDelay from '../ProjectForm/PostPollingDelay';
import ResponsesFilterSettingsForm from '../FilterSettingsForm/ResponsesFilterSettingsForm';
import ProjectAccessSettingsTable from '../AccessSettingsForm/ProjectAccessSettingsTable';

import SentPollingNotificationsCounter from './SentPollingNotificationsCounter';
import SuitingPollingNotificationsCounter from './SuitingPollingNotificationsCounter';
import QuestionTypeSelector, {
  postPollingFilter
} from '../QuestionSideForm/fields/QuestionTypeSelector';

const ChannelsSelector = ({
  onChange,
  value
}: {
  value: null | string,
  onChange: Function
}) => {
  const options = useMemo(
    () =>
      ['sms', 'email', 'all'].map(channel => ({
        value: channel,
        label: channel
      })),
    []
  );

  const handleChange = useCallback(
    data => {
      onChange({
        channel: data
      });
    },
    [onChange]
  );

  return (
    <Dropdown
      value={value}
      name="channel"
      onlyValue
      options={options}
      onChange={handleChange}
    />
  );
};

type Props = {
  data: IPollingNotification,
  validation: Object,
  status: null | 'loading' | 'disabled',
  onSubmit: Function,
  onChange: (dataUpdate: $Shape<IPollingNotification>) => void
};

export default function PollingNotificationForm({
  data,
  onSubmit,
  onChange,
  status,
  validation
}: Props): Node {
  const messagesConfig = useMemo(
    () => ({
      settingsTableColumnsVisible: {
        id: 'accessSettingsForm.fields.settingsTable.columns.visible'
      },

      settingsTableColumnsHidden: {
        id: 'accessSettingsForm.fields.settingsTable.columns.hidden'
      },

      mainTab: { id: 'pollingNotificationForm.tabs.main' },

      filtersTab: {
        id: 'pollingNotificationForm.tabs.filters'
      },

      vizSettingsTab: {
        id: 'pollingNotificationForm.tabs.vizSettings'
      },

      configTab: {
        id: 'pollingNotificationForm.tabs.config'
      },

      // statisticsTab: {
      //   id: 'pollingNotificationForm.tabs.statistics'
      // },

      addGreetingConfig: {
        id: 'pollingNotificationsForm.actions.addGreetingConfig'
      },

      title: {
        id: 'pollingNotificationForm.fields.title.label'
      },

      channel: {
        id: 'pollingNotificationForm.fields.channels.label'
      },

      testsHint: {
        id: 'pollingNotificationForm.fields.tests.hint'
      },

      vizSettingsHint: {
        id: 'pollingNotificationForm.fields.vizSettings.hint'
      },

      greetingTitle: {
        id: 'pollingNotificationsForm.fields.config.greeting.title.label'
      },

      greetingContent: {
        id: 'pollingNotificationsForm.fields.config.greeting.content.label'
      },

      delay: {
        id: 'pollingNotificationsForm.fields.delay.label'
      }
    }),
    []
  );

  const messages = useI18N(messagesConfig);

  const accessTypes = useMemo(
    () => [
      {
        label: messages.settingsTableColumnsVisible,
        value: 'visible'
      },
      {
        label: messages.settingsTableColumnsHidden,
        value: 'hidden'
      }
    ],
    [messages.settingsTableColumnsHidden, messages.settingsTableColumnsVisible]
  );

  const updateGreeting = useCallback(
    (greeting: Object) => {
      if (!onChange) return;

      onChange({
        config: {
          greeting
        }
      });
    },
    [onChange]
  );

  const updateEnding = useCallback(
    (ending: Object) => {
      if (!onChange) return;

      onChange({
        config: {
          ending
        }
      });
    },
    [onChange]
  );

  const { config } = data;

  const hasParting = !!config?.ending;

  const hasGreeting = !!config?.greeting;

  return (
    <Form
      preventEventFlow
      virtualSubmit
      onSubmit={onSubmit}
      validation={validation}
    >
      <TabsProvider initialActiveTab="main">
        <Menu secondary>
          <TabNavItem eventKey="main">{messages.mainTab}</TabNavItem>
          <TabNavItem eventKey="filters">{messages.filtersTab}</TabNavItem>
          <TabNavItem eventKey="vizSettings">
            {messages.vizSettingsTab}
          </TabNavItem>
          <TabNavItem eventKey="templates">{messages.configTab}</TabNavItem>
        </Menu>
        <Tab eventKey="main">
          <Field
            disabled={['disabled', 'loading'].includes(status)}
            name="title"
          >
            <Label nonUI>{messages.title}</Label>
            <InputWrapper>
              <Input
                value={data.title}
                onChange={onChange}
                onlyValue
                name="title"
                useNameAsValueKey
              />
            </InputWrapper>
          </Field>
          <Field
            disabled={['disabled', 'loading'].includes(status)}
            name="channels"
          >
            <Label nonUI>{messages.channel}</Label>
            <ChannelsSelector value={data.channel} onChange={onChange} />
          </Field>
          {!isNewElement(data) && (
            <Field
              group
              wide="two"
              disabled={['disabled', 'loading'].includes(status)}
            >
              <Field>
                <Label nonUI>
                  {/* TODO: localize */}
                  Отправлено
                </Label>
                <div>
                  <SentPollingNotificationsCounter notificationId={data.id} />
                </div>
              </Field>
              <Field>
                <Label nonUI>
                  {/* TODO: localize */}
                  Подходит под настройки
                </Label>
                <div>
                  <SuitingPollingNotificationsCounter
                    notificationId={data.id}
                  />
                </div>
              </Field>
            </Field>
          )}
          <Field>
            <Label nonUI>{messages.delay}</Label>
            <PostPollingDelay
              disabled={['disabled', 'loading'].includes(status)}
              type={data.delayType}
              value={data.delayValue}
              onTypeChange={delayType => onChange({ delayType })}
              onValueChange={delayValue => onChange({ delayValue })}
              typeName="delayType"
              valueName="delayValue"
            />
          </Field>
        </Tab>
        <Tab eventKey="filters">
          <Text nonUI={false} color="grey">
            {messages.testsHint}
          </Text>
          <ResponsesFilterSettingsForm
            validation={validation ? validation.tests : null}
            padded="vertically"
            isDisabled={['disabled', 'loading'].includes(status)}
            data={data.tests}
            onDataChange={tests => onChange({ tests })}
          />
        </Tab>
        <Tab eventKey="vizSettings">
          <Text nonUI={false} color="grey">
            {messages.vizSettingsHint}
          </Text>
          <ProjectAccessSettingsTable
            accessTypes={accessTypes}
            disabled={['disabled', 'loading'].includes(status)}
            data={data}
            onChange={nextData => {
              // TODO: make ProjectAccessSettingsTable to not expect setState-like function
              if (typeof nextData === 'function') {
                onChange(nextData(data));
              } else {
                onChange(nextData);
              }
            }}
          />
        </Tab>
        <Tab eventKey="templates">
          {!hasGreeting ? (
            <Field>
              <Button
                onClick={() => updateGreeting({ title: '', content: '' })}
                disabled={['disabled', 'loading'].includes(status)}
                buttonType="basic"
              >
                <Icon icon="plus" />
                {messages.addGreetingConfig}
              </Button>
            </Field>
          ) : (
            <>
              <Header attached="top">
                {/* TODO: localize */}
                Приветствие
              </Header>
              <Segment attached>
                <Field>
                  <Label nonUI>
                    {/* TODO: localize */}
                    Заголовок
                  </Label>
                  <InputWrapper>
                    <Input
                      disabled={['disabled', 'loading'].includes(status)}
                      value={config.greeting.title}
                      onChange={updateGreeting}
                      onlyValue
                      name="title"
                      useNameAsValueKey
                    />
                  </InputWrapper>
                </Field>
                <Field>
                  <Label nonUI>
                    {/* TODO: localize */}
                    Содержание
                  </Label>
                  <Editor
                    disabled={['disabled', 'loading'].includes(status)}
                    padded={false}
                    value={config.greeting.content}
                    onChange={content => updateGreeting({ content })}
                  />
                </Field>
              </Segment>
              <Segment attached="bottom">
                <DeleteButton
                  className="basic"
                  onClick={() => updateGreeting(null)}
                />
              </Segment>
            </>
          )}
          {!hasParting ? (
            <Field>
              <Button
                onClick={() =>
                  updateEnding({
                    title: '',
                    type: TYPES.HTML,
                    description: ''
                  })
                }
                disabled={['disabled', 'loading'].includes(status)}
                buttonType="basic"
              >
                <Icon icon="plus" />
                {/* TODO: localize */}
                Добавить завершающее сообщение
              </Button>
            </Field>
          ) : (
            <>
              <Header attached="top">
                {/* TODO: localize */}
                Завершение
              </Header>
              <Segment attached>
                <Field>
                  <Label nonUI>
                    {/* TODO: localize */}
                    Заголовок
                  </Label>
                  <Input
                    onlyValue
                    value={config.ending.title}
                    onChange={title => updateEnding({ title })}
                  />
                </Field>
                <Field>
                  <QuestionTypeSelector
                    editable
                    filterOption={postPollingFilter}
                    resetValue={TYPES.HTML}
                    value={config.ending.type}
                    onChange={type => updateEnding({ type: +type })}
                  />
                </Field>
                {/* $FlowIgnore */}
                {config.ending.type === TYPES.Redirect ? (
                  <Field>
                    <Label nonUI>URL</Label>
                    <Input
                      onlyValue
                      value={config.ending.url}
                      onChange={url => updateEnding({ url })}
                    />
                  </Field>
                ) : null}
                {/* $FlowIgnore */}
                {config.ending.type === TYPES.HTML ? (
                  <Field>
                    <Label nonUI>Содержание</Label>
                    <Editor
                      disabled={['disabled', 'loading'].includes(status)}
                      padded={false}
                      value={config.ending.content}
                      onChange={content => updateEnding({ content })}
                    />
                  </Field>
                ) : null}
              </Segment>
              <Segment attached="bottom">
                <DeleteButton
                  className="basic"
                  onClick={() => updateEnding(null)}
                />
              </Segment>
            </>
          )}
        </Tab>
      </TabsProvider>
    </Form>
  );
}

PollingNotificationForm.defaultProps = {
  data: {
    id: null,
    title: '',
    config: {
      greeting: null,
      ending: null
    }
  }
};
