// @flow

import type { InterviewAction } from 'flow-types/actions/interview';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { Reducer } from 'flow-types/Reducer';

export type InterviewStructureState = null | IInterviewStructureElement[];

export type InterviewStructureAction = InterviewAction;

const initialState = null;

const structureReducer: Reducer<
  InterviewStructureState,
  InterviewStructureAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'interview/prepare-complete':
      return action.structure;
    default:
      return state;
  }
};

export default structureReducer;
