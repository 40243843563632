import type { InterviewAction } from '../../flow-types/actions/interview';
import type { InterviewLogState } from '../../flow-types/states/InterviewState/InterviewLogState';

const initialState: InterviewLogState = [];

export default function interviewLogReducer(
  state: InterviewLogState = initialState,
  action: InterviewAction
) {
  switch (action.type) {
    case 'interview/log':
      return [...state, action.payload];
    default:
      return state;
  }
}
