// @flow

import { VARIABLE_TYPES } from './types';

/**
 * Computed Properties use PostgreSQL data types as soon as they
 * are stored in a corresponding table cells with a strict typing,
 * that cannot be changed so easy. Actually, this is relevant for CRM,
 * but we should take it into account from the start.
 *
 * As soon as JS has not such type system as PostgreSQL, we have to downcast:
 * 1. varchar and text to string
 * 2. decimal and integer to number
 *
 * This downcasting will let us to avoid an overusage of both `if-else` statements and `includes` checks
 * to decide how to treat one or another variable.
 */
export const convertVariableTypeToJSType = (varType: string): string => {
  switch (varType) {
    // TODO: this type will be implemented completely later
    case 'varchar':
      return 'string';
    // TODO: this type will be implemented completely later
    case 'integer':
      return 'number';
    case VARIABLE_TYPES.text.code:
      return 'string';
    case VARIABLE_TYPES.decimal.code:
      return 'number';
    default:
      return 'string';
  }
};
