import revertDictionary from '../../utils/revertDictionary';

const testsFormDictionary = {
  'testsForm.actions.confirmTestDelete': {
    en: 'Do you want to delete this test?',
    ru: 'Удалить проверку?'
  },
  'testsForm.actions.addTest': {
    en: 'Add test',
    ru: 'Добавить проверку'
  },
  'testsForm.unionOperator.and': {
    en: 'AND',
    ru: 'И'
  },
  'testsForm.unionOperator.or': {
    en: 'OR',
    ru: 'ИЛИ'
  },
  'testsForm.testOperator.in': {
    en: 'includes',
    ru: '{multi, select, true {выбраны} false {выбрано} other {выбраны}}'
  },
  'testsForm.testOperator.not_in': {
    en: 'excludes',
    ru:
      '{multi, select, true {не выбраны} false {не выбрано} other {не выбраны}}'
  },
  'testsForm.testOperator.eq': {
    en: 'equals',
    ru: 'равно'
  },
  'testsForm.testOperator.lte': {
    en: 'less than or equal',
    ru: 'меньше или равно'
  },
  'testsForm.testOperator.gte': {
    en: 'greater than or equal',
    ru: 'больше или равно'
  },
  'testsForm.testOperator.empty': {
    en: 'is empty',
    ru: 'без значения'
  },
  'testsForm.testOperator.not_empty': {
    en: 'is filled',
    ru: 'содержит значение'
  },
  'testsForm.testOperator.placeholder': {
    en: 'Choose an operator',
    ru: 'Выберите оператор'
  },
  'testsForm.testUnionOperator.allOf': {
    en: 'all of',
    ru: 'все из'
  },
  'testsForm.testUnionOperator.oneOf': {
    en: 'one of',
    ru: 'одно из'
  },
  'testsForm.testUnionOperator.only': {
    en: 'only',
    ru: 'только'
  },
  'testsForm.testOperator.contains': {
    en: 'contains',
    ru: 'содержит'
  },
  'testsForm.testOperator.not_contains': {
    en: 'not contains',
    ru: 'не содержит'
  },
  'testsForm.testOperator.beforeOrMatch': {
    en: 'before or match',
    ru: 'раньше или соответствует'
  },
  'testsForm.testOperator.before': {
    en: 'before',
    ru: 'раньше'
  },
  'testsForm.testOperator.match': {
    en: 'match',
    ru: 'соответствует'
  },
  'testsForm.testOperator.afterOrMatch': {
    en: 'after or match',
    ru: 'после или соответствует'
  },
  'testsForm.testOperator.after': {
    en: 'after',
    ru: 'после'
  },
  'testsForm.testOperator.withingPeriod': {
    en: 'in',
    ru: 'в течение'
  },
  'testsForm.testOperator.wentMoreThan': {
    en: 'passed more than',
    ru: 'прошло не менее'
  },
  'testsForm.testOperator.wentLessThan': {
    en: 'passed less that',
    ru: 'прошло не более'
  },
  'testsForm.testSettingsForm.title': {
    ru: 'Настройки проверки',
    en: 'Test settings'
  },
  'testsForm.testSettingsForm.fields.specialTime': {
    ru: 'Относительное время',
    en: 'Relative time'
  }
};

export default revertDictionary(testsFormDictionary);
