import composeDictionaries from '../../utils/composeDictionaries';
import { passwordChangeFormDictionary } from './passwordChangeFormDictionary';
import { responseCommentFormDictionary } from './responseCommentFormDictionary';
import questionFormDictionary from './questionFormDictionary';
import questionGroupFormDictionary from './questionGroupFormDictionary';
import regionFormDictionary from './regionFormDictionary';
import projectFormDictionary from './projectFormDictionary';
import quickImportFormDictionary from './quickImportFormDictionary';
import logicRuleFormDictionary from './logicRuleFormDictionary';
import interviewerSelectionForm from './interviewerSelectionForm';
import testsFormDictionary from './testsFormDictionary';
import userFormDictionary from './userFormDictionary';
import accessSettingsFormDictionary from './accessSettingsFormDictionary';
import pollingNotificationFormDictionary from './pollingNotificationFormDictionary';
import visibilitySettingsFormDictionary from './visibilitySettingsFormDictionary';
import responsesMassActionsFormDictionary from '../../pages/Project/components/Forms/ResponsesMassActionsForm/dictionary';

const formsDictionaries = composeDictionaries(
  passwordChangeFormDictionary,
  responseCommentFormDictionary,
  questionFormDictionary,
  questionGroupFormDictionary,
  regionFormDictionary,
  projectFormDictionary,
  quickImportFormDictionary,
  logicRuleFormDictionary,
  interviewerSelectionForm,
  testsFormDictionary,
  userFormDictionary,
  accessSettingsFormDictionary,
  pollingNotificationFormDictionary,
  visibilitySettingsFormDictionary,
  responsesMassActionsFormDictionary
);

export default formsDictionaries;
