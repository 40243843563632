import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { camelizeKeys } from 'humps';
import IntlMessageFormat from 'intl-messageformat';
import { toast } from 'react-toastify';
import request from 'utils/request';
import { API } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import type { AuthState } from 'flow-types/states/AuthState';

import { authStateSelector, languageStateSelector } from '../../selectors';
import authDictionary from '../../intl/authDictionary';

export const signInEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('auth/sign-in'),
    RxO.map(action => action.credentials),
    RxO.withLatestFrom($state),
    RxO.switchMap(([credentials, state]) => {
      const auth: AuthState = authStateSelector(state);
      const language = languageStateSelector(state);
      const { rememberMe, ...restCredentials } = credentials;
      return request({
        method: 'POST',
        url: API.login,
        body: restCredentials
      }).pipe(
        RxO.mergeMap(loginResponse => {
          const { response } = loginResponse;

          const {
            user,
            data: { accessToken }
          } = camelizeKeys(response);

          return of({
            type: 'auth/sign-in-success',
            user,
            accessToken,
            rememberMe,
            scope: '*',
            initialLoggedInState: auth.isLoggedIn
          });
        }),
        RxO.catchError(errorResponse => {
          const intlAuthDictionary = authDictionary[language];

          const errorMsg = new IntlMessageFormat(
            intlAuthDictionary['auth.form.messages.wrong.credentials'],
            language
          ).format();

          toast.error(errorMsg, {
            autoClose: 4500,
            showCloseButton: true,
            position: 'bottom-center'
          });

          return of({
            type: 'auth/sign-in-fail',
            error: errorResponse.response
              ? errorResponse.response.error
              : errorResponse.message
          });
        })
      );
    })
  );
