const initialState = {
  data: [],
  status: 'idle'
};

export default function massActionsList(state = initialState, action) {
  switch (action.type) {
    case 'project-responses-mass-actions-list/fetch':
      return {
        ...state,
        status: 'loading'
      };

    case 'project-responses-mass-actions-list/fetch-fail':
      return {
        ...state,
        status: 'idle'
      };

    case 'project-responses-mass-actions-list/fetch-success':
      return {
        ...state,
        data: action.data,
        status: 'idle'
      };
    default:
      return state;
  }
}
