import revertDictionary from 'utils/revertDictionary';

const dictionary = {
  'project.panels.accessSettings.actions.addSettings': {
    ru: 'Добавить настройки',
    en: 'Add settings'
  },
  'project.panels.accessSettings.title': {
    ru: 'Настройки доступа',
    en: 'Access settings'
  },
  'accessSettingsPanel.table.columns.title': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'accessSettingsPanel.table.columns.isDefault': {
    en: 'Use by default',
    ru: 'Использовать по умолчанию'
  }
};

export default revertDictionary(dictionary);
