import fp from 'lodash/fp';
import parseISO from 'date-fns/parseISO';
import { camelizeKeys } from 'humps';
import type { IUpload } from 'flow-types/entities/Upload';
import uploadsNormalizer from './normalizers';

const timeStartSortFn = (record: IUpload) => {
  const { timeStart } = record;

  if (!timeStart) return Number.MAX_SAFE_INTEGER;

  return parseISO(timeStart);
};

// Can be used in cases, when uploads had already been normalized
// by uploadsNormalizer.
//
// The main difference from default export is that postNormalizer
// will not run uploadNormalizer
export const postNormalizer = fp.compose(
  fp.sortBy(timeStartSortFn),
  fp.filter({
    isAudio: true
  })
);

export default fp.compose(
  fp.sortBy(timeStartSortFn),
  uploadsNormalizer,
  fp.filter({
    isAudio: true
  }),
  camelizeKeys
);
