// @flow

import { combineReducers } from 'redux';

import companiesReducer from './companies';
import departmentsReducer from './departments';
import usersReducer from './users';

export default combineReducers({
  companiesList: companiesReducer,
  departmentsList: departmentsReducer,
  usersList: usersReducer
});
