// @flow

import { camelizeKeys } from 'humps';
import fp from 'lodash/fp';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import parseISO from 'date-fns/parseISO';
import { v4 as uuid } from 'uuid';

import { TYPES, getInheritedPropForQuestion } from 'common/helpers/question';

import type { IQuestion } from 'flow-types/entities/Question';
import type { IQuestionOption } from 'flow-types/entities/QuestionOption';

import optionsNormalizer from '../questionOptionsNormalizer';
import sortingItemsNormalizer from '../../sortingItemsNormalizer';

/**
 * Anything here is treated as IQuestion whatever is passed here in fact.
 */
type IQuestionLike = $Shape<IQuestion>;

// creates question.excludingOptions field
export const processQuestionExcludingOptions = (
  question: IQuestionLike
): IQuestionLike => {
  const { options } = question;

  const getter = fp.compose(
    fp.map(i => i.id),
    fp.filter({ isExcluding: true })
  );

  const excludingOptions = getter(options);

  return {
    ...question,
    excludingOptions
  };
};

// creates question.inherits field
export const processInheritsForQuestion = (question: IQuestionLike) => ({
  ...question,
  inherits: getInheritedPropForQuestion(question)
});

/**
 * TODO: find where it is used and move this transducer in there.
 * Creates question.submittable field.
 */
export const processIsSubmittableQuestion = (
  question: IQuestionLike
): IQuestionLike =>
  // $FlowIgnore
  ({
    ...question,
    submittable: ![TYPES.HTML, TYPES.URL, TYPES.Video].includes(question.type)
  });

export const processQuestionDefaultAnswer = (question: IQuestionLike) => {
  const { type, defaultAnswer } = question;

  if (!defaultAnswer || type !== TYPES.DateTime) return question;

  return {
    ...question,
    defaultAnswer: parseISO(defaultAnswer)
  };
};

// creates question.defaultOptions field
export const processQuestionDefaultOptions = (
  question: IQuestionLike
): IQuestionLike => {
  const { options, ...rest } = question;

  const defaultOptions = reduce(
    options,
    (lastResult, option: IQuestionOption) => {
      if (!option.isDefault) {
        return lastResult;
      }
      return [...lastResult, option.id];
    },
    []
  );

  return {
    ...rest,
    options,
    defaultOptions
  };
};

// created mappedQuestions
export const mapQuestionOptions = (question: IQuestionLike): IQuestionLike => {
  const { options } = question;

  // $FlowIgnore
  return {
    ...question,
    mappedOptions: reduce(
      options,
      (result, option, index) => ({
        ...result,
        [option.id]: index
      }),
      {}
    )
  };
};

// runs normalizeOptions on question's options
export const normalizeQuestionOptions = (
  question: IQuestionLike
): IQuestionLike => {
  const { options } = question;

  const normalized = optionsNormalizer(options || []);

  return {
    ...question,
    options: normalized
  };
};

// runs normalizeQuestions on question's subQuestions
export const normalizeQuestionSubQuestions = (question: IQuestionLike) => {
  const { subQuestions } = question;

  let normalized = map(subQuestions, subQuestion => ({
    ...subQuestion,
    questionGroupId: question.questionGroupId
  }));

  // eslint-disable-next-line no-use-before-define
  normalized = normalizeQuestions(normalized);

  return {
    ...question,
    subQuestions: normalized
  };
};

/**
 * Processes image for question.
 * Also calculates ratio for {@link IQuestion.image} in {@link IQuestion.image.imageInfo}.
 */
const processImageForQuestion = (question: IQuestionLike): IQuestionLike => {
  const next = { ...question };

  if (!next.image || (Array.isArray(next.image) && next.image.length === 0)) {
    next.image = null;
  } else {
    const {
      image: {
        imageInfo: { width, height }
      }
    } = next;

    next.image.imageInfo.ratio = width / height;
  }

  return next;
};

/**
 * Processes {@link IQuestionSettings.labels} so,
 * that in there {@link LabelSettings.localId} can be found.
 */
export const processQuestionsSliderLabels = (question: IQuestionLike) => {
  const labelsSettings = question?.settings?.labels;

  if (!labelsSettings) {
    return question;
  }

  return {
    ...question,
    settings: {
      ...question.settings,
      // Case below is covered in condition above.
      // $FlowIgnore
      labels: reduce(
        labelsSettings,
        (results, label) => {
          const localId = uuid();
          return {
            ...results,
            [localId]: {
              color: null,
              ...label,
              localId
            }
          };
        },
        {}
      )
    }
  };
};

export const processPollingFields = (
  question: IQuestionLike
): IQuestionLike => {
  if (!question.settings?.pollingInterval) return question;

  let { hours, minutes, seconds } = question.settings.pollingInterval;

  hours = +hours;
  minutes = +minutes;
  seconds = +seconds;

  let total = 0;

  if (hours) {
    total += hours * 60 * 60;
  }

  if (minutes) {
    total += minutes * 60;
  }

  if (seconds) {
    total += seconds;
  }

  return {
    ...question,
    settings: {
      ...question.settings,
      pollingInterval: {
        hours,
        minutes,
        seconds,
        total
      }
    }
  };
};

const processQuestionVideo = (question: IQuestionLike): IQuestionLike => {
  const { video, videoToken } = question;

  if (!videoToken) {
    return question;
  }

  return {
    ...question,
    video: {
      ...video,
      uploadToken: videoToken,
      isVideo: true
    }
  };
};

export const normalizeQuestion: Function = fp.compose(
  processQuestionVideo,
  processQuestionsSliderLabels,
  processPollingFields,
  processImageForQuestion,
  processInheritsForQuestion,
  processIsSubmittableQuestion,
  normalizeQuestionSubQuestions,
  processQuestionDefaultAnswer,
  processQuestionDefaultOptions,
  processQuestionExcludingOptions,
  mapQuestionOptions,
  normalizeQuestionOptions
);

export const camelizeAndNormalizeQuestion: Function = fp.compose(
  normalizeQuestion,
  camelizeKeys
);

export const normalizeQuestions: Function = fp.compose(
  fp.map(camelizeAndNormalizeQuestion),
  sortingItemsNormalizer
);
