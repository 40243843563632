import omit from 'lodash/omit';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';

import isNewElement from 'common/helpers/isNewElement';

import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import type { IQuestion } from 'flow-types/entities/Question';

export const isGroupUpdated = (
  original: IQuestionGroup,
  updated: IQuestionGroup
) => {
  if (isNewElement(updated)) return true;

  return (
    original &&
    !isEqual(omit(original, 'questions'), omit(updated, 'questions'))
  );
};

const isOptionUpdated = (original, updated) => {
  if (isNewElement(updated)) return true;

  return (
    original &&
    !isEqual(
      omit(original, 'questionGroupId'),
      omit(updated, 'questionGroupId')
    )
  );
};

export const isQuestionUpdated = (original: IQuestion, updated: IQuestion) => {
  if (isNewElement(updated)) {
    return true;
  }

  if (original) {
    return (
      !isEqual(omit(original, 'options'), omit(updated, 'options')) ||
      some(updated.options, opt => {
        if (isNewElement(opt)) {
          return true;
        }
        return isOptionUpdated(opt.cache, omit(opt, 'cache'));
      })
    );
  }

  return some(updated.options, opt =>
    isOptionUpdated(opt.cache, omit(opt, 'cache'))
  );
};
