// @flow
import fp from 'lodash/fp';
import reduce from 'lodash/reduce';

import type {
  IFormattedLogic,
  IFormattedLogicItem,
  IFormattedLogicOperand
} from 'flow-types/entities/Logic';

export const getLogicTestsSources = tests =>
  reduce(
    tests,
    (
      result,
      current:
        | IFormattedLogicItem
        | Array<IFormattedLogicItem | IFormattedLogicOperand>
    ) => {
      if (typeof current === 'string') return result;

      if (!Array.isArray(current)) {
        return [...result, current.questionId];
      }
      const ids = getLogicTestsSources(current);
      return [...result, ...ids];
    },
    []
  );

export const getLogicRuleSources = (rule: IFormattedLogic) => {
  const { tests } = rule;
  return getLogicTestsSources(tests);
};

export const getLogicRulesSources = rules =>
  fp.compose(fp.uniq, fp.flattenDeep, fp.map(getLogicRuleSources))(rules);
