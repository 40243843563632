import { decamelize } from 'humps';

// it works with decamelizing File via humps
const decamelizeKeys = object => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelizeKeys(item));
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [decamelize(next)]: decamelizeKeys(object[next])
        }),
        {}
      );
    }
  }
  return object;
};

export default decamelizeKeys;
