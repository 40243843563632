// @flow
import fp from 'lodash/fp';
import map from 'lodash/map';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import { camelizeKeys } from 'humps';
import type {
  ICompanyAdmin,
  IDivisionHead,
  IManager,
  IOperator,
  IUser
} from '../../../flow-types/entities/User';
import { DIVISION_HEAD, MANAGER } from '../../helpers/user';

type UserType = IUser | ICompanyAdmin | IDivisionHead | IManager | IOperator;

const userCompaniesTreeNormalizer = (user: UserType) => {
  const { companies, departments } = user;

  return {
    ...user,
    companiesTree: map(companies, company => {
      const { id } = company;
      const departmentsForCompany = filter(
        departments,
        d => d.companyId === id
      );
      return {
        ...company,
        departments: map(departmentsForCompany, companyDepartment => ({
          value: companyDepartment.id,
          label: companyDepartment.title
        }))
      };
    })
  };
};

const userDepartmentNormalizer = (user: UserType) => {
  const { department, departmentId } = user;

  return {
    ...user,
    departmentId: departmentId || (department && department.id)
  };
};

const userCompanyNormalizer = (user: UserType) => {
  const { company, companyId } = user;

  return {
    ...user,
    companyId: companyId || (company && company.id)
  };
};

const userDepartmentsNormalizer = (user: UserType) => {
  const { departments } = user;

  return {
    ...user,
    departmentsIds: map(departments, d => d.id)
  };
};

const userCompaniesNormalizer = (user: UserType) => {
  const { companies } = user;

  return {
    ...user,
    companiesIds: map(companies, c => c.id)
  };
};

const userEmployeeNormalizer = fp.compose(
  userCompanyNormalizer,
  userDepartmentNormalizer
);

const userDivisionHeadNormalizer = fp.compose(
  userCompanyNormalizer,
  userDepartmentsNormalizer
);

const userManagerNormalizer = fp.compose(
  userCompaniesTreeNormalizer,
  userDepartmentsNormalizer,
  userCompaniesNormalizer
);

export const normalizeUserProjectsAccessSettings = (user: IUser) => {
  if (!user.projectsAccessSettings) return user;

  const nextUser = { ...user };

  const keyedSettings = keyBy(nextUser.projectsAccessSettings, 'projectId');

  nextUser.projectsAccessSettings = keyedSettings;

  return nextUser;
};

const userRoleSpecificNormalizer = (user: UserType) => {
  if (user.roleId === MANAGER) {
    return userManagerNormalizer(user);
  }

  if (user.roleId === DIVISION_HEAD) {
    return userDivisionHeadNormalizer(user);
  }

  return userEmployeeNormalizer(user);
};

export const normalizeUser: $Call<IUser> = fp.compose(
  normalizeUserProjectsAccessSettings,
  userRoleSpecificNormalizer,
  camelizeKeys
);

const usersNormalizer = fp.map(normalizeUser);

export default usersNormalizer;
