// @flow

import type { Epic } from 'flow-types/Epic';
import * as RxO from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';

import type {
  SavePollingNotification,
  SavePollingNotificationFail,
  SavePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/SavePollingNotification';
import type { PollingNotificationsState } from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import responseParser from 'common/epicHelpers/responseParser';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import pollingNotificationValidator from 'common/validators/validatePollingNotification';
import isNewElement from 'common/helpers/isNewElement';
import { decamelizeAndDenormalizePollingNotification } from 'common/transducers/projects/pollingNotifications/denormalize';
import { camelizeAndNormalizePollingNotification } from 'common/transducers/projects/pollingNotifications/normalize';
import type { IPollingNotification } from 'flow-types/entities/PollingNotification';
import type { FetchPollingNotifications } from 'flow-types/actions/projects/detail/pollingNotifications/list/FetchPollingNotifications';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import parseYupValidation from 'common/transducers/parseYupValidation';
import type { AppState } from 'flow-types/AppState';
import {
  projectIdFromPathSelector,
  projectPollingNotificationsStateSelector
} from '../../../../../selectors/projects';
import {
  SAVE_FAIL,
  SAVE_SUCCESS,
  SET_VALIDATION
} from '../../../../../reducers/projectDetail/pollingNotifications/detail';
import { FETCH_LIST } from '../../../../../reducers/projectDetail/pollingNotifications/list';
import { MODAL_ID } from '../../../components/Modals/PollingNotificationModal';
import closeModal from '../../../../../actions/modals/close';
import type { CloseModal } from '../../../../Modals/modals';

const savePollingNotification$: Epic = (action$, state$) =>
  action$.pipe<SavePollingNotification>(
    ofType('project/save-polling-notification'),
    RxO.withLatestFrom(state$),
    RxO.exhaustMap<[SavePollingNotification, AppState]>(([, state]) => {
      const projectId = projectIdFromPathSelector(state);

      const {
        detail: { data }
      }: PollingNotificationsState = projectPollingNotificationsStateSelector(
        state
      );

      return from(
        pollingNotificationValidator.validate(data, { abortEarly: false })
      ).pipe(
        RxO.mergeMap(() => {
          const isUpdate = !isNewElement(data);

          return request({
            url: isUpdate
              ? interpolateString(API.projects.pollingNotifications.detail, {
                  projectId,
                  // $FlowIgnore
                  notificationId: data.id
                })
              : interpolateString(API.projects.pollingNotifications.list, {
                  projectId
                }),
            method: isUpdate ? 'PUT' : 'POST',
            body: decamelizeAndDenormalizePollingNotification(data)
          }).pipe(
            responseParser,
            RxO.pluck('data'),
            RxO.map(camelizeAndNormalizePollingNotification),
            RxO.mergeMap<IPollingNotification>((nextData): [
              FetchPollingNotifications,
              SavePollingNotificationSuccess,
              CloseModal
            ] => [
              { type: FETCH_LIST },
              {
                type: SAVE_SUCCESS,
                data: nextData
              },
              closeModal(MODAL_ID)
            ]),
            RxO.catchError<any, [SavePollingNotificationFail]>(
              (ajaxError: AjaxError<any>) => [
                {
                  type: SAVE_FAIL,
                  errors: ajaxError.response
                    ? ajaxError.response.data
                    : ajaxError.message
                }
              ]
            )
          );
        }),
        RxO.catchError(error => [
          {
            type: SAVE_FAIL,
            errors: null
          },
          {
            type: SET_VALIDATION,
            validation: parseYupValidation(error, { structured: true })
          }
        ])
      );
    })
  );

export default savePollingNotification$;
