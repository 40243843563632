import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import defaults from 'lodash/defaults';

import type { NilValue } from 'flow-types/NilValue';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';
import takeSecondArg from 'common/helpers/takeSecondArg';
import { projectGroupsStateSelector } from '../../../selectors/projects';

type UseGroupsFnArgs = {|
  keyedBy?: string | null,
  refactorFn?: NilValue | ((items: IQuestionGroup[]) => mixed[])
|};

const selectorFactory = () =>
  createSelector(
    projectGroupsStateSelector,
    takeSecondArg,
    ({ data: groups }, options: UseGroupsFnArgs) => {
      if (!options) return groups;

      let result = [...groups];

      if (options.keyedBy) {
        result = keyBy(result, options.keyedBy);
      }

      return options.refactorFn ? options.refactorFn(result) : result;
    }
  );
const initialOptions: UseGroupsFnArgs = { keyedBy: null, refactorFn: null };

export function useProjectGroups(params: UseGroupsFnArgs = initialOptions) {
  const options = useMemo(() => defaults(params, initialOptions), [params]);

  const selector = useMemo(selectorFactory, []);

  return useSelector(state => selector(state, options));
}
