// @flow
// this thunk will be executed if token exist
import { camelizeKeys } from 'humps';
import { redirect } from 'redux-first-router';
import type { Thunk } from '../../flow-types/router/Thunk';
import { authStateSelector } from '../../selectors';
import request from '../../utils/request';
import { API } from '../../utils/config';
import { SCOPE } from '../../reducers/auth';
import { removeFirstPriorityToken } from '../../utils/removeToken';

const signInWithTokenThunk: Thunk = async (dispatch, getState) => {
  const state = getState();
  const { isLoggedIn } = authStateSelector(state);

  if (isLoggedIn) return Promise.resolve(false);

  try {
    const userResponse = await request({
      url: API.users.self,
      method: 'GET'
    }).toPromise();

    const {
      response: { data }
    } = userResponse;

    dispatch({
      type: 'auth/sign-in-success',
      user: camelizeKeys(data),
      // TODO: check it later,
      //  may be we can use it to control page visibility
      //  for different users
      scope: SCOPE.ALL
    });

    return Promise.resolve(true);
  } catch (e) {
    removeFirstPriorityToken();
    dispatch({ type: 'auth/sign-in-fail' });
    dispatch(redirect({ type: 'router/loginPage' }));
    return Promise.resolve(false);
  }
};

export default signInWithTokenThunk;
