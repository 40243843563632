// @flow

import { merge } from 'merge-anything';

import { LOGIC_OPERAND, LOGIC_OPERATOR } from 'common/helpers/logic/constants';

import type { IQuestion } from 'flow-types/entities/Question';

import { generateNewData } from './generateNewData';

/**
 * Available list of block types, that is used across the app.
 */
export const TYPES = Object.freeze({
  /**
   * Just HTML block
   */
  HTML: 1,
  /**
   * Block that allows to visit URL
   */
  URL: 2,
  /**
   * Block, that allows to select one value from list of values
   */
  SingleAnswer: 3,
  /**
   * Block, that allows to select multiple value from list of values
   */
  MultipleAnswer: 4,
  /**
   * Block, that allows to enter some text
   */
  TextBlock: 5,
  /**
   * Block, that requires to enter email
   */
  Email: 6,
  /**
   * Block, that requires to enter mobile phone
   */
  MobilePhone: 7,
  SecondaryPhones: 8,
  /**
   * The same as {@link TYPES.MultipleAnswer}
   */
  Checklist: 9,
  /**
   * Block, that displays video content
   */
  Video: 10,
  /**
   * Block, that allow to select data w/time
   * or data range (including time)
   */
  DateTime: 11,
  /**
   * Table-like block.
   * It can contain a list of either {@link TYPES.SingleAnswer} or {@link TYPES.MultipleAnswer}
   */
  Table: 12,
  /**
   * Redirection block,
   * will redirect to a specified URL
   */
  Redirect: 13,

  /**
   * Block, that requires to enter number
   */
  Numeric: 14,

  // begin - crm project
  // new question types used in the CRM project
  SearchView: 15,
  TabView: 16,
  // end - crm project

  /**
   * List of draggable options
   */
  Ranging: 17,
  /**
   * Block with shapes or slider from 1 to X
   */
  Rating: 18,
  /**
   * Personal Signature Block
   */
  Signature: 19,

  /**
   * Status block
   */
  Status: 20
});

/**
 * A list of types that requires user to select something from a set of available items
 * @type {(number)[]}
 */
export const SELECTABLE_TYPES = [
  TYPES.SingleAnswer,
  TYPES.MultipleAnswer,
  TYPES.Checklist,
  TYPES.Status
];

/**
 * Available list of view types that can be used in different block types from {@link TYPES}
 * If some block needs it, then add it here.
 */
export const VIEW_TYPES: { [string]: string[] } = Object.freeze({
  [TYPES.Numeric]: ['standard', 'slider'],
  [TYPES.Rating]: ['list', 'slider'],
  [TYPES.MultipleAnswer]: ['list', 'select'],
  [TYPES.SingleAnswer]: ['list', 'select'],
  [TYPES.Checklist]: ['list', 'select'],
  [TYPES.Status]: ['list', 'select']
});

export const TYPES_WITH_INSTANT_NAVIGATION_SUPPORT = [
  TYPES.SingleAnswer,
  TYPES.MultipleAnswer,
  TYPES.Checklist,
  TYPES.Rating
];

export const TABLE_QUESTION_VIEW_TYPES = {
  SINGLE: 'single',
  TABLE: 'table'
};

/**
 * Available icons that can be used inside rating block
 */
export const RATING_ICONS_SETS = ['star', 'user'];

export const getQuestionTypeLocaleMessageId = type => {
  switch (type) {
    case TYPES.HTML:
      return 'question.type.html';
    case TYPES.URL:
      return 'question.type.url';
    case TYPES.SingleAnswer:
      return 'question.type.single';
    case TYPES.MultipleAnswer:
      return 'question.type.multiple';
    case TYPES.Email:
      return 'question.type.email';
    case TYPES.MobilePhone:
      return 'question.type.phone';
    case TYPES.SecondaryPhones:
      return 'question.type.secondaryPhones';
    case TYPES.Checklist:
      return 'question.type.checklist';
    case TYPES.Video:
      return 'question.type.video';
    case TYPES.DateTime:
      return 'question.type.dateTime';
    case TYPES.Table:
      return 'question.type.table';
    case TYPES.Redirect:
      return 'question.type.redirect';
    case TYPES.Numeric:
      return 'question.type.numeric';
    case TYPES.Ranging:
      return 'question.type.ranging';
    case TYPES.Rating:
      return 'question.type.rating';
    case TYPES.Signature:
      return 'question.type.signature';
    case TYPES.Status:
      return 'question.type.status';
    case TYPES.SearchView:
      return 'crm.view.type.search';
    case TYPES.TabView:
      return 'crm.view.type.tab';
    case TYPES.TextBlock:
    default:
      return 'question.type.text';
  }
};

export const getByType = (
  questions: IQuestion[] = [],
  types: number[] = []
): IQuestion[] => {
  if (!questions) return [];
  return questions.filter(question => types.includes(question.type));
};

export const getInheritedPropForQuestion = (
  question?: IQuestion
): null | string => {
  if (!question || !question.optionsFrom) return null;

  if (SELECTABLE_TYPES.includes(question.type)) {
    return 'options';
  }

  if (question.type === TYPES.Table) {
    return 'subQuestions';
  }

  return null;
};

// sets up inheritance between two questions, where target is a question,
// that inherits something from source
export const setQuestionInheritance = (
  source: IQuestion,
  target: IQuestion
): IQuestion => {
  /**
   * SA - Single Answer
   * MA - Multi Answer
   * TQ - Table Question
   *
   * Each of above defined types is able to make inheritance with each other.
   * In cases when SA/MA inherits from TQ, sub-questions of the latter becomes options for the former
   * In cases when TQ inherits from SA/MA, options of the latter becomes sub-questions for the former
   * In cases when TQ inherits from TQ, sub-questions becomes sub-questions
   * In cases when SA/MA inherits from SA/MA, options becomes options
   *
   * Inherited properties are disabled for edit operation.
   */

  let settings = {
    unionType: LOGIC_OPERAND.OR,
    operator: LOGIC_OPERATOR.IN,
    ...target.settings
  };

  if (source.id !== target.optionsFrom) {
    settings = {
      ...settings,
      options: []
    };
  }

  if (
    source.type === TYPES.SingleAnswer &&
    settings.unionType === LOGIC_OPERAND.AND
  ) {
    settings = {
      ...settings,
      unionType: LOGIC_OPERAND.OR
    };
  }

  const inherits = getInheritedPropForQuestion({
    ...target,
    optionsFrom: source.id
  });

  return {
    ...target,
    requiredSaveFor: [
      inherits,
      ...((target.requiredSaveFor && target.requiredSaveFor) || [])
    ].filter(Boolean),
    optionsFrom: source.id,
    // TODO: fix it later
    // $FlowIgnore
    inherits,
    // TODO: fix it later
    // $FlowIgnore
    settings
  };
};

/**
 * Generates new question data
 */
export const generateNewQuestion = (
  initialData?: $Shape<IQuestion>
): $Shape<IQuestion> => {
  // $FlowIgnore
  const questionInitialData: $Shape<IQuestion> = {
    options: [],
    fieldWidth: 300,
    fieldHeight: 150,
    description: '',
    isInterval: false,
    withPoints: false,
    showLabels: false,
    showImages: false,
    useForSms: false,
    useForEmail: false,
    dateOnly: true,
    commentsEditable: false,
    commentsEnabled: false,
    filesEnabled: false,
    inProfile: false,
    required: false,
    otherEnabled: false,
    title: '',
    otherTitle: null,
    showTitle: true,
    unloaded: true,
    editable: true,
    pollingEnabled: false,
    pollingMode: null,
    pollingInterval: {
      hours: null,
      minutes: null,
      seconds: null
    },
    settings: {
      viewType: 'single',
      type: TYPES.SingleAnswer,
      dynamicOptionsSorting: null,
      instantNagivation: true,
      labels: null,
      appearance: {
        backgroundColor: null,
        textColor: null
      }
    }
  };

  return generateNewData(merge(questionInitialData, { ...initialData }), true);
};

/**
 * Checks whether question type allows selection
 * @param type - type of question
 * @param subType - type of subQuestions [for {@link TYPES.Table}]
 * @return {boolean}
 */
export const isSelectable = (type?: number, subType?: number): boolean =>
  type !== TYPES.Table
    ? SELECTABLE_TYPES.includes(type)
    : isSelectable(subType);

/**
 * Checks whether question type allows multi selection
 * @param type - type of question
 * @param subType - type of subQuestions [for {@link TYPES.Table}]
 * @return {boolean}
 */
export const isMultiSelectable = (
  type?: number,
  subType?: number
): boolean %checks =>
  type !== TYPES.Table
    ? // $FlowFixMe
      [TYPES.MultipleAnswer, TYPES.Checklist].includes(type)
    : isMultiSelectable(subType);

/**
 * Checks whether question types is using options
 * @param type
 * @return {boolean}
 */
export const isOptionsRelated = (type: number): boolean %checks =>
  // TODO: try to find out why flow tells about error here?
  // $FlowFixMe
  [...SELECTABLE_TYPES, TYPES.Table, TYPES.Ranging].includes(type);

/**
 * Returns unified type, describing what data type block-born variable will return.
 * This way we can understand how to operate with data stored or returned by a block,
 * or that is represented in a corresponding variable of a block.
 */
export const convertBlockTypeToJSType = (blockType: number): string | null => {
  switch (blockType) {
    case TYPES.MobilePhone:
    case TYPES.Email:
    case TYPES.TextBlock:
    case TYPES.SecondaryPhones:
      return 'string';

    case TYPES.Numeric:
    case TYPES.Rating:
      return 'number';

    case TYPES.SingleAnswer:
      return 'option';

    case TYPES.MultipleAnswer:
    case TYPES.Checklist:
    case TYPES.Ranging:
      return 'options';

    case TYPES.DateTime:
      return 'date';

    default:
      return null;
  }
};

export const DYNAMIC_OPTIONS_SORTING_MODES = {
  ALL: 'all',
  EXCEPT_EXCLUDING: 'except_excluding',
  ONLY_EXCLUDING: 'only_excluding'
};
