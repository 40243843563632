import { combineEpics } from 'redux-observable';
import prepareInterview from './prepareInterview';
import createInterviewRecord from './createInterviewRecord';
import move from './move';
import restartInterview from './restartInterview';
import {
  completeInterviewEpic,
  retryCompleteInterviewEpic
} from './completeInterview';
import makeInitialMoveOnPrepareComplete from './makeInitialMoveOnPrepareComplete';
import startOnInitialMove from './startOnInitialMove';
import processLogicOnAnswerChangeEpic from './processLogicOnAnswerChange';
import refreshAnswerValidationOnActiveStackChange from './refreshAnswerValidationOnActiveStackChange';
import submitAnswers from './submitAnswers';
import processComputedProperties from './computedVariables';
import syncRegistry from './syncRegistry';
import preloadAssets from './preloadAssets';
import applyMovementRestrictionsOnStackChangeEpic from './applyRestrictionsOnStackChange';

export default combineEpics(
  createInterviewRecord,
  restartInterview,
  makeInitialMoveOnPrepareComplete,
  prepareInterview,
  preloadAssets,
  submitAnswers,
  // As soon as computed properties can be in theory used in logic,
  // it is important to process them
  // before logic processing is even started.
  processComputedProperties,
  startOnInitialMove,
  processLogicOnAnswerChangeEpic,
  refreshAnswerValidationOnActiveStackChange,
  syncRegistry,
  move,
  applyMovementRestrictionsOnStackChangeEpic,
  completeInterviewEpic,
  retryCompleteInterviewEpic
);
