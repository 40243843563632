// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';

import type { Epic } from 'flow-types/Epic';
import type {
  TogglePollingNotification,
  TogglePollingNotificationFail,
  TogglePollingNotificationSuccess
} from 'flow-types/actions/projects/detail/pollingNotifications/detail/TogglePollingNotification';
import withLatestSelectors from 'common/epicHelpers/withLatestSelectors';
import type { PollingNotificationsState } from 'flow-types/states/ProjectsState/detail/PollingNotificationsState';
import { createSelector } from 'reselect';
import { API } from 'utils/config';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';
import { POLLING_NOTIFICATION_STATUS } from 'common/helpers/project/constants';
import type { AppState } from 'flow-types/AppState';
import {
  projectIdFromPathSelector,
  projectPollingNotificationsStateSelector
} from '../../../../../selectors/projects';

const notificationIdSelector: Function = createSelector(
  projectPollingNotificationsStateSelector,
  (pollingNotificationsState: PollingNotificationsState) => {
    if (pollingNotificationsState.detail.data) {
      return pollingNotificationsState.detail.data.id;
    }

    return null;
  }
);

const togglePollingNotification$: Epic = (action$, state$) =>
  action$.pipe<TogglePollingNotification>(
    ofType('project/toggle-polling-notification'),
    RxO.withLatestFrom(state$),
    withLatestSelectors([projectIdFromPathSelector, notificationIdSelector]),
    RxO.exhaustMap<
      [TogglePollingNotification, AppState, [number, number]],
      any,
      any
    >(([action, , [projectId, notificationId]]) => {
      const { status } = action;

      const URL =
        status === POLLING_NOTIFICATION_STATUS.ACTIVE
          ? API.projects.pollingNotifications.activate
          : API.projects.pollingNotifications.deactivate;

      return request({
        url: interpolateString(URL, { projectId, notificationId }),
        method: 'POST'
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        // RxO.map(normalizePollingNotification),
        RxO.mergeMap<any, any, any>((): [TogglePollingNotificationSuccess] => [
          {
            type: 'project/toggle-polling-notification-success',
            status
          }
        ]),
        RxO.catchError((error): [TogglePollingNotificationFail] => [
          {
            type: 'project/toggle-polling-notification-fail',
            error: error.response.message,
            status
          }
        ])
      );
    })
  );

export default togglePollingNotification$;
