// @flow

import type {
  SaveResponseComment,
  SaveResponseCommentFail,
  SaveResponseCommentSuccess
} from '../../../flow-types/actions/projects/detail/response/SaveResponseComment';

export function saveResponseComment(): SaveResponseComment {
  return {
    type: 'project/save-response-comment'
  };
}

export function saveResponseCommentFail(): SaveResponseCommentFail {
  return {
    type: 'project/save-response-comment-fail'
  };
}

export function saveResponseCommentSuccess(): SaveResponseCommentSuccess {
  return {
    type: 'project/save-response-comment-success'
  };
}
