// @flow

import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps } from 'react-yandex-maps';

import { createSelector } from 'reselect';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';
import 'rc-slider/assets/index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import './assets/filepond-plugin-get-file-patched/filepond-plugin-get-file.css';

import type { AppDispatch } from 'flow-types/AppDispatch';
import type { AuthState } from 'flow-types/states/AuthState';
import type { PageState } from 'flow-types/states/PageState';

import PageLoader from 'common/components/PageLoader';
import useDidMount from 'common/hooks/useDidMount';
import isDevelopment from 'utils/isDevelopment';
import { YANDEX_MAPS_API_KEY } from 'utils/config';
import { authStateSelector, pageStateSelector } from './selectors';

import * as pages from './pages';
import AppIntlProvider from './common/components/IntlProvider';

import hasAccessToRoute from './common/helpers/hasAccessToRoute';

import SidebarProvider from './common/containers/Sidebar/context';
import AppSizeSpy from './common/containers/AppSizeSpy';
import { queryClient } from './QueryClient';

type SelectedProps = {|
  +page: PageState,
  +dispatch: AppDispatch,
  +isLoggedIn: null | boolean,
  +scope: string
|};

const selector = createSelector(
  pageStateSelector,
  authStateSelector,
  (page, { isLoggedIn, user, scope }: AuthState) => ({
    page,
    isLoggedIn,
    user,
    scope
  })
);

function App(): React.Node {
  const dispatch = useDispatch();

  const { page, isLoggedIn, scope }: SelectedProps = useSelector(selector);

  useDidMount(() => {
    dispatch({
      type: 'initial-start'
    });
  });

  const PageComponent = pages?.[page.name] ?? null;

  let canViewPages = isLoggedIn !== null;

  if (!page.name) {
    canViewPages = false;
  } else if (page.public || page.forced) {
    canViewPages = true;
  }

  React.useLayoutEffect(() => {
    if (isLoggedIn && !hasAccessToRoute(scope, page.key)) {
      dispatch({ type: 'router/restricted' });
    }
    // we do it only when scope has changed,
    // because 'on route change' check happens in onBeforeChange
    // eslint-disable-next-line
  }, [scope]);

  const query = React.useRef({
    apikey: YANDEX_MAPS_API_KEY,
    mode: isDevelopment ? 'debug' : 'release'
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AppIntlProvider>
        <YMaps query={query.current}>
          {canViewPages ? (
            <SidebarProvider>
              {!!PageComponent && <PageComponent />}
            </SidebarProvider>
          ) : (
            <PageLoader fillPage />
          )}
        </YMaps>
      </AppIntlProvider>
      <AppSizeSpy />
      {isDevelopment ? <ReactQueryDevtools initialIsOpen /> : null}
    </QueryClientProvider>
  );
}

export default App;
