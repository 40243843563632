// @flow

import { merge } from 'rxjs';
import * as RxOp from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type { Epic } from 'flow-types/Epic';
import unsavedChangesCheckProcess from './subProcesses/unsavedChangesCheck';

const editElement: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-groups/edit-element-process'),
    // on each call we will wait for prev to be completed
    RxOp.concatMap(action => {
      const cancel$ = action$.pipe(
        ofType('project-groups/unsaved-changes-check-fail')
      );
      return merge(
        action$.pipe(
          ofType('project-groups/unsaved-changes-check-success'),
          RxOp.map(() => {
            if (action.questionId) {
              return {
                ...action,
                type: 'project-groups/select-question'
              };
            }
            return {
              ...action,
              type: 'project-groups/select-group'
            };
          }),
          RxOp.take(1),
          RxOp.takeUntil(cancel$)
        ),
        unsavedChangesCheckProcess(action$, state$)
      );
    })
  );

export default editElement;
