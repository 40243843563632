export function getRatio(width: number, height: number): number {
  return width / height;
}

export function getWidthForRatio(height: number, ratio: number): number {
  return height * ratio;
}

export function getHeightForRatio(
  width: number,
  ratio: number,
  maxHeight: number
): number {
  const result = width / ratio;

  if (!!maxHeight && result > maxHeight) {
    return maxHeight;
  }

  return result;
}
