// @flow

import type { SetLogicFormValidation } from '../../../flow-types/actions/projects/detail/logic/SetLogicFormValidation';

export default function setLogicFormValidationCreator(
  validation
): SetLogicFormValidation {
  return {
    type: 'project/set-logic-form-validation',
    validation
  };
}
