// @flow

import type { StateSelector } from 'flow-types/Selector';
import { createSelector } from 'reselect';
import type { InterviewRegistryState } from 'flow-types/states/InterviewState/InterviewRegistryState';
import filter from 'lodash/filter';
import map from 'lodash/map';
import type { IInterviewProject } from 'flow-types/entities/Project';
import type { ILogicResults } from 'flow-types/entities/LogicResults';
import type {
  IInterviewQuestion,
  IQuestion
} from 'flow-types/entities/Question';
import { INTERVIEW_MODE } from 'common/helpers/interview/constants';
import { flatGroupsQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import includes from 'lodash/includes';
import { TYPES } from 'common/helpers/question';
import hideItemsById, { hideItemsBy } from 'common/helpers/hideItems';
import disableItemsById, {
  disableAll,
  disableItemsBy
} from 'common/helpers/disableItems';
import { interviewStatusesSelector } from './statuses';
import {
  interviewProjectDataSelector,
  interviewRegistrySelector
} from './root';
import { interviewLogicResultsSelector } from './logic';

export const interviewVisitedQuestionsSelector: StateSelector = createSelector(
  interviewRegistrySelector,
  (registry: InterviewRegistryState) => {
    let result = filter(registry.list, item => !!item.enter);

    result = map(result, item => item.questionId);

    return result;
  }
);

export const interviewQuestionsSelector: StateSelector<
  IInterviewQuestion[]
> = createSelector(
  [
    interviewProjectDataSelector,
    interviewLogicResultsSelector,
    interviewStatusesSelector
  ],
  (
    project: IInterviewProject,
    {
      hiddenOptions,
      hiddenQuestions,
      disabledOptions,
      disabledQuestions,
      disabledGroups,
      hiddenGroups,
      requiredQuestions
    }: ILogicResults,
    { mode }
  ): IInterviewQuestion[] => {
    if (!project) return [];

    const { groups } = project;

    const isInEditMode = mode === INTERVIEW_MODE.EDIT;

    // TODO: fix FT
    // $FlowFixMe
    let flatQuestions = flatGroupsQuestions(groups);

    flatQuestions = map(flatQuestions, (question: IQuestion) => {
      const isQuestionRequiredByLogic = includes(
        requiredQuestions,
        question.id
      );

      const questionUpdate: $Shape<IInterviewQuestion> = {};

      if (
        [
          TYPES.SingleAnswer,
          TYPES.Status,
          TYPES.MultipleAnswer,
          TYPES.Checklist,
          TYPES.Table
        ].includes(question.type)
      ) {
        // TODO: fix FT
        // $FlowFixMe
        let nextOptions = [...question.options];

        // TODO: fix FT
        // $FlowFixMe
        nextOptions = hideItemsById(nextOptions, hiddenOptions);

        // TODO: fix FT
        // $FlowIgnore
        nextOptions = disableItemsById(nextOptions, disabledOptions);

        questionUpdate.options = nextOptions;
      }

      if (question.type === TYPES.Table) {
        // TODO: fix FT
        // $FlowFixMe
        let nextSubQuestions = [...question.subQuestions];

        // TODO: fix FT
        // $FlowFixMe
        nextSubQuestions = hideItemsById(nextSubQuestions, hiddenQuestions);

        nextSubQuestions = disableItemsById(
          // TODO: fix FT
          // $FlowIgnore
          nextSubQuestions,
          disabledQuestions
        );

        if (isInEditMode && !question.editable) {
          nextSubQuestions = disableAll(nextSubQuestions);
        } else {
          nextSubQuestions = map(nextSubQuestions, subQuestion => {
            if (subQuestion.hidden || subQuestion.disabled) {
              return subQuestion;
            }

            if (
              isQuestionRequiredByLogic ||
              includes(requiredQuestions, subQuestion.id)
            ) {
              return {
                ...subQuestion,
                required: true
              };
            }

            return subQuestion;
          });
        }

        questionUpdate.subQuestions = nextSubQuestions;
      }

      if (isInEditMode) {
        questionUpdate.disabled = !question.editable;
      }

      if (isQuestionRequiredByLogic && !questionUpdate.disabled) {
        questionUpdate.required = true;
      }

      // TODO: fix FT
      // $FlowFixMe
      return {
        ...question,
        ...questionUpdate
      };
    });

    // TODO: fix FT
    // $FlowFixMe
    flatQuestions = hideItemsBy(flatQuestions, 'id', hiddenQuestions);

    // TODO: fix FT
    // $FlowFixMe
    flatQuestions = disableItemsBy(flatQuestions, 'id', disabledQuestions);

    flatQuestions = hideItemsBy(
      flatQuestions,
      'questionGroupId',
      // TODO: fix FT
      // $FlowFixMe
      hiddenGroups
    );

    flatQuestions = disableItemsBy(
      flatQuestions,
      'questionGroupId',
      // TODO: fix FT
      // $FlowFixMe
      disabledGroups
    );

    return flatQuestions;
  }
);
