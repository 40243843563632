/**
 * Returns first by priority token
 *
 * Priority if the following:
 * 1) Session
 * 2) Remembered
 *
 * @returns string
 */
function getToken(): string {
  let token;

  token = sessionStorage.getItem('authToken');

  if (!token) {
    token = localStorage.getItem('authToken');
  }

  return token;
}

export default getToken;
