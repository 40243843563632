// @flow
import * as React from 'react';
import { useFormikContext } from 'formik';
import useSideProcess from 'common/hooks/useSideProcess';
import useWillUnmount from 'common/hooks/useWillUnmount';
import useWindowBeforeUnload from 'common/hooks/useWindowBeforeUnload';

// TODO: имеется проблема, когда при unmount'е в complete'е process всё ещё не задан,
//  поэтому приходится передавать true как force аргумент в завершающую функцию.
const SideProcessBridge = ({ id }: { id: string }): null => {
  const formik = useFormikContext();

  const [process, start, complete] = useSideProcess(id);

  const callback = React.useCallback(
    event => {
      if (!process.enabled) return;

      const message = 'Form data can be lost.';
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;

      // eslint-disable-next-line consistent-return
      return message;
    },
    [process]
  );

  useWindowBeforeUnload(callback);

  React.useEffect(() => {
    const isActive = formik.isSubmitting || formik.isValidating || formik.dirty;

    if (isActive && !process.enabled) {
      start();
      return;
    }

    if (!isActive && process.enabled) {
      complete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValidating]);

  useWillUnmount(() => {
    complete();
  });

  return null;
};

export default SideProcessBridge;
