import { INTERVIEW_MODE } from 'common/helpers/interview/constants';
import { PROJECT_STATUSES } from 'common/helpers/project/constants';
import type { LocationState } from 'redux-first-router/dist/flow-types';
import type { IInterviewRecord } from 'flow-types/entities/InterviewRecord';
import type { IInterviewProject } from 'flow-types/entities/Project';
import type { InterviewAppearance } from 'flow-types/states/InterviewState/InterviewRecordState';

type GetTargetModeFn = ({
  location: LocationState,
  response: IInterviewRecord,
  project: IInterviewProject,
  appearance: InterviewAppearance
}) => string;

export const getTargetMode: GetTargetModeFn = (params: {
  location: LocationState,
  response: IInterviewRecord,
  project: IInterviewProject,
  appearance: InterviewAppearance
}): string => {
  if (!params) return INTERVIEW_MODE.LOCAL;

  const { location, project, appearance } = params;

  const { type: routeType } = location;

  // here LOCAL is possible
  // here only WEB and CRM can take place
  if (routeType === 'router/interviewPage') {
    if (project.status === PROJECT_STATUSES.PREPARING) {
      return INTERVIEW_MODE.LOCAL;
    }

    if (project.status === PROJECT_STATUSES.ACTIVE) {
      return INTERVIEW_MODE.REMOTE;
    }

    if (project.status === PROJECT_STATUSES.OUTDATED) {
      return INTERVIEW_MODE.VIEW;
    }

    return INTERVIEW_MODE.LOCAL;
  }

  // here LOCAL is impossible
  if (routeType === 'router/interviewsPage') {
    if (appearance === 'edit') {
      return INTERVIEW_MODE.EDIT;
    }

    // post and public are treated as active projects
    return INTERVIEW_MODE.REMOTE;
  }

  return INTERVIEW_MODE.LOCAL;
};
