// @flow

import { merge } from 'merge-anything';
import filter from 'lodash/filter';

import { INTERVIEW_STATUS } from 'common/helpers/interview/constants';

import type { PrepareInterviewFail } from 'flow-types/actions/interview/PrepareInterview';
import type { Move, MoveFail } from 'flow-types/actions/interview/Move';
import type { ChangeActiveStack } from 'flow-types/actions/interview/ChangeActiveStack';
import type { InterviewAction } from 'flow-types/actions/interview';
import type { IUpload } from 'flow-types/entities/Upload';
import type { CreateInterviewRecordSuccess } from 'flow-types/actions/interview/CreateInterviewRecord';
import type { InterviewRecordState } from 'flow-types/states/InterviewState/InterviewRecordState';
import type { IInterviewRecord } from 'flow-types/entities/InterviewRecord';
import type { CompleteInterviewSuccess } from 'flow-types/actions/interview/CompleteInterview';

const initialState: InterviewRecordState = {
  data: {},
  paused: false,
  running: false,
  stopped: false,
  prepared: false,
  preparing: false,
  started: false,
  completed: false,
  completing: false,
  locked: false,
  forcedAppearance: null,
  failed: false,
  attempt: 0,
  state: INTERVIEW_STATUS.NOT_STARTED,
  mode: null,
  menu: true, // IS MENU VISIBLE
  profile: true // IS PROFILE VISIBLE
};

export const INTERVIEW_PREPARE_FAIL_REASON = {
  ACCESS_EXPIRED: 1,
  ACCESS_CLOSED: 2
};

const handleRecordCreateSuccess = (
  state: InterviewRecordState,
  action: CreateInterviewRecordSuccess
) => {
  // const { mode, stage } = state;
  const { interview } = action;

  const updates = [{ data: interview }];

  return merge({ ...state }, ...updates);
};

function handleInterviewPrepareFailed(
  state: InterviewRecordState,
  action: PrepareInterviewFail
) {
  const next = { ...state };

  const { error } = action;

  next.preparing = false;

  if (error === INTERVIEW_PREPARE_FAIL_REASON.ACCESS_CLOSED) {
    next.prepared = true;
    next.state = INTERVIEW_STATUS.ACCESS_CLOSED;
  } else if (error === INTERVIEW_PREPARE_FAIL_REASON.ACCESS_EXPIRED) {
    next.prepared = true;
    next.preparing = false;
    next.state = INTERVIEW_STATUS.ACCESS_EXPIRED;
  } else {
    next.prepared = false;
  }

  return next;
}

const handleRecordChangeOnComplete = (
  data: IInterviewRecord,
  action: CompleteInterviewSuccess
): IInterviewRecord => {
  if (['web', 'public'].includes(action.appearance)) {
    // $FlowIgnore
    return {
      ...data,
      mainFinished: true
    };
  }

  if (['post'].includes(action.appearance)) {
    // $FlowIgnore
    return {
      ...data,
      postPollingFinished: true
    };
  }

  return data;
};

// stores created interview record data and different statuses
export default (
  state: InterviewRecordState = initialState,
  action: InterviewAction | Move | MoveFail | ChangeActiveStack
): InterviewRecordState => {
  switch (action.type) {
    // case 'interview/move':
    //   return lockCases.lock(state);

    case 'interview/remove-upload':
      return {
        ...state,
        data: {
          ...state.data,
          uploads: filter(
            state.data.uploads,
            (upload: IUpload): boolean => upload.id !== action.uploadId
          )
        }
      };
    case 'interview/add-upload':
      return {
        ...state,
        data: {
          ...state.data,
          uploads: !Array.isArray(state.data.uploads)
            ? [action.upload]
            : [...state.data.uploads, action.upload]
        }
      };
    case 'interview/update-record-state':
      return {
        ...state,
        ...action.dataUpdate
      };

    case 'router/interviewPage':
      if (!action.initialState) return state;
      return merge(
        { ...state },
        { ...(action.initialState && action.initialState) }
      );

    case 'interview/prepare':
      return {
        ...state,
        forcedAppearance:
          typeof action.forceAppearance === 'string'
            ? action.forceAppearance
            : null,
        preparing: true,
        prepared: false,
        stopped: false,
        paused: false,
        running: false
      };

    case 'interview/prepare-fail':
      return handleInterviewPrepareFailed(state, action);

    case 'interview/prepare-complete':
      return {
        ...state,
        preparing: false,
        prepared: true,
        data: action.isNext ? action.record : state.data,
        mode: action.mode
      };

    case 'interview/create-interview-record-success':
      return handleRecordCreateSuccess(state, action);

    case 'interview/update-interview':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.dataUpdate
        }
      };

    case 'interview/start':
      return {
        ...state,
        running: true,
        stopped: false,
        paused: false,
        started: true,
        completed: false
      };

    case 'interview/complete':
      return {
        ...state,
        attempt: state.attempt ? state.attempt + 1 : 1,
        completing: true,
        failed: false,
        stopped: false,
        running: false,
        paused: true
      };

    case 'interview/move':
      return {
        ...state,
        paused: true,
        locked: true
      };

    case 'interview/move-fail':
    case 'interview/change-active-stack':
    case 'interview/submit-answers-fail':
      return {
        ...state,
        paused: false,
        locked: false
      };

    case 'interview/complete-success':
      return {
        ...state,
        data: handleRecordChangeOnComplete(state.data, action),
        // TODO: all these boolean flags could be replaced with enum status
        completing: false,
        completed: true,
        stopped: true,
        running: false,
        paused: false
      };

    case 'interview/complete-fail':
      return {
        ...state,
        failed: true,
        completing: false,
        completed: false
      };

    case 'interview/reset':
      return {
        ...initialState,
        data: {}
      };

    default:
      return state;
  }
};
