// @flow

// NPM imports
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';

import type { IUnionOperator } from 'flow-types/entities/FilterSettings';

import { LOGIC_OPERAND } from 'common/helpers/logic/constants';
import Dropdown from '../../components/Dropdown';

type Props = {
  labels?: null | {
    [key: IUnionOperator]: string
  },
  excluded?: null | IUnionOperator[],
  value?: null | string
};

export default function UnionOperatorSelector({
  labels,
  excluded,
  ...props
}: Props) {
  const intl = useIntl();

  const options = useMemo(
    () =>
      reduce(
        LOGIC_OPERAND,
        (result, unionOperator) => {
          if (includes(excluded, unionOperator)) return result;

          const defaultIntlLabel = intl.formatMessage({
            id: `logicRuleForm.testForm.operator.${unionOperator}`
          });

          const customLabel = labels ? labels[unionOperator] : null;

          return [
            ...result,
            {
              value: unionOperator,
              label: customLabel || defaultIntlLabel
            }
          ];
        },
        []
      ),
    [excluded, intl, labels]
  );

  return (
    <Dropdown
      {...props}
      resetValue={LOGIC_OPERAND.AND}
      fluid
      options={options}
    />
  );
}

UnionOperatorSelector.defaultProps = {
  labels: null,
  excluded: null,
  value: null
};
