// @flow

import { EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';

import { AjaxError } from 'rxjs/ajax';

import request from 'utils/request';
import { API } from 'utils/config';

import type { Epic } from 'flow-types/Epic';
import type {
  FetchFilterSettingsListGroups,
  FetchFilterSettingsListGroupsFail,
  FetchFilterSettingsListGroupsSuccess
} from 'flow-types/actions/projects/detail/filtersSettings/FetchFilterSettingsListGroups';
import type { FetchProject } from 'flow-types/actions/projects/detail/project/FetchProject';

import { normalizeFilterSettingsListGroups } from 'common/transducers/projects/filterSettingsNormalizer';

import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import type { AppState } from 'flow-types/AppState';

import { projectIdFromPathSelector } from '../../../../selectors/projects';

const fetchFiltersSettingsListGroups: Epic = (action$, state$) =>
  action$.pipe<FetchFilterSettingsListGroups>(
    ofType('project/fetch-filter-settings-list-groups'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(
      ([action, state]: [FetchFilterSettingsListGroups, AppState]) => {
        const projectId = action.projectId
          ? action.projectId
          : projectIdFromPathSelector(state);

        if (!projectId) return EMPTY;

        return request({
          method: 'GET',
          url: interpolateString(
            API.projects.userFilterSettingsListGroups.list,
            {
              projectId
            }
          ),
          query: {
            without_group: 1,
            filter_settings_list: 1,
            sort: '+sort_order'
          }
        }).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.map((data): FetchFilterSettingsListGroupsSuccess => ({
            type: 'project/fetch-filter-settings-list-groups-success',
            data: normalizeFilterSettingsListGroups(data)
          })),
          RxOperators.catchError((ajaxError: AjaxError): [
            FetchFilterSettingsListGroupsFail
          ] => {
            const { message, response } = ajaxError;
            return [
              {
                type: 'project/fetch-filter-settings-list-groups-fail',
                response,
                message
              }
            ];
          })
        );
      }
    )
  );

export const fetchFilterSettingsOnFetchProjectSuccess: Epic = (
  action$,
  state$
) =>
  action$.pipe(
    ofType('project/fetch-success'),
    RxOperators.withLatestFrom(state$),
    RxOperators.map(([action, state]: [FetchProject, AppState]) => {
      let projectId = null;

      if (!action.projectId) {
        projectId = projectIdFromPathSelector(state);
      } else {
        // eslint-disable-next-line prefer-destructuring
        projectId = action.projectId;
      }

      return {
        type: 'project/fetch-filter-settings-list-groups',
        projectId
      };
    })
  );

export const refetchFilterSettingsOnSaveAndRemoveSuccess: Epic = action$ =>
  action$.pipe(
    ofType(
      'project/save-filter-settings-success',
      'project/remove-filter-settings-success',
      'project/remove-filter-settings-list-group-success',
      'project/save-filter-settings-list-group-success',
      'project/reorder-filter-settings-groups-success',
      'project/reorder-filter-settings-success'
    ),
    RxOperators.map(() => ({
      type: 'project/fetch-filter-settings-list-groups'
    }))
  );

export default fetchFiltersSettingsListGroups;
