import universal from 'react-universal-component';
import PageLoader from 'common/components/PageLoader';

const commonOptions = {
  loading: PageLoader
};

export const Restricted = universal(
  () => import('./Restricted'),
  commonOptions
);

export const Login = universal(() => import('./Login'), commonOptions);

export const Interview = universal(() => import('./Interview'), commonOptions);

export const Checks = universal(() => import('./Checks'), commonOptions);

export const Companies = universal(() => import('./Companies'), commonOptions);

export const Projects = universal(() => import('./Projects'), commonOptions);

export const Project = universal(() => import('./Project'), commonOptions);

export const NotFound = universal(() => import('./NotFound'), commonOptions);

export const Meetings = universal(() => import('./Meetings'), commonOptions);

export const Profile = universal(() => import('./Profile'), commonOptions);

export const Buildings = universal(() => import('./Buildings'), commonOptions);

export const Agitations = universal(
  () => import('./Agitations'),
  commonOptions
);
