// @flow

import isNaN from 'lodash/isNaN';
import type { NilValue } from 'flow-types/NilValue';

type Item = {
  +id?: NilValue | string | number | 'new',
  +isNew?: boolean
};

/**
 * Helper function to check, whether item is new
 */
const isNewElement = (
  item: NilValue | Item,
  by: null | 'id' | 'isNew' = null
): boolean => {
  if (!item) return false;

  const { isNew, id } = item;

  if (by === 'id') {
    if (!id || id === 'new' || isNaN(id)) return true;

    return `${id}` === '-1' || +id <= 0;
  }

  if (by === 'isNew') {
    return !!isNew;
  }

  return isNewElement(item, 'isNew') || isNewElement(item, 'id');
};

export default isNewElement;
