/* eslint-disable no-underscore-dangle */
// @flow

import * as React from 'react';
import { Observable } from 'rxjs';

const processState = (previewSrc, src) => {
  if (previewSrc && src) {
    return 'preview';
  }

  return 'original';
};

export default function useProgressiveImage({
  previewSrc,
  src,
  onReceive
}: {
  previewSrc: null | string,
  src: null | string,
  onReceive: Function
}): null | string {
  const [activeSrc, setActiveSrc] = React.useState(() =>
    processState(previewSrc, src)
  );

  React.useEffect(() => {
    setActiveSrc(processState(previewSrc, src));
  }, [previewSrc, src]);

  React.useEffect(() => {
    if (activeSrc !== 'preview' || !src) return;

    const originalObserver = Observable.create(observer => {
      const img = new Image();

      img.src = src;

      img.onload = () => {
        observer.next(img);
        observer.complete();
      };

      return () => {};
    });

    const subscription = originalObserver.subscribe(image => {
      setActiveSrc('original');
      onReceive?.(image);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSrc, src]);

  if (activeSrc === 'preview') return previewSrc;

  return src;
}
