import * as React from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import takeSecondArg from 'common/helpers/takeSecondArg';

const sideProcessStateSelectorCreator = () => {
  const defaultProcessData = { enabled: false };

  return createSelector(
    state => state.sideProcesses,
    takeSecondArg,
    (processes, processId) => processes?.[processId] ?? defaultProcessData
  );
};

const useSideProcess = processId => {
  const dispatch = useDispatch();

  const processSelector = React.useMemo(sideProcessStateSelectorCreator, []);

  const process = useSelector(state => processSelector(state, processId));

  const start = React.useCallback(() => {
    dispatch({ type: 'process/start', processId });
  }, [dispatch, processId]);

  const complete = React.useCallback(() => {
    dispatch({ type: 'process/complete', processId });
  }, [dispatch, processId]);

  return [process, start, complete];
};

export default useSideProcess;
