// @flow

import { ajax } from 'rxjs/ajax';
import qs from 'qs';

import defaultsDeep from 'common/helpers/defaults';
import { decamelizeKeys } from 'humps';
import getToken from './getToken';

function strictStringHandler(obj: Object) {
  const result = { ...obj };

  Object.keys(result).forEach(key => {
    if (result[key] === '') {
      delete result[key];
    }
  });

  return result;
}

type Params = {
  query?: {
    [key: string]: any
  },
  token?: null | string,
  queryParserConfig?: Object,
  decamelizeQuery?: boolean
};

const initialParams = {
  queryParserConfig: {
    skipNulls: true,
    addQueryPrefix: true,
    strictStringHandler: true
  },
  decamelizeQuery: false
};

const request = (params: Params): rxjs$Observable<any> => {
  const {
    query: rawQuery,
    queryParserConfig,
    url,
    headers,
    token,
    decamelizeQuery
  } = defaultsDeep(params, initialParams);

  const cachedToken = getToken();

  let authHeader = null;

  if (token) {
    authHeader = `Bearer ${token}`;
  } else if (cachedToken) {
    authHeader = `Bearer ${cachedToken}`;
  }

  let query = queryParserConfig.strictStringHandling
    ? strictStringHandler(rawQuery)
    : rawQuery;

  if (decamelizeQuery) {
    query = decamelizeKeys(query);
  }

  query = query ? qs.stringify(query, queryParserConfig) : '';

  return ajax({
    ...params,
    url: `${url}${query}`,
    headers: {
      'Content-Type': 'application/json',
      ...(authHeader && {
        Authorization: authHeader
      }),
      ...headers
    }
  });
};

export default request;
