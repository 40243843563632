import fp from 'lodash/fp';
import filter from 'lodash/filter';
import partition from 'lodash/partition';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import values from 'lodash/values';
import fpMap from 'lodash/fp/map';
import fpGet from 'lodash/fp/get';
import parseISO from 'date-fns/parseISO';
import { camelizeKeys } from 'humps';

import type { IResponse, IResponseAnswer } from 'flow-types/entities/Response';
import type { IAnswer } from 'flow-types/entities/Answer';
import type { IQuestion } from 'flow-types/entities/Question';
import type { IComment } from 'flow-types/entities/Comment';
import type { IUpload } from 'flow-types/entities/Upload';

import { TYPES } from 'common/helpers/question';
import orderBySequence from 'common/helpers/orderBySequence';
import { normalizeQuestions } from '../projects/questions/questionsNormalizer';

import flatDisabledTestElements from './flatDisableTestElements';
import uploadsNormalizer from '../uploads/normalizers';

const optionsIdsGetter = fpMap(fpGet('optionId'));

const responseRevertAnswersQuestions = (answers: IAnswer[]): IQuestion[] =>
  map(answers, answer => {
    const { question, ...rest } = answer;
    return {
      ...question,
      answer: rest
    };
  });

export const responseQuestionsAnswersMixin = (
  questions: IQuestion[],
  answers: IAnswer[]
) =>
  map(questions, (question: IQuestion) => {
    const { id: questionId } = question;

    let answer = find(answers, { questionId });

    if (!answer) {
      answer = null;
    }

    return {
      ...question,
      answer
    };
  });

export const responseQuestionsCommentsMixin = (
  questions: IQuestion[],
  comments: IComment[]
) =>
  map(questions, question => {
    const { id: questionId } = question;

    const questionComments = sortBy(
      filter(
        comments,
        (comment: IComment) => comment.questionId === questionId
      ),
      comment => parseISO(comment.dateCreated)
    );

    return {
      ...question,
      comments: questionComments
    };
  });

export const responseQuestionsAttachmentsMixin = (
  questions: IQuestion[],
  uploads: IUpload[]
): IQuestion[] =>
  map(questions, question => {
    // get only those attahcments related to current question
    const blockRelatedAttachments = filter(uploads, {
      questionId: question.id
    });

    // separate images from non-images
    const [attachments, otherAttachments] = partition(blockRelatedAttachments, {
      isImage: true
    });

    return {
      ...question,
      // TODO: rename to imageAttachments
      attachments,
      otherAttachments
    };
  });

const normalizeResponseAnswers = ({
  answers,
  ...response
}: IResponse): IResponse => ({
  ...response,
  answers: answers.map((answer: IResponseAnswer) => {
    if (!answer.question) return answer;

    if (answer.question.type !== TYPES.Ranging) return answer;

    return {
      ...answer,
      options: orderBySequence(
        // TODO: resolve later
        answer.options,
        // TODO: resolve later
        optionsIdsGetter(answer.meta)
      )
    };
  })
});

const responseQuestionsNormalizer = (response: IResponse) => {
  const { answers, comments, uploads } = response;

  let normalizedQuestions = normalizeQuestions(
    responseRevertAnswersQuestions(answers)
  );

  normalizedQuestions = responseQuestionsCommentsMixin(
    normalizedQuestions,
    comments
  );

  normalizedQuestions = responseQuestionsAttachmentsMixin(
    normalizedQuestions,
    uploads
  );

  return {
    ...response,
    answers,
    questions: normalizedQuestions
  };
};

export const responseNormalizer = fp.compose(
  responseQuestionsNormalizer,
  normalizeResponseAnswers,
  (response: IResponse) => {
    const next: IResponse = clone(response);

    next.uploads = uploadsNormalizer(next.uploads);

    if (next.records) {
      next.records = uploadsNormalizer(next.records);
    }

    next.answers = values(next.answers);

    next.disabledChecklistItems = flatDisabledTestElements(
      next.disabledChecklistItems
    );

    return next;
  },
  camelizeKeys
);

const responsesNormalizer = fp.map(responseNormalizer);

export default responsesNormalizer;
