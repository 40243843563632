import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';
import { toast } from 'react-toastify';
import find from 'lodash/find';

import { API } from 'utils/config';
import request from 'utils/request';

import { projectIdFromPathSelector } from 'selectors/projects';
import isNewElement from 'common/helpers/isNewElement';

import type { Epic } from 'flow-types/Epic';

import { projectFlatQuestionsSelector } from '../components/Panels/ResponsesPanel/selectors';

const removeQuestionEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/remove-question'),
    RxOperators.withLatestFrom($state),
    RxOperators.filter(([, state]) => {
      const projectId = projectIdFromPathSelector(state);

      return projectId !== 'new';
    }),
    RxOperators.mergeMap(([{ question: questionId }, state]) => {
      const questions = projectFlatQuestionsSelector(state);

      const question = find(questions, { id: questionId });

      if (!question || isNewElement(question)) {
        return [
          {
            type: 'project-groups/remove-question-success',
            questionGroupId: question.questionGroupId,
            questionId
          }
        ];
      }

      return request({
        url: API.questions.detail.replace(':question_id', questionId),
        method: 'DELETE'
      }).pipe(
        RxOperators.map(() => ({
          type: 'project-groups/remove-question-success',
          questionId,
          questionGroupId: question.questionGroupId
        })),
        RxOperators.catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'project-groups/remove-question-fail',
            questionId,
            questionGroupId: question.questionGroupId
          });
        })
      );
    })
  );

export default removeQuestionEpic;
