// Данный компонент должен поддерживать два режима

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled, { css } from 'styled-components';
import fp from 'lodash/fp';
import size from 'lodash/size';
import find from 'lodash/find';
import { camelizeKeys } from 'humps';
import { FormattedMessage, useIntl } from 'react-intl';
import type { IUpload } from '../../../flow-types/entities/Upload';
import type { FilePondFile } from '../../components/FilePond/flow';
import { tabletBreakpoint } from '../../../utils/semanticBreakpoints';
import type { IQuestionId } from '../../../flow-types/entities/Question';
import uploadsNormalizer from '../../transducers/uploads/normalizers';
import getToken from '../../../utils/getToken';
import removeUpload from './removeUpload';
import LocalizedFilePond from '../../components/FilePond';
import Message from '../../components/Message';
import { API_PREFIX } from '../../../utils/config';

function parseUploadToFilePond(upload: IUpload): FilePondFile {
  const { uploadToken } = upload;

  return {
    source: uploadToken,
    options: {
      type: 'local',
      metadata: {
        ...upload,
        name: upload.originalFilename
      }
    }
  };
}

const parseUploads = fp.map(parseUploadToFilePond);

const allowRemoveContainerPropHandler = ({ allowRemove }) => {
  if (allowRemove) {
    return null;
  }

  return css`
    .filepond--file-action-button.filepond--action-remove-item {
      display: none;
    }
  `;
};

const Container = styled.div`
  @media (min-width: ${tabletBreakpoint}px) {
    .filepond--item {
      width: calc(50% - 0.5em);
    }
  }
  ${allowRemoveContainerPropHandler}
`;

type Props = {|
  +questionId: IQuestionId,
  +disabled: boolean,
  +attachments: IUpload[],
  +maxFiles?: number,
  +minFiles?: number,
  +onUploadSuccess?: Function,
  +onUploadRemove?: Function,
  +headers: Object
|};

// Только те элементы будут выводится как постеры,
// у которых есть заданный poster в метаданных
const filePosterFilterItem = item => !!item?.options?.metadata?.poster;

export default function Uploads({
  questionId,
  disabled,
  attachments,
  maxFiles,
  minFiles,
  onUploadSuccess,
  onUploadRemove,
  headers
}: Props) {
  const intl = useIntl();

  const [showFileLimitMessage, setFileLimitMessageVisibility] = useState(false);

  const [files, setFiles] = useState([]);

  const filesCountCache = useRef(0);

  useEffect(() => {
    const nextFileCount = size(files);

    if (nextFileCount !== filesCountCache.current) {
      filesCountCache.current = size(files);
      if (nextFileCount >= maxFiles) {
        setFileLimitMessageVisibility(true);
      } else {
        setFileLimitMessageVisibility(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleFileAdd = useCallback(
    file => {
      if (!onUploadSuccess) return;

      const [upload] = uploadsNormalizer([file]);

      onUploadSuccess(upload);
    },
    [onUploadSuccess]
  );

  useEffect(() => {
    if (questionId) {
      setFileLimitMessageVisibility(false);
      filesCountCache.current = size(attachments);
      setFiles(parseUploads(attachments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const messages = useMemo(() => {
    let labelIdle = intl.formatMessage({ id: 'fileUploader.labelIdle' });

    if (!!minFiles || !!maxFiles) {
      const maxLabel =
        maxFiles && maxFiles > 0
          ? intl.formatMessage(
              { id: 'fileUploader.maxFiles' },
              { count: maxFiles || 0 }
            )
          : false;

      const minLabel =
        minFiles && minFiles > 0
          ? intl.formatMessage(
              { id: 'fileUploader.minFiles' },
              { count: minFiles || 0 }
            )
          : false;

      const labelsPostFix = [minLabel, maxLabel].filter(Boolean).join(', ');

      labelIdle = `${labelIdle} (${labelsPostFix})`;
    }

    return {
      labelIdle,
      labelFileLoading: intl.formatMessage({
        id: 'fileUploader.labelFileLoading'
      }),
      labelFileProcessing: intl.formatMessage({
        id: 'fileUploader.labelFileProcessing'
      }),
      labelFileProcessingComplete: intl.formatMessage({
        id: 'fileUploader.labelFileProcessingComplete'
      }),
      labelTapToCancel: intl.formatMessage({
        id: 'fileUploader.labelTapToCancel'
      }),
      labelTapToRetry: intl.formatMessage({
        id: 'fileUploader.labelTapToRetry'
      }),
      // TODO: undo is not supported yet on server
      labelTapToUndo: ''
    };
  }, [intl, maxFiles, minFiles]);

  const tokenHeader = useMemo(
    () => ({
      Authorization: `Bearer ${getToken()}`
    }),
    []
  );

  const onUpdateFiles = useCallback(nextFiles => {
    setFiles(nextFiles.map(file => file.file));
  }, []);

  const fileLimit = useMemo(() => {
    // Значение maxFiles хоть и является ограничением,
    // но может привести к фактическому показу недостоверного количества изображений.
    //
    // Небольшой пример:
    // То есть было загружено 5 файлов.
    // При ограничении в 2 файла компонент выведет именно 2 из 5.
    // Соответственно, нужно создать локальную переменную с ограничением в количестве файлов
    // и если фактическое количество файлов больше, чем в ограничении,
    // то ограничении приравнивается именно к нему, но при этом, добавлять больше файлов нельзя
    // при этом, этот механизм работает только если задан maxFiles
    if (!!maxFiles && maxFiles > 0) {
      if (attachments.length > maxFiles) return attachments.length;

      return maxFiles;
    }

    return null;
  }, [maxFiles, attachments]);

  const onProcessSuccess = useCallback(
    response => {
      const normalizedResponse = camelizeKeys(JSON.parse(response));
      if (handleFileAdd) {
        handleFileAdd(normalizedResponse.data);
      }
      return normalizedResponse.data.uploadToken;
    },
    [handleFileAdd]
  );

  const fileLimitReached = useMemo(() => {
    if (!maxFiles) return false;

    return size(attachments) >= maxFiles;
  }, [attachments, maxFiles]);

  const handleFileRemove = useCallback(
    uploadId => {
      if (!uploadId || !onUploadRemove) return;

      onUploadRemove(uploadId);
    },
    [onUploadRemove]
  );

  const removeFileRemote = async (source, load, error) => {
    const uploadForSource = find(attachments, { uploadToken: source });

    // TODO: make further investigations on the issue below
    // when changing question id,
    // an issue can be faced with a file
    // being unable correctly removed.
    // It is a strange behaviour of a library.
    // It is already known, that by the moment of that bug to be in place,
    // requested files will not be in attachments list,
    // thus just call 'load' and exit that function.
    if (!uploadForSource) {
      load();
      return;
    }

    try {
      await removeUpload(uploadForSource.id);
      handleFileRemove(uploadForSource.id);
      load();
    } catch (e) {
      error(e);
    }
  };
  return (
    <Container allowRemove>
      {showFileLimitMessage && fileLimitReached && (
        <Message status="warning" size="small">
          <FormattedMessage id="fileUploader.limitReached" />
        </Message>
      )}
      <LocalizedFilePond
        imagePreviewHeight={150}
        instantUpload
        itemInsertLocation="after"
        maxFiles={fileLimit}
        disabled={disabled}
        allowMultiple
        onupdatefiles={onUpdateFiles}
        files={files}
        maxParallelUploads={2}
        allowRevert
        allowRemove
        allowReplace={false}
        allowDrop={!fileLimitReached}
        allowBrowse={!fileLimitReached}
        filePosterFilterItem={filePosterFilterItem}
        {...messages}
        server={{
          url: `${API_PREFIX}/api`,
          process: {
            url: '/uploads',
            method: 'POST',
            headers: {
              ...tokenHeader,
              ...(headers && headers)
            },
            onload: onProcessSuccess
          },
          load: {
            url: '/uploads/download/',
            method: 'GET',
            headers: {
              ...tokenHeader
            }
          },
          restore: null,
          fetch: {
            url: '/uploads/download/',
            method: 'GET',
            headers: {
              ...tokenHeader
            }
          },
          revert: removeFileRemote,
          remove: removeFileRemote
        }}
      />
    </Container>
  );
}

Uploads.defaultProps = {
  maxFiles: null,
  minFiles: null,
  onUploadSuccess: null,
  onUploadRemove: null,
  headers: null
};
