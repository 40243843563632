import { map } from 'rxjs/operators';
import normalizePagination from 'common/transducers/paginationNormalizer';

type Response = {|
  +data: Array,
  +pagination: Object
|};

// it should be placed first inside request's or ajax's pipe
export default map((res: Response) => {
  const { data, pagination } = res;
  return {
    pagination: normalizePagination(pagination),
    data
  };
});
