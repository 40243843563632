// @flow
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import cx from 'classnames';
import { useSpring, useChain, useSpringRef } from 'react-spring';
import Content from 'common/components/Content';
import Header from 'common/components/Header';
import type { ReactChildren } from 'flow-types/ReactChildren';

import { createPortal } from 'react-dom';
import useOnClickOutside from 'use-onclickoutside';
import { useHotkeys } from 'react-hotkeys-hook';
import Actions from './Actions';
import type { DimmerProps } from '../Dimmer';

import Dimmer from '../Dimmer';

import { ModalContainer } from './styled';

type Props = {|
  +visible?: boolean,
  +onClose?: Function,
  +onClosed?: Function,
  +className?: null | string,
  +basic?: boolean,
  +size?: 'fullscreen' | 'mini' | 'tiny' | 'small' | 'large' | string,
  +portal?: boolean,
  +children?: ReactChildren,
  +dimmerProps?: DimmerProps,

  /**
   * Defines how modal will dis-/appear
   * naming comes from appear animation like:
   * + fadeIn -- (fades in on entering and fades out and leaving)
   * + slideDown -- (slides from top to target position on entering and slides to top on leaving)
   */
  +effect?: 'slideUp' | 'fadeIn',
  /**
   * Defines effect animation duration
   * TODO: temporal props, the way Modal receives its animation config should be
   */
  +duration?: null | number,
  /**
   * Влияет на изначальную позицию
   */
  +position?: null | 'bottom',
  +overlay?: boolean
|};

const ANIMATION_STYLES = {
  fadeIn: {
    from: {
      scale: 0.9,
      opacity: 0
      // translateY: '0%'
    },
    to: {
      scale: 1,
      opacity: 1
      // translateY: '0%'
    }
  },
  slideUp: {
    from: {
      scale: 1,
      opacity: 1,
      // opacity: 0,
      translateY: '100%'
    },
    to: {
      scale: 1,
      opacity: 1,
      translateY: '0%'
    }
  }
};

export const MODAL_ANIMATION_DURATION = 150;

/**
 * This component is a partial rework of existing component components/Modal
 */
const Modal = ({
  className,
  visible,
  basic,
  size,
  children,
  portal,
  dimmerProps,
  onClosed,
  overlay,
  onClose,
  position,
  effect,
  duration,
  ...props
}: Props): React.Node => {
  const modalRef = React.useRef(null);

  const dimmerARef = useSpringRef();

  const modalARef = useSpringRef();

  // BOTTOM should be not applied as animation, but as STATIC style prop,
  // that would enforce Modal to stick to bottom
  const animation = position === 'bottom' ? 'slideUp' : effect ?? 'fadeIn';

  const spring = useSpring({
    ref: modalARef,
    ...ANIMATION_STYLES[animation][visible ? 'to' : 'from'],
    config: {
      duration: duration ?? MODAL_ANIMATION_DURATION
    },
    onRest: ({ finished }) => {
      if (finished && !visible && onClosed) {
        onClosed();
      }
    }
  });

  useHotkeys('esc', () => {
    if (!visible) return;

    onClose?.();
  });

  useOnClickOutside(modalRef, event => {
    // если клик произошёл внутри toastify, то не нужно закрывать модал
    if (visible && event.target.closest('.Toastify')) return;

    onClose?.();
  });

  const classNames = React.useMemo(
    () =>
      cx(
        'ui',
        size,
        {
          basic,
          visible,
          overlay,
          'pos-bottom': position === 'bottom'
        },
        className,
        'modal'
      ),
    [basic, className, overlay, position, size, visible]
  );

  useChain(visible ? [dimmerARef, modalARef] : [modalARef, dimmerARef]);

  if (portal) {
    // TODO: SSR warning
    return createPortal(
      <Dimmer
        {...props}
        animationRef={dimmerARef}
        visible={visible}
        active={visible}
        isStatic={animation === 'slideUp'}
        fixed={dimmerProps ? dimmerProps.fixed : false}
        inverted={dimmerProps ? dimmerProps.inverted : false}
        page={dimmerProps ? dimmerProps.page : false}
        scrolling={dimmerProps ? dimmerProps.scrolling : false}
      >
        <ModalContainer ref={modalRef} style={spring} className={classNames}>
          {children}
        </ModalContainer>
      </Dimmer>,
      document.body
    );
  }

  return (
    <Dimmer
      {...props}
      animationRef={dimmerARef}
      visible={visible}
      active={visible}
      isStatic={animation === 'slideUp'}
      fixed={dimmerProps ? dimmerProps.fixed : false}
      inverted={dimmerProps ? dimmerProps.inverted : false}
      page={dimmerProps ? dimmerProps.page : false}
      scrolling={dimmerProps ? dimmerProps.scrolling : false}
    >
      <ModalContainer ref={modalRef} style={spring} className={classNames}>
        {children}
      </ModalContainer>
    </Dimmer>
  );
};

Modal.defaultProps = {
  size: '',
  overlay: false,
  dimmerProps: null,
  visible: false,
  onClose: null,
  onClosed: null,
  className: null,
  basic: false,
  portal: false,
  children: null,
  position: null,
  duration: null,
  effect: 'fadeIn'
};

Modal.Content = Content;
Modal.Header = Header;
Modal.Actions = Actions;

export default Modal;
