// @flow

import { createSelector } from 'reselect';
import find from 'lodash/find';
import size from 'lodash/size';
import filter from 'lodash/filter';
import projectGroupsToFlatQuestionsList from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import {
  responseQuestionsAnswersMixin,
  responseQuestionsAttachmentsMixin,
  responseQuestionsCommentsMixin
} from 'common/transducers/response/responsesNormalizer';

import type { ProjectResponsesState } from 'flow-types/states/ProjectsState/detail';
import type { IQuestion } from 'flow-types/entities/Question';
import type { IResponse } from 'flow-types/entities/Response';
import type { IProject } from 'flow-types/entities/Project';
import type { IAnswer } from 'flow-types/entities/Answer';
import type { Pagination } from 'flow-types/Pagination';
import type { StateSelector } from 'flow-types/Selector';

import { filterNonImages } from './helpers';
import {
  projectFinalDataSelector,
  projectGroupsStateSelector,
  projectIdFromPathSelector,
  projectResponsesStateSelector
} from '../../../../../selectors/projects';

export const hasPostPollSelector: StateSelector<boolean> = createSelector(
  projectFinalDataSelector,
  (project: IProject) => !!project.settings?.appearance?.post.enabled
);

export const projectFlatQuestionsSelector: StateSelector<
  IQuestion[]
> = createSelector(projectGroupsStateSelector, groupsState => {
  const { data } = groupsState;

  return projectGroupsToFlatQuestionsList(data);
});

const selectedResponseSelector: StateSelector<null | IResponse> = createSelector(
  projectResponsesStateSelector,
  responsesState => {
    const { data } = responsesState;

    const selected = find(data, { active: true });

    if (!selected) return null;

    return selected;
  }
);

const selectedResponseIdSelector: StateSelector<null | number> = createSelector(
  selectedResponseSelector,
  selectedResponse => {
    if (!selectedResponse) return null;

    return selectedResponse.id;
  }
);

const questionAnswersListForResponseSelector: StateSelector<
  // TODO: resolve later
  Object[]
> = createSelector(
  selectedResponseSelector,
  projectFlatQuestionsSelector,
  (selectedResponse, projectFlatQuestions) => {
    if (!selectedResponse) return null;

    const { uploads, answers, comments } = selectedResponse;

    if (size(uploads) === 0 && size(answers) === 0) return [];

    let questions = filter(projectFlatQuestions, (question: IQuestion) => {
      const { id } = question;

      const hasUploads = uploads
        ? find(filterNonImages(uploads), { questionId: id })
        : false;

      const hasAnswers = find(answers, { questionId: id });

      return hasUploads || hasAnswers;
    });

    questions = responseQuestionsAnswersMixin(questions, answers);

    questions = responseQuestionsAttachmentsMixin(questions, uploads);

    questions = responseQuestionsCommentsMixin(questions, comments);

    return questions;
  }
);

export const projectResponsesFilterSelector: StateSelector<Object> = createSelector(
  projectResponsesStateSelector,
  (projectResponses: ProjectResponsesState) => projectResponses.filter
);

export const responsesPanelSelector: StateSelector<{
  selectedResponse: IResponse,
  selectedResponseId: null | number,
  responsesList: IResponse[],
  responsesFilter: Object,
  responsesLoading: boolean,
  responsesPagination: Pagination,
  projectId: number,
  hasPostPolling: boolean,
  answersList: IAnswer[]
}> = createSelector(
  projectResponsesStateSelector,
  projectIdFromPathSelector,
  hasPostPollSelector,
  selectedResponseSelector,
  selectedResponseIdSelector,
  questionAnswersListForResponseSelector,
  (
    responsesState,
    projectId,
    hasPostPolling,
    selectedResponse,
    selectedResponseId,
    answersList
  ) => {
    const {
      data: responsesList,
      filter: responsesFilter,
      loading: responsesLoading,
      pagination: responsesPagination
    } = responsesState;

    return {
      selectedResponse,
      selectedResponseId,
      responsesList,
      responsesFilter,
      responsesLoading,
      responsesPagination,
      projectId,
      hasPostPolling,
      answersList
    };
  }
);
