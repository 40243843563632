import * as RxOperators from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';

import type {
  RemoveAccessSettings,
  RemoveAccessSettingsFail,
  RemoveAccessSettingsSuccess
} from 'flow-types/actions/projects/detail/accessRules/RemoveAccessSettings';
import type { AppState } from 'flow-types/AppState';
import { projectIdFromPathSelector } from '../../../../selectors/projects';
import type { CloseModal } from '../../../Modals/modals';

const removeAccessSettings = (action$, state$) =>
  action$.pipe(
    ofType('project/remove-access-settings'),
    RxOperators.withLatestFrom(state$),
    RxOperators.exhaustMap(
      ([action, state]: [RemoveAccessSettings, AppState]) => {
        const { settingsId, modalId } = action;

        const projectId = projectIdFromPathSelector(state);

        const params = {
          url: interpolateString(API.projects.accessSettings.detail, {
            projectId,
            settingsId
          }),
          method: 'DELETE'
        };

        return request(params).pipe(
          responseParser,
          RxOperators.pluck('data'),
          RxOperators.mergeMap((): [
            RemoveAccessSettingsSuccess,
            CloseModal
          ] => [
            {
              type: 'project/remove-access-settings-success',
              settingId: settingsId
            },
            modalId && {
              type: 'modals/close',
              modalId,
              dataReset: null
            }
          ]),
          RxOperators.catchError((error: AjaxError): [
            RemoveAccessSettingsFail
          ] => [
            {
              type: 'project/remove-access-settings-fail',
              error: error.response
            }
          ])
        );
      }
    )
  );

export default removeAccessSettings;
