import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import type { IPollingNotification } from 'flow-types/entities/PollingNotification';
import { testGroupsNormalizer } from 'common/transducers/projects/logicNormalizer';

function accessSettingsProcessor(key) {
  return fp.reduce(
    (result, item) => ({
      ...result,
      [item]: {
        id: item,
        [key]: item,
        access: 'visible'
      }
    }),
    {}
  );
}

const processPollingNotificationAccessSettings = (
  pollingNotification: IPollingNotification
) => {
  const next = { ...pollingNotification };

  if (Array.isArray(next.questionIds)) {
    next.questionsAccess = accessSettingsProcessor('questionId')(
      next.questionIds
    );
  }

  if (Array.isArray(next.questionGroupIds)) {
    next.questionGroupsAccess = accessSettingsProcessor('questionGroupId')(
      next.questionGroupIds
    );
  }

  delete next.questionIds;
  delete next.questionGroupIds;

  return next;
};

const processPollingNotificationTestGroups = (
  pollingNotification: IPollingNotification
): IPollingNotification => {
  const next = { ...pollingNotification };

  const tests = testGroupsNormalizer(next.testGroups);

  next.tests = Array.isArray(tests) && tests.length === 1 ? tests[0] : [tests];

  delete next.testGroups;

  return next;
};

export const normalizePollingNotification = fp.compose(
  processPollingNotificationAccessSettings,
  processPollingNotificationTestGroups
);

export const camelizeAndNormalizePollingNotification = fp.compose(
  normalizePollingNotification,
  camelizeKeys
);
