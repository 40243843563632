// @flow
/* eslint-disable max-classes-per-file */

export interface ComputedPropertiesProcessorThrowable {
  /**
   * This property describes where this error occurred.
   * Last element is current processing variable, if list is empty.
   */
  processChain: string[];
}

/**
 * Error is thrown when incorrect or unsupported operator is used in expression.
 * @example
 * "propertyA subtract propertyB" - error
 * "propertyA -+ propertyB"       - error
 * "propertyA - propertyB"        - success
 * "abc" - 2                      - error
 * "abc" * 2                      - error
 * "2" * "2"                        - success
 * "2" * "2"                        - success
 * etc.
 */
export class IncorrectExpressionOperatorUsage extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  operator: ?string;

  allowedOperators: string[] = [];

  constructor(
    context: string[],
    varType: string,
    availableOperators: string[],
    operator: string
  ) {
    super(`${operator} is used incorrectly!`);

    this.name = 'IncorrectExpressionOperatorUsage';

    this.operator = operator;

    this.allowedOperators = availableOperators;

    this.processChain = context;
  }
}

/**
 * Error is thrown when cycle dependency has been found.
 * Dependency cycle error is thrown in cases when property A depends on property B, that depends on property A itself.
 * @example
 * propertyA uses propertyB uses propertyA
 * propertyA uses propertyA
 */
export class VariableCycleDependencyError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  constructor(context: string[], variableId: string) {
    super(`${variableId} has a cycle dependency!`);

    this.name = 'VariableCycleDependencyError';

    this.processChain = context;
  }
}

/**
 * Error is thrown when there is no formulaAst inside IComputedProperty.
 */
export class UndefinedFormulaStructureError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  constructor(context: string[], variableId: string) {
    super(`${variableId} AST is not defined!`);

    this.name = 'UndefinedFormulaStructureError';

    this.processChain = context;
  }
}

/**
 * Error is thrown when undefined property is used.
 */
export class UndefinedPropertyUsageError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  constructor(context: string[], variableId: string) {
    super(`${variableId} is not defined!`);

    this.name = 'UndefinedPropertyUsageError';

    this.processChain = context;
  }
}

/**
 * Error is thrown when undefined property is used.
 */
export class UnresolvableExpressionElement extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  constructor(message: string, context: string[]) {
    super(message);

    this.name = 'UnresolvableExpressionElement';

    this.processChain = context;
  }
}

/**
 * Error is thrown when two impossible types are used
 * @example
 * "abc" + 12 --> error
 * 12 + 12    --> success
 * 12 * 12    --> success
 * 13
 */
export class IncorrectDataTypeIsUsedError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  requiredType: ?string;

  realType: ?string;

  constructor(context: string[], varType: string, valueType: string) {
    super(`incorrect data type!`);

    this.name = 'IncorrectDataTypeIsUsedError';

    this.requiredType = varType;

    this.realType = valueType;

    this.processChain = context;
  }
}

export class UnknownTypeError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  type: ?string;

  constructor(context: string[], type: string) {
    super(`${type} is not supported!`);

    this.name = 'UnknownTypeError';

    this.processChain = context;

    this.type = type;
  }
}

export class UnknownOperatorError extends Error
  implements ComputedPropertiesProcessorThrowable {
  processChain: string[] = [];

  operator: ?string;

  constructor(context: string[], operator: string) {
    super(`${operator} is not supported!`);

    this.name = 'UnknownTypeError';

    this.processChain = context;

    this.operator = operator;
  }
}
