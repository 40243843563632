// @flow

import { combineReducers } from 'redux';
import lists from './checklists';
import comparators from './comparators';
import groups from './groups';

export default combineReducers({
  lists,
  comparators,
  groups
});
