import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import isNil from 'lodash/isNil';

function zIndexPropParser(props) {
  if (isNil(props.zIndex)) {
    return null;
  }

  return css`
    z-index: ${props.zIndex} !important;
  `;
}

function flexDisplayParser(props) {
  return props.visible
    ? css`
        &&& {
          display: flex !important;
        }
      `
    : null;
}

function fixedPropsParser(props) {
  if (!props.fixed) {
    return null;
  }

  return css`
    &&& {
      position: fixed !important;
    }
  `;
}

export const Container = styled.div`
  ${zIndexPropParser};
  ${fixedPropsParser};
  ${flexDisplayParser};
`;

export const ModalContainer = styled(animated.div)`
  // default styles
  &.pos-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
  }

  &&& {
    display: block;
  }
`;
