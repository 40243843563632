// @flow

import type { LocationState } from 'redux-first-router/dist/flow-types';

export const isOpenedViaPublicLink = (params: { location: LocationState }) => {
  if (!params || !params.location || !params.location.query) return false;

  const {
    location: { query, type }
  } = params;

  if (type === 'router/interviewPage') return false;

  return !!query.publicHash;
};
