// @flow

import map from 'lodash/map';
import flatMapDeep from 'lodash/flatMapDeep';
import compose from 'lodash/fp/compose';

import type { IQuestion } from 'flow-types/entities/Question';
import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';

import { TYPES } from '../../helpers/question';

// Weaks are used to expect something, that looks like question or question group,
// but they have not strict opinion on what should it be in fact
type WeakQuestion = $Shape<IQuestion>;
type WeakQuestionGroup = $Shape<IQuestionGroup>;
type FormatItemFn<T, S> = (item1: T, item2: S) => T;

// this function returns all direct children questions from all groups
export const flatGroupsQuestions = (
  questionGroups: WeakQuestionGroup[],
  formatItem?: FormatItemFn<WeakQuestion, WeakQuestionGroup>
): Array<Object> =>
  flatMapDeep(
    questionGroups,
    (questionGroup: WeakQuestionGroup): WeakQuestion[] => {
      const { questions } = questionGroup;

      if (!questions) return [];

      if (!formatItem) {
        return questions;
      }

      return map(questions, question => formatItem(question, questionGroup));
    }
  );

// this function goes inside all questions and
// returns question + all its sub-questions
// if question has them.
export const flatQuestionsSubQuestions = (
  questions: ?(WeakQuestion[])
): WeakQuestion[] => {
  if (!questions) return [];

  return flatMapDeep(questions, (question: WeakQuestion): WeakQuestion[] => {
    const { subQuestions, type } = question;

    if (type !== TYPES.Table) return [question];

    if (Array.isArray(subQuestions)) return [question, ...subQuestions];

    return [question];
  });
};

const projectGroupsToFlatQuestionsList: Function = compose(
  flatQuestionsSubQuestions,
  flatGroupsQuestions
);

export default projectGroupsToFlatQuestionsList;
