import { combineEpics } from 'redux-observable';
import fetchAccessSettingsList from './fetchAccessSettingsList';
import saveAccessSettings from './saveAccessSettings';
import removeAccessSettings from './removeAccessSettings';
import editAccessSettings, { fetchAccessSettings } from './editAccessSettings';

export default combineEpics(
  fetchAccessSettingsList,
  saveAccessSettings,
  removeAccessSettings,
  editAccessSettings,
  fetchAccessSettings
);
