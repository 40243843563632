import React from 'react';
import TestsForm from 'common/containers/TestsForm';
import type { ITest } from 'common/containers/TestsForm/flow';
import { TYPES } from 'common/helpers/question';
import { DEFAULT_EXCLUDED_BLOCKS, useSources } from './useSources';

type Props = {
  validation?: Object,
  isDisabled?: boolean,
  onChange: Function,
  onDataChange: Function,
  data?: ITest[]
};

const EXCLUDED_QUESTION_TYPES = [...DEFAULT_EXCLUDED_BLOCKS, TYPES.Ranging];

export default function ResponsesFilterSettingsForm({
  validation,
  isDisabled,
  onChange,
  onDataChange,
  data,
  ...rest
}: Props) {
  const sources = useSources({ excludedBlockTypes: EXCLUDED_QUESTION_TYPES });

  return (
    <TestsForm
      {...rest}
      name="tests"
      validation={validation ?? null}
      isDisabled={isDisabled}
      onChange={onChange}
      onDataChange={onDataChange}
      sources={sources}
      data={data}
    />
  );
}

ResponsesFilterSettingsForm.defaultProps = {
  data: [],
  isDisabled: false,
  validation: null,
  onDataChange: null,
  onChange: null
};
