import { decamelizeKeys } from 'humps';
import fp from 'lodash/fp';
import type {
  IExportSettings,
  IExportSettings$Raw
} from 'flow-types/entities/ExportSettings';

const blocksGetter = fp.compose(
  fp.map(i => i.fieldCode),
  fp.filter({
    fieldType: 'block',
    exported: true
  })
);

const propertiesGetter = fp.compose(
  fp.map(i => i.fieldCode),
  fp.filter({
    fieldType: 'property',
    exported: true
  })
);

const processExportSettingsFields = (
  exportSettings: IExportSettings
): IExportSettings$Raw => {
  const { settings, ...rest } = exportSettings;

  const { filters = {}, flags, fields } = settings;

  return {
    ...rest,
    settings: {
      flags,
      filters,
      fields: propertiesGetter(fields),
      questionIds: blocksGetter(fields)
    }
  };
};

export const denormalizeExportSettings = fp.compose(
  processExportSettingsFields
);

export const decamelizeAndDenormalizeExportSettings = fp.compose(
  decamelizeKeys,
  denormalizeExportSettings
);

export const decamelizeAndDenormalizeExportSettingsList = fp.compose(
  decamelizeKeys,
  fp.map(denormalizeExportSettings)
);
