/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import * as React from 'react';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import size from 'lodash/size';
import map from 'lodash/map';
import { Formik } from 'formik';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import { v4 as uuid } from 'uuid';
import format from 'date-fns/format';
import isNewElement from 'common/helpers/isNewElement';
import Comment from 'common/components/Comment';
import CommentsUI from 'common/components/Comment/Comments';
import type { $ObjOfType } from 'flow-types/ObjOfType';
import type { IComment } from 'flow-types/entities/Comment';
import Form from 'common/components/Formik/Form';
import Textarea from 'common/components/Formik/Textarea';
import { DATE_FNS_FORMAT } from 'utils/config';
import SideProcessBridge from 'common/components/Formik/SideProcessBridge';
import getDuration from 'common/helpers/getDuration';
import Button from '../components/Button';

type QuestionAnswerCommentsProps = {
  comments: Array<Object>,
  disabled: boolean,
  editable: boolean,
  // is called just before submission,
  // can be used to slightly change data of comment
  // before sending to server
  onBeforeCommentSubmit?: null | ((values: Object, formik: Object) => ?Object),
  onCommentsChange: Function
};

const StyledComments = styled.div`
  max-height: 24rem;
  max-width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AnswerComment = ({
  data,
  onRemove,
  onSelect,
  disabled,
  removable,
  active
}: {
  data: Object,
  onRemove: Function,
  onSelect: Function,
  disabled: boolean,
  removable: boolean,
  active: boolean
}) => {
  // TODO: в целом понимание что здесь может быть offset пока ОК,
  //  пока этот компонент используется только для блока
  const metadata = React.useMemo(() => {
    let result = {
      timeAgo: {
        value: (
          <FormattedRelativeTime
            updateIntervalInSeconds={5}
            numeric="auto"
            value={getDuration(data.dateCreated, Date.now(), {
              unit: 'second'
            })}
          />
        )
      }
    };

    // Если по какой-то причине у комментария
    // стоит offset, то мы его выводим
    if (data.offset) {
      result = {
        ...result,
        offset: {
          value: data.offset
        }
      };
    }

    return result;
  }, [data.dateCreated, data.offset]);

  const actions = React.useMemo(
    () =>
      disabled
        ? // TODO: resolve later
          // $FlowIgnore
          null
        : // TODO: resolve later
          // $FlowIgnore
          {
            edit: {
              label: <FormattedMessage id="common.labels.edit" />
            },
            ...(removable
              ? {
                  remove: {
                    label: <FormattedMessage id="common.labels.delete" />
                  }
                }
              : {})
          },
    [disabled, removable]
  );

  const handleAction = React.useCallback(
    action => {
      if (action === 'edit') {
        onSelect?.(data.id);
      }
      if (action === 'remove') {
        onRemove?.(data.id);
      }
    },
    [data.id, onRemove, onSelect]
  );

  return (
    <Comment
      active={active}
      body={data.body}
      actions={actions}
      metadata={metadata}
      onActionClick={handleAction}
    />
  );
};

const CommentSchema = Yup.object().shape({
  body: Yup.string().required()
});

const Comments = ({
  comments,
  onCommentsChange,
  onBeforeCommentSubmit,
  disabled,
  editable
}: QuestionAnswerCommentsProps): React.Node => {
  const formikRef = React.useRef();

  const commentsRef = React.useRef<HTMLElement | null>(null);

  const sideProcessId = React.useMemo(() => uuid(), []);

  const intl = useIntl();

  const commentsMap = React.useRef<$ObjOfType<IComment>>(keyBy(comments, 'id'));

  const commentsIndexes = React.useRef<$ObjOfType<number>>(
    reduce(
      comments,
      (result, comment, index) => ({
        [comment.id]: index
      }),
      {}
    )
  );

  React.useEffect(() => {
    commentsMap.current = keyBy(comments, 'id');
    commentsIndexes.current = reduce(
      comments,
      (result, comment, index) => ({
        [comment.id]: index
      }),
      {}
    );
  }, [comments]);

  const [comment, setComment] = React.useState<Object>({
    body: '',
    dateCreated: null
  });

  const selectComment = React.useCallback(commentId => {
    if (!commentsMap.current[commentId]) {
      return;
    }

    setComment(commentsMap.current[commentId]);
  }, []);

  const submitComment = React.useCallback(
    (values, actions) => {
      let commentToSubmit = { ...values };

      if (onBeforeCommentSubmit) {
        // if onBeforeCommentSubmit returns something,
        // it is considered as updated comment data
        commentToSubmit =
          onBeforeCommentSubmit(formikRef, commentToSubmit) ?? commentToSubmit;
      }

      if (!commentToSubmit.id) {
        commentToSubmit.id = `comment-${uuid()}`;
        commentToSubmit.dateCreated = format(new Date(), DATE_FNS_FORMAT);
        commentToSubmit.isNew = true;
      }

      if (size(comments) === 0) {
        onCommentsChange([commentToSubmit]);
        setComment({ body: '', dateCreated: null });
        actions.setSubmitting(false);
        actions.resetForm({ values: { body: '', dateCreated: null } });
      }

      if (!commentsMap.current[commentToSubmit.id]) {
        onCommentsChange([...comments, commentToSubmit]);
        setComment({ body: '', dateCreated: null });
        actions.setSubmitting(false);
        actions.resetForm({ values: { body: '', dateCreated: null } });
      }

      const nextComments = [...comments];

      const commentIndex = commentsIndexes.current[commentToSubmit.id];

      nextComments[commentIndex] = commentToSubmit;

      onCommentsChange(nextComments);
      setComment({ body: '', dateCreated: null });
      actions.setSubmitting(false);
      actions.resetForm({ values: { body: '', dateCreated: null } });
    },
    [comments, onBeforeCommentSubmit, onCommentsChange]
  );

  const removeComment = React.useCallback(
    commentId => {
      onCommentsChange(
        filter(
          comments,
          c =>
            // eslint-disable-next-line eqeqeq
            c.id != commentId
        )
      );
    },
    [comments, onCommentsChange]
  );

  const commentsLength = comments?.length ?? 0;

  React.useLayoutEffect(() => {
    const scrollHeight = commentsRef.current?.scrollHeight ?? 0;
    const clientHeight = commentsRef.current?.clientHeight ?? 0;

    if (commentsLength > 1 && scrollHeight > clientHeight) {
      commentsRef.current?.scrollTo({
        top: scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [commentsLength]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={comment}
        onSubmit={submitComment}
        enableReinitialize
        isInitialValid
        validationSchema={CommentSchema}
      >
        <Form preventEventFlow className="reply">
          <Textarea disabled={disabled} name="body" rows="5" />
          <Button buttonType="primary" type="submit" disabled={disabled}>
            <FormattedMessage
              id={
                !isNewElement(comment, 'id')
                  ? 'answer.comments.edit'
                  : 'answer.comments.add'
              }
            />
          </Button>
          <SideProcessBridge id={sideProcessId} />
        </Form>
      </Formik>
      <CommentsUI
        ref={commentsRef}
        tagName={StyledComments}
        threaded
        size="small"
      >
        {size(comments) === 0 && (
          <Comment body={intl.formatMessage({ id: 'answer.comments.empty' })} />
        )}
        {map(comments, (c, index) => (
          <AnswerComment
            disabled={!editable}
            active={comment.id === c.id}
            key={`comment-${index}-${c.id}`}
            data={c}
            removable={comment.id !== c.id}
            onRemove={removeComment}
            onSelect={selectComment}
          />
        ))}
      </CommentsUI>
    </>
  );
};

Comments.defaultProps = {
  onBeforeCommentSubmit: null
};

export default Comments;
