// @flow
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import {
  mergeMap,
  switchMap,
  catchError,
  map,
  filter,
  pluck
} from 'rxjs/operators';
import { decamelizeKeys } from 'humps';
import { toast } from 'react-toastify';
import request from 'utils/request';
import { API } from 'utils/config';
import interpolateString from 'common/helpers/interpolateString';
import { toClientMap } from 'common/transducers/projects/statisticTablesSettings/toClient';

import type { Epic } from 'flow-types/Epic';

import listResponseParser from 'common/epicHelpers/listResponseParser';
import responseParser from 'common/epicHelpers/responseParser';

import toBackend from 'common/transducers/projects/statisticTablesSettings/toBackend';
import toClient from 'common/transducers/projects/statisticTables/toClient';

import closeModal from '../../../actions/modals/close';

const savePivotTableEpic: Epic = action$ =>
  action$.pipe(
    ofType('pivot-table/save-request'),
    // TODO: Если операция сохранения лочит любой UI, который может вызвать этот метод повторно,
    //  то данная тема не нужна
    // distinctUntilKeyChanged(
    //   'payload',
    //   (prev, curr) => prev.projectId !== curr.projectId || prev.id !== curr.id
    // ),
    switchMap(action => {
      const url = action.tableId
        ? API.projects.statisticTablesSettings.detail
        : API.projects.statisticTablesSettings.list;

      const method = action.tableId ? 'PUT' : 'POST';

      return request({
        url: interpolateString(url, {
          projectId: action.projectId,
          // it will be replaced only if URL has corresponding placeholder
          settingsId: action.tableId
        }),
        method,
        body: toBackend(action.payload)
      }).pipe(
        mergeMap(() => {
          const events = [
            {
              type: 'pivot-table/save-success',
              tableId: action.tableId,
              projectId: action.projectId
            }
          ];

          if (action.closeModalOnComplete) {
            events.push(closeModal(action.closeModalOnComplete, true));
          }

          return events;
        }),
        catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'pivot-table/save-failure'
          });
        })
      );
    })
  );

const deletePivotTableEpic = action$ =>
  action$.pipe(
    ofType('pivot-table/delete-request'),
    switchMap(action =>
      request({
        url: interpolateString(API.projects.statisticTablesSettings.detail, {
          projectId: action.projectId,
          settingsId: action.tableId
        }),
        method: 'DELETE'
      }).pipe(
        map(() => ({
          type: 'pivot-table/delete-success',
          projectId: action.projectId
        })),
        catchError(({ message }) => {
          toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500
          });
          return of({
            type: 'pivot-table/delete-failure'
          });
        })
      )
    )
  );

const fetchPivotTablesEpic = action$ =>
  action$.pipe(
    // TODO: flow types for events
    ofType(
      'pivot-table/fetch-request',
      'pivot-table/save-success',
      // 'pivot-table/create-success',
      // 'pivot-table/update-success',
      'pivot-table/delete-success'
    ),
    switchMap(action =>
      request({
        url: interpolateString(API.projects.statisticTablesSettings.list, {
          projectId: action.projectId
        })
      }).pipe(
        responseParser,
        listResponseParser,
        map(({ data, pagination }) => ({
          type: 'pivot-table/fetch-success',
          data: toClientMap(data),
          pagination
        })),
        catchError(() =>
          of({
            type: 'pivot-table/fetch-failure'
          })
        )
      )
    )
  );

const fetchPivotTableStatisticEpic = action$ =>
  action$.pipe(
    // TODO: flow types for events
    ofType('pivot-table/fetch-statistic-request', 'pivot-table/save-success'),
    filter(action => !!action.tableId),
    mergeMap(({ projectId, tableId }) =>
      request({
        url: interpolateString(API.projects.statisticTables.list, {
          projectId
        }),
        method: 'POST',
        body: decamelizeKeys({
          userNewStatisticTableSettingId: tableId
        })
      }).pipe(
        responseParser,
        pluck('data'),
        map(data => ({
          type: 'pivot-table/fetch-statistic-success',
          data: toClient(data),
          projectId,
          tableId
        })),
        catchError(() =>
          of({
            type: 'pivot-table/fetch-statistic-failure'
          })
        )
      )
    )
  );

export default combineEpics(
  savePivotTableEpic,
  deletePivotTableEpic,
  fetchPivotTablesEpic,
  fetchPivotTableStatisticEpic
);
