// @flow

import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';

import type { ExportAction } from 'flow-types/actions/projects/detail/export';
import type { ProjectExportSettingsFormState } from 'flow-types/states/ProjectsState/detail/ProjectExportState';
import type { ToggleExportSettingsField } from 'flow-types/actions/projects/detail/export/ToggleExportSettingsField';
import type { UpdateExportSettingsFilter } from 'flow-types/actions/projects/detail/export/UpdateExportSettingsFilter';
import type { UpdateExportSettingsTitle } from 'flow-types/actions/projects/detail/export/UpdateExportSettingsTitle';
import type { ToggleExportSettingsFlag } from 'flow-types/actions/projects/detail/export/ToggleExportSettingsFlag';
import type { IExportSettings$Settings } from 'flow-types/entities/ExportSettings';
import type { SetFormValues } from 'flow-types/actions/projects/detail/export/SetFormValues';
import type {
  SaveExportSettings,
  SaveExportSettingsSuccess
} from 'flow-types/actions/projects/detail/export/SaveExportSettings';
import type { $ActionType } from 'flow-types/ActionType';
import { EXPORT_FORMAT, EXPORT_FORMAT_FLAGS } from 'common/helpers/response';
import type { Reducer } from 'flow-types/Reducer';
import type { SetExportSettingsformat } from 'flow-types/actions/projects/detail/export/SetExportSettingsformat';

const createSettings = (): IExportSettings$Settings => ({
  format: EXPORT_FORMAT.EXCEL,
  fields: {},
  filters: {},
  flags: {
    showGroups: false,
    showViews: false,
    withAttachments: false,
    withAudio: false
  }
});

const createInitialState = (): ProjectExportSettingsFormState => ({
  cache: {
    settings: createSettings(),
    title: ''
  },
  data: {
    settings: createSettings(),
    title: ''
  },
  isRemoving: false,
  isSaving: false,
  isSavingAsNew: false
});

const handleSetFormat: Reducer<
  ProjectExportSettingsFormState,
  SetExportSettingsformat
> = (state, action) => {
  const { format } = action;

  const next = { ...state };

  if (!next.data.settings) {
    next.data.settings = createSettings();
  }

  next.data.settings = {
    ...next.data.settings,
    format: +format
  };

  // если выбран какой-либо формат,
  // то проверяем поддерживает ли он имеющиеся флаги,
  // все флаги, которые присутствуют в настройках,
  // но не поддерживаются - выключаются
  if (format) {
    forEach(next.data.settings.flags, (_, flag) => {
      // $FlowIgnore
      if (!EXPORT_FORMAT_FLAGS[format].includes(flag)) {
        next.data.settings.flags = {
          ...next.data.settings.flags,
          [flag]: false
        };
      }
    });
  }

  return next;
};

const handleFieldToggle = (
  state: ProjectExportSettingsFormState,
  action: ToggleExportSettingsField
) => {
  let next = { ...state };

  if (action.multiple) {
    action.fields.forEach(field => {
      next = handleFieldToggle(next, {
        type: action.type,
        field,
        forceState: action.forceState
      });
    });

    return next;
  }

  const { field, forceState } = action;

  if (!next.data.settings) {
    next.data.settings = createSettings();
  }

  // first case is when field is not presented in settings object
  if (!next.data.settings.fields[field.id]) {
    next.data.settings.fields[field.id] = {
      fieldType: field.type,
      fieldCode: field.id,
      exported: forceState !== 'off'
    };
  } else if (forceState === 'off') {
    next.data.settings.fields[field.id].exported = false;
  } else if (forceState === 'on') {
    next.data.settings.fields[field.id].exported = true;
  } else {
    next.data.settings.fields[field.id].exported = !next.data.settings.fields[
      field.id
    ].exported;
  }

  return next;
};

const handleFlagToggle = (
  state: ProjectExportSettingsFormState,
  action: ToggleExportSettingsFlag
) => {
  const next = { ...state };

  if (!next.data.settings) {
    next.data.settings = createSettings();
  }

  if (!next.data.settings.flags[action.flagCode]) {
    next.data.settings.flags[action.flagCode] = true;
  } else {
    next.data.settings.flags[action.flagCode] = !next.data.settings.flags[
      action.flagCode
    ];
  }

  return next;
};

function handleFilterUpdate(
  state: ProjectExportSettingsFormState,
  action: UpdateExportSettingsFilter
): ProjectExportSettingsFormState {
  const next: ProjectExportSettingsFormState = { ...state };

  if (!next.data.settings) {
    next.data.settings = {
      ...createSettings(),
      filters: {
        ...action.data
      }
    };
  } else {
    next.data.settings.filters = {
      ...next.data.settings.filters,
      ...action.data
    };
  }

  return next;
}

function handleTitleChange(
  state: ProjectExportSettingsFormState,
  action: UpdateExportSettingsTitle
) {
  const next = { ...state };

  next.data.title = action.data;

  return next;
}

function setFormValues(
  state: ProjectExportSettingsFormState,
  action: SaveExportSettingsSuccess | SetFormValues
) {
  const next = { ...state };

  next.data = cloneDeep(action.data);
  next.cache = cloneDeep(action.data);

  next.isSavingAsNew = false;
  next.isSaving = false;

  return next;
}

function handleSettingsSaveStart(
  state: ProjectExportSettingsFormState,
  action: SaveExportSettings
) {
  const next = { ...state };

  if (action.asNew) {
    next.isSavingAsNew = true;
  } else {
    next.isSaving = true;
  }

  return next;
}

function handleSettingsRemoveStart(
  state: ProjectExportSettingsFormState
): ProjectExportSettingsFormState {
  const next = { ...state };

  next.isRemoving = true;

  return next;
}

export const PROJECT_EXPORT_TOGGLE_SETTINGS_FIELD: $ActionType<ToggleExportSettingsField> =
  'project-export/toggle-settings-field';

// TODO: make it mutable-safer
export default function settingsFormReducer(
  state: ProjectExportSettingsFormState = createInitialState(),
  action: ExportAction
): ProjectExportSettingsFormState {
  switch (action.type) {
    case 'project-export/set-settings-format':
      return handleSetFormat(state, action);

    case 'project-export/reset-form-values':
      return {
        ...state,
        data: cloneDeep(state.cache)
      };

    case 'project-export/save-settings':
      return handleSettingsSaveStart(state, action);

    case 'project-export/save-settings-fail':
      return {
        ...state,
        isSavingAsNew: false,
        isSaving: false
      };

    case 'project-export/save-settings-success':
    case 'project-export/set-form-values':
      return setFormValues(state, action);

    case 'project-export/update-settings-title':
      return handleTitleChange(state, action);

    case 'project-export/update-settings-filter':
      return handleFilterUpdate(state, action);

    case PROJECT_EXPORT_TOGGLE_SETTINGS_FIELD:
      return handleFieldToggle(state, action);

    case 'project-export/toggle-settings-flag':
      return handleFlagToggle(state, action);

    case 'project-export/remove-settings':
      return handleSettingsRemoveStart(state);

    case 'project-export/remove-settings-fail':
      return {
        ...state,
        isRemoving: false
      };

    case 'project-export/reset-form':
    case 'project-export/remove-settings-success':
      return createInitialState();

    default:
      return state;
  }
}
