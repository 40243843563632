// @flow

import React, { useMemo } from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { AjaxResponse } from 'rxjs/ajax';

import { AsyncDropdown } from 'common/components/Dropdown';
import interpolateString from 'common/helpers/interpolateString';

import request from 'utils/request';
import { API } from 'utils/config';

import type { FetcherFn } from 'common/components/Dropdown/flow';
import type { IProject } from 'flow-types/entities/Project';
import { useSelector } from 'react-redux';
import createDeepEqualSelector from 'common/helpers/createDeepEqualSelector';
import normalizeCompanies from 'common/transducers/companies/normalizers';
import type { IDepartment } from 'flow-types/entities/Department';
import Icon from 'common/components/Icon';
import { projectFinalDataSelector } from '../../../selectors/projects';

type Props = {
  value?: null | number,
  onChange?: null | ((value: number | number[]) => void)
};

const fetcher: FetcherFn = async (filter, query) => {
  const { companyId } = filter;

  const {
    response: { data }
  }: AjaxResponse = await request({
    url: interpolateString(API.departments.list, { company_id: companyId }),
    query: {
      search: query
    }
  }).toPromise();

  return data;
};

const projectCompanyIdSelectorFactory = () =>
  createDeepEqualSelector(projectFinalDataSelector, (project: IProject) => ({
    companyId: project.companyId,
    departmentIds: map(project.departments, dep => dep.id)
  }));

function itemRenderer(item, asLabel) {
  const { assignedToProject, title } = item;

  return (
    <>
      {!asLabel && assignedToProject && <Icon icon="small star" />}
      {title}
    </>
  );
}

export default function ProjectDepartmentsSelector({
  value,
  onChange,
  ...rest
}: Props) {
  const selector = useMemo(projectCompanyIdSelectorFactory, []);

  const { companyId, departmentIds } = useSelector(selector);

  const filter = useMemo(() => ({ companyId }), [companyId]);

  const dataFormatter = useMemo(
    () => data =>
      map(normalizeCompanies(data), (department: IDepartment) => ({
        ...department,
        assignedToProject: includes(departmentIds, department.id)
      })),
    [departmentIds]
  );

  return (
    <AsyncDropdown
      {...rest}
      value={value}
      onChange={onChange}
      fetcher={fetcher}
      filter={filter}
      dataFormatter={dataFormatter}
      renderItem={itemRenderer}
      valueKey="id"
      labelKey="title"
      onlyValue
    />
  );
}

ProjectDepartmentsSelector.defaultProps = {
  onChange: null,
  value: null
};
