import type { EnablingTestElementsState } from 'flow-types/states/ProjectsState/detail';
import type { ProjectAction } from '../../flow-types/actions/projects/detail/project';

const initialState: EnablingTestElementsState = [];

export default function enablingTestElements(
  state: EnablingTestElementsState = initialState,
  action: ProjectAction
) {
  switch (action.type) {
    case 'project-responses/enable-test-element':
      return [...state, action.testElementId];
    case 'project-responses/enable-test-element-fail':
    case 'project-responses/enable-test-element-success':
      return state.filter(
        testElementId => testElementId !== action.testElementId
      );
    default:
      return state;
  }
}
