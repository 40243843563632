const userRolesDictionary = {
  en: {
    'userRoles.admin': 'Admin',
    'userRoles.operator': 'Operator',
    'userRoles.manager': 'Manager',
    'userRoles.companyAdmin': 'Company admin',
    'userRoles.divisionHead': 'Division head',
    'userRoles.respondent': 'Respondent',
    'userRoles.expert': 'Specialist'
  },
  ru: {
    'userRoles.admin': 'Админ',
    'userRoles.operator': 'Оператор',
    'userRoles.manager': 'Менеджер',
    'userRoles.companyAdmin': 'Администратор компании',
    'userRoles.divisionHead': 'Руководитель отдела',
    'userRoles.respondent': 'Респондент',
    'userRoles.expert': 'Эксперт'
  }
};

export default userRolesDictionary;
