import revertDictionary from 'utils/revertDictionary';

const accessSettingsFormDictionary = {
  'accessSettingsForm.title': {
    en: 'Access rules editor',
    ru: 'Редактор прав доступа'
  },
  'accessSettingsForm.fields.title.label': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'accessSettingsForm.fields.isDefault.label': {
    en: 'Use by default',
    ru: 'Использовать по умолчанию'
  },
  'accessSettingsForm.fields.settingsTable.columns.title': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'accessSettingsForm.fields.settingsTable.columns.type': {
    en: 'Block type',
    ru: 'Тип блока'
  },
  'accessSettingsForm.fields.settingsTable.columns.editable': {
    en: 'Editable',
    ru: 'Редактируемый'
  },
  'accessSettingsForm.fields.settingsTable.columns.visible': {
    en: 'Visible',
    ru: 'Видимый'
  },
  'accessSettingsForm.fields.settingsTable.columns.disabled': {
    en: 'Disabled',
    ru: 'Заблокирован'
  },
  'accessSettingsForm.fields.settingsTable.columns.hidden': {
    en: 'Hidden',
    ru: 'Скрыт'
  }
};

export default revertDictionary(accessSettingsFormDictionary);
