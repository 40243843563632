import revertDictionary from 'utils/revertDictionary';

const comparatorsDictionary = {
  'comparators.formTitle.create': {
    en: 'Register test element',
    ru: 'Регистрация тест элемента'
  },
  'comparators.formTitle.edit': {
    en: 'Edit test element',
    ru: 'Обновление тест элемента'
  },
  'comparators.form.title': {
    en: 'Title',
    ru: 'Название'
  },
  'comparators.form.description': {
    en: 'Description',
    ru: 'Описание'
  },
  'comparators.form.points': {
    en: 'Points',
    ru: 'Баллы'
  },
  'comparators.form.checklist': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'comparators.form.testGroup': {
    en: 'Test Group',
    ru: 'Тест группа'
  },
  'comparators.form.keywords': {
    en: 'Keywords',
    ru: 'Ключевые слова'
  },
  'comparators.form.keywords.fullMatch': {
    en: 'Full match',
    ru: 'Полное совпадение'
  },
  'comparators.form.keywords.partialMatch': {
    en: 'Partial match',
    ru: 'Частичное совпадение'
  },
  'comparators.list.noItemsPlaceholder': {
    en: 'No checklists found',
    ru: 'Нет данных по тест-элементам'
  },
  'comparators.list.noSelectedGroupPlaceholder': {
    en: 'Select group to view its test elements',
    ru: 'Выберите группу для просмотра её элементов'
  },
  'comparators.actions.remove.message': {
    en: 'Are you definitely want to delete test element?',
    ru: 'Вы действительно хотите удалить тест-элемент?'
  },
  'comparators.form.tags.title.label': {
    en: 'Tag title',
    ru: 'Название тэга'
  },
  'comparators.form.rates.tag.placeholder': {
    en: 'Select tag',
    ru: 'Выберите тэг'
  },
  'checklistItem.availableWithoutAudio': {
    en: 'Audio fragment not required',
    ru: 'Разметка региона не обязательна'
  }
};

export default revertDictionary(comparatorsDictionary);
