import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import type { AxisSettings } from '../../../../pages/Project/components/Panels/ReportsPanel/PivotTables/flow';

const sort = arr =>
  sortBy(arr, option => option.sortOrder ?? option.title ?? option.name);

const sortDataSourceOptions = sourceName => statisticTable => {
  let { [sourceName]: sourceData } = statisticTable;

  if (['interviewers', 'experts', 'departments'].includes(sourceName)) {
    return {
      ...statisticTable,
      [sourceName]: sort(sourceData)
    };
  }

  if (sourceData.options) {
    sourceData = {
      ...sourceData,
      options: sort(sourceData.options)
    };
  }

  return {
    ...statisticTable,
    [sourceName]: sourceData
  };
};

/**
 * This method resolves data source for a selected axis item,
 * i.e. for each row in rows and for each column in columns.
 */
function resolveAxis(row: AxisSettings, { statistic }) {
  let result = [];

  // TODO: resolve later flow types
  const {
    questions,
    departments,
    interviewers,
    experts,
    checklists,
    checklistGroups,
    checklistItems
  } = statistic;

  if (row.field === 'expert_id') {
    result = experts;
  } else if (row.field === 'interviewer_id') {
    result = interviewers;
  } else if (row.field === 'department_id') {
    result = departments;
  } else if (row.questionId) {
    result = find(questions, { id: row.questionId })?.options;
  } else if (row.checklistId) {
    result = checklists;
  } else if (row.checklistGroupId) {
    result = checklistGroups;
  } else if (row.checklistItemId) {
    result = find(checklistItems, { id: row.checklistItemId })?.options;
  }

  return result ?? [];
}

const resolveAxisItemsOptions = statisticTable => ({
  ...statisticTable,
  columnsSources: statisticTable.settings.columns.map(column =>
    resolveAxis(column, { statistic: statisticTable })
  ),
  rowsSources: statisticTable.settings.rows.map(row =>
    resolveAxis(row, { statistic: statisticTable })
  )
});

const isChecklistOrChecklistGroupAxis = (axisItem: AxisSettings) =>
  axisItem.checklistId || axisItem.checklistGroupId;

const resolveMode = statisticTable => {
  /*
  Есть 4 мода:
  null
  rowsAvg
  colsAvg
  tableAvg

  rowsAvg если:
    а) задано среднее и направление по строкам или же;
    б) задано чеклисты и есть указанные чеклисты в колонках;
  colsAvg:
    а) если задано среднее и направление по колонкам;
    б) задано чеклисты и есть указанные чеклисты в строках;

  tableAvg если задано среднее и направление по таблице в целом

  null во всех остальных случаях
  */
  const {
    settings: { resultType, resultDir, rows, columns }
  } = statisticTable;

  let mode = null;

  const rowsAverage =
    (resultType === 'average' && resultDir === 'rows') ||
    (resultType === 'checklist' &&
      columns.some(item => isChecklistOrChecklistGroupAxis(item)));

  const colsAverage =
    (resultType === 'average' && resultDir === 'columns') ||
    (resultType === 'checklist' &&
      rows.some(item => isChecklistOrChecklistGroupAxis(item)));

  const isTableAverage = resultType === 'average' && resultDir === 'table';

  if (rowsAverage) {
    mode = 'rowsAvg';
  } else if (colsAverage) {
    mode = 'colsAvg';
  } else if (isTableAverage) {
    mode = 'tableAvg';
  }

  return {
    ...statisticTable,
    mode
  };
};

const toClient = fp.compose(
  resolveMode,
  resolveAxisItemsOptions,
  sortDataSourceOptions('questions'),
  sortDataSourceOptions('checklists'),
  sortDataSourceOptions('checklistGroups'),
  sortDataSourceOptions('checklistItems'),
  sortDataSourceOptions('departments'),
  sortDataSourceOptions('interviewers'),
  sortDataSourceOptions('experts'),
  camelizeKeys
);

export const toClientMap = fp.map(toClient);

export default toClient;
