import React, { useCallback, useMemo, useState } from 'react';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { TableCell, TableRow } from 'common/components/Table';
import Checkbox from 'common/components/Checkbox';
import Icon from 'common/components/Icon';

import type { IQuestionGroup } from 'flow-types/entities/QuestionGroup';

import type { AccessSettingsForm as IAccessSettingsForm } from 'flow-types/entities/AccessSettings';
import QuestionAccessSettings from './QuestionAccessSettings';
import useRowUniqColor from './useRowUniqColor';
import { useAccessTypes } from './AccessTypesProvider';

type Props = {|
  data: IQuestionGroup,
  settings: IAccessSettingsForm,
  onChange: (prev: IAccessSettingsForm) => IAccessSettingsForm,
  disabled?: boolean
|};

export default function GroupAccessSettings({
  data,
  settings,
  onChange,
  disabled
}: Props) {
  const color = useRowUniqColor();

  const accessTypes = useAccessTypes();

  const [isExpanded, setIsExpanded] = useState(false);

  const groupId = useMemo(() => data.id, [data.id]);

  const groupAccess = useMemo(() => {
    if (!settings) return null;

    const { questionGroupsAccess } = settings;

    if (!questionGroupsAccess || !questionGroupsAccess[groupId]) {
      return null;
    }

    return questionGroupsAccess[groupId].access;
  }, [groupId, settings]);

  const questions = useMemo(() => data.questions, [data.questions]);

  const questionsIds = useMemo(() => map(questions, q => q.id), [questions]);

  const name = useMemo(() => `group-${groupId}-access`, [groupId]);

  const handleGroupAccessSettingsChange = useCallback(
    nextAccess => {
      onChange((prev: IAccessSettingsForm): IAccessSettingsForm => {
        const next = { ...prev };

        if (!next.questionGroupsAccess) {
          next.questionGroupsAccess = {};
        }

        // create or update access settings for a group
        if (!next.questionGroupsAccess[groupId]) {
          next.questionGroupsAccess[groupId] = {
            projectAccessSettingsId: next.id,
            access: nextAccess,
            questionGroupId: groupId
          };
        } else {
          next.questionGroupsAccess[groupId].access = nextAccess;
        }

        // filter out all access rules for questions inside that group
        next.questionsAccess = reduce(
          next.questionsAccess,
          (result, questionAccess, questionId) => {
            if (questionsIds.includes(+questionId)) {
              return {
                ...result,
                [questionId]: null
              };
            }

            return {
              ...result,
              [questionId]: questionAccess
            };
          },
          {}
        );

        return next;
      });
    },
    [groupId, onChange, questionsIds]
  );

  const handleQuestionAccessSettingsChange = useCallback(
    (questionId, nextAccess) => {
      onChange((prev: IAccessSettingsForm): IAccessSettingsForm => {
        const next = { ...prev };

        let cachedGroupAccess = null;
        // check if group access settings in action

        if (!next.questionGroupsAccess) {
          next.questionGroupsAccess = {};
        }

        if (next.questionGroupsAccess[groupId]) {
          cachedGroupAccess = next.questionGroupsAccess[groupId].access;
          // remove question group access
          delete next.questionGroupsAccess[groupId];
        }

        if (!next.questionsAccess) {
          next.questionsAccess = {};
        }

        if (!next.questionsAccess[questionId]) {
          next.questionsAccess[questionId] = {
            access: nextAccess,
            questionId,
            projectAccessSettingsId: next.id
          };
        } else {
          next.questionsAccess[questionId].access = nextAccess;
        }

        // if group had access settings set, set it to all of the rest questions
        if (cachedGroupAccess) {
          const restIds = questionsIds.filter(qId => qId !== questionId);

          restIds.forEach(qId => {
            if (!next.questionsAccess[qId]) {
              next.questionsAccess[qId] = {
                access: cachedGroupAccess,
                questionId: qId,
                projectAccessSettingsId: next.id
              };
            } else {
              next.questionsAccess[qId].access = cachedGroupAccess;
            }
          });
        }

        return next;

        // if so, then set this settings to all questions expect changed one
        // create or update access settings for a question
      });
    },
    [groupId, onChange, questionsIds]
  );

  return (
    <>
      <TableRow className={isExpanded ? `left marked ${color}` : null}>
        <TableCell collapsing>
          <Icon
            className="fitted"
            icon={!isExpanded ? 'chevron up' : 'chevron down'}
            onClick={() => setIsExpanded(prev => !prev)}
          />
        </TableCell>
        <TableCell colSpan={2}>{data.title}</TableCell>
        {accessTypes.map(type => (
          <TableCell key={type.value}>
            <Checkbox
              name={name}
              value={type.value === groupAccess}
              disabled={disabled}
              radio
              fitted
              onChange={() => handleGroupAccessSettingsChange(type.value)}
            />
          </TableCell>
        ))}
      </TableRow>
      {isExpanded
        ? map(questions, question => (
            <QuestionAccessSettings
              disabled={disabled}
              data={question}
              key={question.id}
              settings={settings}
              color={color}
              onChange={handleQuestionAccessSettingsChange}
            />
          ))
        : null}
    </>
  );
}

GroupAccessSettings.defaultProps = {
  disabled: false
};
