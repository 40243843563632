import styled, { css } from 'styled-components';

export const TableQuestionBodyContainer = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: auto;
`;

export const Image = styled.div`
  width: 100%;
  height: 6.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${props => props.src && `background-image: url(${props.src});`}
`;

export const Controls = styled.div`
  margin-top: 0.5rem;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .ui.ui.ui.ui.checkbox {
    float: right;
  }

  .question__options-item-label {
    float: left;
  }
`;

const imagePropStylesForOption = props => {
  if (!props.image) {
    return css`
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    `;
  }

  return css`
    width: 10rem;
    position: relative;
  `;
};

export const Option = styled.div`
  ${imagePropStylesForOption};
`;

// TODO: may be we can update fomantic-ui original css using overrides
//  to allow custom options in checkboxes lists
export const OtherOption = styled.div`
  ${imagePropStylesForOption};
  & > .content > .field > .ui.input:nth-child(2) {
    margin-top: 0.5rem;
    margin-left: 1.85714em;
    &.fluid {
      width: calc(100% - 1.85714em);
    }
  }
`;

const headerColor = (props: { light: boolean }) => {
  const { light } = props;

  if (light) {
    return 'color: rgba(0, 0, 0, .6)';
  }

  return null;
};

export const Header = styled.h3`
  font-weight: 400;
  ${headerColor};
  max-width: 100%;
`;

export const Hint = styled.div`
  margin-bottom: 1em;
  max-width: 100%;
  width: 100%;
`;

export const Content = styled.div`
  overflow-wrap: break-word;
  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

export const IdBar = styled.div`
  flex-shrink: 1;
`;

export const ContentBar = styled.div`
  flex: 1;
  max-width: 100%;
`;

export const PolygonImage = styled.div`
  position: relative;

  & > .polygon-image__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  margin-bottom: 1em;
`;

export const StyledImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledPolygonSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledPolygon = styled.polygon`
  cursor: pointer;
  transition: fill 0.3s;
  fill: transparent;

  ${props =>
    props.selected &&
    css`
      fill: rgba(251, 197, 27, 0.3) !important;
    `};

  ${props =>
    props.hovered &&
    css`
      fill: rgba(255, 255, 255, 0.3);
    `};

  :hover {
    fill: rgba(255, 255, 255, 0.3);
  }

  :active {
    fill: rgba(255, 255, 255, 0.5) !important;
  }
`;
