// @flow

import size from 'lodash/size';
import fp from 'lodash/fp';
import { camelizeKeys } from 'humps';
import type {
  FilterSettings,
  FilterSettingsListGroup,
  FilterSettingsTest,
  IUnionOperator
} from 'flow-types/entities/FilterSettings';

import { recalculateOrder } from 'common/transducers/sortingItemsNormalizer';
import {
  encapsulateBracketsIntoTests,
  replaceArrayWithBrackets
} from '../logic/logicStructureFormatter';
import { flatMapTestGroup } from '../logic/flatMapTestGroup';
import { toClientFormat as parseValueToClientFormat } from '../parseSpecialTimeFormat';

import type { ITest } from '../../containers/TestsForm/flow';

const filterSettingsTestToTestNormalizer = (
  test: FilterSettingsTest | IUnionOperator
): ITest | IUnionOperator => {
  if (typeof test === 'string') return test;

  const {
    fieldName,
    questionId,
    operator,
    id,
    unionType,
    settings,
    value,
    ...safeData
  } = test;

  const parsedTest: $Shape<ITest> = {
    id: id || null,
    operator,
    unionType,
    settings: settings ?? {},
    value: value ?? null,
    ...safeData
  };

  if (fieldName) {
    parsedTest.source = fieldName;
  } else if (questionId) {
    parsedTest.source = questionId;
  }

  if (value && settings && settings.specialTime) {
    parsedTest.value = parseValueToClientFormat(value);
  }

  return parsedTest;
};

export const testsParser = fp.map(filterSettingsTestToTestNormalizer);

const conditionTestGroupsNormalizer = fp.map(
  fp.compose(
    encapsulateBracketsIntoTests,
    replaceArrayWithBrackets,
    flatMapTestGroup
  )
);

export const processFilterSettingsTests = (filterSettings: FilterSettings) => {
  const { testGroups, ...safeData } = filterSettings;

  let tests = conditionTestGroupsNormalizer(testGroups);

  // in case we passed everything inside a single root group
  if (size(tests) === 1 && Array.isArray(tests[0])) {
    // eslint-disable-next-line prefer-destructuring
    tests = tests[0];
  }

  tests = testsParser(tests);

  return {
    ...safeData,
    tests
  };
};

const normalizeFilterSettingsStyles = (
  filterSettings: FilterSettings
): FilterSettings => {
  const { bgColor, fontColor, ...rest } = filterSettings;

  return {
    ...rest,
    bgColor: !bgColor ? '' : bgColor,
    fontColor: !fontColor ? '' : fontColor
  };
};

const normalizeFilterSettingsPatterns = (
  filterSettings: FilterSettings
): FilterSettings => {
  const { responsePattern, notificationPattern, ...rest } = filterSettings;

  return {
    ...rest,
    responsePattern: responsePattern === null ? '' : responsePattern,
    notificationPattern: notificationPattern === null ? '' : notificationPattern
  };
};

export const normalizeFilterSettings = fp.compose(
  normalizeFilterSettingsPatterns,
  normalizeFilterSettingsStyles,
  processFilterSettingsTests,
  camelizeKeys
);

export const filterSettingsListNormalizer = fp.compose(
  recalculateOrder,
  fp.sortBy('sortOrder'),
  fp.map(normalizeFilterSettings)
);

export function baseFilterSettingsListGroupNormalizer(
  filterSettingsListGroup: FilterSettingsListGroup
) {
  const next = {
    ...filterSettingsListGroup
  };

  if (next.filterSettingsList) {
    next.filterSettingsList = filterSettingsListNormalizer(
      next.filterSettingsList
    );
  }

  return next;
}

export const normalizeFilterSettingsListGroup = fp.compose(
  baseFilterSettingsListGroupNormalizer,
  camelizeKeys
);

export const normalizeFilterSettingsListGroups = fp.compose(
  recalculateOrder,
  fp.sortBy('sortOrder'),
  fp.map(normalizeFilterSettingsListGroup)
);

export const flatGroupFilters = fp.flatMapDeep(
  ({ filterSettingsList }: FilterSettingsListGroup) => {
    if (!filterSettingsList) return [];

    return [...filterSettingsList];
  }
);
