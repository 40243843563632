// @flow

import React from 'react';
import cx from 'classnames';

import type { Node, Config, AbstractComponent } from 'react';
import type { NilValue } from 'flow-types/NilValue';
import type { ReactChildren } from 'flow-types/ReactChildren';

type Props = {
  as?: 'div' | 'ul' | 'ol',
  bulleted?: boolean,
  ordered?: boolean,
  suffixed?: boolean,
  link?: boolean,
  aligned?: NilValue | 'left' | 'middle' | 'right',
  horizontal?: boolean,
  inverted?: boolean,
  selection?: boolean,
  className?: string,
  animated?: boolean,
  relaxed?: boolean,
  divided?: boolean,
  celled?: boolean,
  nonUI?: boolean,
  fluid?: boolean,
  size?:
    | null
    | 'mini'
    | 'tiny'
    | 'small'
    | 'large'
    | 'big'
    | 'huge'
    | 'massive',
  children?: ReactChildren
};

type Cfg = Config<Props, { nonUI: false, as: 'div' }>;

const List: AbstractComponent<Cfg, any> = React.forwardRef(
  (
    {
      // eslint-disable-next-line react/prop-types
      as = 'div',
      // eslint-disable-next-line react/prop-types
      className,
      // eslint-disable-next-line react/prop-types
      aligned,
      // eslint-disable-next-line react/prop-types
      animated,
      // eslint-disable-next-line react/prop-types
      bulleted,
      // eslint-disable-next-line react/prop-types
      celled,
      // eslint-disable-next-line react/prop-types
      divided,
      // eslint-disable-next-line react/prop-types
      inverted,
      // eslint-disable-next-line react/prop-types
      horizontal,
      // eslint-disable-next-line react/prop-types
      link,
      // eslint-disable-next-line react/prop-types
      ordered,
      // eslint-disable-next-line react/prop-types
      relaxed,
      // eslint-disable-next-line react/prop-types
      selection,
      // eslint-disable-next-line react/prop-types
      size,
      // eslint-disable-next-line react/prop-types
      suffixed,
      // eslint-disable-next-line react/prop-types
      fluid,
      // eslint-disable-next-line react/prop-types
      nonUI,
      ...rest
    },
    ref
  ): Node => {
    const Component = as ?? 'div';

    const classNames = cx(
      { ui: !nonUI },
      {
        'left aligned': aligned === 'left',
        'middle aligned': aligned === 'middle',
        'right aligned': aligned === 'right',
        fluid,
        suffixed,
        selection,
        animated,
        ordered,
        link,
        horizontal,
        inverted,
        divided,
        celled,
        bulleted,
        relaxed
      },
      className,
      size,
      'list'
    );

    return <Component {...rest} className={classNames} ref={ref} />;
  }
);

List.displayName = 'List.ForwardRef';

// $FlowIgnore
List.defaultProps = ({
  as: 'div',
  nonUI: false,
  animated: false,
  children: null
}: Props);

export default List;
