import reduce from 'lodash/reduce';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { IQuestionId } from 'flow-types/entities/Question';
import type { NilValue } from 'flow-types/NilValue';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import every from 'lodash/every';
import findLast from 'lodash/findLast';

type GetStackByIdConfig = {
  // means that search will through the whole hierarchy,
  // i.e. if stack has items, then search will go through a whole items set
  recursive?: boolean,
  // means that is match has been found,
  // then only parent will be returned
  // for all sub-items of a stack where match has been found
  rootOnly?: boolean,
  // указывает минимальную глубину,
  // от которой будет считаться выбор возвращаемого
  // элемента при включённом rootOnly
  rootOnlyMinDepth?: number
};

type GetStackByIdFn = (
  string,
  Array<IInterviewStructureElement>,
  GetStackByIdConfig
) => IInterviewStructureElement | null;

// TODO: implement rootOnlyMinDepth option
export const getStackById: GetStackByIdFn = (
  stackId,
  structure,
  options = {}
) => {
  const { recursive = true, rootOnly = false } = options;

  if (!stackId) return null;

  return reduce(
    structure,
    (el, stack: IInterviewStructureElement) => {
      if (el) return el;

      const { localId, items } = stack;

      // eslint-disable-next-line eqeqeq
      if (localId == stackId) return stack;

      if (recursive && items) {
        const itemsMatch = getStackById(stackId, items, options);

        if (itemsMatch) {
          return rootOnly ? stack : itemsMatch;
        }
      }

      return el;
    },
    null
  );
};

export function getStackByQuestionId(
  structure: Array<IInterviewStructureElement>,
  id: IQuestionId,
  options: GetStackByIdConfig = {},
  rootDepth: number = -1
): NilValue | IInterviewStructureElement {
  const depth = rootDepth + 1; // on first call it should be be zero

  const { recursive = true, rootOnly = true, rootOnlyMinDepth = 0 } = options;

  return reduce(
    structure,
    (el, stack: IInterviewStructureElement) => {
      if (el) return el;

      const { items, questionId } = stack;

      // eslint-disable-next-line eqeqeq
      if (questionId == id) return stack;

      if (recursive && items) {
        const foundItem = getStackByQuestionId(items, id, options, depth);

        if (foundItem) {
          if (!rootOnly) {
            return foundItem;
          }

          // In case rootOnly, check for rootOnlyMinDepth.
          // Check for less or equal cannot be used,
          // because recursive call of getStackByQuestionId usage
          if (isNil(rootOnlyMinDepth) || depth === rootOnlyMinDepth) {
            return stack;
          }
        }
      }

      return el;
    },
    null
  );
}

export const getFirstVisible = (
  structure: Array<IInterviewStructureElement>
): IInterviewStructureElement | typeof undefined =>
  find(structure, (el: IInterviewStructureElement) => {
    const { hidden, items } = el;

    const isItemsHidden = every(
      items,
      (item: IInterviewStructureElement) => !!item.hidden
    );

    return !hidden || !isItemsHidden;
  });

export const getLastVisible = (
  structure: Array<IInterviewStructureElement>
): IInterviewStructureElement | typeof undefined =>
  findLast(structure, (el: IInterviewStructureElement) => {
    const { hidden, items } = el;

    const isItemsHidden = every(
      items,
      (item: IInterviewStructureElement) => !!item.hidden
    );

    return !hidden || !isItemsHidden;
  });
