// @flow

import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { mergeAndConcat } from 'merge-anything';

import configureValidator from 'common/validators/validateAnswer';
import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';

import type { IUpload } from 'flow-types/entities/Upload';
import type {
  IInterviewQuestion,
  IQuestion
} from 'flow-types/entities/Question';
import type { IInterviewStructureElement } from 'flow-types/entities/InterviewStructureElement';
import type { IInterviewAnswers$Map } from 'flow-types/states/InterviewState/InterviewAnswersState';

import type { Move } from 'flow-types/actions/interview/Move';
import type { AppState } from '../../../../flow-types/AppState';
import validateAttachments from '../../../../common/validators/validateAttachments';
import {
  interviewActiveStackDataSelector,
  interviewLogicAwaredStructureSelector
} from '../../../../selectors/interview/answers';
import {
  interviewAnswersSelector,
  interviewRecordSelector
} from '../../../../selectors/interview/root';
import { interviewQuestionsSelector } from '../../../../selectors/interview/questions';
import { hasAnswer } from '../../../../pages/Project/components/Answers/Answer';
import { interviewStatusesSelector } from '../../../../selectors/interview/statuses';
import { getValidationConfigForBlock } from './getValidationConfigForBlock';

export function validateAnswers(action: Move, state: AppState): any {
  const activeStackData: IInterviewStructureElement = interviewActiveStackDataSelector(
    state
  );

  if (!activeStackData) {
    return null;
  }

  const { data } = interviewRecordSelector(state);

  const interviewAttachments = (data && data.uploads) || [];

  const structure = interviewLogicAwaredStructureSelector(state);

  const answers = interviewAnswersSelector(state);

  const questions = interviewQuestionsSelector(state);

  const flatQuestions = flatQuestionsSubQuestions(questions);

  // const language = languageStateSelector(state);

  // if we decided to complete interview,
  // then we should check all non-hidden required stacks being answered,
  // if some of required questions are not answered
  // we show error message to user listing all required non-answered questions

  let validationResults = null;

  // we have three possible cases
  // 1 - when 'Complete' button is clicked
  // 2 - when current stack has items
  // 3 - when current stack represents one item
  if (action.complete) {
    // eslint-disable-next-line no-use-before-define
    validationResults = validateStructureElement({
      stack: { items: structure },
      questions: flatQuestions,
      attachments: interviewAttachments,
      answers
    });
  } else {
    const { isPostPolling, isPublicPolling } = interviewStatusesSelector(state);

    // eslint-disable-next-line no-use-before-define
    validationResults = validateStructureElement({
      stack: activeStackData,
      attachments: interviewAttachments,
      questions: flatQuestions,
      answers,
      avoidEmptyRequired: (isPostPolling || isPublicPolling) && action.backward
    });
  }

  if (!validationResults) return null;

  return validationResults;
}

type ValidateQuestionConfig = {
  questionId: number,
  questions: IInterviewQuestion[],
  attachments: IUpload[],
  answers: IInterviewAnswers$Map,
  /*
    Переход назад не будет вызывать проверку пустых обязательных блоков.
    Backward move will not check required questions w/o answer.
  */
  avoidEmptyRequired?: boolean
};

export function validateQuestion({
  questionId,
  answers,
  attachments,
  questions,
  // does not validate required blocks
  // if their answers are not set
  avoidEmptyRequired
}: ValidateQuestionConfig): any {
  let results = null;

  // find related question
  const question: IQuestion | typeof undefined = find(questions, {
    id: questionId
  });

  // if question is not found, return null
  // however in development it would be rather error case
  if (!question || question.hidden || question.disabled) {
    return null;
  }

  const { filesEnabled, maxFiles, minFiles, required } = question;

  const answer = answers[questionId] ?? null;

  if (required && avoidEmptyRequired && (!answer || !hasAnswer(answer))) {
    return null;
  }

  const questionAttachments = filter(attachments, { questionId });

  // validate given answer
  try {
    const config = getValidationConfigForBlock(question);

    const validator = configureValidator(config);

    validator.validateSync(answer ?? {}, { abortEarly: false });
  } catch (e) {
    results = mergeAndConcat(
      { ...(results && results) },
      {
        [questionId]: e.errors
      }
    );
  }

  // decide whether to check attachments or not
  let shouldCheckAttachments = false;
  if (filesEnabled && (minFiles > 0 || maxFiles > 0)) {
    shouldCheckAttachments = true;
  }

  // validate attachments
  if (shouldCheckAttachments) {
    try {
      validateAttachments(questionAttachments, {
        enabled: filesEnabled,
        max: maxFiles,
        min: minFiles
      });
    } catch (e) {
      results = mergeAndConcat(
        { ...results },
        {
          [questionId]: e.errors
        }
      );
    }
  }

  return results;
}

type ValidateStructureElementConfig = {
  stack: IInterviewStructureElement,
  questions: IInterviewQuestion[],
  attachments: IUpload[],
  answers: IInterviewAnswers$Map,
  /*
    Переход назад не будет вызывать проверку пустых обязательных блоков.
    Backward move will not check required questions w/o answer.
  */
  avoidEmptyRequired?: boolean
};

export function validateStructureElement({
  stack,
  questions,
  attachments,
  answers,
  /*
    Переход назад не будет вызывать проверку пустых обязательных блоков.
    Backward move will not check required questions w/o answer.
  */
  avoidEmptyRequired
}: ValidateStructureElementConfig): any {
  if (!stack.items) {
    if (stack.hidden || stack.disabled) return null;

    // eslint-disable-next-line no-use-before-define
    return validateQuestion({
      questionId: stack.questionId,
      attachments,
      questions,
      answers,
      avoidEmptyRequired
    });
  }

  return reduce(
    stack.items,
    (result, itemStack: IInterviewStructureElement) => {
      // if element is hidden or disabled, it can be not checked
      if (itemStack.hidden || itemStack.disabled) return result;

      // if result is null, then it cannot be destructured,
      // but otherwise it will be an object, thus - destructurable
      const nextResult = isNil(result) ? result : { ...result };

      const itemResult = validateStructureElement({
        stack: itemStack,
        questions,
        attachments,
        answers,
        avoidEmptyRequired
      });

      if (!itemResult) {
        return nextResult;
      }

      return mergeAndConcat(
        { ...(nextResult && nextResult) },
        { ...itemResult }
      );
    },
    null
  );
}
