const statusesDictionary = {
  en: {
    'common.statuses.new': 'New',
    'common.statuses.open': 'Opened',
    'common.statuses.registered': 'Registered',
    'common.statuses.inProgress': 'In progress',
    'common.statuses.pending': 'Pending',
    'common.statuses.ready': 'Ready',
    'common.statuses.failed': 'Failed',
    'common.statuses.active': 'Active',
    'common.statuses.preparing': 'Preparing',
    'common.statuses.outdated': 'Outdated',
    'common.statuses.archived': 'Archived',
    'common.statuses.saving': 'Saving',
    'common.statuses.loading': 'Loading',
    'common.statuses.unknown': 'Unknown'
  },
  ru: {
    'common.statuses.new': 'Новое',
    'common.statuses.open': 'Открыто',
    'common.statuses.registered': 'Зарегистрировано',
    'common.statuses.inProgress': 'В процессе',
    'common.statuses.pending': 'В очереди',
    'common.statuses.ready': 'Готово',
    'common.statuses.failed': 'Произошла ошибка',
    'common.statuses.active': 'Активно',
    'common.statuses.preparing': 'Подготовка',
    'common.statuses.outdated': 'Завершено',
    'common.statuses.archived': 'В архиве',
    'common.statuses.saving': 'Сохранение',
    'common.statuses.loading': 'Загрузка',
    'common.statuses.unknown': 'Неизвестно'
  }
};

export default statusesDictionary;
