import fp from 'lodash/fp';
import decamelizeKeys from '../../../helpers/decamelizeKeys';
import type {
  ChecklistItemOption,
  IChecklistItem
} from '../../../../flow-types/entities/ChecklistItem';

const ratesDenormalizer = fp.map((rate: ChecklistItemOption) => {
  const { id, title, value, isNew, checklistTagId } = rate;
  return {
    ...(!isNew && {
      id
    }),
    title,
    value,
    checklistTagId
  };
});

export const comparatorDenormalizer = fp.compose(
  decamelizeKeys,
  ({
    fullMatchKeywords,
    partialMatchKeywords,
    rates,
    id,
    isNew,
    ...safeData
  }: IChecklistItem) => ({
    ...(!isNew && { id }),
    ...safeData,
    options: ratesDenormalizer(rates),
    fullMatchKeywords: fullMatchKeywords
      ? fullMatchKeywords.split(';').filter(key => key.length > 0)
      : null,
    partialMatchKeywords: partialMatchKeywords
      ? partialMatchKeywords.split(';').filter(key => key.length > 0)
      : null
  })
);

export default fp.map(comparatorDenormalizer);
