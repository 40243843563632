import mapFp from 'lodash/fp/map';
import filterFp from 'lodash/fp/filter';
import compose from 'lodash/fp/compose';

import type {
  AccessSettings,
  AccessSettingsForm,
  QuestionAccessSettings,
  QuestionGroupAccessSettings
} from 'flow-types/entities/AccessSettings';
import { ACCESS_STATUSES } from 'common/helpers/project/constants';
import isNewElement from '../../../helpers/isNewElement';

const newElementSignsCleaner = element => {
  if (!isNewElement(element)) {
    return element;
  }

  const { isNew, id, ...rest } = element;

  return rest;
};

// creates 'isVisible' and 'isEditable' on basis of 'access' field
const processAccessField = (
  accessSettings: QuestionAccessSettings | QuestionGroupAccessSettings
) => {
  const { access, ...rest } = accessSettings;

  return {
    ...rest,
    isEditable: access === ACCESS_STATUSES.EDITABLE,
    isVisible:
      access === ACCESS_STATUSES.DISABLED || access === ACCESS_STATUSES.EDITABLE
  };
};

const denormalizeAccessSettingsList = compose(
  mapFp(processAccessField),
  mapFp(newElementSignsCleaner),
  // reemove nullables
  filterFp(Boolean)
);

export const denormalizeAccessSettings = (
  data: AccessSettingsForm
): AccessSettings => {
  const { id, title, isDefault, questionsAccess, questionGroupsAccess } = data;

  return {
    id,
    title,
    isDefault,
    questionGroupsAccess: denormalizeAccessSettingsList(questionGroupsAccess),
    questionsAccess: denormalizeAccessSettingsList(questionsAccess)
  };
};
