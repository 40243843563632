import { EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import * as RxOperators from 'rxjs/operators';

import type { ChangeActiveStack } from 'flow-types/actions/interview/ChangeActiveStack';
import type { Move } from 'flow-types/actions/interview/Move';
import type { Epic } from 'flow-types/Epic';

import { interviewStatusesSelector } from '../../selectors/interview/statuses';

const startOnInitialMove: Epic = (action$, state$) =>
  action$.pipe(
    ofType('interview/move'),
    RxOperators.withLatestFrom(state$),
    RxOperators.switchMap(([action, state]) => {
      const { stackId, forward, restart }: Move = action;
      const { running } = interviewStatusesSelector(state);

      // default for restart is 'true'
      if (typeof restart !== 'undefined' && !restart) {
        return EMPTY;
      }

      if ((!!stackId || forward) && !running) {
        // start interview after change-active-stack
        return action$.pipe(
          ofType('interview/change-active-stack'),
          RxOperators.filter(
            (changeStackAction: ChangeActiveStack) =>
              typeof changeStackAction.stackId !== 'undefined'
          ),
          RxOperators.map(() => ({ type: 'interview/start' }))
        );
      }

      return EMPTY;
    })
  );

export default startOnInitialMove;
