// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

import List from 'common/components/List';
import ListItem from 'common/components/ListItem';
import Link from 'common/components/Link';
import downloadFile from 'common/helpers/downloadFile';

import type { IUpload } from 'flow-types/entities/Upload';

import Icon from 'common/components/Icon';
import Flexbox from 'common/components/Flexbox';
import Image from 'common/components/Image';
import type { IInterviewProfileQuestion } from './flow';

type OnAttachmentClickCb = (attachment: IUpload, index: number) => void;

type Props = {|
  data?: IInterviewProfileQuestion[],
  // onAttachmentClick is not working for viewType link-list
  onAttachmentClick?: null | OnAttachmentClickCb,
  viewType?: 'preview' | 'link-list'
|};

const lazyProps = {
  scrollContainer: '.lazy-scroll-box'
};

const ImagesContainer = styled.div`
  margin-top: -0.25em;
  margin-right: -0.25em;

  & > * {
    margin-top: 0.25em;
  }
`;

// TODO: As soon as it is used in ResponsesPanel,
//  it should stick to the principles of common components
export default function Attachments({
  data,
  onAttachmentClick,
  viewType
}: Props): Node {
  if (!Array.isArray(data) || data.length === 0) return null;

  if (viewType === 'link-list') {
    return (
      <List link>
        {map(data, (attachment: IUpload, index) => (
          <ListItem
            as={Link}
            key={index}
            onClick={() => downloadFile(attachment)}
          >
            <Icon icon="download" />
            {attachment.originalFilename || attachment.filename}
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <Flexbox as={ImagesContainer} $direction="row" wrap="wrap">
      {map(data, (upload: IUpload, index) => {
        if (!upload.url) return null;

        const onClick = onAttachmentClick
          ? () => {
              onAttachmentClick(upload, index);
            }
          : null;

        return (
          <Image
            avatar
            rounded
            size="large"
            lazyProps={lazyProps}
            onClick={onClick}
            src={upload.thumb128 || upload.url}
            key={`upload-${upload.id}`}
          />
        );
      })}
    </Flexbox>
  );
}

Attachments.defaultProps = {
  data: [],
  onAttachmentClick: null,
  viewType: 'preview'
};
