import revertDictionary from 'utils/revertDictionary';

const dictionary = {
  'projectExportPanel.exportTasks.messages.taskIsReady': {
    ru: 'Экспорт #{taskId} по проекту #{projectId} подготовлен',
    en: 'Export #{taskId} in project #{projectId} is ready'
  },
  'projectExportPanel.exportTasks.messages.taskIsFailed': {
    ru:
      'Произошла ошибка при подготовке экспорта #{taskId} по проекту #{projectId}',
    en:
      'Error occurred while export task #{taskId} preparing for project #{projectId}'
  },
  'projectExportPanel.exportSettingsList.title': {
    en: 'Export settings',
    ru: 'Настройки экспорта'
  },
  'projectExportPanel.exportTasksList.title': {
    en: 'Export tasks',
    ru: 'Запросы на экспорт'
  },
  'projectExportPanel.actions.removeExportSettings': {
    ru: 'Вы точно хотите удалить эти настройки фильтра?',
    en: 'Are you definitely want to delete these export settings?'
  },
  'projectExportPanel.exportTasksTable.emptyData': {
    ru: 'История экспортов чиста',
    en: 'Export history is clear'
  },

  /**
   * SETTINGS FORM
   */
  'projectExportPanel.filterSettingsForm.title': {
    ru: 'Фильтр экспорта',
    en: 'Export filters'
  },

  'projectExportPanel.filterSettingsForm.actions.saveAsNew': {
    ru: 'Сохранить как новую настройку',
    en: 'Save as new'
  },

  'projectExportPanel.filterSettingsForm.fields.title.label': {
    ru: 'Название',
    en: 'Title'
  },

  'projectExportPanel.filterSettingsForm.fields.userFilterSettingId.label': {
    ru: 'Используемый фильтр настроек',
    en: 'Filter setting to use'
  },

  /**
   * FLAGS FORM
   */
  'projectExportPanel.extraSettingsForm.title': {
    ru: 'Дополнительные настройки',
    en: 'Advanced settings'
  },

  'projectExportPanel.filterFlagsForm.fields.showChecklists.label': {
    ru: 'Экспортировать оценки по чеклистам',
    en: 'Export checklist rates'
  },

  'projectExportPanel.filterFlagsForm.fields.withAudio.label': {
    ru: 'Экспортировать аудио-записи',
    en: 'Add audio to export'
  },

  'projectExportPanel.filterFlagsForm.fields.showAnswers.label': {
    ru: 'Показывать ответы',
    en: 'Show answers'
  },
  'projectExportPanel.filterFlagsForm.fields.showCodes.label': {
    ru: 'Показывать коды вопросов',
    en: 'Show questions codes'
  },
  'projectExportPanel.filterFlagsForm.fields.expandOptions.label': {
    ru: 'Выводить опции в отдельных колонках',
    en: 'Use separate column per question option'
  },
  'projectExportPanel.filterFlagsForm.fields.showComments.label': {
    ru: 'Показывать комментарии',
    en: 'Show comments'
  },

  'projectExportPanel.filterFlagsForm.fields.withAttachments.label': {
    ru: 'Экспортировать прикрепления',
    en: 'Add attachments to export'
  },

  'projectExportPanel.filterFlagsForm.fields.showGroups.label': {
    ru: 'Показать группы блоков',
    en: 'Show groups titles'
  },

  'projectExportPanel.filterFlagsForm.fields.showViews.label': {
    ru: 'Показать просмотры блоков',
    en: 'Show blocks views'
  },

  'projectExportPanel.filterFlagsForm.fields.showHistory.label': {
    ru: 'Показать историю изменений ответов',
    en: 'Show answers history of change'
  },

  /**
   * FIELDS FORM
   */
  'projectExportPanel.exportFieldsForm.title': {
    ru: 'Экспортируемые поля',
    en: 'Exported fields'
  },

  'projectExportPanel.fieldsForm.fields.project_id.label': {
    ru: 'ИД проекта',
    en: 'Project ID'
  },

  'projectExportPanel.fieldsForm.fields.response_local_id.label': {
    ru: 'Внутренний ИД записи',
    en: 'Record local id'
  },

  'projectExportPanel.fieldsForm.fields.interviewer_id.label': {
    ru: 'ИД интервьюера',
    en: "Interviewer's ID"
  },

  'projectExportPanel.fieldsForm.fields.interviewer_name.label': {
    ru: 'ФИО интервьюера',
    en: "Interviewer's full name"
  },

  'projectExportPanel.fieldsForm.fields.interviewer_email.label': {
    ru: 'Email интервьюера',
    en: "Inerviewer's email"
  },

  'projectExportPanel.fieldsForm.fields.meeting_guid.label': {
    ru: 'ГУИД встречи',
    en: "Meeting's GUID"
  },

  'projectExportPanel.fieldsForm.fields.meeting_respondent_name.label': {
    ru: 'Имя респондента',
    en: "Respondent's name"
  },

  'projectExportPanel.fieldsForm.fields.meeting_respondent_phone.label': {
    ru: 'Телефон респондента',
    en: "Respondent's phone"
  },

  'projectExportPanel.fieldsForm.fields.date_created.label': {
    ru: 'Дата встречи',
    en: 'Date created'
  },

  'projectExportPanel.fieldsForm.fields.time_start.label': {
    ru: 'Время начала',
    en: 'Time start'
  },

  'projectExportPanel.fieldsForm.fields.time_end.label': {
    ru: 'Время окончания',
    en: 'Time end'
  },

  'projectExportPanel.fieldsForm.fields.expert_id.label': {
    ru: 'ИД эксперта',
    en: 'Expert ID'
  },

  'projectExportPanel.fieldsForm.fields.expert_name.label': {
    ru: 'Имя эксперта',
    en: 'Expert name'
  },

  'projectExportPanel.fieldsForm.fields.expert_email.label': {
    ru: 'Email эксперта',
    en: 'Expert email'
  },

  'projectExportPanel.fieldsForm.fields.expert_phone.label': {
    ru: 'Телефон эксперта',
    en: 'Expert phone'
  },

  'projectExportPanel.fieldsForm.fields.reviewer_comment.label': {
    ru: 'Комментарий проверяющего',
    en: 'Reviewer comment'
  },

  'projectExportPanel.fieldsForm.fields.company.label': {
    ru: 'Компания',
    en: 'Company'
  },

  'projectExportPanel.fieldsForm.fields.department.label': {
    ru: 'Отдел',
    en: 'Department'
  },

  /**
   * FORMATS FORM
   */
  'projectExportPanel.extraSettingsForm.formats.label': {
    ru: 'Формат',
    en: 'Extension'
  },
  'projectExportPanel.extraSettingsForm.formats.1.label': {
    ru: 'Excel',
    en: 'Excel'
  },
  'projectExportPanel.extraSettingsForm.formats.2.label': {
    ru: 'SPSS',
    en: 'SPSS'
  }
};

export default revertDictionary(dictionary);
