// @flow

import interpolateString from '../../../helpers/interpolateString';

const punctuationReplacer = (str: string): string =>
  interpolateString(str, {
    /* eslint-disable prettier/prettier */
        'точка': '.',
        ' запятая': ',',
        ' многоточие': '...',
        ' точка запятой': ';',
        ' двоеточие': ':',
        ' восклицательный знак': '!',
        ' вопросительный знак': '?',
        'открыть кавычки ': '"',
        ' закрыть кавычки': '"',
        'открыть скобку ': '(',
        ' закрыть скобку': ')',
        ' новая строка': '\n',
        'тире': '-'
        /* eslint-enable prettier/prettier */
  });

export default punctuationReplacer;
