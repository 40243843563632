import map from 'lodash/map';
import type { InterviewAction } from '../../flow-types/actions/interview';
import type { InterviewRegistryState } from '../../flow-types/states/InterviewState/InterviewRegistryState';

const initialState: InterviewRegistryState = {
  list: [],
  loading: false,
  saved: false
};

const addRegistryRecords = (state, action) => {
  const {
    payload: { questionIds, ...safePayload }
  } = action;
  const hasMultipleIds = !!questionIds;

  if (hasMultipleIds) {
    return {
      ...state,
      list: [
        ...state.list,
        ...map(questionIds, questionId => ({
          questionId,
          ...safePayload
        }))
      ]
    };
  }
  return {
    ...state,
    list: [...state.list, action.payload]
  };
};

// stores enter/exit question data
export default (state = initialState, action: InterviewAction) => {
  switch (action.type) {
    case 'interview/reset':
      if (action.keepRegistry) return state;
    // eslint-disable-next-line no-fallthrough
    case 'interview/prepare':
      return initialState;

    case 'interview/prepare-complete':
      return action.isNext ? { ...state, list: action.registry } : state;

    case 'interview/fetch-registry-success':
      return {
        ...state,
        list: action.data
      };

    case 'interview/registry':
      return addRegistryRecords(state, action);

    default:
      return state;
  }
};
