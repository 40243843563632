import revertDictionary from 'utils/revertDictionary';

export const PANEL_TITLE = 'pollingNotificationPanel.title';

export const TABLE_COLUMN_TITLE =
  'pollingNotificationsPanel.table.columns.title';

export const TABLE_COLUMN_SUCCEEDED =
  'pollingNotificationsPanel.table.columns.succeeded';

export const CREATE_NEW = 'pollingNotificationsPanel.actions.createNew';

export const MODAL_FORM_TITLE_CREATE = 'pollingNotificationsPanel.title.create';

export const MODAL_FORM_TITLE_EDIT = 'pollingNotificationsPanel.title.edit';

export const MODAL_FORM_ACTION_ACTIVATE =
  'pollingNotificationsPanel.actions.activate';

export const MODAL_FORM_ACTION_DEACTIVATE =
  'pollingNotificationsPanel.actions.stop';

const pollingNotificationDictionary = revertDictionary({
  [PANEL_TITLE]: {
    ru: 'Рассылка опросов',
    en: 'Polling Notifications'
  },
  [TABLE_COLUMN_TITLE]: {
    ru: 'Заголовок',
    en: 'Title'
  },
  [CREATE_NEW]: {
    ru: 'Создать рассылку',
    en: 'Create new'
  },
  [MODAL_FORM_TITLE_CREATE]: {
    ru: 'Новая рассылка',
    en: 'New polling notification'
  },
  [MODAL_FORM_TITLE_EDIT]: {
    ru: 'Редактирование рассылки',
    en: 'Edit polling notification'
  },
  [TABLE_COLUMN_SUCCEEDED]: {
    ru: 'Кол-во отправок',
    en: 'Sends count'
  },
  [MODAL_FORM_ACTION_ACTIVATE]: {
    ru: 'Активировать',
    en: 'Activate'
  },
  [MODAL_FORM_ACTION_DEACTIVATE]: {
    ru: 'Остановить',
    en: 'Deactivate'
  }
});

export default pollingNotificationDictionary;
