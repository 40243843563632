import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { camelizeKeys } from 'humps';
import some from 'lodash/some';
import { toast } from 'react-toastify';
import type { Epic } from 'flow-types/Epic';
import request from 'utils/request';
import { API } from 'utils/config';
import responseParser from 'common/epicHelpers/responseParser';
import decamelizeAndDenormalizeQuestion from 'common/transducers/projects/questions/questionDenormalizer';
import { camelizeAndNormalizeQuestion } from 'common/transducers/projects/questions/questionsNormalizer';
import { TYPES } from 'common/helpers/question';
import decamelizeKeys from 'common/helpers/decamelizeKeys';
import type {
  SaveQuestion,
  SaveQuestionFail
} from 'flow-types/actions/projects/detail/structure/questions/SaveQuestion';
import createQuestionValidator from 'common/validators/validateQuestion';
import parseYupValidation from 'common/transducers/parseYupValidation';
import type { AppState } from 'flow-types/AppState';
import fetchProjectCreator from '../../../actions/project/fetchProject';
import {
  projectIdFromPathSelector,
  questionsListSelector
} from '../../../selectors/projects';
import { locationStateSelector } from '../../../selectors';

const questionValidator = createQuestionValidator();

const saveQuestionEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project-groups/save-question'),
    withLatestFrom($state),
    filter(([, state]) => {
      const projectId = projectIdFromPathSelector(state);

      return projectId !== 'new';
    }),
    mergeMap(([{ question }, state]: [SaveQuestion, AppState]) => {
      try {
        questionValidator.validateSync(question, {
          abortEarly: false
        });
      } catch (e) {
        return [
          ({
            type: 'project-groups/save-question-fail',
            error: parseYupValidation(e),
            originalGroupId: question.questionGroupId,
            originalId: question.id
          }: SaveQuestionFail)
        ];
      }

      const method = question.isNew ? 'POST' : 'PUT';

      const isUpdate = !question.isNew;

      // Variable has to be created first and
      // then its ID is assigned inside block settings.
      // Only then we can save a block.
      let createVariable$ = of(null);

      if (
        question.type === TYPES.SearchView &&
        !question.settings.dataSettings.variableId
      ) {
        const {
          payload: { projectId }
        } = locationStateSelector(state);

        const { tableName } = question.settings.dataSettings.query.entity;

        const body = decamelizeKeys({
          projectId,
          title: `identifier: ${tableName}`,
          type: 'integer',
          formula: 'empty',
          code: `search.${question.id}.${tableName}.id`,
          toEntity: true
        });

        createVariable$ = request({
          url: API.crm.projectVariables,
          method: 'POST',
          body
        });
      }

      if (question.type === TYPES.TabView) {
        const emptyEntityTabs = question.settings.tabs
          .filter(tab => tab.dataSettings.query.entity == null)
          .map((tab, index) => index);

        if (emptyEntityTabs.length > 0) {
          return of({
            type: 'project-groups/save-question-fail',
            originalId: question.id,
            originalGroupId: question.questionGroupId,
            error: emptyEntityTabs.reduce(
              (error, tabIndex) => ({
                ...error,
                tabs: {
                  ...error.tabs,
                  [tabIndex]: {
                    entity: 'viewComposer.error.entityUndefined'
                  }
                }
              }),
              { tabs: {} }
            )
          });
        }
      }

      const questions = questionsListSelector(state);

      // if at least one question depends on that question,
      // then reload structure
      const shouldReloadQuestions: boolean = some(
        questions,
        q => q.optionsFrom === question.id
      );

      return createVariable$.pipe(
        mergeMap(variableResponse => {
          if (variableResponse) {
            // TODO: think how to avoid such a deep data structure like this one,
            // I don't really like the way we work with a block settings objects.
            // eslint-disable-next-line
            question.settings.dataSettings.variableId =
              variableResponse.response.data.id;
          }

          const body = decamelizeAndDenormalizeQuestion(question);

          return request({
            url: !isUpdate
              ? API.questions.list
              : API.questions.detail.replace(':question_id', question.id),
            method,
            body
          }).pipe(
            responseParser,
            mergeMap(response => {
              const { data } = response;

              const normalizedQuestion = camelizeAndNormalizeQuestion(data);

              return [
                {
                  type: 'project-groups/save-question-success',
                  data: normalizedQuestion,
                  ...(!isUpdate && {
                    originalId: question.id,
                    originalGroupId: question.questionGroupId
                  })
                },
                shouldReloadQuestions && fetchProjectCreator(null, true)
              ].filter(Boolean);
            })
          );
        }),
        catchError(({ response, message, status }) => {
          toast.error(
            +status === 422 ? 'Provided data is not acceptable (422)' : message,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2500
            }
          );
          return of({
            type: 'project-groups/save-question-fail',
            originalId: question.id,
            originalGroupId: question.questionGroupId,
            error: response ? camelizeKeys(response.data) : message
          });
        })
      );
    })
  );

export default saveQuestionEpic;
