/* eslint-disable eqeqeq */
// @flow

import fp from 'lodash/fp';
import map from 'lodash/map';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import { camelizeKeys } from 'humps';
import parseISO from 'date-fns/parseISO';

import type { IProject } from 'flow-types/entities/Project';

import defaultsDeep from 'common/helpers/defaults';
import checklistsNormalizer from 'common/transducers/checks/checklists/normalizers';
import questionGroupsNormalizer from './questionGroupsNormalizer';

export const projectChecklistsNormalizer = (project: IProject): IProject => {
  const next = { ...project };

  next.checklists = checklistsNormalizer(next.checklists);

  return next;
};

export const projectSettingsNormalizer = (project): IProject => {
  const next: IProject = { ...project };

  next.settings = defaultsDeep(next.settings, {
    showRequiredBlockOverlay: true,
    browserTitles: {
      mainTitle: '',
      postPollingTitle: '',
      publicTitle: ''
    }
  });

  // edit inherits all settings from web
  if (next.settings?.appearance?.web) {
    next.settings.appearance.edit = { ...next.settings.appearance.web };
  }

  return next;
};

export const projectPeriodNormalizer = (project): IProject => ({
  ...project,
  ...(project.timeEnd && {
    timeEnd: parseISO(project.timeEnd)
  }),
  ...(project.timeStart && {
    timeStart: parseISO(project.timeStart)
  })
});

export const projectCompaniesNormalizer = (project): IProject => ({
  ...project,
  companyId: project.company ? project.company.id : null,
  departmentIds: null,
  ...(size(project.departments) > 0 && {
    departmentIds: map(project.departments, dep => dep.id)
  })
});

export const projectGroupsNormalizer = (project): IProject => ({
  ...project,
  groups: questionGroupsNormalizer(project.groups)
});

export const projectGroupsMapper = (project): IProject => {
  // indexed groups
  const mappedGroups = reduce(
    project.groups,
    (result, group, index) => ({
      ...result,
      [group.id]: index
    }),
    {}
  );

  return {
    ...project,
    mappedGroups
  };
};

export const projectNormalizer: $Call<IProject> = fp.compose(
  projectChecklistsNormalizer,
  projectSettingsNormalizer,
  projectGroupsMapper,
  projectGroupsNormalizer,
  projectCompaniesNormalizer,
  projectPeriodNormalizer,
  camelizeKeys
);

export default fp.map(projectNormalizer);
