type UpdateFilter = {
  // current filter
  +base?: Object,
  // next filter
  +replacer?: Object,
  // filter update
  +updater?: Object
};

/**
 * Helper function to reduce boilerplate of state filter update
 * @returns {*}
 * @param options
 */
const updateFilter = (options: UpdateFilter): Object => {
  if (!options) return {};

  const { base, replacer, updater } = options;

  if (!updater && !replacer) return base;

  if (!updater) return replacer;

  return {
    ...base,
    ...updater
  };
};

// TODO: make updateFilter redundant
export function reducerAwaredUpdateFilter(
  state,
  { filter, filterUpdate } = {}
) {
  return updateFilter({
    base: state.filter,
    replacer: filter,
    updater: filterUpdate
  });
}

export default updateFilter;
