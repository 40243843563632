import revertDictionary from 'utils/revertDictionary';

const pollingNotificationFormDictionary = revertDictionary({
  // TABS
  'pollingNotificationForm.tabs.main': {
    ru: 'Общее',
    en: 'Main'
  },
  'pollingNotificationForm.tabs.filters': {
    ru: 'Условия',
    en: 'Conditions'
  },
  'pollingNotificationForm.tabs.vizSettings': {
    ru: 'Настройки отображения',
    en: 'Visibility Settings'
  },
  'pollingNotificationForm.tabs.config': {
    ru: 'Доп. Настройки',
    en: 'Extras'
  },

  // FIELDS
  'pollingNotificationForm.fields.title.label': {
    ru: 'Заголовок',
    en: 'Title'
  },
  'pollingNotificationForm.fields.channels.label': {
    ru: 'Каналы отправки',
    en: 'Channels'
  },
  'pollingNotificationForm.fields.tests.hint': {
    ru: 'Укажите условия отправки уведомления',
    en: 'Set conditions for sending polling link notification'
  },
  'pollingNotificationForm.fields.vizSettings.hint': {
    ru: 'Укажите доступные для просмотра',
    en: 'Configure visible elements'
  },
  'pollingNotificationsForm.fields.config.greeting.title.label': {
    ru: 'Заголовок приветственного сообщения',
    en: 'Greeting message title'
  },
  'pollingNotificationsForm.fields.config.greeting.content.label': {
    ru: 'Содержание приветственного сообщения',
    en: 'Greeting message content'
  },
  'pollingNotificationsForm.fields.delay.label': {
    ru: 'Настройки задержки отправки',
    en: 'Sending delay settings'
  },

  // VALIDATION
  'pollingNotificationsForm.validation.fields.title.isRequired': {
    ru: 'Необходимо указать название уведомления',
    en: 'Title is required'
  },
  'pollingNotificationsForm.validation.fields.delayType.isRequired': {
    ru: 'Необходимо указать тип задержки перед отправкой',
    en: 'Delay type is required'
  },
  'pollingNotificationsForm.validation.fields.delayValue.isRequired': {
    ru: 'Необходимо указать продолжительность задержки перед отправкой',
    en: 'Delay is required'
  },
  // 'pollingNotificationsForm.validation.fields.questionsAccess.isRequiredWhenNoGroupAccess': {
  //   ru: '',
  //   en: ''
  // },
  // 'pollingNotificationsForm.validation.fields.questionGroupsAccess.isRequiredWhenNoQuestionsAccess': {
  //   ru: '',
  //   en: ''
  // }

  // ACTIONS
  'pollingNotificationsForm.actions.addGreetingConfig': {
    ru: 'Добавить приветственное сообщение',
    en: 'Add greeting message'
  }
});

export default pollingNotificationFormDictionary;
