// @flow

import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { timer, concat } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { createSelector } from 'reselect';
import { decamelizeKeys } from 'humps';

import keyBy from 'lodash/keyBy';
import findLast from 'lodash/findLast';
import isNil from 'lodash/isNil';

import { API } from 'utils/config';
import request from 'utils/request';

import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';

import type { Epic } from 'flow-types/Epic';
import type {
  SubmitAnswers,
  SubmitAnswersSuccess
} from 'flow-types/actions/interview/SubmitAnswers';
import type { AppState } from 'flow-types/AppState';
import type { IRegistryRecord } from 'flow-types/entities/RegistryRecord';
import type { InterviewRegistryState } from 'flow-types/states/InterviewState/InterviewRegistryState';

import { flatQuestionsSubQuestions } from 'common/transducers/projects/projectGroupsToFlatQuestionsList';
import { denormalizeAnswer } from 'common/transducers/answers/answerDenormalizer';
import { TYPES } from 'common/helpers/question';
import catchError from 'common/epicHelpers/catchError';

import takeSecondArg from 'common/helpers/takeSecondArg';
import { searchBlocksSelector } from '../../selectors/crm';
import {
  interviewAnswersSelector,
  interviewRecordSelector,
  interviewRegistrySelector
} from '../../selectors/interview/root';
import { authStateSelector } from '../../selectors';
import { interviewStatusesSelector } from '../../selectors/interview/statuses';
import { interviewQuestionsSelector } from '../../selectors/interview/questions';

const payloadSelector: Function = createSelector(
  [
    interviewRecordSelector,
    interviewAnswersSelector,
    interviewQuestionsSelector,
    interviewRegistrySelector,
    searchBlocksSelector,
    takeSecondArg,
    (_, __, timeStamp: mixed) => timeStamp
  ],
  (
    { data: record },
    answersState,
    questions,
    { list: registry }: InterviewRegistryState,
    searchBlocks,
    questionsIds,
    timeStamp
  ) => {
    const flatQuestions = keyBy(flatQuestionsSubQuestions(questions), 'id');

    const questionsAnswers = questionsIds.reduce((result, questionId) => {
      const lastQuestionEntryTS = findLast(
        registry,
        (reg: IRegistryRecord) => !!reg.enter && +reg.questionId === +questionId
      );

      const questionAnswer = answersState?.[questionId] || null;

      const commons = {
        timeEnd: timeStamp,
        timeStart: lastQuestionEntryTS?.enter || timeStamp,
        question: flatQuestions[questionId] || null,
        questionId
      };

      return [
        ...result,
        denormalizeAnswer(
          questionAnswer
            ? {
                ...questionAnswer,
                ...commons
              }
            : commons
        )
      ];
    }, []);

    const answers = questionsAnswers.reduce((result, answer) => {
      if (typeof answer.value === 'undefined') return result;

      return [
        ...result,
        {
          questionId: answer.questionId,
          value: answer.value
        }
      ];
    }, []);

    const comments = questionsAnswers.reduce((result, answer) => {
      if (!Array.isArray(answer.comments) || answer.comments.length === 0) {
        return result;
      }

      return [...result, ...answer.comments];
    }, []);

    const views = questionsAnswers.reduce(
      (result, answer) => [
        ...result,
        {
          questionId: answer.questionId,
          timeStart: answer.timeStart,
          timeEnd: answer.timeEnd
        }
      ],
      []
    );

    const variables = questionsIds.reduce((result, questionId) => {
      const question = flatQuestions[questionId];

      if (!question) return result;

      const { type, code } = question;

      if (type !== TYPES.SearchView) return result;

      if (isNil(searchBlocks[code])) return result;

      const projectVariableId =
        question.settings?.dataSettings?.variableId || null;

      if (!projectVariableId) return result;

      return {
        projectVariableId,
        value: searchBlocks[code]?.id || null
      };
    }, []);

    const payload = {
      localId: record.localId,
      answers,
      comments,
      views,
      variables
    };

    // do not send empty fields
    Object.keys(payload).forEach(field => {
      if (Array.isArray(payload[field]) && payload[field].length === 0) {
        delete payload[field];
      }
    });

    return payload;
  }
);

const submitAnswers$: Epic = (action$, state$) =>
  action$.pipe<SubmitAnswers>(
    ofType('interview/submit-answers'),
    RxO.withLatestFrom(state$),
    // $FlowIgnore
    RxO.mergeMap<[SubmitAnswers, AppState]>(([action, state]):
      | [SubmitAnswersSuccess]
      | rxjs$Observable<any> => {
      const {
        data: { id: responseId }
      } = interviewRecordSelector(state);

      const { token } = authStateSelector(state);
      const { isPostPolling, isPublicPolling } = interviewStatusesSelector(
        state
      );

      let retries = 0;
      // const language = languageStateSelector(state);

      const { questionsIds, instantSubmit } = action;

      // TODO:
      //  here resending pull can be taken from app state
      //  and put into new questionsIds array

      if (instantSubmit) {
        return [
          {
            type: 'interview/submit-answers-success',
            questionsIds,
            only: action.only
          }
        ];
      }

      const payload = payloadSelector(state, questionsIds, action.timeStamp);

      // empty array can be in place
      const hasPayloadSelectionDefined =
        Array.isArray(action.only) && action.only.length > 0;

      const body = decamelizeKeys({
        ...payload,
        answers:
          hasPayloadSelectionDefined && !action.only.includes('answers')
            ? []
            : payload.answers,
        variables:
          hasPayloadSelectionDefined && !action.only.includes('answers')
            ? []
            : payload.variables,
        views:
          hasPayloadSelectionDefined && !action.only.includes('views')
            ? []
            : payload.views
      });

      return request({
        url: interpolateString(API.responses.answers, { responseId }),
        method: 'POST',
        body,
        token: (isPostPolling || isPublicPolling) && token
      }).pipe(
        responseParser,
        RxO.map<any, SubmitAnswersSuccess>(() => ({
          type: 'interview/submit-answers-success',
          questionsIds,
          only: action.only
        })),
        // TODO: return type
        catchError({
          key(error: AjaxError) {
            return error.response?.code ?? null;
          },
          responders: {
            default: (error: AjaxError, source) => {
              // Try this one to make a generic reusable retry
              // https://danreynolds.ca/tech/2018/01/18/Using-RxJS/
              retries += 1;

              const { response } = error;

              return concat(
                // TODO: fibonacci numbers?
                timer(3000 * retries).pipe(
                  RxO.mapTo({
                    type: 'interview/submit-answers-fail',
                    questionsIds,
                    error: response ? response.message : 'unknown error',
                    only: action.only
                  })
                ),
                // TODO: takeUntil and check the network state
                source.pipe(
                  RxO.startWith({
                    type: 'interview/submit-answers-retrying',
                    retries
                  })
                )
              );
            }
          }
        })
      );
    })
  );

export default submitAnswers$;
