// @flow

import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import logger from 'redux-logger';
import LogRocket from 'logrocket';

import type { AppStore } from 'flow-types/AppStore';

import reducers from './reducers';
import epic from './epics';
import connectedRoutes from './router';

const { enhancer, middleware } = connectedRoutes;

// eslint-disable-next-line
let composeEnhancers;

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  composeEnhancers =
    (window &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
        trace: true
      })) ||
    compose;
} else {
  composeEnhancers = compose;
}

const epicMiddleware = createEpicMiddleware();

const middlewares = applyMiddleware(
  logger,
  epicMiddleware,
  middleware,
  LogRocket.reduxMiddleware()
);

const enhancers = composeEnhancers(enhancer, middlewares);

const store: AppStore = createStore(reducers, enhancers);

epicMiddleware.run(epic);

export default store;
