import fp from 'lodash/fp';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import format from 'date-fns/format';
import orderBy from 'lodash/orderBy';
import { camelizeKeys } from 'humps';
import type { IDecoding } from 'flow-types/entities/Decoding';
import type { IMessage, IMeta } from 'common/containers/Messenger/flow';
import { DATE_FNS_FORMAT } from 'utils/config';

const processMetaDateCreated = (meta: IMeta): IMeta => ({
  ...meta,
  dateCreated: format(new Date(meta.dateCreated), DATE_FNS_FORMAT)
});

export const normalizeMeta = fp.compose(processMetaDateCreated);

const processMessageTimeframes = (message: IMessage) => {
  const { timeframes } = message;

  return {
    ...message,
    timeframes: map(timeframes, frame => parseFloat(frame))
  };
};

export const normalizeMessage = fp.compose(processMessageTimeframes);

export const camelizeAndNormalizeMessage = fp.compose(
  normalizeMessage,
  camelizeKeys
);

export const orderByTimeframe = items => orderBy(items, ['timeframes.0']);

export const normalizeMessages = fp.compose(
  orderByTimeframe,
  fp.map(normalizeMessage)
);

const processDecodingMessages = (decoding: IDecoding): IDecoding => {
  const { messages } = decoding;

  return {
    ...decoding,
    messages: normalizeMessages(messages)
  };
};

const processDecodingSides = (decoding: IDecoding): IDecoding => ({
  ...decoding,
  sides: keyBy(decoding.sides, 'id')
});

const normalizeDecodingMeta = (decoding: IDecoding) => {
  if (!decoding.meta) {
    return decoding;
  }
  return {
    ...decoding,
    meta: normalizeMeta(decoding.meta)
  };
};

export const normalizeDecoding = fp.compose(
  normalizeDecodingMeta,
  processDecodingMessages,
  processDecodingSides
);

export const camelizeAndNormalizeDecoding = fp.compose(
  normalizeDecoding,
  camelizeKeys
);

export const camelizeAndNormalizeDecodingsList = fp.map(
  camelizeAndNormalizeDecoding
);
