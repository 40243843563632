// @flow

// NPM imports
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { denormalizeAccessSettings } from 'common/transducers/projects/accessSettings/accessSettingsDenormalizer';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../../common/components/animated/Modal';
import useModal from '../../../../../common/hooks/useModal';
import AccessSettingsForm from '../../Forms/AccessSettingsForm';
import { projectAccessSettings } from '../../../../../selectors/projects';

export const MODAL_ID = 'accessRuleFormModal';

const dimmerProps = { fixed: true, page: true, scrolling: true };

export default function AccessRuleFormModal() {
  const dispatch = useDispatch();

  const [modalState, , close] = useModal(MODAL_ID);

  const { isMutating } = useSelector(projectAccessSettings);

  const handleSubmit = React.useCallback(
    accessSettings => {
      const parsedAccessSettings = denormalizeAccessSettings(accessSettings);

      dispatch({
        type: 'project/save-access-settings',
        accessSettings: parsedAccessSettings,
        modalId: MODAL_ID
      });
    },
    [dispatch]
  );

  const handleRemove = React.useCallback(
    settingsId => {
      dispatch({
        type: 'project/remove-access-settings',
        settingsId,
        modalId: MODAL_ID
      });
    },
    [dispatch]
  );

  const initialData = React.useMemo(() => {
    if (!modalState.visible) return null;

    if (!modalState.data || !modalState.data) return null;

    return modalState.data;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.data, modalState.visible]);

  return (
    <Modal
      portal
      visible={modalState.visible}
      onClose={isMutating ? null : close}
      dimmerProps={dimmerProps}
    >
      <Modal.Header>
        <FormattedMessage id="accessSettingsForm.title" />
      </Modal.Header>
      <Modal.Content>
        <AccessSettingsForm
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          onCancel={() => close()}
          disabled={isMutating}
          initialData={initialData}
        />
      </Modal.Content>
    </Modal>
  );
}
