import revertDictionary from 'utils/revertDictionary';

const iconsSetsDictionary = revertDictionary({
  'common.iconSet.star': {
    en: 'Star',
    ru: 'Звезда'
  },
  'common.iconSet.user': {
    en: 'Human',
    ru: 'Человек'
  }
});

export default iconsSetsDictionary;
