const commentsDictionary = {
  en: {
    'answer.comments': 'Comments',
    'answer.comments.showAll': 'Show all',
    'answer.comments.showLatest': 'Show latest only',
    'answer.comments.all': 'All comments',
    'answer.comments.all.short': 'All',
    'answer.comments.latest': 'Latest comments',
    'answer.comments.latest.short': 'Latest',
    'answer.comments.edit': 'Edit comment',
    'answer.comments.add': 'Add comment',
    'answer.comments.empty': 'No comments'
  },
  ru: {
    'answer.comments': 'Комментарии',
    'answer.comments.showAll': 'Показать все',
    'answer.comments.showLatest': 'Показать последние',
    'answer.comments.all': 'Все комментарии',
    'answer.comments.all.short': 'Все',
    'answer.comments.latest': 'Последние комментарии',
    'answer.comments.latest.short': 'Последние',
    'answer.comments.edit': 'Редактировать комментарий',
    'answer.comments.add': 'Создать комментарий',
    'answer.comments.empty': 'Нет комментариев'
  }
};

export default commentsDictionary;
