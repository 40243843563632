// @flow

import { ofType } from 'redux-observable';
import * as RxO from 'rxjs/operators';
import { merge, of } from 'rxjs';

import { API } from 'utils/config';
import request from 'utils/request';
import responseParser from 'common/epicHelpers/responseParser';
import interpolateString from 'common/helpers/interpolateString';

import { decamelizeAndDenormalizeProject } from 'common/transducers/projects/projectsDenormalizer';

import type { Epic } from 'flow-types/Epic';
import isNewElement from 'common/helpers/isNewElement';
import type {
  SaveProject,
  SaveProjectFail
} from 'flow-types/actions/projects/detail/project/SaveProject';
import type { AjaxError } from 'flow-types/rxjs/AjaxObservable';
import type { AppState } from 'flow-types/AppState';
import { camelizeKeys } from 'humps';
import { projectFinalDataSelector } from '../../../selectors/projects';

const saveProjectEpic: Epic = ($action, $state) =>
  $action.pipe(
    ofType('project/save'),
    RxO.withLatestFrom($state),
    RxO.switchMap<[SaveProject, AppState]>(([action, state]) => {
      const project = action.project
        ? action.project
        : projectFinalDataSelector(state);

      if (action.fromScript) {
        return [
          {
            type: 'project/save-fail'
          }
        ];
      }

      const isNew = isNewElement(project);

      return request({
        url: isNew
          ? API.projects.list
          : interpolateString(API.projects.detail, { projectId: project.id }),
        method: isNew ? 'POST' : 'PUT',
        body: decamelizeAndDenormalizeProject(project)
      }).pipe(
        responseParser,
        RxO.pluck('data'),
        RxO.mergeMap(nextProject =>
          request({
            url: interpolateString(API.projects.checklists, {
              projectId: nextProject.id
            }),
            query: {
              rewrite: true
            },
            body: {
              checklist_ids:
                Array.isArray(project.checklists) &&
                project.checklists.length > 0
                  ? project.checklists.map(check => check.id)
                  : null
            },
            method: 'PUT'
          }).pipe(
            RxO.mergeMap(() => {
              if (!isNew) {
                return merge(
                  $action.pipe(
                    ofType('project/fetch-success'),
                    RxO.map(() => ({ type: 'project/save-success' })),
                    RxO.take(1),
                    RxO.takeUntil($action.pipe(ofType('project/fetch-fail')))
                  ),
                  of({
                    type: 'project/fetch',
                    projectId: nextProject.id,
                    silent: true
                  })
                );
              }

              return of(
                { type: 'project/save-success' },
                {
                  type: 'router/projectPage',
                  payload: { projectId: nextProject.id }
                }
              );
            }),
            RxO.catchError((ajaxError: AjaxError<any>): [SaveProjectFail] => [
              {
                type: 'project/save-fail',
                error: {
                  checklists: ajaxError.response
                    ? camelizeKeys(ajaxError.response.data)
                    : ajaxError.message
                }
              }
            ])
          )
        ),
        RxO.catchError((ajaxError: AjaxError<any>): [SaveProjectFail] => [
          {
            type: 'project/save-fail',
            error: ajaxError.response
              ? camelizeKeys(ajaxError.response.data)
              : ajaxError.message
          }
        ])
      );
    })
  );

export default saveProjectEpic;
