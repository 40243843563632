import revertDictionary from '../../utils/revertDictionary';

const labelsDictionary = {
  'common.labels.user': {
    en: 'User',
    ru: 'Пользователь'
  },
  'common.labels.detailView': {
    en: 'Detail view',
    ru: 'Подробный вид'
  },
  'common.labels.check': {
    en: 'Check',
    ru: 'Отметить'
  },
  'common.labels.uncheck': {
    en: 'Uncheck',
    ru: 'Снять отметку'
  },
  'common.labels.refresh': {
    en: 'Refresh',
    ru: 'Обновить'
  },
  'common.labels.start': {
    en: 'Start',
    ru: 'Начало'
  },
  'common.labels.end': {
    en: 'End',
    ru: 'Конец'
  },
  'common.labels.ok': {
    en: 'OK',
    ru: 'ОК'
  },
  'common.labels.yes': {
    en: 'Yes',
    ru: 'Да'
  },
  'common.labels.yes_complete': {
    en: 'Yes, complete',
    ru: 'Да, завершить'
  },
  'common.labels.areYouSure': {
    en: 'Are you sure',
    ru: 'Точно?'
  },
  'common.labels.no': {
    en: 'No',
    ru: 'Нет'
  },
  'common.labels.subQuestions': {
    en:
      '{multi, select, true {Sub-questions} false {Sub-question} other {Sub-question}}',
    ru:
      '{multi, select, true {Подвопросы} false {Подвопрос} other {Подвопросы}}'
  },
  'common.labels.postPolling.dashed': {
    en: 'post-polling',
    ru: 'пост-опрос'
  },
  'common.labels.occurredErrors': {
    en: 'Occurred errors',
    ru: 'Возникшие ошибки'
  },
  'common.labels.errorsOccurred': {
    en: 'Errors occurred',
    ru: 'Возникли ошибки'
  },
  'common.labels.returnBack': {
    en: 'Return back',
    ru: 'Вернуться назад'
  },
  'common.labels.stop': {
    en: 'Stop',
    ru: 'Стоп'
  },
  'common.labels.question': {
    en: '{multi, select, true {Questions} false {Question} other {Questions}}',
    ru: '{multi, select, true {Вопросы} false {Вопрос} other {Вопросы}}'
  },
  'common.labels.questionsGroup': {
    en:
      '{multi, select, true {Question groups} false {Question group} other {Question groups}}',
    ru:
      '{multi, select, true {Группы вопросов} false {Группа вопросов} other {Группы вопросов}}'
  },
  'common.labels.options': {
    en: '{multi, select, true {Options} false {Option} other {Options}}',
    ru:
      '{multi, select, other {Опции} false {{case, select, accusative {Опцию} other {Опция}}}}'
  },
  'common.labels.group': {
    en: 'Group',
    ru: 'Группа'
  },
  'common.labels.new': {
    en: 'New',
    ru: 'Новый'
  },
  'common.labels.createNew': {
    ru: 'Создать новый',
    en: 'Create new'
  },
  'common.labels.search.placeholder': {
    en: 'Search...',
    ru: 'Введите для поиска...'
  },
  'common.labels.append': {
    en: 'Append',
    ru: 'Добавить'
  },
  'common.labels.replace': {
    en: 'Replace',
    ru: 'Заменить'
  },
  'common.labels.or': {
    en: 'or',
    ru: 'или'
  },
  'common.labels.and': {
    en: 'and',
    ru: 'и'
  },
  'common.labels.publish': {
    en: 'Publish',
    ru: 'Опубликовать'
  },
  'common.labels.active': {
    en: 'Active',
    ru: 'Активный'
  },
  'common.labels.draft': {
    en: 'Draft',
    ru: 'Черновик'
  },
  'common.labels.deactivate': {
    en: 'Deactivate',
    ru: 'Деактивировать'
  },
  'common.labels.save': {
    en: 'Save',
    ru: 'Сохранить'
  },
  'common.labels.saveAndExit': {
    en: 'Save and exit',
    ru: 'Сохранить и выйти'
  },
  'common.labels.apply': {
    en: 'Apply',
    ru: 'Применить'
  },
  'common.labels.submit': {
    en: 'Submit',
    ru: 'Подтвердить'
  },
  'common.labels.reset': {
    en: 'Reset',
    ru: 'Сбросить'
  },
  'common.labels.resetChanges': {
    en: 'Reset changes',
    ru: 'Сбросить изменения'
  },
  'common.labels.create': {
    en: 'Create',
    ru: 'Создать'
  },
  'common.labels.delete': {
    en: 'Delete',
    ru: 'Удалить'
  },
  'common.labels.edit': {
    en: 'Edit',
    ru: 'Редактировать'
  },
  'common.labels.clone': {
    en: 'Clone',
    ru: 'Клонировать'
  },
  'common.labels.exit': {
    en: 'Exit',
    ru: 'Выйти'
  },
  'common.labels.clearSelection': {
    en: 'Clear selection',
    ru: 'Очистить выбор'
  },
  'common.labels.exitWithoutSave': {
    en: 'Exit without save',
    ru: 'Выйти без сохранения'
  },
  'common.labels.cancel': {
    en: 'Cancel',
    ru: 'Отмена'
  },
  'common.labels.close': {
    en: 'Close',
    ru: 'Закрыть'
  },
  'common.labels.restore': {
    en: 'Restore',
    ru: 'Восстановить'
  },
  'common.labels.value': {
    en: 'Value',
    ru: 'Значение'
  },
  'common.labels.label': {
    en: 'Label',
    ru: 'Подпись'
  },
  'common.labels.activate': {
    en: 'Activate {what}',
    ru: 'Активировать {what}'
  },
  'common.labels.download': {
    en: 'Download',
    ru: 'Скачать'
  },
  'common.labels.format': {
    en: 'Extension',
    ru: 'Формат'
  },
  'common.labels.file.uploads': {
    en: 'Upload file',
    ru: 'Загрузить файл'
  },
  'common.labels.enterDate': {
    en: 'Enter date',
    ru: 'Введите дату'
  },
  'common.labels.upload': {
    en: 'Upload {what, select, logo {logo} image {image} other {file}}',
    ru:
      'Загрузить {what, select, logo {логотип} image {изображение} other {файл}}'
  },
  'common.labels.saveToSee': {
    en: 'Save to see {what}!',
    ru: 'Сохраните чтобы увидеть {what}!'
  },
  'common.labels.changes': {
    en: '{multi, select, other {Changes} false {Change}}',
    ru: '{multi, select, other {Изменения} false {Изменение}}'
  },
  'common.labels.export': {
    en: 'Export',
    ru: 'Экспорт'
  },
  'common.labels.clear': {
    en: 'Clear',
    ru: 'Очистить'
  },
  'common.labels.last_updated_date': {
    en: 'Last update {ago}',
    ru: 'Последнее обновление {ago}'
  },
  'common.labels.no_match': {
    en: 'No match',
    ru: 'Нет совпадений'
  },
  'common.labels.noValue.short': {
    en: 'w/o value',
    ru: 'без значения'
  },
  'common.labels.hasValue.short': {
    en: 'w/ value',
    ru: 'содержит значение'
  },
  'common.labels.not_set': {
    en: 'Not set',
    ru: 'Не задано'
  },
  'common.labels.no_items': {
    en: 'No available items',
    ru: 'Нет доступных элементов'
  },
  'common.labels.no_data': {
    en: 'No data',
    ru: 'Нет данных'
  },
  'common.labels.self_choice': {
    en: 'Self choice',
    ru: 'Свой вариант'
  },
  'common.labels.company': {
    en: 'Company',
    ru: 'Компания'
  },
  'common.labels.department': {
    en: 'Department',
    ru: 'Отдел'
  },
  'common.labels.manager': {
    en: 'Manager',
    ru: 'Менеджер'
  },
  'common.labels.points': {
    en: 'Points',
    ru: 'Баллы'
  },
  'common.labels.meetingStart': {
    en: 'Meeting start',
    ru: 'Начало встречи'
  },
  'common.labels.checklist': {
    en: 'Checklist',
    ru: 'Чеклист'
  },
  'common.labels.meetingDuration': {
    en: 'Meeting duration',
    ru: 'Длительность встречи'
  },
  'common.labels.retry': {
    en: 'Retry',
    ru: 'Повторить'
  },
  'common.labels.load.data': {
    en: 'Load data',
    ru: 'Загрузить данные'
  },
  'common.labels.select.file': {
    en: 'Select file',
    ru: 'Выберите файл'
  },
  'common.labels.page.not_found': {
    en: 'Page not found',
    ru: 'Страница не найдена'
  },
  'common.labels.any': {
    en: 'Any',
    ru: 'Любой'
  },
  'common.labels.quickImport': {
    en: 'Quick import',
    ru: 'Быстрый импорт'
  },
  'common.labels.title': {
    en: 'Title',
    ru: 'Заголовок'
  },
  'common.labels.hasUnsavedChanges': {
    ru: 'У вас есть несохранённые изменения.',
    en: 'You have unsaved changes.'
  },
  'common.labels.from': {
    ru: 'От',
    en: 'From'
  },
  'common.labels.till': {
    ru: 'До',
    en: 'Till'
  },
  'common.labels.goTo': {
    ru: 'Перейти',
    en: 'Go'
  },
  'common.labels.prev': {
    ru: 'Предыдущий',
    en: 'Previous'
  },
  'common.labels.current': {
    ru: 'Текущий',
    en: 'Current'
  },
  'common.labels.next': {
    ru: 'Следующий',
    en: 'Next'
  },
  'common.labels.day': {
    ru: `{count, plural, 
      =0 {Дней} 
      one {День} 
      few {Дня} 
      many {Дней} 
      other {Дней}
    }`,
    en: `{count, plural, 
      =0 {Days} 
      one {Day} 
      many {Days} 
      other {Days}
    }`
  },
  'common.labels.week': {
    ru: `{count, plural, 
      =0 {Недель} 
      one {Неделя} 
      few {Недели} 
      many {Недель} 
      other {Недель}
    }`,
    en: `{count, plural, 
      =0 {Weeks} 
      one {Week} 
      many {Weeks} 
      other {Weeks}
    }`
  },
  'common.labels.month': {
    ru: `{count, plural, 
      =0 {Месяцев} 
      one {Месяц} 
      few {Месяца} 
      many {Месяцев} 
      other {Месяцев}
    }`,
    en: `{count, plural, 
      =0 {Months} 
      one {Month} 
      many {Months} 
      other {Months}
    }`
  },
  'common.labels.year': {
    ru: `{count, plural, 
      =0 {Лет} 
      one {Год} 
      few {Года} 
      many {Лет} 
      other {Лет}
    }`,
    en: `{count, plural, 
      =0 {Years} 
      one {Year} 
      many {Years} 
      other {Years}
    }`
  },
  'common.labels.hour': {
    ru: `{count, plural, 
      =0 {Часов} 
      one {Час} 
      few {Часа} 
      many {Часов} 
      other {Часов}
    }`,
    en: `{count, plural, 
      =0 {Hours} 
      one {Hour} 
      many {Hours} 
      other {Hours}
    }`
  },
  'common.labels.minute': {
    ru: `{count, plural, 
      =0 {Минут} 
      one {Минута} 
      few {Минуты} 
      many {Минут} 
      other {Минут}
    }`,
    en: `{count, plural, 
      =0 {Minutes} 
      one {Minute} 
      many {Minutes} 
      other {Minutes}
    }`
  },
  'common.labels.second': {
    ru: `{count, plural, 
      =0 {Секунд} 
      one {Секунда} 
      few {Секунды} 
      many {Секунд} 
      other {Секунд}
    }`,
    en: `{count, plural, 
      =0 {Seconds} 
      one {Second} 
      many {Seconds} 
      other {Seconds}
    }`
  },
  'common.labels.response': {
    ru: `{count, plural, 
      =0 {Встреч} 
      one {Встреча} 
      few {Встречи} 
      many {Встреч} 
      other {Встреч}
    }`,
    en: `{count, plural, 
      =0 {Responses} 
      one {Response} 
      many {Responses} 
      other {Responses}
    }`
  },
  'common.labels.record': {
    ru: `{count, plural, 
      =0 {Записей} 
      one {Запись} 
      few {Записи} 
      many {Записей} 
      other {Записей}
    }`,
    en: `{count, plural, 
      =0 {Records} 
      one {Record} 
      many {Records} 
      other {Records}
    }`
  },
  'common.labels.subQuestion': {
    ru: `{count, plural, 
      =0 {Подвопросов} 
      one {Подвопрос} 
      few {Подвопроса} 
      many {Подвопросов} 
      other {Подвопросов}
    }`,
    en: `{count, plural, 
      =0 {Subquestions} 
      one {Subquestion} 
      many {Subquestions} 
      other {Subquestions}
    }`
  },
  'common.labels.collapseAll': {
    en: 'Collapse All',
    ru: 'Свернуть всё'
  },
  'common.labels.expandAll': {
    en: 'Expand All',
    ru: 'Раскрыть всё'
  },
  'common.labels.selectAll': {
    en: 'Select all',
    ru: 'Выбрать всё'
  },

  /**
   * SEARCH RELATED
   */
  'common.labels.search.emptyMessageDescription': {
    en: 'No items have matched the query',
    ru: 'По запросу не найдено ни одного элемента'
  },
  'common.labels.search.emptyMessageTitle': {
    en: 'No Results',
    ru: 'Без результатов'
  },

  /**
   * Unsaved data related
   */
  'common.labels.waitForActiveProcessesToComplete': {
    en: 'You have unsaved data on this page. Please wait...',
    ru:
      'У вас есть не сохранённые изменения на данной странице. Пожалуйста, подождите...'
  }
};

export default revertDictionary(labelsDictionary);
