import type { DecodingsAction } from 'flow-types/actions/records/decodings';
import type { SidesFormState } from 'flow-types/states/RecordsState';
import { SAVE_SIDES, SAVE_SIDES_FAIL, SAVE_SIDES_SUCCESS } from './list';

const initialState: SidesFormState = {
  status: 'success'
};

export default function sidesFormReducer(
  state: SidesFormState = initialState,
  action: DecodingsAction
) {
  switch (action.type) {
    case SAVE_SIDES:
      return {
        ...state,
        status: 'loading'
      };

    case SAVE_SIDES_FAIL:
      return {
        ...state,
        status: 'error'
      };

    case SAVE_SIDES_SUCCESS:
      return {
        ...state,
        status: 'success'
      };

    default:
      return state;
  }
}
