import * as RxO from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { camelizeKeys, decamelizeKeys } from 'humps';
import request from 'utils/request';
import { API } from 'utils/config';

import interpolateString from 'common/helpers/interpolateString';
import responseParser from 'common/epicHelpers/responseParser';
import listResponseParser from 'common/epicHelpers/listResponseParser';

import type { Epic } from 'flow-types/Epic';
import type { AppState } from 'flow-types/AppState';

import { projectIdFromPathSelector } from '../../../../selectors/projects';

const fetchMassActionsTasks: Epic = (action$, state$) =>
  action$.pipe(
    ofType('project-responses-mass-actions-tasks/fetch'),
    RxO.withLatestFrom(state$),
    RxO.switchMap(([, state]: [Object, AppState]) => {
      const projectId = projectIdFromPathSelector(state);
      const massTasksFilter = state.projectDetail.massActionsTasks.filter;

      return request({
        url: interpolateString(API.responses.massActions.tasksList, {
          projectId
        }),
        query: decamelizeKeys(massTasksFilter),
        method: 'GET'
      }).pipe(
        responseParser,
        listResponseParser,
        RxO.map(({ data, pagination }) => ({
          type: 'project-responses-mass-actions-tasks/fetch-success',
          data: camelizeKeys(data),
          pagination
        }))
      );
    })
  );

export default fetchMassActionsTasks;
