import get from 'lodash/get';
import set from 'lodash/set';

// use cases:
// const a = { b: { c: [0, 1, 3] } };
// const b = keyedDataUpdater(a, 'b.c.2', oldValue => 4); [0, 1, 4]

const keyedDataUpdater = (data, pathFinder, updater) => {
  const copy = Array.isArray(data) ? [...data] : { ...data };
  let updated;
  if (updater) {
    if (typeof updater === 'function') {
      updated = updater(get(copy, pathFinder));
    } else {
      updated = updater;
    }
  } else {
    updated = data;
  }
  // let updated = updater ? updater(get(copy, pathFinder)) : data;
  return set(copy, pathFinder, updated);
};

export default keyedDataUpdater;
