// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './index';

const SubmitButton: React.AbstractComponent<
  Object,
  HTMLElement
> = React.forwardRef((props, ref) => (
  <Button {...props} ref={ref} buttonType="primary">
    <FormattedMessage id="common.labels.submit" />
  </Button>
));

export default SubmitButton;
