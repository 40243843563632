// @flow
/* eslint-disable react/no-danger */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import AceEditor from 'react-ace';
import CKEditor from '@ckeditor/ckeditor5-react';
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import Classic from '@ckeditor/ckeditor5-build-classic';
import cx from 'classnames';
import map from 'lodash/map';
import size from 'lodash/size';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';

import 'brace/mode/jsx';
import 'brace/snippets/html';
import 'brace/mode/html';
import 'brace/theme/monokai';
import 'brace/ext/language_tools';
import 'brace/ext/searchbox';

import Menu from 'common/components/Menu';
import ListItem from 'common/components/ListItem';
import { Tab, TabsProvider } from 'common/contexts/TabsContext';
import useDidMount from 'common/hooks/useDidMount';
import TemplateCode from 'common/components/Editor/TemplateCode';

import type { TemplateCode as ITemplateCode } from 'common/components/Editor/flow';

import Label from '../Label';
import Message from '../Message';

type Props = {|
  padded?: boolean | 'horizontally' | 'vertically',
  value: string,
  maxHeight: string,
  minHeight: string,
  onChange: Function,
  defaultState: 'preview' | 'wysiwyg' | 'source',
  error: ?string,
  // Decided to move templateCodes functionality inside HTML Editor
  // in purpose to create plugin for CKEditor in a future to be able
  // to easily paste required template code inside html code.
  templateCodes?: ITemplateCode[],
  modes?: Array<'wysiwyg' | 'source'>
|};

const Container = styled.div`
  .ck.ck-content {
    ${props => props.maxHeight && `max-height: ${props.maxHeight}px;`}
    ${props => props.minHeight && `min-height: ${props.minHeight}px;`}
  }

  // On small screen it will be overridden by semantic's styles,
  // i.e. 1rem padding will be added.
  // In all other cases 10px padding-top will be added to each template code box
  // starting from third element.
  .tmpl-code:not(:first-child):not(:nth-child(2)) {
    padding-top: 10px;
  }
`;

export const EDITOR_MODE = {
  PREVIEW: 'preview',
  WYSIWYG: 'wysiwyg',
  SOURCE: 'source'
};

export default function Editor({
  value,
  padded,
  maxHeight,
  minHeight,
  error,
  defaultState,
  modes,
  onChange,
  templateCodes,
  ...rest
}: Props) {
  const [loaded, setLoaded] = useState(false);

  // This field is used to avoid unexpected CKEditor
  // behaviour to change initial data
  const isFocused = React.useRef(false);

  useDidMount(() => {
    setLoaded(true);
  });

  const [view, setState] = useState(defaultState);

  const setOptions = useMemo(
    () => ({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
      showLineNumbers: true,
      tabSize: 2,
      ...(rest.setOptions && rest.setOptions)
    }),
    [rest.setOptions]
  );

  return (
    <Container
      minHeight={minHeight}
      maxHeight={maxHeight}
      className={cx(
        'ui',
        {
          horizontally: padded === 'horizontally',
          vertically: padded === 'vertically',
          padded
        },
        'stackable',
        'grid'
      )}
    >
      <div className="row">
        <div className="column">
          <Menu secondary>
            <ListItem
              active={view === EDITOR_MODE.WYSIWYG}
              onClick={() => setState(EDITOR_MODE.WYSIWYG)}
            >
              <FormattedMessage id="htmlEditor.modes.wysiwyg.title" />
            </ListItem>
            {includes(modes, EDITOR_MODE.SOURCE) && (
              <ListItem
                active={view === EDITOR_MODE.SOURCE}
                onClick={() => setState(EDITOR_MODE.SOURCE)}
              >
                <FormattedMessage id="htmlEditor.modes.source.title" />
              </ListItem>
            )}
            {EDITOR_MODE.WYSIWYG && (
              <ListItem
                active={view === EDITOR_MODE.PREVIEW}
                onClick={() => setState(EDITOR_MODE.PREVIEW)}
              >
                <FormattedMessage id="htmlEditor.modes.preview.title" />
              </ListItem>
            )}
          </Menu>
          <div>
            <TabsProvider activeTab={view}>
              <Tab eventKey={EDITOR_MODE.PREVIEW}>
                <div dangerouslySetInnerHTML={{ __html: value }} />
              </Tab>
              {loaded && (
                <>
                  {includes(modes, EDITOR_MODE.SOURCE) && (
                    <Tab eventKey={EDITOR_MODE.SOURCE}>
                      {error && (
                        <Label color="red" pointing="below">
                          {error}
                        </Label>
                      )}
                      <AceEditor
                        {...rest}
                        mode="html"
                        theme="monokai"
                        commands={Beautify.commands}
                        onChange={code => {
                          onChange(code);
                        }}
                        fontSize={14}
                        style={{
                          width: '100%',
                          ...(rest.style && rest.style)
                        }}
                        value={!isNil(value) ? value : ''}
                        highlightActiveLine
                        setOptions={setOptions}
                      />
                    </Tab>
                  )}
                  {includes(modes, EDITOR_MODE.WYSIWYG) && (
                    <Tab eventKey={EDITOR_MODE.WYSIWYG}>
                      {error && (
                        <Message size="small" status="error">
                          {error}
                        </Message>
                      )}
                      <CKEditor
                        {...rest}
                        editor={Classic}
                        data={value || ''}
                        onFocus={() => {
                          isFocused.current = true;
                        }}
                        onBlur={() => {
                          isFocused.current = false;
                        }}
                        onChange={(_, editor) => {
                          if (!isFocused.current) return;
                          onChange(editor.getData());
                        }}
                      />
                    </Tab>
                  )}
                </>
              )}
            </TabsProvider>
          </div>
        </div>
      </div>
      {size(templateCodes) > 0 && (
        <div className="two column row">
          {map(templateCodes, (data: ITemplateCode) => (
            <div className="column tmpl-code" key={data.code}>
              <TemplateCode data={data} />
            </div>
          ))}
        </div>
      )}
    </Container>
  );
}

Editor.defaultProps = {
  value: '',
  padded: 'horizontally',
  defaultState: EDITOR_MODE.WYSIWYG,
  templateCodes: [],
  modes: [EDITOR_MODE.WYSIWYG, EDITOR_MODE.SOURCE]
};
